<template>
  <div class="pa-0">
    <div v-if="loading" style="position: relative; padding: 200px 0;">
      <div class="thrive-loader"></div>
    </div>

    <div v-else>
      <v-container grid-list-md class=" ma-0 pa-0" fluid>
        <v-layout row wrap class="py-0">
          <v-flex md4 xs12>
            <!-- <h2>{{ $t('conversion.generalSettings', $store.state.locale)}}</h2> -->
          </v-flex>
          <v-flex md8 xs12 class="text-right">
            <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small :dark="changed" :loading="updateWidgetSettingsBusy" :disabled="!changed" :depressed="!changed" @click="updateWidgetSettings()">
              <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale)}} <v-icon right >mdi-chevron-right</v-icon></div>
              <div v-else>{{ $t('general.saved', $store.state.locale)}} <v-icon right >mdi-check</v-icon></div>  
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>

      <v-container grid-list-md class="pa-0 py-4 ma-0">
        <v-layout row wrap>
          <v-flex xs12 v-if="feedback">
            <v-alert :value="true" class="ma-0 mb-2 pa-2" style="border-radius: 0" type="warning">{{ feedback }}</v-alert>
          </v-flex>
          <v-flex md6 xs12 class="pr-3">
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
              <div class="pa-3">
                <v-avatar
                  size="36"
                  light
                  style="float: left"
                  class="ma-0 mr-2 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cog</v-icon>
                </v-avatar>
                <!-- <h3 class="pt-1 pb-4">{{ $t('branch.extraWidgetSettings', $store.state.locale)}} </h3> -->
                <h3 class="pt-1 pb-4">Algemeen</h3>
                
                <v-divider></v-divider>
                  <v-menu
                    :close-on-content-click="false"
                    v-model="conversionStartDialog"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      v-on="on"
                      dense
                      class="mt-4"
                      background-color="background"
                      v-model="conversionStartDate.readable"
                      :label="$t('general.startDate', $store.state.locale)"
                      prepend-inner-icon="mdi-calendar"
                      clearable
                      @click:clear="resetConversionStartDate()"
                      readonly
                      :hint="$t('conversion.startDateHint', $store.state.locale)"
                      persistent-hint
                    ></v-text-field>
                    </template> 
                    <v-date-picker
                    v-model="conversionStartDateRaw"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    no-title
                    @input="conversionStartDialog = false"
                    ></v-date-picker>
                  </v-menu>

                <v-text-field
                    class="pt-4"
                    dense
                    :label="$t('conversion.successUrl', $store.state.locale)"
                    v-model="activeCompData.conversion.successUrl"
                    prepend-inner-icon="mdi-web"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :hint="$t('conversion.successUrlHint', $store.state.locale)"
                    persistent-hint
                    required
                    clearable
                    outlined
                ></v-text-field>

                <v-text-field
                    v-if="$store.state.activeUserRoleNumber < 2"
                    class="pt-4"
                    dense
                    style="max-width: 450px"
                    :label="$t('conversion.adwordsBudget', $store.state.locale)"
                    prefix="€"
                    v-model.number="activeCompData.conversion.adwordsBudget"
                    prepend-inner-icon="mdi-cash-multiple"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    :hint="$t('conversion.adwordsBudgetHint', $store.state.locale)"
                    persistent-hint
                    required
                    outlined
                ></v-text-field>

                <v-text-field
                    class="pt-4"
                    dense
                    style="max-width: 450px"
                    :label="$t('branch.gtmCode', $store.state.locale)"
                    v-model.number="activeCompData.conversion.widget.gtmTracking.gtmCode"
                    prepend-inner-icon="mdi-code-tags"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    persistent-hint
                    required
                    outlined
                ></v-text-field>

                <v-switch
                    class="mt-3 pa-0"
                    :label="$t('conversion.feedBackOnClose', $store.state.locale)"
                    v-model="activeCompData.conversion.widget.feedBackOnClose"
                    persistent-hint
                    :hint="$t('conversion.feedBackOnCloseHint', $store.state.locale)"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                ></v-switch>

                <v-switch
                    class="mt-3 pa-0"
                    :label="$t('conversion.showCallback', $store.state.locale)"
                    v-model="activeCompData.conversion.widget.showCallback"
                    persistent-hint
                    :hint="$t('conversion.showCallbackHint', $store.state.locale)"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                ></v-switch>
              </div>
            </v-card>
          </v-flex>
          <v-flex md6 xs12 d-flex >
            <v-card style="width: 100%" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
              <div class="pa-3">
                <v-avatar
                  size="36"
                  light
                  style="float: left"
                  class="ma-0 mr-2 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-edit</v-icon>
                </v-avatar>
                <!-- <h3 class="pt-1 pb-4">{{ $t('branch.extraWidgetSettings', $store.state.locale)}} </h3> -->
                <h3 class="pt-1 pb-4">Extra instellingen</h3>
                
                <v-divider></v-divider>

                <v-switch
                  class="mt-4 pa-0"
                  :label="$t('conversion.bookingsAvailable', $store.state.locale)"
                  v-model="activeCompData.conversion.widget.active"
                  hide-details
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                ></v-switch>

                <v-switch
                  class="mt-4 pa-0"
                  :label="$t('conversion.bookingsAvailableFrom', $store.state.locale)"
                  v-model="activeCompData.conversion.widget.startDateActive"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  hide-details
                ></v-switch>

                <v-menu
                  v-if="activeCompData.conversion.widget.startDateActive"
                  :close-on-content-click="false"
                  v-model="widgetStartDialog"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  >
                  <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    style="max-width: 450px"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-on="on"
                    dense
                    class="mt-4"
                    background-color="menu"
                    v-model="widgetStartDate.readable"
                    :label="$t('conversion.bookingsAvailableFromDate', $store.state.locale)"
                    prepend-inner-icon="mdi-calendar"
                    clearable
                    hide-details
                    readonly
                  ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="widgetStartDateRaw"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    no-title
                    @input="widgetStartDialog = false"
                  ></v-date-picker>
              </v-menu>

                <v-switch
                  class="mt-4 pa-0"
                  :label="$t('conversion.showNotification', $store.state.locale)"
                  v-model="activeCompData.conversion.widget.notification.show"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  hide-details
                ></v-switch>

                <div v-if="activeCompData.conversion.widget.notification.show">
                  <v-select
                    dense
                    class="mt-4"
                    :item-text="item => item.name[($store.state.nativeLanguage).toLowerCase()]"
                    item-value="id"
                    :items="availableLanguages"
                    v-model="selectedLanguage"
                    label="Selecteer taal"
                    outlined
                    hide-details
                    background-color="menu"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    >
                    <template v-slot:prepend-inner>
                      <div style="margin: 2px 0 0 0">
                        <div v-if="selectedLanguage == 'en'" class="language-flag uk"></div>
                        <div v-if="selectedLanguage == 'nl'" class="language-flag nl"></div>
                      </div>
                    </template>
                  </v-select>

                  <v-text-field
                    style="width: 100%"
                    class="pt-4"
                    :label="$t('conversion.notificationTitle', $store.state.locale)"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="menu"
                    v-model="activeCompData.conversion.widget.notification.messageTitle[(selectedLanguage).toLowerCase()]"
                    hide-details 
                    outlined
                ></v-text-field>

                  <v-textarea
                    outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    class="mt-4"
                    rows="8"
                    background-color="menu"
                    v-model="activeCompData.conversion.widget.notification.message[(selectedLanguage).toLowerCase()]"
                    :label="$t('conversion.notification', $store.state.locale)"
                    hide-details
                  ></v-textarea>
                </div>

                <div class="color-box-wrapper clear" style="padding: 25px 0 15px 0">
                  <v-container grid-list-md class="pa-0" fluid>
                    <v-layout row wrap>
                      <v-flex xs6>
                        <div class="color-boxes-icon">
                            <v-icon>mdi-palette</v-icon>
                        </div>
                        <div class="color-boxes clear">
                            <p class="ma-0">{{ $t('conversion.selectWidgetPrimaryColor', $store.state.locale) }}</p>
                            <div class="color-box" v-bind:style="{ backgroundColor: activeCompData.conversion.widget.primaryColor.hex}" @click="widgetColor.primary.dialog = true"></div>
                        </div>
                      </v-flex>
                      <v-flex xs6>
                        <div class="color-boxes-icon">
                            <v-icon>mdi-palette</v-icon>
                        </div>
                        <div class="color-boxes clear">
                          <p class="ma-0">{{ $t('conversion.selectWidgetSecondaryColor', $store.state.locale) }}</p>
                          <div class="color-box" v-bind:style="{ backgroundColor: activeCompData.conversion.widget.secondaryColor.hex }" @click="widgetColor.secondary.dialog = true"></div>
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </div>
              </div>
            </v-card>
          </v-flex>
          <v-flex md6 xs12 class="pt-3 pr-3">
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
              <div class="pa-3">
                <v-avatar
                  size="36"
                  light
                  style="float: left"
                  class="ma-0 mr-2 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chart-pie</v-icon>
                </v-avatar>
                <h3 class="pt-1 pb-4">{{ $t('conversion.conversionSources', $store.state.locale) }}</h3>

                <v-divider></v-divider>

                <div class="default-source-tags">
                  <div style="padding: 12px 0 0 0;">
                    <p style="font-weight: bold; font-size: 16px;" class="ma-0">{{ $t('conversion.generalTags', $store.state.locale) }}</p>
                    <p><i>{{ $t('conversion.addNewTagsExplaination', $store.state.locale) }}</i></p>
                  </div>

                  <div style="display: inline-block;" v-for="(tag ,index) in generalTags" :key="index">
                    <v-btn class="ma-1" small :color="tag.color" :style="{color: tag.textColor}" style="text-transform: none;"><strong>{{tag.name}}</strong><span class="tag-query" :style="{color: tag.textColor}">[?src={{ tag.query }}]</span></v-btn>
                    <div v-if="tag.copy == false" class="copy-tag-url" @click="copyTextToClipboard( activeCompData.url + '/?src=' + tag.query, index, 'tag' )"><v-icon small color="#333">mdi-content-copy</v-icon>{{ $t('general.copyUrl', $store.state.locale) }}</div>
                    <div v-else class="copy-tag-url copied" >{{ $t('general.copied', $store.state.locale) }}</div>
                  </div>
                </div>

                <v-container grid-list-md class="pa-0" fluid>
                  <v-layout row wrap>
                    <v-flex xs8>
                      <div style="padding: 20px 10px 10px 10px;">
                        <p style="font-weight: bold; font-size: 16px;" class="ma-0">{{ $t('conversion.createdTags', $store.state.locale)}}</p>
                        <p><i>{{ $t('conversion.addNewTags', $store.state.locale)}}</i></p>
                      </div>
                      </v-flex>
                      <v-flex xs4>
                      <v-btn small style="float: right;" v-if="activeCompData.conversion.sources.length > 0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" fab dark @click="addNewConversionSource()">
                        <v-icon small>mdi-plus</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>

                <div style="display: inline-block;" v-for="(source, index) in activeCompData.conversion.sources" :key="index">
                  <v-btn class="ma-1" small :color="source.bgColor.hex"  :style="{color: source.textColor.hex}" style="text-transform: none;">
                    <strong>{{ source.name }}</strong> <span class="tag-query" :style="{color: source.textColor.hex}">[?src={{ source.query }}]</span> <v-icon @click.native="editConversionSource(index)"  :color="source.textColor.hex" dark small right>mdi-pencil</v-icon> <v-icon @click="deleteConversionSourceDialog(index)" :color="source.textColor.hex" dark small right>mdi-delete</v-icon>
                  </v-btn>
                  <div v-if="source.copy == false" class="copy-tag-url" @click="copyTextToClipboard( activeCompData.url + '/?src=' + source.query, index, 'source' )"><v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon> {{ $t('general.copyUrl', $store.state.locale)}}</div>
                  <div v-else class="copy-tag-url copied" >{{ $t('general.copied', $store.state.locale)}}</div>
                  </div>
                  <div class="text-center" v-if="activeCompData.conversion.sources.length == 0">
                  <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" fab dark @click="addNewConversionSource()">
                      <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <p style="text-align: center; padding: 10px 0;"><i>{{ $t('conversion.noTagsYet', $store.state.locale)}}</i></p>
                </div>
              </div>
            </v-card>
          </v-flex>

          <v-flex md6 xs12 d-flex class="pt-3">
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 mt-0" style="width: 100%" :class="$store.state.companyTheme">
              <div class="pa-3">
                <v-avatar
                  size="36"
                  light
                  style="float: left"
                  class="ma-0 mr-2 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-web</v-icon>
                </v-avatar>
                <h3 class="pt-1 pb-4">{{ $t('conversion.widgetUrl', $store.state.locale) }}</h3>

                <v-divider></v-divider>

                <v-container grid-list-md  fluid >
                <div style="padding: 10px 0 0 0;">
                  <p class="ma-0"><i>{{ $t('conversion.widgetCampagneUse', $store.state.locale)}}</i></p>
                </div>

                <v-layout row wrap>
                  <v-flex xs6>
                    <v-select
                      :label="$t('conversion.selectSource', $store.state.locale)"  
                      outlined
                      dense
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      class="my-2"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].menu"
                      :items="allConversionItems"
                      prepend-inner-icon="mdi-forward"
                      item-text="name"
                      item-value="query"
                      v-model="campagnUrl.source"
                      hide-details
                      clearable
                    >
                    </v-select>
                    
                    <v-switch
                      class="mt-3 pa-0"
                      :label="$t('conversion.openWidget', $store.state.locale)"
                      v-model="campagnUrl.openWidget"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    ></v-switch>

                    <v-container v-if="campagnUrl.openWidget" grid-list-md class="pa-0" fluid>
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-select
                            outlined
                            dense
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            class="my-2"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].menu"
                            :items="languages"
                            prepend-inner-icon="mdi-flag"
                            item-text="name"
                            item-value="code"
                            v-model="campagnUrl.lang"
                            :label="$t('conversion.selectLanguage', $store.state.locale)"  
                            hide-details
                            >
                          </v-select>
                        </v-flex>

                        <v-flex xs6>
                          <v-text-field
                            outlined
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            class="my-2"
                            dense
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].menu"
                            v-model="campagnUrl.name"
                            prepend-inner-icon="mdi-account"
                            :label="$t('general.name', $store.state.locale)"
                            hide-details
                          ></v-text-field>
                          <p style="font-size: 12px; padding: 2px "><i>{{$t('conversion.mailchimpExampleFname', $store.state.locale)}}</i></p>
                        </v-flex>

                        <v-flex xs6>
                          <v-text-field
                            outlined
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            class="my-2"
                            dense
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].menu"
                            prepend-inner-icon="mdi-email"
                            v-model="campagnUrl.email"
                            :label="$t('general.email', $store.state.locale)"
                            hide-details
                          ></v-text-field>
                          <p style="font-size: 12px; padding: 2px "><i>{{$t('conversion.mailchimpExampleEmail', $store.state.locale)}}</i></p>
                        </v-flex>

                        <v-flex xs6>
                          <v-text-field
                            outlined
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            class="my-2"
                            dense
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].menu"
                            prepend-inner-icon="mdi-phone"
                            v-model="campagnUrl.phone"
                            :label="$t('general.phone', $store.state.locale)"
                            hide-details
                          ></v-text-field>
                          <p style="font-size: 12px; padding: 2px "><i>{{$t('conversion.mailchimpExamplePhone', $store.state.locale)}}</i></p>
                        </v-flex>

                        <v-flex xs6>
                          <v-select
                            outlined
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            v-if="branches.length > 1"
                            class="my-2"
                            dense
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].menu"
                            :items="branches"
                            prepend-inner-icon="mdi-office-building"
                            item-text="name"
                            item-value="id"
                            v-model="campagnUrl.branch"
                            :label="$t('conversion.selectBranch', $store.state.locale)"
                            hide-details
                            >
                          </v-select>
                        </v-flex>

                        <v-flex xs6>
                          <v-select
                            outlined
                            dense
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            class="my-2"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].menu" 
                            :items="showPricesValues"
                            prepend-inner-icon="mdi-cash-multiple"
                            item-text="name"
                            item-value="id"
                            v-model="campagnUrl.showPrices"
                            :label="$t('conversion.showPrices', $store.state.locale)"
                            hide-details
                            >
                          </v-select>
                        </v-flex>

                        <v-flex xs6>
                          <v-text-field
                            outlined
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            class="my-2"
                            dense
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].menu" 

                            prepend-inner-icon="mdi-alpha-t-box"
                            v-model="campagnUrl.treatment"
                            :label="$t('conversion.treatmentId', $store.state.locale)"
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    

                  </v-flex>
                </v-layout>
                <div style="display: block; padding: 5px 5px 15px 5px;">
                  <div style="display: inline-block; font-style: italic; padding: 5px 10px; color: #fff; background-color: #333;" v-if="websiteUrl.enable">{{ websiteUrl.url}}</div>
                  <div style="font-style: italic;" v-else v-html="$t('conversion.widgetUrlRules', $store.state.locale)"></div>
                </div>
                
                <v-btn :dark="websiteUrl.enable" :depressed="!websiteUrl.enable" :disabled="!websiteUrl.enable" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="copyTextToClipboard(websiteUrl.url, null, 'campagne')"><v-icon left small color="#fff">mdi-content-copy</v-icon>{{ $t('general.copyUrl', $store.state.locale) }}</v-btn>
                <div style="display: inline-block" v-if="campagnUrl.copy" class="copy-tag-url copied" >{{ $t('general.copied', $store.state.locale) }}</div>
                
              </v-container>  

              </div>
            </v-card>
          </v-flex>

          <v-flex md6 xs12 d-flex class="pt-3">
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 mt-0" style="width: 100%" :class="$store.state.companyTheme">
              <div class="pa-3">
                <v-avatar
                  size="36"
                  light
                  style="float: left"
                  class="ma-0 mr-2 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-close-circle</v-icon>
                </v-avatar>
                <h3 class="pt-1 pb-4">{{ $t('conversion.widgetBlacklist', $store.state.locale) }}</h3>

                <v-divider></v-divider>

                <v-container grid-list-md  fluid>
          
                  <p class="ma-0"><i>{{ $t('conversion.widgetBlacklistUse', $store.state.locale)}}</i></p>

                  <v-alert outlined dense text class="ma-0 mt-2"  v-if="newBlacklistItem.feedback" type="warning">
                    {{ newBlacklistItem.feedback  }}
                  </v-alert>
            
                  <v-row dense class="pb-4">
                    <v-col cols="2">
                      <v-select
                        :label="$t('general.type', $store.state.locale)"  
                        outlined
                        dense
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        class="mt-4"
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].menu"
                        :items="blacklistTypes"
                        item-text="name"
                        item-value="id"
                        return-object
                        v-model="newBlacklistItem.type"
                        hide-details
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        class="mt-4"
                        dense
                        :label="newBlacklistItem.type.name"
                        v-model="newBlacklistItem.value"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        background-color="background"
                        required
                        hide-details
                        append-icon="mdi-close"
                        @click:append="newBlacklistItem.value = ''"
                        outlined
                      ></v-text-field>
                    </v-col>
                     <v-col cols="3">
                      <v-select
                        :label="$t('general.branches', $store.state.locale)"  
                        outlined
                        dense
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        class="mt-4"
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].menu"
                        :items="blacklistBlockTypes"
                        item-text="name"
                        item-value="id"
                        return-object
                        v-model="newBlacklistItem.blockType"
                        hide-details
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="2">
                      <v-btn class="mt-4" height="40px" block :dark="newBlacklistItem.value.length > 0" :depressed="newBlacklistItem.value.length == 0" :disabled="newBlacklistItem.value.length == 0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addBlacklistItem()">{{ $t('general.add', $store.state.locale) }}</v-btn>
                    </v-col>
                  </v-row>

                  <div v-if="!blackListItemsLoading">
                    <div v-if="backlistItems.length > 0">

                      <div v-for="(item, index) in backlistItems" :key="index">
                        <v-divider></v-divider>
                        <div class="blacklist-item" >
                          <div class="blacklist-item-data item-value">
                            {{ item.value }}
                          </div>
                          <div class="blacklist-item-data item-blacktype text-right">
                            <v-chip v-if="item.blockType == 'global'" :link="false" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mr-2 my-0 custom-chip-style"> {{ $t('products.allBranches', $store.state.locale)}}</v-chip>
                            
                            <v-tooltip dark bottom v-else> 
                              <template v-slot:activator="{ on }">
                                <v-chip v-on="on" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mr-2 my-0 custom-chip-style" close @click:close="setBlackListItemToGlobal(item)"> {{ getBranchName(item.branchId) }}</v-chip>
                              </template>
                              <span class="tooltip-text-white">{{ $t('conversion.makeBlackListItemGlobal', $store.state.locale)}}</span>
                            </v-tooltip>

                          </div>
                          <div class="blacklist-item-data item-remove text-center">
                            <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeBlackListItemDialog(item, 'deletedBlackListItem')">
                              mdi-delete
                            </v-icon>
                          </div> 
                        </div>  
                        <v-divider v-if="index == backlistItems.length - 1"></v-divider>     
                      </div>
                    </div>

                    
                    <div v-else>
                      <v-divider class="mb-7"></v-divider>
                      <div class="text-center pa-4">
                        <v-avatar
                          size="48"
                          light
                          class="ma-0 mr-2 custom-avatar-style" 
                          rounded="lg"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                          >
                          <v-icon size="28" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-close-circle</v-icon>
                        </v-avatar>
                        <p class="pt-4"> {{ $t('conversion.notItemsBlackList', $store.state.locale) }}</p>
                      </div>
                    </div>
                  </div>
                  <div v-else style="position: relative; padding: 100px 0;">
                    <div class="thrive-loader"></div>
                  </div>

                </v-container>  

              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>


    </div>
        
    <!-- / New Conversion Source Dialog \-->
    <v-dialog v-model="newConversionSource.dialog" max-width="600" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t('conversion.addNewTag', $store.state.locale) }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="newConversionSource.dialog = false">
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert
          class="ma-0 pa-1"
          v-if="newConversionSource.feedback"
          :value="true"
          type="warning"
          >
          {{ newConversionSource.feedback }} 
        </v-alert>

        <v-divider></v-divider>
        
        <v-card-text>
          <v-text-field
            class="mt-4"
            outlined
            dense
            background-color="background"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            :label="$t('general.name', $store.state.locale)"
            v-model="newConversionSource.name"
            required
            hide-details
          ></v-text-field>

          <v-text-field
            class="mt-4"
            outlined
            dense
            background-color="background"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            :label="$t('conversion.query', $store.state.locale)"
            v-model="newConversionSource.query"
            :hint="$t('conversion.queryHint', $store.state.locale)"
            persistent-hint
            required
          ></v-text-field>

          <div class="color-box-wrapper clear" style="padding: 5px 0 15px 0">
            <v-container grid-list-md class="pa-0" fluid>
              <v-layout row wrap>
                <v-flex xs6>
                  <div class="color-boxes-icon">
                      <v-icon>mdi-palette</v-icon>
                  </div>
                  <div class="color-boxes clear">
                      <p class="ma-0">{{ $t('conversion.selectBackgroundColor', $store.state.locale) }}</p>
                      <div class="color-box" v-bind:style="{ backgroundColor: newConversionSource.bgColor.hex }" @click="newConversionSource.bgColorDialog = true"></div>
                  </div>
                </v-flex>
                <v-flex xs6>
                  <div class="color-boxes-icon">
                      <v-icon>mdi-palette</v-icon>
                  </div>
                  <div class="color-boxes clear">
                      <p class="ma-0">{{ $t('conversion.selectTextColor', $store.state.locale) }}</p>
                      <div class="color-box" v-bind:style="{ backgroundColor: newConversionSource.textColor.hex }" @click="newConversionSource.textColorDialog = true"></div>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                  @click="newConversionSource.dialog = false"
                  >
                  {{ $t('general.cancel', $store.state.locale) }}
                  </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                  <v-btn
                  dark
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  @click="addConversionSourceTag()"
                  >
                  {{ $t('conversion.addTag', $store.state.locale) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
            </v-flex>
            </v-layout>
         </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ New Conversion Source Dialog / -->

    <!-- / Edit Conversion Source Dialog \-->
    <v-dialog v-model="selectedConversionSource.dialog" max-width="600" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2>{{ $t('conversion.editTag', $store.state.locale) }}</h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="selectedConversionSource.dialog = false">
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
          
        <v-alert
          class="ma-0 pa-1"
          v-if="selectedConversionSource.feedback"
          :value="true"
          type="warning"
          >
          {{ selectedConversionSource.feedback }} 
        </v-alert>

        <v-divider></v-divider>
          
        <v-card-text>
          <v-text-field
            class="mt-4"
            outlined
            dense
            background-color="background"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            :label="$t('general.name', $store.state.locale)"
            v-model="selectedConversionSource.name"
            required
            hide-details
          ></v-text-field>

          <v-text-field
            class="mt-4"
            outlined
            dense
            background-color="background"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            :label="$t('conversion.query', $store.state.locale)"
            v-model="selectedConversionSource.query"
            :hint="$t('conversion.queryHint', $store.state.locale)"
            persistent-hint
            required
          ></v-text-field>

          <div class="color-box-wrapper clear" style="padding: 5px 0 15px 0">
            <v-container grid-list-md class="pa-0" fluid>
              <v-layout row wrap>
                <v-flex xs6>
                  <div class="color-boxes-icon">
                      <v-icon>mdi-palette</v-icon>
                  </div>
                  <div class="color-boxes clear">
                      <p class="ma-0">{{ $t('conversion.selectBackgroundColor', $store.state.locale) }}</p>
                      <div class="color-box" v-bind:style="{ backgroundColor: selectedConversionSource.bgColor.hex }" @click="selectedConversionSource.bgColorDialog = true"></div>
                  </div>
                  </v-flex>
                  <v-flex xs6>
                  <div class="color-boxes-icon">
                      <v-icon>mdi-palette</v-icon>
                  </div>
                  <div class="color-boxes clear">
                      <p class="ma-0">{{ $t('conversion.selectTextColor', $store.state.locale) }}</p>
                      <div class="color-box" v-bind:style="{ backgroundColor: selectedConversionSource.textColor.hex }" @click="selectedConversionSource.textColorDialog = true"></div>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn
                    outlined
                    depressed
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                    @click="selectedConversionSource.dialog = false"
                    >
                    {{ $t('general.cancel', $store.state.locale) }}
                  </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                  <v-btn
                    dark
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="updateConversionSourceTag()"
                    >
                    {{ $t('general.saveChanged', $store.state.locale) }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
              </v-flex>
              </v-layout>
          </v-container>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- \ Edit Conversion Source Dialog / -->

    <!-- / Delete Action Dialog \ -->
    <v-dialog v-model="deleteSelectedSource.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
          </v-avatar>
          <p class="ma-0">{{ $t('conversion.removeTagInfo', $store.state.locale) }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="deleteSelectedSource.dialog = false">
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="deleteConversionSource()">
                  Verwijderen
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete Action Dialog / -->

    <v-dialog width="225px" v-model="newConversionSource.bgColorDialog" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <chrome-picker v-model="newConversionSource.bgColor"></chrome-picker>
    </v-dialog>
    <v-dialog width="225px" v-model="newConversionSource.textColorDialog" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <chrome-picker v-model="newConversionSource.textColor"></chrome-picker>
    </v-dialog>
    <v-dialog width="225px" v-model="selectedConversionSource.bgColorDialog" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <chrome-picker v-model="selectedConversionSource.bgColor"></chrome-picker>
    </v-dialog>
    <v-dialog width="225px" v-model="selectedConversionSource.textColorDialog" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <chrome-picker v-model="selectedConversionSource.textColor"></chrome-picker>
    </v-dialog>
    <v-dialog width="225px" v-model="widgetColor.primary.dialog" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <chrome-picker v-model="activeCompData.conversion.widget.primaryColor"></chrome-picker>
    </v-dialog>
    <v-dialog width="225px" v-model="widgetColor.secondary.dialog" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <chrome-picker v-model="activeCompData.conversion.widget.secondaryColor"></chrome-picker>
    </v-dialog>
  </div>
</template>

<script>
  import Vue from "vue";
  import moment from "moment";
  import { bus } from "../main";
  import db from "../firebase/init";
  import platform from 'platform'; 
  import { Chrome } from "vue-color";
  import { parsePhoneNumber } from 'awesome-phonenumber'

  export default {
    name: "WidgetSettings",

    components: {
      "chrome-picker": Chrome,
    },

    data() {
      return {
        loading: true,
        activeCompData: new Object(),
        activeCompDataOriginal: new Object(),
        feedback: null,
        widgetUrl: {
          lang: 'nl',
          info: 'front',
          copy: false
        },
        blackListItemsLoading: true,
        newBlacklistItem: {
          feedback: null,
          type: new Object(),
          value: '',
          blockType: 'global'
        },
        blacklistTypes: [
          {
            id: 'ip',
            name: this.$t('general.ipAddress', this.$store.state.locale),
          },
          {
            id: 'email',
            name: this.$t('general.email', this.$store.state.locale),  
          },
          {
            id: 'phone',
            name: this.$t('general.phone', this.$store.state.locale),  
          },
        ],

        blacklistBlockTypes: [
          {
            id: 'global',
            name: this.$t('general.all', this.$store.state.locale),
          },
          {
            id: 'branch',
            name: this.$t('general.selected', this.$store.state.locale),  
          },
        ],

        backlistItems: new Array(),
        showPricesValues: [
          {
            id: 'yes',
            name: 'Prijzen tonen',
          },
          {
            id: 'no',
            name: 'Prijzen verbergen',
          }
        ],
        emailVariables: [
          {
            id: 'firstName',
            name: '[Voornaam]',
            copy: false
          },
          {
            id: 'date',
            name: '[Afspraakdatum]',
            copy: false
          },
          {
            id: 'time',
            name: '[Afspraaktijdstip]',
            copy: false
          },
          {
            id: 'branch',
            name: '[Salon]',
            copy: false
          },
          {
            id: 'price',
            name: '[Prijs]',
            copy: false
          },

        ],
        availableLanguages: [
          {
            id: 'nl',
            name: {
              nl: 'Nederlands',
              en: 'Dutch'
            }
          },
          {
            id: 'en',
            name: {
              nl: 'Engels',
              en: 'English'
            }
          }
        ],
        searchCompanies: "",
        updateCallcenterBusy: false,
        updateSmsSettingsBusy: false,
        updateWidgetSettingsBusy: false,
        campagnUrl: {
          source: null,
          copy: false,
          openWidget: false,
          name: null,
          email: null,
          phone: null,
          treatment: null,
          branch: null,
          lang: 'nl',
          showPrices: "no"
        },
        generalTags: [
          {
            name: 'Adwords',
            query: 'adw',
            copy: false,
            color: '#1a73e8',
            textColor: '#fff'
          },
          {
            name: 'Mailchimp',
            query: 'mc',
            copy: false,
            color: '#ffe01b',
            textColor: '#333'
          }
        ],

        widgetInfoPostion: [
          {
            name: "Vooraan",
            id: "front"
          },
          {
            name: "Achteraan",
            id: "back"
          }
        ],
        languages: [
          {
            name: "Nederlands",
            code: "nl"
          },
          {
            name: "Engels",
            code: "en"
          }
        ],
        newConversionSource: {
          dialog: false,
          feedback: null,
          name: "",
          query: "",
          textColor: {
            hex: '#ffffff'
          },
          bgColor: {
            hex: "#333333"
          },
          textColorDialog: false,
          bgColorDialog: false,
        },
        widgetColor: {
          primary: {
            dialog: false,
          },
          secondary: {
            dialog: false,
          }
        },
        selectedConversionSource: {
          dialog: false,
          feedback: null,
          name: "",
          query: "",
          textColor: {
            hex: '#ffffff'
          },
          bgColor: {
            hex: "#333333"
          },
          textColorDialog: false,
          bgColorDialog: false,
        },
        branches: [],
        activeComp: "", 
        conversionStartDateRaw: "",
        conversionStartDialog: false,
        widgetStartDateRaw: "",
        widgetStartDialog: false,
        now: null,
        deleteSelectedSource: {
          dialog: false,
          index: null
        },
        selectedLanguage: "nl",
        smsSettingsFeedback: null,
      }
    },

    created() {
      this.newBlacklistItem.type = this.blacklistTypes[0];
      bus.$on("conversionUpdate", this.loadData);
      bus.$on("companyUpdate", this.loadData);
      bus.$on("deletedBlackListItem", (id) => { this.removeBlackListItem(id) });
      this.loadData();
      this.now = moment().format("YYYY-MM-DD");

    },

    methods: {
      loadData(){
        this.loading = true;
        this.activeCompData = _.cloneDeep(this.$store.state.activeCompany);
        this.activeCompDataOriginal =  _.cloneDeep(this.activeCompData);
        this.loadBranches();
        this.loadBlackListItems();
      },

      async loadBlackListItems(){
        this.blackListItemsLoading = true;
        this.backlistItems = new Array();
        await db.collection("companies")
        .doc(this.$store.state.activeCompany.id)
        .collection("blacklist")
        .get()
        .then(snap => {
          snap.forEach(doc => {
            let item = doc.data();
            item.id = doc.id;
            this.backlistItems.push(item);
          });
        })
        this.backlistItems.sort((a, b) => a.created.localeCompare(b.created));
        this.blackListItemsLoading = false;
      },
      
      resetBlackListItem(){
        this.newBlacklistItem.feedback = null;
        this.newBlacklistItem.value = "";
        this.newBlacklistItem.type = this.blacklistTypes[0];
        this.newBlacklistItem.blockType = 'global';
      },

      checkPhonenumber(input, region){
        const pn = parsePhoneNumber( input, region );
        if(pn.g && pn.g.valid){
          return pn.g.number.e164
        }
        else{
          return false;
        }
      },

      async addBlacklistItem(){
        this.newBlacklistItem.feedback = null;
        let item = _.cloneDeep(this.newBlacklistItem);
        let emailValid = item.type.id == 'email' ? String(item.value).toLowerCase().match( /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/) != null ? true : false : true;
        let ipValid = item.type.id == 'ip' ? String(item.value).toLowerCase().match( /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/) != null ? true : false : true;
        let phoneValid = item.type.id == 'phone' ? this.checkPhonenumber(item.value, 'NL')  ? true : false : true;

        if(emailValid && ipValid && phoneValid){
          item.branchId = this.$store.state.activeBranch.id;
          item.user = `${this.$store.state.activeUserFirstName} ${this.$store.state.activeUserLastName}`;
          item.userType = this.$store.state.activeUserRole;
          item.created = moment().format("YYYY-MM-DDTHH:mm");

          item.value =  item.type.id == 'phone' ? this.checkPhonenumber(item.value, 'NL') : item.value,
          
          await db.collection("companies")
          .doc(this.$store.state.activeCompany.id)
          .collection("blacklist")
          .add({
            value: item.value,
            created: item.created,
            type: item.type.id,
            blockType: item.blockType,
            userAdded: item.user,
            userTypeAdded: item.userType,
            branchId:  item.branchId           
          })
          .then(docRef=>{
            item.id = docRef.id
          })
          this.backlistItems.unshift(item);
          this.resetBlackListItem();
        }
        else{
          if(!emailValid){
            this.newBlacklistItem.feedback = this.$t('general.emailaddressNotValid', this.$store.state.locale); 
          }
          if(!ipValid){
            this.newBlacklistItem.feedback = this.$t('general.ipNotValid', this.$store.state.locale); 
          }
          if(!phoneValid){
            this.newBlacklistItem.feedback = this.$t('general.phoneNotValid', this.$store.state.locale); 
          }
        }
      },

      async setBlackListItemToGlobal(item){
        await db.collection("companies")
        .doc(this.$store.state.activeCompany.id)
        .collection("blacklist")
        .doc(item.id)
        .set({
          blockType: 'global'
        },{merge: true});
        this.loadBlackListItems();
      },

      removeBlackListItemDialog(item, deleteFunction){
        let deleteInformation = new Object();
        deleteInformation.id = item.id;
        deleteInformation.emit = deleteFunction;
        deleteInformation.title = this.$t('conversion.removeBlacklistItem', this.$store.state.locale);
        deleteInformation.info = this.$t('conversion.removeBlacklistItemInfo', this.$store.state.locale);
        bus.$emit('deletedModal', deleteInformation, 'open');
      },

      async removeBlackListItem(id){
        await db.collection("companies")
        .doc(this.$store.state.activeCompany.id)
        .collection("blacklist")
        .doc(id)
        .delete();
        this.loadBlackListItems();
        bus.$emit('deletedModal', null, 'close');
      },

      getBranchName(id){
        let branchName = "";
        if(this.$store.state.companyBranches && this.$store.state.companyBranches.length > 0){
          this.$store.state.companyBranches.forEach(branch=>{
            if(branch.id == id){
              branchName = branch.name;
            }
          })
        }
       return branchName;
      },

      loadBranches(){
        db.collection("branches")
          .where("companyId", "==", this.activeCompData.id)
          .get()
          .then(snap => {
            snap.forEach(doc => {
              let branchCollection = doc.data();
              branchCollection.id = doc.id;
              this.branches.push(branchCollection);
            });
          })
          .then(()=>{
            if(this.branches.length > 0){
              this.campagnUrl.branch = this.branches[0].id;
            }
            this.loading = false;
          })
          .catch(error=>{
            console.error("Error loading branches: ", error);
          })
      },

      resetConversionStartDate(){
        this.conversionStartDateRaw = null;
        this.activeCompData.conversion.start = null;
      },

      addNewConversionSource(){
        this.newConversionSource.dialog = true;
      },

      editConversionSource(index){
        this.selectedConversionSource = { 
          feedback: null,
          name: this.activeCompData.conversion.sources[index].name,
          query: this.activeCompData.conversion.sources[index].query,
          textColor: this.activeCompData.conversion.sources[index].textColor,
          bgColor: this.activeCompData.conversion.sources[index].bgColor,
          textColorDialog: false,
          bgColorDialog: false,
          dialog: true,
          index: index
        }
      },

      deleteConversionSourceDialog(index){
        this.deleteSelectedSource.dialog = true
        this.deleteSelectedSource.index = index
      },

      addConversionSourceTag(){
        this.newConversionSource.feedback = null
        if(this.newConversionSource.name && this.newConversionSource.query){
          let newSource = {
            name: this.newConversionSource.name,
            query: this.newConversionSource.query,
            textColor: this.newConversionSource.textColor,
            bgColor: this.newConversionSource.bgColor,
            copy: false
          } 
          this.activeCompData.conversion.sources.push(newSource)
          console.log("add tag", this.activeComp,  this.activeCompData.conversion.sources)
          db.collection("companies")
            .doc(this.activeCompData.id)
            .set({
              conversion: {
                sources: this.activeCompData.conversion.sources
              } 
            }, { merge: true })
            .then(()=>{
              this.newConversionSource = {
                dialog: false,
                feedback: null,
                name: "",
                query: "",
                textColor: {
                  hex: '#ffffff'
                },
                bgColor: {
                  hex: "#333333"
                },
                textColorDialog: false,
                bgColorDialog: false,
              }
            })
            .catch(error=>{
              console.error("Toevoegen conversion source niet gelukt: ", error)
            })
        } else{
          this.newConversionSource.feedback = "Vul alle velden in om tag toe te voegen."
        }
      },

      updateConversionSourceTag(){
        this.selectedConversionSource.feedback = null
        
        if(this.selectedConversionSource.name && this.selectedConversionSource.query){
          let updatedSource = {
            name: this.selectedConversionSource.name,
            query: this.selectedConversionSource.query,
            textColor: this.selectedConversionSource.textColor,
            bgColor: this.selectedConversionSource.bgColor
          } 
          this.activeCompData.conversion.sources[this.selectedConversionSource.index] = updatedSource
          db.collection("companies")
            .doc(this.activeCompData.id)
            .set({
              conversion: {
                sources: this.activeCompData.conversion.sources
              } 
            }, { merge: true })
            .then(()=>{
              this.activeCompData.conversion.sources.forEach(conversieItem =>{
                conversieItem.copy = false
              })
              this.selectedConversionSource = {
                dialog: false,
                feedback: null,
                name: "",
                query: "",
                textColor: {
                  hex: '#ffffff'
                },
                bgColor: {
                  hex: "#333333"
                },
                textColorDialog: false,
                bgColorDialog: false,
              }
            })
            .catch(error=>{
              console.error("Updaten conversion source niet gelukt: ", error)
            })
        } else{
          this.selectedConversionSource.feedback = "Vul alle velden in om wijzigingen op te slaan."
        }
      },

      deleteConversionSource(){   
        this.activeCompData.conversion.sources.splice(this.deleteSelectedSource.index , 1)
        db.collection("companies")
          .doc(this.activeCompData.id)
          .set({
            conversion: {
              sources: this.activeCompData.conversion.sources  
            } 
          }, { merge: true })
          .then(()=>{
            this.deleteSelectedSource.dialog = false
            this.deleteSelectedSource.index = null
          })
          .catch(error=>{
            console.error("Updaten (verwijderen) conversion source niet gelukt: ", error)
          })
      },

      updateWidgetSettings(){
        this.updateWidgetSettingsBusy = true
        let startDate = null
        this.feedback = null;

        let noWidgetStartDate = this.activeCompData.conversion.widget.startDateActive && !this.widgetStartDate.firebase ? true : false;

        let widgetNotificationText;
        if(this.activeCompData.conversion.widget.notification.show){
          if(this.activeCompData.conversion.widget.notification.message.nl && this.activeCompData.conversion.widget.notification.message.en){
            widgetNotificationText = true;
          }
          else{
            widgetNotificationText = false;
          }
        }
        else{
          widgetNotificationText = true;
        }

        if(!noWidgetStartDate && widgetNotificationText) {
          db.collection("companies")
          .doc(this.activeCompData.id)
          .set({
            conversion: {
              start: this.conversionStartDate.firebase,
              adwordsBudget: this.activeCompData.conversion.adwordsBudget,
              widget: {
                showCallback: this.activeCompData.conversion.widget.showCallback,
                feedBackOnClose: this.activeCompData.conversion.widget.feedBackOnClose,
                notification: this.activeCompData.conversion.widget.notification,
                startDateActive: this.activeCompData.conversion.widget.startDateActive,
                startDate: this.widgetStartDate.firebase,
                active: this.activeCompData.conversion.widget.active,
                primaryColor: this.activeCompData.conversion.widget.primaryColor,
                secondaryColor: this.activeCompData.conversion.widget.secondaryColor,
                gtmTracking: {
                  gtmCode: this.activeCompData.conversion.widget.gtmTracking.gtmCode.replace(/\s/g, '')
                }
              },
              successUrl: this.activeCompData.conversion.successUrl,
            } 
          }, { merge: true })
          .then(()=>{
            this.activeCompDataOriginal.conversion.successUrl = _.cloneDeep(this.activeCompData.conversion.successUrl)
            this.activeCompDataOriginal.conversion.adwordsBudget = _.cloneDeep(this.activeCompData.conversion.adwordsBudget)
            if(this.widgetStartDateRaw){
              let selectedDate = _.cloneDeep(this.widgetStartDate.formatted)
              this.activeCompData.conversion.widget.startDate = {};
              this.activeCompData.conversion.widget.startDate.seconds = moment(selectedDate).format("X");
              this.widgetStartDateRaw = "";
            }
            this.activeCompDataOriginal.conversion.widget = _.cloneDeep(this.activeCompData.conversion.widget) 

            if(this.conversionStartDateRaw){
              let selectedDate = _.cloneDeep(this.conversionStartDate.formatted)
              this.activeCompData.conversion.start = {};
              this.activeCompData.conversion.start.seconds = moment(selectedDate).format("X");
              this.conversionStartDateRaw = null;
            }  
          })
          .then(()=>{
            this.updateWidgetSettingsBusy = false;
          })
          .catch(error=>{
            console.error("Updaten niet gelukt: ", error)
          })

        }
        else{
          if(noWidgetStartDate){
            this.feedback = "Vul de startdatum voor het toestaan van een online afspraak in om op te slaan."
          }
          else{
            this.feedback = "Vul de tekst in beide talen in voor de Noodmelding om op te slaan."
          }
          this.updateWidgetSettingsBusy = false;
        }

      
      },

      fallbackCopyTextToClipboard(text) {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position="fixed";  //avoid scrolling to bottom
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          var successful = document.execCommand('copy');
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
      },

      copyTextToClipboard(text, index, type) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }
        navigator.clipboard.writeText(text).then(()=> {
          this.campagnUrl.copy = false
          this.widgetUrl.copy = false
          this.activeCompData.conversion.sources.forEach(conversieItem =>{
            conversieItem.copy = false
          })
          this.generalTags.forEach(conversieItem =>{
            conversieItem.copy = false
          })
          if(type == 'source'){        
            this.activeCompData.conversion.sources[index].copy = true
            let newData = _.cloneDeep(this.activeCompData.conversion.sources)
            this.activeCompData.conversion.sources = newData

            setTimeout(()=>{ 
              this.activeCompData.conversion.sources[index].copy = false
            }, 1000);
          } 
          else if(type == 'tag'){
            this.generalTags[index].copy = true

            setTimeout(()=>{ 
              this.generalTags[index].copy = false
            }, 1000);
          }
          else if(type == 'campagne'){
            this.campagnUrl.copy = true

            setTimeout(()=>{ 
              this.campagnUrl.copy = false
            }, 1000);
          }
          else if(type == 'widget'){
            this.widgetUrl.copy = true

            setTimeout(()=>{ 
              this.widgetUrl.copy = false
            }, 1000);
          }

        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      },

      copyVariableToClipboard(text, index){
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }
        navigator.clipboard.writeText(text).then(()=> {
          this.emailVariables.forEach(variable =>{
            variable.copy = false
          })
          this.generalTags.forEach(conversieItem =>{
            conversieItem.copy = false
          })
          this.emailVariables[index].copy = true
          setTimeout(()=>{ 
            this.emailVariables[index].copy = false
          }, 1000);

        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      }
    },

    computed: {
      conversionStartDate(){
        let date = {
          readable: "",
          formatted: "",
          firebase: null
        }
        if(this.conversionStartDateRaw){
          date.readable = moment(this.conversionStartDateRaw).locale('nl').format("D MMMM YYYY");
          date.formatted = moment(this.conversionStartDateRaw).format("YYYY/MM/DD");
          date.firebase = new Date(moment(this.conversionStartDateRaw).format("YYYY/MM/DD"));
          return date
        }
        else if(this.activeCompData.conversion.start){
          date.readable = moment.unix(this.activeCompData.conversion.start.seconds).locale('nl').format("D MMMM YYYY");
          date.formatted = moment.unix(this.activeCompData.conversion.start.seconds).format("YYYY/MM/DD");
          date.firebase = new Date(moment(date.formatted).format("YYYY/MM/DD"));
          return date
        }
        else {
          return date
        }
      },
      
      widgetStartDate(){
        let date = {
          readable: "",
          formatted: "",
          firebase: null
        }
        if(this.widgetStartDateRaw){
          date.readable = moment(this.widgetStartDateRaw).locale('nl').format("D MMMM YYYY");
          date.formatted = moment(this.widgetStartDateRaw).format("YYYY/MM/DD");
          date.firebase = new Date(moment(this.widgetStartDateRaw).format("YYYY/MM/DD"));
          return date;
        }
        else if(this.activeCompData.conversion.widget.startDate){
          date.readable = moment.unix(this.activeCompData.conversion.widget.startDate.seconds).locale('nl').format("D MMMM YYYY");
          date.formatted = moment.unix(this.activeCompData.conversion.widget.startDate.seconds).format("YYYY/MM/DD");
          date.firebase = new Date(moment(date.formatted).format("YYYY/MM/DD"));
          return date;
        }
        else {
          return date;
        }
      },


      websiteUrl(){
        let url = {
          enable: false,
          url: 'leeg'
        }
        let source = ""
        let openWidget = ""
        let name = ""
        let email = ""
        let phone = ""
        let treatment = ""
        let lang = ""
        let branch = ""
        let showPrices = ""
        let compUrl = this.activeCompData.url
        if(this.campagnUrl.source || this.campagnUrl.openWidget){
          url.enable = true
        } else {
          url.enable = false
        }

        if(this.campagnUrl.openWidget){
          openWidget = '#thriveWidget/?comp=' + this.activeCompData.id;
        }

        if(this.campagnUrl.openWidget && this.campagnUrl.lang){
          lang = '&lang=' + this.campagnUrl.lang;
        }

        if(this.campagnUrl.openWidget && this.campagnUrl.branch){
          branch = '&branch=' + this.campagnUrl.branch;
        }
        
        if(this.campagnUrl.openWidget && this.campagnUrl.name){
          name = '&cname=' + this.campagnUrl.name;
        }

        if(this.campagnUrl.openWidget && this.campagnUrl.email){
          email = '&cem=' + this.campagnUrl.email;
        }

        if(this.campagnUrl.openWidget && this.campagnUrl.phone){
          phone = '&ctel=' + this.campagnUrl.phone;
        }

        if(this.campagnUrl.openWidget && this.campagnUrl.treatment){
          treatment = '&shtm=' + this.campagnUrl.treatment;
        }   

        if(this.campagnUrl.openWidget && this.campagnUrl.showPrices == "yes"){
          showPrices = '&shp=1';
        }

        if(this.campagnUrl.openWidget && this.campagnUrl.source){
          source = '&src=' + this.campagnUrl.source
        }

        url.url = compUrl + openWidget + lang + branch + name + email + phone + treatment + showPrices + source

        return url
      },

      allConversionItems(){
        let items = _.cloneDeep(this.generalTags)
    
        if(this.activeCompData.conversion.sources.length > 0){
          this.activeCompData.conversion.sources.forEach(source=>{
            items.push(source)
          })
        } 
        return items
      },
    

      changed() {
        let successUrl = _.isEqual(this.activeCompData.conversion.successUrl, this.activeCompDataOriginal.conversion.successUrl)
        let adwordsBudget = _.isEqual(this.activeCompData.conversion.adwordsBudget, this.activeCompDataOriginal.conversion.adwordsBudget)
        let widget = _.isEqual(this.activeCompData.conversion.widget, this.activeCompDataOriginal.conversion.widget)

        console.log("widget:", widget, "adwordsBudget: ", adwordsBudget, "successUrl: ", successUrl)

        if(this.activeCompData.conversion.start && this.activeCompData.conversion.start.seconds){
          if (moment.unix(this.activeCompData.conversion.start.seconds).format("YYYY/MM/DD") != this.conversionStartDate.formatted || !adwordsBudget || !successUrl || !widget || moment.unix(this.activeCompData.conversion.widget.startDate.seconds).format("YYYY/MM/DD") != this.widgetStartDate.formatted) {
            return true;
          } else {
            return false;
          }
        } 
        else{
          if (this.conversionStartDate.firebase || !successUrl || !adwordsBudget || !widget || this.widgetStartDate.firebase) {
            return true;
          } else {
            return false;
          }
        }
        
      }
    }
  };
</script>

<style>
  .email-preview .ck.ck-editor__main .ck.ck-content.ck-editor__editable{
    background-color: var(--v-menu-base)!important;
    border-color: var(--v-menuborder-base)!important;
  }
</style>

<style>
  .conversion-source-tags{
    margin: 15px 0;
    padding: 10px 5px;
    background-color: var(--v-menuhover-base)
  }
  .conversion-source-tags:after{
    clear: both;
    display: block;
    content: "";
  }

  .tag-query{
    font-style: italic;
    font-size: 12px;
    margin: 0 0 0 5px;
  }

  .color-boxes-icon {
    float: left;
    width: 35px;
    padding: 20px 0 0 0;
  }

  .color-boxes {
    float: left;
  }
  .color-box-wrapper p {
    font-family: "Sofia Pro", sans-serif;
    font-weight: 400;
    padding: 0 0 5px 0;
    font-size: 12px;
    margin: 0;
  }

  .color-box {
    float: left;
    margin: 0 15px 0 0;
    width: 40px;
    height: 25px;
    border: 1px solid rgba(0, 0, 0, 0.3);
  }

  .color-box:hover {
    cursor: pointer;
  }

  .blacklist-item:after{
    display: block;
    content: "";
    clear: both;
  }
  .blacklist-item-data{
    float: left;
    padding: 8px 0;
    height: 38px;
  }

  .blacklist-item-data.item-value{
    width: calc(100% - 290px);
  }

  .blacklist-item-data.item-blacktype{
    width: 250px;
  }

  .blacklist-item-data.item-remove{
    width: 40px;
  }
  
</style>
