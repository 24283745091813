<template>
  <div>
    <div v-if="loading">
        <div style="position: relative; padding: 200px 0;">
          <div class="thrive-loader"></div>
        </div>
      </div>

    <div v-else>
      <v-row v-if="$store.state.userRoleNumber == 0 && !$store.state.demoMode">
        <v-col cols="6" sd="12" class="pr-5">
          <v-card class="mb-2" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].developer" >
            <v-btn dark @click="copyTextToClipboard(selectedMembership.id)" class="ma-2 my-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="text-transform: none">
              <strong>{{ selectedMembership.id }}</strong>
            </v-btn>
            <div style="display: inline-block" v-if="!selectedMembershipIdCopy" class="pa-2 copy-tag-url" @click="copyTextToClipboard(selectedMembership.id)">
              <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon>
              Kopieër DocId
            </div>
            <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied">DocId gekopieërd</div>
          </v-card>
        </v-col>
        <v-col cols="6" sd="12" class="text-right">
          <v-btn
            fab
            v-if="!editEnabled && $store.state.activeUserRoleNumber == 0 && !$store.state.demoMode"
            dark
            small
            :disabled="selectedMembership.deleted?.deleted"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            depressed
            @click="enableMembershipEdit()"
            >
            <v-icon size="22" darken>mdi-pencil</v-icon>
          </v-btn>
          <div  v-if="editEnabled">
            <v-btn
              dark
              class="mr-2"
              outlined
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
              depressed
              @click="disableMembershipEditModal()"
              >
              {{ $t('general.cancel', $store.state.locale) }}
            </v-btn>

            <v-btn
              v-if="editEnabled"
              :dark="changed"
              :disabled="!changed"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
              @click="saveMembershipEdit()"
              >
              <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale) }} <v-icon right >mdi-chevron-right</v-icon></div>
              <div v-else> {{ $t('general.saved', $store.state.locale) }} <v-icon right >mdi-check</v-icon></div>   
            </v-btn>
          </div>
         
        </v-col>
      </v-row>
      <v-container grid-list-md class="pa-0 ma-0" fluid>
        <v-layout row wrap>
          <v-flex md6 xs12 class="pr-5"> 
            
            <!-- / GENERAL \ -->
            <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].text }"  class="theme-card theme-shadow pa-3 ma-0 pb-4" :class="$store.state.companyTheme" style="width: 100%">
              <v-row align="center">
                <v-col cols="6" sd="6">
                  <h3>{{ $t('leadCampaign.general', $store.state.locale) }}</h3>
                </v-col>
                <v-col v-if="selectedMembership.deleted?.deleted" cols="6" sd="6" class="text-right">
                  <v-chip small block label color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-0 custom-chip-style">
                    {{ $t('general.removed', $store.state.locale) }} 
                  </v-chip>
                </v-col>
              </v-row>

              <v-divider class="ma-0 pa-0 pt-3 mt-3"></v-divider>

              <!-- / Name \ -->
              <v-row align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 1 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="6" sd="6" class="py-1"><strong>{{ $t('general.name', $store.state.locale) }}:</strong></v-col>
                <v-col cols="6" sd="6"  class="py-1">
                  <div v-if="editLevel < 2">{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, selectedMembership.name) }}</div>
                  <div v-if="editLevel > 1">
                    <v-text-field
                      v-for="(name, key, index) in selectedMembership.name"
                      :label="`${$t('general.name', $store.state.locale)} (${$t('general.' + key, $store.state.locale)})`"
                      dense
                      v-model="selectedMembership.name[key]"
                      outlined
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      :class="{'mb-2' : index < Object.keys(selectedMembership.name).length - 1 }"
                      hide-details
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <!-- \ Name / -->

              <!-- / Description \ -->
              <v-row align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 1 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="6" sd="6" class="py-1"><strong>{{ $t('general.description', $store.state.locale) }}:</strong></v-col>
                <v-col cols="6" sd="6" class="py-1">
                  <div v-if="editLevel < 2">{{ selectedMembership.posDescription[$store.state.locale.toLowerCase()] }}</div>
                  <div v-if="editLevel > 1">
                    <v-text-field
                      v-for="(name, key, index) in selectedMembership.posDescription"
                      :label="`${$t('general.name', $store.state.locale)} (${$t('general.' + key, $store.state.locale)})`"
                      dense
                      v-model="selectedMembership.posDescription[key]"
                      outlined
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      :class="{'mb-2' : index < Object.keys(selectedMembership.posDescription).length - 1 }"
                      hide-details
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <!-- \ Description / -->

              <!-- / Branches \ -->
              <v-row align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 1 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="6" sd="6" class="py-1"><strong>{{ $t('general.branches', $store.state.locale) }}:</strong></v-col>
                <v-col cols="6" sd="6" class="py-1">
                  <div v-if="editLevel < 2">
                    <span v-if="selectedMembership.branches.all">{{ $t('products.allBranches', $store.state.locale) }}</span>
                    <span v-else>
                      <v-chip v-for="(branch, index) in selectedMembership.branches.branches" small block label :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"light class="mr-2 my-0 custom-chip-style" >{{ getBranchName(branch) }}</v-chip>
                    </span>
                  </div>
                  <div v-if="editLevel > 1">
                    <div >
                      <v-switch 
                        :label="$t('products.allBranches', $store.state.locale) "
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        v-model="selectedMembership.branches.all"
                        inset
                        @change="resetBranches()"
                        class="pa-0 ma-0 mb-2 ml-1"
                        hide-details
                      ></v-switch>
                    </div>
                    <div>
                      <v-select
                        v-if="!selectedMembership.branches.all"
                        :items="$store.state.companyBranches"
                        v-model="selectedMembership.branches.branches"
                        item-text="name"
                        item-value="id"
                        outlined
                        multiple
                        small-chips
                        dense
                        @change="onFrequencyChange()"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                        hide-details
                        class="mt-0"
                      ></v-select>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <!-- \ Branches / -->

              <!-- / Start \ -->
              <v-row align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 1 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="6" sd="6" class="py-1"><strong>{{ $t('general.start', $store.state.locale) }}:</strong></v-col>
                <v-col cols="6" sd="6" class="py-1">
                  <div v-if="editLevel < 2">{{ membershipStartDate.readable }}</div>
                  <div v-if="editLevel > 1">
                    <v-menu ref="menuStartDate" v-model="menuStartDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          v-model="membershipStartDate.readable"
                          readonly
                          prepend-inner-icon="mdi-calendar"
                          hide-details
                          outlined
                          clearable
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        no-title
                        :min="membershipStartMinDate"
                        v-model="selectedMembership.startDate"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        @input="menuStartDate = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
              <!-- \ Start / -->

              <!-- / End \ -->
              <v-row align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 1 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="6" sd="6" class="py-1"><strong>{{ $t('general.end', $store.state.locale) }}:</strong></v-col>
                <v-col cols="6" sd="6" class="py-1">
                  <div v-if="editLevel < 2">
                    <div v-if="selectedMembership.status.endDate">{{ membershipEndDate.readable }}</div>
                    <div v-else>{{ $t('tasks.noDate', $store.state.locale) }}
                      
                      <v-chip small block label color="#56ca00" text-color="#56ca00" light class="ml-1 ma-0 px-1 custom-chip-style" >{{ $t('memberships.autoRenewShort', $store.state.locale) }}</v-chip>
                    </div>

                  </div>
                  <div v-if="editLevel > 1">
                    <v-menu ref="menuEndDate" v-model="menuEndDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          v-model="membershipEndDate.readable"
                          readonly
                          prepend-inner-icon="mdi-calendar"
                          hide-details
                          outlined
                          clearable
                          @click:clear="selectedMembership.status.endDate = null"
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        ref="picker"
                        no-title
                        :min="membershipStartMinDate"
                        v-model="selectedMembership.status.endDate"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        @input="menuEndDate = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
              <!-- \ End / -->

              <!-- / Duration \ -->
              <v-row align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 1 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="6" sd="6" class="py-1"><strong>{{ $t('payment.duration', $store.state.locale) }}:</strong></v-col>
                <v-col cols="6" sd="6" class="py-1">
                  <div v-if="editLevel < 2">{{ selectedMembership.duration }} {{  durationSuffix }}</div>
                  <div v-if="editLevel > 1">
                    <v-text-field
                      dense
                      number
                      v-model.number="selectedMembership.duration"
                      outlined
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      class="mb-2"
                      :suffix="durationSuffix"
                      hide-details
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <!-- \ Duration / -->

              <!-- / Periode \ -->
              <v-row align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 1 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="6" sd="6" class="py-1"><strong>{{ $t('general.period', $store.state.locale) }}:</strong></v-col>
                <v-col cols="6" sd="6" class="py-1">
                  <div v-if="editLevel < 2">{{  $t('memberships.' + selectedMembership.paymentPeriod , $store.state.locale)}}</div>
                  <div v-if="editLevel > 1">
                    <v-select
                      :items="frequencyTypes"
                      v-model="selectedMembership.paymentPeriod"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      @change="onFrequencyChange()"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      hide-details
                      class="mt-0"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
              <!-- \ Periode / -->

              <!-- / Limit Number \ -->
              <v-row align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 1 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="6" sd="6" class="py-1"><strong>{{ $t('memberships.limitNumber', $store.state.locale) }}:</strong></v-col>
                <v-col cols="6" sd="6" class="py-1">
                  <div v-if="editLevel < 2" style="text-transform: lowercase">{{selectedMembership.usageRules.limitNumber }}x {{ limitType }}</div>
                  <div v-if="editLevel > 1">
                    <v-text-field
                      dense
                      v-model="selectedMembership.usageRules.limitNumber"
                      outlined
                      @input="calculateBrutoPrice()"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      class="mb-2"
                      hide-details
                      suffix="x"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <!-- \ Limit Number / -->

              <!-- / Limit Type  \ -->
              <v-row v-if="editLevel > 1" align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 1 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="6" sd="6" class="py-1"><strong>{{ $t('memberships.limitType', $store.state.locale) }}:</strong></v-col>
                <v-col cols="6" sd="6" class="py-1">
                  <v-select
                    :items="limitTypes"
                    v-model="selectedMembership.usageRules.limitType"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    @input="calculateBrutoPrice()"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    hide-details
                    class="mt-0"
                  ></v-select>
                </v-col>
              </v-row>
              <!-- \ Limit Type / -->

              

              <!-- / Payment Type \ -->
              <v-row align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 1 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="6" sd="6" class="py-1"><strong>{{ $t('memberships.paymentMethod', $store.state.locale) }}:</strong></v-col>
                <v-col cols="6" sd="6" class="py-1">
                  <div v-if="editLevel < 2">{{ getStatusType(selectedMembership.status.type) }}</div>
                  <div v-if="editLevel > 1">
                    <v-select
                      :items="membershipTypes"
                      v-model="selectedMembership.status.type"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      hide-details
                      class="mt-0"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
              <!-- \ Payment Type / -->

              <!-- / Payment Process \ -->
              <v-row v-if="selectedMembership.status.type != 'single'" align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 1 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="6" sd="6" class="py-1"><strong>{{ $t('memberships.paymentProcess', $store.state.locale) }}:</strong></v-col>
                <v-col cols="6" sd="6"  class="py-1">
                  <div v-if="editLevel < 2">{{ getStatusType(selectedMembership.status.type) }}</div>
                  <div v-if="editLevel > 1">
                    <v-select
                      :items="processingCollection"
                      v-model="selectedMembership.paymentDay"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      hide-details
                      class="mt-0"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
              <!-- \ Payment Process / -->

              <!-- / Auto Renew \ -->
              <!-- <v-row align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 1 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="6" sd="6" class="py-1"><strong>{{ $t('memberships.autoRenew', $store.state.locale) }}:</strong></v-col>
                <v-col cols="6" sd="6" class="py-1">
                  <div v-if="editLevel < 2">
                    <v-chip small block label :color="getAutoRenewType.color" :text-color="getAutoRenewType.color" light class="mr-2 my-0 custom-chip-style" >{{ getAutoRenewType.name }}</v-chip>
                    </div>
                  <div v-if="editLevel > 1" class="text-right">
                    <v-switch 
                      style="position: relative; width: 50px; float: right"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      v-model="selectedMembership.autoRenew"
                      inset
                      class="pa-0 ma-0 mt-1"
                      hide-details
                    ></v-switch>
                  </div>
                </v-col>
              </v-row> -->
              <!-- \ Auto Renew / -->

            </v-card>
            <!-- \ GENERAL / -->

            <!-- / PRICE \ -->
            <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-3 mt-5 obvious" :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].text }" :class="$store.state.companyTheme" style="width: 100%">
              <h3 :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary + '!important'}">{{ $t('general.price', $store.state.locale) }}</h3>
              <v-divider class="pt-3 mt-3"></v-divider>

              <p  v-if="selectedMembership.usageRules.limitType == 'total'">{{ $t('memberships.priceFor', $store.state.locale) }} {{ $t('memberships.times', $store.state.locale)  }}</p>
              <p v-else>{{ $t('memberships.priceFor', $store.state.locale) }} {{ paymentPeriode }}</p>

              <!-- / VAT Percentage \ -->
              <v-row v-if="editLevel > 1" align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 1 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="4" sd="4" class="py-1"><strong>{{ $t('general.vat', $store.state.locale) }}:</strong></v-col>
                <v-col cols="8" sd="8" class="py-1">
                  <div>
                    <v-text-field
                      style="width: 100%"
                      :value="getVatPercentage(selectedMembership.vat)"
                      :readonly="!editEnabled"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      suffix="%"
                      dense
                      disabled
                      outlined
                      hide-details
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <!-- \ VAT Percentage  / -->

              <!-- / Price / Amount Netto \ -->
              <v-row v-if="editLevel > 1" align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 1 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="4" sd="4" class="py-1"><strong>{{ $t('treatments.nettoPrice', $store.state.locale) }}:</strong></v-col>
                <v-col cols="8" sd="8" class="py-1">
                  <div>
                    <v-text-field
                      dense
                      number
                      prefix="€"
                      v-model.number="selectedMembership.amount"
                      outlined
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      class="mb-1"
                      @input="calculateBrutoPrice()"
                      hide-details
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <!-- \ Price / Amount Netto / -->

              <!-- / Price / Amount Bruto \ -->
              <v-row v-if="editLevel > 1" align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 1 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="4" sd="4" class="py-1"><strong>{{ $t('treatments.grossPrice', $store.state.locale) }}:</strong></v-col>
                <v-col cols="8" sd="8" class="py-1">
                  <div>
                    <v-text-field
                      dense
                      number
                      prefix="€"
                      v-model.number="selectedMembership.amountBruto"
                      outlined
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      class="mb-1"
                      hide-details
                      @input="calculateNettoPrice()"
                    ></v-text-field>
                  </div>
                </v-col>
              </v-row>
              <!-- \ Price / Amount Bruto / -->

              <v-container class="pa-0 mt-3" v-if="editLevel == 0">
                <v-layout row>
                  <v-flex md4>
                    <v-text-field
                      style="width: 100%"
                      :label="$t('general.vat', $store.state.locale)"
                      :value="getVatPercentage(selectedMembership.vat)"
                      :readonly="!editEnabled"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      suffix="%"
                      dense
                      outlined
                      hide-details
                    ></v-text-field>
                  </v-flex>

                  <v-flex md4>
                    <v-text-field
                      style="width: 100%"
                      :label="$t('treatments.nettoPrice', $store.state.locale)"
                      :value="selectedMembership.amount"
                      :readonly="!editEnabled"
                      prefix="€"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  
                  <v-flex md4>
                    <v-text-field
                      style="width: 100%"
                      :label="$t('treatments.grossPrice', $store.state.locale)"
                      :value="selectedMembership.amountBruto"
                      :readonly="!editEnabled"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      outlined
                      dense
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      type="number"
                      prefix="€"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>

              <v-container class="pa-0 mt-3">
                <v-layout style="margin: 10px 0 5px 0" v-if="!selectedMembership.periodicPaymentDiscount.active">
                  <v-flex md4>
                    <p class="ma-0" style="font-size: 14px;">
                      <strong>{{ $t('memberships.totalCosts', $store.state.locale) }}</strong><br>
   
                      <div v-if="selectedMembership.usageRules.limitType == 'total'">
                        {{ selectedMembership.usageRules.limitNumber }}x
                      </div>
                      <div v-else>
                        {{ selectedMembership.duration }} {{ durationSuffix }}
                      </div>
                    </p>
                  </v-flex>
                  <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;">&euro; {{ price.netto }} <span style="font-size: 12px; font-style: italic">{{ $t('general.vatExcluded', $store.state.locale) }}</span></p></v-flex>
                  <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;"><strong>&euro; {{ price.bruto }}</strong>  <span style="font-size: 12px; font-style: italic">{{ $t('general.vatIncluded', $store.state.locale) }}</span></p></v-flex>
                </v-layout>

                <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card pa-3 mt-3" :class="$store.state.companyTheme" style="width: 100%"  v-if="selectedMembership.singlePaymentDiscount.active">
                  <h3 class="pb-3">{{ $t('memberships.priceSingle', $store.state.locale) }}</h3>
                  <v-layout>
                    <v-flex md4>
                      <p class="ma-0" style="font-size: 14px;">
                        <strong>{{ $t('memberships.totalDiscount', $store.state.locale) }}</strong>
                      </p>
                    </v-flex>
                    <v-flex md4><p class="ma-0" style="font-size: 14px;">&euro; {{ price.singlePaymentDiscount.netto }}</p></v-flex>
                    <v-flex md4><p class="ma-0" style="font-size: 14px;"><strong>&euro; {{ price.singlePaymentDiscount.bruto }}</strong></p></v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex md4>
                      <p class="ma-0" style="font-size: 14px;">
                        <strong>{{ $t('memberships.totalCosts', $store.state.locale) }}</strong><br>
                        {{ selectedMembership.duration }} {{ durationSuffix}}
                      </p>
                    </v-flex>
                    <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;">&euro; {{ price.singlePaymentNetto }} <span style="font-size: 12px; font-style: italic">{{ $t('general.vatExcluded', $store.state.locale) }}</span></p></v-flex>
                    <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;"><strong>&euro; {{ price.singlePaymentBruto }}</strong> <span style="font-size: 12px; font-style: italic">{{ $t('general.vatIncluded', $store.state.locale) }}</span></p></v-flex>
                  </v-layout>
                </v-card>

                <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card pa-3 mt-4" :class="$store.state.companyTheme" style="width: 100%"  v-if="selectedMembership.periodicPaymentDiscount.active">
                  <h3 class="pb-3">{{ $t('memberships.pricePeriodic', $store.state.locale) }}</h3>
                  <v-layout>
                    <v-flex md4>
                      <p class="ma-0" style="font-size: 14px;">
                        <strong>{{ $t('memberships.discountFor', $store.state.locale) }}{{ paymentPeriode }}</strong>
                      </p>
                    </v-flex>
                    <v-flex md4><p class="ma-0" style="font-size: 14px;">&euro; {{ price.periodicPaymentDiscount.periodic.netto }}</p></v-flex>
                    <v-flex md4><p class="ma-0" style="font-size: 14px;"><strong>&euro; {{ price.periodicPaymentDiscount.periodic.bruto }}</strong></p></v-flex>
                  </v-layout>

                  <v-layout>
                    <v-flex md4>
                      <p class="ma-0" style="font-size: 14px;">
                        <strong>{{ $t('memberships.priceFor', $store.state.locale) }} {{ paymentPeriode }}</strong>
                      </p>
                    </v-flex>
                    <v-flex md4><p class="ma-0" style="font-size: 14px;">&euro; {{ (selectedMembership.amount - price.periodicPaymentDiscount.periodic.netto).toFixed(2) }}</p></v-flex>
                    <v-flex md4><p class="ma-0" style="font-size: 14px;"><strong>&euro; {{ (selectedMembership.totalPrice - price.periodicPaymentDiscount.periodic.bruto).toFixed(2) }}</strong></p></v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex md4>
                      <p class="ma-0" style="font-size: 14px;">
                        <strong>{{ $t('memberships.totalCosts', $store.state.locale) }}</strong><br>
                        {{ selectedMembership.duration }} {{ durationSuffix}}
                      </p>
                    </v-flex>
                    <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;">&euro; {{ price.periodicPaymentNetto }} <span style="font-size: 12px; font-style: italic">{{ $t('general.vatExcluded', $store.state.locale) }}</span></p></v-flex>
                    <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;"><strong>&euro; {{ price.periodicPaymentBruto }}</strong> <span style="font-size: 12px; font-style: italic">{{ $t('general.vatIncluded', $store.state.locale) }}</span></p></v-flex>
                  </v-layout>
                </v-card>

              </v-container>
            </v-card>
            <!-- \ PRICE / -->

            <!-- / DISCOUNTS \ --> 
            <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].text }" class="theme-card theme-shadow pa-3 mt-5 " :class="$store.state.companyTheme" style="width: 100%">
              <h3>{{ $t('memberships.discounts', $store.state.locale) }}</h3>
              <v-divider class="ma-0 pa-0 pt-3 mt-3"></v-divider>

              <!-- / Membership Discounts \ -->
              <v-row align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 2 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="4" sd="4" class="py-1"><strong>{{ $t('memberships.membershipDiscounts', $store.state.locale) }}:</strong></v-col>
                <v-col cols="8" sd="8" class="py-1">
                  <div v-if="editLevel < 2">
                    <div class="mt-1" v-if="selectedMembership.separatedDiscount.discountsInsideSubscriptionDays && selectedMembership.separatedDiscount.discountsInsideSubscriptionDays.length > 0">
                      <v-chip small label v-for="(discount, index) in selectedMembership.separatedDiscount.discountsInsideSubscriptionDays" :key="index" :color="$themes[$store.state.companyTheme][$store.state.themeModus][getDiscountName(discount).color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][getDiscountName(discount).color]" light class="mr-2 my-1 custom-chip-style" >{{ getDiscountName(discount).name }}</v-chip>
                    </div>
                  </div>
                  <div v-if="editLevel > 1">
                    <v-select
                      :items="membershipDiscounts"
                      v-model="selectedMembership.separatedDiscount.discountsInsideSubscriptionDays"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      chips
                      small-chips
                      multiple
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      hide-details
                      class="mt-0"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
              <!-- \ Membership Discounts  / -->

              <!-- / Extra Discounts \ -->
              <v-row align="center" style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 2 }]" :style="[{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">
                <v-col cols="4" sd="4" class="py-1"><strong>{{ $t('memberships.extraDiscounts', $store.state.locale) }}:</strong></v-col>
                <v-col cols="8" sd="8" class="py-1">
                  <div v-if="editLevel < 2">
                    <div class="mt-1" v-if="selectedMembership.separatedDiscount.discountsOutsideSubscriptionDays && selectedMembership.separatedDiscount.discountsOutsideSubscriptionDays.length > 0">
                      <v-chip small label v-for="(discount, index) in selectedMembership.separatedDiscount.discountsOutsideSubscriptionDays" :key="index" :color="$themes[$store.state.companyTheme][$store.state.themeModus][getDiscountName(discount).color]" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus][getDiscountName(discount).color]" light class="mr-2 my-1 custom-chip-style" >{{ getDiscountName(discount).name }}</v-chip>
                    </div>
                  </div>
                  <div v-if="editLevel > 1">
                    <v-select
                      :items="extraDiscounts"
                      v-model="selectedMembership.separatedDiscount.discountsOutsideSubscriptionDays"
                      item-text="name"
                      item-value="id"
                      outlined
                      multiple
                      chips
                      @change="checkDiscounts()"
                      small-chips
                      dense
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      hide-details
                      class="mt-0"
                    ></v-select>
                  </div>
                </v-col>
              </v-row>
              <!-- \ Extra Discounts  / -->

            </v-card>
            <!-- \ DISCOUNTS / --> 

            <!-- / TRIAL PERIOD \ --> 
            <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-3 mt-5" :class="$store.state.companyTheme" style="width: 100%"  v-if="selectedMembership.paymentTypes == 'periodic'">
                <h3>Proefperiode</h3>
                <v-divider class="py-3 my-3"></v-divider>
                <v-switch 
                  label="Proefperiode"
                  :value="selectedMembership.freePeriod.active"
                  disabled
                  hide-details
                  class="mb-2"
                ></v-switch>

                <div v-if="selectedMembership.freePeriod.active">

                  <v-text-field
                    label="Type proefperiode"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :value="selectedMembership.freePeriod.type"
                    disabled
                    outlined
                    hide-details
                  ></v-text-field>

                  <v-row v-if="selectedMembership.freePeriod.type == 'general'" class="ma-0">
                    <v-flex md12>
                      <v-alert
                        dense
                        text
                        style="width: 100%;"
                        class="mt-2 ma-0"
                        outlined
                        type="info" 
                        prominent 
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        >
                        Proefperiode geldt voor <strong>iedereen</strong> die het abonnement afsluit.
                      </v-alert>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="Proefperiode"
                        type="number"
                        :value="selectedMembership.freePeriod.number"
                        disabled
                        outlined
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                        :suffix="durationSuffix"
                        class="mt-4"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-row>

                  <v-row  v-else class="ma-0">
                    <v-flex md12>
                      <v-alert
                        dense
                        text
                        style="width: 100%;"
                        class="mt-2 ma-0"
                        outlined
                        type="info" 
                        prominent 
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        >
                        Proefperiode kan bij het versturen <strong>per cliënt</strong> ingesteld worden. 
                      </v-alert>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="Maximale proefperiode"
                        type="number"
                        :value="selectedMembership.freePeriod.number"
                        disabled
                        outlined
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                        :suffix="durationSuffix"
                        class="mt-4"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                  </v-row>
                </div>
            </v-card>
            <!-- \ TRIAL PERIOD / --> 

            <!-- / VALIDITY \ --> 
            <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pt-3 mt-5" :class="$store.state.companyTheme" style="width: 100%" v-if="selectedMembership.days">
              <h3 class="pt-0 pb-2 px-3">{{ $t("memberships.validation", $store.state.locale) }}</h3>

              <div style="border-radius: 5px;" :class="[{'ma-1': editLevel > 1 }, {'py-2': editLevel > 2 }]" :style="[{'color': $themes[$store.state.companyTheme][$store.state.themeModus].text },{'border': editLevel == 0 ? '' : '2px solid' },{'backgroundColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].background }, {'borderColor': editLevel == 0 ? '' : $themes[$store.state.companyTheme][$store.state.themeModus].primary }]">

                <v-row v-if="editLevel > 1" align="center" class="pa-3">
                  <v-col cols="4" sd="4" class="py-1"> <strong>{{ $t("general.day", $store.state.locale) }}</strong></v-col>
                  <v-col cols="2" sd="2" class="py-1"> <strong>{{ $t("general.active", $store.state.locale) }}</strong></v-col>
                  <v-col cols="2" sd="2" class="py-1"> <strong>{{ $t("general.allDay", $store.state.locale) }}</strong></v-col>
                  <v-col cols="2" sd="2" class="py-1"> <strong>{{ $t("general.start", $store.state.locale) }}</strong></v-col>
                  <v-col cols="2" sd="2" class="py-1"> <strong>{{ $t("general.end", $store.state.locale) }}</strong></v-col>
                </v-row>

           
                <!-- / Monday \ -->
                <v-divider class="ma-0 pa-0"></v-divider>

                <v-row align="center" class="pa-3" style="height: 74px;">
                  <v-col cols="4" sd="4" class="py-1"><strong>{{ $t('general.monday', $store.state.locale) }}:</strong></v-col>
                  <v-col cols="4" sd="4" class="py-1"v-if="editLevel == 0">
                    <v-chip small block label v-if="selectedMembership.days.Mon.active && selectedMembership.days.Mon.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.dayValid', $store.state.locale) }}</v-chip>
                    <v-chip small block label v-if="selectedMembership.days.Mon.active && !selectedMembership.days.Mon.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" ></v-chip>
                    <v-chip small block label v-if="!selectedMembership.days.Mon.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.notValid', $store.state.locale) }}</v-chip>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0 py-1" v-model="selectedMembership.days.Mon.active" @change="resetDayTimes('Mon', 'all')" hide-details></v-switch>

                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!selectedMembership.days.Mon.active"  class="ma-0 pa-0 py-1" v-model="selectedMembership.days.Mon.allDay" @change="resetDayTimes('Mon')" hide-details></v-switch>
                  </v-col>
                  <v-col cols="4" sd="4" class="py-1" v-if="editLevel == 0">
                    <p style="font-size: 13px" v-if="selectedMembership.days.Mon.active && !selectedMembership.days.Mon.allDay" class="ma-0 pa-0 mt-2">{{ $t('memberships.validFrom', $store.state.locale) }} {{ selectedMembership.days.Mon.start }}u {{ $t('general.till', $store.state.locale) }} {{ selectedMembership.days.Mon.end }}u </p>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="selectedMembership.days.Mon.active && !selectedMembership.days.Mon.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="selectedMembership.days.Mon.start" ></v-text-field>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="selectedMembership.days.Mon.active && !selectedMembership.days.Mon.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="selectedMembership.days.Mon.end" ></v-text-field>
                  </v-col>
                </v-row>
                <!-- \ Monday / -->

                <!-- / Tuesday \ -->
                <v-divider class="ma-0 pa-0"></v-divider>

                <v-row align="center" class="pa-3" style="height: 74px;">
                  <v-col cols="4" sd="4" class="py-1"><strong>{{ $t('general.tuesday', $store.state.locale) }}:</strong></v-col>
                  <v-col cols="4" sd="4" class="py-1"v-if="editLevel == 0">
                    <v-chip small block label v-if="selectedMembership.days.Tue.active && selectedMembership.days.Tue.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.dayValid', $store.state.locale) }}</v-chip>
                    <v-chip small block label v-if="selectedMembership.days.Tue.active && !selectedMembership.days.Tue.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" ></v-chip>
                    <v-chip small block label v-if="!selectedMembership.days.Tue.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.notValid', $store.state.locale) }}</v-chip>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0 py-1" v-model="selectedMembership.days.Tue.active" @change="resetDayTimes('Tue', 'all')" hide-details></v-switch>

                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!selectedMembership.days.Tue.active"  class="ma-0 pa-0 py-1" v-model="selectedMembership.days.Tue.allDay" @change="resetDayTimes('Tue')" hide-details></v-switch>
                  </v-col>
                  <v-col cols="4" sd="4" class="py-1" v-if="editLevel == 0">
                    <p style="font-size: 13px" v-if="selectedMembership.days.Tue.active && !selectedMembership.days.Tue.allDay" class="ma-0 pa-0 mt-2">{{ $t('memberships.validFrom', $store.state.locale) }} {{ selectedMembership.days.Tue.start }}u {{ $t('general.till', $store.state.locale) }} {{ selectedMembership.days.Tue.end }}u </p>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="selectedMembership.days.Tue.active && !selectedMembership.days.Tue.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="selectedMembership.days.Tue.start" ></v-text-field>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="selectedMembership.days.Tue.active && !selectedMembership.days.Tue.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="selectedMembership.days.Tue.end" ></v-text-field>
                  </v-col>
                </v-row>
                <!-- \ Tuesday / -->

                <!-- / Wednesday \ -->
                <v-divider class="ma-0 pa-0"></v-divider>

                <v-row align="center" class="pa-3" style="height: 74px;">
                  <v-col cols="4" sd="4" class="py-1"><strong>{{ $t('general.wednesday', $store.state.locale) }}:</strong></v-col>
                  <v-col cols="4" sd="4" class="py-1"v-if="editLevel == 0">
                    <v-chip small block label v-if="selectedMembership.days.Wed.active && selectedMembership.days.Wed.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.dayValid', $store.state.locale) }}</v-chip>
                    <v-chip small block label v-if="selectedMembership.days.Wed.active && !selectedMembership.days.Wed.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" ></v-chip>
                    <v-chip small block label v-if="!selectedMembership.days.Wed.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.notValid', $store.state.locale) }}</v-chip>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0 py-1" v-model="selectedMembership.days.Wed.active" @change="resetDayTimes('Wed', 'all')" hide-details></v-switch>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!selectedMembership.days.Wed.active"  class="ma-0 pa-0 py-1" v-model="selectedMembership.days.Wed.allDay" @change="resetDayTimes('Wed')" hide-details></v-switch>
                  </v-col>
                  <v-col cols="4" sd="4" class="py-1" v-if="editLevel == 0">
                    <p style="font-size: 13px" v-if="selectedMembership.days.Wed.active && !selectedMembership.days.Wed.allDay" class="ma-0 pa-0 mt-2">{{ $t('memberships.validFrom', $store.state.locale) }} {{ selectedMembership.days.Wed.start }}u {{ $t('general.till', $store.state.locale) }} {{ selectedMembership.days.Wed.end }}u </p>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="selectedMembership.days.Wed.active && !selectedMembership.days.Wed.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="selectedMembership.days.Wed.start" ></v-text-field>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="selectedMembership.days.Wed.active && !selectedMembership.days.Wed.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="selectedMembership.days.Wed.end" ></v-text-field>
                  </v-col>
                </v-row>
                <!-- \ Wednesday / -->

                <!-- / Thursday \ -->
                <v-divider class="ma-0 pa-0"></v-divider>

                <v-row align="center" class="pa-3" style="height: 74px;">
                  <v-col cols="4" sd="4" class="py-1"><strong>{{ $t('general.wednesday', $store.state.locale) }}:</strong></v-col>
                  <v-col cols="4" sd="4" class="py-1"v-if="editLevel == 0">
                    <v-chip small block label v-if="selectedMembership.days.Thu.active && selectedMembership.days.Thu.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.dayValid', $store.state.locale) }}</v-chip>
                    <v-chip small block label v-if="selectedMembership.days.Thu.active && !selectedMembership.days.Thu.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" ></v-chip>
                    <v-chip small block label v-if="!selectedMembership.days.Thu.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.notValid', $store.state.locale) }}</v-chip>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0 py-1" v-model="selectedMembership.days.Thu.active" @change="resetDayTimes('Thu', 'all')" hide-details></v-switch>

                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!selectedMembership.days.Thu.active"  class="ma-0 pa-0 py-1" v-model="selectedMembership.days.Thu.allDay" @change="resetDayTimes('Thu')" hide-details></v-switch>
                  </v-col>
                  <v-col cols="4" sd="4" class="py-1" v-if="editLevel == 0">
                    <p style="font-size: 13px" v-if="selectedMembership.days.Thu.active && !selectedMembership.days.Thu.allDay" class="ma-0 pa-0 mt-2">{{ $t('memberships.validFrom', $store.state.locale) }} {{ selectedMembership.days.Thu.start }}u {{ $t('general.till', $store.state.locale) }} {{ selectedMembership.days.Thu.end }}u </p>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="selectedMembership.days.Thu.active && !selectedMembership.days.Thu.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="selectedMembership.days.Thu.start" ></v-text-field>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="selectedMembership.days.Thu.active && !selectedMembership.days.Thu.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="selectedMembership.days.Thu.end" ></v-text-field>
                  </v-col>
                </v-row>
                <!-- \ Thursday / -->

                <!-- / Friday \ -->
                <v-divider class="ma-0 pa-0"></v-divider>

                <v-row align="center" class="pa-3" style="height: 74px;">
                  <v-col cols="4" sd="4" class="py-1"><strong>{{ $t('general.friday', $store.state.locale) }}:</strong></v-col>
                  <v-col cols="4" sd="4" class="py-1"v-if="editLevel == 0">
                    <v-chip small block label v-if="selectedMembership.days.Fri.active && selectedMembership.days.Fri.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.dayValid', $store.state.locale) }}</v-chip>
                    <v-chip small block label v-if="selectedMembership.days.Fri.active && !selectedMembership.days.Fri.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" ></v-chip>
                    <v-chip small block label v-if="!selectedMembership.days.Fri.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.notValid', $store.state.locale) }}</v-chip>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0 py-1" v-model="selectedMembership.days.Fri.active" @change="resetDayTimes('Fri', 'all')" hide-details></v-switch>

                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!selectedMembership.days.Fri.active"  class="ma-0 pa-0 py-1" v-model="selectedMembership.days.Fri.allDay" @change="resetDayTimes('Fri')" hide-details></v-switch>
                  </v-col>
                  <v-col cols="4" sd="4" class="py-1" v-if="editLevel == 0">
                    <p style="font-size: 13px" v-if="selectedMembership.days.Fri.active && !selectedMembership.days.Fri.allDay" class="ma-0 pa-0 mt-2">{{ $t('memberships.validFrom', $store.state.locale) }} {{ selectedMembership.days.Fri.start }}u {{ $t('general.till', $store.state.locale) }} {{ selectedMembership.days.Fri.end }}u </p>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="selectedMembership.days.Fri.active && !selectedMembership.days.Fri.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="selectedMembership.days.Fri.start" ></v-text-field>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="selectedMembership.days.Fri.active && !selectedMembership.days.Fri.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="selectedMembership.days.Fri.end" ></v-text-field>
                  </v-col>
                </v-row>
                <!-- \ Friday / -->

                <!-- / Saturday \ -->
                <v-divider class="ma-0 pa-0"></v-divider>

                <v-row align="center" class="pa-3" style="height: 74px;">
                  <v-col cols="4" sd="4" class="py-1"><strong>{{ $t('general.saturday', $store.state.locale) }}:</strong></v-col>
                  <v-col cols="4" sd="4" class="py-1"v-if="editLevel == 0">
                    <v-chip small block label v-if="selectedMembership.days.Sat.active && selectedMembership.days.Sat.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.dayValid', $store.state.locale) }}</v-chip>
                    <v-chip small block label v-if="selectedMembership.days.Sat.active && !selectedMembership.days.Sat.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" ></v-chip>
                    <v-chip small block label v-if="!selectedMembership.days.Sat.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.notValid', $store.state.locale) }}</v-chip>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0 py-1" v-model="selectedMembership.days.Sat.active" @change="resetDayTimes('Sat', 'all')" hide-details></v-switch>

                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!selectedMembership.days.Sat.active"  class="ma-0 pa-0 py-1" v-model="selectedMembership.days.Sat.allDay" @change="resetDayTimes('Sat')" hide-details></v-switch>
                  </v-col>
                  <v-col cols="4" sd="4" class="py-1" v-if="editLevel == 0">
                    <p style="font-size: 13px" v-if="selectedMembership.days.Sat.active && !selectedMembership.days.Sat.allDay" class="ma-0 pa-0 mt-2">{{ $t('memberships.validFrom', $store.state.locale) }} {{ selectedMembership.days.Sat.start }}u {{ $t('general.till', $store.state.locale) }} {{ selectedMembership.days.Sat.end }}u </p>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="selectedMembership.days.Sat.active && !selectedMembership.days.Sat.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="selectedMembership.days.Sat.start" ></v-text-field>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="selectedMembership.days.Sat.active && !selectedMembership.days.Sat.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="selectedMembership.days.Sat.end" ></v-text-field>
                  </v-col>
                </v-row>
                <!-- \ Saturday / -->

                <!-- / Sunday \ -->
                <v-divider class="ma-0 pa-0"></v-divider>

                <v-row align="center" class="pa-3" style="height: 74px;">
                  <v-col cols="4" sd="4" class="py-1"><strong>{{ $t('general.sunday', $store.state.locale) }}:</strong></v-col>
                  <v-col cols="4" sd="4" class="py-1"v-if="editLevel == 0">
                    <v-chip small block label v-if="selectedMembership.days.Sun.active && selectedMembership.days.Sun.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.dayValid', $store.state.locale) }}</v-chip>
                    <v-chip small block label v-if="selectedMembership.days.Sun.active && !selectedMembership.days.Sun.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" ></v-chip>
                    <v-chip small block label v-if="!selectedMembership.days.Sun.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.notValid', $store.state.locale) }}</v-chip>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0 py-1" v-model="selectedMembership.days.Sun.active" @change="resetDayTimes('Sun', 'all')" hide-details></v-switch>

                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!selectedMembership.days.Sun.active"  class="ma-0 pa-0 py-1" v-model="selectedMembership.days.Sun.allDay" @change="resetDayTimes('Sun')" hide-details></v-switch>
                  </v-col>
                  <v-col cols="4" sd="4" class="py-1" v-if="editLevel == 0">
                    <p style="font-size: 13px" v-if="selectedMembership.days.Sun.active && !selectedMembership.days.Sun.allDay" class="ma-0 pa-0 mt-2">{{ $t('memberships.validFrom', $store.state.locale) }} {{ selectedMembership.days.Sun.start }}u {{ $t('general.till', $store.state.locale) }} {{ selectedMembership.days.Sun.end }}u </p>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="selectedMembership.days.Sun.active && !selectedMembership.days.Sun.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="selectedMembership.days.Sun.start" ></v-text-field>
                  </v-col>
                  <v-col cols="2" sd="2" class="py-1" v-if="editLevel > 1">
                    <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="selectedMembership.days.Sun.active && !selectedMembership.days.Sun.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="selectedMembership.days.Sun.end" ></v-text-field>
                  </v-col>
                </v-row>
                <!-- \ Sunday / -->
              </div>
              
            </v-card>
            <!-- \ VALIDITY / --> 
          </v-flex>

          <v-flex md6 xs12 v-if="selectedMembership.usage">
            <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-3 pb-0" :class="$store.state.companyTheme" style="width: 100%">
              <h3 :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"  class="client-overview-subtitle pt-0 pb-1">{{ $t('payment.usage', $store.state.locale) }} </h3>
              <p v-if="selectedMembership.usage.length > 0">{{ $t('memberships.membershipIs', $store.state.locale) }} {{ selectedMembership.usage.length }}x {{ $t('memberships.membershipUsed', $store.state.locale) }}.</p>
              <v-divider class="pt-3 mt-3"></v-divider>

              <div v-if="selectedMembership.usage && selectedMembership.usage.length > 0">
                <v-timeline class="pa-0 custom-dense-timeline" dense>
                  <v-timeline-item 
                    v-for="(usage, index) in selectedMembership.usage.slice().reverse()" :key="index" 
                    small 
                    right 
                    fill-dot
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus][usageColor(usage)]" 
                    class="py-4"
                    >

                    <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus][usageColor(usage)]" class="custom-card-style" :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus][usageColor(usage)] + '!important'}" elevation="0" >
                      <v-card-title class="py-0 px-2">
                        <v-list two-line class="pa-0 ma-0" style="width: 100%"   disabled>
                          <v-list-item class="pa-0 ma-0">
                            <v-list-item-action class="ma-0 mr-2">
                              <v-icon v-if="usage.override" :color="$themes[$store.state.companyTheme][$store.state.themeModus][usageColor(usage)]">mdi-lightning-bolt</v-icon>
                              <v-icon v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus][usageColor(usage)]">mdi-check-bold</v-icon>
                            </v-list-item-action>
                            <v-list-item-content class="pa-0 ma-0" :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus][usageColor(usage)] + '!important'}">
                              <v-list-item-title style="font-weight: bold">
                                {{ usage.date | moment("dddd D MMMM YYYY") }}
                              </v-list-item-title>
                              <v-list-item-subtitle v-if="usage.override" :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus][usageColor(usage)] + '!important'}">
                                <span style="font-size: 12px; line-height: 14px">{{ $t('memberships.forcedUse', $store.state.locale) }}</span>
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="usage.receiptId">
                              <v-btn icon>
                                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus][usageColor(usage)]">mdi-receipt</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card-title>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </div>
              <div v-else class="py-4 text-center">

                <v-avatar 
                  size="76"
                  light
                  class="ma-4 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-clipboard-outline</v-icon>
                </v-avatar>
                
                <div class="pb-4">{{ $t('memberships.notUsed', $store.state.locale) }}</div>
              </div>

            </v-card>
          </v-flex>
        </v-layout>
      </v-container>

      <!-- / Warning Dialog \ -->
      <v-dialog v-model="disableMembershipEditDialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
        <v-card>
          <v-card-text class="text-center ma-0 pt-4">
            <v-avatar 
              size="56"
              light
              class="ma-4 custom-avatar-style" 
              rounded="lg"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
              >
              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-alert</v-icon>
            </v-avatar>
            <h3 class="mb-4"> {{ $t('general.dontSaveChanges', $store.state.locale) }}</h3> 
            <p class="ma-0"> {{ $t('general.unaveChanges', $store.state.locale) }}</p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn outlined depressed  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="disableMembershipEditDialog = false">
                    {{ $t('general.cancel', $store.state.locale) }}
                  </v-btn>
                </v-flex>
                <v-flex xs12 md6 class="text-right">
                  <v-btn dark :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="disableMembershipEdit()">
                    {{ $t('general.continue', $store.state.locale) }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- \ Warning Dialog / -->

    </div>  
    
  </div>
</template>

<script>
  import '@firebase/firestore';
  import db from "../firebase/init";
  import Moment from "moment";
  import { extendMoment } from "moment-range";
  import { bus } from "../main";

  const moment = extendMoment(Moment);

  export default {
    name: "MembershipsInformation",
    props:{
      source: String
    },

    data(){
      return{
        menuStartDate: false,
        menuEndDate: false,
        selectedMembershipIdCopy: false,
        overview: true,
        memberships: new Array(),
        membershipsPerCompany:new Array(),
        loading: true,
        search: null,
        membershipSearch: null,
        clientsFetched:false,
        newSubscription: {
          dialog: false,
          feedback: null,
          startDate: null,
          subscription: null,
          newclient: false,
        },
        editEnabled: false,
        editLevel: 0,
        selectedMembership: {
          name: new Object(),
          posDescription: new Object(),
          periodicPaymentDiscount: new Object(),
          singlePaymentDiscount: new Object(),
          separatedDiscount: new Object(),
        },
        disableMembershipEditDialog: false,
        selectedMembershipOriginal: new Object(),
        customerDialog: false,
        genderSelect: 0,
        menuStartDate: false,
        valid: false,
        packagesLoading: true,
        membershipPackages: new Array(),
        clientData: {
          firstName: "",
          firstNameRules: [(v) => !!v || this.$t('booking.firstnameRequired', this.$store.state.locale)],
          surname: "",
          surnameRules: [(v) => !!v || this.$t('booking.lastnameRequired', this.$store.state.locale)],
          email: "",
          id: null,
          phone: "",
          emailRules: [(v) => !!v || this.$t('booking.emailRequired', this.$store.state.locale), (v) => /.+@.+/.test(v) || this.$t('booking.emailInvalid', this.$store.state.locale)],
        },
        processingCollection: new Array(),
        membershipStartMinDate: null,
        headers:[
          { 
            text: this.$t('memberships.client', this.$store.state.locale),
            value: 'clientName'
          },
          { 
            text: this.$t('memberships.plan', this.$store.state.locale),
            value: 'ClientId'
          },
          { 
            text: this.$t('general.startDate', this.$store.state.locale),
            value: 'start'
          },
          { 
            text: this.$t('memberships.expiresOn', this.$store.state.locale),
            value: 'expireDate'
          },
          { 
            text: this.$t('general.status', this.$store.state.locale),
            value: 'status.status'
          },
          { 
            text: '', 
            value: 'edit'
          }
        ],
        price: {
          bruto: 0,
          netto: 0,
          periodicPaymentDiscount: {
            periodic: {
              bruto: 0,
              netto: 0,
            },
            total: {
              bruto: 0,
              netto: 0,
            }
          },
          singlePaymentDiscount: {
            bruto: 0,
            netto: 0,
          },
          periodicPaymentBruto: 0,
          periodicPaymentNetto: 0,
          singlePaymentBruto: 0,
          singlePaymentNetto: 0,
        },
        trialTypes:[
          {
            id:'general',
            name:'Algemeen'
          },
          {
            id:'individual',
            name:'Instellen per cliënt'
          }
        ],
        vatItems: new Array(),
        treatments: new Array(),
        discounts: new Array(),
        treatmentTypesLoading: true,
        discountsLoading: true,
        frequencyAmountsItems: new Array(),
        frequencyTypes:[
          {
            name:  this.$t('memberships.daily', this.$store.state.locale),
            id: 'daily'
          },
          {
            name:  this.$t('memberships.everyWeek', this.$store.state.locale),
            id: 'everyWeek'
          },
          {
            name:  this.$t('memberships.everyFourWeeks', this.$store.state.locale),
            id: 'everyFourWeeks'
          },
          {
            name:  this.$t('memberships.everyMonth', this.$store.state.locale),
            id: 'everyMonth'
          },
          {
            name:  this.$t('memberships.everyQuarter', this.$store.state.locale),
            id: 'everyQuarter'
          },
          {
            name:  this.$t('memberships.everyHalfYear', this.$store.state.locale),
            id: 'everyHalfYear'
          },
          {
            name:  this.$t('memberships.everyYear', this.$store.state.locale),
            id: 'everyYear'
          }
        ],
        membershipTypes:[
          {
            name:  this.$t('memberships.paymentTypePeriodic', this.$store.state.locale),
            id: "continuous"
          },
          {
            name:  this.$t('memberships.paymentTypeSingle', this.$store.state.locale),
            id: "single"
          }
        ],
        paymentPeriodMonths: [
          {
            id: "immediately",
            name:  this.$t('memberships.subscriptionStartImmediately', this.$store.state.locale),
          },
          {
            id: 1,
            name:  this.$t('general.january', this.$store.state.locale),
          },
          {
            id: 2,
            name:  this.$t('general.february', this.$store.state.locale),
          },
          {
            id: 3,
            name:  this.$t('general.march', this.$store.state.locale)
          },
          {
            id: 4,
            name:  this.$t('general.april', this.$store.state.locale)
          },
          {
            id: 5,
            name:  this.$t('general.may', this.$store.state.locale)
          },
          {
            id: 6,
            name:  this.$t('general.june', this.$store.state.locale)
          },
          {
            id: 7,
            name:  this.$t('general.july', this.$store.state.locale)
          },
          {
            id: 8,
            name:  this.$t('general.august', this.$store.state.locale)
          },
          {
            id: 9,
            name:  this.$t('general.september', this.$store.state.locale)
          },
          {
            id: 10,
            name:  this.$t('general.october', this.$store.state.locale)
          },
          {
            id: 11,
            name:  this.$t('general.november', this.$store.state.locale)
          },
          {
            id: 12,
            name:  this.$t('general.december', this.$store.state.locale)
          }
        ],
        paymentFrequencyWeeks: [ 
          {
            id: 1,
            name: "Elke week"
          },
          {
            id: 2,
            name: "Elke 2 weken"
          },
          {
            id: 4,
            name: "Elke 4 weken"
          }
        ],
        paymentFrequencyDays: [
          {
            id: 0,
            name:  this.$t('general.sunday', this.$store.state.locale)
          },
          {
            id: 1,
            name:  this.$t('general.monday', this.$store.state.locale)
          },
          {
            id: 2,
            name:  this.$t('general.tuesday', this.$store.state.locale)
          },
          {
            id: 3,
            name:  this.$t('general.wednesday', this.$store.state.locale)
          },
          {
            id: 4,
            name:  this.$t('general.thursday', this.$store.state.locale)
          },
          {
            id: 5,
            name:  this.$t('general.friday', this.$store.state.locale)
          },
          {
            id: 6,
            name:  this.$t('general.saturday', this.$store.state.locale)
          }
        ],
        limitTypes: [
          {
            id: "total",
            name: this.$t('memberships.perTotalMembershipLength', this.$store.state.locale),
          },
          {
            id: "period",
            name: this.$t('memberships.perPeriod', this.$store.state.locale),
          }
        ],
      }
    },

    async created(){
      
      bus.$on("openMembershipInformation", (membership) => {
        this.loadMembership(membership);
      })

      bus.$on("closeMembershipInformation", () => {
        this.disableMembershipEdit();
      })

      
    },
    

    methods:{

      getStatusType(type){
        let name = "";
        let data = this.membershipTypes.find((item)=>{ return item.id == type});
        if(data != undefined){
          name = data.name;
        }
        return name;
      },


      async loadMembership(membership){
        this.loading = true; 
        this.selectedMembership = membership;
        await Promise.all([this.getTreatments(),this.loadDiscounts()]);
        this.calculateNettoPrice();
        if(Object.keys(this.selectedMembership.posDescription).length == 0){
          this.selectedMembership.posDescription = {nl:"", en: ""}
        }

        this.selectedMembershipOriginal = JSON.parse(JSON.stringify(this.selectedMembership))
        
        this.loading = false; 
      },

      enableMembershipEdit(){
        this.editEnabled = true;
        this.editLevel = 1;
        if(this.$store.state.activeUserRoleNumber == 0){
          this.editLevel = 5; // More options because Active User is a Developer
        }
        this.onFrequencyChange();
        
      },

      disableMembershipEditModal(){
        if(this.changed){ // Check if changes are made and show modal
          this.disableMembershipEditDialog = true;
        }
        else{ // No Changes
          this.disableMembershipEdit();
        }
      },  

      disableMembershipEdit(){
        console.log("disableMembershipEdit")
        this.editEnabled = false;
        this.editLevel = 0;
        this.selectedMembership = JSON.parse(JSON.stringify(this.selectedMembershipOriginal));
        this.disableMembershipEditDialog = false;
      },

      /**
       * Save Changes to database
       */
      async saveMembershipEdit(){
        await db.collection("memberships").doc(this.selectedMembership.id).set({
          name: this.selectedMembership.name,
          posDescription: this.selectedMembership.posDescription,
          amount: this.selectedMembership.amount,
          amountBruto: this.selectedMembership.amountBruto,
          duration: this.selectedMembership.duration, 
          paymentPeriod: this.selectedMembership.paymentPeriod,   
          startDate: this.membershipStartDate.formatted,
          status: {
            type: this.selectedMembership.status.type,
            endDate: this.selectedMembership.status.endDate
          },
          paymentDay: this.selectedMembership.paymentDay,
          days: this.selectedMembership.days,    
          usageRules: {
            limitType: this.selectedMembership.usageRules.limitType,
            limitNumber: this.selectedMembership.usageRules.limitNumber
          },   
          separatedDiscount: {
            discountsInsideSubscriptionDays: this.selectedMembership.separatedDiscount.discountsInsideSubscriptionDays,
            discountsOutsideSubscriptionDays: this.selectedMembership.separatedDiscount.discountsOutsideSubscriptionDays,
            splitDiscounts: this.selectedMembership.separatedDiscount.splitDiscounts
          }           
        },{ merge: true })
        this.calculateNettoPrice();
        this.selectedMembershipOriginal = JSON.parse(JSON.stringify(this.selectedMembership));
        this.disableMembershipEdit();

        if(this.source =="membershipOverview"){
          bus.$emit("membershipChanged", this.selectedMembership)
        }
      },

      checkDiscounts(){
        this.selectedMembership.separatedDiscount.splitDiscounts = this.selectedMembership.separatedDiscount.discountsOutsideSubscriptionDays && this.selectedMembership.separatedDiscount.discountsOutsideSubscriptionDays.length > 0 ? true : false;
      },

      copyTextToClipboard(text) {
        if (!navigator.clipboard) {
          console.error('Clipboard API not supported');
          fallbackCopyTextToClipboard(text);
          return;
        }

        navigator.clipboard.writeText(text).then(
          () => {
            this.selectedMembershipIdCopy = true;
            setTimeout(() => {
              this.selectedMembershipIdCopy = false;
            }, 2000);
          },
          (err) => {
            console.error('Unable to copy text', err);
          }
        );
      },

      getDiscountName(discount){
        let discountItem =  discount && this.discounts.find(({ id }) => id === discount) ? this.discounts.find( ({ id }) => id === discount ): null;
        if(discountItem){
          return {
            name : discountItem.name,
            color: 'primary'
          }
        }
        else{
          return {
            name : 'Niet gevonden',
            color: 'secondary'
          }
        }
      },

      resetBranches(){
        this.selectedMembership.branches.branches = new Array();
      },
      
      /**
       * Get the name of a company branch based on the ID
       * @param {String} id - ID of the Branch
       */
      getBranchName(id){
        let branchData = this.$store.state.companyBranches.find(branch => branch.id == id);
        if(branchData){
          return branchData.name
        }
        else{
          return null
        }
      },

      /**
       * Reset Times when a day is disabled
       * @param {String} key - Specific Day Key
       * @param {String} type - type of reset
       */
      resetDayTimes(key, type){
        if(type && type == 'all'){
          this.selectedMembership.days[key].allDay = null;
        }
        this.selectedMembership.days[key].start = null;
        this.selectedMembership.days[key].end = null;
      },

      getPeriodName(paymentPeriod){
        let paymentPeriods = {
          daily: {nl: 'dag', en: 'a day'},
          everyWeek: {nl: 'per week', en: ' week'},
          everyFourWeeks: {nl: 'per vier weken', en: 'four weeks'},
          everyMonth: {nl: 'maand', en: 'month'},
          everyQuarter: {nl: 'kwartaal', en: 'quarter'},
          everyHalfYear: {nl: 'half jaar', en: 'half year'},
          everyYear: {nl: 'jaarlijk', en: 'year'},
        }    
        return paymentPeriods[paymentPeriod] ? paymentPeriods[paymentPeriod][(this.$store.state.locale).toLowerCase()] : null;
      },

      activeColor(active){ // Set color green for active or red for inactive
        return active ? "#81C784" : "#EF5350"; 
      },

      getVatPercentage(vat){
        let vatPercentage =  vat && this.$store.state.vatItems.find(({ id }) => id === vat) ? this.$store.state.vatItems.find( ({ id }) => id === vat ): null;
        if(vatPercentage && vatPercentage.percentage){
          return vatPercentage.percentage
        }
        else{
          return " - "
        }
      },

      calculateBrutoPrice(){
        let vatPercentage =  this.selectedMembership.vat && this.$store.state.vatItems.find(({ id }) => id === this.selectedMembership.vat) ? this.$store.state.vatItems.find( ({ id }) => id === this.selectedMembership.vat ): null;

        if(vatPercentage && vatPercentage.percentage){
            let extraVat = (vatPercentage.percentage * this.selectedMembership.amount) / 100;
            this.selectedMembership.totalPrice = Number((extraVat + this.selectedMembership.amount).toFixed(2));
        }
        this.calculateTotalPrice() 
      },

      calculateNettoPrice(){
        let vatPercentage =  this.selectedMembership.vat && this.$store.state.vatItems.find(({ id }) => id === this.selectedMembership.vat) ? this.$store.state.vatItems.find( ({ id }) => id === this.selectedMembership.vat ): null;

        if(vatPercentage && vatPercentage.percentage){
           this.selectedMembership.amount = Number((this.selectedMembership.amountBruto / (1 + (vatPercentage.percentage) / 100)).toFixed(4));
            let extraVat = (vatPercentage.percentage * this.selectedMembership.amount) / 100;
            this.selectedMembership.totalPrice = Number((extraVat + this.selectedMembership.amount).toFixed(2));
        }
        this.calculateTotalPrice() 
      },

      calculateTotalPrice(){
        let subscriptionNumber = this.selectedMembership.usageRules.limitType == 'total' ? this.selectedMembership.usageRules.limitNumber : this.selectedMembership.duration;
        let vatPercentage =  this.selectedMembership.vat && this.$store.state.vatItems.find(({ id }) => id === this.selectedMembership.vat) ? this.$store.state.vatItems.find( ({ id }) => id === this.selectedMembership.vat ): null;

        this.price.bruto = (subscriptionNumber * this.selectedMembership.totalPrice).toFixed(2);
        this.price.netto = (subscriptionNumber * this.selectedMembership.amount).toFixed(2);
        
        
        if(this.selectedMembership.periodicPaymentDiscount.active && this.selectedMembership.periodicPaymentDiscount.number > 0){
          if(this.selectedMembership.periodicPaymentDiscount.type == 'percentage'){
            this.price.periodicPaymentDiscount.periodic.bruto = ((this.selectedMembership.amount) /100 * this.selectedMembership.periodicPaymentDiscount.number).toFixed(2)
            if(this.price.periodicPaymentDiscount.periodic.bruto > 0){
              this.price.periodicPaymentDiscount.periodic.netto =  (this.price.periodicPaymentDiscount.periodic.bruto / ((vatPercentage.percentage / 100) + 1)).toFixed(2)
              this.price.periodicPaymentDiscount.total.bruto = (this.price.periodicPaymentDiscount.periodic.bruto * subscriptionNumber).toFixed(2)
              this.price.periodicPaymentDiscount.total.netto = (this.price.periodicPaymentDiscount.total.netto * subscriptionNumber).toFixed(2)
            }
            else{
              this.price.periodicPaymentDiscount.periodic.bruto = 0;
              this.price.periodicPaymentDiscount.periodic.netto = 0;
              this.price.periodicPaymentDiscount.total.bruto = 0;
              this.price.periodicPaymentDiscount.total.netto = 0;
            }
          }
          else{
            this.price.periodicPaymentDiscount.periodic.bruto = (this.selectedMembership.periodicPaymentDiscount.number).toFixed(2)
            if(this.price.periodicPaymentDiscount.periodic.bruto > 0){
              this.price.periodicPaymentDiscount.periodic.netto = (this.price.periodicPaymentDiscount.periodic.bruto / ((vatPercentage.percentage / 100) + 1)).toFixed(2)
              this.price.periodicPaymentDiscount.total.bruto = (this.price.periodicPaymentDiscount.periodic.bruto * subscriptionNumber).toFixed(2)
              this.price.periodicPaymentDiscount.total.netto = (this.price.periodicPaymentDiscount.total.netto * subscriptionNumber).toFixed(2)
            }
            else{
              this.price.periodicPaymentDiscount.periodic.bruto = 0;
              this.price.periodicPaymentDiscount.periodic.netto = 0;
              this.price.periodicPaymentDiscount.total.bruto = 0;
              this.price.periodicPaymentDiscount.total.netto = 0;
            }
          }
          this.price.periodicPaymentBruto = (this.price.bruto - this.price.periodicPaymentDiscount.total.bruto).toFixed(2);
          this.price.periodicPaymentNetto = Number((this.price.netto - this.price.periodicPaymentDiscount.total.netto).toFixed(2));
        }
        else{
          this.price.periodicPaymentBruto = this.price.bruto;
          this.price.periodicPaymentNetto = this.price.netto;
          this.price.periodicPaymentDiscount.total.netto = 0;
          this.price.periodicPaymentDiscount.total.bruto = 0;
          this.price.periodicPaymentDiscount.periodic.netto = 0;
          this.price.periodicPaymentDiscount.periodic.bruto = 0;
        }

        if(this.selectedMembership.singlePaymentDiscount.active && this.selectedMembership.singlePaymentDiscount.number > 0){
          if(this.selectedMembership.singlePaymentDiscount.type == 'percentage'){
            this.price.singlePaymentDiscount.bruto = ((this.selectedMembership.totalPrice * subscriptionNumber) /100 * this.selectedMembership.singlePaymentDiscount.number).toFixed(2)
            if(this.price.singlePaymentDiscount.bruto > 0){
              this.price.singlePaymentDiscount.netto =  Number((this.price.singlePaymentDiscount.bruto / ((vatPercentage.percentage / 100) + 1)).toFixed(2));
            }
            else{
              this.price.singlePaymentDiscount.bruto = 0;
              this.price.singlePaymentDiscount.netto = 0;
            }
          }
          else{
            this.price.singlePaymentDiscount.bruto = (this.selectedMembership.singlePaymentDiscount.number).toFixed(2)
            if(this.price.singlePaymentDiscount.bruto > 0){
              this.price.singlePaymentDiscount.netto =  Number((this.price.singlePaymentDiscount.bruto / ((vatPercentage.percentage / 100) + 1)).toFixed(2));
            }
            else{
              this.price.singlePaymentDiscount.bruto = 0;
              this.price.singlePaymentDiscount.netto = 0;
            }
          }
          this.price.singlePaymentBruto = (this.price.bruto - this.price.singlePaymentDiscount.bruto).toFixed(2);
          this.price.singlePaymentNetto = Number((this.price.netto - this.price.singlePaymentDiscount.netto).toFixed(2));
        }
        else{
          this.price.singlePaymentBruto = this.price.bruto;
          this.price.singlePaymentNetto = this.price.netto;
          this.price.singlePaymentDiscount.bruto = 0;
          this.price.singlePaymentDiscount.netto = 0;
        }
        this.loading = false;
      },

      usageColor(usage){
        if(usage.override){
          return 'secondary';
        }
        else{
          return 'primary';
        }
      },

      async getCategories(){ // Load categories from DB and fetch it into select element
        this.categories = new Array();
        await db.collection('membershipCategories')
        .get()
        .then(querySnapshot=>{
          querySnapshot.forEach(doc=>{
            this.categories.push({
              id:doc.id,
              name: doc.data().name
            });
          })
        })
        .then(()=>{
          this.categories_Loading = false;
        })
        .catch(err=>{
          console.error(err);
        })
        return
      },
    
      async getTreatments(){ // Load treatments from DB and fetch it into select element
        this.treatments = new Array();
        await db.collection('treatments')
        .where("companyId","==", this.$store.state.activeCompany.id)
        .get()
        .then(snap=>{
          snap.forEach(doc=>{
            this.treatments.push({
              id: doc.id,
              title: doc.data().treatmentTitle
            });
          })
        })
        .then(()=>{
          this.treatmentTypesLoading = false;
        })
        .catch(err=>{
          console.error(err);
        })
        return
      },

      async loadDiscounts(){
        this.discounts = new Array();
        await db.collection('modifiers')
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("active", "==", true)
        .get()
        .then(querySnapshot=>{
          querySnapshot.forEach(doc=>{
            let discount = doc.data();
            discount.id = doc.id;
            this.discounts.push(discount);
          })
        })
        .then(()=>{
          this.discountsLoading = false;
        })
        .catch(err=>{
          console.error(err);
        })
        return
      },

          //Frequency type changing fetch another select items 
      onFrequencyChange(){
        if(this.selectedMembership.paymentPeriod){
          if(this.selectedMembership.paymentPeriod == 'daily'){
            this.frequencyAmountsItems = this.getDaysCollection();
          }
          if(this.selectedMembership.paymentPeriod == 'everyWeek' || this.selectedMembership.paymentPeriod == 'everyFourWeeks'){
            console.log("everyWeek:", this.paymentFrequencyDays)
            this.frequencyAmountsItems = this.paymentFrequencyWeeks;
            this.processingCollection = this.paymentFrequencyDays;
            this.selectedMembership.paymentDay = 4;

          }
          else if(this.selectedMembership.paymentPeriod == 'everyMonth' ){
            this.frequencyAmountsItems = this.getMonthsCollection();
            this.processingCollection =  this.getDaysOfMonth();
            this.selectedMembership.paymentDay = 14;

          }
          else if(this.selectedMembership.paymentPeriod == 'everyQuarter' || this.selectedMembership.paymentPeriod == 'everyHalfYear' || this.selectedMembership.paymentPeriod == 'everyYear'){
            this.frequencyAmountsItems = this.paymentFrequencyYear;
            this.processingCollection =  this.getDaysOfMonth();
            this.selectedMembership.paymentDay = 14;
          }
        }
      },

      getDaysCollection(){ // Days functions
      let days = [];
      for(let i = 0 ; i < 30; i++){
        if(i==0){
          let data = {
            id: i,
            name: 'Elke dag'
          }
          days.push(data)
        }
        else{
          let data = {
            id: i,
            name: 'Elke '+ (i + 1) + ' dagen'
          }
          days.push(data)
        }
      }
      return days;
    },

      // Weeks functions
      getWeeksCollection(){
        let weeks = [
        {
          id: 1,
          name: "Elke week"
        },
        {
          id: 2,
          name: "Elke 2 weken"
        },
        {
          id: 4,
          name: "Elke 4 weken"
        }
        ];
        for(let i = 0 ; i < 4; i++){
          if(i==0){
            let data = {
              id: i,
              name: 'Elke week'
            }
            weeks.push(data)
          }
          else{
          let data = {
              id: i,
              name: 'Elke '+ (i + 1) + ' weken'
            }
            weeks.push(data)
          }
        }
        return weeks;
      },
      
      //Month functions
      getMonthsCollection(){
        let months = [
          {
              id: 1,
              name:'Elke maand'
          }
        ];
        
        for(let i = 2 ; i < 13 ; i++){
          let data = {
              id: i,
              name: 'Elke '+ i + ' maanden'
            }
            months.push(data)
        }
        return months;
      },

      getDaysOfMonth(){
        let daysOfMonth = [
          {
              id: 1,
              name:'1e van de maand'
          }
        ];

        for(let i = 2 ; i < 27 ; i++){
          let data = {
              id: i,
              name: i + 'e van de maand'
            }
          daysOfMonth.push(data)
        }
        return daysOfMonth;
      },
    },

    computed: {

      changed(){
        return JSON.stringify(this.selectedMembership) === JSON.stringify(this.selectedMembershipOriginal) ? false : true;
      },

      paymentPeriode(){
        if(this.selectedMembership.paymentPeriod == 'daily'){
          return "dag"
        }
        else if(this.selectedMembership.paymentPeriod == 'everyWeek'){
          return "week"
        }
        else if(this.selectedMembership.paymentPeriod == 'everyFourWeeks'){
          return "vierweken"
        }
        else if(this.selectedMembership.paymentPeriod == 'everyMonth'){
          return "maand"
        }
        else if(this.selectedMembership.paymentPeriod == 'everyQuarter'){
          return "kwartaal"
        }
        else if(this.selectedMembership.paymentPeriod == 'everyHalfYear'){
          return "halfjaar"
        }
        else if(this.selectedMembership.paymentPeriod == 'everyYear'){
          return "jaar"
        }
      },

      extraDiscounts(){
        return this.discounts.filter(discount => { return discount.modifierGroup != 'membershipModifier'})
      },

      membershipDiscounts(){
        return this.discounts.filter(discount => { return discount.modifierGroup == 'membershipModifier'})
      },

      durationSuffix() {
        if(this.selectedMembership.paymentPeriod == 'daily'){
          if(this.selectedMembership.duration == 1){
            return "dag"
          }
          else{
            return "dagen"
          }
        }

        else if(this.selectedMembership.paymentPeriod == 'everyWeek'){
          if(this.selectedMembership.duration == 1){
            return "week"
          }
          else{
            return "weken"
          }
        }

        else if(this.selectedMembership.paymentPeriod == 'everyFourWeeks'){
          return "vierweken"
        }

        else if(this.selectedMembership.paymentPeriod == 'everyMonth'){
          if(this.selectedMembership.duration == 1){
            return "maand"
          }
          else{
            return "maanden"
          }
        }
        else if(this.selectedMembership.paymentPeriod == 'everyQuarter'){
          if(this.selectedMembership.duration == 1){
            return "kwartaal"
          }
          else{
            return "kwartalen"
          }
        }
        else if(this.selectedMembership.paymentPeriod == 'everyHalfYear'){
          if(this.selectedMembership.duration == 1){
            return "halfjaar"
          }
          else{
            return "halfjaren"
          }
        }
        else if(this.selectedMembership.paymentPeriod == 'everyYear'){
          if(this.selectedMembership.duration == 1){
            return "jaar"
          }
          else{
            return "jaren"
          }
        }
        else{
          return `${this.selectedMembership.paymentPeriod}`;
        }
      },

      singleDiscountInfo(){
        if(this.selectedMembership.singlePaymentDiscount.type == "amount"){
          return {
            prefix: "€",
            suffix: null,
            label: "Kortingsbedrag",
            mask: "####"
          }
        }
        else {
          return {
            prefix: null,
            suffix: "%",
            label: "Kortingspercentage",
            mask: "##"
          }
        }
      },
      periodicDiscountInfo(){
        if(this.selectedMembership.singlePaymentDiscount.type == "amount"){
          return {
            prefix: "€",
            suffix: null,
            label: "Kortingsbedrag",
            mask: "####"
          }
        } 
        else {
          return {
            prefix: null,
            suffix: "%",
            label: "Kortingspercentage",
            mask: "##"
          }
        }
      },
      getAutoRenewType(){
        if(this.selectedMembership.autoRenew){
          return {
            name: this.$t('general.yes', this.$store.state.locale),
            color: "#56ca00"
          }
        }
        else{
          return {
            name: this.$t('general.no', this.$store.state.locale),
            color: "#ff4c51"
          }
        }
      },

      limitType(){
        let name = "";
        let data = this.limitTypes.find((type) => { return type.id == this.selectedMembership.usageRules.limitType })
        if(data != undefined){
          name = data.name;
        }
        return name;
      },

      membershipStartDate() {
        let date = {
          readable: null,
          formatted: "",
          firebase: null,
        };
        if (this.selectedMembership.startDate) {
          date.readable = moment(this.selectedMembership.startDate).format("D MMMM YYYY");
          date.formatted = moment(this.selectedMembership.startDate).format("YYYY-MM-DD");
          return date;
        }
        else {
          return date;
        }
      },

      membershipEndDate() {
        let date = {
          readable: null,
          formatted: "",
          firebase: null,
        };
        if (this.selectedMembership.status.endDate) {
          date.readable = moment(this.selectedMembership.status.endDate).format("D MMMM YYYY");
          date.formatted = moment(this.selectedMembership.status.endDate).format("YYYY-MM-DD");
          return date;
        }
        else {
          return date;
        }
      },
    }
  };
</script>
