import { cashConv } from "./quickWins";
const uniqid = require("uniqid");
const moment = require("moment");

// cashConv(4.1245678, "EUR", 2); //?

/**
 * DELETED
 * deleted Modifiers are neccesracy for duplicates
 */
let deletedModifiers = new Array();

/**
 * ADVISED
 * Advised modifiers, for reviewing
 */
let advisedModifiers = new Array();

/**


 {
  subscription: "ksXoFmLusPg2qxfYGon3",
  duration: 12,
  registrationDescription: {
    en: null,
    nl: "Kom je voordelig bij ons sporten? Zeer betaalbaar....",
  },
  type: "membership"
  name: "Joe Behandeling Abbotje",
  freePeriod: { number: "1", active: false, type: "general" },
  registrationUrl: null,
  clientId: "joevanettekovengmailcom",
  sendInvoice: true,
  paymentPeriod: "everyMonth",
  paymentReminder: true,
  treatments: {
    limitNumber: 10,
    active: true,
    limit: true,
    limitType: "total",
    treatments: [
      { id: "y28tfUIbX31jmUyKp2t8", title: "Wassen Knippen Stylen" },
    ],
  },
  trialPeriod: { active: false },
  singlePaymentDiscount: { type: "percentage", active: false, number: 0 },
  terms: { nl: null, en: null },
  amount: 19.7105,
  startDate: "2021-11-23",
  companyId: "hogansbeauty",
  created: { seconds: 1637699171, nanoseconds: 385000000 },
  sepaAttempts: 2,
  vat: { percentage: 21, id: "UOjSPqfO7kyR8MS09Wa9" },
  posDescription: {
    nl: "Een van de betere behandelingen van Joe. Kosten vervalt.",
  },
  periodicPaymentDiscount: {
    type: "percentage",
    number: 0,
    max: 0,
    active: false,
    continue: false,
    discountType: "general",
  },
  usage: [],
  paymentDay: 14,
  status: true,
  docId: "e4IcfeJwIFjIe8mCo6ub",
};
  
 
 */

/**
 * usedModifiers
 * Al added modifers will be added, but will be calculated if calcApproved = true;
 * note: calcApproved must be true for calculation.
 */
// let usedModifiers = new Array();

let errorsArray = new Array();

let selectedItems = new Array();

let modifiers = new Array();

let clientDataObj = new Object();

/**
 * USED
 * Background Modifiers are neccesracy for duplicates
 */
let usedModifiers = new Array();
let sortDiscountOrder = new Array();

let internalUseItems = new Array();

//Calculation percentages always converted to EUR

//FOR TEST PURPOSE




// import testData from './POStestData.js';

// selectedItems = testData.selectedItems;
// usedModifiers = testData.usedModifiers;
// modifiers = testData.modifiers;
// let modifier = {};

// testData.usedModifiers //?

// console.log(usedModifiers)
// usedModifiers = testData.usedModifiers;
// modifiers = testData.modifiers;

// Auto Pupose
// scanDiscounts(selectedItems, true, modifier, modifiers, usedModifiers, deletedModifiers, true); //?

//END FOR TEST PURPOSE

export function quickValidate(items, modifier, alreadyUsed, clientData) {


	clientDataObj = clientData;
	selectedItems = items;
	usedModifiers = alreadyUsed;
	return validationOptions(modifier);
	function validationOptions(modifier) {
		//Block equal discount duplicate blocking
		const discountOnceCheck = discountOnce(modifier.id, modifier.name);
		if (discountOnceCheck !== true) {
			return true;
		}

		// console.log("Quick Validate", modifier, modifier.numberLimit, modifier.name, modifier.id)

		const used = numberUsed(modifier.numberLimit, modifier.name, modifier.id);
		if (used !== true) {
			return true;
		}

		const combinationCheck = combinationLimit(modifier.combinationLimit, modifier.name);
		// console.log("Combination Limit:", combinationCheck);
		if (combinationCheck.fail == true) {
			return true;
		}

		//Filter Products or Treatments
		if (!modifier.custom) {
			const filteredItems = filterSys(modifier);
			// console.log("modifierItems:", modifier);
			// console.log("filteredItems:", filteredItems);
			if (filteredItems.fail == true) {
				return true;
			}
		}

		return false;
	}
}

//Manual Purpose
// scanDiscounts(selectedItems, true, modifiers[0], modifiers, usedModifiers, deletedModifiers, true); //?
// scanDiscounts(selectedItems, false, modifiers, modifiers, usedModifiers, deletedModifiers, true); //?

/**
 *
 * @param {array} items - selectedItems
 * @param {boolean} manual - true or false for adding manual discount.
 * @param {object} modifier - object of the modifier
 * @param {array} items - selectedItems
 * @param {map} alreadyUsed - map of already used items
 * @param {map} deleted - map of already deleted items
 * @param {boolean} debug - true or false
 * @param {boolean} sortDiscountOrder = Array of objects used for sorting Discounts by Weight
 */
export function scanDiscounts(items, manual, modifier, allModifiers, alreadyUsed, deleted, debug, clientData, discountByOrder) {
	
	


	/**
	 * The data for scanDiscounts function is always fresh to recalaucate the new values.
	 * We use the JSON.parse(JSON.stringify()) to make a deep copy of the object without references
	 * or observers from Vue.
	 */
	let toCalculate = new Array();
	clientDataObj = JSON.parse(JSON.stringify(clientData));
	selectedItems = JSON.parse(JSON.stringify(items)); //?
	modifiers = JSON.parse(JSON.stringify(modifier)); //?
	usedModifiers = JSON.parse(JSON.stringify(alreadyUsed)); //?
	deletedModifiers = JSON.parse(JSON.stringify(deleted));
	sortDiscountOrder = JSON.parse(JSON.stringify(discountByOrder));
	allModifiers = JSON.parse(JSON.stringify(allModifiers));
	internalUseItems = new Array();
	advisedModifiers = new Array(); //<-- always new advise
	errorsArray = new Array();

	// console.log("usedModifiers", usedModifiers);

	//Generate INDEX and create uniqid when needed

	// console.log("POS selectedItems", selectedItems);
	selectedItems.map((item, index) => {
		let obj = item;
		obj.index = index;

		//Generate a new uniqid (if needed)
		if (!item.modifiedId) obj.modifiedId = uniqid.time();
		obj.usedModifierId = new Set(); //For unique Modifiers
		obj.usedMembershipId = new Set(); //For unique Modifiers

		return obj;
	});

	//filter out selectedItems with interalUse === true
	selectedItems = selectedItems.filter((item) => item.internalUse !== true);

	//Save the internalUse items to a new array for later use
	internalUseItems = items.filter((item) => item.internalUse === true);
	internalUseItems.map((item, index) => {
		let obj = item;
		obj.usedModifierId = new Array(); //Not needed for Internal Use 
		obj.usedMembershipId = new Array(); //Not needed for Internal Use 
		return obj;
	});

	if (manual === true) {

		console.log("LEGACY ROUTE");
		//manual add and Recalculate Already UsedModifiers

		/**
		 * MANUAL
		 */

		// let manualModifier = modifiers;
		// let usedRecalc = new Object();
		// let deleted = new Object();

		

		// //Calculate the rest of already used modifiers
		// allModifiers.forEach((modi) => {
		// 	//Recalculate if Already Used
		// 	usedRecalc = usedModifiers.find((usedModi) => usedModi.id == modi.id);
		// 	deleted = deletedModifiers.find((deletedModi) => deletedModi.id == modi.id);
		// 	if (!usedRecalc && !deleted) {
		// 		// console.log("usedRecalc !usedRecalc && !deleted", usedRecalc)
		// 		// //Remove by filtering out from usedModifiers and calculate Again
		// 		// usedModifiers = usedModifiers.filter((item) => item.id !== usedRecalc.id);
		// 		// manualModifier.forEach((manualModifier) => toCalculate.push(manualModifier))
		// 		// toCalculate.push(usedRecalc);
		// 	} else if (!usedRecalc && deleted) {
		// 		//Do nothing
		// 	} else {
		// 		//Remove by filtering out from usedModifiers and calculate Again
		// 		usedModifiers = usedModifiers.filter((item) => item.id !== usedRecalc.id);
		// 		toCalculate.push(usedRecalc);
		// 	}
		// });

	

		// if (toCalculate.length !== 0) {
		// 	toCalculate.forEach((calculateModifier) => {
		// 		typeOfDiscountSwitch(calculateModifier, manual, debug);
		// 	});
		// } else {
		// 	// if (debug) errorsArray.push({ fail: true, reason: "Nothing Manual to calculate", code: 310 });
		// }
	} else {
		/**
		 * AUTO
		 * only calculate auto or usedModifiers (if needed)
		 */
		let countDiscounts = 0;
		allModifiers; //?
		usedModifiers; //?
		allModifiers.forEach((modi) => {
			modi //?
			// console.log("Modifiers console:", modi);
			let used = usedModifiers.find((usedModi) => usedModi.id == modi.id); //?
			used //?
			if (modi.automatic) {
				//automatic and used
				if (used) {
					//Remove by filtering out from usedModifiers and calculate Again
					usedModifiers = usedModifiers.filter((item) => item.id !== used.id);
					countDiscounts++;
				
					toCalculate.push(modi);
				
				} else {
					//Not used so the first time the discount is added
					countDiscounts++;
					modi.addedVia = "automaticDiscount";
					toCalculate.push(modi);
				
	
				}
			}
			//Not automatic but used
			if (!modi.automatic) {
				if (used) {
					//Special by Membership

					//Remove by filtering out from usedModifiers and calculate Again
					usedModifiers = usedModifiers.filter((item) => item.id !== used.id);
					countDiscounts++;
					toCalculate.push(modi);
				}
			}
		});

		if (toCalculate.length !== 0) {
			// console.log("toCalculate", toCalculate);

			/* 
        Sort modifiers "toCalculate" by priority
        1. Get all the modifiers that have a priority defined.
        2. Sort them by priority.
        3. Get all the modifiers that don't have a priority defined.
        4. Add them to the end of the array. */

			let toCalculateUndefined = toCalculate.filter((toCalculateModifier) => typeof toCalculateModifier.priority == "undefined");
			let toCalculatePrioritySorted = toCalculate
				.filter((toCalculateModifier) => typeof toCalculateModifier.priority != "undefined")
				.sort((a, b) => a.priority - b.priority); //?
			toCalculate = [...toCalculatePrioritySorted, ...toCalculateUndefined]; //?

			//Calculate the sorted Modifiers that will be calculated. We call this toCalculate.
			toCalculate.forEach((calculateModifier) => {
				typeOfDiscountSwitch(calculateModifier, manual, debug);
			});
		} else {
			// if (debug) errorsArray.push({ fail: true, reason: "Nothing Manual to calculate", code: 310 });
		}

		//Count Discount and check size if already in use
		if (usedModifiers.size > 0 && countDiscounts == 0)
			errorsArray.push({ fail: true, reason: "Discount(s) already in use, and or no discount(s) left...", code: 307 });
	}

	/**
	 * This function start its proces to validate a modifier and close (when is validated)
	 * with usedModfiers
	 *
	 * @param {object} modi - as modifier
	 * @param {boolean} manual
	 * @param {boolean} debug
	 */

	//Manipulate value from set to array Firebase doesnt read Sets
	for (let index = 0; index < selectedItems.length; index++) {
		selectedItems[index].usedModifierId = [...selectedItems[index].usedModifierId];
		selectedItems[index].usedMembershipId = [...selectedItems[index].usedMembershipId];
	}

	//Everything calculate done? Return / give back the new values.

	//Are there internalUse items? Add the internalUse items to the selectedItems in the right index order
	if (internalUseItems.length > 0) {
		//Sort internalUseItems by index
		internalUseItems.forEach((item) => {
			//add the internalUse items to the selectedItems in the right index order
			selectedItems.splice(item.index, 0, item); // add item to selectedItems at index
		});
	}
		
	
	
	//remove modified field from selectedItems
	// selectedItems.forEach((item) => {
	// 	delete item.modified;
	// });


	return {
		selectedItems: selectedItems,
		usedModifiers: usedModifiers,
		deletedModifiers: deletedModifiers,
		advise: advisedModifiers,
		error: errorsArray,
	};
}

function typeOfDiscountSwitch(modi, manual, debug) {
	 console.log("MODI", modi);
	modi.typeDiscount //?
	modi //?
	switch (modi.typeDiscount.type) {
		case "fixedAmount":
			return discountFixedAmount(modi, manual, debug);

			break;

		case "buyXX":
			buyXX(modi, manual, debug); //?

			break;

		case "buyXY":
			buyXY(modi, manual, debug); //?

			break;

		case "quantityDiscount":
			quantityDiscount(modi, manual, debug);

			break;

	   // LEGACY
		// case "membership":
		// 	console.log("membership", modi);
		// 	membership(modi, manual, debug);

		// 	break;

		default:
			break;
	}
}

/**
 * func: discountFixedAmount
 * @param {object} modifier - discount settings
 * @param {boolean} manual - true or false
 * @param {boolean} debug - true or false
 */
function discountFixedAmount(modifier, manual, debug) {
	

	console.log(modifier.automatic, modifier.addedVia);
	

	//Already Deleted
	const deletedCheck = deletedDiscount(modifier.id, modifier.name, modifier.addedVia);
	if (deletedCheck !== true) {
		if (debug) errorsArray.push(deletedCheck);
		// console.log(deletedCheck.reason);
		return false;
	}

	//Block equal discount duplicate blocking
	const discountOnceCheck = discountOnce(modifier.id, modifier.name);
	if (discountOnceCheck !== true) {
		if (debug) errorsArray.push(discountOnceCheck);
		// console.log(discountOnceCheck);
		return false;
	}

	const used = numberUsed(modifier.numberLimit, modifier.name, modifier.id);

	if (used !== true) {
		if (debug) errorsArray.push(used);
		// console.log(used.reason);
		return false;
	}

	const combinationCheck = combinationLimit(modifier.combinationLimit, modifier.name);
	if (combinationCheck.fail == true) {
		if (debug) errorsArray.push(combinationCheck);
		// console.log(combinationCheck.reason);
		return false;
	}

	//TODO BEREKENEN MET CUSTOM KORTING, WANNEER NODIG
	/**
	 * Filter FixedAmount
	 * Could be a custom Filter by ModifiedId OR a fixedAmount modifier preset.
	 * This depends if modifier.typeDiscount.custom true
	 */
	let filteredItems = null;
	let getItems = new Array();

	//Custome Discounts
	if (modifier.custom) {
		const itemFilterIndex = modifier.typeDiscount.itemFilterIndex;

		//Push id to getItems for the calcDiscountObj object

		const foundedItem = selectedItems.find((item) => item.index == itemFilterIndex);
		if (foundedItem) {
			console.log("itemFilterIndex activeer mij", itemFilterIndex, "filter:", selectedItems.find((item) => item.index == itemFilterIndex).id);

			getItems.push(foundedItem.id);
		}
		// getItems.push(selectedItems.find((item) => item.index == itemFilterIndex).id);
	}  else 	{

		console.log("modifier", modifier);

		//Filter Products or Treatments
		filteredItems = filterSys(modifier);

		if (filteredItems.fail == true && filteredItems.code === 301) {
			if (debug) errorsArray.push(filteredItems);
			console.log(filteredItems.reason);
			return false;
		} else if (filteredItems.fail == true && filteredItems.code === 311) {
			//Membership is found but the user added no items. Check filtersys function for more information

			//The function stops here so the rule will be added but not calculated
			usedModifiers.push(filteredItems.modifierObj);
			return false;
		} else if (filteredItems.fail == true && filteredItems.code === 312) {
			//No Membership Treatment found but other Discount are included by the membership?

			usedModifiers.push(filteredItems.modifierObj);
			return false;
		}
		// Filtered Items found and
		let filteredProducts = filteredItems.filter((item) => item.type == "product");
		let filteredTreatments = filteredItems.filter((item) => item.type == "treatment");

		filteredTreatments //?

		//Make the items unique
		if (modifier.type === "treatment" || modifier.type === "all" || modifier.type === "downPayment") {
			let uniqTreatments = [...new Set(filteredTreatments.map((item) => item.id))];
			getItems.push(...uniqTreatments);
		}
		if (modifier.type === "product" || modifier.type === "all" || modifier.type === "downPayment") {
			let uniqProducts = [...new Set(filteredProducts.map((item) => item.id))];
			getItems.push(...uniqProducts);
		}
	}

	//Sorter before calculation Sort by VAT and Price depending on typeDiscount.
	// const sortedFilteredItems = sorter(modifier.typeDiscount.type, filteredItems);

	console.log(modifier);
	//Enroll modifier
	const calcDiscountObj = {

		discountValue: modifier.typeDiscount.discountValue,
		type: "fixedAmount", //Could be "fixedAmount","buyXX", "buyXY", "quantityDiscount"
		repetitive: modifier.typeDiscount.repetitive == true ? true : false,
		discountType: modifier.typeDiscount.discountType, //could be "amount", "percentage",free
		quantityRules: null,
		get: getItems,
		modifierId: modifier.id,
		manualInputDiscount: modifier.manualInputDiscount ? true : false


	};

	console.log("calcDiscountObj", calcDiscountObj);

	//Start Calculation and RETURN remainingAmount
	let calculatedDiscountIntel = calculateDiscount(calcDiscountObj);

	//Give back remainingAmount
	modifier.remainingAmount = calculatedDiscountIntel.remainingAmount;

	//Everything looks fine add

	usedModifiers.push(modifier);
	// console.log("TEST VANUIT POS", selectedItems[0]);
	return true;
}

/**
 * func: buyXX buy X get X
 * @param {object} modifier - discount settings
 * @param {boolean} manual - true or false
 * @param {boolean} debug - true or false
 */
function buyXX(modifier, manual, debug) {
	//Already Deleted
	const deletedCheck = deletedDiscount(modifier.id, modifier.name, modifier.addedVia);
	if (deletedCheck !== true) {
		if (debug) errorsArray.push(deletedCheck);
		// console.log(deletedCheck.reason);
		return false;
	}

	//Block equal discount duplicate blocking
	const discountOnceCheck = discountOnce(modifier.id, modifier.name);
	if (discountOnceCheck !== true) {
		if (debug) errorsArray.push(discountOnceCheck);
		// console.log(discountOnceCheck);
		return false;
	}

	const used = numberUsed(modifier.numberLimit, modifier.name, modifier.id);
	if (used !== true) {
		if (debug) errorsArray.push(used);
		// console.log(used.reason);
		return false;
	}

	const combinationCheck = combinationLimit(modifier.combinationLimit, modifier.name);
	if (combinationCheck.fail == true) {
		if (debug) errorsArray.push(combinationCheck);
		// // console.log(combinationCheck.reason);
		return false;
	}

	//Filter Products or Treatments
	const filteredItems = filterSys(modifier);
	if (filteredItems.fail == true && filteredItems.code === 301) {
		if (debug) errorsArray.push(filteredItems);
		// console.log(filteredItems.reason);
		return false;
	} else if (filteredItems.fail == true && filteredItems.code === 311) {
		//Membership is found but the user added no items. Check filtersys function for more information

		//The function stops here so the rule will be added but not calculated
		usedModifiers.push(filteredItems.modifierObj);
		return false;
	}

	//Interpreter for getting the right getQuantity
	const getQuantity = mixMatchInterpreter(modifier, filteredItems);
	if (getQuantity === false) return false;
	getQuantity; //?
	const calcDiscountObj = {
		discountValue: modifier.typeDiscount.discountValue,
		type: "buyXX", //Could be "fixedAmount","buyXX", "buyXY", "quantityDiscount"
		discountType: modifier.typeDiscount.discountType, //could be "amount", "percentage",free
		quantityRules: null,
		get: getQuantity,
		modifierId: modifier.id,
	};

	//TODO Memberships

	calculateDiscount(calcDiscountObj);
	//Everything looks fine add
	usedModifiers.push(modifier);
	return true;
}

/**
 * func: buyXY buy X get Y
 * @param {object} modifier - discount settings
 * @param {boolean} manual - true or false
 * @param {boolean} debug - true or false
 */
function buyXY(modifier, manual, debug) {
	//Already Deleted
	const deletedCheck = deletedDiscount(modifier.id, modifier.name, modifier.addedVia);
	if (deletedCheck !== true) {
		if (debug) errorsArray.push(deletedCheck);
		// console.log(deletedCheck.reason);
		return false;
	}

	//Block equal discount duplicate blocking
	const discountOnceCheck = discountOnce(modifier.id, modifier.name);
	if (discountOnceCheck !== true) {
		if (debug) errorsArray.push(discountOnceCheck);
		// console.log(discountOnceCheck);
		return false;
	}

	const used = numberUsed(modifier.numberLimit, modifier.name, modifier.id);
	if (used !== true) {
		if (debug) errorsArray.push(used);
		// console.log(used.reason);
		return false;
	}

	const combinationCheck = combinationLimit(modifier.combinationLimit, modifier.name);
	if (combinationCheck.fail == true) {
		if (debug) errorsArray.push(combinationCheck);
		// // console.log(combinationCheck.reason);
		return false;
	}

	//Filter Products or Treatments
	const filteredItems = filterSys(modifier);
	if (filteredItems.fail == true && filteredItems.code === 301) {
		if (debug) errorsArray.push(filteredItems);
		// console.log(filteredItems.reason);
		return false;
	} else if (filteredItems.fail == true && filteredItems.code === 311) {
		//Membership is found but the user added no items. Check filtersys function for more information

		//The function stops here so the rule will be added but not calculated
		usedModifiers.push(filteredItems.modifierObj);
		return false;
	}

	//Interpreter for getting the right getQuantity
	const getQuantity = mixMatchInterpreter(modifier, filteredItems);
	if (getQuantity === false) return false;
	//getQuantity; //?

	// console.log("getQuantity", getQuantity);

	const calcDiscountObj = {
		discountValue: modifier.typeDiscount.discountValue,
		type: "buyXY", //Could be "fixedAmount","buyXX", "buyXY", "quantityDiscount"
		discountType: modifier.typeDiscount.discountType, //could be "amount", "percentage",free
		quantityRules: null,
		get: getQuantity,
		modifierId: modifier.id,
	};

	calculateDiscount(calcDiscountObj);
	//Everything looks fine add
	usedModifiers.push(modifier);

	return true;
}

/**
 * func: quantityDiscount
 * @param {object} modifier - discount settings
 * @param {boolean} manual - true or false
 * @param {boolean} debug - true or false
 */
function quantityDiscount(modifier, manual, debug) {
	//Already Deleted
	const deletedCheck = deletedDiscount(modifier.id, modifier.name, modifier.addedVia);
	if (deletedCheck !== true) {
		if (debug) errorsArray.push(deletedCheck);
		// console.log(deletedCheck.reason);
		return false;
	}

	//Block equal discount duplicate blocking
	const discountOnceCheck = discountOnce(modifier.id, modifier.name);
	if (discountOnceCheck !== true) {
		if (debug) errorsArray.push(discountOnceCheck);
		// console.log(discountOnceCheck);
		return false;
	}

	const used = numberUsed(modifier.numberLimit, modifier.name, modifier.id);
	if (used !== true) {
		if (debug) errorsArray.push(used);
		// console.log(used.reason);
		return false;
	}

	const combinationCheck = combinationLimit(modifier.combinationLimit, modifier.name);
	if (combinationCheck.fail == true) {
		if (debug) errorsArray.push(combinationCheck);
		// // console.log(combinationCheck.reason);
		return false;
	}

	//Filter Products or Treatments
	const filteredItems = filterSys(modifier);
	if (filteredItems.fail == true && filteredItems.code === 301) {
		if (debug) errorsArray.push(filteredItems);
		// console.log(filteredItems.reason);
		return false;
	} else if (filteredItems.fail == true && filteredItems.code === 311) {
		//Membership is found but the user added no items. Check filtersys function for more information

		//The function stops here so the rule will be added but not calculated
		usedModifiers.push(filteredItems.modifierObj);
		return false;
	}

	//Prepare for calculation

	const acceptedItemsforDiscount = validateQuantityDiscount(modifier, filteredItems);
	if (Array.isArray(acceptedItemsforDiscount) === false) {
		if (debug) errorsArray.push(acceptedItemsforDiscount);
		return false;
	}

	const calcDiscountObj = {
		discountValue: null,
		type: "quantityDiscount", //Could be "fixedAmount","buyXX", "buyXY", "quantityDiscount"
		discountType: modifier.typeDiscount.discountType, //could be "amount", "percentage",free
		quantityRules: modifier.typeDiscount.quantityRules,
		get: acceptedItemsforDiscount,
		modifierId: modifier.id,
	};

	calculateDiscount(calcDiscountObj);
	//Everything looks fine add
	usedModifiers.push(modifier);

	return true;
}

function membership(modifier, manual, debug) {
	//Already Deleted
	const deletedCheck = deletedDiscount(modifier.id, modifier.name, modifier.addedVia);
	if (deletedCheck !== true) {
		if (debug) errorsArray.push(deletedCheck);
		// console.log(deletedCheck.reason);
		return false;
	}

	//Block equal discount duplicate blocking
	const discountOnceCheck = discountOnce(modifier.id, modifier.name);
	if (discountOnceCheck !== true) {
		if (debug) errorsArray.push(discountOnceCheck);
		// console.log(discountOnceCheck);
		return false;
	}

	/**
	 * Filter FixedAmount
	 * Could be a custom Filter by ModifiedId OR a fixedAmount modifier preset.
	 * This depends if modifier.typeDiscount.custom true
	 */
	let filteredItems = null;
	let uniqueItems = new Array();

	if (modifier.typeDiscount.custom) {
		const itemFilterIndex = modifier.typeDiscount.itemFilterIndex;
		//Push id to uniqueItems for the calcDiscountObj object
		if (itemFilterIndex.length !== 0) uniqueItems.push(selectedItems.find((item) => item.index == itemFilterIndex).id);
		uniqueItems = [...new Set(filteredItems.map((item) => item.id))];
	} else {
		//Filter Products or Treatments
		filteredItems = filterSys(modifier);
		if (filteredItems.fail == true && filteredItems.code === 312) {
			if (debug) errorsArray.push(filteredItems);
			console.log(filteredItems.reason);

			// return false;
		} else {
			//Membership found calculate Membership
			uniqueItems = [...new Set(filteredItems.map((item) => item.id))];
		}

		//Make unique for FixedAmount
	}

	//Legacy Membership code, new code is for using modifiers in combination with Memberships

	// const calcDiscountObj = {
	//   discountValue: null,
	//   type: "membership", //Could be "fixedAmount","buyXX", "buyXY", "quantityDiscount"
	//   discountType: "free", //could be "amount", "percentage",free
	//   quantityRules: null,
	//   get: uniqueItems,
	//   modifierId: modifier.id,
	// };

	//Enroll modifier ^_^
	const calcDiscountObj = {
		discountValue: 0,
		type: "fixedAmount", //Could be "fixedAmount","buyXX", "buyXY", "quantityDiscount"
		discountType: "amount", //could be "amount", "percentage",free
		quantityRules: null,
		get: uniqueItems,
		modifierId: modifier.id,
	};

	console.log("calcDiscountObj", calcDiscountObj);

	// if(modifier.toggle == false) {
	//   console.log("Modifier niet  gevonden!")
	//   usedModifiers = usedModifiers.filter(usedModifer => usedModifer.id !== "Pd9qQo4y3HAPfKrtIxgw")
	//   modifiers = modifiers.filter(usedModifer => usedModifer.id !== "Pd9qQo4y3HAPfKrtIxgw")

	// }

	let foundDiscounts = new Array();
	let peakOrOffPeak = null;

	// createdDiscounts.byMembership = true;

	// typeOfDiscountSwitch(createdDiscounts, false, false)

	//Get the right day
	let todayDay = moment().locale("en").format("ddd");

	// console.log("WAT IS DE PEAK STATUS?? POS:", modifier.peakStatus.peak,  moment().locale("en").format("ddd"))

	if (modifier.days[todayDay]) {
		// console.log("Start tijd onderscheid peak", modifier)

		//Function output object: {  peak: "peak" or "offPeak" };

		//Force Override
		if (modifier.override) {
			// console.log("Dit is mijn peak");
			modifier.peakStatus.peak = "peak";
		} else {
			//Not overrided or new added membership
			peakOrOffPeak = peakSubscriptionCheck(modifier.days[todayDay]); //?
			modifier.peakStatus = peakOrOffPeak;
		}

		//PAST DE DALL KORTING TOE

		if (modifier.peakStatus.peak === "peak") {
			// console.log("REKEN DICOUNT DOOR", modifier)
			//Start Calculation
			// calculateDiscount(calcDiscountObj);
			//Everything looks fine add
			usedModifiers.push(modifier);
		}

		if (modifier.peakStatus.peak === "offPeak") {
			//Only Add rule
			usedModifiers.push(modifier);
		}

		//Get the right Inside or Outside Peak hours discounts
		if (modifier.separatedDiscount.active === true) {
			if (modifier.peakStatus.peak === "peak") {
				//foreach  discountsInsideSubscriptionDays: ["Pd9qQo4y3HAPfKrtIxgw"],

				console.log("FOUND PEAK DISCOUNT", modifier.separatedDiscount.discountsInsideSubscriptionDays);
				modifier.separatedDiscount.discountsInsideSubscriptionDays; //?

				modifier.separatedDiscount.discountsInsideSubscriptionDays.forEach((discount) => {
					foundDiscounts.push(discount);
					// let foundDiscount =
					//   modifier.separatedDiscount.discountsInsideSubscriptionDays.find(
					//     (insideDiscount) => insideDiscount.id === discount.id
					//   );

					// if (foundDiscount) {
					//   foundDiscounts.push(foundDiscount);
					// }
				});
			}

			if (modifier.peakStatus.peak === "offPeak") {
				//Is there a split between peak and off peak discounts
				console.log("OUTSIDE PEAK");

				if (modifier.separatedDiscount.splitDiscounts === true) {
					//Get outside Discounts

					modifier.separatedDiscount.discountsOutsideSubscriptionDays.forEach((discount) => {
						// let foundDiscount =
						//   modifier.separatedDiscount.discountsOutsideSubscriptionDays.find(
						//     (insideDiscount) => insideDiscount.id === discount.id
						//   );

						// if (foundDiscount) {
						console.log("OUTSIDE PEAK 1A SPLIT");
						//   foundDiscounts.push(foundDiscount);
						// }
						foundDiscounts.push(discount);
					});
				} else {
					//Post the default inside Subscription days promotions
					//foreach discountsInsideSubscriptionDays: ["Pd9qQo4y3HAPfKrtIxgw"],

					modifier.separatedDiscount.discountsInsideSubscriptionDays.forEach((discount) => {
						// let foundDiscount =
						//   modifier.separatedDiscount.discountsInsideSubscriptionDays.find(
						//     (insideDiscount) => insideDiscount.id === discount.id
						//   );

						// if (foundDiscount) {
						console.log("OUTSIDE PEAK 2A");
						//   foundDiscounts.push(foundDiscount);
						// }
						foundDiscounts.push(discount);
					});
				}
			}
		}
	}
	//Peak Or Off-Peak
	function peakSubscriptionCheck(byDay) {
		// { end: null,
		//   name: 'saturday',
		//   active: false,
		//   allDay: null,
		//   start: null }

		//first check if active is true,

		if (byDay.active) {
			//then check if its Allday is true else look at start and end.
			if (byDay.allDay) {
				//its allDay

				return { peak: "peak" };
			} else {
				//check start and End time

				if (moment().locale("en").format("HH:mm") >= byDay.start && moment().locale("en").format("HH:mm") <= byDay.end) {
					return { peak: "peak" };
				} else {
					return { peak: "offPeak" };
				}
			}
		} else {
			return { peak: "offPeak" };
		}
	}

	// console.log("foundedDiscounts", foundDiscounts)

	//Get Feedback Message Only visible when extra discount rules added
	// if (foundDiscounts.length !== 0) {
	//   if (modifier.peakStatus.peak === "peak") {
	//     advisedModifiers.push({
	//       reason: modifier.peakMessage,
	//       code: 501,
	//       modifierName: modifier.name,
	//     });
	//   }

	//   if (modifier.peakStatus.peak === "offPeak") {
	//     advisedModifiers.push({
	//       reason: modifier.offPeakMessage,
	//       code: 502,
	//       modifierName: modifier.name,
	//     });
	//   }
	// }

	//Check if Membership is toggled on

	//Add the sorted Discount Rules by Peak or Offpeak status

	// console.log("FoundedDiscount", foundDiscounts);

	if (foundDiscounts.length !== 0) {
		foundDiscounts.forEach((discountId) => {
			let foundedDiscount = modifiers.find((allModifiers) => allModifiers.id === discountId);

			//WHen the discount is found. Side note: A discount could be deleted. So no discounts will be added.
			if (foundedDiscount) {
				//Force Priority Membership Treatment

				foundedDiscount.priority = sortDiscountOrder.find((typeOrder) => typeOrder.typeDiscount == "membershipDiscount").priorityNumber;

				// console.log("FOUNDED DISCOUNT", foundedDiscount);
				foundedDiscount.byMembership = true; //?
				typeOfDiscountSwitch(foundedDiscount, false, false);
			} else {
			}
		});
	}

	return true;
}

/**
 * Every discount can be deleted, so not use again. This is build for automatic added modifiers.
 * @param {string} id check by id
 */
function deletedDiscount(id, name, addedVia) {

	// console.log("deletedDiscount", id, name, addedVia);

	if(addedVia !== "automaticDiscount") return true;
	if (deletedModifiers.find((item) => item.id == id)) {
		return { fail: true, reason: "Discount already deleted", code: 305, modifierName: name, addedVia: addedVia };
	} else {
		return true;
	}
}

/**
 * Every discount can be used once
 * @param {string} id check by id
 */
function discountOnce(id, name) {
	if (usedModifiers.find((item) => item.id == id)) {
		return { fail: true, reason: "Discount already active", code: 304, modifierName: name };
	} else {
		return true;
	}
}

function numberUsed(numberLimit, name, id) {
	// console.log(numberLimit, name, id);
	if (numberLimit.active === true) {
		if (numberLimit.type === "max") {
			if (numberLimit.numberUsed > numberLimit.number) {
				return { fail: true, reason: "Discount maximum limit reached", code: 306, modifierName: name };
			} else {
				return true;
			}
		}

		if (numberLimit.type === "maxClient") {
			let countUsedModifierId = null;
			if (clientDataObj.usedModifiers) {
				countUsedModifierId = clientDataObj.usedModifiers.filter((used) => used.id === id);

				if (countUsedModifierId.length > numberLimit.number) {
					advisedModifiers.push({
						reason: `${clientDataObj.name} heeft al ${countUsedModifierId.length} keer gebruik gemaakt van de korting: ${name}`,
						code: 600,
						modifierName: name,
					});

					return { fail: true, reason: "Discount maximum limit (for client) reached", code: 306, modifierName: name };
				} else {
					return true;
				}
			} else {
				//usedModifiers doesnt exist so client doesnt use any modifier before.
				return true;
			}
		}
	} else {
		return true;
	}
}

/**
 * Combination limit. Could the modifier combined with other modifiers?
 * @param {boolean} combinationLimit check by id
 */
function combinationLimit(combinationLimit, name) {
	//Stop process when combinationLimit true and other discount are available
	if (combinationLimit === true && usedModifiers.size > 0) {
		return { fail: true, reason: "Combination not allowed", code: 303, modifierName: name };
	} else {
		return true;
	}
}

/**
 * filterSys for filtering related items
 * OUTPUT: true gives an object of founded items : false gives an reason
 * @param {*} modifier
 */
function filterSys(modifier) {
	console.log("Modifier FilterSys", modifier);

	let foundItems = new Object();
	foundItems.foundProducts = new Array();
	foundItems.foundTreatments = new Array();
	foundItems.foundMemberships = new Array();
	foundItems.foundPrePayments = new Array();

	//Operators
	if( modifier.modifierGroup === "downPayment") {
		getAllProducts(); //1. Products
		getAllTreatments(); //2. Treatments
	}

	//Default Discount || Loyalty Discount || Membership Discount
	if (modifier.modifierGroup === "defaultModifier" || modifier.modifierGroup === "loyaltyModifier" || modifier.modifierGroup === "membershipModifier") {
		if (modifier.type === "product") {
			if (modifier.filterProducts.active === true) {
				switch (modifier.filterProducts.type) {
					case "specific":
						console.log("filterProductsSpecific", modifier.filterProducts.items);
						filterProductsSpecific(modifier);
						break;

					case "brands":
						filterProductsBrands(modifier);
						break;

					case "categories":
						filterProductsCategories(modifier);
						break;

					default:
						break;
				}
			} else {
				//No filter is specified, get all products;
				getAllProducts();
			}
		} else if (modifier.type === "treatment") {

			// console.log("MODIFIER", modifier);
			//Filter Treatments

			if (modifier.filterTreatments.active === true) {
				switch (modifier.filterTreatments.type) {
					case "specific":
						filterTreatmentsSpecific(modifier);
						break;

					case "categories":
						filterTreatmentsCategories(modifier);
						break;

					default:
						break;
				}
			} else {
				//All
				getAllTreatments();
			}

		} else {
			// All for other future purposes
			
		}
	}

	//Memberships
	// if (modifier.modifierGroup === "membershipModifier") {
	// 	if (modifier.type === "product") {
	// 		if (modifier.filterProducts.active === true) {
	// 			console.log("filterProducts", modifier.filterProducts);
	// 			switch (modifier.filterProducts.type) {
	// 				case "specific":
	// 					console.log("filterProductsSpecific", modifier.filterProducts.items);
	// 					filterProductsSpecific(modifier);
	// 					break;

	// 				case "brands":
	// 					filterProductsBrands(modifier);
	// 					break;

	// 				case "categories":
	// 					filterProductsCategories(modifier);
	// 					break;

	// 				default:
	// 					break;
	// 			}
	// 		} else {
	// 			//No filter is specified, get all products;
	// 			getAllProducts();
	// 		}

	// 	} else if (modifier.type === "treatment") {
	// 		if (modifier.filterTreatments.active === true) {
	// 			switch (modifier.filterTreatments.type) {
	// 				case "specific":
	// 					filterTreatmentsSpecific(modifier);
	// 					break;

	// 				case "categories":
	// 					filterTreatmentsCategories(modifier);
	// 					break;

	// 				default:
	// 					break;
	// 			}
	// 		} else {
	// 			//All
	// 			getAllTreatments();
	// 		}
	// 	} else {
	// 		// All for other future purposes

	// 		console.log("No Product nor Treatment to filter ", modifier);

	// 		// if (modifier.filterTreatments.active === true) {
	// 		// 	switch (modifier.filterTreatments.type) {
	// 		// 		case "specific":
	// 		// 			filterMembershipTreatments(modifier);
	// 		// 			break;
	// 		// 		// case "brands":
	// 		// 		//   filterTreatmentsBrands(modifier);
	// 		// 		//   break;
	// 		// 		// case "categories":
	// 		// 		//   filterTreatmentsCategories(modifier);
	// 		// 		//   break;

	// 		// 		default:
	// 		// 			break;
	// 		// 	}
	// 		// }
	// 	}
	// }

	//Pre Payments
	if (modifier.modifierGroup === "prePaymentModifier") {

		console.log("modifier", modifier);

		if (modifier.type === "product") {
			if (modifier.filterProducts.active === true) {
				switch (modifier.filterTreatments.type) {
					case "all":
						filterPrePaymentsProducts(modifier);
						break;

					// case "brands":
					//   filterTreatmentsBrands(modifier);
					//   break;
					// case "categories":
					//   filterTreatmentsCategories(modifier);
					//   break;

					default:
						break;
				}
			}
		} else if (modifier.type === "treatment") {
			if (modifier.filterTreatments.active === true) {
				switch (modifier.filterTreatments.type) {
					case "all":
						filterPrePaymentsTreatments(modifier);
						break;

					case "discountPrePaid":
						filterPrePaymentsTreatmentsDiscount(modifier);
						break;

					// case "brands":
					//   filterTreatmentsBrands(modifier);
					//   break;
					// case "categories":
					//   filterTreatmentsCategories(modifier);
					//   break;

					default:
						break;
				}
			} else {
				// All
			}
		}
	}

	//Filter memberships

	//TODO also with exclude feature
	function filterMembershipTreatments(modifier) {
		if (modifier.filterTreatments.itemsAction === "include") {
			modifier.filterTreatments.items.forEach((item) => {
				selectedItems.filter((selectedItem) => {
					if (selectedItem.type === "treatment" && selectedItem.typeOfPayment === "deposit" && selectedItem.id === item) {
						const data = selectedItem;
						// data.index = index;
						// console.log("Membership Found!", data)
						foundItems.foundMemberships.push(data);
					}
				});
			});
		}
	}

	//Filter prePayments

	function filterPrePaymentsTreatments(modifier) {
		if (modifier.filterTreatments.itemsAction === "include") {
			//Continuely add new treatments from highest price to lowest price

			/**
       * The foundPrePayments variable is assigned to the selectedItems array, filtered to only
      include treatments with a typeOfPayment of "deposit", and sorted by the newPriceTotal property
      in descending order.
       */

			let foundTreatments = selectedItems
				.filter((selectedItem) => selectedItem.type == "treatment" && selectedItem.typeOfPayment === "deposit")
				.sort((a, b) => b.newPriceTotal - a.newPriceTotal);

			foundItems.foundPrePayments = [...foundItems.foundPrePayments, ...foundTreatments];
		}
	}

	function filterPrePaymentsProducts(modifier) {
		if (modifier.filterProducts.itemsAction === "include") {
			//Continuely add new products from highest price to lowest price

			/**
       * The foundPrePayments variable is assigned to the selectedItems array, filtered to only
      include treatments with a typeOfPayment of "deposit", and sorted by the newPriceTotal property
      in descending order.
       */

			let foundProducts = selectedItems
				.filter((selectedItem) => selectedItem.type == "product" && selectedItem.typeOfPayment === "deposit")
				.sort((a, b) => b.newPriceTotal - a.newPriceTotal);

			foundItems.foundPrePayments = [...foundItems.foundPrePayments, ...foundProducts];
		}
	}

	//Extra Discount Filter PrePayments Discount
	function filterPrePaymentsTreatmentsDiscount(modifier) {
		//Condition itemsAction
		if (modifier.filterTreatments.itemsAction === "include") {
			modifier.filterTreatments.items.forEach((item) => {
				selectedItems.filter((selectedItem) => {
					selectedItem.index; //?
					item; //?
					if (selectedItem.type === "treatment" && selectedItem.typeOfPayment === "deposit" && selectedItem.id === item) {
						const data = selectedItem; //?
						// data.index = index;
						foundItems.foundPrePayments.push(data);
					}
				});
			});
		} else {
			modifier.filterTreatments.items.forEach((item) => {
				selectedItems.filter((selectedItem) => {
					if (selectedItem.type === "treatment" && selectedItem.typeOfPayment === "deposit" && selectedItem.id !== item) {
						const data = selectedItem;
						// data.index = index;
						foundItems.foundPrePayments.push(data);
					}
				});
			});
		}
	}

	/**
	 * The function getAllProducts() filters the selectedItems array and returns all the items that are
	 * of type "product" and typeOfPayment "deposit"
	 */
	function getAllProducts() {
		foundItems.foundProducts = selectedItems.filter((selectedItem) => selectedItem.type === "product" && selectedItem.typeOfPayment === "deposit");
	}

	/**
	 * The function getAllTreatments() filters the selectedItems array and returns all the items that are
	 * of type "treatment" and typeOfPayment "deposit"
	 */
	function getAllTreatments() {
		foundItems.foundTreatments = selectedItems.filter((selectedItem) => selectedItem.type === "treatment" && selectedItem.typeOfPayment === "deposit"); //?
	}

	//Filter Products
	function filterProductsSpecific(modifier) {
		//Condition itemsAction
		if (modifier.filterProducts.itemsAction === "include") {
			modifier.filterProducts.items.forEach((item) => {
				selectedItems.filter((selectedItem) => {
					console.log("selectedItem", selectedItem);
					if (selectedItem.type === "product" && selectedItem.typeOfPayment === "deposit" && selectedItem.id === item) {
						const data = selectedItem;
						// data.index = index;
						foundItems.foundProducts.push(data);
					}
				});
			});
		} else {
			modifier.filterProducts.items.forEach((item) => {
				selectedItems.filter((selectedItem) => {
					if (selectedItem.type === "product" && selectedItem.typeOfPayment === "deposit" && selectedItem.id !== item) {
						const data = selectedItem;
						// data.index = index;
						foundItems.foundProducts.push(data);
					}
				});
			});
		}
	}
	//Filter Products
	function filterTreatmentsSpecific(modifier) {
		//Condition itemsAction
		if (modifier.filterTreatments.itemsAction === "include") {
			modifier.filterTreatments.items.forEach((item) => {
				selectedItems.filter((selectedItem) => {
					selectedItem.index; //?
					item; //?
					if (selectedItem.type === "treatment" && selectedItem.typeOfPayment === "deposit" && selectedItem.id === item) {
						const data = selectedItem; //?
						// data.index = index;
						foundItems.foundTreatments.push(data);
					}
				});
			});
		} else {
			modifier.filterTreatments.items.forEach((item) => {
				selectedItems.filter((selectedItem) => {
					if (selectedItem.type === "treatment" && selectedItem.typeOfPayment === "deposit" && selectedItem.id !== item) {
						const data = selectedItem;
						// data.index = index;
						foundItems.foundTreatments.push(data);
					}
				});
			});
		}
	}

	//Filter Products by Brands
	function filterProductsBrands(modifier) {
		//Condition itemsAction
		if (modifier.filterProducts.itemsAction === "include") {
			modifier.filterProducts.items.forEach((item) => {
				selectedItems.filter((selectedItem) => {
					if (selectedItem.type === "product" && selectedItem.typeOfPayment === "deposit" && selectedItem.supplier === item) {
						const data = selectedItem;
						// data.index = index;
						foundItems.foundProducts.push(data);
					}
				});
			});
		} else {
			modifier.filterProducts.items.forEach((item) => {
				selectedItems.filter((selectedItem) => {
					if (selectedItem.type === "product" && selectedItem.typeOfPayment === "deposit" && selectedItem.supplier !== item) {
						const data = selectedItem;
						// data.index = index;
						foundItems.foundProducts.push(data);
					}
				});
			});
		}
	}

	//Filter Treatments by Brands
	function filterTreatmentsBrands(modifier) {
		//Condition itemsAction
		if (modifier.filterTreatments.itemsAction === "include") {
			modifier.filterTreatments.items.forEach((item) => {
				selectedItems.filter((selectedItem) => {
					if (selectedItem.type === "treatment" && selectedItem.typeOfPayment === "deposit" && selectedItem.supplier === item) {
						const data = selectedItem;
						// data.index = index;
						foundItems.foundTreatments.push(data);
					}
				});
			});
		} else {
			modifier.filterTreatments.items.forEach((item) => {
				selectedItems.filter((selectedItem) => {
					if (selectedItem.type === "treatment" && selectedItem.typeOfPayment === "deposit" && selectedItem.supplier !== item) {
						const data = selectedItem;
						// data.index = index;
						foundItems.foundTreatments.push(data);
					}
				});
			});
		}
	}

	//Filter Products by Categorie
	function filterProductsCategories(modifier) {
		//Condition itemsAction
		if (modifier.filterProducts.itemsAction === "include") {
			modifier.filterProducts.items.forEach((item) => {
				selectedItems.filter((selectedItem, index) => {
					if (selectedItem.type === "product" && selectedItem.typeOfPayment === "deposit" && selectedItem.category.includes(item)) {
						const data = selectedItem;
						// data.index = index;
						foundItems.foundProducts.push(data);
					}
				});
			});
		} else {
			modifier.filterProducts.items.forEach((item) => {
				selectedItems.filter((selectedItem, index) => {
					if (selectedItem.type === "product" && selectedItem.typeOfPayment === "deposit" && !selectedItem.category.includes(item)) {
						const data = selectedItem;
						// data.index = index;
						foundItems.foundProducts.push(data);
					}
				});
			});
		}
	}

	//Filter Treatments by Categorie
	function filterTreatmentsCategories(modifier) {
		//Condition itemsAction
		if (modifier.filterTreatments.itemsAction === "include") {
			modifier.filterTreatments.items.forEach((item) => {
				selectedItems.filter((selectedItem, index) => {
					if (selectedItem.type === "treatment" && selectedItem.typeOfPayment === "deposit" && selectedItem.treatmentType.includes(item)) {
						const data = selectedItem;
						// data.index = index;
						foundItems.foundTreatments.push(data);
					}
				});
			});
		} else {
			modifier.filterTreatments.items.forEach((item) => {
				selectedItems.filter((selectedItem, index) => {
					if (selectedItem.type === "treatment" && selectedItem.typeOfPayment === "deposit" && !selectedItem.treatmentType.includes(item)) {
						const data = selectedItem;
						// data.index = index;
						foundItems.foundTreatments.push(data);
					}
				});
			});
		}
	}

	//Qualify restricted filters

	console.log("foundItems", foundItems);

	if (modifier.modifierGroup === "defaultModifier" || modifier.modifierGroup === "loyaltyModifier" || modifier.modifierGroup === "membershipModifier") {
		
		console.log("modifier", modifier );

		if (modifier.type === "all" ) {
			if (Object.entries(foundItems.foundProducts).length !== 0 && Object.entries(foundItems.foundTreatments).length !== 0) {
				
				console.log("foundItems", foundItems);

				return [...foundItems.foundProducts, ...foundItems.foundTreatments];
				
			} else {
				console.log({
					fail: true,
					reason: modifier.name + " => Such product and treatment not found...",
					code: 301,
					modifierName: modifier.name,
					modifierGroup: modifier.modifierGroup,
				});

				return {
					fail: true,
					reason: modifier.name + " => Such product and treatment not found...",
					code: 301,
					modifierName: modifier.name,
					modifierGroup: modifier.modifierGroup,
				};
			}
		} else if (modifier.type === "product" ) {
			console.log("foundItems.foundProducts", foundItems.foundProducts);
			if (Object.entries(foundItems.foundProducts).length !== 0) {
				return foundItems.foundProducts;
			} else {
				return {
					fail: true,
					reason: modifier.name + " => Such product not found...",
					code: 301,
					modifierName: modifier.name,
					modifierGroup: modifier.modifierGroup,
				};
			}
		} else if (modifier.type === "treatment") {
			console.log("foundItems.foundTreatments", foundItems.foundTreatments);
			if (Object.entries(foundItems.foundTreatments).length !== 0) {
				return foundItems.foundTreatments;
			} else {
				return {
					fail: true,
					reason: modifier.name + " => Such treatment not found...",
					code: 301,
					modifierName: modifier.name,
					modifierGroup: modifier.modifierGroup,
				};
			}
		} else {
			console.error("failed to filter", modifier);
		}
	}
   

	//Downpayment
	if(modifier.modifierGroup === "downPayment") {
		if (Object.entries(foundItems.foundProducts).length !== 0 || Object.entries(foundItems.foundTreatments).length !== 0) {
			return [...foundItems.foundProducts, ...foundItems.foundTreatments]; //?
		} else {
			return {
				fail: true,
				reason: "down payment " +  modifier.name + " => Such product or treatment not found...",
				code: 301,
				modifierName: modifier.name,
				modifierObj: modifier,
			};
		
		}
	}


	// //Either product AND treatment must be found.
	// if (
	// 	modifier.modifierGroup !== "membershipModifier" &&
	// 	modifier.modifierGroup !== "prePaymentModifier" &&
	// 	modifier.filterTreatments.active === true &&
	// 	modifier.filterProducts.active === true
	// ) {
	// 	if (Object.entries(foundItems.foundProducts).length !== 0 && Object.entries(foundItems.foundTreatments).length !== 0) {
	// 		return [...foundItems.foundProducts, ...foundItems.foundTreatments]; //?
	// 	} else {
	// 		if (modifier.byMembership) {
	// 			return {
	// 				fail: true,
	// 				reason: modifier.name + " => Membership product or treatment not found...",
	// 				code: 311,
	// 				modifierName: modifier.name,
	// 				modifierObj: modifier,
	// 			};
	// 		}
	// 		return { fail: true, reason: modifier.name + " => Such product or treatment not found...", code: 301, modifierName: modifier.name };
	// 	}
	// }

	// //Either product OR treatment must be found.

	// //product
	// if (modifier.modifierGroup !== "membershipModifier" && modifier.modifierGroup !== "prePaymentModifier" && modifier.filterProducts.active == true) {
	// 	if (Object.entries(foundItems.foundProducts).length !== 0) {
	// 		return foundItems.foundProducts;
	// 	} else {
	// 		if (modifier.byMembership) {
	// 			return { fail: true, reason: modifier.name + " => Membership product not found...", code: 311, modifierName: modifier.name, modifierObj: modifier };
	// 		}

	// 		return { fail: true, reason: modifier.name + " => No product found...", code: 301, modifierName: modifier.name };
	// 	}
	// }

	// //treatment
	// if (modifier.modifierGroup !== "membershipModifier" && modifier.modifierGroup !== "prePaymentModifier" && modifier.filterTreatments.active == true) {
	// 	if (Object.entries(foundItems.foundTreatments).length !== 0) {
	// 		return foundItems.foundTreatments;
	// 	} else {
	// 		if (modifier.byMembership) {
	// 			return { fail: true, reason: modifier.name + " => Membership treatment not found...", code: 311, modifierName: modifier.name, modifierObj: modifier };
	// 		}

	// 		return { fail: true, reason: modifier.name + " => No treatment found...", code: 301, modifierName: modifier.name };
	// 	}
	// }
	// //Membership Treatment
	// if (modifier.modifierGroup === "membershipModifier" && modifier.filterTreatments.active == true) {
	// 	if (Object.entries(foundItems.foundMemberships).length !== 0) {
	// 		return foundItems.foundMemberships;
	// 	} else {
	// 		return { fail: true, reason: modifier.name + " => No Membership treatment found...", code: 312, modifierName: modifier.name, modifierObj: modifier };
	// 	}
	// }

	// if (modifier.modifierGroup === "prePaymentModifier") {
	// 	if (Object.entries(foundItems.foundPrePayments).length !== 0) {
	// 		return foundItems.foundPrePayments;
	// 	} else {
	// 		return { fail: true, reason: modifier.name + " => No PrePayment treatment found...", code: 312, modifierName: modifier.name, modifierObj: modifier };
	// 	}
	// }
}

/**
 * Mix and Match Interpreter
 * 1 Cluster Items
 * 2 Sort Highest amount
 * 3 Calculate Ratio Gives Advice when needed
 * 4 how many times is the discount possible AND/OR check the limits
 * 5 outputs an Array with getQuantity used for object "calcDiscountObj"


 * @param {object} modifier - the discount object
 * @param {array} filteredItems - filteredItems to validate
 * return: object for calculation OR return false when fails with advise
 */
function mixMatchInterpreter(modifier, filteredItems) {
	const buyQuantity = modifier.typeDiscount.buyQuantity;
	const getQuantity = modifier.typeDiscount.getQuantity;

	switch (modifier.typeDiscount.type) {
		case "buyXX":
			const getXXquantity = validateQuantityXX(modifier, filteredItems);
			if (Array.isArray(getXXquantity) === true) {
				return getXXquantity;
			} else {
				return false;
			}

			break;

		case "buyXY":
			const getXYquantity = validateQuantityXY(modifier, filteredItems);
			if (Array.isArray(getXYquantity) === true) {
				return getXYquantity;
			} else {
				return false;
			}

			break;

		default:
			break;
	}

	/**
	 * validateQuantityXX
	 * UniqueCheck X to X, example product must be 1111 OR 2222 not 1113 OR 1234 with respect to Categorie and Brand filter
	 * OUTPUT: Calculate Object for Calculation Array
	 * @param {object} modifier - the discount object
	 * @param {array} filteredItems - filteredItems to validate
	 */
	function validateQuantityXX(modifier, filteredItems) {
		/**
		 * Check Uniq
		 * If both Treatment AND Product filters are active uniqueSet can be with a value size of 2
		 * If Treatment OR Product uniqueSet value size can be only 1
		 * 1111 means 1 unique
		 * OR 1113 (3) as treatment
		 */
		const clusteredSameItems = getSameItems(filteredItems);

		/**
		 * getSameItems buyXX
		 * for BuyXX every item needs to be strictly the same. Like 1111 or 2222 and NOT 1113 or 1234 like buyXY
		 * @param {array} filteredItems
		 */
		function getSameItems(filteredItems) {
			const uniqSet = new Set();
			filteredItems.forEach((item) => uniqSet.add(item.name));

			let uniqItems = new Array();

			//Get all the items of uniq item and cluster together. Always de [0] because the other items are the same.
			uniqSet.forEach((uniqItem) => {
				filteredItems.filter((item) => item.name == uniqItem)[0].name;
				const foundedItems = filteredItems.filter((item) => item.name == uniqItem);
				uniqItems.push({
					id: foundedItems[0].id,
					name: foundedItems[0].name,
					amount: foundedItems.length, //<- amount of clustered items found.
					type: foundedItems[0].type,
					VAT: foundedItems[0].percentageVAT,
					priceNetto: foundedItems[0].priceNetto, //<- Based on Netto
				});
			});

			return uniqItems;
		}

		/**
		 * get the Highest Amount of Uniq Clustered Items and Orded by 1 priceNetto then 2 VAT
		 * @param {array} clusteredSameItems
		 */
		function getSameHighestAmountSorted(clusteredSameItems) {
			// clusteredSameItems; //?
			let filtered = clusteredSameItems.filter((uniqRatio) => uniqRatio.amount == Math.max(...clusteredSameItems.map((rat) => rat.amount))); //?

			//legalcy _.sortBy(filtered, ["VAT", "priceBruto"]).reverse();
			return filtered.sort(function (priceA, priceB) {
				// Sort by Price
				if (priceA.priceNetto > priceB.priceNetto) return -1;
				if (priceA.priceNetto < priceB.priceNetto) return 1;

				// Sort by VAT
				if (priceA.percentageVAT > priceB.percentageVAT) return -1;
				if (priceA.percentageVAT < priceB.percentageVAT) return 1;
			});
		}
		//Get the Highest number when its more then one give advise for both.
		const highestAmount = getSameHighestAmountSorted(clusteredSameItems); //?

		const approvedForDiscount = calculateRatioOptionB(highestAmount, buyQuantity, getQuantity);
		//check approvedForDiscount before continue
		if (approvedForDiscount === true) {
			const getQuantityDiscount = howManyTimesDiscount(highestAmount[0].amount, buyQuantity, getQuantity); //?
			//Filter items by Highest amount and sort by PriceNetto and VAT
			//Sorted Double for the sake of strict filtering total getQuantity items
			const newQuantity = filteredItems
				.filter((item) => item.id == highestAmount[0].id)
				.sort(function (priceA, priceB) {
					// Sort by Price
					if (priceA.priceNetto > priceB.priceNetto) return -1;
					if (priceA.priceNetto < priceB.priceNetto) return 1;

					// Sort by VAT
					if (priceA.percentageVAT > priceB.percentageVAT) return -1;
					if (priceA.percentageVAT < priceB.percentageVAT) return 1;
				})
				.map((item) => item.id)
				.splice(0, getQuantityDiscount);

			return newQuantity;

			// return howManyTimesDiscount(highestAmount[0].amount, buyQuantity, getQuantity); //?
		} else {
			//Terminate by reason approvedForDiscount
			return approvedForDiscount;
		}

		/**
		 * Calculate For Advise OPTION B buy XY
		 * @param {array} highestAmount[0] - Sorted by priceNetto. Always [0] because this one is sorted by priceNetto
		 * @param {*} buyQuantity
		 * @param {*} getQuantity
		 */
		function calculateRatioOptionB(highestAmount, buyQuantity, getQuantity) {
			let ratio = buyQuantity - 1;
			let productAdvice = "";
			let treatmentAdvice = "";
			let bothActive = modifier.filterProducts.active === true && modifier.filterTreatments.active === true ? " en/of " : "";

			if (modifier.filterProducts.active === true) {
				if (modifier.filterProducts.type == "categories") {
					productAdvice = "producten binnen dezelfde categorie";
				} else if (modifier.filterProducts.type == "brands") {
					productAdvice = "producten binnen hetzelfde merk";
				} else if (modifier.filterProducts.type == "specific") {
					productAdvice = "producten met dezelfde specificatie";
				}
			}

			if (modifier.filterTreatments.active === true) {
				if (modifier.filterTreatments.type == "categories") {
					treatmentAdvice = "behandelingen binnen dezelfde categorie";
				} else if (modifier.filterTreatments.type == "specific") {
					treatmentAdvice = "behandelingen met dezelfde specificatie";
				}
			}

			//Take the first in the array because the prices al even.
			if (highestAmount[0].amount < buyQuantity && highestAmount[0].amount >= ratio) {
				//When its almost
				// console.log(
				//   `Activeer de korting "${modifier.name}" door het volgende toe te voegen: ${productAdvice}${bothActive}${treatmentAdvice}. Bijvoorbeeld ` +
				//     highestAmount.map((item) => "een " + item.type + " " + item.name).join(" of ")
				// );
				advisedModifiers.push({
					reason:
						`De actie is bijna actief! Activeer deze actie door het volgende toe te voegen. ${productAdvice}${bothActive}${treatmentAdvice}. Bijvoorbeeld nog ` +
						highestAmount.map((item) => "een " + item.type + " " + item.name).join(" of "),
					code: 405,
					modifierName: modifier.name,
				});

				// advisedModifiers.push({
				//   reason: `Activeer deze actie door het volgende toe te voegen: ` + highestAmount.map((item) => "een " + item.type + " " + item.name).join(" of "),
				//   code: 406,
				//   modifierName: modifier.name,
				// });
				return { fail: true, reason: modifier.name + " => Discount almost active", code: 406, modifierName: modifier.name };
			} else if (highestAmount[0].amount < ratio) {
				//When ratios is too low
				advisedModifiers.push({
					reason:
						`Activeer deze actie door meer ${productAdvice}${bothActive}${treatmentAdvice} toe te voegen, bijvoorbeeld nog ${
							buyQuantity - highestAmount[0].amount
						} extra ` + highestAmount.map((item) => item.type + "(en)" + " " + item.name).join(" of "),
					code: 406,
					modifierName: modifier.name,
				});
				return { fail: true, reason: modifier.name + " => Amount below ratio", code: 307, modifierName: modifier.name };
			} else if (highestAmount[0].amount >= buyQuantity) {
				//When its Amount is higher or equal buy

				return true;
			}
		}
	}

	/**
	 * validateQuantityXY
	 * UniqueCheck X to Y, example product must be 1234 OR 1111 depends on "UniqueItems" Boolean not 1113 OR 1234 with respect to Categorie and Brand filter
	 * OUTPUT: Calculate Object for Calculation Array
	 * @param {object} modifier - the discount object
	 * @param {array} filteredItems - filteredItems to validate
	 *
	 */
	function validateQuantityXY(modifier, filteredItems) {
		/**
		 * uniqueModifier === true : every items needs to be uniq 1234 option A
		 * else it must be option B 1111 (getSameItems) or option C 1123 (filtereItemsMapped)
		 * Wich option between B or C depends on most founded Item.
		 * When every option is not equal to "buyQuantitiy" it will return with false and (if needed) advice.
		 */

		if (modifier.typeDiscount.uniqueItems === true) {
			//OPTION A if this option fails, every item must be unique

			return optionA(modifier, filteredItems); //?
		} else {
			//OPTION B OR C

			/**
       * clusteredSameitemsOptionB example:
       *  [ { id: 'BBB',
            name: 'Blush 2',
            amount: 4,
            type: 'product',
            VAT: 21,
            priceNetto: 39 } ]
       */
			const clusteredSameItemsOptionB = getSameItems(filteredItems); //?

			const filteredItemsOptionC = filteredItems.map((filteredItem) => {
				return { id: filteredItem.id, name: filteredItem.name, type: filteredItem.type, VAT: filteredItem.percentageVAT, priceNetto: filteredItem.priceNetto };
			}); //?

			//Which Option wins?
			if (clusteredSameItemsOptionB[0].amount >= filteredItemsOptionC.length) {
				//Returns the GET quantitiies -> ['AAAA', 'DDDD']
				//option B = 1111 or 2222 etc..

				/**
				 * ROUTE TO XX
				 * then to calculateDiscount and return the new discount
				 */
				let manipulatedModifier = modifier;
				manipulatedModifier.typeDiscount.type = "buyXX";

				const getQuantity = mixMatchInterpreter(manipulatedModifier, filteredItems);
				if (getQuantity === false) return false;
				const calcDiscountObj = {
					discountValue: modifier.typeDiscount.discountValue,
					type: "buyXX", //Could be "fixedAmount","buyXX", "buyXY", "quantityDiscount"
					discountType: modifier.typeDiscount.discountType, //could be "amount", "percentage",free
					quantityRules: null,
					get: getQuantity,
					modifierId: modifier.id,
				};

				calculateDiscount(calcDiscountObj);
				//Everything looks fine add
				usedModifiers.push(modifier);

				return true;
			} else {
				//option C = 1233 or 1344 etc..
				return optionC(filteredItemsOptionC, modifier, filteredItems);
			}
		}

		/**
		 * getSameItems buyXY
		 * for BuyXY every item needs to be strictly the same. Like 1111 or 2222 and NOT 1113 or 1234 like buyXY
		 * @param {array} filteredItems
		 */
		function getSameItems(filteredItems) {
			const uniqSet = new Set();
			filteredItems.forEach((item) => uniqSet.add(item.name));

			let uniqItems = new Array();

			//Get all the items of uniq item and cluster together. Always de [0] because the other items are the same.
			uniqSet.forEach((uniqItem) => {
				filteredItems.filter((item) => item.name == uniqItem)[0].name;
				const foundedItems = filteredItems.filter((item) => item.name == uniqItem);
				uniqItems.push({
					id: foundedItems[0].id,
					name: foundedItems[0].name,
					amount: foundedItems.length, //<- amount of clustered items found.
					type: foundedItems[0].type,
					VAT: foundedItems[0].percentageVAT,
					priceNetto: foundedItems[0].priceNetto, //<- Based on Netto
				});
			});

			return uniqItems;
		}

		//Option A every item must be unique
		function optionA(modifier, filteredItems) {
			//Get Uniq Items
			const uniqSet = new Set();
			filteredItems.forEach((item) => uniqSet.add(item.id));

			const uniqItems = [...uniqSet];
			uniqItems.length; //?

			const uniqWithHighestPrice = getUniqueWithHighestPrice(uniqItems).sort(function (priceA, priceB) {
				// Sort by Price
				if (priceA.priceNetto < priceB.priceNetto) return -1;
				if (priceA.priceNetto > priceB.priceNetto) return 1;

				// Sort by VAT
				if (priceA.percentageVAT > priceB.percentageVAT) return -1;
				if (priceA.percentageVAT < priceB.percentageVAT) return 1;
			}); //?

			//Get Every Uniq product and sort it by priceNetto and VAT
			function getUniqueWithHighestPrice(uniqItems) {
				let tmpUniqHighestItem = new Array();
				uniqItems.forEach((uniqItem) => {
					//Get always the [0] because of uniqness and highest Netto Price

					tmpUniqHighestItem.push(
						selectedItems
							.filter((selectedItem) => selectedItem.id == uniqItem)
							.sort(function (priceA, priceB) {
								// Sort by Price
								if (priceA.priceNetto < priceB.priceNetto) return -1;
								if (priceA.priceNetto > priceB.priceNetto) return 1;

								// Sort by VAT
								if (priceA.percentageVAT > priceB.percentageVAT) return -1;
								if (priceA.percentageVAT < priceB.percentageVAT) return 1;
							})[0]
					);
				});
				return tmpUniqHighestItem; //?
			}

			const approvedForDiscount = calculateRatioOptionA(uniqWithHighestPrice, buyQuantity, getQuantity);

			if (approvedForDiscount === true) {
				const getQuantityDiscount = howManyTimesDiscount(uniqWithHighestPrice.length, buyQuantity, getQuantity);
				//Splice array by getQuantityDiscount no sort needed already done

				const newQuantity = uniqWithHighestPrice.map((item) => item.id).splice(0, getQuantityDiscount);
				return newQuantity;
			} else {
				//Terminate by reason approvedForDiscount
				return approvedForDiscount;
			}

			function calculateRatioOptionA(uniqWithHighestPrice, buyQuantity, getQuantity) {
				let ratio = buyQuantity - 1;
				let productAdvice = "";
				let treatmentAdvice = "";
				let bothActive = modifier.filterProducts.active === true && modifier.filterTreatments.active === true ? " en/of " : "";

				//Take the first in the array because the prices al even.
				if (uniqWithHighestPrice.length < buyQuantity && uniqWithHighestPrice.length >= ratio) {
					//When its almost

					if (modifier.filterProducts.active === true) {
						if (modifier.filterProducts.type == "categories") {
							productAdvice = "unieke producten binnen dezelfde categorie";
						} else if (modifier.filterProducts.type == "brands") {
							productAdvice = "unieke producten binnen hetzelfde merk";
						} else if (modifier.filterProducts.type == "specific") {
							productAdvice = "unieke producten met dezelfde specificatie";
						}
					}

					if (modifier.filterTreatments.active === true) {
						if (modifier.filterTreatments.type == "categories") {
							treatmentAdvice = "unieke behandelingen binnen dezelfde categorie";
						} else if (modifier.filterTreatments.type == "specific") {
							treatmentAdvice = "unieke behandelingen met dezelfde specificatie";
						}
					}

					// console.log(
					//   `Activeer de korting "${modifier.name}" door het volgende toe te voegen: ${productAdvice}${bothActive}${treatmentAdvice}. Bijvoorbeeld iets anders dan ` +
					//     uniqWithHighestPrice.map((item) => "een " + item.type + " " + item.name).join(" of ")
					// );

					advisedModifiers.push({
						reason:
							`De actie is bijna actief! Activeer deze actie door het volgende toe te voegen, ${productAdvice}${bothActive}${treatmentAdvice}. Bijvoorbeeld iets anders dan ` +
							uniqWithHighestPrice.map((item) => "een " + item.type + " " + item.name).join(" of "),
						code: 405,
						modifierName: modifier.name,
					});

					return { fail: true, reason: modifier.name + " => Discount almost active", code: 406 };
				} else if (uniqWithHighestPrice.length < ratio) {
					//When ratios is too low
					return { fail: true, reason: modifier.name + " => Amount below ratio", code: 307 };
				} else if (uniqWithHighestPrice.length >= buyQuantity) {
					//When its Amount is higher or equal buy

					return true;
				}
			}
		}

		//Option B
		// function optionB(clusteredSameItemsOptionB, modifier, filteredItems) {
		//   /**
		//    * get the Highest Amount of Uniq Clustered Items and Orded by 1 priceNetto then 2 VAT
		//    * @param {array} clusteredSameItemsOptionB
		//    */
		//   function getSameHighestAmountSortedOptionB(clusteredSameItemsOptionB) {
		//     // clusteredSameItemsOptionB; //?
		//     let filtered = clusteredSameItemsOptionB.filter((uniqRatio) => uniqRatio.amount == Math.max(...clusteredSameItemsOptionB.map((rat) => rat.amount))); //?

		//     //legalcy _.sortBy(filtered, ["VAT", "priceBruto"]).reverse();
		//     return filtered.sort(function(priceA, priceB) {
		//       // Sort by Price
		//       if (priceA.priceNetto > priceB.priceNetto) return -1;
		//       if (priceA.priceNetto < priceB.priceNetto) return 1;

		//       // Sort by VAT
		//       if (priceA.percentageVAT > priceB.percentageVAT) return -1;
		//       if (priceA.percentageVAT < priceB.percentageVAT) return 1;
		//     });
		//   }
		//   //Get the Highest number when its more then one give advise for both.
		//   const highestAmount = getSameHighestAmountSortedOptionB(clusteredSameItemsOptionB);

		//   const approvedForDiscount = calculateRatioOptionB(highestAmount, buyQuantity, getQuantity);
		//   //check approvedForDiscount before continue
		//   if (approvedForDiscount === true) {
		//     const getQuantityDiscount = howManyTimesDiscount(highestAmount[0].amount, buyQuantity, getQuantity);
		//     //Filter items by Highest amount and sort by PriceNetto and VAT
		//     //Sorted Double for the sake of strict filtering total getQuantity for the [0] items 1111
		//     const newQuantity = filteredItems
		//       .filter((item) => item.id == highestAmount[0].id)
		//       .sort(function(priceA, priceB) {
		//         // Sort by Price
		//         if (priceA.priceNetto > priceB.priceNetto) return -1;
		//         if (priceA.priceNetto < priceB.priceNetto) return 1;

		//         // Sort by VAT
		//         if (priceA.percentageVAT > priceB.percentageVAT) return -1;
		//         if (priceA.percentageVAT < priceB.percentageVAT) return 1;
		//       })
		//       .map((item) => item.id)
		//       .splice(0, getQuantityDiscount);

		//     return newQuantity;

		//     // return howManyTimesDiscount(highestAmount[0].amount, buyQuantity, getQuantity);
		//   } else {
		//     //Terminate by reason approvedForDiscount
		//     return approvedForDiscount;
		//   }

		//   /**
		//    * Calculate For Advise
		//    * @param {array} highestAmount[0] - Sorted by priceNetto. Always [0] because this one is sorted by priceNetto
		//    * @param {*} buyQuantity
		//    * @param {*} getQuantity
		//    */
		//   function calculateRatioOptionB(highestAmount, buyQuantity, getQuantity) {
		//     let ratio = buyQuantity - 1;

		//     //Take the first in the array because the prices al even.
		//     if (highestAmount[0].amount < buyQuantity && highestAmount[0].amount >= ratio) {
		//       //When its almost
		//       console.log(`Activeer de korting "${modifier.name}" door het volgende toe te voegen: ` + highestAmount.map((item) => "een " + item.type + " " + item.name).join(" of "));
		//       advisedModifiers.push(`Activeer de korting "${modifier.name}" door het volgende toe te voegen: ` + highestAmount.map((item) => "een " + item.type + " " + item.name).join(" of "));
		//       return { fail: true, reason: modifier.name + " => Discount almost active", code: 406 };
		//     } else if (highestAmount[0].amount < ratio) {
		//       //When ratios is too low
		//       return { fail: true, reason: modifier.name + " => Amount below ratio", code: 307 };
		//     } else if (highestAmount[0].amount >= buyQuantity) {
		//       //When its Amount is higher or equal buy

		//       return true;
		//     }
		//   }
		// } //End Function optionB

		//Option C
		function optionC(filteredItemsOptionC, modifier, filteredItems) {
			const sortHighestPrice = filteredItemsOptionC.sort(function (priceA, priceB) {
				// Sort by Price
				if (priceA.priceNetto > priceB.priceNetto) return -1;
				if (priceA.priceNetto < priceB.priceNetto) return 1;

				// Sort by VAT
				if (priceA.percentageVAT > priceB.percentageVAT) return -1;
				if (priceA.percentageVAT < priceB.percentageVAT) return 1;
			});

			const approvedForDiscount = calculateRatioOptionC(sortHighestPrice, buyQuantity, getQuantity, modifier);

			if (approvedForDiscount === true) {
				const getQuantityDiscount = howManyTimesDiscount(sortHighestPrice.length, buyQuantity, getQuantity); //?
				const newQuantity = sortHighestPrice
					.sort(function (priceA, priceB) {
						// Sort by Price
						if (priceA.priceNetto > priceB.priceNetto) return -1;
						if (priceA.priceNetto < priceB.priceNetto) return 1;

						// Sort by VAT
						if (priceA.percentageVAT > priceB.percentageVAT) return -1;
						if (priceA.percentageVAT < priceB.percentageVAT) return 1;
					})
					.map((item) => item.id)
					.splice(0, getQuantityDiscount);

				return newQuantity;
			} else {
				//Terminate by reason approvedForDiscount
				return approvedForDiscount;
			}

			function calculateRatioOptionC(sortHighestPrice, buyQuantity, getQuantity, modifier) {
				let ratio = buyQuantity - 1;
				let productAdvice = "";
				let treatmentAdvice = "";
				let bothActive = modifier.filterProducts.active === true && modifier.filterTreatments.active === true ? " en/of " : "";

				sortHighestPrice.length; //?

				//Take the first in the array because the prices al even.
				if (sortHighestPrice.length < buyQuantity && sortHighestPrice.length >= ratio) {
					//When its almost

					if (modifier.filterProducts.active === true) {
						if (modifier.filterProducts.type == "categories") {
							productAdvice = "producten binnen dezelfde categorie";
						} else if (modifier.filterProducts.type == "brands") {
							productAdvice = "producten binnen hetzelfde merk";
						} else if (modifier.filterProducts.type == "specific") {
							productAdvice = "producten met dezelfde specificatie";
						}
					}

					if (modifier.filterTreatments.active === true) {
						if (modifier.filterTreatments.type == "categories") {
							treatmentAdvice = "behandelingen binnen dezelfde categorie";
						} else if (modifier.filterTreatments.type == "specific") {
							treatmentAdvice = "behandelingen met dezelfde specificatie";
						}
					}

					// console.log(
					//   `Activeer de korting "${modifier.name}" door het volgende toe te voegen: ${productAdvice}${bothActive}${treatmentAdvice}. Bijvoorbeeld een extra ` +
					//     sortHighestPrice.map((item) => "een " + item.type + " " + item.name).join(" of ")
					// );
					advisedModifiers.push({
						reason:
							`De actie is bijna actieff! Activeer deze actie door het volgende toe te voegen. ${productAdvice}${bothActive}${treatmentAdvice}. Bijvoorbeeld iets anders dan ` +
							sortHighestPrice.map((item) => "een " + item.type + " " + item.name).join(" of "),
						code: 405,
						modifierName: modifier.name,
					});
					return { fail: true, reason: modifier.name + " => Discount almost active", code: 406 };
				} else if (sortHighestPrice.length < ratio) {
					//When ratios is too low
					return { fail: true, reason: modifier.name + " => Amount below ratio", code: 307 };
				} else if (sortHighestPrice.length >= buyQuantity) {
					//When its Amount is higher or equal buy
					return true;
				}
			}
		}
	}

	/**
	 * Calculate how many times Discount will be earned
	 * AND also check its limits
	 * The getQuantity will multiplied by X posabillitys to calculate.
	 * @param {number} input - Is the highestAmount[0].amount
	 * @param {number} buyQuantity
	 * @param {number} getQuantity
	 */
	function howManyTimesDiscount(input, buyQuantity, getQuantity) {
		//Devide how many times and round to floor.
		let possibleDiscount = Math.floor(input / buyQuantity); //?

		//Check Use limit
		if (modifier.useLimit.active === true) {
			if (modifier.useLimit.number < possibleDiscount) {
				possibleDiscount = modifier.useLimit.number;
			}
		}
		// (x) * possibility
		return getQuantity * possibleDiscount; //?
	}
} //END mixMatchInterpreter

/**
 * Make it Uniq and Validate before calculation
 * @param {object} modifier
 * @param {array} filteredItems
 * OUTPUT: Uniq Array
 */
function validateQuantityDiscount(modifier, filteredItems) {
	//Make it uniq
	let uniqItems = new Set();
	filteredItems.forEach((item) => uniqItems.add(item.id));

	uniqItems = [...uniqItems];

	return checkRatio(uniqItems);

	//Is there enough for giving a quantityDiscount anyway?
	function checkRatio(uniqItems) {
		let acceptedQuantity = new Array();
		uniqItems.forEach((uniqItem) => {
			const getQuantity = selectedItems.filter((item) => item.id === uniqItem).length; //?

			//Get the first quantityRule (if possible)
			if (getQuantity >= modifier.typeDiscount.quantityRules[0].number) {
				acceptedQuantity.push(uniqItem);
			}

			if (modifier.typeDiscount.quantityRules.length >= 2) {
				if (getQuantity >= modifier.typeDiscount.quantityRules[0].number && getQuantity < modifier.typeDiscount.quantityRules[1].number) {
					const nameQuantities = acceptedQuantity
						.map((itemId) => {
							return selectedItems.find((filterItem) => filterItem.id == itemId).name;
						})
						.join(" en / of "); //?
					// selectedItems.filter() uniqWithHighestPrice.map((item) => "een " + item.type + " " + item.name).join(" of "),
					advisedModifiers.push({
						reason: `Deze bundel promotie is activeerd, voeg meer van hetzelfde toe bijvoorbeeld een ${nameQuantities} voor meer promotie.`,
						code: 407,
						modifierName: modifier.name,
					});
				}
			}
		});

		acceptedQuantity; //?

		if (acceptedQuantity.length !== 0) {
			return acceptedQuantity;
		} else {
			return { fail: true, reason: modifier.name + " => Not enough quantity for this discount", code: 309, modifierName: modifier.name };
		}
	}
}

//CALCULATE THE DISCOUNTS
/**
 * Loop thru the get propety of calcDiscountObj
 * @param {object} calcDiscountObj
 */
function calculateDiscount(calcDiscountObj) {
	switch (calcDiscountObj.type) {
		case "fixedAmount":
			if (calcDiscountObj.repetitive === true) {
				return repetitiveAmountCalc(calcDiscountObj);
			} else {
				return fixedAmountCalc(calcDiscountObj); //Filter hoogste eerst daar het verschil terug rekenen.
			}

			break;

		case "buyXX":
			calcDiscountObj.get.forEach((getQuantityId) => {
				iterateHighestPrice(calcDiscountObj, getQuantityId); //Filter hoogste eerst daar het verschil terug rekenen.
			});
			break;

		case "buyXY":
			calcDiscountObj.get.forEach((getQuantityId) => {
				iterateHighestPrice(calcDiscountObj, getQuantityId); //Filter hoogste eerst daar het verschil terug rekenen.
			});
			break;

		case "quantityDiscount":
			calcDiscountObj.get.forEach((getQuantityId) => {
				iterateHighestPrice(calcDiscountObj, getQuantityId); //Filter hoogste eerst daar het verschil terug rekenen.
			});
			break;

		case "membership":
			calcDiscountObj.get.forEach((getQuantityId) => {
				iterateHighestPrice(calcDiscountObj, getQuantityId); //Filter hoogste eerst daar het verschil terug rekenen.
			});
			break;

		default:
			break;
	}
}

/**
 * Calculate the till the discountValue runs till zero
 * @param {object} calcDiscountObj
 */
function fixedAmountCalc(calcDiscountObj) {
	//Inverse discount to negative
	let remainingAmount = -Math.abs(calcDiscountObj.discountValue); //?

	if (calcDiscountObj.discountType === "amount") {

		console.log("calcDiscountObj", calcDiscountObj);
		calcDiscountObj.get.forEach((getQuantityId) => {
			let tmpHighestNewPrice = selectedItems.filter((item) => item.id === getQuantityId).sort((a, b) => b.newPriceTotal - a.newPriceTotal); //?
			for (let i = 0; i < tmpHighestNewPrice.length; i++) {
				if (remainingAmount >= 0) break;

				//Calculate remainingAmount
				// remainingAmount = (tmpHighestNewPrice[i].newPriceTotal * 10000 + remainingAmount * 10000) / 10000; //?
				remainingAmount = cashConv(tmpHighestNewPrice[i].newPriceTotal + remainingAmount, "EUR", 4);

				// tmpHighestNewPrice[i].newPriceTotal //?
				// remainingAmount //?
				// tmpHighestNewPrice[i].newPriceTotal - remainingAmount //?
				/**
				 * FLOATING POINT WORKAROUND BECAUSE THE NUMBERS GOING NEGATIVE
				 */

				if (remainingAmount <= 0) {
					//there is a residual remaining amount left

					let percentageVAT = selectedItems[tmpHighestNewPrice[i].index].percentageVAT;
					let calculatedDiscountVAT = cashConv(remainingAmount + tmpHighestNewPrice[i].newPriceTotal - remainingAmount, "EUR", 4) //?
					let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8); //?

					//Total new Price Bruto
					selectedItems[tmpHighestNewPrice[i].index].newPriceTotal = 0;
					//Total new Price Netto
					selectedItems[tmpHighestNewPrice[i].index].newPriceTotalNetto = 0;
					//Total given Discount in VAT
					selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountVAT = cashConv(selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
					//Total given Discount in Netto
					selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountNetto = cashConv(selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
					//Modifier ID
					//Custom Modifier always have a uniqId with 8 Characters
					selectedItems[tmpHighestNewPrice[i].index].usedModifierId.add(calcDiscountObj.modifierId);
					// selectedItems[tmpHighestNewPrice[i].index].discountValueByModifier.push({modifierId: calcDiscountObj.modifierId, givenDiscount: calcDiscountObj.discountValue, remain: remainingAmount })

					if (!selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers)
						selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers = new Array();

					//

					selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers.push({
						modifierId: calcDiscountObj.modifierId,
						vatPercentage: percentageVAT,
						givenDiscount: calculatedDiscountVAT,
						discountType: calcDiscountObj.discountType,
						discountValue: calcDiscountObj.discountValue,
						manualInputDiscount: calcDiscountObj.manualInputDiscount
					});

					console.log(
						"remaining < modifierId",
						calcDiscountObj.modifierId,
						"selectedItem Id",
						selectedItems[tmpHighestNewPrice[i].index].id,
						"givenDiscountBTW",
						calculatedDiscountVAT,
						"remain",
						remainingAmount
					);
				}
				if (remainingAmount >= 0) {
					//there is NO a residual amount left, so the modifier is fully used
					//btw = (bedrag incl btw/(100+btw%)) x btw%
					let percentageVAT = selectedItems[tmpHighestNewPrice[i].index].percentageVAT;
					let calculatedDiscountVAT = cashConv(tmpHighestNewPrice[i].newPriceTotal - remainingAmount, "EUR", 8); //?
					let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8); //?

					//Total new Price Bruto
					selectedItems[tmpHighestNewPrice[i].index].newPriceTotal = remainingAmount; //?
					//Total new Price Netto
					selectedItems[tmpHighestNewPrice[i].index].newPriceTotalNetto = cashConv(
						remainingAmount - (remainingAmount / (100 + percentageVAT)) * percentageVAT,
						"EUR",
						8
					); //?
					//Total given Discount in VAT
					selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountVAT = cashConv(selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
					//Total given Discount in Netto
					selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountNetto = cashConv(selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
		

					//Modifier ID
					//Custom Modifier always have a uniqId with 8 Characters
					selectedItems[tmpHighestNewPrice[i].index].usedModifierId.add(calcDiscountObj.modifierId);

					if (!selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers)
						selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers = new Array();

					console.log(
						"remaining > modifierId",
						calcDiscountObj.modifierId,
						"selectedItem Id",
						selectedItems[tmpHighestNewPrice[i].index].id,
						"givenDiscountBTW",
						calculatedDiscountVAT,
						"remain",
						remainingAmount
					);

					selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers.push({
						modifierId: calcDiscountObj.modifierId,
						vatPercentage: percentageVAT,
						givenDiscount: calculatedDiscountVAT,
						discountType: calcDiscountObj.discountType,
						discountValue: calcDiscountObj.discountValue,
						manualInputDiscount: calcDiscountObj.manualInputDiscount
					});
				}
			}

			console.log("remainingAmount: ", remainingAmount);
		});
	} //end  amount

	if (calcDiscountObj.discountType === "percentage") {
		calcDiscountObj.get.forEach((getQuantityId) => {
			let tmpHighestNewPrice = selectedItems.filter((item) => item.id === getQuantityId).sort((a, b) => b.newPriceTotal - a.newPriceTotal); //?
			for (let i = 0; i < tmpHighestNewPrice.length; i++) {
				if (remainingAmount >= 0) break;

				//Calculate remainingAmount
				// remainingAmount = (tmpHighestNewPrice[i].newPriceTotal * 10000 + remainingAmount * 10000) / 10000; //?
				remainingAmount = cashConv(tmpHighestNewPrice[i].newPriceTotal - (tmpHighestNewPrice[i].priceBruto * calcDiscountObj.discountValue) / 100, "EUR", 8);

				// tmpHighestNewPrice[i].newPriceTotal //?
				// remainingAmount //?
				// tmpHighestNewPrice[i].newPriceTotal - remainingAmount //?
				/**
				 * FLOATING POINT WORKAROUND BECAUSE THE NUMBERS GOING NEGATIVE
				 */

				if (remainingAmount <= 0) {
					//there is a residual remaining amount left

					let percentageVAT = selectedItems[tmpHighestNewPrice[i].index].percentageVAT;
					// let calculatedDiscountVAT = cashConv(remainingAmount + tmpHighestNewPrice[i].priceBruto  - remainingAmount) / 10000; //?

					tmpHighestNewPrice[i].newPriceTotal; //?
					remainingAmount; //?
					selectedItems[tmpHighestNewPrice[i].index].priceBruto; //?

					let calculatedDiscountVAT = cashConv(selectedItems[tmpHighestNewPrice[i].index].priceBruto - remainingAmount, "EUR", 8); //?

					// let calculatedDiscountVAT = (remainingAmount * 10000 + tmpHighestNewPrice[i].newPriceTotal * 10000 - remainingAmount * 10000) / 10000; //?
					let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8); //?

					//Total new Price Bruto
					selectedItems[tmpHighestNewPrice[i].index].newPriceTotal = 0;
					//Total new Price Netto
					selectedItems[tmpHighestNewPrice[i].index].newPriceTotalNetto = 0;
					//Total given Discount in VAT
					selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountVAT += calculatedDiscountVAT;
					//Total given Discount in Netto
					selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountNetto += calculatedDiscountNetto;
					//Modifier ID
					//Custom Modifier always have a uniqId with 8 Characters
					selectedItems[tmpHighestNewPrice[i].index].usedModifierId.add(calcDiscountObj.modifierId);
					// selectedItems[tmpHighestNewPrice[i].index].discountValueByModifier.push({modifierId: calcDiscountObj.modifierId, givenDiscount: calcDiscountObj.discountValue, remain: remainingAmount })

					if (!selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers)
						selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers = new Array();

					//
					console.log(
						"remaining < modifierId",
						calcDiscountObj.modifierId,
						"selectedItem Id",
						selectedItems[tmpHighestNewPrice[i].index].id,
						"givenDiscountBTW",
						calculatedDiscountVAT,
						"remain",
						remainingAmount
					);

					selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers.push({
						modifierId: calcDiscountObj.modifierId,
						vatPercentage: percentageVAT,
						givenDiscount: calculatedDiscountVAT,
						discountType: calcDiscountObj.discountType,
						discountValue: calcDiscountObj.discountValue,
					});
				}
				if (remainingAmount > 0) {
					//there is NO a residual amount left, so the modifier is fully used
					//btw = (bedrag incl btw/(100+btw%)) x btw%
					let percentageVAT = selectedItems[tmpHighestNewPrice[i].index].percentageVAT;

					//how much promotion is given by the modifier
					let calculatedDiscountVAT = cashConv(selectedItems[tmpHighestNewPrice[i].index].priceBruto - remainingAmount, "EUR", 8);

					// let calculatedDiscountVAT = cashConv(remainingAmount + tmpHighestNewPrice[i].newPriceTotal  - remainingAmount, "EUR", 8);
					let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

					//Total new Price Bruto
					selectedItems[tmpHighestNewPrice[i].index].newPriceTotal = remainingAmount; //?
					//Total new Price Netto
					selectedItems[tmpHighestNewPrice[i].index].newPriceTotalNetto = cashConv(
						remainingAmount - (remainingAmount / (100 + percentageVAT)) * percentageVAT,
						"EUR",
						8
					); //?
					//Total given Discount in VAT
					selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountVAT = cashConv(selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
					//Total given Discount in Netto
					selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountNetto =  cashConv(selectedItems[tmpHighestNewPrice[i].index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);

					//Modifier ID
					//Custom Modifier always have a uniqId with 8 Characters
					selectedItems[tmpHighestNewPrice[i].index].usedModifierId.add(calcDiscountObj.modifierId);

					if (!selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers)
						selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers = new Array();

					console.log(
						"remaining > modifierId",
						calcDiscountObj.modifierId,
						"selectedItem Id",
						selectedItems[tmpHighestNewPrice[i].index].id,
						"givenDiscountBTW",
						calculatedDiscountVAT,
						"remain",
						remainingAmount
					);

					selectedItems[tmpHighestNewPrice[i].index].discountValueByModifiers.push({
						modifierId: calcDiscountObj.modifierId,
						vatPercentage: percentageVAT,
						givenDiscount: calculatedDiscountVAT,
						discountType: calcDiscountObj.discountType,
						discountValue: calcDiscountObj.discountValue,
					});
				}
			}

			// console.log("remainingAmount: ", remainingAmount);
		});
	} //end percentage

	selectedItems[0].calculatedDiscountVAT; //?

	return { modifierId: calcDiscountObj.modifierId, remainingAmount: remainingAmount };
}

function repetitiveAmountCalc(calcDiscountObj) {
	if (calcDiscountObj.discountType === "amount") {
		calcDiscountObj; //?
		calcDiscountObj.get.forEach((getQuantityId) => {
			
			selectedItems.forEach((selectedItem, indexer) => {
				
        if (selectedItem.id === getQuantityId) {
					//if the selectedItem has the same id as the getQuantityId

					//Note there isn't any highest Item because we will calculate all the selectedItems
					let selectedItem = selectedItems.find((item) => getQuantityId == item.id);

					let discountPrice = selectedItem.newPriceTotal - calcDiscountObj.discountValue;
					let percentageVAT = selectedItem.percentageVAT;

					if (discountPrice < 0) {
						let calculatedDiscountVAT = cashConv(selectedItem.newPriceTotal - discountPrice, "EUR", 8); //?
						let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

						//Total new Price Netto
						selectedItems[indexer].newPriceTotalNetto = 0;
						//Total new Price Bruto
						selectedItems[indexer].newPriceTotal = 0;
						//Calculated Discount VAT
						selectedItems[indexer].calculatedDiscountVAT = cashConv(selectedItems[indexer].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
						//Calculated Discount Netto
						selectedItems[indexer].calculatedDiscountNetto = cashConv(selectedItems[indexer].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
						//Modifier ID
						selectedItems[indexer].usedModifierId.add(calcDiscountObj.modifierId);

						if (!selectedItems[indexer].discountValueByModifiers) selectedItems[indexer].discountValueByModifiers = new Array();

						selectedItems[indexer].discountValueByModifiers.push({
							modifierId: calcDiscountObj.modifierId,
							vatPercentage: percentageVAT,
							givenDiscount: calculatedDiscountVAT,
							discountType: calcDiscountObj.discountType,
							discountValue: calcDiscountObj.discountValue,
						});

						return;
					} else {
						let calculatedDiscountVAT = cashConv(selectedItem.newPriceTotal - discountPrice, "EUR", 8);
						let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

						//Total new Price Netto
						selectedItems[indexer].newPriceTotalNetto = cashConv(discountPrice - (discountPrice / (100 + percentageVAT)) * percentageVAT, "EUR", 8);
						//Total new Price Bruto
						selectedItems[indexer].newPriceTotal = discountPrice;
						//Calculated Discount VAT
						selectedItems[indexer].calculatedDiscountVAT = cashConv(selectedItems[indexer].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
						//Calculated Discount Netto
						selectedItems[indexer].calculatedDiscountNetto = cashConv(selectedItems[indexer].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);

						//Modifier ID
						selectedItems[indexer].usedModifierId.add(calcDiscountObj.modifierId);

						if (!selectedItems[indexer].discountValueByModifiers) selectedItems[indexer].discountValueByModifiers = new Array();

						selectedItems[indexer].discountValueByModifiers.push({
							modifierId: calcDiscountObj.modifierId,
							vatPercentage: percentageVAT,
							givenDiscount: calculatedDiscountVAT,
							discountType: calcDiscountObj.discountType,
							discountValue: calcDiscountObj.discountValue,
						});

						return;
					}
				}
			});
		}); //end get.forEach
	} //end if amount

	//Percentage instead of amount
	if (calcDiscountObj.discountType === "percentage") {
		calcDiscountObj; //?
		calcDiscountObj.get.forEach((getQuantityId) => {
			//Note there isn't any highest Item because we will calculate all the selectedItems
			selectedItems.forEach((selectedItem, indexer) => {
				if (selectedItem.id === getQuantityId) {

			let discountPrice = cashConv(selectedItem.newPriceTotal - (selectedItem.priceBruto * calcDiscountObj.discountValue) / 100, "EUR", 8);
			let percentageVAT = selectedItem.percentageVAT;

			if (discountPrice < 0) {
				let calculatedDiscountVAT = cashConv(selectedItem.newPriceTotal - discountPrice, "EUR", 8); //?
				let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

				//Total new Price Netto
				selectedItems[indexer].newPriceTotalNetto = 0;
				//Total new Price Bruto
				selectedItems[indexer].newPriceTotal = 0;
				//Calculated Discount VAT
				selectedItems[indexer].calculatedDiscountVAT = cashConv(selectedItems[indexer].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
				//Calculated Discount Netto
				selectedItems[indexer].calculatedDiscountNetto = cashConv(selectedItems[indexer].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
				//Modifier ID
				selectedItems[indexer].usedModifierId.add(calcDiscountObj.modifierId);

				if (!selectedItems[indexer].discountValueByModifiers) selectedItems[indexer].discountValueByModifiers = new Array();

				selectedItems[indexer].discountValueByModifiers.push({
					modifierId: calcDiscountObj.modifierId,
					vatPercentage: percentageVAT,
					givenDiscount: calculatedDiscountVAT,
					discountType: calcDiscountObj.discountType,
					discountValue: calcDiscountObj.discountValue,
				});
				return;
			} else {
				selectedItem.newPriceTotal //?
				let calculatedDiscountVAT = cashConv(selectedItem.newPriceTotal - discountPrice, "EUR", 8); //?
				let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

				//Total new Price Netto
				selectedItems[indexer].newPriceTotalNetto = cashConv(discountPrice - (discountPrice / (100 + percentageVAT)) * percentageVAT, "EUR", 8);
				//Total new Price Bruto
				selectedItems[indexer].newPriceTotal = discountPrice;
				//Calculated Discount VAT
				selectedItems[indexer].calculatedDiscountVAT = cashConv(selectedItems[indexer].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
				
				//Calculated Discount Netto
				selectedItems[indexer].calculatedDiscountNetto = cashConv(selectedItems[indexer].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
				
				//Modifier ID
				selectedItems[indexer].usedModifierId.add(calcDiscountObj.modifierId);

				if (!selectedItems[indexer].discountValueByModifiers) selectedItems[indexer].discountValueByModifiers = new Array();

				selectedItems[indexer].discountValueByModifiers.push({
					modifierId: calcDiscountObj.modifierId,
					vatPercentage: percentageVAT,
					givenDiscount: calculatedDiscountVAT,
					discountType: calcDiscountObj.discountType,
					discountValue: calcDiscountObj.discountValue,
				});
				return;
			}
    }
  });
		}); //end get.forEach
	} //end if percentage

	//Free Item
	if (calcDiscountObj.discountType === "free") {
		calcDiscountObj.get.forEach((getQuantityId) => {
			//Note there isn't any highest Item because we will calculate all the selectedItems
			selectedItems.forEach((selectedItem, indexer) => {
				if (selectedItem.id === getQuantityId) {

			let percentageVAT = selectedItem.percentageVAT;

			let calculatedDiscountVAT = selectedItem.newPriceTotal;
			let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

			//Total new Price Netto
			selectedItems[indexer].newPriceTotalNetto = 0;
			//Total new Price Bruto
			selectedItems[indexer].newPriceTotal = 0;
			//Calculated Discount VAT
			selectedItems[indexer].calculatedDiscountVAT = cashConv(selectedItems[indexer].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
			//Calculated Discount Netto
			selectedItems[indexer].calculatedDiscountNetto = cashConv(selectedItems[indexer].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
			
			//Modifier ID
			selectedItems[indexer].usedModifierId.add(calcDiscountObj.modifierId);

			if (!selectedItems[indexer].discountValueByModifiers) selectedItems[indexer].discountValueByModifiers = new Array();
			selectedItems[indexer].discountValueByModifiers.push({
				modifierId: calcDiscountObj.modifierId,
				vatPercentage: percentageVAT,
				givenDiscount: calculatedDiscountVAT,
				discountType: calcDiscountObj.discountType,
				discountValue: calcDiscountObj.discountValue,
			});
        }
      });
		}); //end get.forEach
	}

	return { modifierId: calcDiscountObj.modifierId, remainingAmount: 0 };
}

/**
 * Get (highestNewPriceTotal) by highest price and subtract discount. Till the difference is 0
 * @param {*} calcDiscountObj - discount obj
 * @param {*} getQuantityId - get object from discount object (calcDiscountObj)
 * @param {*} difference - amount of difference for recursion
 * @param {boolean} recursion - true or false
 *
 */
function iterateHighestPrice(calcDiscountObj, getQuantityId) {
	//Get the highest newest price left
	let highestNewPriceTotal = Math.max(...selectedItems.filter((PTfilter) => PTfilter.id == getQuantityId).map((element) => element.newPriceTotal));
	// highestNewPriceTotal; //?
	let highestItem = selectedItems.find((item) => getQuantityId == item.id && item.newPriceTotal == highestNewPriceTotal); //?
	// highestItem.name; //?
	return calculateDiscountType(calcDiscountObj.type, calcDiscountObj, highestItem, getQuantityId);
}

/**
 * Calculates "buyXX", "buyXY", "quantityDiscount"
 * @param {string} type - discountType "buyXX", "buyXY", "quantityDiscount"
 * @param {object} calcDiscountObj
 * @param {object} highestItem
 * @param {object} getQuantityId - object discount from function calculateDiscount
 */
function calculateDiscountType(type, calcDiscountObj, highestItem, getQuantityId) {
	let percentageVAT = highestItem.percentageVAT;

	switch (type) {
		case "buyXX":
			// let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);
			if (calcDiscountObj.discountType === "amount") {
				let calcXX = highestItem.newPriceTotal - calcDiscountObj.discountValue;

				if (calcXX < 0) {
					let calculatedDiscountVAT = cashConv(calcXX + highestItem.newPriceTotal - calcXX, "EUR", 8);
					let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

					//Total new Price Netto
					selectedItems[highestItem.index].newPriceTotalNetto = 0;
					//Total new Price Bruto
					selectedItems[highestItem.index].newPriceTotal = 0;
					//Calculated Discount VAT
					selectedItems[highestItem.index].calculatedDiscountVAT = cashConv(selectedItems[highestItem.index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
					//Calculated Discount Netto
					selectedItems[highestItem.index].calculatedDiscountNetto = cashConv(selectedItems[highestItem.index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
					//Modifier ID
					selectedItems[highestItem.index].usedModifierId.add(calcDiscountObj.modifierId);

					if (!selectedItems[highestItem.index].discountValueByModifiers) selectedItems[highestItem.index].discountValueByModifiers = new Array();

					selectedItems[highestItem.index].discountValueByModifiers.push({
						modifierId: calcDiscountObj.modifierId,
						vatPercentage: percentageVAT,
						givenDiscount: calculatedDiscountVAT,
						discountType: calcDiscountObj.discountType,
						discountValue: calcDiscountObj.discountValue,
					});

					return;
				} else {
					let calculatedDiscountVAT = calcDiscountObj.discountValue;
					let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

					//Total new Price Netto
					selectedItems[highestItem.index].newPriceTotalNetto = cashConv(calcXX - (calcXX / (100 + percentageVAT)) * percentageVAT, "EUR", 8);
					//Total new Price Bruto
					selectedItems[highestItem.index].newPriceTotal = calcXX;
					//Calculated Discount VAT
					selectedItems[highestItem.index].calculatedDiscountVAT = cashConv(selectedItems[highestItem.index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
					//Calculated Discount Netto
					selectedItems[highestItem.index].calculatedDiscountNetto = cashConv(selectedItems[highestItem.index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
					//Modifier ID
					selectedItems[highestItem.index].usedModifierId.add(calcDiscountObj.modifierId);

					if (!selectedItems[highestItem.index].discountValueByModifiers) selectedItems[highestItem.index].discountValueByModifiers = new Array();

					selectedItems[highestItem.index].discountValueByModifiers.push({
						modifierId: calcDiscountObj.modifierId,
						vatPercentage: percentageVAT,
						givenDiscount: calculatedDiscountVAT,
						discountType: calcDiscountObj.discountType,
						discountValue: calcDiscountObj.discountValue,
					});

					return;
				}
			}

			if (calcDiscountObj.discountType === "percentage") {
				let calcXX = cashConv(highestItem.newPriceTotal - (highestItem.priceBruto * calcDiscountObj.discountValue) / 100, "EUR", 8);

				if (calcXX < 0) {
					let calculatedDiscountVAT = cashConv(calcXX + highestItem.newPriceTotal - calcXX, "EUR", 8);
					let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

					//Total new Price Netto
					selectedItems[highestItem.index].newPriceTotalNetto = 0;
					//Total new Price Bruto
					selectedItems[highestItem.index].newPriceTotal = 0; //?
					//Total given Discount in VAT
					selectedItems[highestItem.index].calculatedDiscountVAT = cashConv(selectedItems[highestItem.index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
					//Total given Discount in Netto
					selectedItems[highestItem.index].calculatedDiscountNetto = cashConv(selectedItems[highestItem.index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
					//Modifier ID
					selectedItems[highestItem.index].usedModifierId.add(calcDiscountObj.modifierId);

					if (!selectedItems[highestItem.index].discountValueByModifiers) selectedItems[highestItem.index].discountValueByModifiers = new Array();

					selectedItems[highestItem.index].discountValueByModifiers.push({
						modifierId: calcDiscountObj.modifierId,
						vatPercentage: percentageVAT,
						givenDiscount: calculatedDiscountVAT,
						discountType: calcDiscountObj.discountType,
						discountValue: calcDiscountObj.discountValue,
					});

					return;
				} else {
					let calculatedDiscountVAT = cashConv(calcXX + highestItem.newPriceTotal - calcXX, "EUR", 8);
					let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

					//Total new Price Netto
					selectedItems[highestItem.index].newPriceTotalNetto = cashConv(calcXX - (calcXX / (100 + percentageVAT)) * percentageVAT, "EUR", 8);
					//Total new Price Bruto
					selectedItems[highestItem.index].newPriceTotal = calcXX;
					//Total given Discount in VAT
					selectedItems[highestItem.index].calculatedDiscountVAT  = cashConv(selectedItems[highestItem.index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
					//Total given Discount in Netto
					selectedItems[highestItem.index].calculatedDiscountNetto = cashConv(selectedItems[highestItem.index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
					//Modifier ID
					selectedItems[highestItem.index].usedModifierId.add(calcDiscountObj.modifierId);

					if (!selectedItems[highestItem.index].discountValueByModifiers) selectedItems[highestItem.index].discountValueByModifiers = new Array();

					selectedItems[highestItem.index].discountValueByModifiers.push({
						modifierId: calcDiscountObj.modifierId,
						vatPercentage: percentageVAT,
						givenDiscount: calculatedDiscountVAT,
						discountType: calcDiscountObj.discountType,
						discountValue: calcDiscountObj.discountValue,
					});

					return;
				}
			}

			if (calcDiscountObj.discountType === "free") {
				let calculatedDiscountVAT = highestItem.newPriceTotal;
				let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);
				//Total new Price Netto
				selectedItems[highestItem.index].newPriceTotalNetto = 0;
				//Total new Price Bruto
				selectedItems[highestItem.index].newPriceTotal = 0;
				//Total given Discount in VAT
				selectedItems[highestItem.index].calculatedDiscountVAT = cashConv(selectedItems[highestItem.index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
				//Total given Discount in Netto
				selectedItems[highestItem.index].calculatedDiscountNetto = cashConv(selectedItems[highestItem.index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
				//Modifier ID
				selectedItems[highestItem.index].usedModifierId.add(calcDiscountObj.modifierId);

				if (!selectedItems[highestItem.index].discountValueByModifiers) selectedItems[highestItem.index].discountValueByModifiers = new Array();

				selectedItems[highestItem.index].discountValueByModifiers.push({
					modifierId: calcDiscountObj.modifierId,
					vatPercentage: percentageVAT,
					givenDiscount: calculatedDiscountVAT,
					discountType: calcDiscountObj.discountType,
					discountValue: calcDiscountObj.discountValue,
				});

				return;
			}

			break;

		case "buyXY":
			// let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);
			if (calcDiscountObj.discountType === "amount") {
				highestItem.newPriceTotal; //?
				let calcXY = highestItem.newPriceTotal - calcDiscountObj.discountValue;

				if (calcXY < 0) {
					let calculatedDiscountVAT = cashConv(calcXY + highestItem.newPriceTotal - calcXY, "EUR", 8);
					let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

					//Total new Price Netto
					selectedItems[highestItem.index].newPriceTotalNetto = 0;
					//Total new Price Bruto
					selectedItems[highestItem.index].newPriceTotal = 0;
					//Calculated Discount VAT
					selectedItems[highestItem.index].calculatedDiscountVAT = cashConv(selectedItems[highestItem.index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
					//Calculated Discount Netto
					selectedItems[highestItem.index].calculatedDiscountNetto = cashConv(selectedItems[highestItem.index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
					//Modifier ID
					selectedItems[highestItem.index].usedModifierId.add(calcDiscountObj.modifierId);

					if (!selectedItems[highestItem.index].discountValueByModifiers) selectedItems[highestItem.index].discountValueByModifiers = new Array();

					selectedItems[highestItem.index].discountValueByModifiers.push({
						modifierId: calcDiscountObj.modifierId,
						vatPercentage: percentageVAT,
						givenDiscount: calculatedDiscountVAT,
						discountType: calcDiscountObj.discountType,
						discountValue: calcDiscountObj.discountValue,
					});

					return;
				} else {
					let calculatedDiscountVAT = calcDiscountObj.discountValue;
					let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

					//Total new Price Netto
					selectedItems[highestItem.index].newPriceTotalNetto = cashConv(calcXY - (calcXY / (100 + percentageVAT)) * percentageVAT, "EUR", 8);
					//Total new Price Bruto
					selectedItems[highestItem.index].newPriceTotal = calcXY;
					//Calculated Discount VAT
					selectedItems[highestItem.index].calculatedDiscountVAT = cashConv(selectedItems[highestItem.index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
					//Calculated Discount Netto
					selectedItems[highestItem.index].calculatedDiscountNetto = cashConv(selectedItems[highestItem.index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
					//Modifier ID
					selectedItems[highestItem.index].usedModifierId.add(calcDiscountObj.modifierId);

					if (!selectedItems[highestItem.index].discountValueByModifiers) selectedItems[highestItem.index].discountValueByModifiers = new Array();

					selectedItems[highestItem.index].discountValueByModifiers.push({
						modifierId: calcDiscountObj.modifierId,
						vatPercentage: percentageVAT,
						givenDiscount: calculatedDiscountVAT,
						discountType: calcDiscountObj.discountType,
						discountValue: calcDiscountObj.discountValue,
					});

					return;
				}
			}

			if (calcDiscountObj.discountType === "percentage") {
				let calcXY = highestItem.newPriceTotal - (highestItem.priceBruto * calcDiscountObj.discountValue) / 100;

				if (calcXY < 0) {
					let calculatedDiscountVAT = cashConv(calcXY + highestItem.newPriceTotal - calcXY, "EUR", 8);
					let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

					//Total new Price Netto
					selectedItems[highestItem.index].newPriceTotalNetto = 0;
					//Total new Price Bruto
					selectedItems[highestItem.index].newPriceTotal = 0; //?
					//Total given Discount in VAT
					selectedItems[highestItem.index].calculatedDiscountVAT = cashConv(selectedItems[highestItem.index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
					//Total given Discount in Netto
					selectedItems[highestItem.index].calculatedDiscountNetto = cashConv(selectedItems[highestItem.index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
					//Modifier ID
					selectedItems[highestItem.index].usedModifierId.add(calcDiscountObj.modifierId);

					if (!selectedItems[highestItem.index].discountValueByModifiers) selectedItems[highestItem.index].discountValueByModifiers = new Array();

					selectedItems[highestItem.index].discountValueByModifiers.push({
						modifierId: calcDiscountObj.modifierId,
						vatPercentage: percentageVAT,
						givenDiscount: calculatedDiscountVAT,
						discountType: calcDiscountObj.discountType,
						discountValue: calcDiscountObj.discountValue,
					});

					return;
				} else {
					let calculatedDiscountVAT = cashConv(calcXY + highestItem.newPriceTotal - calcXY, "EUR", 8);
					let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

					//Total new Price Netto
					selectedItems[highestItem.index].newPriceTotalNetto = cashConv(calcXY - (calcXY / (100 + percentageVAT)) * percentageVAT, "EUR", 8);
					//Total new Price Bruto
					selectedItems[highestItem.index].newPriceTotal = calcXY;
					//Total given Discount in VAT
					selectedItems[highestItem.index].calculatedDiscountVAT = cashConv(selectedItems[highestItem.index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
					//Total given Discount in Netto
					selectedItems[highestItem.index].calculatedDiscountNetto = cashConv(selectedItems[highestItem.index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
					//Modifier ID
					selectedItems[highestItem.index].usedModifierId.add(calcDiscountObj.modifierId);

					if (!selectedItems[highestItem.index].discountValueByModifiers) selectedItems[highestItem.index].discountValueByModifiers = new Array();

					selectedItems[highestItem.index].discountValueByModifiers.push({
						modifierId: calcDiscountObj.modifierId,
						vatPercentage: percentageVAT,
						givenDiscount: calculatedDiscountVAT,
						discountType: calcDiscountObj.discountType,
						discountValue: calcDiscountObj.discountValue,
					});

					return;
				}
			}

			if (calcDiscountObj.discountType === "free") {
				let calculatedDiscountVAT = highestItem.newPriceTotal;
				let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);
				//Total new Price Netto
				selectedItems[highestItem.index].newPriceTotalNetto = 0;
				//Total new Price Bruto
				selectedItems[highestItem.index].newPriceTotal = 0;
				//Total given Discount in VAT
				selectedItems[highestItem.index].calculatedDiscountVAT = cashConv(selectedItems[highestItem.index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
				//Total given Discount in Netto
				selectedItems[highestItem.index].calculatedDiscountNetto = cashConv(selectedItems[highestItem.index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
				//Modifier ID
				selectedItems[highestItem.index].usedModifierId.add(calcDiscountObj.modifierId);

				if (!selectedItems[highestItem.index].discountValueByModifiers) selectedItems[highestItem.index].discountValueByModifiers = new Array();

				selectedItems[highestItem.index].discountValueByModifiers.push({
					modifierId: calcDiscountObj.modifierId,
					vatPercentage: percentageVAT,
					givenDiscount: calculatedDiscountVAT,
					discountType: calcDiscountObj.discountType,
					discountValue: calcDiscountObj.discountValue,
				});

				return;
			}

			break;

		case "quantityDiscount":
			//HIGHEST
			//Check range of quantityRules
			let getQuantity = selectedItems.filter((item) => item.id === getQuantityId).length;
			//Iterate thru discount rules and filter / find by total founded quantity

			let discountByRule = new Number();

			/**
			 * Check if there is any rule to match equal for the quantitiy (A)
			 * Sort by closest (B) and then get the lowest amount.
			 * Because we do not want to give too much discount by itself:)
			 * So from the sorted rules we will pick the lowest. (C)
			 */

			//A
			if (calcDiscountObj.quantityRules.some((rule) => rule.number === getQuantity)) {
				discountByRule = calcDiscountObj.quantityRules.find((rule) => rule.number >= getQuantity).discount;
			} else {
				//B
				discountByRule = calcDiscountObj.quantityRules.sort((a, b) => {
					return Math.abs(getQuantity - a.number) - Math.abs(getQuantity - b.number);
				});
				//C
				discountByRule = Math.min(...discountByRule.slice(0, 2).map((rule) => rule.number));
			}

			discountByRule; //?

			selectedItems.forEach((itemIterate) => {
				if (itemIterate.id === getQuantityId) {
					if (calcDiscountObj.discountType === "amount") {
						//If range is found

						let calcQuantityDiscount = itemIterate.newPriceTotal - discountByRule; //?

						if (calcQuantityDiscount < 0) {
							let calculatedDiscountVAT = cashConv(calcQuantityDiscount + discountByRule, "EUR", 8);
							let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);
							//Total new Price Netto
							selectedItems[itemIterate.index].newPriceTotalNetto = 0;
							//Total new Price Bruto
							selectedItems[itemIterate.index].newPriceTotal = 0;
							//Calculated Discount VAT
							selectedItems[itemIterate.index].calculatedDiscountVAT = cashConv(selectedItems[itemIterate.index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
							//Calculated Discount Netto
							selectedItems[itemIterate.index].calculatedDiscountNetto = cashConv(selectedItems[itemIterate.index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
							//Modifier ID
							selectedItems[itemIterate.index].usedModifierId.add(calcDiscountObj.modifierId);

							if (!selectedItems[highestItem.index].discountValueByModifiers) selectedItems[highestItem.index].discountValueByModifiers = new Array();

							selectedItems[highestItem.index].discountValueByModifiers.push({
								modifierId: calcDiscountObj.modifierId,
								vatPercentage: percentageVAT,
								givenDiscount: calculatedDiscountVAT,
								discountType: calcDiscountObj.discountType,
								discountValue: calcDiscountObj.discountValue,
							});

							return;
						} else {
							let calculatedDiscountVAT = discountByRule; //?
							let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

							//Total new Price Netto
							selectedItems[itemIterate.index].newPriceTotalNetto = cashConv(
								calcQuantityDiscount - (calcQuantityDiscount / (100 + percentageVAT)) * percentageVAT,
								"EUR",
								8
							);
							//Total new Price Bruto
							selectedItems[itemIterate.index].newPriceTotal = calcQuantityDiscount;
							//Calculated Discount VAT
							selectedItems[itemIterate.index].calculatedDiscountVAT = cashConv(selectedItems[itemIterate.index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
							//Calculated Discount Netto
							selectedItems[itemIterate.index].calculatedDiscountNetto = cashConv(selectedItems[itemIterate.index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
							//Modifier ID
							selectedItems[itemIterate.index].usedModifierId.add(calcDiscountObj.modifierId);

							selectedItems[highestItem.index].discountValueByModifiers.push({
								modifierId: calcDiscountObj.modifierId,
								vatPercentage: percentageVAT,
								givenDiscount: calculatedDiscountVAT,
								discountType: calcDiscountObj.discountType,
								discountValue: calcDiscountObj.discountValue,
							});

							return;
						}
					}

					if (calcDiscountObj.discountType === "percentage") {
						let calcQuantityDiscount = itemIterate.newPriceTotal - (itemIterate.priceBruto * discountByRule) / 100; //?

						if (calcQuantityDiscount < 0) {
							itemIterate.newPriceTotal;
							let calculatedDiscountVAT = itemIterate.newPriceTotal;
							let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

							//Total new Price Netto
							selectedItems[itemIterate.index].newPriceTotalNetto = 0;
							//Total new Price Bruto
							selectedItems[itemIterate.index].newPriceTotal = 0;
							//Calculated Discount VAT
							selectedItems[itemIterate.index].calculatedDiscountVAT = cashConv(selectedItems[itemIterate.index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
							//Calculated Discount Netto
							selectedItems[itemIterate.index].calculatedDiscountNetto = cashConv(selectedItems[itemIterate.index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
							//Modifier ID
							selectedItems[itemIterate.index].usedModifierId.add(calcDiscountObj.modifierId);

							if (!selectedItems[highestItem.index].discountValueByModifiers) selectedItems[highestItem.index].discountValueByModifiers = new Array();

							selectedItems[highestItem.index].discountValueByModifiers.push({
								modifierId: calcDiscountObj.modifierId,
								vatPercentage: percentageVAT,
								givenDiscount: calculatedDiscountVAT,
								discountType: calcDiscountObj.discountType,
								discountValue: calcDiscountObj.discountValue,
							});

							return;
						} else {
							let calculatedDiscountVAT = (itemIterate.newPriceTotal * discountByRule) / 100;
							let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);

							//Total new Price Netto
							selectedItems[itemIterate.index].newPriceTotalNetto = cashConv(
								calcQuantityDiscount - (calcQuantityDiscount / (100 + percentageVAT)) * percentageVAT,
								"EUR",
								8
							);
							//Total new Price Bruto
							selectedItems[itemIterate.index].newPriceTotal = calcQuantityDiscount;
							//Calculated Discount VAT
							selectedItems[itemIterate.index].calculatedDiscountVAT = cashConv(selectedItems[itemIterate.index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
							//Calculated Discount Netto
							selectedItems[itemIterate.index].calculatedDiscountNetto = cashConv(selectedItems[itemIterate.index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
							//Modifier ID
							selectedItems[itemIterate.index].usedModifierId.add(calcDiscountObj.modifierId);

							if (!selectedItems[highestItem.index].discountValueByModifiers) selectedItems[highestItem.index].discountValueByModifiers = new Array();

							selectedItems[highestItem.index].discountValueByModifiers.push({
								modifierId: calcDiscountObj.modifierId,
								vatPercentage: percentageVAT,
								givenDiscount: calculatedDiscountVAT,
								discountType: calcDiscountObj.discountType,
								discountValue: calcDiscountObj.discountValue,
							});

							return;
						}
					}
				}
			});

			break;

		case "membership":
			if (calcDiscountObj.discountType === "free") {
				let calculatedDiscountVAT = highestItem.newPriceTotal;
				let calculatedDiscountNetto = cashConv(calculatedDiscountVAT - (calculatedDiscountVAT / (100 + percentageVAT)) * percentageVAT, "EUR", 8);
				//Total new Price Netto
				selectedItems[highestItem.index].newPriceTotalNetto = 0;
				//Total new Price Bruto
				selectedItems[highestItem.index].newPriceTotal = 0;
				//Total given Discount in VAT
				selectedItems[highestItem.index].calculatedDiscountVAT = cashConv(selectedItems[highestItem.index].calculatedDiscountVAT + calculatedDiscountVAT, "EUR", 8);
				//Total given Discount in Netto
				selectedItems[highestItem.index].calculatedDiscountNetto = cashConv(selectedItems[highestItem.index].calculatedDiscountNetto + calculatedDiscountNetto, "EUR", 8);
				//Modifier ID

				selectedItems[highestItem.index].usedMembershipId.add(calcDiscountObj.modifierId);
				return;
			}

			break;

		default:
			break;
	}
}

//Debug Info
// selectedItems[0].newPriceTotal; //?
// selectedItems[0].calculatedDiscountVAT; //?
// selectedItems[0].name; //?
// selectedItems[0].newPriceTotal; //?
// selectedItems[1].name; //?
// selectedItems[2].newPriceTotal; //?
// selectedItems[2].name; //?
// selectedItems[3].newPriceTotal; //?
// selectedItems[3].name; //?
