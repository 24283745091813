
<template>
  <div>
    <div v-if="dataloaded" class="pb-4">
      <div class="clear">
        <companySelector @companyChanged="companyUpdate"></companySelector>
      </div>
    </div>

    <div v-if="dataloaded == false" class="thrive-loader"></div>

    <v-tabs class="mb-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].background" show-arrows v-model="tab"" style="border-bottom: thin solid" :style="{'border-color' : $themes[$store.state.companyTheme][$store.state.themeModus].separator }">
      <v-tab>{{ $t('financial.discounts', $store.state.locale)}}</v-tab>
      <v-tab>{{ $t('financial.giftcards', $store.state.locale)}}</v-tab>
      <v-tab>{{ $t('financial.revenue', $store.state.locale)}}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="transparant-tabs">
      <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
        <discounts></discounts>
      </v-tab-item>
      <v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition">
        <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card"></v-card>
      </v-tab-item>
      <v-tab-item :value="2" :transition="tabTransition" :reverse-transition="tabTransition">
        <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card"></v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { bus } from "../main";
import Discounts from "./Discounts.vue";
import PackagesOverview from "./PackagesOverview.vue";
import companySelector from "@/components/elements/companySelector.vue";

export default {
  components: {
    discounts: Discounts,
    companySelector: companySelector,
    "packages-overview": PackagesOverview
  },
  name: "Financials",
  data: () => ({
    tab: 0,
    tabTransition: false,
    dataloaded: true,
  }),

  methods: {
    companyUpdate(){
      bus.$emit("companyUpdate");
    },
  },
};
</script>
