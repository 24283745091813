<template>
   <v-dialog v-model="showDialog" scrollable max-width="700px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="type=='new'">{{ $t('memberships.newSubscription', $store.state.locale) }}</h3>
        <h3 v-if="type=='edit'">{{ $t('memberships.editSubscription', $store.state.locale) }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-tabs :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" v-model="tab" show-arrows :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
        <v-tab><v-icon left>mdi-information</v-icon> {{ $t('general.general', $store.state.locale)}}</v-tab>
        <v-tab><v-icon left>mdi-content-copy</v-icon>{{ $t('memberships.subscription.subscription', $store.state.locale)}}</v-tab>
        <v-tab><v-icon left>mdi-cash-multiple</v-icon>{{ $t('memberships.subscription.paymentSettings', $store.state.locale)}}</v-tab>
        <v-tab><v-icon left>mdi-clipboard-check-outline</v-icon>{{ $t('memberships.subscription.terms', $store.state.locale)}}</v-tab>
      </v-tabs>

      <v-divider></v-divider>

      <v-card-text class="pa-0 ma-0">
        <v-tabs-items v-model="tab" style="height: 800px; overflow-y: scroll">
          <!-- / GENERAL TAB \ -->
          <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">

              <v-card class="mb-2" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].developer" >
                <v-btn dark @click="copyTextToClipboard(subscription.id)" class="ma-2 my-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="text-transform: none">
                  <strong>{{ subscription.id }}</strong>
                </v-btn>
                <div style="display: inline-block" v-if="!selectedMembershipIdCopy" class="pa-2 copy-tag-url" @click="copyTextToClipboard(subscription.id)">
                  <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon>
                  Kopieër DocId
                </div>
                <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied">DocId gekopieërd</div>
              </v-card>

              <v-container grid-list-md class="pa-0 ma-0">
                <v-select
                  class="mt-4"
                  :label="$t('general.languages', $store.state.locale)"
                  :items="languages"
                  item-text="title"
                  item-value="icon"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  dense
                  v-model="subscription.languages"
                  v-on:change="checkSubscriptionLanguages()"
                  outlined
                  multiple
                  hide-details 
                  :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                  >
                </v-select>

                <v-select
                  v-if="subscription.languages.length > 1"
                  class="mt-4"
                  label="Selecteer taal om te bewerken"
                  :items="subscriptionLanguages"
                  item-text="title"
                  item-value="icon"
                  v-model="selectedLanguage"
                  outlined
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  dense
                  hide-details 
                  >
                  <template v-slot:prepend-inner>
                    <div style="margin: 2px px 0 0">
                      <div v-if="selectedLanguage == 'en'" class="language-flag uk"></div>
                      <div v-if="selectedLanguage == 'nl'" class="language-flag nl"></div>
                    </div>
                  </template>       
                </v-select>

                <v-text-field
                    v-model="subscription.name[selectedLanguage]"
                    :counter="50"
                    :label="$t('general.name', $store.state.locale)"
                    required
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    outlined
                    hide-details
                    class="mt-4"
                    >
                    <template v-slot:prepend-inner>
                      <div style="margin: 2px px 0 0" v-if="subscription.languages.length > 1">
                        <div v-if="selectedLanguage == 'en'" class="language-flag uk"></div>
                        <div v-if="selectedLanguage == 'nl'" class="language-flag nl"></div>
                      </div>
                    </template>  
                </v-text-field>

                <v-text-field
                  v-model="subscription.posDescription[selectedLanguage]"
                  :counter="50"
                  :label="$t('memberships.posDescription', $store.state.locale)"
                  required
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  outlined
                  hide-details
                  class="mt-4"
                  >
                  <template v-slot:prepend-inner>
                    <div style="margin: 2px px 0 0" v-if="subscription.languages.length > 1">
                      <div v-if="selectedLanguage == 'en'" class="language-flag uk"></div>
                      <div v-if="selectedLanguage == 'nl'" class="language-flag nl"></div>
                    </div>
                  </template>  
                </v-text-field>

                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" class="my-4">
                  <v-card-text class="pa-3 ma-0">                     
                    <h3>{{ $t('general.branches', $store.state.locale) }}</h3>
                    <v-switch 
                      :label="$t('memberships.allBranches', $store.state.locale)"
                      v-model="subscription.branches.all"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      hide-details
                      class="mb-2"
                    ></v-switch>
                    <v-select
                      v-if="!subscription.branches.all"
                      :label="$t('memberships.selectBranches', $store.state.locale)"
                      :items="$store.state.companyBranches"
                      v-model="subscription.branches.branches"
                      item-text="name"
                      item-value="id"
                      chips
                      small-chips
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" 
                      multiple
                      dense
                      outlined
                      hide-details
                      class="mt-4"
                    ></v-select>
                  </v-card-text>
                </v-card>

                  <v-select
                    :label="$t('general.category', $store.state.locale)"
                    :items="categories"
                    v-model="subscription.category"
                    :loading="categories_Loading"
                    item-text="name"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    return-object
                    dense
                    outlined
                    hide-details
                    class="mt-4"
                  ></v-select>
                  
                  <v-text-field
                    :label="$t('treatments.nameIntern', $store.state.locale)"
                    v-model="subscription.description"
                    :counter="50"
                    required
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    dense
                    outlined
                    hide-details
                    class="mt-4"
                  ></v-text-field>

                  <v-textarea
                  rows="3"
                  class="mt-2"
                  :label="$t('memberships.formText', $store.state.locale)"
                  :hint="$t('memberships.formTextHint', $store.state.locale)"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  prepend-inner-icon="mdi-clipboard-text"
                  v-model="subscription.registrationDescription[selectedLanguage]"
                  outlined
                  persistent-hint
                  :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                  hide-details
                  >
                  <template v-slot:prepend-inner>
                    <div style="margin: 2px px 0 0" v-if="subscription.languages.length > 1">
                      <div v-if="selectedLanguage == 'en'" class="language-flag uk"></div>
                      <div v-if="selectedLanguage == 'nl'" class="language-flag nl"></div>
                    </div>
                  </template> 
                </v-textarea>
          
                <v-text-field
                  class="mt-2"
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :label="$t('memberships.successUrl', $store.state.locale)"
                  persistent-hint
                  :hint="`${$t('treatments.nameIntern', $store.state.locale)} https://thrive365.nl/succes`"
                  v-model="subscription.registrationUrl"
                  data-vv-as="field"
                  outlined
                  :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                ></v-text-field>

                <v-switch
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :label="$t('general.active', $store.state.locale)"
                  v-model="subscription.active"
                  hide-details
                  class="mt-2"
                ></v-switch>
              </v-container>
            </div>
          </v-tab-item>
          <!-- \ GENERAL TAB / -->

          <!-- / SUBSCRIPTION TAB \ -->
          <v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">
              <v-container grid-list-md class="pa-0 ma-0">
                <v-row >
                  <v-col class="d-flex" sm="12">
                    <v-select
                      :label="$t('memberships.paymentMethodes', $store.state.locale)"
                      item-text="name"
                      item-value="id"
                      :items="subscriptionPaymentTypes"
                      v-model="subscription.paymentTypes"
                      outlined
                      dense
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      v-on:input="payMethodChanges()"
                      hide-details
                      class="mt-2"
                    ></v-select>
                  </v-col>
                  <v-col class="d-flex" sm="12">
                    <v-alert
                      dense
                      text
                      style="width: 100%;"
                      class="ma-0"
                      outlined
                      type="info" 
                      prominent 
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      v-if="subscription.paymentTypes == 'periodic'"
                      >
                      <span v-html="$t('memberships.paymentPeriodic', $store.state.locale)"></span>
                    </v-alert>
                    <v-alert
                      dense
                      text
                      style="width: 100%;"
                      class="ma-0"
                      outlined
                      type="info"
                      prominent
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      v-if="subscription.paymentTypes == 'single'"
                      >
                      <span v-html="$t('memberships.paymentSingle', $store.state.locale)"></span>
                    </v-alert>
                    <v-alert
                      dense
                      text
                      style="width: 100%;"
                      class="ma-0"
                      outlined
                      type="info" 
                      prominent 
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      v-if="subscription.paymentTypes == 'singleAndPeriodic'"
                      >
                      <span v-html="$t('memberships.paymentSingleAndPeriodic', $store.state.locale)"></span>
                    </v-alert>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="d-flex" sm="12" md="6"  >
                    <v-text-field
                      dense
                      :label="$t('memberships.limitNumber', $store.state.locale)"
                      v-model="subscription.treatments.limitNumber"
                      outlined
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      class="mb-2"
                      hide-details
                      suffix="x"
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" sm="12" md="6"  >
                    <v-select
                      :label="$t('memberships.limitType', $store.state.locale)"
                      :items="limitTypes"
                      v-model="subscription.treatments.limitType"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      v-on:input="calculatePrice()"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      hide-details
                      class="mt-0"
                    ></v-select>
                  </v-col>
                  
                </v-row>
    
                <v-row>
                  <v-col class="d-flex" sm="12" md="6"  >
                    <v-select
                      :label="$t('memberships.paymentPeriod', $store.state.locale)"
                      :items="frequencyTypes"
                      v-model="subscription.paymentPeriod"
                      @change="onFrequencyChange()"
                      item-text="name"
                      item-value="id"
                      outlined
                      dense
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      hide-details
                      class="mt-0"
                    ></v-select>
                  </v-col>

                  <v-col class="d-flex" sm="12" md="6">
                    <v-text-field
                      :label="$t('memberships.minDuration', $store.state.locale)"
                      type="number"
                      item-text="name"
                      item-value="id"
                      dense
                      :min="0"
                      v-model.number="subscription.duration"
                      outlined
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      :suffix="durationSuffix(subscription.duration)"
                      class="mt-0"
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row >
                  <v-col class="d-flex" sm="12" md="6">
                    <v-select
                      :label="$t('memberships.start', $store.state.locale)"
                      item-text="name"
                      item-value="id"
                      :items="subscriptionStartOptions"
                      v-model="subscription.membershipStart"
                      prepend-innericon="mdi-calendar-range"
                      outlined
                      dense
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      hide-details
                      class="mt-0"
                    ></v-select>
                  </v-col>

                  <v-col class="d-flex" sm="12" md="6" v-if="subscription.paymentTypes != 'single'">
                    <v-select
                      :label="$t('memberships.paymentProcess', $store.state.locale)"
                      :items="processingCollection"
                      v-model="subscription.paymentDay"
                      item-text="name"
                      item-value="id"
                      prepend-inner-icon="mdi-calendar-range"
                      outlined
                      dense
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                      hide-details
                      class="mt-0"
                    ></v-select>
                  </v-col>
                </v-row>    
              
                <v-switch 
                  v-if="subscription.paymentTypes != 'single'"
                  :label="$t('memberships.autoRenew', $store.state.locale)"
                  v-model="subscription.autoRenew"
                  persistent-hint
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :hint="$t('memberships.autoRenewHint', $store.state.locale)" 
                ></v-switch>

                <!-- / PRICES \ -->

                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" class="my-4">
                  <v-card-text class="pa-3 ma-0">
                    <h3>{{ $t('memberships.priceFor', $store.state.locale) }} <span style="text-transform: lowercase">{{ $t('general.'+paymentPeriode, $store.state.locale)  }}</span></h3>

                    <v-container grid-list-md class="pt-4 px-0 pb-0">
                      <v-layout row>
                        <v-flex md4>
                          <v-select
                            style="width: 100%"
                            :label="$t('general.vat', $store.state.locale)"
                            :items="$store.state.vatItems"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            item-text="percentage"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            v-model="subscription.vat"
                            suffix="%"
                            dense
                            outlined
                            v-on:input="calculatePrice()"
                            hide-details
                            return-object
                          ></v-select>
                        </v-flex>

                        <v-flex md4>
                          <v-text-field
                            style="width: 100%"
                            :label="$t('treatments.nettoPrice', $store.state.locale)"
                            v-model.number="subscription.amount"
                            prefix="€"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            outlined
                            dense
                            type="number"
                            required
                            v-on:input="calculateBrutoPrice()"
                            hide-details
                          ></v-text-field>
                        </v-flex>
                        
                        <v-flex md4>
                          <v-text-field
                            style="width: 100%"
                            :label="$t('treatments.grossPrice', $store.state.locale)"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            outlined
                            dense
                            min="0"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            type="number"
                            prefix="€"
                            v-model.number="subscription.amountBruto"
                            v-on:input="calculateNettoPrice()"
                            required
                            hide-details
                          ></v-text-field>
                        </v-flex>

                      </v-layout>
                      <v-layout style="margin: 10px 0 5px 0" v-if="!subscription.periodicPaymentDiscount.active">
                        <v-flex md4>
                          <p class="ma-0" style="font-size: 14px;">
                            <strong>{{ $t('treatments.total', $store.state.locale)}}</strong>
                            
                          </p>
                          <div v-if="subscription.treatments.limitType == 'total'">
                              {{ subscription.treatments.limitNumber }}x
                            </div>
                            <div v-else>
                              {{ subscription.duration }} {{ durationSuffix( subscription.duration) }}
                            </div>
                        </v-flex>
                        <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;">&euro; {{ price.netto }} <span style="font-size: 12px; font-style: italic">{{ $t('general.vatExcluded', $store.state.locale)}}</span></p></v-flex>
                        <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;"><strong>&euro; {{ price.bruto }}</strong>  <span style="font-size: 12px; font-style: italic">{{ $t('general.vatIncluded', $store.state.locale)}}</span></p></v-flex>
                      </v-layout>

                      <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" class="pa-2 mt-3" v-if="subscription.singlePaymentDiscount.active">
                        <h3 class="pb-3">{{ $t('memberships.priceSingle', $store.state.locale)}}</h3>
                        <v-layout>
                          <v-flex md4>
                            <p class="ma-0" style="font-size: 14px;">
                              <strong>{{ $t('treatments.discount', $store.state.locale)}} </strong>
                            </p>
                          </v-flex>
                          <v-flex md4><p class="ma-0" style="font-size: 14px;">&euro; {{ price.singlePaymentDiscount.netto }}</p></v-flex>
                          <v-flex md4><p class="ma-0" style="font-size: 14px;"><strong>&euro; {{ price.singlePaymentDiscount.bruto }}</strong></p></v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex md4>
                            <p class="ma-0" style="font-size: 14px;">
                              <strong>{{ $t('treatments.totalPrice', $store.state.locale)}}  </strong>
                            </p>
                            <div v-if="subscription.treatments.limitType == 'total'">
                              {{ subscription.treatments.limitNumber }}x
                            </div>
                            <div v-else>
                              {{ subscription.duration }} {{ durationSuffix( subscription.duration) }}
                            </div>
                          </v-flex>
                          <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;">&euro; {{ price.singlePaymentNetto }} <span style="font-size: 12px; font-style: italic">{{ $t('general.vatExcluded', $store.state.locale)}} </span></p></v-flex>
                          <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;"><strong>&euro; {{ price.singlePaymentBruto }}</strong> <span style="font-size: 12px; font-style: italic"></span> {{ $t('general.vatIncluded', $store.state.locale)}} </p></v-flex>
                        </v-layout>
                      </v-card>


                      <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" class="pa-2 mt-3" v-if="subscription.periodicPaymentDiscount.active">
                        <h3 class="pb-3">{{ $t('memberships.pricePeriodic', $store.state.locale)}}</h3>
                        <v-layout>
                          <v-flex md4>
                            <p class="ma-0" style="font-size: 14px;">
                              <strong>{{ $t('memberships.discountFor', $store.state.locale)}} <span style="text-transform: lowercase">{{ $t('general.'+paymentPeriode, $store.state.locale)  }}</span></strong>
                            </p>
                          </v-flex>
                          <v-flex md4><p class="ma-0" style="font-size: 14px;">&euro; {{ price.periodicPaymentDiscount.periodic.netto }}</p></v-flex>
                          <v-flex md4><p class="ma-0" style="font-size: 14px;"><strong>&euro; {{ price.periodicPaymentDiscount.periodic.bruto }}</strong></p></v-flex>
                        </v-layout>

                        <v-layout>
                          <v-flex md4>
                            <p class="ma-0" style="font-size: 14px;">
                              <strong>{{ $t('memberships.priceFor', $store.state.locale)}} <span style="text-transform: lowercase">{{ $t('general.'+paymentPeriode, $store.state.locale)  }}</span></strong>
                            </p>
                          </v-flex>
                          <v-flex md4><p class="ma-0" style="font-size: 14px;">&euro; {{ (subscription.amount - price.periodicPaymentDiscount.periodic.netto).toFixed(2) }}</p></v-flex>
                          <v-flex md4><p class="ma-0" style="font-size: 14px;"><strong>&euro; {{ (subscription.amountBruto - price.periodicPaymentDiscount.periodic.bruto).toFixed(2) }}</strong></p></v-flex>
                        </v-layout>
                        <v-layout>
                          <v-flex md4>
                            <p class="ma-0" style="font-size: 14px;">
                              <strong>{{ $t('treatments.totalPrice', $store.state.locale)}}</strong><br>
                              {{ subscription.duration }} {{ durationSuffix( subscription.duration) }}
                            </p>
                          </v-flex>
                          <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;">&euro; {{ price.periodicPaymentNetto }} <span style="font-size: 12px; font-style: italic">{{ $t('general.vatExcluded', $store.state.locale)}}</span></p></v-flex>
                          <v-flex md4><p class="ma-0" style="font-size: 14px; line-height: 45px;"><strong>&euro; {{ price.periodicPaymentBruto }}</strong> <span style="font-size: 12px; font-style: italic">{{ $t('general.vatIncluded', $store.state.locale)}}</span></p></v-flex>
                        </v-layout>
                      </v-card>

                    </v-container>
                  </v-card-text>
                </v-card>
                <!-- \ PRICES / -->

                <!-- / PAYMENT DISCOUNTS \ -->    
                <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" flat class="my-4">
                  <v-card-text class="pa-3 ma-0">
                    <h3>{{ $t('memberships.discounts', $store.state.locale)}} </h3>
                    <v-switch
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      v-if="subscription.paymentTypes == 'single' || subscription.paymentTypes == 'singleAndPeriodic'"
                      :label="$t('memberships.discountSingle', $store.state.locale)"
                      v-model="subscription.singlePaymentDiscount.active"
                      hide-details
                      class="mb-2"
                    ></v-switch>
                    <v-container grid-list-md v-if="subscription.singlePaymentDiscount.active" class="pt-0 px-0">
                      <v-layout row>
                        <v-flex md6>
                          <v-select
                            class="mt-4"
                            v-model="subscription.singlePaymentDiscount.type"
                            :label="$t('memberships.discountType', $store.state.locale)"
                            :items="discountTypes"
                            item-text="label"
                            dense
                            item-value="id"
                            v-on:input="calculateTotalPrice()"
                            required
                            hide-details
                            outlined
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          ></v-select>
                        </v-flex>
                        <v-flex md6>
                          <v-text-field
                            class="mt-4"
                            :label="singleDiscountInfo.label"
                            v-model.number="subscription.singlePaymentDiscount.number"
                            dont-fill-mask-blanks
                            :prefix="singleDiscountInfo.prefix"
                            :suffix="singleDiscountInfo.suffix"
                            v-on:input="calculateTotalPrice()"
                            hide-details
                            dense
                            outlined
                            required
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    <v-switch 
                      v-if="subscription.paymentTypes == 'periodic' || subscription.paymentTypes == 'singleAndPeriodic'"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :label="$t('memberships.discountPeriodic', $store.state.locale)"
                      v-model="subscription.periodicPaymentDiscount.active"
                      hide-details
                      class="mb-2"
                    ></v-switch>
                    <v-container grid-list-md v-if="subscription.periodicPaymentDiscount.active" class="pt-0 px-0">
                      <v-select
                        :label="$t('memberships.discountType', $store.state.locale)"
                        :items="trialTypes"
                        item-text="name"
                        item-value="id"
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                        v-model="subscription.periodicPaymentDiscount.discountType"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        outlined
                        dense
                        class="mt-4"
                        hide-details
                      ></v-select>

                      <v-layout row v-if="subscription.periodicPaymentDiscount.discountType == 'general'">
                        <v-flex md12>
                          <v-alert
                            dense
                            text
                            style="width: 100%;"
                            class="mt-2 ma-0"
                            outlined
                            type="info" 
                            prominent 
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            >
                            <span v-html="$t('memberships.discountForAll', $store.state.locale)"></span>
                          </v-alert>
                        </v-flex>
                        <v-flex md6>
                          <v-select
                            class="mt-4"
                            v-model="subscription.periodicPaymentDiscount.type"
                            :label="$t('memberships.discountType', $store.state.locale)"
                            :items="discountTypes"
                            item-text="label"
                            item-value="id"
                            v-on:input="calculateTotalPrice()"
                            required
                            dense
                            hide-details
                            outlined
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          ></v-select>
                        </v-flex>
                        <v-flex md6>
                          <v-text-field
                            class="mt-4"
                            :label="periodicDiscountInfo.label"
                            v-model.number="subscription.periodicPaymentDiscount.number"
                            dont-fill-mask-blanks
                            :prefix="periodicDiscountInfo.prefix"
                            :suffix="periodicDiscountInfo.suffix"
                            v-on:input="calculateTotalPrice()"
                            hide-details
                            outlined
                            dense
                            required
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row v-else>
                        <v-flex md12>
                          <v-alert
                            dense
                            text
                            style="width: 100%;"
                            class="mt-2 ma-0"
                            outlined
                            type="info" 
                            prominent 
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            >      
                            <span v-html="$t('memberships.setClientDiscountOnSend', $store.state.locale)"></span> 
                          </v-alert>
                        </v-flex>
                        <v-flex md6>
                          <v-text-field
                            :label="`${$t('memberships.maxDiscountFor', $store.state.locale)} <span style='text-transform: lowercase'>${$t('general.' + paymentPeriode, $store.state.locale)}</span>`"
                            type="number"
                            prefix="€"
                            dense
                            v-model="subscription.periodicPaymentDiscount.max"
                            outlined
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            class="mt-4"
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout>
                        <v-flex>
                          <v-switch
                            v-if="subscription.autoRenew" 
                            :label="$t('memberships.respectDiscountOnAutoRenew', $store.state.locale)"
                            v-model="subscription.periodicPaymentDiscount.continue"
                            hide-details
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            class="mb-2"
                          ></v-switch>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>
                </v-card>
                <!-- \ PAYMENT DISCOUNTS / -->

                <!-- / TRIAL \ -->
                <v-card v-if="subscription.paymentTypes == 'periodic'" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" class="my-4">
                  <v-card-text class="pa-3 ma-0">
                    <h3>{{ $t('memberships.trial', $store.state.locale) }}</h3>
                    <v-switch 
                      :label="$t('memberships.trial', $store.state.locale)"
                      v-model="subscription.freePeriod.active"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      hide-details
                      class="mb-2"
                    ></v-switch>

                    <div v-if="subscription.freePeriod.active" class="pt-4">
                      <v-select
                        :label="$t('memberships.discountType', $store.state.locale)"
                        :items="trialTypes"
                        item-text="name"
                        item-value="id"
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        v-model="subscription.freePeriod.type"
                        dense
                        outlined
                        hide-details
                      ></v-select>

                      <v-row v-if="subscription.freePeriod.type == 'general'" class="ma-0">
                        <v-flex md12>
                          <v-alert
                            dense
                            text
                            style="width: 100%;"
                            class="mt-2 ma-0"
                            outlined
                            type="info" 
                            prominent 
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            >
                            <span v-html="$t('memberships.generalTrial', $store.state.locale)"></span>
                          </v-alert>
                        </v-flex>
                        <v-flex md6>
                          <v-text-field
                            :label="$t('memberships.maxTrial', $store.state.locale)"
                            type="number"
                            item-text="name"
                            item-value="id"
                            v-model="subscription.freePeriod.number"
                            outlined
                            dense
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            :suffix="durationSuffix(subscription.freePeriod.number)"
                            class="mt-4"
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-row>

                      <v-row  v-else class="ma-0">
                        <v-flex md12>
                          <v-alert
                            dense
                            text
                            style="width: 100%;"
                            class="mt-2 ma-0"
                            outlined
                            type="info" 
                            prominent 
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            >
                            <span v-html="$t('memberships.specificTrial', $store.state.locale)"></span>
                          </v-alert>
                        </v-flex>
                        <v-flex md6>
                          <v-text-field
                            :label="$t('memberships.maxTrial', $store.state.locale)"
                            type="number"
                            v-model="subscription.freePeriod.number"
                            outlined
                            dense
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            :suffix="durationSuffix(subscription.freePeriod.number)"
                            class="mt-4"
                            hide-details
                          ></v-text-field>
                        </v-flex>
                      </v-row>
                    </div>
                  </v-card-text>
                </v-card>
                <!-- \ TRIAL / -->

                <!-- / SUBSCRIPTION DISCOUNTS  \ -->
                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" class="mt-4">
                  <v-card-text class="pa-3 ma-0">
                    <h3>{{ $t('memberships.membershipDiscounts', $store.state.locale) }}</h3>

                    <v-container grid-list-md v-if="subscription.separatedDiscount.active" class="pt-0 px-0">             
                      <v-layout row>
                        <v-flex xs12>
                         
                          <v-select
                            class="mt-4"
                            v-model="subscription.separatedDiscount.discountsInsideSubscriptionDays"
                            :label="$t('memberships.discounts', $store.state.locale)"
                            :items="filteredMembershipModifiers"
                            item-text="name"
                            dense
                            chips
                            small-chips
                            :loading="discountsLoading"
                            multiple
                            item-value="id"
                            required
                            hide-details
                            outlined
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          ></v-select>
                          <v-alert
                            dense
                            text
                            style="width: 100%;"
                            class="mt-2 ma-0"
                            outlined
                            type="info" 
                            prominent 
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            >
                            <span v-html="$t('memberships.discountMembershipValid', $store.state.locale)" ></span>
                          </v-alert>
                        </v-flex>
                        <v-flex md12>
                          <v-switch
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :label="$t('memberships.useDiscountMembershipNotValid', $store.state.locale)"
                            v-model="subscription.separatedDiscount.splitDiscounts"
                            @change="setSeparatedDiscount()"
                            hide-details
                            class="mb-2"
                          ></v-switch>
                        </v-flex>
                        <v-flex xs12>
                          
                          <v-select
                            v-if="subscription.separatedDiscount.splitDiscounts"
                            class="mt-4"
                            v-model="subscription.separatedDiscount.discountsOutsideSubscriptionDays"
                            :label="$t('memberships.discounts', $store.state.locale)"
                            :items="filteredDefaultModifiers"
                            item-text="name"
                            dense
                            chips
                            small-chips
                            :loading="discountsLoading"
                            multiple
                            item-value="id"
                            required
                            hide-details
                            outlined
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          ></v-select>

                          <v-alert
                            v-if="subscription.separatedDiscount.splitDiscounts"
                            dense
                            text
                            style="width: 100%;"
                            class="mt-2 ma-0"
                            outlined
                            type="info" 
                            prominent 
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            >
                            <span v-html="$t('memberships.discountMembershipNotValid', $store.state.locale)" ></span>
                          </v-alert>
                        </v-flex>
                      </v-layout>
                    </v-container>
                
                  </v-card-text>
                </v-card>
                <!-- \ SUBSCRIPTION DISCOUNTS / -->

                <!-- / SUBSCRIPTION VALID DAYS \ -->
                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" class="mt-4">
                  <v-card-text class="pa-3 ma-0">
                    <h3 class="ma-0 pb-2">{{ $t('memberships.validation', $store.state.locale) }} </h3>

                    <div>
                      <v-text-field
                        v-model="subscription.peakMessage[selectedLanguage]"
                        :counter="50"
                        :label="$t('memberships.peakMessage', $store.state.locale)"
                        required
                        dense
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        outlined
                        hide-details
                        class="mb-4"
                        >
                        <template v-slot:prepend-inner>
                          <div style="margin: 2px px 0 0" v-if="subscription.languages.length > 1">
                            <div v-if="selectedLanguage == 'en'" class="language-flag uk"></div>
                            <div v-if="selectedLanguage == 'nl'" class="language-flag nl"></div>
                          </div>
                        </template>  
                      </v-text-field>

                      <v-text-field
                        v-if="checkIfValidityIsLimited()"
                        v-model="subscription.offPeakMessage[selectedLanguage]"
                        :counter="50"
                        :label="$t('memberships.offPeakMessage', $store.state.locale)"
                        required
                        dense
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        outlined
                        hide-details
                        class="mb-4"
                        >
                        <template v-slot:prepend-inner>
                          <div style="margin: 2px px 0 0" v-if="subscription.languages.length > 1">
                            <div v-if="selectedLanguage == 'en'" class="language-flag uk"></div>
                            <div v-if="selectedLanguage == 'nl'" class="language-flag nl"></div>
                          </div>
                        </template>  
                      </v-text-field>
                    </div>

                    <v-container class="pa-0 ma-0">
                      <v-layout row wrap class="table-header">
                        <v-flex xs2>
                          <strong>{{ $t("general.day", $store.state.locale) }}</strong>
                        </v-flex>
                        <v-flex xs2><strong>{{ $t("general.active", $store.state.locale) }}</strong></v-flex>
                        <v-flex xs2><strong>{{ $t("general.allDay", $store.state.locale) }}</strong></v-flex>
                        <v-flex xs3><strong>{{ $t("general.start", $store.state.locale) }}</strong></v-flex>
                        <v-flex xs3><strong>{{ $t("general.end", $store.state.locale) }}</strong></v-flex>
                      </v-layout>

                      <v-layout row wrap class="pa-0">
                        <v-flex xs2 ><div class="py-2">{{ $t("general.monday", $store.state.locale) }}</div> </v-flex>
                        <v-flex xs2 class="branch-day-active pt-2">
                          <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0 py-1" v-model="subscription.days.Mon.active" @change="resetDayTimes('Mon', 'all')" hide-details></v-switch>
                        </v-flex>
                        <v-flex xs2 class="branch-day-active py-2" >
                          <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Mon.active"  class="ma-0 pa-0 py-1" v-model="subscription.days.Mon.allDay" @change="resetDayTimes('Mon')" hide-details></v-switch>
                        </v-flex>
                        <v-flex xs3 class="branch-start-hours" v-if="subscription.days.Mon.active && !subscription.days.Mon.allDay">
                          <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Mon.active || subscription.days.Mon.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="subscription.days.Mon.start" ></v-text-field>
                        </v-flex>
                        <v-flex xs3 v-if="subscription.days.Mon.active && !subscription.days.Mon.allDay">
                          <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Mon.active || subscription.days.Mon.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="subscription.days.Mon.end" ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap class="pa-0">
                        <v-flex xs2 ><div class="py-2">{{ $t("general.tuesday", $store.state.locale) }}</div> </v-flex>
                        <v-flex xs2 class="branch-day-active pt-2">
                          <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0 py-1" v-model="subscription.days.Tue.active" @change="resetDayTimes('Tue', 'all')" hide-details></v-switch>
                        </v-flex>
                        <v-flex xs2 class="branch-day-active py-2" >
                          <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Tue.active"  class="ma-0 pa-0 py-1" v-model="subscription.days.Tue.allDay" @change="resetDayTimes('Tue')" hide-details></v-switch>
                        </v-flex>
                        <v-flex xs3 class="branch-start-hours" v-if="subscription.days.Tue.active && !subscription.days.Tue.allDay">
                          <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Tue.active || subscription.days.Tue.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="subscription.days.Tue.start" ></v-text-field>
                        </v-flex>
                        <v-flex xs3 v-if="subscription.days.Tue.active && !subscription.days.Tue.allDay">
                          <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Tue.active || subscription.days.Tue.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="subscription.days.Tue.end" ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap class="pa-0">
                        <v-flex xs2 ><div class="py-2">{{ $t("general.wednesday", $store.state.locale) }}</div> </v-flex>
                        <v-flex xs2 class="branch-day-active pt-2">
                          <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0 py-1" v-model="subscription.days.Wed.active" @change="resetDayTimes('Wed', 'all')" hide-details></v-switch>
                        </v-flex>
                        <v-flex xs2 class="branch-day-active py-2" >
                          <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Wed.active"  class="ma-0 pa-0 py-1" v-model="subscription.days.Wed.allDay" @change="resetDayTimes('Wed')" hide-details></v-switch>
                        </v-flex>
                        <v-flex xs3 class="branch-start-hours" v-if="subscription.days.Wed.active && !subscription.days.Wed.allDay">
                          <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Wed.active || subscription.days.Wed.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="subscription.days.Wed.start" ></v-text-field>
                        </v-flex>
                        <v-flex xs3 v-if="subscription.days.Wed.active && !subscription.days.Wed.allDay">
                          <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Wed.active || subscription.days.Wed.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="subscription.days.Wed.end" ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap class="pa-0">
                        <v-flex xs2 ><div class="py-2">{{ $t("general.thursday", $store.state.locale) }}</div> </v-flex>
                        <v-flex xs2 class="branch-day-active pt-2">
                          <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0 py-1" v-model="subscription.days.Thu.active" @change="resetDayTimes('Thu', 'all')" hide-details></v-switch>
                        </v-flex>
                        <v-flex xs2 class="branch-day-active py-2" >
                          <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Thu.active"  class="ma-0 pa-0 py-1" v-model="subscription.days.Thu.allDay" @change="resetDayTimes('Thu')" hide-details></v-switch>
                        </v-flex>
                        <v-flex xs3 class="branch-start-hours" v-if="subscription.days.Thu.active && !subscription.days.Thu.allDay">
                          <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Thu.active || subscription.days.Thu.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="subscription.days.Thu.start" ></v-text-field>
                        </v-flex>
                        <v-flex xs3 v-if="subscription.days.Thu.active && !subscription.days.Thu.allDay">
                          <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Thu.active || subscription.days.Thu.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="subscription.days.Thu.end" ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap class="pa-0">
                        <v-flex xs2 ><div class="py-2">{{ $t("general.friday", $store.state.locale) }}</div> </v-flex>
                        <v-flex xs2 class="branch-day-active pt-2">
                          <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0 py-1" v-model="subscription.days.Fri.active" @change="resetDayTimes('Fri', 'all')" hide-details></v-switch>
                        </v-flex>
                        <v-flex xs2 class="branch-day-active py-2" >
                          <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Fri.active"  class="ma-0 pa-0 py-1" v-model="subscription.days.Fri.allDay" @change="resetDayTimes('Fri')" hide-details></v-switch>
                        </v-flex>
                        <v-flex xs3 class="branch-start-hours" v-if="subscription.days.Fri.active && !subscription.days.Fri.allDay">
                          <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Fri.active || subscription.days.Fri.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="subscription.days.Fri.start" ></v-text-field>
                        </v-flex>
                        <v-flex xs3 v-if="subscription.days.Fri.active && !subscription.days.Fri.allDay">
                          <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Fri.active || subscription.days.Fri.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="subscription.days.Fri.end" ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap class="pa-0">
                        <v-flex xs2 ><div class="py-2">{{ $t("general.saturday", $store.state.locale) }}</div> </v-flex>
                        <v-flex xs2 class="branch-day-active pt-2">
                          <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0 py-1" v-model="subscription.days.Sat.active" @change="resetDayTimes('Sat', 'all')" hide-details></v-switch>
                        </v-flex>
                        <v-flex xs2 class="branch-day-active py-2" >
                          <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Sat.active"  class="ma-0 pa-0 py-1" v-model="subscription.days.Sat.allDay" @change="resetDayTimes('Sat')" hide-details></v-switch>
                        </v-flex>
                        <v-flex xs3 class="branch-start-hours" v-if="subscription.days.Sat.active && !subscription.days.Sat.allDay">
                          <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Sat.active || subscription.days.Sat.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="subscription.days.Sat.start" ></v-text-field>
                        </v-flex>
                        <v-flex xs3 v-if="subscription.days.Sat.active && !subscription.days.Sat.allDay">
                          <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Sat.active || subscription.days.Sat.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="subscription.days.Sat.end" ></v-text-field>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap class="pa-0">
                        <v-flex xs2 ><div class="py-2">{{ $t("general.sunday", $store.state.locale) }}</div> </v-flex>
                        <v-flex xs2 class="branch-day-active pt-2">
                          <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0 py-1" v-model="subscription.days.Sun.active" @change="resetDayTimes('Sun', 'all')" hide-details></v-switch>
                        </v-flex>
                        <v-flex xs2 class="branch-day-active py-2" >
                          <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Sun.active"  class="ma-0 pa-0 py-1" v-model="subscription.days.Sun.allDay" @change="resetDayTimes('Sun')" hide-details></v-switch>
                        </v-flex>
                        <v-flex xs3 class="branch-start-hours" v-if="subscription.days.Sun.active && !subscription.days.Sun.allDay">
                          <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Sun.active || subscription.days.Sun.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="subscription.days.Sun.start" ></v-text-field>
                        </v-flex>
                        <v-flex xs3 v-if="subscription.days.Sun.active && !subscription.days.Sun.allDay">
                          <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="!subscription.days.Sun.active || subscription.days.Sun.allDay" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="subscription.days.Sun.end" ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    

                  </v-card-text>
                </v-card>
                <!-- \ SUBSCRIPTION VALID DAYS / -->

              </v-container>
            </div>
          </v-tab-item>
          <!-- \ SUBSCRIPTION TAB / -->

          <!-- / PAYMENT TAB \ -->
          <v-tab-item :value="2" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">
              <v-container grid-list-md class="pa-0 ma-0">
                <v-alert
                  v-if="subscription.paymentTypes == 'single'"
                  dense
                  text
                  style="width: 100%;"
                  class="mt-2 ma-0"
                  outlined
                  type="info" 
                  prominent 
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  >
                  <span v-html="$t('memberships.paymentSingleOnlyHint', $store.state.locale)"></span>
                </v-alert>
                <v-select
                  v-if="subscription.paymentTypes != 'single'"
                  :label="$t('memberships.collectionMethods', $store.state.locale)"
                  :items="paymentTypes"
                  v-model="subscription.debitTypes"
                  :loading="paymentTypes_Loading"
                  item-text="name"
                  item-value="id"
                  multiple
                  small-chips
                  chips
                  dense
                  outlined
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                  hide-details
                  class="mt-4"
                ></v-select>

                <v-select
                  v-if="subscription.paymentTypes != 'single'"
                  :label="$t('memberships.mandateType', $store.state.locale)"
                  :items="mandatesTypes"
                  v-model="subscription.mandateTypes"
                  outlined
                  multiple
                  dense
                  item-text="name"
                  item-value="id"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                  hide-details
                  class="mt-4"
                ></v-select>

                <v-select
                  v-if="subscription.paymentTypes != 'single'"
                  :label="$t('memberships.followUpOnFailedCollections', $store.state.locale)"
                  :items="attempts"
                  item-text="name"
                  item-value="id"
                  v-model="subscription.sepaAttempts"
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                  hide-details
                  class="mt-4 mb-4"
                ></v-select>

                <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="my-4">
                  <v-card-text class="pa-3 ma-0">
                    <h3>{{ $t('memberships.billing', $store.state.locale)}}</h3>
                    <v-switch 
                      :label="$t('memberships.billingByEmail', $store.state.locale)"
                      v-model="subscription.sendInvoice"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      hide-details
                      class="mb-2"
                    ></v-switch>
                    <v-switch 
                      :label="$t('memberships.billingReminderByEmail', $store.state.locale)"
                      v-model="subscription.paymentReminder"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      hide-details
                      class="mb-2"
                    ></v-switch>
                  </v-card-text>
                </v-card>
              </v-container>
            </div>
          </v-tab-item>
          <!-- \ PAYMENT TAB / -->

          <!-- / TERMS TAB \ -->
          <v-tab-item :value="3" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">
              <v-container grid-list-md class="pa-0 ma-0">
                <v-textarea
                  outlined
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                  hide-details
                  auto-grow
                  :label="$t('memberships.generalTerms', $store.state.locale)"
                  rows="4"
                  row-height="50"
                  v-model="subscription.terms[selectedLanguage]"
                ></v-textarea>
              </v-container>
            </div>
          </v-tab-item>
          <!-- \ TERMS TAB / -->

        </v-tabs-items>

      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
                @click="closeDialog()"
                >
                {{ $t('general.cancel', $store.state.locale) }}
              </v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn
                v-if="type == 'new'"
                dark
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                @click="validateForm()"
                >
                {{ $t('general.add', $store.state.locale) }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              <v-btn
                v-if="type == 'edit'"
                :dark="changed"
                :disabled="!changed"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                @click="validateForm()"
                >
                  <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale) }} <v-icon right >mdi-chevron-right</v-icon></div>
                  <div v-else> {{ $t('general.saved', $store.state.locale) }} <v-icon right >mdi-check</v-icon></div>   
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>

import db from "../../firebase/init";
import { bus } from "../../main";
import CountryFlag from 'vue-country-flag';

export default {
  name: "membership-modal",
  components:{CountryFlag},
  data() {
     return {
      showDialog: false,
      type: 'new',
      tab: 0,
      tabTransition: false,

      subscription: {
        languages: new Object(),
        name: new Object(),
        branches: new Array(),
        registrationDescription: new Object(),
        periodicPaymentDiscount: new Object(),
        singlePaymentDiscount: new Object(),
        separatedDiscount: new Object(),
        treatments: new Object(),
        terms: new Object(),
        posDescription: new Object(),
        offPeakMessage: new Object(),
        peakMessage: new Object(),
        days: {
          Mon: new Object(),
          Tue: new Object(),
          Wed: new Object(),
          Thu: new Object(),
          Fri: new Object(),
          Sat: new Object(),
          Sun: new Object()
        },
      },
      subscriptionOriginal: new Object(),

      feedback: null,
      categories: new Array(),
            
      price: {
        bruto: 0,
        netto: 0,
        periodicPaymentDiscount: {
          periodic: {
            bruto: 0,
            netto: 0,
          },
          total: {
            bruto: 0,
            netto: 0,
          }
        },
        singlePaymentDiscount: {
          bruto: 0,
          netto: 0,
        },
        periodicPaymentBruto: 0,
        periodicPaymentNetto: 0,
        singlePaymentBruto: 0,
        singlePaymentNetto: 0,
      },

      discountTypes: [
        {
          label: this.$t('general.percentage', this.$store.state.locale),
          id: "percentage"
        },
        {
          label: this.$t('general.amount', this.$store.state.locale),
          id: "amount"
        }
      ],

      trialTypes:[
        {
          id:'general',
          name:  this.$t('general.general', this.$store.state.locale),
        },
        {
          id:'individual',
          name:'Instellen per cliënt'
        }
      ],
      paymentTypes:[
        {
          id:'sepa',
          name:'SEPA Incasso'
        },
        {
          id:'credit',
          name:'CreditCard'
        }
      ],
      selectedPaymentType: [
          {
            id:'sepa',
            name:'SEPA Incasso'
          }
      ],
      attempts:[
        {
          name:  this.$t('memberships.oneFollowUpAttemt', this.$store.state.locale),
          id: 1
        },
        {
          name:  this.$t('memberships.twoFollowUpAttemts', this.$store.state.locale),
          id: 2
        },
        {
          name:  this.$t('memberships.noFollowUpAttemts', this.$store.state.locale),
          id: 0
        }
      ],

      membershipTypes:[
        {
          name:  this.$t('memberships.continuousPayment', this.$store.state.locale),
          id: "continuous"
        },
        {
          name:  this.$t('memberships.oneTimePayment', this.$store.state.locale),
          id: "single"
        }
      ],
      mandatesTypes:[
        {
          name: 'Email',
          id: 'email'
        } 
      ],
      subscriptionTypes: [
        {
          name:  this.$t('memberships.fixedNumberType', this.$store.state.locale),
          id: 'fixedNumber'
        },
        {
          name:  this.$t('memberships.subscriptionType', this.$store.state.locale),
          id: 'subscription'
        }
      ],
      frequencyTypes:[
        {
          name:  this.$t('memberships.daily', this.$store.state.locale),
          id: 'daily'
        },
        {
          name:  this.$t('memberships.everyWeek', this.$store.state.locale),
          id: 'everyWeek'
        },
        {
          name:  this.$t('memberships.everyFourWeeks', this.$store.state.locale),
          id: 'everyFourWeeks'
        },
        {
          name:  this.$t('memberships.everyMonth', this.$store.state.locale),
          id: 'everyMonth'
        },
        {
          name:  this.$t('memberships.everyQuarter', this.$store.state.locale),
          id: 'everyQuarter'
        },
        {
          name:  this.$t('memberships.everyHalfYear', this.$store.state.locale),
          id: 'everyHalfYear'
        },
        {
          name:  this.$t('memberships.everyYear', this.$store.state.locale),
          id: 'everyYear'
        }
      ],
      frequencyTypesSingle:[
        // {
        //   name: 'Dagelijks',
        //   id: 'daily'
        // },
        {
          name:  this.$t('memberships.week', this.$store.state.locale),
          id: 'week'
        },
        {
          name:  this.$t('memberships.month', this.$store.state.locale),
          id: 'month'
        },
        {
          name:  this.$t('memberships.annual', this.$store.state.locale),
          id: 'annual'
        }
      ],
      processingCollection: new Array(),
      chosenProcessing: 14,
      frequencyAmountsItems: new Array(),
      // selectedFrequencyAmount:'iedere maand',
      // selectedFrequency:'monthly',
      countities: new Array(),
      selectedMonth:null,
      isSubscription: false,
      isCardType:false,
      cardFrequentieAmount:null,
      cardFrequentiePer:null,
      cardFrequentieItems:[
        {
          id: "weekly",
          name:  this.$t('memberships.frequencyWeekly', this.$store.state.locale),
        },
        {
          id: "monthly",
          name:  this.$t('memberships.frequencyMonthly', this.$store.state.locale),
        },
        {
          id: "yearly",
          name:  this.$t('memberships.frequencyYearly', this.$store.state.locale),
        }
      ],
      linkedTreatments:new Array(),
      treatments: new Array(),
      languages:[
        {
          title: 'Nederlands',
          icon: 'nl',
        },
        {
          title: 'English',
          icon: 'en',
        },
      ],
      discountsLoading: true,
      discounts: new Array(),
      selectedLanguage: "nl",
      registrationUrl: null,
      treatmentsSearch:"",
      yearlyType:null,
      subscriptionStartOptions: [
        {
          id: "immediately",
          name:  this.$t('memberships.subscriptionStartImmediately', this.$store.state.locale),
        },
        {
          id: "clientPick",
          name:  this.$t('memberships.subscriptionStartClientPick', this.$store.state.locale),
        },
      ],
      subscriptionPaymentTypes: [
        {
          id: "periodic",
          name:  this.$t('memberships.paymentTypePeriodic', this.$store.state.locale),
        },
        {
          id: "single",
          name:  this.$t('memberships.paymentTypeSingle', this.$store.state.locale),
        },
        {
          id: "singleAndPeriodic",
          name:  this.$t('memberships.paymentTypeSingleAndPeriodic', this.$store.state.locale),
        },
      ],
      paymentPeriodMonths: [
        {
          id: "immediately",
          name:  this.$t('memberships.subscriptionStartImmediately', this.$store.state.locale),
        },
        {
          id: 1,
          name:  this.$t('general.january', this.$store.state.locale),
        },
        {
          id: 2,
          name:  this.$t('general.february', this.$store.state.locale),
        },
        {
          id: 3,
          name:  this.$t('general.march', this.$store.state.locale)
        },
        {
          id: 4,
          name:  this.$t('general.april', this.$store.state.locale)
        },
        {
          id: 5,
          name:  this.$t('general.may', this.$store.state.locale)
        },
        {
          id: 6,
          name:  this.$t('general.june', this.$store.state.locale)
        },
        {
          id: 7,
          name:  this.$t('general.july', this.$store.state.locale)
        },
        {
          id: 8,
          name:  this.$t('general.august', this.$store.state.locale)
        },
        {
          id: 9,
          name:  this.$t('general.september', this.$store.state.locale)
        },
        {
          id: 10,
          name:  this.$t('general.october', this.$store.state.locale)
        },
        {
          id: 11,
          name:  this.$t('general.november', this.$store.state.locale)
        },
        {
          id: 12,
          name:  this.$t('general.december', this.$store.state.locale)
        }
      ],
      paymentFrequencyWeeks: [ 
        {
          id: 1,
          name: "Elke week"
        },
        {
          id: 2,
          name: "Elke 2 weken"
        },
        {
          id: 4,
          name: "Elke 4 weken"
        }
      ],
      paymentFrequencyYear: [
        {
          id: 1,
          name: 'Elk jaar'
        },
        {
          id: 2,
          name: 'Eens per 2 jaar'
        },
        {
          id: 3,
          name: 'Eens per 3 jaar'
        },
        {
          id: 4,
          name: 'Eens per 4 jaar'
        },
        {
          id: 5,
          name: 'Eens per 5 jaar'
        }
      ],

      paymentFrequencyDays: [
        {
          id: 0,
          name:  this.$t('general.sunday', this.$store.state.locale)
        },
        {
          id: 1,
          name:  this.$t('general.monday', this.$store.state.locale)
        },
        {
          id: 2,
          name:  this.$t('general.tuesday', this.$store.state.locale)
        },
        {
          id: 3,
          name:  this.$t('general.wednesday', this.$store.state.locale)
        },
        {
          id: 4,
          name:  this.$t('general.thursday', this.$store.state.locale)
        },
        {
          id: 5,
          name:  this.$t('general.friday', this.$store.state.locale)
        },
        {
          id: 6,
          name:  this.$t('general.saturday', this.$store.state.locale)
        }
      ],

      //Loaders for selects
      paymentTypes_Loading:false,
      categories_Loading:true,
      treatmentTypes_Loading:true,
      selectProcessing:false,
      annuallySelected:false,

      selectedMembershipIdCopy: false,
      rules:{
        friendlyUrl(value){
          var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
          if(!!pattern.test(value)){ return true; }
          return 'gebruik valid url zoals: https://thrive365.nl/success'
        }
      } 
    }
  },

  created(){
    this.getCategories();
    this.loadDiscounts();
    this.subscriptionChange();
    this.onFrequencyChange();
    bus.$on("subscriptionModal", (item, type)=>{
      this.resetSubscription();
      this.type = type;
      if(type == 'edit'){
        this.subscription = _.cloneDeep(item);
        this.subscriptionOriginal = _.cloneDeep(item);
        this.calculateBrutoPrice();
        this.onFrequencyChange();
      }
      this.showDialog = true;
    })
  },

  methods:{
    
    closeDialog(){ // Close membership dialog and reset data
      this.showDialog = false;
      this.resetSubscription();
    },

    checkIfValidityIsLimited(){
      let validityIsLimited = false;
      for (const [key] of Object.entries(this.subscription.days)) { // Controleren of starttijd groter of gelijk is aan eindtijd
        if(this.subscription.days[key].active){
          if(!this.subscription.days[key].allDay){
            validityIsLimited = true;
          }
        }
        else{
          validityIsLimited = true;
        }
      }
      return validityIsLimited;
    },

    resetSubscription(){ // Reset Membership data
      this.type = 'new';
      this.processingCollection =  this.getDaysOfMonth();
      this.subscription = {
        active: true,
        languages: [
          'nl'
        ],
        name: {
          nl: null,
          en: null
        },
        branches: {
          all: true,
          branches: new Array()
        },
        description: null,
        category: null,
        registrationDescription: {
          nl: null,
          en: null
        },
        posDescription: new Object(),
        offPeakMessage: new Object(),
        peakMessage: new Object(),
        registrationUrl: null,
        mandateTypes: ['email'],
        paymentTypes: 'singleAndPeriodic',
        debitTypes: ['sepa'],
        amount: null,
        amountBruto: null,
        vat: null,
        sepaAttempts: 2,
        sendInvoice: true,
        paymentReminder: true,
        terms: {
           nl: null,
           en: null
        },
        separatedDiscount: {
          active: true,
          splitDiscounts: false,
          discountsInsideSubscriptionDays: new Array(),
          discountsOutsideSubscriptionDays: new Array()
        },

        days: {
          Mon: { active: true, allDay: true, start: null, end: null},
          Tue: { active: true, allDay: true, start: null, end: null},
          Wed: { active: true, allDay: true, start: null, end: null},
          Thu: { active: true, allDay: true, start: null, end: null},
          Fri: { active: true, allDay: true, start: null, end: null},
          Sat: { active: true, allDay: true, start: null, end: null},
          Sun: { active: true, allDay: true, start: null, end: null},
        },
        
        subscriptionPeriod: 'everyMonth', // weekly/monthly/yearly
        membershipType: "continuous",
        duration: 0,
        paymentPeriod: 'everyMonth', // weekly/monthly/yearly

        paymentDay: null, // ??
        paymentFrequency: 0,
        autoRenew: true,

        membershipStart: "immediately",
        treatments: {
          active: false,
          treatments: [],
          limit: false,
          limitNumber: 1,
          limitType: 'period'
        },

        freePeriod: {
          active: false,
          type: 'general',
          number: '1'
        },
        trialPeriod: {
          active: false,
        },
        singlePaymentDiscount: {
          active: false,
          type: 'percentage',
          number: 0
        },
        periodicPaymentDiscount: {
          active: false,
          type: 'percentage',
          number: 0,
          continue: false,
          discountType: 'general',
          max: 0
        },

        packageType: "subscription",
        frequencyAmount: 1,
        
        selectedMonth:null, 
      };
    },
                  
    loadDiscounts(){
      if(this.discountsLoading){  
        db.collection('modifiers')
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("modifierGroup", "in", ['membershipModifier', 'defaultModifier'])
        .get()
        .then(querySnapshot=>{
            querySnapshot.forEach(doc=>{
              let discount = doc.data();
              discount.id = doc.id;
              discount.disabled = doc.data().active ? false : true; 
              this.discounts.push(discount);
            })
        })
        .then(()=>{
            this.discountsLoading = false;
        })
        .catch(err=>{
            console.error(err);
        })
      }
    },

    setSeparatedDiscount(){ // Empty discounts outside Subscription Days on 
      this.subscription.separatedDiscount.discountsOutsideSubscriptionDays = new Array();
    },

    copyTextToClipboard(text) {
        if (!navigator.clipboard) {
          console.error('Clipboard API not supported');
          fallbackCopyTextToClipboard(text);
          return;
        }

        navigator.clipboard.writeText(text).then(
          () => {
            this.selectedMembershipIdCopy = true;
            setTimeout(() => {
              this.selectedMembershipIdCopy = false;
            }, 2000);
          },
          (err) => {
            console.error('Unable to copy text', err);
          }
        );
      },
 
    calculateNettoPrice(){ // Calculate Netto Price when Brutoprice or VAT is changed
      if(this.subscription.vat){
        this.subscription.amount = Number((this.subscription.amountBruto / (100 + this.subscription.vat.percentage)*100).toFixed(4));
      }
      this.calculateTotalPrice();
    },

    calculatePrice(){
      this.subscription.amount = Number(this.subscription.amount);
      this.subscription.amountBruto = Number(this.subscription.amountBruto);

      if(this.subscription.amount){
        this.calculateBrutoPrice()
      } 
      else if(this.subscription.amountBruto){
        this.calculateNettoPrice()
      }
      this.calculateTotalPrice()
    },

    calculateBrutoPrice(){ // Calculate Bruto Price when Netto price or VAT is changed
       if(this.subscription.vat){
          let extraVat = (this.subscription.vat.percentage * this.subscription.amount) / 100;
          this.subscription.amountBruto = (extraVat + this.subscription.amount).toFixed(2);
       }
      this.calculateTotalPrice() 
    },

    calculateTotalPrice(){
      let subscriptionNumber = this.subscription.treatments.limitType == 'total' ? this.subscription.treatments.limitNumber : this.subscription.duration;
      this.price.bruto = (subscriptionNumber * this.subscription.amountBruto).toFixed(2);
      this.price.netto = (subscriptionNumber * this.subscription.amount).toFixed(4);
      
      
      if(this.subscription.periodicPaymentDiscount.active && this.subscription.periodicPaymentDiscount.number > 0){
        if(this.subscription.periodicPaymentDiscount.type == 'percentage'){
          this.price.periodicPaymentDiscount.periodic.bruto = ((this.subscription.amount) /100 * this.subscription.periodicPaymentDiscount.number).toFixed(2)
          if(this.price.periodicPaymentDiscount.periodic.bruto > 0){
            this.price.periodicPaymentDiscount.periodic.netto =  (this.price.periodicPaymentDiscount.periodic.bruto / ((this.subscription.vat.percentage / 100) + 1)).toFixed(2)
            this.price.periodicPaymentDiscount.total.bruto = (this.price.periodicPaymentDiscount.periodic.bruto * this.subscription.duration).toFixed(2)
            this.price.periodicPaymentDiscount.total.netto = (this.price.periodicPaymentDiscount.total.netto * this.subscription.duration).toFixed(4)
          }
          else{
            this.price.periodicPaymentDiscount.periodic.bruto = 0;
            this.price.periodicPaymentDiscount.periodic.netto = 0;
            this.price.periodicPaymentDiscount.total.bruto = 0;
            this.price.periodicPaymentDiscount.total.netto = 0;
          }
        }
        else{
          this.price.periodicPaymentDiscount.periodic.bruto = (this.subscription.periodicPaymentDiscount.number).toFixed(2)
          if(this.price.periodicPaymentDiscount.periodic.bruto > 0){
            this.price.periodicPaymentDiscount.periodic.netto = (this.price.periodicPaymentDiscount.periodic.bruto / ((this.subscription.vat.percentage / 100) + 1)).toFixed(2)
            this.price.periodicPaymentDiscount.total.bruto = (this.price.periodicPaymentDiscount.periodic.bruto * this.subscription.duration).toFixed(2)
            this.price.periodicPaymentDiscount.total.netto = (this.price.periodicPaymentDiscount.total.netto * this.subscription.duration).toFixed(4)
          }
          else{
            this.price.periodicPaymentDiscount.periodic.bruto = 0;
            this.price.periodicPaymentDiscount.periodic.netto = 0;
            this.price.periodicPaymentDiscount.total.bruto = 0;
            this.price.periodicPaymentDiscount.total.netto = 0;
          }
        }
        this.price.periodicPaymentBruto = (this.price.bruto - this.price.periodicPaymentDiscount.total.bruto).toFixed(2);
        this.price.periodicPaymentNetto = Number((this.price.netto - this.price.periodicPaymentDiscount.total.netto).toFixed(4));
      }
       else{
        this.price.periodicPaymentBruto = this.price.bruto;
        this.price.periodicPaymentNetto = this.price.netto;
        this.price.periodicPaymentDiscount.total.netto = 0;
        this.price.periodicPaymentDiscount.total.bruto = 0;
        this.price.periodicPaymentDiscount.periodic.netto = 0;
        this.price.periodicPaymentDiscount.periodic.bruto = 0;
      }

      if(this.subscription.singlePaymentDiscount.active && this.subscription.singlePaymentDiscount.number > 0){
        
        if(this.subscription.singlePaymentDiscount.type == 'percentage'){
          this.price.singlePaymentDiscount.bruto = ((this.subscription.amount * subscriptionNumber) / 100 * this.subscription.singlePaymentDiscount.number).toFixed(2)
          if(this.price.singlePaymentDiscount.bruto > 0){
            this.price.singlePaymentDiscount.netto =  Number((this.price.singlePaymentDiscount.bruto / ((this.subscription.vat.percentage / 100) + 1)).toFixed(2));
          }
          else{
            this.price.singlePaymentDiscount.bruto = 0;
            this.price.singlePaymentDiscount.netto = 0;
          }
        }
        else{
          this.price.singlePaymentDiscount.bruto = (this.subscription.singlePaymentDiscount.number).toFixed(2)
          if(this.price.singlePaymentDiscount.bruto > 0){
            this.price.singlePaymentDiscount.netto =  Number((this.price.singlePaymentDiscount.bruto / ((this.subscription.vat.percentage / 100) + 1)).toFixed(4));
          }
          else{
            this.price.singlePaymentDiscount.bruto = 0;
            this.price.singlePaymentDiscount.netto = 0;
          }
        }
        this.price.singlePaymentBruto = (this.price.bruto - this.price.singlePaymentDiscount.bruto).toFixed(2);
        this.price.singlePaymentNetto = Number((this.price.netto - this.price.singlePaymentDiscount.netto).toFixed(4));
      }
      else{
        this.price.singlePaymentBruto = this.price.bruto;
        this.price.singlePaymentNetto = this.price.netto;
        this.price.singlePaymentDiscount.bruto = 0;
        this.price.singlePaymentDiscount.netto = 0;
      }
    },

    payMethodChanges(){
      this.checkDiscounts();
      this.subscription.treatments.limitType = 'period';
    },

    checkDiscounts(){
      if(this.subscription.paymentTypes == "periodic"){
        this.subscription.singlePaymentDiscount.active = false;
        this.subscription.singlePaymentDiscount.number = 0;
      } 
      else if(this.subscription.paymentTypes == "single"){
        this.subscription.periodicPaymentDiscount.active = false;
        this.subscription.periodicPaymentDiscount.continue = false;
        this.subscription.periodicPaymentDiscount.number = 0;  
      }
      this.subscription.autoRenew = false;  
      this.calculateTotalPrice();
    },

    validateForm(){
      this.feedback = null;

      // TAB 1 GENERAL
      let languages = this.subscription.languages.length > 0 ? true : false; // Languages

      let name = true;
      if(this.subscription.languages && this.subscription.languages.length > 0){
        this.subscription.languages.forEach((language)=>{
          if(!this.subscription.name[language]){ name = false; }
        })
      }
      let branches = !this.subscription.branches.all && this.subscription.branches.branches.length == 0 ? false : true;

      let category = this.subscription.category ? true : false; // Categorie
      
      // TAB 2 SUBSCRIPTION
      let amount = this.subscription.amount ? true : false; // Price per period invullen
      let duration = this.subscription.duration ? true : false; // Minimale looptijd moet ingevuld worden

      let singlePaymentDiscount = ((this.subscription.singlePaymentDiscount.active && this.subscription.singlePaymentDiscount.number) || !this.subscription.singlePaymentDiscount.active) ? true : false; // Als eennmalige korting geselecteerd is ook een bedrag of percentage invullen
      let periodicPaymentDiscount = ((this.subscription.periodicPaymentDiscount.active && this.subscription.periodicPaymentDiscount.number) || !this.subscription.periodicPaymentDiscount.active) ? true : false; // Als periodieke korting geselecteerd is ook een bedrag of percentage invullen

      let treatments = ((this.subscription.treatments.active && this.subscription.treatments.treatments.length > 0) || !this.subscription.treatments.active) ? true : false; // Als behandeling gekoppeld is ook een behandeling selecteren invullen
      let treatmentsLimit = ((this.subscription.treatments.limit && this.subscription.treatments.limitNumber) || !this.subscription.treatments.limit) ? true : false; // Als behandeling gelimiteert is maximaal aantal invullen

      let days = true;

      for (const [key] of Object.entries(this.subscription.days)) { // Controleren of starttijd groter of gelijk is aan eindtijd
        if(this.subscription.days[key].active){
          if(!this.subscription.days[key].allDay){
            if(this.subscription.days[key].start >= this.subscription.days[key].end){
              days = false;
            }
          }
        }
      }

      // TAB 3 PAYMENT
      let paymentTypes = (((this.subscription.paymentTypes == 'singleAndPeriodic' || this.subscription.paymentTypes == 'periodic') && this.subscription.debitTypes.length > 0) || this.subscription.paymentTypes == 'single') ? true : false; // Bij periodieke betaling een incassomethode invullen
      let mandateTypes = (((this.subscription.paymentTypes == 'singleAndPeriodic' || this.subscription.paymentTypes == 'periodic') && this.subscription.mandateTypes.length > 0) || this.subscription.paymentTypes == 'single') ? true : false; // Bij periodieke betaling een mandaattype invullen

      if(languages && name && category && amount && duration && singlePaymentDiscount && periodicPaymentDiscount && treatments && treatmentsLimit && paymentTypes && mandateTypes && branches && days){
        this.formCompleted = true; // Form is valid
        if(this.type == 'new'){
          this.addSubscription();
        }
        else{
          this.updateSubscription();
        }
        
      }
      else{
        if(!languages){
          this.feedback = "Selecteer minimaal één taal."
        }
        else if(!name){
          this.feedback = "Vul de naam van het abonnement in."
        }
        else if(!category){
          this.feedback = "Selecteer een categorie"
        }
        else if(!amount){
          this.feedback = "Vul de prijs van het abonnement in."
        }
        else if(!duration){
          this.feedback = "Vul de minimale looptijd van het abonnement in."
        }
        else if(!singlePaymentDiscount){
          this.feedback = "Vul de éénmalige kortingsbedrag of percentage in."
        }
        else if(!periodicPaymentDiscount){
          this.feedback = "Vul de periodieke kortingsbedrag of percentage in."
        }
        else if(!treatments){
          this.feedback = "Selecteer gekoppelde behandelingen."
        }
        else if(!treatmentsLimit){
          this.feedback = "Vul het maximaal aantal behandelingen in."
        }
        else if(!paymentTypes){
          this.feedback = "Selecteer minimaal één incassomethode."
        }
        else if(!mandateTypes){
          this.feedback = "Selecteer mandaattype."
        }
        else if(!branches){
          this.feedback = "Selecteer minimaal één vestiging."
        }
        else if(!days){
          this.feedback = "Vul de dagen bij geldigheid goed in."
        }
        this.formCompleted = false; // Form is NOT valid 
      }    
    },


    getCategories(){ // Load membership categories
      db.collection('membershipCategories')
      .get()
      .then(querySnapshot=>{
          querySnapshot.forEach(doc=>{
              this.categories.push({
                id: doc.id,
                name: doc.data().name
              });
          })
      })
      .then(()=>{
          this.categories_Loading = false;
      })
      .catch(err=>{
          console.error(err);
      })
    },

    // Active whenever you changed selected abonnement type between ( rittenkaart en abonnement)
    subscriptionChange(){
      if(this.subscription.packageType){
        if(this.subscription.packageType == 'subscription'){
          this.subscription.duration = 0;
          // this.isSubscription = true;
          // this.isCardType = false;
          this.onFrequencyChange();
        }
        else{
          // this.isSubscription = false;
          // this.isCardType = true;
          // this.cardFrequentiePer = 'monthly';
          this.subscription.paymentFrequency = 0;
          this.subscription.duration = 5;
        }  
      }
    },

   //Frequency type changing fetch another select items 
    onFrequencyChange(){
      if(this.subscription.paymentPeriod){
        if(this.subscription.paymentPeriod == 'daily'){
          this.frequencyAmountsItems = this.getDaysCollection();
          this.selectProcessing = false;
          this.annuallySelected = false;
          this.subscription.paymentFrequency = 13;
        }
        if(this.subscription.paymentPeriod == 'everyWeek' || this.subscription.paymentPeriod == 'everyFourWeeks'){
          this.frequencyAmountsItems = this.paymentFrequencyWeeks;
          this.processingCollection = this.paymentFrequencyDays;
          this.countities = this.getCountities();
          this.selectProcessing = true;
          this.annuallySelected = false;
          this.subscription.duration = 52; 
          if(this.subscription.paymentPeriod == 'everyFourWeeks'){
            this.subscription.duration = 13
          }
          this.subscription.paymentFrequency = 1; 
          this.subscription.paymentDay  = 4;

        }
        else if(this.subscription.paymentPeriod == 'everyMonth' ){
          this.frequencyAmountsItems = this.getMonthsCollection();
          this.processingCollection =  this.getDaysOfMonth();
          this.countities = this.getCountities();
          this.selectProcessing = true;
          this.annuallySelected = false;
          this.subscription.duration = 12;
          this.subscription.paymentFrequency = 1;
          this.subscription.paymentDay = 14;

        }
        else if(this.subscription.paymentPeriod == 'everyQuarter' || this.subscription.paymentPeriod == 'everyHalfYear' || this.subscription.paymentPeriod == 'everyYear'){
          this.frequencyAmountsItems = this.paymentFrequencyYear;
          this.processingCollection =  this.getDaysOfMonth();
          this.countities = [ {id: 0, name: 'Doorlopend'},{id: 1, name: '1x'},{id: 2, name: '2x'},{id: 3, name: '3x'},{id: 4, name: '4x'},{id: 5, name: '5x'}];
          this.selectProcessing = true;
          this.annuallySelected = true;
          this.subscription.duration = 1; 
          this.subscription.paymentFrequency = 1; 

          this.subscription.paymentDay = 14;
        }
      }
    },

    resetDayTimes(key, type){
      if(type && type == 'all'){
        this.subscription.days[key].allDay = null;
      }
      this.subscription.days[key].start = null;
      this.subscription.days[key].end = null;
   
    },

    checkSubscriptionLanguages(){
      if(this.subscription.languages.length != 0){
        this.selectedLanguage =  this.subscription.languages[0]
      }
    },
   
    //Functions to fitch select items with loops
   
    getCountities(){ //Hoeveel keer per selected frequency? (week and month)
      let items = [
        { 
          id: 0,
          name: 'Doorlopend'
        }
      ];

      for(let i = 1 ; i < 52 ; i++){
        let data = {
            id: i,
            name: i + 'x'
          }
        items.push(data)
      }
      return items;
    },

    getDaysCollection(){ // Days functions
      let days = [];
      for(let i = 0 ; i < 30; i++){
        if(i==0){
          let data = {
            id: i,
            name: 'Elke dag'
          }
          days.push(data)
        }
        else{
          let data = {
            id: i,
            name: 'Elke '+ (i + 1) + ' dagen'
          }
          days.push(data)
        }
      }
      return days;
    },

    //Weeks functions
    getWeeksCollection(){
      let weeks = [
      {
        id: 1,
        name: "Elke week"
      },
      {
        id: 2,
        name: "Elke 2 weken"
      },
      {
        id: 4,
        name: "Elke 4 weken"
      }
      ];
      for(let i = 0 ; i < 4; i++){
        if(i==0){
          let data = {
            id: i,
            name: 'Elke week'
          }
          weeks.push(data)
        }
        else{
        let data = {
            id: i,
            name: 'Elke '+ (i + 1) + ' weken'
          }
          weeks.push(data)
        }
      }
      return weeks;
    },
    
    //Month functions
    getMonthsCollection(){
      let months = [
        {
            id: 1,
            name:'Elke maand'
        }
      ];
      
      for(let i = 2 ; i < 13 ; i++){
        let data = {
            id: i,
            name: 'Elke '+ i + ' maanden'
          }
          months.push(data)
      }
      return months;
    },

    getDaysOfMonth(){
      let daysOfMonth = [
        {
            id: 1,
            name:'1e van de maand'
        }
      ];

      for(let i = 2 ; i < 27 ; i++){
        let data = {
            id: i,
            name: i + 'e van de maand'
          }
        daysOfMonth.push(data)
      }
      return daysOfMonth;
    },

    addSubscription(){
      db.collection('membershipPackages').add({
        languages: this.subscription.languages,
        name: this.subscription.name,
        category: this.subscription.category,
        description: this.subscription.description,
        registrationDescription: this.subscription.registrationDescription,
        registrationUrl: this.subscription.registrationUrl,
        branches: this.subscription.branches,

        paymentTypes: this.subscription.paymentTypes,
        paymentPeriod:  this.subscription.paymentPeriod,
        duration:  this.subscription.duration,
        membershipStart: this.subscription.membershipStart,
        paymentDay: this.subscription.paymentDay,
        autoRenew: this.subscription.autoRenew,
        amountBruto: this.subscription.amountBruto,
        amount: this.subscription.amount,
        active: this.subscription.active,
        vat: this.subscription.vat.id,

        periodicPaymentDiscount: this.subscription.periodicPaymentDiscount,
        singlePaymentDiscount: this.subscription.singlePaymentDiscount,
        freePeriod: this.subscription.freePeriod,
        trialPeriod: this.subscription.trialPeriod,
        treatments: this.subscription.treatments,

        debitTypes: this.subscription.debitTypes,
        mandateTypes: this.subscription.mandateTypes,
        sepaAttempts: this.subscription.sepaAttempts,
        sendInvoice: this.subscription.sendInvoice,
        paymentReminder: this.subscription.paymentReminder,
        days: this.subscription.days,
        separatedDiscount: this.subscription.separatedDiscount,
        companyId:this.$store.state.activeCompany.id,

        posDescription: this.subscription.posDescription,
        offPeakMessage: this.subscription.offPeakMessage,
        peakMessage: this.subscription.peakMessage,

        terms: this.subscription.terms,
        deleted: false,
        created: new Date()
      })
      .then(()=>{
        this.$emit("addMembership");
        this.closeDialog();
      })
      .catch(err=>{
        console.error("Error adding membershipPackage:", err)
      })
    },

    updateSubscription(){
      db.collection('membershipPackages').doc(this.subscription.id).set({
        languages: this.subscription.languages,
        name: this.subscription.name,
        category: this.subscription.category,
        description: this.subscription.description,
        registrationDescription: this.subscription.registrationDescription,
        registrationUrl: this.subscription.registrationUrl,
        branches: this.subscription.branches,

        paymentTypes: this.subscription.paymentTypes,
        paymentPeriod:  this.subscription.paymentPeriod,
        duration:  this.subscription.duration,
        membershipStart: this.subscription.membershipStart,
        paymentDay: this.subscription.paymentDay,
        autoRenew: this.subscription.autoRenew,

        amount: this.subscription.amount,
        active: this.subscription.active,
        vat: this.subscription.vat.id,

        periodicPaymentDiscount: this.subscription.periodicPaymentDiscount,
        singlePaymentDiscount: this.subscription.singlePaymentDiscount,
        freePeriod: this.subscription.freePeriod,
        trialPeriod: this.subscription.trialPeriod,
        treatments: this.subscription.treatments,

        posDescription: this.subscription.posDescription,
        offPeakMessage: this.subscription.offPeakMessage,
        peakMessage: this.subscription.peakMessage,

        debitTypes: this.subscription.debitTypes,
        mandateTypes: this.subscription.mandateTypes,
        sepaAttempts: this.subscription.sepaAttempts,
        sendInvoice: this.subscription.sendInvoice,
        paymentReminder: this.subscription.paymentReminder,
        days: this.subscription.days,
        separatedDiscount: this.subscription.separatedDiscount,
        terms: this.subscription.terms,
      }, {merge: true})
      .then(()=>{
        this.$emit("addMembership");
        this.closeDialog();
      })
      .catch(err=>{
        console.error("Error adding membershipPackage:", err)
      })
    },

    durationSuffix(periodNumber) {
      if(this.subscription.paymentPeriod == 'daily'){
        if(periodNumber == 1){
          return this.$t('general.day', this.$store.state.locale).toLowerCase()
        }
        else{
          return this.$t('general.days', this.$store.state.locale).toLowerCase()
        }
      }

      else if(this.subscription.paymentPeriod == 'everyWeek'){
        if(periodNumber== 1){
          return this.$t('general.week', this.$store.state.locale).toLowerCase()
        }
        else{
          return this.$t('general.weeks', this.$store.state.locale).toLowerCase()
        }
      }

      else if(this.subscription.paymentPeriod == 'everyFourWeeks'){
        return this.$t('general.fourWeeks', this.$store.state.locale).toLowerCase()
      }

      else if(this.subscription.paymentPeriod == 'everyMonth'){
        if(periodNumber == 1){
          return this.$t('general.month', this.$store.state.locale).toLowerCase()
        }
        else{
          return this.$t('general.months', this.$store.state.locale).toLowerCase()
        }
      }
      else if(this.subscription.paymentPeriod == 'everyQuarter'){
        if(periodNumber == 1){
          return this.$t('general.quarter', this.$store.state.locale).toLowerCase()
        }
        else{
          return this.$t('general.quarters', this.$store.state.locale).toLowerCase()
        }
      }
      else if(this.subscription.paymentPeriod == 'everyHalfYear'){
        if(periodNumber== 1){
          return this.$t('general.halfYear', this.$store.state.locale).toLowerCase()
        }
        else{
          return this.$t('general.halfYears', this.$store.state.locale).toLowerCase()
        }
      }
      else if(this.subscription.paymentPeriod == 'everyYear'){
        if(periodNumber == 1){
          return this.$t('general.year', this.$store.state.locale).toLowerCase()
        }
        else{
          return this.$t('general.years', this.$store.state.locale).toLowerCase()
        }
      }
    },


  },



  computed: {

    filteredDefaultModifiers(){
      return this.discounts.filter(discount => discount.modifierGroup === 'defaultModifier' && !discount.automatic);
    },

    filteredMembershipModifiers(){
      return this.discounts.filter(discount => discount.modifierGroup === 'membershipModifier');
    },
    
    subscriptionLanguages(){
      let languages = new Array();
      this.languages.forEach((language)=>{
        this.subscription.languages.forEach((selectedLanguage)=>{
          if(language.icon == selectedLanguage){
            languages.push(language)
          }
        })
      })
      return languages;
    },


   // durationCalculated(){
    //   let periodDays = null;
    //   let period = ""
    //   let year = 365;
    //   let month = 30.416;
    //   let week = 7;
    //   let calcDays = true;

    //   if(this.subscription.paymentPeriod == 'daily'){
    //     periodDays = this.subscription.duration;
    //   }

    //   else if(this.subscription.paymentPeriod == 'everyWeek'){
    //     periodDays = Math.ceil(this.subscription.duration * week);
    //     calcDays = false;
    //   }
    //   else if(this.subscription.paymentPeriod == 'everyFourWeeks'){
    //     periodDays = Math.ceil(this.subscription.duration * week * 4);
    //     calcDays = false;
    //   }

    //   else if(this.subscription.paymentPeriod == 'everyMonth'){
    //     periodDays = Math.ceil(this.subscription.duration * month);
    //   }
    //   else if(this.subscription.paymentPeriod == 'everyQuarter'){
    //     periodDays = Math.ceil(this.subscription.duration * 91.25);
    //   }
    //   else if(this.subscription.paymentPeriod == 'everyHalfYear'){
    //     periodDays = Math.ceil(this.subscription.duration * 182.5);
    //   }
    //   else if(this.subscription.paymentPeriod == 'everyYear'){
    //     periodDays = Math.ceil(this.subscription.duration * year);
    //   }

    //   let years = Math.floor(periodDays / 365);
    //   let months = Math.floor(periodDays / month);
    //   let weeks = Math.floor(periodDays / week);
    //   let remainingMonths = null;
    //   let remainingWeeks = null;
    //   let remainingDays = null;
    //   let seperator = '';
    //   console.log(periodDays, years, months, weeks)
        
    //   if(years > 0){
    //     period = years + " jaar"
    //   }
    
    //   if(months > 0 ){
    //     remainingMonths =  Math.floor((periodDays - (years * year)) / month);
    //     seperator = ''
    //     let monthsLabel = ' maand'
    //     if(remainingMonths > 1){
    //       monthsLabel = ' maanden'
    //     }
    //     if(years > 0){
    //       seperator = ", "
    //     }
    //     if(remainingMonths > 0){
    //       period = period + seperator + remainingMonths + monthsLabel;
    //     }
    //   }

    //   if(weeks > 0){
    //     remainingWeeks =  Math.floor((periodDays - ((years * year) + (remainingMonths * month))) / week) ;
    //     seperator = ''
    //     let weekLabel = ' week'
    //     if(remainingWeeks > 1){
    //       weekLabel = ' weken'
    //     }
    //     if(remainingMonths > 0 || years > 0){
    //       seperator = ", "
    //     }
        
    //     if(remainingWeeks > 0){
    //       period = period + seperator + remainingWeeks + weekLabel
    //     }
    //   }

    //   if(periodDays > 0 && calcDays){
    //     console.log("days: ", periodDays - ((years * year) + (remainingMonths * month )+ (remainingWeeks * week)))
    //     remainingDays =  Math.floor(periodDays - ((years * year) + (remainingMonths * month )+ (remainingWeeks * week))) ;

    //     seperator = ''
    //     let dayLabel = ' dag'
    //     if(remainingDays > 1){
    //       dayLabel = ' dagen'
    //     }
    //     if(remainingMonths > 0 || remainingWeeks > 0 || years > 0){
    //       seperator = " en "
    //     }
        
    //     if(remainingDays > 0){
    //       period = period + seperator + remainingDays + dayLabel
    //     }
    //   }
    //   return period;
      
    // },

    paymentPeriode(){
      if(this.subscription.paymentPeriod == 'daily'){
        return "day"
      }

      else if(this.subscription.paymentPeriod == 'everyWeek'){
        return "week"
      }

      else if(this.subscription.paymentPeriod == 'everyFourWeeks'){
        return "fourWeeks"
      }

      else if(this.subscription.paymentPeriod == 'everyMonth'){
        return "month"
      }
      else if(this.subscription.paymentPeriod == 'everyQuarter'){
        return "quarter"
      }
      else if(this.subscription.paymentPeriod == 'everyHalfYear'){
        return "halfYear"
      }
      else if(this.subscription.paymentPeriod == 'everyYear'){
        return "year"
      }
    },

    
    singleDiscountInfo(){
      if(this.subscription.singlePaymentDiscount.type == "amount"){
        return {
          prefix: "€",
          suffix: null,
          label: "Kortingsbedrag",
          mask: "####"
        }
      }
      else {
        return {
          prefix: null,
          suffix: "%",
          label: "Kortingspercentage",
          mask: "##"
        }
      }
    },
    periodicDiscountInfo(){
      if(this.subscription.singlePaymentDiscount.type == "amount"){
        return {
          prefix: "€",
          suffix: null,
          label: "Kortingsbedrag",
          mask: "####"
        }
      } 
      else {
        return {
          prefix: null,
          suffix: "%",
          label: "Kortingspercentage",
          mask: "##"
        }
      }
    },

    limitTypes(){
      return [
        {
          id: "total",
          name: this.$t('memberships.perTotalMembershipLength', this.$store.state.locale),
          disabled: this.subscription.paymentTypes != 'single'
        },
        {
          id: "period",
          name: this.$t('memberships.perPeriod', this.$store.state.locale),
        }
      ]
    },
    
    filteredTreatments(){
      return this.treatments
      // return this.treatments.filter(treatment=>{
      //   // return treatment.title && ((treatment.title.en.toLowerCase().includes(this.treatmentsSearch.toLowerCase())) || (treatment.title.nl.toLowerCase().includes(this.treatmentsSearch.toLowerCase())));
      // })
    },
    changed(){
      if(_.isEqual(this.subscription, this.subscriptionOriginal) == false){
        return true;
      } 
      return false;
    }
  }
};
</script>

<style scoped>
  .invoice-options{
    margin-top: 20px;
  }
  .v-row-switch{
    align-items:baseline;
    margin: 0;
    margin-bottom:-25px;
  }
  .switch-col{
    max-width:90%;
    font-size: 0.8rem;
  }
  .space-between{
    margin-left: 10px;
  }
  .hover{
    cursor: pointer;
  }
</style>
