<template>
  <div class="tab-component-wrapper">
    <div v-if="loading">
      <div style="position: relative; padding: 200px 0;">
        <div class="thrive-loader"></div>
      </div>
    </div>

    <v-container grid-list-md class="ma-0 pa-0" v-if="!loading">
      <v-layout row wrap v-if="products.length > 0">
        <v-flex xs3 style="max-width: 280px;">
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme" v-show="products.length > 0">
            <v-text-field
              v-model="search"
              outlined
              dense
              clearable
              hide-details
              background-color="background"  
              name="searchfield"
              :label=" $t('general.search', $store.state.locale)"
              prepend-inner-icon="mdi-magnify"
              single-line
              class="ma-2"
              @click="filterProductView('all', null)"
              @input="filterProductView(null,'search')"
              @click:clear="filterProductView('all'), search = ''"
              ></v-text-field>

            <v-divider></v-divider>

            <!-- / Product Brands \ -->
            <v-list class="pa-0 ma-0" dense>
              <v-subheader>{{ $t('reports.sales.brands', $store.state.locale) }}</v-subheader>
              <v-divider></v-divider>
              <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('allSuppliers')" @click="filterProductView('all', 'supplier')">
                <v-list-item-content>
                  <v-list-item-title>{{ $t('general.all', $store.state.locale) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div v-for="(productBrand, index) in productBrandsFiltered" :key="index">
                <v-divider></v-divider>
                <v-list-group v-if="productBrand.groups && productBrand.groups.length > 0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView(productBrand.id)" :value="(selectedSupplier == productBrand.id)">
                  <template v-slot:activator>
                    <v-list-item-title  @click="filterProductView(productBrand.id, 'supplier')">{{ productBrand.name }}</v-list-item-title>
                  </template>
                  
                  <div class="product-group-wrapper" v-for="(group, i) in activeProductBrands(productBrand.groups)" :key="i" :style="{'backgroundColor': $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader}">
                    <v-divider></v-divider>
                    <v-list-item dense :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" backgroundColor="red" :input-value="selectedView(group.id)" @click="filterProductView(group.id, 'group', productBrand.id)">
                      <v-list-item-content>
                        <v-list-item-title>{{ group.name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </div>
                  
                </v-list-group>
 
                <v-list-item  v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView(productBrand.id)" :value="(selectedSupplier == productBrand.id)">
                  <v-list-item-content>
                    <v-list-item-title  @click="filterProductView(productBrand.id, 'supplier')">{{ productBrand.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <v-divider></v-divider>
            </v-list>
            <!-- \ Product Brands / -->

            <!-- / Product Categories \ -->
            <v-list class="pa-0 ma-0" dense>
              <v-subheader>{{ $t('indicator.categories', $store.state.locale) }}</v-subheader>
              <v-divider></v-divider>
              <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('all')" @click="filterProductView('all',  null)">
                <v-list-item-content>
                  <v-list-item-title>{{ $t('general.all', $store.state.locale) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div v-for="(productCategory, index) in productCategoriesFiltered" :key="index">
                <v-divider></v-divider>
                <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView(productCategory.id)" @click="filterProductView(productCategory.id, 'category')">
                  <v-list-item-content>
                    <v-list-item-title>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, productCategory.name) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <v-divider></v-divider>
            </v-list>
            <!-- \ Product Categories / -->

            <!-- / Add New Product \ -->
            <v-list class="pa-0 ma-0" dense>
              <div class="pa-3">
                <v-btn dark height="40" depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="addProduct()" block class="ma-0">
                  <v-icon left>mdi-plus</v-icon> {{ $t('general.new', $store.state.locale) }} 
                </v-btn>
              </div>
            </v-list>
            <!-- \ Add New Product / -->
          </v-card>
        </v-flex>

        <v-flex xs9 style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">
          <v-card  flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme" v-if="productsFiltered.length > 0">
            <v-container grid-list-md class=" ma-0 pa-0" fluid>
              <v-layout row wrap>
                <v-flex md4 xs12 class="text-right"></v-flex>
                <v-flex md4 xs12>
                  <p v-if="productsFiltered && productsFiltered.length == 1" class="text-center py-3 pb-1 ma-0">{{ productsFiltered.length }} <span style="text-transform: lowercase;">{{ $t('general.product', $store.state.locale) }}</span></p>
                  <p v-if="productsFiltered && productsFiltered.length > 1" class="text-center py-3 pb-1 ma-0">{{ productsFiltered.length }} <span style="text-transform: lowercase;">{{ $t('general.products', $store.state.locale) }}</span></p>
                  <v-pagination v-if="pageCount > 1" class="text-center pt-0 mt-0 pb-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :total-visible="9" v-model="productPage" :length="pageCount"></v-pagination>   
                </v-flex>
                <v-flex md4 xs12 class="text-right">
                  <v-btn
                    class="ma-2"
                    outlined
                    depressed
                    dark
                    height="30px"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" 
                    v-if="selected.length > 0"
                    @click="showDeleteDialog(null, 'deleteProducts')"
                    >
                    {{ $t('general.remove', $store.state.locale)}}
                  </v-btn>
                  <v-btn
                      v-if="selected.length == 1"
                      dark
                      fab
                      x-small
                      outlined
                      class="ma-2"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      @click="cloneProduct()"
                      >
                      <v-icon size="18" dark>mdi-content-copy</v-icon>
                    </v-btn>
                </v-flex>
              </v-layout>
            </v-container>

            <v-divider></v-divider>

            <v-data-table
              v-if="productsFiltered.length > 0"
              :headers="headers"
              :items="productsFiltered"
              v-model="selected"   
              dense
              :page.sync="productPage"
              :single-select="true"
              show-select
              class="dense-table"
              hide-default-footer
              :itemsPerPage.sync="pages"
              :no-results-text="`Geen resultaten gevonden voor: ${search}`"
              > 
              <template v-slot:item="{item, isSelected, select,}">
                <tr>
                  <td>
                    <v-icon @click="select(true)" v-if="!isSelected">mdi-checkbox-blank-outline</v-icon>
                    <v-icon @click="select(false)" v-if="isSelected" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-checkbox-marked</v-icon>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.content }}</td>
                  <td>
                    <v-chip v-for="(category, index) in findProductCategories(item.category)" :key="index" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mr-2 my-1 custom-chip-style">{{ category }}</v-chip>
                  </td>
                  <td>
                    <span v-for="(supplier, index) in productSuppliers" :key="index">
                      <span v-if="supplier.id == item.supplier"> {{supplier.name}}</span>
                    </span>
                  </td>
                  <td class="text-right">{{ calculateBrutoPrice(item.priceNetto, item.vat)  }}</td>
                  <!-- <td class="text-right">
                    <span v-for="(vat, index) in vatItems" :key="index">
                      <span v-if="vat.id == item.vat"> {{vat.percentage}}%</span>
                    </span>
                  </td> -->
                  <!-- <td class="text-right">
                    <span v-for="(vat, index) in vatItems" :key="index">
                      <span v-if="vat.id == item.vat"> &euro;{{ (item.priceNetto * ((vat.percentage / 100)+1)).toFixed(2) }}</span>
                    </span>
                  </td> -->
                  <td class="text-center">
                    <v-icon class="px-2" :color="activeColor(item.active)" small>mdi-checkbox-blank-circle</v-icon>
                  </td>
                  <td>
                    <v-btn icon class="mx-0" @click="editProduct(item)">
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table> 

            <v-divider v-if="pageCount > 1" class="pb-3"></v-divider>

            <v-pagination v-if="pageCount > 1" class="text-center pt-0 mt-0 py-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :total-visible="9" v-model="productPage" :length="pageCount"></v-pagination>   

          </v-card>

          <div class="no-data-message" v-if="productsFiltered.length == 0 && (productFilterActive == 'search' || productFilterType == 'group')">
            <v-avatar
              size="58"
              light
              class="ma-0 mr-2 custom-avatar-style" 
              rounded="lg"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
              >
              <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-spray-bottle</v-icon>
            </v-avatar>
            <p v-if="productFilterActive == 'search'"> {{ $t('treatments.noResultsFoundFor', $store.state.locale) }} <i>{{ search }}</i>.</p>
            <p v-if="productFilterType == 'group'"> {{ $t('products.noProductsInGroup', $store.state.locale) }}.</p>
          </div>

        </v-flex>
      </v-layout>
    
      <div class="no-data-message" v-if="products.length == 0">
        <v-avatar
          size="58"
          light
          class="ma-0 mr-2 custom-avatar-style" 
          rounded="lg"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
          >
          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-spray-bottle</v-icon>
        </v-avatar>
        <p> {{ $t('products.noProductsFound', $store.state.locale) }}  {{ $store.state.activeCompany.name }}.</p>
        <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addProduct()">
          <v-icon left >mdi-plus</v-icon> {{ $t('products.newProduct', $store.state.locale) }}
        </v-btn>
      </div>
    </v-container>

    <!-- / Product Dialog \ -->
    <product-form
      :loading="editProductLoading"
      :vatItems="vatItems"
      :productSuppliers="productSuppliers"
      :productCategories="productCategories"
      :branches="branches"
      @editProductDialogChange="editProductDialog = $event"
      @updateData="loadProducts('update')"
    ></product-form>
    <!-- \ Product Dialog / -->
  </div>
</template>

<script>
  import db from "../firebase/init";
  import { bus } from "../main";
  import ProductForm from "../components/modals/ProductForm.vue";
  import {cashConv} from "@/modules/quickWins";
  import _ from "lodash";
  import moment from "moment";

  export default {
    props: {
      productCategories: Array,
      productSuppliers: Array,
      supplierProductGroups: Array,
      branches: Array,
      vatItems: Array
    },
    components: {
      "product-form": ProductForm
    },
    name: "Products",

    data(){
      return{
        newProductDialog: false,
        editProductDialog: false,
        editProductLoading: true,
        productFilterActive: "all",
        productFilterSupplierId: null,
        productsFiltered: new Array(),
        products: new Array(),
        pageCount: 0,
        productPage: 1,
        pages: 40,   
        loading: true,
        search: "",
        selected: new Array(),
        productFilterType: "none",
        selectedSupplier: null,
        headers: [
          {
            text: this.$t('general.name', this.$store.state.locale),
            align: "left",
            sortable: true,
            value: "name"
          },
          {
            text: this.$t('general.content', this.$store.state.locale),
            align: "left",
            value: "productType",
            width: "120px",
            sortable: false
          },
          {
            text: this.$t('products.header.categories', this.$store.state.locale),
            align: "left",
            value: "productType"
          },
          {
            text: this.$t('products.header.supplier', this.$store.state.locale),
            align: "left",
            value: "time",
            sortable: false
          },
          // {
          //   text: this.$t('general.vat', this.$store.state.locale),
          //   align: "end",
          //   value: "vat",
          //   width: "70px",
          //   sortable: false
          // },
          {
            text: this.$t('products.header.grossPrice', this.$store.state.locale),
            align: "end",
            value: "nettoPrice",
            width: "110px"
          },
          {
            text: this.$t('products.header.active', this.$store.state.locale),
            align: "center",
            value: "gender",
            width: "40px",
            sortable: false
          },
          {
            text: "",
            align: "left",
            value: "edit",
            width: "40px",
            sortable: false,
          }
        ]
      }
    },

    created() {
      this.loadProducts();
      bus.$on("companyInventoryUpdate", this.loadProducts);
      bus.$on("deleteProducts", () => {this.deleteProducts() });
    },

    methods: {
      loadProducts(type) {
        this.loading = type && type == 'update' ? false : true ;
        this.products = new Array()
        db.collection("products")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .get()
        .then(snap => {
          this.products = new Array();
          snap.forEach(doc => {
            let product = doc.data();
            product.id = doc.id;
            if(!product.deleted || !product.deleted){
              this.products.push(product);
            }
          });
        })
        .then(()=>{
          this.filterProductView(this.productFilterActive, this.productFilterType, this.productFilterSupplierId);
          this.loading = false;
        })
      },

      async filterProductView(id, type, supplierId){
        this.productPage = 1;
        this.productFilterType = type;
        this.productFilterSupplierId = supplierId;
        if(id == "all"){
          if(type == 'supplier'){
            this.productFilterActive = 'allSuppliers';
          } 
          else{
            this.productFilterActive = 'all';
          }

          this.productsFiltered = _.cloneDeep(this.products);
          this.search = "";
        }
        else{
          if(type == 'search'){
            if(this.search && this.search.length > 0){
              this.productFilterActive = 'search';
              this.productsFiltered = this.products.filter(product => {
                let productName =  product.name.toLowerCase().includes(this.search.toLowerCase()) ? true : false;
                let productDescription = product.productDescription ? product.productDescription.toLowerCase().includes(this.search.toLowerCase()) ? true : false : false;
                let productCategory = false;
                let productSupplier = false;

                this.productCategories.forEach(type=>{
                  product.category.forEach(category=>{
                    if(category == type.id){
                      if(this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, type.name).toLowerCase().includes(this.search.toLowerCase())){
                        productCategory = true;
                      }
                    }
                  })
                })

                this.productSuppliers.forEach(supplier=>{
                  if(product.supplier == supplier.id){
                    if(supplier.name.toLowerCase().includes(this.search.toLowerCase())){
                      productSupplier = true;
                    }
                  }
                })

                if(productName || productDescription ||  productCategory || productSupplier){
                  return product;
                }
              });
            }
            else{
              this.productFilterActive = 'all';
              this.productsFiltered = _.cloneDeep(this.products);
            }
            
          }

          if(type == 'category'){
            this.productFilterActive = id;
            this.productsFiltered = await this.products.filter(item => {
              return item.category.some(cat => cat === id);
            })
            this.search = "";
          }

          if(type == 'supplier'){
            this.productFilterActive = id;
            this.productsFiltered = await this.products.filter(item => {
              return item.supplier == id;
            })
            this.search = "";
          }

          if(type == 'group'){
            this.selectedSupplier = supplierId;
            this.productFilterActive = id;
            this.productsFiltered = await this.products.filter(item => {
              return item.supplierProductGroup == id;
            })
            this.search = "";
          }
        }
        this.pageCount = Math.ceil(this.productsFiltered.length / 40);
        this.loading = false;
      },

      activeColor(active){ // Set color green for active or red for inactive
        return active ? "#81C784" : "#EF5350"; 
      },

      addProduct(){
        bus.$emit("ProductModal", null, 'new');
      },

      findProductCategories(category){
        let categories = new Array();
        if(Array.isArray(category) && category.length > 0){
          category.forEach(item => {
            let foundItem = _.find(this.productCategories, ['id', item]);
            if(foundItem){
              categories.push(foundItem.name[(this.$store.state.locale).toLowerCase()]);
            }
          })
        }
        return categories;
      },

      /**
       * @param {number} nettoPrice - nettoPrice of product
       * @param {number} vatId - vatId of product
       */
      calculateBrutoPrice(nettoPrice, vatId){
        let brutoPrice = "-";
        let vatItem = this.$store.state.vatItems.find((vat)=> { return vat.id == vatId })
        if(vatItem != undefined && nettoPrice > 0){
          brutoPrice = new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(Number(nettoPrice * ((vatItem.percentage/ 100)+1)));
        }
        return brutoPrice;
      },
                  
      async editProduct(item) {
        let selectedProductInfo = Object.assign(new Object(), item);
        let vat
        let counter = 0
        await new Promise((resolve)=>{
          this.vatItems.forEach(vatItem => {
            if(vatItem.id == item.vat){
              vat = (vatItem.percentage / 100) + 1
            }
            counter++
            if(counter == this.vatItems.length){
              resolve()
            }
          })
        })

        selectedProductInfo.priceBruto = (selectedProductInfo.priceNetto * vat).toFixed(2)

        await new Promise((resolve)=>{
          let branchCounter = 0
          this.branches.forEach(branch => {
            if(item.stock[branch.id]){
              if(!item.stock[branch.id].minStockValue){
                item.stock[branch.id].minStockValue = 0;
              }
              if(!item.stock[branch.id].stockValue){
                item.stock[branch.id].stockValue = 0;
              }
            }
            else{
              item.stock[branch.id] = {
                minStockValue: 0,
                stockValue: 0,
              };
            }
            branchCounter++
            if(branchCounter == this.branches.length){
              resolve()
            }
          });
        })

        bus.$emit("ProductModal", selectedProductInfo, "edit");
        this.editProductLoading = false;
        this.editProductDialog = true;
      },

      cloneProduct(){
        bus.$emit("ProductModal", this.selected[0], "clone");
        this.selected = new Array();
      },

      showDeleteDialog(id, deleteFunction){
        let deleteInformation = new Object();
        deleteInformation.id = id;
        deleteInformation.emit = deleteFunction;
  
        if(this.selected.length > 1){
          deleteInformation.title = this.$t('products.removeProducts', this.$store.state.locale);
          deleteInformation.info = this.$t('products.removeProductsInfo', this.$store.state.locale);
        }
        else{
          deleteInformation.title = this.$t('products.removeProduct', this.$store.state.locale);
          deleteInformation.info = this.$t('products.removeProductInfo', this.$store.state.locale);
        }
        bus.$emit('deletedModal', deleteInformation, 'open');
      },

      activeProductBrands(groups){
        let filterdGroups = groups.filter((item) => { return item.disabled == false;})
        return filterdGroups;
      },

      async deleteProducts() {
        await new Promise((resolve)=>{
          let counter = 0; 
          this.selected.forEach(element => {
            db.collection("products")
            .doc(element.id)
            .set({
              deleted: {
                deleted: true,
                deletedOn: moment().format("YYYY-MM-DDTHH:mm"),
                deletedBy: this.$store.state.activeUserId
              }
            },{merge: true})
            counter++
            if(counter == this.selected.length){
              resolve()
            }
          });
        })
        this.selected = new Array();
        this.loadProducts();
        bus.$emit('deletedModal', null, 'close');
      },

    },

    computed:{
      productCategoriesFiltered(){ // Show only product categories if there are products with this category
        let productCategories = new Array();
        this.productCategories.forEach(type => {
          let productsOfType = new Array();
          this.products.forEach(item => {
            item.category.forEach(category => {
              if(category == type.id){
                productsOfType.push(type)
              }
            })
          })
          if(productsOfType && productsOfType.length > 0){
            productCategories.push(type)
          }
        })
        return productCategories.sort((a, b) => this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, a.name).localeCompare(this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, b.name)))
      },

      productBrandsFiltered(){ // Show only product categories if there are products with this category
        let productBrands = new Array();
        this.productSuppliers.forEach(type => {
          let productsOfBrand = new Array();
          this.products.forEach(item => {
            if(item.supplier == type.id){
              productsOfBrand.push(type)
            }
          })
          if(productsOfBrand && productsOfBrand.length > 0){
            productBrands.push(type)
          }
        });
        return productBrands.sort((a, b) => a.name.localeCompare(b.name));
      },

      selectedView(){
        return id => {
          if(this.productFilterActive == id){
            return true;
          }
          else {
            return false;
          }
        }
      }, 
    }
  };
</script>

<style>
  .product-group-wrapper .v-list-item--dense .v-list-item__content, 
  .product-group-wrapper .v-list--dense .v-list-item .v-list-item__content{
    padding: 0!important;
  }

  .product-group-wrapper .v-list-item--dense .v-list-item__title, 
  .product-group-wrapper .v-list--dense .v-list-item .v-list-item__title{
    font-size: 12px!important
  }

  .product-group-wrapper .v-list-item,
  .product-group-wrapper .v-list-item.v-list-item--dense{
    min-height: 32px!important;
  }
</style>
