<template>
  <div>
    <v-form ref="EditBranchForm" lazy-validation v-if="!branchDataLoading">
      <v-container grid-list-md class="ma-0 pa-0" fluid>
        <v-layout row wrap class="py-0">
          <!-- <v-flex md4 xs12>
             <h2>{{ $t("branch.branchInformation", $store.state.locale) }}</h2>
          </v-flex> -->
          <v-flex xs12 class="text-right py-0">
            <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small :dark="changed" :loading="updateBranchSettingsBusy" :disabled="!changed" :depressed="!changed" @click="updateBranch()">
                <div v-if="changed">{{ $t("general.saveChanged", $store.state.locale) }} <v-icon right small>mdi-chevron-right</v-icon></div>
                <div v-else>{{ $t("general.saved", $store.state.locale) }} <v-icon right small>mdi-check</v-icon></div>
              </v-btn>
          </v-flex>
        </v-layout>
      </v-container>

      <v-container grid-list-md class="pa-0 py-4 ma-0">
        <v-layout row wrap>
          <v-flex md6 xs12 class="pr-2">

            <!-- / Basic Branch Information \ -->
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
              <div class="pa-3">
                <v-avatar
                  size="36"
                  light
                  style="float: left"
                  class="ma-0 mr-2 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-map-marker</v-icon>
                </v-avatar>
                <h3 class="pt-1 pb-4">{{ $t('general.general', $store.state.locale) }}</h3>
                <v-divider></v-divider>

                <v-layout row wrap>
                  <v-flex xs12>
                    <v-card outlined class="ma-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" v-if="$store.state.userRoleNumber < 2 && !this.$store.state.demoMode">
                      <v-btn dark @click="copyTextToClipboard(activeBranchData.id)" class="ma-1 my-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="text-transform: none">
                        <strong>{{ activeBranchData.id }}</strong>
                      </v-btn>
                      <div style="display: inline-block" v-if="selectedBrancheIdCopy == false" class="pa-2 copy-tag-url" @click="copyTextToClipboard(activeBranchData.id)">
                        <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon> Kopieër DocId
                      </div>
                      <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied">DocId gekopieërd</div>
                    </v-card>

                    <v-text-field
                      :label="$t('branch.nameBranch', $store.state.locale)"
                      v-model="activeBranchData.name"
                      prepend-inner-icon="mdi-domain"
                      required
                      dense
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      outlined
                      hide-details
                      class="mt-4"
                    ></v-text-field>

                    <v-layout row wrap>
                      <v-flex xs8>
                        <v-text-field
                          :label="$t('general.address', $store.state.locale)"
                          v-model="activeBranchData.branchAddress.address"
                          prepend-inner-icon="mdi-map-marker"
                          required
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="background"
                          outlined
                          hide-details
                          class="mt-4"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs4>
                        <v-text-field
                          :label="$t('general.number', $store.state.locale)"
                          v-model="activeBranchData.branchAddress.nr"
                          required
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="background"
                          outlined
                          hide-details
                          class="mt-4"
                        ></v-text-field>
                      </v-flex>
                      <v-flex md6>
                        <v-text-field
                          :label="$t('general.zipcode', $store.state.locale)"
                          v-model="activeBranchData.branchAddress.postalCode"
                          prepend-inner-icon=""
                          required
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="background"
                          outlined
                          hide-details
                          class="mt-4"
                        ></v-text-field>
                      </v-flex>
                      <v-flex md6>
                        <v-text-field
                          :label="$t('general.city', $store.state.locale)"
                          v-model="activeBranchData.branchAddress.city"
                          required
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          outlined
                          dense
                          hide-details
                          background-color="background"
                          class="mt-4"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>

                    <v-text-field
                      :label="$t('general.province', $store.state.locale)"
                      v-model="activeBranchData.branchAddress.province"
                      prepend-inner-icon=""
                      required
                      dense
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      outlined
                      hide-details
                      class="mt-4"
                    ></v-text-field>
                    <v-text-field
                      :label="$t('general.country', $store.state.locale)"
                      v-model="activeBranchData.branchAddress.country"
                      required
                      dense
                      prepend-inner-icon="mdi-earth"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      outlined
                      hide-details
                      class="mt-4"
                    ></v-text-field>
                    <v-text-field
                      :label="$t('general.url', $store.state.locale)"
                      v-model="activeBranchData.url"
                      required
                      dense
                      prepend-inner-icon="mdi-web"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      outlined
                      hide-details
                      class="mt-4"
                    ></v-text-field>
                    <v-layout row wrap>
                      <v-flex md6 xs12>
                        <v-text-field
                          :label="$t('general.email', $store.state.locale)"
                          v-model="activeBranchData.email"
                          required
                          dense
                          prepend-inner-icon="mdi-mail"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="background"
                          outlined
                          hide-details
                          class="mt-4"
                        ></v-text-field>
                      </v-flex>
                      <v-flex md6 xs12>
                        <v-text-field
                          :label="$t('general.phone', $store.state.locale)"
                          v-model="activeBranchData.phone"
                          required
                          dense
                          prepend-inner-icon="mdi-phone"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="background"
                          outlined
                          hide-details
                          class="mt-4"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    
                    <div class="mt-2">
                      <h5 class="pb-1">Logo</h5>
                      <v-row align="center" justify="center" class="my-0 pa-0">
                        <v-col xs3 style="max-width: 100px!important;" :style="{ backgroundColor: activeBranchData.logoUrl ? $themes[$store.state.companyTheme][$store.state.themeModus].background : 'none' }" class=" pa-0 ml-3 ">
                          <v-icon  v-if="!activeBranchData.logoUrl" :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" size="86" class="pa-0 ma-0">mdi-image</v-icon>
                          <img v-else :src="activeBranchData.logoUrl" style="max-width: 100%; padding: 5px;" />
                        </v-col>
                        <v-col xs9>
                          <v-btn v-if="!activeBranchData.logoUrl" @click="openMediaLibrary()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small dark>Select from library</v-btn>
                          <div v-else>
                            <a :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}" @click="openMediaLibrary()">Replace</a>
                          </div>
                        </v-col>
                      </v-row>
                    </div>

                    <v-layout row wrap>
                      <v-flex xs12>
                        <v-text-field
                          :key="senderNameChanges"
                          :label="$t('branch.senderName', $store.state.locale)"
                          v-model="activeBranchData.senderName"
                          required
                          dense
                          prepend-inner-icon="mdi-cellphone-basic"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          background-color="background"
                          counter
                          persistent-hint
                          :hint="$t('branch.senderNameHint', $store.state.locale)"
                          maxlength="11"
                          outlined
                          class="mt-4"
                          @input="checkSenderName()"
                        ></v-text-field>
                      </v-flex>
                    </v-layout>

                    <v-switch :label="$t('general.active', $store.state.locale)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="activeBranchData.active" hide-details class="ma-0 pa-0 mt-4"></v-switch>
                  </v-flex>
                </v-layout>
              </div>
            </v-card>
            <!-- \ Basic Branch Information / -->

            <!-- /  Branch User \ -->
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 mt-4" :class="$store.state.companyTheme">
              <div class="pa-3">
                <v-avatar
                  size="36"
                  light
                  style="float: left"
                  class="ma-0 mr-2 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account-lock</v-icon>
                </v-avatar>
                <h3 class="pt-1 pb-4">{{ $t("userRules.userAccountBranch", $store.state.locale) }}</h3>
                <v-divider></v-divider>

                <v-alert type="info" prominent text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mt-2 mb-0">
                  <span :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].primary }" v-html="$t('userRules.userAccountBranchHint', $store.state.locale)"></span>
                </v-alert>

                <div v-if="!loadingUserAccount">
                  <div v-if="userAccountBranch">
                    <v-container grid-list-md class="pa-0 ma-0">
                      <v-layout row wrap>
                        <v-flex xs12>
                          <h4 class="mt-4">{{ $t("userRules.userAccount", $store.state.locale) }}</h4>
                          <v-text-field
                            class="mt-4"
                            label="E-mail"
                            v-model="userAccountBranch.email"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :background-color="$vuetify.theme.themes[theme].menu"
                            required
                            dense
                            outlined
                            disabled
                            readonly
                            hide-details
                          ></v-text-field>
                        </v-flex>
                        <v-flex xs12>
                          <v-select
                            :label="$t('general.language', $store.state.locale)"
                            outlined
                            dense
                            class="mt-1"
                            content-class="pa-0 ma-0 mr-4"
                            :items="availableUserlanguages"
                            v-model="userAccountBranch.language"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            item-value="languageCode"
                            item-text="code"
                            hide-details
                            >
                            <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                              <country-flag :country="data.item.flagCode" size="small"/>
                              <span>{{ data.item.languageName[$store.state.locale.toLowerCase()]}}</span>
                            </template>
                            <template slot="item" slot-scope="data">
                              <country-flag :country="data.item.flagCode" size="small"/>
                              <span>{{ data.item.languageName[$store.state.locale.toLowerCase()]}}</span>
                            </template>
                          </v-select>
                        </v-flex>
                        <v-flex xs12>
                          <v-btn v-if="userAccountBranch.active" dark class="mt-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" block depressed :outlined="userAccountBranch.active" @click="changeUserStatus()">{{
                            $t("userRules.deactivateUser", $store.state.locale)
                          }}</v-btn>
                          <v-btn v-else class="mt-3" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" block depressed :outlined="userAccountBranch.active" @click="changeUserStatus()">{{
                            $t("userRules.activateUser", $store.state.locale)
                          }}</v-btn>
                        </v-flex>
                        <v-flex xs12>
                          <v-card flat :color="$vuetify.theme.themes[theme].menu">
                            <v-card-text>
                              <h4 class="mb-4">{{ $t("userRules.changePassword", $store.state.locale) }}</h4>
                              <v-text-field
                                :label="$t('user.newPassword', $store.state.locale)"
                                v-model="userAccountBranch.password"
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                                :background-color="$vuetify.theme.themes[theme].background"
                                required
                                dense
                                outlined
                                :rules="[rules.required, rules.min]"
                                :type="userAccountBranch.showPassword ? 'text' : 'password'"
                                :append-icon="userAccountBranch.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="userAccountBranch.showPassword = !userAccountBranch.showPassword"
                                counter
                              ></v-text-field>

                              <v-text-field
                                v-if="userAccountBranch.password"
                                class="pt-2"
                                :label="$t('user.confirmPassword', $store.state.locale)"
                                v-model="userAccountBranch.passwordConfirm"
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                                :background-color="$vuetify.theme.themes[theme].background"
                                required
                                dense
                                outlined
                                :rules="[rules.required, rules.min]"
                                :type="userAccountBranch.showPassword ? 'text' : 'password'"
                                :append-icon="userAccountBranch.showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                @click:append="userAccountBranch.showPassword = !userAccountBranch.showPassword"
                                counter
                              ></v-text-field>
                              <v-alert class="ma-0 mt-2 pa-2" type="warning" color="warning" outlined v-if="passwordNotEqual">
                                <span :style="{ color: $vuetify.theme.themes[theme].text }">{{ $t("user.passwordsNotEqual", $store.state.locale) }}</span>
                              </v-alert>
                              <v-btn
                                v-if="userAccountBranch.password"
                                class="mt-1"
                                depressed
                                color="secondary"
                                block
                                :dark="newPassword"
                                :loading="updatePasswordBusy"
                                :disabled="!newPassword"
                                @click="changePassword()"
                                >{{ $t("user.changePassword", $store.state.locale) }}</v-btn
                              >
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-container>

                    <div class="mt-4">
                      <h3>
                        <strong>{{ $t("userRules.accessRules", $store.state.locale) }}</strong>
                      </h3>
                      <v-switch
                        :label="$t('userRules.useGeneralAccessRules', $store.state.locale)"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        v-model="userAccountBranch.userRules.companyRules"
                        hide-details
                        v-on:change="resetUserRules()"
                        class="ma-0 pa-0 mt-4"
                      ></v-switch>
                    </div>

                    <div v-if="userAccountBranch.userRules.companyRules">
                      <v-alert type="info" prominent text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mt-2 mb-2">
                        <span :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].primary }" v-html="$t('userRules.useGeneralAccessRulesHint', $store.state.locale)"></span>
                      </v-alert>
                    </div>

                    <v-container v-else grid-list-md class="pa-0 ma-0">
                      <div class="text-center">
                        <h4 class="mt-4">
                          <strong>{{ $t("userRules.branchUserRules", $store.state.locale) }}</strong>
                        </h4>
                        <p class="ma-0 mb-2">{{ $t("userRules.sortUserRules", $store.state.locale) }}</p>
                      </div>
                      <v-layout row wrap>
                        <v-flex xs4 d-flex>
                          <v-card flat :color="$vuetify.theme.themes[theme].menu" style="width: 100%">
                            <v-card-text class="pa-2 text-center">
                              <h4 class="mb-4" style="text-transform: uppercase; font-weight: bold">{{ $t("userRules.never", $store.state.locale) }}</h4>
                              <draggable v-model="userAccountBranch.userRules.never" v-bind="{ group: 'rules' }" style="min-height: 10px">
                                <v-chip v-for="(rule, index) in userAccountBranch.userRules.never" :key="index" color="error" text-color="white" draggable label class="mb-1" small
                                  ><v-icon color="white" left>mdi-drag</v-icon
                                  ><span style="color: #fff"
                                    ><strong>{{ userRulesNames[rule] }}</strong></span
                                  ></v-chip
                                >
                              </draggable>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                        <v-flex xs4 d-flex>
                          <v-card flat :color="$vuetify.theme.themes[theme].menu" style="width: 100%">
                            <v-card-text class="pa-2 text-center">
                              <h4 class="mb-4" style="text-transform: uppercase; font-weight: bold">{{ $t("userRules.restricted", $store.state.locale) }}</h4>
                              <draggable v-model="userAccountBranch.userRules.restricted" v-bind="{ group: 'rules' }" style="min-height: 10px">
                                <v-chip v-for="(rule, index) in userAccountBranch.userRules.restricted" :key="index" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" draggable text-color="white" label class="mb-1" small
                                  ><v-icon color="white" left>mdi-drag</v-icon
                                  ><span style="color: #fff"
                                    ><strong>{{ userRulesNames[rule] }}</strong></span
                                  ></v-chip
                                >
                              </draggable>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                        <v-flex xs4 d-flex>
                          <v-card flat :color="$vuetify.theme.themes[theme].menu" style="width: 100%">
                            <v-card-text class="pa-2 text-center">
                              <h4 class="mb-4" style="text-transform: uppercase; font-weight: bold">{{ $t("userRules.free", $store.state.locale) }}</h4>
                              <draggable v-model="userAccountBranch.userRules.free" v-bind="{ group: 'rules' }" style="min-height: 10px">
                                <v-chip v-for="(rule, index) in userAccountBranch.userRules.free" :key="index" color="success" draggable label class="mb-1" text-color="white" small
                                  ><v-icon color="white" left>mdi-drag</v-icon
                                  ><span style="color: #fff"
                                    ><strong>{{ userRulesNames[rule] }}</strong></span
                                  ></v-chip
                                >
                              </draggable>
                            </v-card-text>
                          </v-card>
                        </v-flex>
                      </v-layout>
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-select
                            :items="logoutTimes"
                            v-model="userAccountBranch.userRules.logoutTime"
                            :label="$t('userRules.autoLogoutTime', $store.state.locale)"
                            item-text="name"
                            item-value="id"
                            prepend-inner-icon="mdi-clock-outline"
                            required
                            outlined
                            class="mt-4"
                            persistent-hint
                            :hint="$t('userRules.autoLogoutTimeHint', $store.state.locale)"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            
                          ></v-select>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-container grid-list-md class="pa-0 ma-0">
                      <v-layout row wrap>
                        <v-flex xs12>
                          <v-btn
                            class="mt-1"
                            depressed
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            block
                            :dark="userAccountBranchChanged"
                            :loading="updateUserAccountBranchBusy"
                            :disabled="!userAccountBranchChanged"
                            @click="updateUserAccountBranch()"
                          >
                            <div v-if="userAccountBranchChanged">{{ $t("general.saveChanged", $store.state.locale) }} <v-icon righy>mdi-chevron-right</v-icon></div>
                            <div v-else>{{ $t("general.saved", $store.state.locale) }} <v-icon right>mdi-check</v-icon></div>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </div>

                  <div v-else>
                    <v-form ref="AddBranchUserForm" v-model="branchUserFormValid" lazy-validation>
                      <v-container grid-list-md class="pa-0 ma-0">
                        <v-layout row wrap>
                          <v-flex xs12>
                            <h4 class="mt-4">{{ $t("userRules.createUserAccountBranch", $store.state.locale) }}</h4>
                            <v-alert v-if="branchUserFeedback" class="ma-0 mt-2 pa-2" style="border-radius: 0" type="warning">{{ branchUserFeedback }}</v-alert>
                          </v-flex>
                          <v-flex md6 xs12>
                            <v-text-field
                              :label="$t('general.emailaddress', $store.state.locale)"
                              v-model="newUserAccountBranch.email"
                              :autocomplete="false"
                              required
                              dense
                              :rules="[rules.required, rules.email]"
                              prepend-inner-icon="mdi-email"
                              outlined
                              class="mt-1"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                              
                            ></v-text-field>
                          </v-flex>
                          <v-flex md6 xs12>
                            <v-text-field
                              :label="$t('general.password', $store.state.locale)"
                              v-model="newUserAccountBranch.password"
                              prepend-inner-icon="mdi-lock"
                              persistent-hint
                              :hint="$t('userRules.passwordHint', $store.state.locale)"
                              counter
                              :rules="[rules.required, rules.min]"
                              required
                              dense
                              outlined
                              class="mt-1"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                              
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12>
                            <v-select
                              :label="$t('general.language', $store.state.locale)"
                              outlined
                              dense
                              class="mt-1"
                              content-class="pa-0 ma-0 mr-4"
                              :items="availableUserlanguages"
                              v-model="newUserAccountBranch.language"
                              item-value="languageCode"
                              item-text="code"
                              hide-details
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            >
                              <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                                <country-flag :country="data.item.flagCode" size="small"/>
                                <span>{{ data.item.languageName[$store.state.locale.toLowerCase()]}}</span>
                              </template>
                              <template slot="item" slot-scope="data">
                                <country-flag :country="data.item.flagCode" size="small"/>
                                <span>{{ data.item.languageName[$store.state.locale.toLowerCase()]}}</span>
                              </template>
                            </v-select>
                          </v-flex>
                          <v-flex xs12>
                            <v-btn class="mt-1" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" block depressed :loading="newUserAccountBranch.loading" @click="createUserAccountBranch()"
                              ><strong>{{ $t("userRules.createAccount", $store.state.locale) }}</strong></v-btn
                            >
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-form>
                  </div>
                </div>
                <div v-else style="position: relative; height: 150px">
                  <div class="thrive-loader" :class="$store.state.companyTheme"></div>
                </div>
              </div>
            </v-card>
            <!-- \  Branch User / -->
          </v-flex>

          <v-flex md6 xs12 class="pl-2">
            <!-- /  Branch Times \ -->
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
              <div class="pa-3">
                <v-avatar
                  size="36"
                  light
                  style="float: left"
                  class="ma-0 mr-2 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-clock-time-five-outline</v-icon>
                </v-avatar>
                <h3 class="pt-1 pb-4">{{ $t("branch.branchTimes", $store.state.locale) }}</h3>
                <v-divider></v-divider>

                <v-container>
                  <v-layout row wrap class="table-header">
                    <v-flex xs3><strong>{{ $t("general.day", $store.state.locale) }}</strong></v-flex>
                    <v-flex xs3></v-flex>
                    <v-flex xs3><strong>{{ $t("general.start", $store.state.locale) }}</strong></v-flex>
                    <v-flex xs3><strong>{{ $t("general.end", $store.state.locale) }}</strong></v-flex>
                  </v-layout>
                  <v-layout row wrap class="pa-0 my-0">
                    <v-flex xs3 class="pt-3">{{ $t("general.sunday", $store.state.locale) }}</v-flex>
                    <v-flex xs3 class="branch-day-active pt-3">
                      <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 pa-0" v-model="activeBranchData.defaultDays.Sun" hide-details></v-switch>
                    </v-flex>
                    <v-flex xs3 class="branch-start-hours" v-if="activeBranchData.defaultDays.Sun == true">
                      <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="activeBranchData.branchTime.Sun.start" ></v-text-field>
                    </v-flex>
                    <v-flex xs3 v-if="activeBranchData.defaultDays.Sun == true">
                      <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="activeBranchData.branchTime.Sun.end" ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap class="pa-0 my-0">
                    <v-flex xs3 class="pt-3">{{ $t("general.monday", $store.state.locale) }}</v-flex>
                    <v-flex xs3 class="branch-day-active pt-3">
                      <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="activeBranchData.defaultDays.Mon" hide-details class="ma-0 pa-0"></v-switch>
                    </v-flex>
                    <v-flex xs3 class="branch-start-hours" v-if="activeBranchData.defaultDays.Mon == true">
                      <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="activeBranchData.branchTime.Mon.start" ></v-text-field>
                    </v-flex>
                    <v-flex xs3 v-if="activeBranchData.defaultDays.Mon == true">
                      <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="activeBranchData.branchTime.Mon.end" ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap class="pa-0 my-0">
                    <v-flex xs3 class="pt-3">{{ $t("general.tuesday", $store.state.locale) }}</v-flex>
                    <v-flex xs3 class="branch-day-active pt-3">
                      <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="activeBranchData.defaultDays.Tue" class="ma-0 pa-0" hide-details></v-switch>
                    </v-flex>
                    <v-flex xs3 class="branch-start-hours" v-if="activeBranchData.defaultDays.Tue == true">
                      <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="activeBranchData.branchTime.Tue.start" ></v-text-field>
                    </v-flex>
                    <v-flex xs3 v-if="activeBranchData.defaultDays.Tue == true">
                      <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="activeBranchData.branchTime.Tue.end" ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap class="pa-0 my-0">
                    <v-flex xs3 class="pt-3">{{ $t("general.wednesday", $store.state.locale) }}</v-flex>
                    <v-flex xs3 class="branch-day-active pt-3">
                      <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="activeBranchData.defaultDays.Wed" class="ma-0 pa-0" hide-details></v-switch>
                    </v-flex>
                    <v-flex xs3 class="branch-start-hours" v-if="activeBranchData.defaultDays.Wed == true">
                      <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" type="time" outlined dense class="ma-0 pa-0" hide-details v-model="activeBranchData.branchTime.Wed.start" ></v-text-field>
                    </v-flex>
                    <v-flex xs3 v-if="activeBranchData.defaultDays.Wed == true">
                      <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" type="time" outlined dense class="ma-0 pa-0" hide-details v-model="activeBranchData.branchTime.Wed.end" ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap class="pa-0 my-0">
                    <v-flex xs3 class="pt-3">{{ $t("general.thursday", $store.state.locale) }}</v-flex>
                    <v-flex xs3 class="branch-day-active pt-3">
                      <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="activeBranchData.defaultDays.Thu" class="ma-0 pa-0" hide-details></v-switch>
                    </v-flex>
                    <v-flex xs3 class="branch-start-hours" v-if="activeBranchData.defaultDays.Thu == true">
                      <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" type="time" outlined dense class="ma-0 pa-0" hide-details v-model="activeBranchData.branchTime.Thu.start" ></v-text-field>
                    </v-flex>
                    <v-flex xs3 v-if="activeBranchData.defaultDays.Thu == true">
                      <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" type="time" outlined dense hide-details v-model="activeBranchData.branchTime.Thu.end" ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap class="pa-0 my-0">
                    <v-flex xs3 class="pt-2">{{ $t("general.friday", $store.state.locale) }}</v-flex>
                    <v-flex xs3 class="branch-day-active pt-3">
                      <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="activeBranchData.defaultDays.Fri" class="ma-0 pa-0" hide-details></v-switch>
                    </v-flex>
                    <v-flex xs3 class="branch-start-hours" v-if="activeBranchData.defaultDays.Fri == true">
                      <v-text-field  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" type="time" outlined dense class="ma-0 pa-0" hide-details v-model="activeBranchData.branchTime.Fri.start" ></v-text-field>
                    </v-flex>
                    <v-flex xs3 v-if="activeBranchData.defaultDays.Fri == true">
                      <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" type="time" outlined dense class="ma-0 pa-0" hide-details v-model="activeBranchData.branchTime.Fri.end" ></v-text-field>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap class="pa-0 my-0">
                    <v-flex xs3 class="pt-3">{{ $t("general.saturday", $store.state.locale) }}</v-flex>
                    <v-flex xs3 class="branch-day-active pt-3">
                      <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="activeBranchData.defaultDays.Sat" class="ma-0 pa-0" hide-details></v-switch>
                    </v-flex>
                    <v-flex xs3 class="branch-start-hours" v-if="activeBranchData.defaultDays.Sat == true">
                      <v-text-field  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" type="time" outlined dense v-model="activeBranchData.branchTime.Sat.start" class="ma-0 pa-0" hide-details></v-text-field>
                    </v-flex>
                    <v-flex xs3 v-if="activeBranchData.defaultDays.Sat == true">
                      <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" type="time" outlined dense hide-details class="ma-0 pa-0" v-model="activeBranchData.branchTime.Sat.end" ></v-text-field>
                    </v-flex>
                  </v-layout>
                </v-container>
              </div>
            </v-card>
            <!-- \  Branch Times / -->

            <!-- /  Branch Times Exceptions \ -->
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 mt-4" :class="$store.state.companyTheme">
              <div class="pa-3">
                <v-card-title class="ma-0 pa-0 pb-2">
                  <v-avatar
                    size="36"
                    light
                    style="float: left"
                    class="ma-0 mr-2 custom-avatar-style" 
                    rounded="lg"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                    >
                    <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-clock-edit-outline</v-icon>
                  </v-avatar>
                  <h3>{{ $t("branch.openingHoursExeptions", $store.state.locale)  }}</h3>
                  <v-spacer></v-spacer>
                  <v-btn fab small icon @click.stop="newExceptionDialog = true">
                    <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-plus-circle</v-icon>
                  </v-btn>
                </v-card-title>
                <v-divider></v-divider>

                <div class="pa-0 pt-4">
                  <v-container class="ma-0 pa-0">
                    <v-layout row wrap class="ma-0 pa-0">
                      <v-flex xs6 class="ma-0 pa-0">
                        <v-btn class="ma-0 pa-0" dark  style="border-radius: 0" block :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="exeptionDisplay = 'past'" :outlined="exceptionFuture" depressed>{{
                          $t("branch.past", $store.state.locale)
                        }}</v-btn>
                      </v-flex>
                      <v-flex xs6 class="ma-0 pa-0">
                        <v-btn class="ma-0 pa-0" dark style="border-radius: 0" block :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="exeptionDisplay = 'future'" :outlined="!exceptionFuture" depressed>{{
                          $t("branch.future", $store.state.locale)
                        }}</v-btn>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </div>
              </div>

              <v-data-table
                :headers="headersExceptions"
                :items="branchExceptionsFiltered"
                item-key="name"
                hide-default-footer
                :style="{ backgroundColor: $vuetify.theme.themes[theme].background }"
                :no-data-text="noExeptionData"
              >
                <template v-slot:item="{ item, index }" v-if="exeptionDisplay == 'future'">
                  <tr v-if="item.end > now">
                    <td>
                      {{ item.start | moment("D MMMM YYYY") }} <span v-if="item.start != item.end">- {{ item.end | moment("D MMMM YYYY") }}</span>
                    </td>
                    <td>{{ item.extra }}</td>
                    <td>
                      <v-btn icon @click="removeException(item, index)">
                        <v-icon color="grey">mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>

                <template v-slot:item="{ item, index }" v-else>
                  <tr v-if="item.end <= now">
                    <td>{{ item.start | moment("D MMM YYYY") }} - {{ item.end | moment("D MMM YYYY") }}</td>
                    <td>{{ item.extra }}</td>
                    <td>
                      <v-btn icon @click="removeException(item, index)">
                        <v-icon color="text">mdi-delete</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
            <!-- \  Branch Times Exceptions/ -->

            <!-- /  Branch Loyalty \ -->
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 mt-4" :class="$store.state.companyTheme">
              <div class="pa-3">
                <v-avatar
                  size="36"
                  light
                  style="float: left"
                  class="ma-0 mr-2 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-star-plus</v-icon>
                </v-avatar>
                <h3 class="pt-1 pb-4">{{ $t("loyalty.titleLoyalty", $store.state.locale) }}</h3>
                <v-divider></v-divider>

                <v-alert type="info" prominent text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mt-2 mb-0">
                  <span :style="{ color: $themes[$store.state.companyTheme][$store.state.themeModus].primary }" v-html="$t('loyalty.selectInfo', $store.state.locale)"></span>
                </v-alert>
              
                <v-container class="ma-0 pa-0">
                  <v-layout row wrap class="ma-0 pa-0">
                    <v-flex xs6 class="ma-0 pa-0">
                      <v-switch
                        v-model="activeBranchData.loyalty.active"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :label="$t('loyalty.activate', $store.state.locale)"
                      ></v-switch>
                    </v-flex>

                    <div v-if="activeBranchData.loyalty.active">

                      {{ $t('loyalty.whereToRedeem', $store.state.locale) }}

                      <v-flex xs12 class="ma-0 pa-0">
                        <v-select
                        v-model="activeBranchData.loyalty.redeemBranches"
                        :items="$store.state.activeUserBranches"
                        item-text="name"
                        item-value="id"
                        outlined
                        dense
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        small-chips
                        multiple
                        chips
                        :hint="$t('loyalty.redeemToBranch', $store.state.locale)"
                        persistent-hint
                      ></v-select>
                    
                    </v-flex>
                    </div>
                  </v-layout>
                </v-container>
              </div>
            </v-card>
            <!-- \  Branch Loyalty / -->

            <!-- /  Branch Social \ -->
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 mt-4" :class="$store.state.companyTheme">
              <div class="pa-3">
                <v-avatar
                  size="36"
                  light
                  style="float: left"
                  class="ma-0 mr-2 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account-multiple</v-icon>
                </v-avatar>
                <h3 class="pt-1 pb-4">{{ $t("branch.social", $store.state.locale) }}</h3>

                <v-divider></v-divider>

                <div class="pt-3">
                  <v-card flat class="my-2 pa-3" style="width: 100%;" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" >
                    <h4><v-icon size="20">mdi-facebook</v-icon> Facebook</h4>
              
                    <v-switch
                      class="pa-0 ma-0 mt-2"
                      hide-details
                      :label="$t('general.active', $store.state.locale)"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      v-model="activeBranchData.social.facebook"
                    ></v-switch>

                    <v-text-field
                      v-if="activeBranchData.social.facebook == true && activeBranchData"
                      :label="`${ $t('social.linkTo', $store.state.locale)} Facebookpagina`"
                      :hint="`${ $t('social.linkHint', $store.state.locale)} https://www.facebook.com/account/`"
                      outlined
                      dense
                      background-color="menu"
                      class="mt-2"
                      v-model="activeBranchData.social.facebookUrl"
                      persistent-hint
                    ></v-text-field>
                </v-card>

                <v-card flat class="my-2 pa-3" style="width: 100%;" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" >
                  <h4><v-icon size="20">mdi-instagram</v-icon> Instagram</h4>

                  <v-switch
                    :label="$t('general.active', $store.state.locale)"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-model="activeBranchData.social.instagram"
                    class="pa-0 ma-0 mt-2"
                    hide-details
                  ></v-switch>

                  <v-text-field
                    v-if="activeBranchData.social.instagram == true"
                    :label="`${ $t('social.linkTo', $store.state.locale)} Instagrampagina`"
                    :hint="`${ $t('social.linkHint', $store.state.locale)} https://www.instagram.com/account/`"
                    outlined
                    dense
                    background-color="menu"
                    class="mt-2"
                    v-model="activeBranchData.social.instagramUrl"
                    persistent-hint
                  ></v-text-field>
                </v-card>

                <v-card flat class="my-2 pa-3" style="width: 100%;" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" >
                  <h4><v-icon size="20">mdi-twitter</v-icon> Twitter</h4>
                  <v-switch
                    :label="$t('general.active', $store.state.locale)"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-model="activeBranchData.social.twitter"
                    class="pa-0 ma-0 mt-2"
                    hide-details
                  ></v-switch>

                  <v-text-field
                    v-if="activeBranchData.social.twitter == true"
                    :label="`${ $t('social.linkTo', $store.state.locale)} Twitterpagina`"
                    :hint="`${ $t('social.linkHint', $store.state.locale)} https://www.twitter.com/account/`"
                    v-model="activeBranchData.social.twitterUrl"
                    persistent-hint
                    dense
                    outlined
                    background-color="menu"
                    class="mt-2"
                  ></v-text-field>
                </v-card>

                <v-card flat class="my-2 pa-3" style="width: 100%;" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" >
                  <h4><v-icon size="20">mdi-pinterest</v-icon> Pinterest</h4>

                  <v-switch
                    :label="$t('general.active', $store.state.locale)"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-model="activeBranchData.social.pinterest"
                    class="pa-0 ma-0 mt-2"
                    hide-details
                  ></v-switch>
            
                  <v-text-field
                    v-if="activeBranchData.social.pinterest == true"
                    :label="`${ $t('social.linkTo', $store.state.locale)} Pinterestpagina`"
                    :hint="`${ $t('social.linkHint', $store.state.locale)} https://www.pinterest.com/account/`"
                    v-model="activeBranchData.social.pinterestUrl"
                    persistent-hint
                    outlined
                    dense
                    background-color="menu"
                    class="mt-2"
                  ></v-text-field>
                </v-card>
                </div>


              </div>
            </v-card>
            <!-- \  Branch Social / -->

          </v-flex>
        </v-layout>
      </v-container>
    </v-form>

    <!-- / New Exception Dialog \ -->
    <v-dialog v-model="newExceptionDialog" max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <template>
        <v-card>
          <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
            <h2>{{ $t("branch.addNewException", $store.state.locale) }}</h2>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="newExceptionDialog = false">
              <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-alert v-if="feedbackException" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedbackException }}</v-alert>

          <v-divider></v-divider>

          <v-card-text>
            <v-form ref="AddRoomForm" lazy-validation>
              <v-container grid-list-md class="pa-0 ma-0">
                <v-layout row wrap>
                  <v-flex xs6>
                    <v-menu :close-on-content-click="false" v-model="exceptionStart" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          dense
                          v-model="newException.start"
                          :label="$t('general.startDate', $store.state.locale)"
                          background-color="background"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          prepend-inner-icon="mdi-calendar-range"
                          outlined
                          readonly
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="newException.start" no-title :min="now" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @input="exceptionStart = false"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs6>
                    <v-menu :close-on-content-click="false" v-model="exceptionEnd" :nudge-right="40" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          v-model="newException.end"
                          :label="$t('general.endDate', $store.state.locale)"
                          prepend-inner-icon="mdi-calendar-range"
                          readonly
                          dense
                          background-color="background"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          outlined
                          :disabled="!newException.start"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="newException.end" no-title :min="newException.start" @input="exceptionEnd = false"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field dense v-model="newException.extra" outlined background-color="background" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" hide-details :label="$t('branch.comments', $store.state.locale)"></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"  @click="newExceptionDialog = false">
                    {{ $t("general.cancel", $store.state.locale) }}
                  </v-btn>
                </v-flex>
                <v-flex xs12 md8 class="text-right">
                  <v-btn
                    :disabled="!filled"
                    :depressed="!filled"
                    :dark="filled"
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    @click="addException()"
                  >
                    {{ $t("branch.addException", $store.state.locale) }}
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <!-- \ New Exception Dialog / -->

    <!-- / Image Library \ -->
    <v-dialog
      v-model="libraryDialog"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
      content-class="custom-shape"
      >
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2>Logo's Upload</h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeLibraryDialog()">
            <v-icon dark :color=" $themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-0 ma-0" style="position: relative">
          <media-library
          @mediaFileSelect="setChosenImage" 
          :assetGroup="'companyAsset'" 
          :assetType="'logo'" 
          :fileRules="{accept: ['image/jpg', 'image/jpeg', 'image/png'], fileSize: 2000000}" ></media-library>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- \ Image Library / -->


  </div>
</template>

<script>
  import firebase from "firebase/app";
  import "firebase/auth";
  import "@firebase/firestore";
  import axios from "axios";
  import db from "../firebase/init";
  import { bus } from "../main";
  import draggable from "vuedraggable";
  import slugify from "slugify";
  import MediaLibrary from "../components/elements/MediaLibrary";

  export default {
    props: {
      now: String,
    },
    components:{
      "media-library" : MediaLibrary,
      draggable,
    },

    name: "branch-info",
    data() {
      return {
        activeBranchData: new Object(),
        activeBranchDataOriginal: new Object(),
        branchDataLoading: true,
        libraryDialog: false,
        loadingUserAccount: true,
        updateBranchSettingsBusy: false,
        newException: {},
        userAccountBranch: null,
        userAccountBranchOriginal: null,
        senderNameChanges: 0,
        newUserAccountBranch: {
          email: "",
          password: "",
          language: "NL",
          userRole: "branch",
          accessBranches: new Array(),
          accessCompanies: new Array(),
          loading: false,
          userRules: {
            companyRules: true,
            never: new Array(),
            restricted: new Array(),
            free: new Array(),
            logoutTime: null,
          },
        },
        snackbar: {
          color: "",
          timeout: 2000,
          icon: null,
          message: null,
        },
        loyalty: {
          active: false,
          loyaltyRedeemBranches: new Array(),
        },
        selectedBrancheIdCopy: false,
        updateUserAccountBranchBusy: false,
        userRulesNames: {
          viewReports: this.$t("userRules.viewReports", this.$store.state.locale),
          updateInventory: this.$t("userRules.updateInventory", this.$store.state.locale),
          viewClient: this.$t("userRules.viewClient", this.$store.state.locale),
          editClient: this.$t("userRules.editClient", this.$store.state.locale),
          addTasks: this.$t("userRules.addTasks", this.$store.state.locale),
          completeTasks: this.$t("userRules.completeTasks", this.$store.state.locale),
          payments: this.$t("userRules.payments", this.$store.state.locale),
          changeAppointments: this.$t("userRules.changeAppointments", this.$store.state.locale),
          addAppointments: this.$t("userRules.addAppointments", this.$store.state.locale),
        },

        userRules: {
          never: ["viewReports", "updateInventory"],
          restricted: ["viewClient", "editClient", "addTasks", "completeTasks"],
          free: ["payments", "changeAppointments", "addAppointments"],
          logoutTime: 120,
        },
        logoutTimes: [
          {
            name: this.$t("userRules.thirtySeconds", this.$store.state.locale),
            id: 30,
          },
          {
            name: this.$t("userRules.oneMinute", this.$store.state.locale),
            id: 60,
          },
          {
            name: this.$t("userRules.twoMinutes", this.$store.state.locale),
            id: 120,
          },
          {
            name: this.$t("userRules.fiveMinutes", this.$store.state.locale),
            id: 300,
          },
        ],
        updatePasswordBusy: false,
        branchUserFeedback: null,
        branchUserFormValid: false,
        exeptionDisplay: "future",
        exceptionEnd: false,
        exceptionStart: false,
        newExceptionDialog: false,
        feedbackException: null,      
        headersExceptions: [
          {
            text: this.$t("branch.period", this.$store.state.locale),
            align: "left",
            value: "start",
          },
          {
            text: this.$t("branch.comments", this.$store.state.locale),
            align: "left",
            value: "extra",
            sortable: false,
          },
          {
            text: "",
            align: "left",
            value: "name",
            sortable: false,
            width: "40px",
          },
        ],
        rules: {
          email: (value) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          },
          required: (value) => !!value || this.$t("general.required", this.$store.state.locale),
          min: (v) => v.length >= 6 || this.$t("user.sixCharactersPassword", this.$store.state.locale),
        },
      };
    },

    created() {
      this.loadBranchData();
      bus.$on("loadBranchData", () => {
        this.loadBranchData();
      });
      
    },

    methods: {

      async loadBranchData(){
        this.branchDataLoading = true;
        this.activeBranchData = _.cloneDeep(this.$store.state.activeBranch);
        await db.collection("branches")
        .doc(this.$store.state.activeBranch.id)
        .collection("businessHours")
        .doc("default")
        .get()
        .then(doc => {
          if(doc.exists) {
            this.activeBranchData.branchTime = doc.data().branchTime
          }
          else{
            this.activeBranchData.branchTime = {
              Sun: {start: null, end: null},
              Mon: {start: null, end: null},
              Tue: {start: null, end: null},
              Wed: {start: null, end: null},
              Thu: {start: null, end: null},
              Fri: {start: null, end: null},
              Sat: {start: null, end: null}
            }
          }
        })
        .catch((err) => {
          console.error("Error getting Branch Time =>", err);
        });
        this.checkForUserAccountBranch();

        this.activeBranchDataOriginal = _.cloneDeep(this.activeBranchData);
        this.branchDataLoading = false;
      },  



      changeUserStatus() {
        firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((idToken) => {
          if(!this.userAccountBranch.active) {
            // API Call to enable user account
            axios.post("https://europe-west1-thrive-365.cloudfunctions.net/auth", {
              token: idToken,
              type: "enableUser",
              uid: this.userAccountBranch.user_id,
              userId: this.userAccountBranch.id,
            })
            .then(
              (response) => {
                this.userAccountBranch.active = true;
              },
              (error) => {
                console.error("Error enabling User Account", error);
              }
            );
          }
          else {
            // API Call to disable user account
            axios.post("https://europe-west1-thrive-365.cloudfunctions.net/auth", {
              token: idToken,
              type: "disableUser",
              uid: this.userAccountBranch.user_id,
              userId: this.userAccountBranch.id,
            })
            .then(
              (response) => {
                this.userAccountBranch.active = false;
              },
              (error) => {
                console.error("Error disabling User Account", error);
              }
            );
          }
        })
        .catch((error) => {
          console.error("Error getting ID Token", error);
        });
      },

      openMediaLibrary(){
        this.libraryDialog = true;
        bus.$emit("openMediaLibrary");
      },

      setChosenImage(data){
        this.activeBranchData.logoUrl = data.url;
        this.closeLibraryDialog();
      },

      copyTextToClipboard(text) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }

        navigator.clipboard.writeText(text).then(
          () => {
            this.selectedBrancheIdCopy = true;
            setTimeout(() => {
              this.selectedBrancheIdCopy = false;
            }, 1000);
          },
          function (err) {
            console.error("Async: Could not copy text: ", err);
          }
        );
      },

      changeImageSrc(){
        this.selectedItem.imageName = null;
        this.selectedItem.size = null;
        if(this.selectedItem.attributes.src == '[companyLogoUrl]'){
          this.selectedItem.imageName = "Company logo";
        }     
      },

      closeLibraryDialog(){
        this.libraryDialog = false;
        bus.$emit("clearMediaLibrarySelection");
      },

      resetUserRules() {
        if (this.userAccountBranch.userRules.companyRules) {
          this.userAccountBranch.userRules.never = new Array();
          this.userAccountBranch.userRules.restricted = new Array();
          this.userAccountBranch.userRules.free = new Array();
          this.userAccountBranch.userRules.logoutTime = null;
          this.updateUserAccountBranch();
        } else {
          if (this.$store.state.activeCompany.userBranchRules) {
            this.userAccountBranch.userRules.never = this.$store.state.activeCompany.userBranchRules.never;
            this.userAccountBranch.userRules.restricted = this.$store.state.activeCompany.userBranchRules.restricted;
            this.userAccountBranch.userRules.free = this.$store.state.activeCompany.userBranchRules.free;
            this.userAccountBranch.userRules.logoutTime = this.$store.state.activeCompany.userBranchRules.logoutTime;
          } else {
            this.userAccountBranch.userRules = _.cloneDeep(this.userRules);
          }
        }
      },

      async updateUserAccountBranch() {
        this.updateUserAccountBranchBusy = true;

        let logData = {
          superAdminAction: false, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "branchAccountRightsEditted",
          actionItem: this.activeBranchData.name,
          extra:[
            {
              name: "originalData",
              value: ""
            },
            {
              name: "rules",
              value: JSON.stringify(this.userAccountBranchOriginal.userRules)
            },
            {
              name: "changedData",
              value: ""
            },
            {
              name: "rules",
              value: JSON.stringify(this.activeBranchData.userRules)
            },
          ]
        };

        try {
          await db.collection("users")
          .doc(this.userAccountBranch.id)
          .set({
            userRules: this.userAccountBranch.userRules,
            language: this.userAccountBranch.language,
          },{ merge: true })

          await this.$createNewLog("info", logData);
          this.userAccountBranchOriginal = _.cloneDeep(this.userAccountBranch);
          this.updateUserAccountBranchBusy = false;
        }
        catch (error) {
          this.updateUserAccountBranchBusy = false;
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      },

      checkSenderName(){
        let data = _.cloneDeep(this.activeBranchData.senderName);
        let name = slugify(data, {
          replacement: "",
          remove: /[!@#$%^&*()"';:.,]/g,
          strict: true,
          lower: false,
        });
        this.activeBranchData.senderName = _.cloneDeep(name)
      },

      changePassword() {
        this.updatePasswordBusy = true;
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((idToken) => {
            // API Call to change user password
            axios
              .post("https://europe-west1-thrive-365.cloudfunctions.net/auth", {
                token: idToken,
                type: "changePassword",
                uid: this.userAccountBranch.user_id,
                password: this.userAccountBranch.password,
              })
              .then((response) => {
                if (response.status == 200) {
                  // Password succesfully changed
                  this.snackbar.active = true;
                  this.snackbar.color = "rgba(1, 230, 110, 0.7)";
                  this.snackbar.icon = "mdi-thumb-up";
                  this.snackbar.message = this.$t("user.passwordSuccessfulChanged", this.$store.state.locale);
                  this.userAccountBranch.password = "";
                  this.userAccountBranch.passwordConfirm = "";
                } else {
                  this.snackbar.active = true;
                  this.snackbar.color = "rgba(213, 0, 0, 0.7)";
                  this.snackbar.icon = "mdi-thumb-down";
                  this.snackbar.message = this.$t("user.passwordNotSuccessfulChanged", this.$store.state.locale);
                }

                this.updatePasswordBusy = false;
              })
              .catch((error) => {
                console.error("Password updated niet gelukt: ", error);
                this.updatePasswordBusy = false;
              });
          });
      },

      checkForUserAccountBranch() {
        this.loadingUserAccount = true;
        db.collection("users")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("userRole", "==", "branch")
          .where("accessBranches", "array-contains", this.activeBranchData.id)
          .get()
          .then((snapshot) => {
            if (snapshot.empty) {
              this.userAccountBranch = null;
              this.newUserAccountBranch.accessBranches.push(this.activeBranchData.id);
              this.newUserAccountBranch.accessCompanies.push(this.$store.state.activeCompany.id);
            }
            else {
              let users = new Array();
              snapshot.forEach((doc) => {
                let userCollection = doc.data();
                userCollection.id = doc.id;
                userCollection.language = doc.data().language ? doc.data().language : "NL";
                userCollection.passwordConfirm = "";
                userCollection.password = "";
                userCollection.showPassword = false;
                users.push(userCollection);
              });
              this.userAccountBranch = users[0];
            }
          })
          .then(() => {
            this.userAccountBranchOriginal = _.cloneDeep(this.userAccountBranch);
            this.loadingUserAccount = false;
          });
      },

      async createUserAccountBranch() {
        this.branchUserFeedback = null;

        let logData = {
          superAdminAction: false, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "userAdded",
          actionItem: this.activeBranchData.name,
          extra:[
            {
              name: "name",
              value: this.activeBranchData.name
            },
            {
              name: "email",
              value: this.newUserAccountBranch.email
            },
            {
              name: "role",
              value: "branch"
            },
            {
              name: "company",
              value: this.$store.state.activeCompany.id
            },
            {
              name: "companyAccess",
              value: this.newUserAccountBranch.accessCompanies
            },
            {
              name: "branchAccess",
              value: this.newUserAccountBranch.accessBranches
            },
          ]
        };

        if (this.$refs.AddBranchUserForm.validate()) {
          let emailAlreadyUsed = true;
          await db.collection("users")
          .where("email", "==", this.newUserAccountBranch.email)
          .get()
          .then((snapshot) => {
            if (snapshot.empty) {
              emailAlreadyUsed = false;
            }
            else {
              this.branchUserFeedback = this.$t("user.userExists", this.$store.state.locale);
            }
          })

          if (!emailAlreadyUsed) {
            console.log("bestaat nog niet")
            this.newUserAccountBranch.loading = true;
            let idToken = await firebase.auth().currentUser.getIdToken(true);
            try {
              let response = await axios.post("https://europe-west1-thrive-365.cloudfunctions.net/auth", {
                token: idToken,
                type: "createUser",
                email: this.newUserAccountBranch.email,
                password: this.newUserAccountBranch.password,
                sendEmail: false,
                company: this.$store.state.activeCompany.id,
                language: this.newUserAccountBranch.language,
                status: true,
                customRules: false,
                name: this.$store.state.activeCompany.name + " " + this.activeBranchData.name,
              })

              await db.collection("users").add({
                firstName: this.$store.state.activeCompany.name,
                lastName: this.activeBranchData.name,
                userRole: "branch",
                user_id: response.data,
                language: this.newUserAccountBranch.language,
                employeeId: null,
                accessCompanies: this.newUserAccountBranch.accessCompanies,
                accessBranches: this.newUserAccountBranch.accessBranches,
                companyId: this.$store.state.activeCompany.id,
                email: this.newUserAccountBranch.email,
                userRules: this.newUserAccountBranch.userRules,
                active: true,
              })

              await this.$createNewLog("info", logData);
              this.checkForUserAccountBranch();
              this.newUserAccountBranch.loading = false;
              this.newUserAccountBranch = {
                email: "",
                password: "",
                active: true,
                sendEmail: false,
                password: "",
              };

            }
            catch(error) {
              logData.extra.push({ name: "error", value: JSON.stringify(error) })
              await this.$createNewLog("error", logData);
              console.error("Error Creating User Account", error);
              this.newUserAccountBranch.loading = false;
            }
          }
          else{
            this.newUserAccountBranch.loading = false;
          }
        }
        else {
          this.branchUserFeedback = this.$t("general.requiredField", this.$store.state.locale);
        }
      },

      async updateBranch() {
        // Checkt of de begintijd hoger is dan eindtijd. En geeft indien nodig een error met aanwijzing.
        let wrongValuesArr = new Array();
        let somethingWrong = false;
        let dayName = null;

        var translate = {
          Sun: "Zondag ",
          Mon: "Maandag ",
          Tue: "Dinsdag ",
          Wed: "Woensdag ",
          Thu: "Donderdag",
          Fri: "Vrijdag ",
          Sat: "Zaterdag ",
        };

        Object.entries(this.activeBranchData.branchTime).map(([key, value]) => {
          if (
            (this.activeBranchData.branchTime[key].start > this.activeBranchData.branchTime[key].end && this.activeBranchData.defaultDays[key] == true) ||
            (this.activeBranchData.branchTime[key].start == "" && this.activeBranchData.defaultDays[key] == true) ||
            (this.activeBranchData.branchTime[key].end == "" && this.activeBranchData.defaultDays[key] == true)
          ) {
            dayName = key.replace(/Sun|Mon|Tue|Wed|Thue|Fri|Sat/gi, function (matched) {
              return translate[matched];
            });
            wrongValuesArr.push(dayName);
            somethingWrong = true;
          }
        });

        if(somethingWrong == true) {
          this.$swal({
            title: "Onjuiste gegevens",
            imageUrl: "../img/svg/tip.svg",
            text: `De starttijd is hoger dan de eindtijd. Pas dit eerst aan. ${wrongValuesArr}`,
            confirmButtonText: "Okay",
            confirmButtonColor: "#00adef",
          });
        }
        else {
          let logData = {
            superAdminAction: false, // Is action only accessible by superadmin?
            actionType: "userAction",
            action: "updatingBranchInfo",
            actionItem: this.activeBranchData.name,
            extra:[
              {
                name: "originalData",
                value: ""
              },
              {
                name: "name",
                value: this.activeBranchData.name
              },
              {
                name: "companyId",
                value: this.$store.state.activeCompany.id
              },
              {
                name: "branchId",
                value: this.activeBranchData.id
              },
              {
                name: "address",
                value: JSON.stringify(this.activeBranchDataOriginal.branchAddress)
              },
              {
                name: "defaultDays",
                value: JSON.stringify(this.activeBranchDataOriginal.defaultDays,)
              },
              {
                name: "active",
                value: this.activeBranchDataOriginal.active
              },
              {
                name: "changedData",
                value: ""
              },
              {
                name: "name",
                value: this.activeBranchData.name
              },
              {
                name: "companyId",
                value: this.$store.state.activeCompany.id
              },
              {
                name: "branchId",
                value: this.activeBranchData.id
              },
              {
                name: "address",
                value: JSON.stringify(this.activeBranchData.branchAddress)
              },
              {
                name: "defaultDays",
                value: JSON.stringify(this.activeBranchData.defaultDays,)
              },
              {
                name: "active",
                value: this.activeBranchData.active
              }
            ]
          };

          await new Promise((resolve, reject) => {
            let daysProcessed = 0;
            let branchDefaultDays = Object.keys(this.activeBranchData.defaultDays);
            branchDefaultDays.forEach((key) => {
              daysProcessed++;
              if (this.activeBranchData.defaultDays[key] == false) {
                this.activeBranchData.branchTime[key].start = "00:00";
                this.activeBranchData.branchTime[key].end = "00:00";
              }
              if (daysProcessed === branchDefaultDays.length) {
                resolve();
              }
            });
          })
          try {
            await db.collection("branches")
            .doc(this.activeBranchData.id)
            .collection("businessHours")
            .doc("default")
            .set(
              {
                branchTime: this.activeBranchData.branchTime,
                // week: moment().isoWeek(),
                // year: moment().format("YYYY"),
              },
              { merge: true }
            )
            .then(()=>{
              this.removeCalendarResourceCache(moment().format("YYYY-MM-DD"), moment().add(6, "months").format("YYYY-MM-DD"))// Remove created cache in firebase
            })
            .catch((err) => {
              console.error("Error Branch Time =>", err);
            });
            this.activeBranchData.url = this.activeBranchData.url ? this.activeBranchData.url.replace(/([^:]\/)\/+/g, "$1") : ""; // Cleanup URL
            await db.collection("branches")
            .doc(this.activeBranchData.id)
            .set(
              {
                name: this.activeBranchData.name,
                branchAddress: this.activeBranchData.branchAddress,
                paymentOptions: this.activeBranchData.paymentOptions,
                logoUrl: this.activeBranchData.logoUrl ? this.activeBranchData.logoUrl : null,
                senderName: this.activeBranchData.senderName ? this.activeBranchData.senderName : null,
                url: this.activeBranchData.url ? this.activeBranchData.url : null,
                email: this.activeBranchData.email ? this.activeBranchData.email : null,
                phone: this.activeBranchData.phone ? this.activeBranchData.phone : null,
                active: this.activeBranchData.active,
                defaultDays: this.activeBranchData.defaultDays,
                branchTime: this.activeBranchData.branchTime,
                loyalty: this.activeBranchData.loyalty,
              },
              { merge: true }
            )

            await this.$createNewLog("info", logData);
            this.activeBranchDataOriginal = _.cloneDeep(this.activeBranchData)

          }
          catch(error){
            logData.extra.push({ name: "error", value: JSON.stringify(error) })
            await this.$createNewLog("error", logData);
          }
        }
      },

      async addException() {
        let exceptions = this.activeBranchData.exception;
        exceptions.push(this.newException);

        let logData = {
          superAdminAction: false, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "addingBranchScheduleException",
          actionItem: this.activeBranchData.name,
          extra:[
            {
              name: "companyId",
              value: this.$store.state.activeCompany.id
            },
            {
              name: "branchId",
              value: this.activeBranchData.id
            },
            {
              name: "exception",
              value: this.newException
            }
          ]
        };

        try{
          await db.collection("branches")
          .doc(this.activeBranchData.id)
          .update({
            exception: this.activeBranchData.exception,
          })
    
          this.removeCalendarResourceCache(this.newException.start, this.newException.end)// Remove created cache in firebase
          await this.$createNewLog("info", logData);
          console.log("Exception succesfully added");
          this.newExceptionDialog = false;
          this.newException = new Object();
        }
        catch(error) {
          console.error("Error adding Exception: ", error);
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      },

      async removeException(item, index) {
        let exeptions = this.activeBranchData.exception;
        exeptions.splice(index, 1);

        let logData = {
          superAdminAction: false, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "removingBranchScheduleException",
          actionItem: this.activeBranchData.name,
          extra:[
            {
              name: "companyId",
              value: this.$store.state.activeCompany.id
            },
            {
              name: "branchId",
              value: this.activeBranchData.id
            },
            {
              name: "removedException",
              value: JSON.stringify(item)
            }
          ]
        };

        try{
          await db.collection("branches")
          .doc(this.activeBranchData.id)
          .update({
            exception: this.activeBranchData.exception,
          })
          this.removeCalendarResourceCache(item.start, item.end)// Remove created cache in firebase
          await this.$createNewLog("info", logData);
          console.log("Exception succesfully removed");
          this.newExceptionDialog = false;
          this.newException = new Object();
        }
        catch(error) {
          console.error("Error adding Exception: ", error);
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      },

      removeCalendarResourceCache(start, end){ // Remove created cache in firebase
        this.$store.dispatch('removeCalendarResourceCache', {branchId: this.activeBranchData.id, start: start, end: end}) // Remove created cache
        .then((response)=>{
          console.log("removeCalendarResourceCache response: ", response)
        })
      },
    },

    computed: {
      availableUserlanguages(){
        return this.$countries.filter(language => { // Filter rich languages data from languages
          if(language.userLanguage){ return language.id }
        });
      },

      userLanguage() {
        return this.$store.state.locale ? this.$store.state.locale : "NL";
      },
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        if(this.$store.state.companyTheme){
          return this.$store.state.companyTheme;
        }
        else{
          return "thrive"
        }
      },
      
      changed() {
        if(_.isEqual(this.activeBranchData, this.activeBranchDataOriginal) == false){
          return true;
        } else {
          return false;
        }
   
      },

      userAccountBranchChanged() {
        if (_.isEqual(this.userAccountBranch, this.userAccountBranchOriginal) == false) {
          return true;
        } else {
          return false;
        }
      },

      passwordNotEqual() {
        if (this.userAccountBranch.passwordConfirm) {
          if (this.userAccountBranch.passwordConfirm == this.userAccountBranch.password) {
            return false;
          } else {
            return true;
          }
        } else {
          return false;
        }
      },

      newPassword() {
        if (this.userAccountBranch.password && this.userAccountBranch.passwordConfirm == this.userAccountBranch.password) {
          return true;
        } else {
          return false;
        }
      },

      branchExceptionsFiltered() {
        let exceptions = new Array();
        this.activeBranchData.exception.forEach((exception) => {
          if (this.exeptionDisplay == "future") {
            if (exception.end > this.now) {
              exceptions.push(exception);
            }
          } else {
            if (exception.end <= this.now) {
              exceptions.push(exception);
            }
          }
        });
        return exceptions;
      },

      noExeptionData() {
        if (this.exeptionDisplay == "future") {
          return this.$t("branch.noFutureExceptions", this.$store.state.locale);
        } else {
          return this.$t("branch.noPastExceptions", this.$store.state.locale);
        }
      },

      exceptionFuture() {
        if (this.exeptionDisplay == "future") {
          return true;
        } else {
          return false;
        }
      },
      filled() {
        if (this.newException.start && this.newException.end) {
          return true;
        } else {
          return false;
        }
      },
    },
  };
</script>
