<template>
  <v-dialog v-model="showDialog" scrollable @click:outside="closeDialog" max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`"> 
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3>{{ $t('clients.addNewClient', $store.state.locale) }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning">{{ feedback }}</v-alert>
      <v-alert v-if="feedbackSuccess" color="success" class="ma-0 pa-2" style="border-radius: 0" type="success">{{ feedbackSuccess }}</v-alert>

      <v-divider></v-divider>

      <v-card-text class="modal-card ma-0 pa-0">
        <v-form ref="AddClientForm" v-model="valid"  autocomplete="off">
          <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-3 mb-2 ma-4 obvious" :class="$store.state.companyTheme" >
            <h3 :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary + '!important'}">{{ $t('clients.addNewClientMandatory', $store.state.locale) }}</h3>
            <v-divider class="pt-3 mt-3 ma-0"></v-divider>

            <v-container class="pa-0 ma-0">
              <v-card :flat="!newClient.minor" :outlined="newClient.minor" :color="!newClient.minor ? $themes[$store.state.companyTheme][$store.state.themeModus].card : $themes[$store.state.companyTheme][$store.state.themeModus].background" class="ma-0 px-3" :class="{'py-3' : newClient.minor}"> 
                <v-switch
                  class="pa-0 ma-0"
                  v-model="newClient.minor"
                  inset
                  :label="$t('clients.minorLabel', $store.state.locale)"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :hint="`<i>${$t('clients.minorChoiceHint', $store.state.locale)}</i>`"
                  persistent-hint
                  @change="setMinorData()"
                  >
                  <template v-slot:message="{ message, key }">
                    <span v-html="message"></span>
                  </template>
                </v-switch>

                <v-select
                  v-if="newClient.minor" 
                  :label="$t('general.type', $store.state.locale)"
                  outlined
                  class="my-2"
                  dense
                  :item-text="item => $t('general.'+item.name, $store.state.locale)"
                  item-value="id"
                  hide-details
                  content-class="pa-0 ma-0 mr-4"
                  :items="minorTypes"
                  v-model="newClient.minorType"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                  >
                </v-select>

                <label v-if="newClient.minor" style="font-size: 12px">{{ $t('clients.clientControlSearch', $store.state.locale) }}</label>
                <div v-if="newClient.minor && newClient.guardianClientIds.length > 0">
                  <v-text-field
                    readonly
                    :value="guardianClientName"
                    dense
                    class="mt-0"
                    outlined
                    :placeholder="$t('clients.clientControlSearch', $store.state.locale)"
                    append-icon=""
                    prepend-inner-icon="mdi-account"
                    hide-details
                    append-icon="mdi-close"
                    @click:append="resetGuardianClientId()"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    ></v-text-field>
                </div>

                <div v-if="newClient.minor && newClient.guardianClientIds.length == 0">
                  <clientSearch :source="'addClient'" @clientSelect="getSelectedClientData"></clientSearch>  
                </div>
              </v-card>

              <v-select
                class="mt-5"   
                :label="$t('general.gender', $store.state.locale)"
                v-model="newClient.gender"
                :rules="requiredRules"
                :items="genders"
                item-text="name"
                item-value="id"
                required
                outlined
                dense
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details 
              ></v-select>

              <v-text-field
                :label="$t('general.firstName', $store.state.locale)"
                v-model="newClient.name"
                autocomplete
                :rules="requiredRules"
                required
                outlined
                dense
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details
                class="mt-3"           
              ></v-text-field>
        
              <v-text-field
                :label="$t('general.lastName', $store.state.locale)"
                v-model="newClient.surname"
                :rules="requiredRules"
                required
                outlined
                dense
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details
                class="mt-3"
              ></v-text-field>

              <!-- <v-text-field
                :label="$t('general.email', $store.state.locale)"
                v-model="newClientEmail"
                :rules="emailRules"
                outlined
                dense
                class="mt-3"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details
                >
                <template v-slot:append>        
                  <v-icon v-if="validEmail" color="green"> mdi-check-circle </v-icon> 
                </template>
              </v-text-field>  -->

              <v-text-field
                :label="$t('general.email', $store.state.locale)"
                v-model="newClientEmail"
                outlined
                dense
                class="mt-3"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                hide-details
                >
                <template v-slot:append>        
                  <v-icon v-if="validEmail" color="green"> mdi-check-circle </v-icon> 
                </template>
              </v-text-field> 

              <v-select
                :label="$t('general.language', $store.state.locale)"
                outlined
                :hint="$t('user.languageHint', $store.state.locale)"
                persistent-hint
                class="mt-4"
                dense
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                content-class="pa-0 ma-0 mr-4"
                :items="availableLanguages"
                v-model="newClient.language"
                item-text="id"
                >
                <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                  <country-flag :country="data.item.flagCode" size='small'/>
                  <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                </template>
                <template slot="item" slot-scope="data">
                  <country-flag :country="data.item.flagCode" size='small'/>
                  <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                </template>
              </v-select>
            </v-container>
          </v-card>

          <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" flat class="theme-card pa-3 ma-4 mt-2 mb-0" :class="$store.state.companyTheme" >
            <h3>{{ $t('clients.addNewClientExtra', $store.state.locale) }}</h3>
            <v-divider class="pt-3 mt-3 ma-0"></v-divider>
            <v-container grid-list-md >
              <v-layout>
                <v-flex xs12>
                  <v-select
                    :label="$t('general.country', $store.state.locale)"
                    v-model="newClient.address.country"
                    :items="$countries"
                    :item-text="item => item.countryName[($store.state.locale).toLowerCase()]"
                    item-value="country"
                    required
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details
                  ></v-select>
                </v-flex> 
              </v-layout>
              <v-layout  class="mt-2">
                <v-flex xs12 md6>
                  <v-text-field
                    :label="$t('general.zipcode', $store.state.locale)"
                    v-model="newClient.address.postalCode"
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    suffix="1234AA"
                    hide-details               
                  ></v-text-field>
                </v-flex>
                <v-flex xs6 md3>
                  <v-text-field
                    :label="$t('general.number', $store.state.locale)"
                    number
                    v-model.number="newClient.address.number"
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details
                  ></v-text-field>
                </v-flex> 
                <v-flex xs6 md3>
                  <v-text-field
                    :label="$t('general.numberAdd', $store.state.locale)"
                    v-model="newClient.address.numberAddition"
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details
                    ></v-text-field>
                </v-flex>
              </v-layout>
              
              <v-layout  class="mt-2">
                <v-flex xs6>
                  <v-text-field
                    :label="$t('general.street', $store.state.locale)"
                    v-model="newClient.address.street"
                    outlined
                    dense
                    :disabled="!addAddressManual"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details
                  ></v-text-field>
                </v-flex> 
                <v-flex xs6>
                  <v-text-field
                    :label="$t('general.city', $store.state.locale)"
                    v-model="newClient.address.city"
                    outlined
                    dense
                    :disabled="!addAddressManual"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout  class="mt-1">
                <v-flex xs12 class="text-center">
                  <v-alert v-if="!addAddressManual && addressNotFound && !addressChanged" outlined class="ma-0 pa-2" dense type="warning">{{ $t('clients.addressNotFound', $store.state.locale) }}</v-alert>
                  <p :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary }" v-if="!newClient.address.postalCode || !newClient.address.number"> <v-icon size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon> <i>{{ $t('clients.getAddressHint', $store.state.locale) }}</i></p>
                  <v-btn class="mt-2" v-if="!addAddressManual && ((!addressNotFound && addressChanged)|| (addressNotFound && addressChanged))" :disabled="!newClient.address.postalCode || !newClient.address.number" outlined block small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="checkAddress">{{ $t('clients.getAddress', $store.state.locale) }}</v-btn>
                  <v-btn class="mt-2" outlined small block v-if="!addAddressManual && (newClient.address.country != 'netherlands' || !addressChanged && addressNotFound)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="!addAddressManual ? addAddressManual = true : addAddressManual = false">{{ $t('clients.addAddressManual', $store.state.locale) }}</v-btn>
                  <p v-if="addAddressManual"><i>{{ $t('clients.addressFoundManual', $store.state.locale) }}</i> <v-btn small icon @click="addAddressManual = false;"><v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-undo-variant</v-icon></v-btn></p>
                  <p v-if="addressChecked && !addAddressManual && !addressNotFound"><v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon> <i>{{ $t('clients.addressFound', $store.state.locale) }}</i></p>
                </v-flex>    
              </v-layout>
              <v-layout>
                <v-flex xs12>
                  <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="mt-4">

                    <h3 class="text-center py-3 pb-2">{{ $t('general.phones', $store.state.locale) }}</h3>

                    <div v-if="!newClient.phones || newClient.phones.length == 0" class="text-center pa-1 pt-0">
                      <v-avatar 
                        size="46"
                        light
                        class="ma-4 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-phone-ring</v-icon>
                      </v-avatar>
                      <p class="ma-0">{{ $t('clients.noPhonenumbersAdded', $store.state.locale) }}</p>
                    </div>

                    <div v-if="newClient.phones && newClient.phones.length > 0">
                      <v-data-table
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :headers="phoneHeaders"
                        :items-per-page="-1"
                        :items="newClient.phones"
                        hide-default-footer
                        style="background: none"
                        >
                        <template v-slot:item="{item}">
                          <tr>
                            <td class="text-center">
                              <v-icon :size="18" v-if="item.type == 'mobile'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-cellphone</v-icon>
                              <v-icon :size="18" v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-deskphone</v-icon>
                            </td>
                            <td v-if="item.region == 'NL'">{{ item.phone.national }}</td>
                            <td v-else>{{ item.phone.international }}</td>
                            <td class="text-center">
                              <v-icon v-if="item.primary" :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-star</v-icon>
                              <v-btn @click="setPrimary(item)" icon small v-else><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].textSmooth">mdi-star-outline</v-icon></v-btn>
                            </td>
                            <td>
                              <v-btn icon small @click="removePhone(item)"><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon></v-btn>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                      <v-divider></v-divider>
                    </div>

                    <div class="text-center pa-4 pt-3">
                      <v-btn fab small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="addPhonenumber()"><v-icon>mdi-plus</v-icon></v-btn>
                    </div>
                  </v-card>
                </v-flex>
              </v-layout>
              <v-layout>
                <v-flex xs12 >
                  <v-textarea
                    :label="$t('clients.notes', $store.state.locale)"
                    v-model="newClient.notes"
                    no-resize
                    dense
                    rows="3"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    outlined
                    hide-details
                    class="mt-4"
                  ></v-textarea>
                </v-flex> 
              </v-layout>
            </v-container>
          </v-card> 
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                @click="closeDialog()"
                >
                {{ $t('general.cancel', $store.state.locale) }}
              </v-btn>
            </v-flex>
            <v-flex xs12 md8 class="text-right">
              <v-btn
                dark  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="addClient()"
                >
                {{ $t('clients.addClient', $store.state.locale) }}
                <v-icon right dark>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>

    </v-card>

    <!-- / New Phonenumber Dialog \ -->
    <add-phonenumber-dialog :type="'addClientCard'"  @newPhoneNumber="addNewPhonenumber($event)"></add-phonenumber-dialog>
    <!-- \ New Phonenumber Dialog / -->      
    
    <!-- / Client Exist Dialog \ -->
    <v-dialog v-model="existingClientModal" persistent width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card class="dialog-round-wrapper">
        <v-card-text class="ma-0 pt-4 pa-3">
          <div class="text-center">
            <v-avatar 
              size="76"
              light
              class="ma-4 custom-avatar-style" 
              rounded="lg"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
              >
              <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account-multiple</v-icon>
            </v-avatar>
            <h2 class="pa-2">{{ $t('clients.clientAlreadyExists', $store.state.locale) }}</h2>
            <p class="mb-2">{{ $t('clients.clientAlreadyExistsHint', $store.state.locale) }}</p>

            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="pa-4">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left"><strong>{{ $t('general.name', $store.state.locale) }}:</strong></v-flex>
                <v-flex xs12 md6 class="text-left">{{ existingClientData.name }} {{ existingClientData.surname }}</v-flex>
              </v-layout>
              <v-layout row wrap class="ma-0 pa-0" v-if="existingClientData.gender && getGenderName(existingClientData.gender)">
                <v-flex xs12 md6 class="text-left"><strong>{{ $t('general.gender', $store.state.locale) }}:</strong></v-flex>
                <v-flex xs12 md6 class="text-left">{{ getGenderName(existingClientData.gender).name }}</v-flex>
              </v-layout>
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left"><strong>{{ $t('general.emailaddress', $store.state.locale) }}:</strong></v-flex>
                <v-flex xs12 md6 class="text-left">
                  <v-chip x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark v-for="(email, index) in existingClientData.emails" :key="index">
                    {{  email.email }}
                  </v-chip>
                </v-flex>
              </v-layout> 
              <v-layout row wrap class="ma-0 pa-0" v-if=" existingClientData.phones &&  existingClientData.phones.length > 0">
                <v-flex xs12 md6 class="text-left"><strong>{{ $t('clients.phones', $store.state.locale) }}:</strong></v-flex>
                <v-flex xs12 md6 class="text-left">
                  <v-chip x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark v-for="(phone, index) in existingClientData.phones" :key="index">
                    {{  phone.phone.national }}
                  </v-chip>
                </v-flex>
              </v-layout> 
            </v-card>
          </div>
        </v-card-text>

        <v-card-actions class="px-3">
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 class="text-right">
                <v-btn dark block outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="existingClientModal = false">
                {{ $t('clients.clientExistsContinue', $store.state.locale) }}                
                </v-btn>
              </v-flex>
              <v-flex xs12 class="text-right" v-if="source == 'clients'">
                <v-btn dark block :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="openExistingClient()">
                {{ $t('clients.clientExistsCloseAndView', $store.state.locale) }}<v-icon right>mdi-eye</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs12 class="text-right" v-if="source == 'pay'">
                <v-btn dark block :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="selectExistingClient()">
                {{ $t('clients.clientExistsCloseAndSelect', $store.state.locale) }} <v-icon right>mdi-account-check</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Client Exist Dialog / -->

  </v-dialog>
</template>

<script>

import _ from "lodash";
import { bus } from "../../main";
import axios from "axios";
import db from "../../firebase/init";
import AddPhonenumber from "../modals/AddPhoneNumber.vue";
import algoliasearch from "algoliasearch/lite";
import clientSearch from "@/components/elements/clientSearch.vue";

export default {
  components: {
    "add-phonenumber-dialog": AddPhonenumber,
    clientSearch
  },
  name: "add-client-card",
  data() {
    return {
      algoSearch: algoliasearch("B4XU2Z5BAW", "a29d0e9160e36dc8ee83d5888c28cdcc"),
      showDialog: false,
      source: null,
      newClientEmail: "",
      addAddressManual: false,
      addressNotFound: false,
      clientAddressChecked: new Object(),
      addressChecked: false,
      existingClientModal: false, 
      existingClientData: new Object(),
      newClientStart: null,
      clientSearch: null,
      newClient: {
        minor: false, 
        guardianClientIds: new Array(),
        minorType: 'child',
        address: {
          street: null,
          city: null,
          country: "nl",
          number: null,
          postalCode: null,
          numberAddition: null,
          coordinates: null
        },
        health: {
          clientDashboard: {},
          goal: null,
          idealWeight: null,
          kcalBasaal: null,
          length: null,
          lifestyle: null,
          wristSize: null,
          weightGoal: null,
          macronutrients: {}
        },
        phone: null,
        birthday: null,
        language: 'netherlands',
        gender: 2,
        name: "",
        emails: new Array(),
        surname: "",
        phones: new Array(),
        notes: null,
        ethnic: null,
        companies: [],
        permissions: {},
        emailSubscriptions: {
          newsletter: false
        }
      },
      minorTypes: [ 
        {name:'child', id:'child'}, 
        {name:'needy', id:'needy'}, 
        {name:'partner', id:'partner'},   
      ],
      phoneHeaders: [
        {
          text: this.$t('general.type', this.$store.state.locale),
          align: "center",
          value: "type",
          width: "50px",
          sortable: false,
        },
        {
          text: this.$t('general.phone', this.$store.state.locale),
          align: "left",
          value: "phone",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          value: "primary",
          width: "50px",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          value: "primary",
          width: "50px",
          sortable: false,
        }  
      ],
      validEmail: false,
      validatingEmail: false,
      emailRules: [
        // v => !!v || this.$t('general.mandatoryEmailField', this.$store.state.locale),
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||  this.$t('general.mandatoryEmailValid', this.$store.state.locale)
      ],
      requiredRules: [v => !!v || this.$t('general.mandatoryField', this.$store.state.locale)],
      valid: false,
      feedback: null,
      existingClient: false,
      feedbackSuccess: null,
      genders: [
        {
          id: 1,
          name: this.$t('general.male', this.$store.state.locale) 
        },
        {
          id: 2,
          name: this.$t('general.female', this.$store.state.locale) 
        },
        {
          id: 3,
          name: this.$t('general.other', this.$store.state.locale) 
        }
      ],
    };
    
  },

  created(){
    this.newClientStart = _.cloneDeep(this.newClient); 
    bus.$on("newClient", (source) => {
      this.source = source;
      this.newClient.language = this.availableLanguages[0].id;
      this.showDialog = true;
    });
  },

  methods: {

    getGenderName(id){
      return this.genders.find(gender => id == gender.id)
    },

    addPhonenumber(){
      bus.$emit('addPhonenumberDialog', this.newClient.phones, 'addClientCard');
    },

    addNewPhonenumber(data){
      this.newClient.phones.push(data)
    },

    getGender(gender) {
			if (gender == 2) {
				return "Vrouw";
			} else if (gender == 1) {
				return "Man";
			} else {
				return "Onbekend";
			}
		},

    async checkEmail() {
      this.existingClientData = {
        name: null,
        surname: null,
        gender: null,
        emails: new Array(),
        phones: new Array(),
        id: null
      }
      const axiosHeaders = {
        headers: { "Content-Type": "application/json" },
      };

      let axiosData = {
        companyId: this.$store.state.activeCompany.id,
        clientEmail: (this.newClientEmail).toLowerCase(),
        clientFirstName: this.newClient.name ? this.newClient.name : "",
        clientLastName: this.newClient.surname ? this.newClient.surname : "",
        clientPhone: this.newClient.phone ? this.newClient.phone : "",
      };

      await new Promise((resolve) => {
        axios
          .post(`${this.$store.state.restApi.url}/postClientExist`, axiosData, axiosHeaders)
          .then((response) => {
            console.log("response checkEmail: ", response)
            if (response.data.typeExist == 2) { // Client does not exist
              this.existingClient = false;
              this.validatingEmail = false;
              resolve();
            }
            else { // Client bestaat al
              this.existingClient = true;
              this.validatingEmail = false;
              this.existingClientModal= true;
              this.getExistingClientData(response.data.clientId)
              resolve();
            }
          })
          .catch((err) => {
            console.error("Error axios postClientExist =>", err);
            return;
          });
      });
    },

    setMinorData(){
      if(!this.newClient.minor){
        this.resetGuardianClientId();
      }
    },

    resetGuardianClientId(){
      this.newClient.guardianClientIds = new Array();
      this.guardianClientName = "";
    },

    async getSelectedClientData(clientData) {
      this.newClient.guardianClientIds = [clientData.objectID];
      this.guardianClientName = `${clientData.name} ${clientData.surname}`;
    },

    async getExistingClientData(id){
      await db.collection("clients").doc(id)
      .get()
      .then(doc=>{
        this.existingClientData = doc.data();
        this.existingClientData.id = id;
      })
    },

    checkAddress(){
      if(this.newClient.address.country && this.newClient.address.postalCode && this.newClient.address.number){
        this.addressChecked = false;
        this.addressNotFound = false;
        this.clientAddressChecked = {
          postalcode: _.cloneDeep(this.newClient.address.postalCode),
          number: _.cloneDeep(this.newClient.address.number )
        }
        let url = `${this.$store.state.locationsApi.url}/getLocation?country=${this.newClient.address.country}&postalcode=${this.newClient.address.postalCode}&number=${this.newClient.address.number}`;
        axios
        .get(url)
        .then((response) => {
          if(response.status == 200 && response.data){
            this.newClient.address.city = response.data.city;
            this.newClient.address.street = response.data.street;
            this.newClient.address.coordinates = response.data.coordinates;       
          }
          else{
            this.addressNotFound = true;
          }
        })
        .catch(error => {
          console.error("Postcode API error: ", error);
          this.addressNotFound = true;
        })
        this.addressChecked = true;
      }
    },

    async addClient() {
      this.feedback = null;
      let logData = {
        superAdminAction: false, // Is action only accessible by superadmin?
        actionType: "userAction",
        action: "clientAdded",
        actionItem: `${this.newClient.name} ${this.newClient.surname}`,
        extra: new Array()
      };
      if (this.$refs.AddClientForm.validate()) {
        let docId = null;

        let emailaddressData = {
          primary: true,
          email: this.newClientEmail.toLowerCase()
        }
        
        if(this.newClientEmail){
          let emailValidationObject = await this.emailValidation(this.newClientEmail);
          emailaddressData.score = emailValidationObject.score;
          emailaddressData.error = emailValidationObject.error;
          this.newClient.emails.push(emailaddressData);
        }

        try {
          let docRef = await db.collection("clients").add({
            name: this.newClient.name,
            surname: this.newClient.surname,
            emails: this.newClient.emails,
            ethnic: this.newClient.ethnic,
            gender: this.newClient.gender,
            minor: this.newClient.minor, 
            minorType: this.newClient.minorType, 
            guardianClientIds: this.newClient.guardianClientIds,
            health: this.newClient.health,
            language: this.newClient.language,
            birthday: this.newClient.birthday,
            address: this.newClient.address,
            phones: this.newClient.phones,
            notes: this.newClient.notes,
            permissions: this.newClient.permissions,
            companyId: this.$store.state.activeCompany.id,
            emailSubscriptions: this.newClient.emailSubscriptions
          })

          await this.$createNewLog("info", logData);
          if(this.source == 'clients'){
            let client = {
              objectID: docRef.id,
              name: this.newClient.name,
              surname: this.newClient.surname,
              emails: this.newClient.emails,
              ethnic: this.newClient.ethnic,
              gender: this.newClient.gender,
              health: this.newClient.health,
              language: this.newClient.language,
              birthday: this.newClient.birthday,
              address: this.newClient.address,
              phones: this.newClient.phones,
              path: `clients/${docId}`,
              companyId: this.$store.state.activeCompany.id,
            }
            bus.$emit("addClient", client);
          }
          else if(this.source == 'pay'){
            let client = {
              name: this.newClient.name,
              surname: this.newClient.surname,
              id: docId
            }
            bus.$emit("selectClientForPayment", client);
          }
          this.closeDialog();
        }
        catch (error) {
          this.feedback = this.$t("general.addError", this.$store.state.locale) ;
          console.error("Error adding client: ", error);
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      }
      else {
        this.feedback = this.$t('clients.AddClientNotValid', this.$store.state.locale)
      }
    },

    removePhone(item){
      this.newClient.phones = this.newClient.phones.filter(phone => {return phone.phone.e164 != item.phone.e164})
      this.checkForPrimaryNumber()
    },

    async checkForPrimaryNumber(){
      let primary = this.newClient.phones.filter(phone => {return phone.primary})
      if(!primary || primary.length == 0){
        let mobilePhones = this.newClient.phones.filter(phone => {return phone.type == 'mobile'})
        if(!mobilePhones || primary.mobilePhones == 0){
          this.setPrimary(this.newClient.phones[0])
        }
        else{
          this.setPrimary(mobilePhones[0])
        }
      }
    },

    async emailValidation(emailClientCard) {
      
      const start = Date.now();

      const emailIntelScore = await axios
      .get(`https://mailvalidate.hogans.nl/validate-email?email=${emailClientCard}`, { timeout: 6000 })
      .then((response) => {
        // console.log(response.data);
        const durationMs = Date.now() - start; //?
        // console.log(durationMs);
        return rateScore(emailClientCard, response.data, durationMs, true);
      })
      .catch((error) => {
        console.log("Error validating email =>", error.message);
        const durationMs = Date.now() - start; //?
        return rateScore(emailClientCard, null, durationMs, false);
      });

      return emailIntelScore;

      /**
       * @param {*} emailClientCard | String email adress
       * @param {*} emailData  | object with email data
       * @param {*} performanceMs  | time in ms
       * @param {*} axiosSucces  | boolean
       * @returns
       */
      function rateScore(emailClientCard, emailData, performanceMs, axiosSucces) {
        //Domain DNS found
        if (axiosSucces === true) {
          //Domain is found but email is not active?
          if (typeof emailData.success !== "undefined") {
            //Email found check performance and set to primary true
            if (emailData.success == true) {
              return { email: emailClientCard, score: performanceScore(performanceMs), primary: true, error: {} };
            }

            //Email not found
            if (emailData.success == false) {
              console.log(chalk.red("Email Domain succes but not active:", emailClientCard));
              return {
                email: emailClientCard,
                score: 0,
                error: { en: "Email Domain succes but email is invalid", nl: "Domein bestaat maar email wordt niet herkend" },
              };
            }
          } else {
            //Domain DNS is not found
            return { email: emailClientCard, score: 0, error: { en: "Domain DNS not found", nl: "Domein DNS niet gevonden" } };
          }
        } else {
          //Axios time out so no response and possibly bad email.

          return { email: emailClientCard, score: 0, error: { en: "Email does not response, time-out", nl: "Email reageert niet, time-out" } };
        }

        function performanceScore(performanceMs) {
          if (performanceMs < 1000) {
            return 10;
          } else if (performanceMs > 1000 && performanceMs < 2000) {
            return 8;
          } else if (performanceMs > 2000 && performanceMs < 3000) {
            return 7;
          } else if (performanceMs > 3000 && performanceMs < 5000) {
            return 6;
          } else {
            return 5;
          }
        }
      }
    },
    
    setPrimary(item){
      this.newClient.phones.map(phone => {
        phone.phone.e164 == item.phone.e164 ? phone.primary = true : phone.primary = false })
    },

    selectExistingClient(){ 
      // bus.$emit("selectClientForPayment", this.existingClientData);
      this.closeDialog();
    },
    
    openExistingClient(){
      bus.$emit("viewClient", {objectID: this.existingClientData.id});
      this.closeDialog();
    },

    closeDialog() {
      this.showDialog = false;
      this.validEmail = false;
      this.existingClient = false;
      this.feedback = null;
      this.feedbackSuccess = null;
      this.newClient = _.cloneDeep(this.newClientStart);
      this.newClientEmail = "";
      this.addAddressManual = false;
      this.addressNotFound = false;
      this.clientAddressChecked = new Object();
      this.addressChecked = false;
      this.existingClientModal = false;
    }
  },

  watch: {
    newClientEmail: function() { // Check if email is valid
      if ((/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.newClientEmail))) {
        this.validEmail = true;
        this.checkEmail();
      }
      else {
        this.validEmail = false;
        this.existingClient = false;
        this.feedback = null;
        this.feedbackSuccess = null;
      }
    },
    newPhoneNumberInput: {
      handler() {
        if(this.newPhoneNumberInput && this.newPhoneNumberInput.length > 7){
          this.$countries.forEach(language => {
            if(this.newPhoneNumberInput.includes(language.phonePrefix)){
              this.newPhoneNumberData.region = language.countryCode;
            }
          });
          this.checkPhonenumber(this.newPhoneNumberInput, this.newPhoneNumberData.region)
        }
        else{
          this.newPhoneNumberData.valid = null;
        }
      },
      deep: true
    }
  },

  computed: {
    selectedLanguageObject(){
      return this.selectedLanguage ? this.$countries.find(language => { return language.id == this.selectedLanguage }) : {languageName:new Object()};
    },
    
    availableLanguages(){
      let availableLanguages = this.$countries.filter(language => { // Filter rich languages data from company languages
        if(this.$store.state.activeCompany.languages.includes(language.id)){ return language.id }
      });
      // Show languages in the same order as company languages
      return availableLanguages.sort((a, b) => this.$store.state.activeCompany.languages.indexOf(a.id) - this.$store.state.activeCompany.languages.indexOf(b.id)); 
    },

    addressChanged(){
      let clientAddress = {postalCode: this.newClient.address.postalCode, number: this.newClient.address.number}
      let clientAddressChecked = this.clientAddressChecked;

      return _.isEqual(clientAddress, clientAddressChecked) ? false : true;
    },

    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      if(this.$store.state.companyTheme){
        return this.$store.state.companyTheme;
      }
      else{
        return "thrive"
      }
    }
  }
};

</script>


<style>
.modal-card .container.client-address-wrapper {
  padding: 15px !important;
  background-color: #f6f7fb;
}
.modal-card .container {
  padding: 0 !important;
}
.client-address-wrapper p {
  color: #251433;
}

.invalid-email-text {
  margin: -15px 0 0 0;
  font-size: 12px;
  color: #ff5252;
}

.valid-email-text {
  margin: -15px 0 0 0;
  font-size: 12px;
  color: #4caf50;
}

.validate-email-wrapper {
  width: 40px;
  height: 40px;
  margin: 12px 0 0 0;
  background: url("../../assets/icons/loading-dots.svg") no-repeat center center;
  background-size: 40px auto;
}

span.client-optional,
span.client-required{
  font-size: 12px;
  color: rgba(0, 0, 0, 0.67);
  font-weight: 500;
  padding: 0 0 0 5px;
}

span.client-required{
  color: #ff5252;
}

.expantion-item-title{
  font-family: "TT Norms", Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #000000;
  font-size: 18px;
}
#client-info-expand.v-expansion-panel{
  box-shadow: none!important
}
#client-info-expand .v-expansion-panel__container{
  border: none!important;
  background-color: rgba(219,231,255, 0.05);
}

#client-info-expand .v-expansion-panel__container .v-expansion-panel__header{
  border-bottom: 1px solid #DBE5FF!important;
  background-color: #ffffff;
  min-height: 39px;
}

#client-info-expand .v-expansion-panel__container.v-expansion-panel__container--active{
  border-bottom: 1px solid #DBE5FF!important;
  background-color: rgba(219,231,255, 0.05);
}

#client-info-expand .v-expansion-panel__container.v-expansion-panel__container--active .v-expansion-panel__header{
  border-bottom: 1px solid #DBE5FF!important;
  background-color: #ffffff;
}

.input-wrapper{
  padding: 0 20px;
}

.extra-info {
  font-family: "Sofia Pro", Helvetica, Arial, sans-serif;
  font-style: italic;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  padding: 10px;
}
</style>

