<template>
  <fragment>
    <v-flex md6 xs12 v-if="(!$store.state.userCompanies || $store.state.userCompanies.length == 1) && (!showBranches || !$store.state.userBranches || $store.state.userBranches.length == 0)"></v-flex>
    <v-flex :class="companyClass" xs12 v-if="$store.state.userCompanies && $store.state.userCompanies.length > 1 && !$store.state.demoMode">
      <v-select 
        :items="filteredCompanies"
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
        background-color="menu"       
        item-text="name"
        item-value="id"
        hide-details
        rounded
        style="width: 100%"
        @input="changeActiveCompData"
        :value="$store.state.activeCompany.id"
        :no-data-text="$t('general.noCompanies', $store.state.locale)"
        :label=" $t('general.company', $store.state.locale)"
        outlined
        dense>
        <template v-slot:prepend-item>
          <v-list-item style="margin-top: -8px"> 
            <v-list-item-content  class="pt-0" >
              <v-text-field
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :placeholder="$t('general.search', $store.state.locale)"
                prepend-inner-icon="mdi-magnify"
                clearable
                v-model="searchCompanies"
                hide-details
                dense
              ></v-text-field>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <div v-if="searchCompanies && searchCompanies.length > 0" style="padding: 4px 17px 3px 17px; background-color:rgba(0,0,0, 0.02); color: rgba(0, 0, 0, 0.6); text-align: center; font-size: 12px">{{ filteredCompanies.length }} {{ $t("settings.of", $store.state.locale) }} {{ $store.state.userCompanies.length }} {{ $t("settings.companies", $store.state.locale) }}</div>
          <v-divider v-if="searchCompanies && searchCompanies.length > 0"></v-divider>
        </template>
      </v-select>
    </v-flex>
    <v-flex v-if="showBranches && $store.state.userBranches && $store.state.userBranches.length > 0" md3 xs12>
      <v-select
          outlined
          dense
          rounded
          :readonly="$store.state.userBranches.length == 1"
          :disabled="$store.state.userBranches.length == 1"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          hide-details
          :items="$store.state.userBranches"
          item-text="name"
          item-value="id"
          @input="changeActiveBranchData"
          :value="$store.state.activeBranch.id"
          :label="$t('general.branch', $store.state.locale)"
        ></v-select>
    </v-flex>
    <v-flex v-if="showExtraSpace" md3 xs12></v-flex>
  </fragment>

</template>

<script>
  export default {
    name: "companyAndBranchesSelector",
    props: {
      showBranches: Boolean,
      hideExtraSpace: Boolean
    },
    data() {
      return {
        searchCompanies: ""
      }
    },

    methods:{
      async changeActiveCompData(companyId){
        if(companyId != this.$store.state.activeCompany.id){
          await this.$store.dispatch("changeActiveCompData", companyId);
          this.$emit("companyChanged",);
        }
      },
      async changeActiveBranchData(branchId){
        if(branchId != this.$store.state.activeBranch.id){
          await this.$store.dispatch("changeActiveBranchData", branchId);
          this.$emit("branchChanged",);
        }
      },
    },

    computed: {   
      companyClass(){
        if(this.showBranches && this.$store.state.userBranches){
          return "md3"
        }
        else{
          return "md6"
        }
      },

      showExtraSpace(){ // Show or hide extra space in GUI when companies cannot be selected. To ensure proper alignment in the header

        let oneSelectorShowing = this.$store.state.userCompanies.length == 1 || !this.showBranches; // No Companies to show or branches are hidden
        let hideCompaniesSelector = this.$store.state.activeUserRoleNumber < 2 && this.$store.state.demoMode; // Developer or Super admin is logged in and DemoModus is active
        
        if((oneSelectorShowing && !this.hideExtraSpace) || (hideCompaniesSelector && !this.hideExtraSpace)){
          return true;
        }
        else{
          return false;
        }
      },

      filteredCompanies(){ // Filter companies based on search input
        if (this.searchCompanies && this.searchCompanies.length > 0){
          return this.$store.state.userCompanies.filter(company => {
            return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
          });
        }
        else {
          return this.$store.state.userCompanies;
        }
      }
    }
  };
</script>
