<template>
  <v-dialog v-model="addCustomClientFilterDialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3>{{ $t('clients.addClientFilter', $store.state.locale) }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeAddFilterDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      
      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ feedback }}</v-alert>

      <v-divider></v-divider>

      <v-card-text>
        <v-form ref="AddCustomClientFilterForm" lazy-validation>
          <v-text-field 
            label="Naam" 
            v-model.trim="newFilter.name" 
            autocomplete 
            required
            outlined
            dense
            class="mt-4"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            background-color="background"
            hide-details
          ></v-text-field>

          <div class="py-4">
            <v-menu
              offset-y
              close-on-content-click
              >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                <v-icon dark left>mdi-plus-circle</v-icon>
                  {{ $t('clients.newCriteria', $store.state.locale) }}
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="(criterium, index) in criteria"
                  :key="index"
                  @click="addCriteriaToList(index)"
                 >
                  <v-list-item-title>{{ criterium.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>

          <v-card class="pt-1 px-1 mb-2" v-for="(criterium, index) in newFilter.criteria" :key="index" outlined color="background" flat>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs6><h4 class="pt-2" style="font-size: 16px; font-weight: bold;">{{ criteriaData(criterium.id).name}}</h4></v-flex>
                <v-flex xs6 class="text-right"> 
                  <v-btn 
                    class="pa-0 ma-0"
                    icon
                    @click="removeCriterium(index)"
                    >
                    <v-icon color="text">mdi-delete</v-icon>
                  </v-btn>
                </v-flex>

                <v-flex xs6>
                  <v-select
                    :items="criteriaData(criterium.id).conditions.conditionOptions"
                    :label="criteriaData(criterium.id).conditions.conditionLabel"
                    v-model="criterium.condition"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    dense
                    :readonly="criteriaInputDisabled(criterium.id)"
                    :disabled="criteriaInputDisabled(criterium.id)"
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="menu"
                    hide-details
                  ></v-select>
                </v-flex>
              
                <v-flex xs6>
                  <v-select
                    v-if="criteriaData(criterium.id).conditions.inputType == 'select'"
                    :items="criteriaData(criterium.id).conditions.inputOptions"
                    :label="criteriaData(criterium.id).conditions.inputLabel"
                    v-model="criterium.value"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    dense
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="menu"
                    hide-details
                  ></v-select>
                  <v-text-field
                    v-if="criteriaData(criterium.id).conditions.inputType == 'number'"  
                    :label="criteriaData(criterium.id).conditions.inputLabel"
                    v-model.number="criterium.value"
                    outlined
                    dense
                    type="number"
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="menu"
                    hide-details
                  ></v-text-field> 
                </v-flex>
                <v-flex xs12 v-if="criterium.secondCondition"><h5 class="pa-0" style="font-size: 14px; font-weight: bold;">{{ criteriaData(criterium.id).conditions.secondConditionName}}</h5></v-flex>
                <v-flex xs6 v-if="criterium.secondCondition">
                  <v-select
                    :items="criteriaData(criterium.id).conditions.secondConditionOptions"
                    :label="criteriaData(criterium.id).conditions.secondConditionLabel"
                    v-model="criterium.secondCondition"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    dense
                    :readonly="criteriaSecondInputDisabled(criterium.id)"
                    :disabled="criteriaSecondInputDisabled(criterium.id)"
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="menu"
                    hide-details
                  ></v-select>
                </v-flex>
              
                <v-flex xs6 v-if="criterium.secondCondition">
                  <v-select
                    v-if="criteriaData(criterium.id).conditions.secondInputType == 'select'"
                    :items="criteriaData(criterium.id).conditions.secondInputOptions"
                    :label="criteriaData(criterium.id).conditions.secondInputLabel"
                    v-model="criterium.secondValue"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    dense
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="menu"
                    hide-details
                  ></v-select>
                  <v-text-field
                    v-if="criteriaData(criterium.id).conditions.secondInputType == 'number'"  
                    :label="criteriaData(criterium.id).conditions.secondInputLabel"
                    v-model.number="criterium.secondValue"
                    outlined
                    dense
                    type="number"
                    class="ma-0 pa-0"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="menu"
                    hide-details
                  ></v-text-field> 
                </v-flex>
       
              </v-layout>

            </v-container>
          </v-card>

        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md4 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn
                outlined
                depressed
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" 
                @click="closeAddFilterDialog()"
                >
                {{ $t('general.cancel', $store.state.locale) }}
              </v-btn>
            </v-flex>
            <v-flex xs12 md8 class="text-right">
              <v-btn
                dark
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="addCustomFilter()"
                >
                {{ $t('clients.addClientFilter', $store.state.locale) }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import db from "../../firebase/init";
import { bus } from "../../main";

export default {
  name: "AddCustomClientFilter",
  props: {
    criteria: Array,
    type: String
  },
  data() {
    return {
      addCustomClientFilterDialog: false,
      branches: null,
      feedback: null,
      newFilter: {
        name: "",
        criteria: []
      },
    };
  },

  created(){
    bus.$on('newCustomClientFilter',()=>{
      this.addCustomClientFilterDialog = true;
    });
  },

  methods: {
    closeAddFilterDialog() {
      this.addCustomClientFilterDialog = false;
      this.newFilter.name = "";
      this.newFilter.criteria = [];
    },

    addCriteriaToList(index){
      let criterium = {
        id: this.criteria[index].id,
        condition: this.criteria[index].conditions.conditionValue,
        value: this.criteria[index].conditions.inputValue,
        
      }
      if(this.criteria[index].conditions.secondConditionType){
        criterium.secondCondition = this.criteria[index].conditions.secondConditionValue;
        criterium.secondValue = this.criteria[index].conditions.secondInputValue;
      }
      this.newFilter.criteria.push(criterium)
    },

    removeCriterium(index){
      this.newFilter.criteria.splice(index, 1)
    },

    async addCustomFilter() {
      this.feedback = null;
      let logData = {
        superAdminAction: false, // Is action only accessible by superadmin?
        actionType: "userAction",
        action: "customClientFilterAdded",
        actionItem: this.newFilter.name,
        extra: [
          {
            name: "name",
            value: this.newFilter.name
          },
          {
            name: "criteria",
            value: this.newFilter.criteria
          }
        ]
      }
      if (this.newFilter.name && this.newFilter.criteria.length > 0) {
        try {
          let docRef = await db.collection("customClientFilters")
          .add({
            companyId: this.$store.state.activeCompany.id,
            connections: [],
            name: this.newFilter.name,
            type: this.type,
            criteria: this.newFilter.criteria,
            created: new Date()
          })
          let filterData = _.cloneDeep(this.newFilter)
          filterData.id = docRef.id; 
          await this.$createNewLog("info", logData);

          this.$emit('updateData', 'new', null, filterData);
          console.log("this.newFilter: ", filterData);
          this.closeAddFilterDialog();    
        }
        catch (error) {
          this.feedback = this.$t('general.addError', this.$store.state.locale);
          console.error("Error adding Custom Client Filter: ", error);
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      } 
      else {
        this.feedback = this.$t('general.requiredField', this.$store.state.locale);
      }
    }
  },
  computed: {
    criteriaInputDisabled(){    
      return id => {
        let item = _.find(this.criteria, ['id', id]);
        if(item.conditions.conditionType == 'hidden'){
          return true;
        }
        else {
          return false;
        }
      }
    },
    criteriaSecondInputDisabled(){    
      return id => {
        let item = _.find(this.criteria, ['id', id]);
        if(item.conditions.secondConditionType == 'hidden'){
          return true;
        }
        else {
          return false;
        }
      }
    },
    criteriaData(){
      return id => _.find(this.criteria, ['id', id]);
    },
    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    }
  }
};
</script>
