<template>
  <div>
    <div class="treatments" :class="{ selected: treatmentSelected }" v-if="!loadingTreatments">
      <div class="pa-3" :style="{ backgroundColor : $themes[$store.state.companyTheme][$store.state.themeModus].card }">
        <v-text-field outlined v-model="treatmentSearch" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"  prepend-inner-icon="mdi-magnify"  clearable dense hide-details :placeholder="$t('booking.searchTreatments', $store.state.locale)"></v-text-field>
      </div>

      <div class="treatment-list-by-type" v-if="!treatmentSearch || treatmentSearch.length == 0">
        <v-container v-if="$store.state.activeBranch.calendarSettings.bookingTreatmentFilter == 'gender'" grid-list-md class="pt-0 py-3 px-2 ma-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex md4>
              <v-btn block depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :outlined="buttonColorAll" :dark="!buttonColorAll" @click="selectedGender = 'all'">{{ $t('general.all', $store.state.locale) }}</v-btn>
            </v-flex>
            <v-flex md4>
              <v-btn block depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :outlined="buttonColorFemale" :dark="!buttonColorFemale" @click="selectedGender = 'female'">{{ $t('general.woman', $store.state.locale) }}</v-btn>
            </v-flex>
            <v-flex md4>
              <v-btn block depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :outlined="buttonColorMale" :dark="!buttonColorMale" @click="selectedGender = 'male'">{{ $t('general.man', $store.state.locale) }}</v-btn>
            </v-flex>
          </v-layout>
        </v-container>

        <v-slide-group v-if="$store.state.activeBranch.calendarSettings.bookingTreatmentFilter == 'treatmentTypes'" class="mb-3" show-arrows>
          <v-slide-item>
            <v-btn
              class="mx-1"
              :color="selectedTreatmentType == 'all' ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].background" 
              :dark="selectedTreatmentType == 'all'" 
              depressed
              :input-value="selectedTreatmentType == 'all'"
              @click="selectTreatmentType('all')"
            >
            {{ $t('general.all', $store.state.locale) }}
            </v-btn>
          </v-slide-item>

          <v-slide-item
            v-for="(treatmentType, index) in treatmentTypes"
            :key="index"
            v-slot="{ active, toggle }"
            >
            <v-btn
              class="mx-1"
              :color="selectedTreatmentType == treatmentType.id ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].background" 
              :dark="selectedTreatmentType == treatmentType.id" 
              :input-value="selectedTreatmentType == treatmentType.id"
              depressed
              @click="selectTreatmentType(treatmentType.id)"
              >
                {{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, treatmentType.name)}}
            </v-btn>
          </v-slide-item>
        </v-slide-group>

        <v-slide-group v-if="$store.state.activeBranch.calendarSettings.bookingTreatmentFilter == 'customTreatmentTypes'" class="mb-3"  show-arrows>
          <v-slide-item>
            <v-btn
              class="mx-1"
              :color="selectedCustomTreatmentType == 'all' ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].background" 
              :dark="selectedCustomTreatmentType == 'all'" 
              depressed
              :input-value="selectedCustomTreatmentType == 'all'"
              @click="selectCustomTreatmentType('all')"
            >
            {{ $t('general.all', $store.state.locale) }}
            </v-btn>
          </v-slide-item>

          <v-slide-item
            v-for="(treatmentType, index) in $store.state.companyCustomTreatmentTypes"
            :key="index"
            v-slot="{ active, toggle }"
            >
            <v-btn
              class="mx-1"
              :color="selectedCustomTreatmentType == treatmentType.id ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].background" 
              :dark="selectedCustomTreatmentType == treatmentType.id" 
              :input-value="selectedCustomTreatmentType == treatmentType.id"
              depressed
              @click="selectCustomTreatmentType(treatmentType.id)"
              >
                {{ treatmentType.name }}
            </v-btn>
          </v-slide-item>
        </v-slide-group>

        <p v-if="treatmentTypes.length == 0" class="text-center pa-4 ma-0" style="font-size: 16px">{{ $t('booking.noTreatments', $store.state.locale) }}</p>
        <v-divider></v-divider>

        <v-list class="pa-0 ma-0" two-line flat>
          <v-list-item-group v-for="(treatmentType, index) in treatmentTypesFiltered" :key="treatmentType.id" multiple>
            <div class="treatment-type-title">
              <h3>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, treatmentType.name) }}</h3>
            </div>
            <v-list-item
              class="treatment-list"
              :class="{ selected: treatment.selected }"
              v-for="(treatment, i) in treatmentType.treatments"
              :key="i"
              @click="selectTreatment('treatment', treatment, i, index)"
              >
              <v-list-item-action v-if="treatment.type == 'treatment'" class="mr-4">
                <v-icon v-if="treatment.selected" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" size="30">mdi-checkbox-marked-circle</v-icon>
                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" size="30" v-else>mdi-checkbox-blank-circle-outline</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, treatment.treatmentTitle) }}
                </v-list-item-title>
                <v-list-item-subtitle class="treatment-description">
                  {{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, treatment.treatmentDesc) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action-text>
                <span v-if="treatment.treatmentPrice">
                  <strong>{{ numberWithCommas(treatment.treatmentPrice) }}</strong>
                </span>
                <span class="treatment-price empty"  v-else>-</span>
              </v-list-item-action-text>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </div>

      <div class="treatment-list-by-search custom-algolia" v-else>
        <v-divider></v-divider>
        <ais-instant-search :search-client="algoSearch" index-name="thrive_treatments" class="pa-0 ma-0">
          <v-list class="pa-0 ma-0" flat>
            <ais-hits class="pa-0 ma-0">
              <template slot="item" slot-scope="{ item }" >
                <v-list-item
                  two-line
         
                  :class="{ selected: item.selected }"
                  @click="selectTreatment('treatment', item, null, null)"
                  >
                  <v-list-item-action class="mr-4">
                   
                    <v-icon v-if="selectedTreatment.treatment && item.objectID == selectedTreatment.treatment.id" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" size="30">mdi-checkbox-marked-circle</v-icon>
                    <v-icon v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" size="30" >mdi-checkbox-blank-circle-outline</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.treatmentTitle) }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="treatment-description" v-if="item.treatmentDesc && Object.keys(item.treatmentDesc).length > 0">{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.treatmentDesc) }}</v-list-item-subtitle>
                    <v-list-item-subtitle class="treatment-description" v-else>-</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action-text>
                    <span v-if="item.treatmentPrice">
                      <strong>{{ numberWithCommas(item.treatmentPrice, 'netto', item.vat, item.branchPriceAdjustments) }}</strong>
                    </span>
                    <span class="treatment-price empty"  v-else>-</span>
                  </v-list-item-action-text>
                </v-list-item>
              </template>
            </ais-hits>
          </v-list>
          <ais-state-results>
          <p class="text-center pa-2 pt-4 pb-1" slot-scope="{ state: { query }, results: { hits } }" v-show="!hits.length">
            <i>{{ $t('general.noResultsFoundFor', $store.state.locale) }}  {{ query }}</i>
          </p>
        </ais-state-results>
        <ais-configure :query="treatmentSearch" :facetFilters="[`companyId:${this.$store.state.activeCompany.id}`, 'treatmentActive:true', `branches:${this.$store.state.activeBranch.id}`]"/>
        </ais-instant-search>
      </div>
    </div>

    <div v-else class="text-center" style="padding: 50px 10px 10px 10px">
      <v-progress-circular :size="50" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" indeterminate></v-progress-circular>
    </div>
  </div>

</template>

<script>
  import { bus } from "../../main";
  import axios from "axios";
  import algoliasearch from 'algoliasearch/lite';
  import 'instantsearch.css/themes/satellite-min.css';

  export default {
    name: "treatmentSelector",
    props: {
      source: String,
    },
    data() {
      return {
        algoSearch: algoliasearch("B4XU2Z5BAW", "a29d0e9160e36dc8ee83d5888c28cdcc"),
        seperateTreatments: false,
        filterCustomTreatmentTypes: false,
        filterTreatmentTypes: false,
        treatmentSearch: "",
        treatments: new Array(),
        treatmentTypes: new Array(),
        loadingTreatments: true,
        treatmentSelected: false,
        treatmentIndex: 0,
        selectedTreatment: {
          type: null,
          treatment: null,
          fullTreatment: null,
        },
        speedSelect: {
          active: false,
          id: null
        },
        genders: [
          {
            id: 1,
            name: this.$t('general.man', this.$store.state.locale)
          },
          {
            id: 2,
            name: this.$t('general.woman', this.$store.state.locale)
          }
        ],
        selectedGender: "all",
        selectedCustomTreatmentType: "all",
        selectedTreatmentType: "all",
      }   
    },

    created(){   
      this.getTreatments(); // Get all treatments on created
      /**
       * Reload Treatments for Companies and Branches (Branches can have different prices)
       */
      bus.$on("reloadTreatments", () => { 
        this.getTreatments()
      });

      /**
       * Listen to preSelectTreatment
       */
      bus.$on("preSelectTreatment", (treatmentId) => { 
        this.preSelectTreatment(treatmentId)
      });

      /**
       *  Listen to resetTreatmentSelect
       */
      bus.$on("resetTreatmentSelect", () => {
        this.resetSelectTreatment();   
      });
    },

    methods:{
      /**
       * Load all treatment with the Thrive Treatment API
       */
      async getTreatments() {
        this.loadingTreatments = true;

       await axios
        .get(`${this.$store.state.restApi.url}/getTreatments?companyId=${this.$store.state.activeCompany.id}&branchId=${this.$store.state.activeBranch.id}&showTreatment=all&showEvents=false`)
        .then((response) => {
          this.treatmentsGender = {
            female: response.data.treatmentsWoman,
            male: response.data.treatmentsMan,
          };

          this.treatments = response.data.treatments;
          this.treatmentTypes = response.data.treatmentTypes;
          this.treatmentTypes.forEach(treatmentType =>{
            treatmentType.treatments.sort((a, b) => this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, a.treatmentTitle).localeCompare(this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, b.treatmentTitle)));
          })

          if(this.speedSelect.active && this.speedSelect.id){ // Find and select treatment that was pre-selected en continue
            let treatmentTypeIndex = _.findIndex(this.treatmentTypes,(type)=> { return _.find(type.treatments, ['id', this.speedSelect.id]);});
            let treatmentIndex =  _.findIndex(this.treatmentTypes[treatmentTypeIndex].treatments, ['id', this.speedSelect.id]);
            let data = this.treatmentTypes[treatmentTypeIndex].treatments[treatmentIndex];
            let type = this.treatmentTypes[treatmentTypeIndex].treatments[treatmentIndex].type;
            this.selectTreatment(type, data, treatmentIndex, treatmentTypeIndex);
            this.next();
            this.loadingTreatments = false;
          }
          else{
            this.loadingTreatments = false;
          }
        });
        return
      },

      /**
       * Format number to currency number
       * @param {number} x = treatmentPrice
       * @param {string} type = Is price netto or bruto?
       * @param {string} vatId = vatId when price is netto
       */
      numberWithCommas(x, type, vatId, branchPriceAdjustments) {
        let price = 0;

        if(type == 'netto' && vatId){
          let vatItem = this.$store.state.vatItems.find((vat)=> { return vat.id == vatId}) // Get vatItem information from VUEX-store
          if(vatItem != undefined){
            price = ((vatItem.percentage * x) / 100) + x;

            if(branchPriceAdjustments && branchPriceAdjustments[this.$store.state.activeBranch.id] && branchPriceAdjustments[this.$store.state.activeBranch.id].adjustmentType){ // There are price adjustments for this branch
              if(branchPriceAdjustments[this.$store.state.activeBranch.id].adjustmentType != 'noChanges'){
                price = branchPriceAdjustments[this.$store.state.activeBranch.id].totalAmountNetto * ((vatItem.percentage / 100)+1);
              }
            }
          }
        }
        else{
          price = Number(x.toFixed(2));
        }
        return price ? price.toLocaleString("nl-NL", { style: "currency", currency: "EUR" }): "";
      },

      /**
       * Selecter customTreatmentType in carousel and show only treatments of custom type
       * @param {*} id = customTreatmentType Id
       */
      selectCustomTreatmentType(id){
        this.selectedCustomTreatmentType = id;
      },

      /**
       * Selecter TreatmentType in carousel and show only treatments of type
       * @param {string} id = treatmentType Id
       */
      selectTreatmentType(id){  
        this.selectedTreatmentType = id;
      },

      /**
       * Reset all data and start over again (on close or booking)
       */
      resetSelectTreatment(){
        this.treatmentSearch = "";
        this.treatmentIndex = 0;
        this.treatmentSelected = false;
        this.selectedTreatment = {
          type: null,
          treatment: null,
          fullTreatment: null,
        };
        this.treatmentTypes.forEach((treatmentType)=> {
          treatmentType.treatments.forEach((treatment)=> {
            treatment.selected = false
          })
        })
      },

      /**
       * Pre-select a treatment (example: for changing a treatment, the current treatment is pre-selected)
       * @param {string} type = DocId of the treatment
       */
      preSelectTreatment(treatmentId){ 
        this.treatmentTypes.forEach((treatmentType, treatmentTypeIndex)=> {
          treatmentType.treatments.forEach((treatment, treatmentIndex)=> {
            if(treatment.id == treatmentId){
              this.selectTreatment('treatment', treatment, treatmentIndex, treatmentTypeIndex)
            }
          })
        })
      },

      /**
       * Select treatment and change data to show which treatment is selected
       * @param {string} type = Selected Type (treatment or event)
       * @param {object} data = Data of the treatment
       * @param {number} treatmentIndex = Index of the treatment in the treatments Array of treatmentTypes
       * @param {number} treatmentTypeIndex  Index of treatmentType in the treatmentTypes Array
       */
      selectTreatment(type, data, treatmentIndex, treatmentTypeIndex) {
        if (type == "treatment" ) {
          if (data.type == "treatment" || this.treatmentSearch.length > 0) {
            this.selectedTreatment.type = "treatment";
            this.selectedTreatment.treatment = null;

            // SELECT & DESELECT TREATMENTS & PUSH SELECTED-TREATMENT-DATA

            this.treatmentTypes.forEach((treatmentType) => {
              treatmentType.treatments.forEach((treatment) => {
                if (treatment.id == data.id || treatment.id == data.objectID) {
                  treatment.selected = true;
                  this.selectedTreatment.treatment = treatment;
                }
                else {
                  treatment.selected = false;
                }
              });
            });

            this.treatments.forEach((treatment) => {
              if (treatment.id == data.id || treatment.id == data.objectID) {
                treatment.selected = true;
              } else {
                treatment.selected = false;
              }
            });

            if (this.treatmentTypesFiltered.treatments && this.treatmentTypesFiltered.treatments.length > 0) {
              this.treatmentTypesFiltered.treatments.forEach((treatment) => {
                if (treatment.id == data.id || treatment.id == data.objectID) {
                  console.log("Found 2")
                  treatment.selected = true;
                } else {
                  treatment.selected = false;
                }
              });
            }
            this.calculateTotalTreatmentPrice();
          }
        }

        if (this.selectedTreatment.treatment) {
          this.treatmentSelected = true;
          if (treatmentTypeIndex == this.treatmentTypes.length - 1) {
            if (treatmentIndex == this.treatmentTypes[treatmentTypeIndex].treatments.length - 1) {
              setTimeout(() => {
                // this.scrollToTreatmentsBottom();
              }, 250);
            }
          }
        }
        else {
          this.treatmentSelected = false;
        }

        if(this.source == "calendar"){
          bus.$emit("changeTreatmentSelected", this.selectedTreatment.treatment.id);
        }
        else{
          bus.$emit("bookingTreatmentSelected", this.selectedTreatment);
        }
      },

      /**
       *  Calculate total price of selected treatments
       */
      calculateTotalTreatmentPrice() {
        this.totalTreatmentPrice = 0;
        this.treatments.forEach((treatment) => {
          if (treatment.selected) {
            this.totalTreatmentPrice = this.totalTreatmentPrice + treatment.treatmentPrice;
          }
        });

        if (this.selectedTreatment.treatment && this.showPrices) {
          var element = document.getElementById("total-price");
          element.classList.add("run-animation");
          setTimeout(function () {
            element.classList.remove("run-animation");
          }, 500);
        }
      },
    },

    computed: {
      /**
       * Show treatments based on selected Type or customtype
       */
      treatmentTypesFiltered() { 
        let treatmentTypesFiltered = new Array();   
    
        if (this.treatmentTypes.length > 0) {
          this.loadingTreatments = true;


          let userFilter = this.$store.state.activeBranch.calendarSettings.bookingTreatmentFilter != 'none' ? true: false;
          let genderFilter = (this.selectedGender != "all" && this.$store.state.activeBranch.calendarSettings.bookingTreatmentFilter == 'gender') ? true : false;
          let treatmentTypeFilter = (this.selectedTreatmentType != 'all' && this.$store.state.activeBranch.calendarSettings.bookingTreatmentFilter == 'treatmentTypes') ? true : false;
          let customTreatmentTypeFilter = (this.selectedCustomTreatmentType != 'all' && this.$store.state.activeBranch.calendarSettings.bookingTreatmentFilter == 'customTreatmentTypes') ? true : false;

          if(userFilter && (genderFilter || treatmentTypeFilter || customTreatmentTypeFilter)){ // Filter Treatments
            if(this.$store.state.activeBranch.calendarSettings.bookingTreatmentFilter == 'gender'){
              this.treatmentTypes.forEach((treatmentType) => {
                let treatmentTypeByGender = _.cloneDeep(treatmentType);
                treatmentTypeByGender.treatments = new Array();
                treatmentType.treatments.forEach((treatment) => {
                  if (treatment.treatmentGender == this.selectedGender) {
                    treatmentTypeByGender.treatments.push(treatment);
                    treatmentTypeByGender.treatments.sort((a, b) => a.weight - b.weight || this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, a.treatmentTitleIntern).localeCompare(this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, b.treatmentTitleIntern)));
                  }
                });
                if (treatmentTypeByGender.treatments.length > 0) {
                  treatmentTypesFiltered.push(treatmentTypeByGender);
                }
              });
              this.loadingTreatments = false;
              return treatmentTypesFiltered;
            }
            if(this.$store.state.activeBranch.calendarSettings.bookingTreatmentFilter == 'treatmentTypes'){
              let selectedTtreatmentTypes = new Array()
              this.treatmentTypes.forEach((treatmentType) => {
                if(treatmentType.id == this.selectedTreatmentType){
                  selectedTtreatmentTypes.push(treatmentType);
                }
              });
              this.loadingTreatments = false;
              return selectedTtreatmentTypes;
            }
            if(this.$store.state.activeBranch.calendarSettings.bookingTreatmentFilter == 'customTreatmentTypes'){
              this.treatmentTypes.forEach((treatmentType) => {
                let customTreatmentType = _.cloneDeep(treatmentType);
                customTreatmentType.treatments = new Array();
                treatmentType.treatments.forEach((treatment) => {
                  if (treatment.customTreatmentType == this.selectedCustomTreatmentType) {
                    customTreatmentType.treatments.push(treatment);
                    customTreatmentType.treatments.sort((a, b) => a.weight - b.weight || this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, a.treatmentTitleIntern).localeCompare(this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, b.treatmentTitleIntern)));
                  }
                });
                if (customTreatmentType.treatments.length > 0) {
                  treatmentTypesFiltered.push(customTreatmentType);
                }
              });
              this.loadingTreatments = false;
              return treatmentTypesFiltered;
            }
          }

          else { // Show All Treatments
            this.treatmentTypes.forEach((treatmentType) => {
              treatmentTypesFiltered.push(treatmentType);
              
            });
            this.loadingTreatments = false;
            return treatmentTypesFiltered;
          }
        }
        else {
          return new Array();
        }
      },
    }
  };
</script>
