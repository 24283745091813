<template v-slot:activator="{ on }">
  <div>

    <div class="pb-2">
      <v-container grid-list-md class="ma-0 pa-0 pb-2" fluid>
        <v-layout row wrap>
          <companySelector :showBranches="true" @companyChanged="loadData" @branchChanged="loadData"></companySelector>
        </v-layout>
      </v-container>
    </div>

    <div v-if="!dataloaded" class="thrive-loader"></div>

    <div v-if="dataloaded">
      <v-container container grid-list-xl fluid class="ma-0 pa-0">
        <v-layout row wrap>
          <v-flex xs6>
            <!-- <v-row no-gutters>
              <v-col cols="8">
                <h3 v-if="$store.state.connectedEmployee && $store.state.connectedEmployee.userRoleNumber > 4" class="news-title">{{ $t('tasks.todoFor', $store.state.locale) }} {{ $store.state.connectedEmployee.name}}</h3>
                <h3 v-else class="news-title">{{ $t('tasks.todo', $store.state.locale) }}</h3>
              </v-col>
              <v-col cols="4">
                <div class="text-right" v-if="addTasks">
                  <v-btn fab dark small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="openNewTaskDialog()">
                    <v-icon dark>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row> -->

            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow mt-2 mb-4" :class="$store.state.companyTheme">
              <div class="pa-3">
                <v-avatar
                  size="36"
                  light
                  style="float: left"
                  class="ma-0 mr-2 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-checkbox-marked</v-icon>
                </v-avatar>
                <v-row no-gutters>
                  <v-col cols="8">
                    <h3 class="pt-1 pb-4" v-if="$store.state.connectedEmployee && $store.state.connectedEmployee.userRoleNumber > 4">{{ $t('tasks.todoFor', $store.state.locale) }} {{ $store.state.connectedEmployee.name}}</h3>
                    <h3 class="pt-1 pb-4" v-else >{{ $t('tasks.todo', $store.state.locale) }}</h3>
                  </v-col>
                  <v-col cols="4">
                    <div class="text-right" v-if="addTasks">
                      <v-btn fab dark small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="openNewTaskDialog()">
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
                
                <v-divider></v-divider>

              </div>
              <v-container container class="ma-0 pa-2">
                <v-layout row wrap class="ma-0 pa-0">
                  <v-flex xs3 d-flex class="ma-0 pa-0">
                    <div class="multi-button left" :class="{ active: tasksPeriod == 'day' }" @click="loadTasks('day')">
                      <p class="multi-button-text">{{ $t('general.today', $store.state.locale) }}</p>
                      <div class="multi-button-background"></div> 
                    </div>
                  </v-flex>
                  <v-flex xs3 d-flex class="ma-0 pa-0">
                    <div class="multi-button middle first" :class="{ active: tasksPeriod == 'thisWeek' }" @click="loadTasks('thisWeek')">
                      <p class="multi-button-text">{{ $t('tasks.thisWeek', $store.state.locale) }}</p>
                      <div class="multi-button-background"></div> 
                    </div>
                  </v-flex>
                  <v-flex xs3 d-flex class="ma-0 pa-0">
                    <div class="multi-button middle last" :class="{ active: tasksPeriod == 'nextWeek' }" @click="loadTasks('nextWeek')">
                      <p class="multi-button-text">{{ $t('tasks.nextWeek', $store.state.locale) }}</p>
                      <div class="multi-button-background"></div> 
                    </div>
                  </v-flex>
                  <v-flex xs3 d-flex class="ma-0 pa-0">
                    <div class="multi-button right" :class="{ active: tasksPeriod == 'completed' }" @click="loadTasks('completed')">
                      <p class="multi-button-text">{{ $t('tasks.completed', $store.state.locale) }}</p>
                      <div class="multi-button-background"></div> 
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>

              <div v-if="tasksLoaded">

                <!-- / OPEN TASKS \ -->
                <v-list dense  class="pa-0 ma-0" v-if="filteredTasks.open.length > 0 && tasksPeriod != 'completed'" >  
                  <div v-for="(task, index) in filteredTasks.open" :key="index + tasksCheck">
                    <div v-if="(!showAllTasks[tasksPeriod] && index < 9) || showAllTasks[tasksPeriod]">
                      <v-divider></v-divider>
                      <v-list-item dense :class="task.status" style="min-height: 50px">
                        <v-list-item-action v-if="completeTasks" class="my-0 py-0" @click="taksAction(task)" style="cursor: pointer">
                          <v-icon v-if="task.extra.completed || task.justCompleted" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-checkbox-marked</v-icon>
                          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                        </v-list-item-action>
                        <v-list-item-action class="my-0 py-0" v-else style="cursor: pointer">
                          <v-icon v-if="task.extra.completed" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-checkbox-marked</v-icon>
                          <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                        </v-list-item-action>

                        <v-list-item-content class="my-0 py-0">
                          <v-list-item-title><span :class="{'strike-task': task.justCompleted}">{{ task.description }}</span></v-list-item-title>
                          <v-list-item-subtitle>
                            <span :class="{'strike-task': task.justCompleted}">{{ task.clientname }}
                              <span v-if="tasksPeriod == 'day' && task.dateBooking && task.start && !task.allDay" class="v-list-item__subtitle">({{ task.start | moment("H:mm") }}u)</span>
                              <span v-else-if="task.dateBooking" class="v-list-item__subtitle">({{ task.dateBooking | moment("dddd D MMMM") }})</span>
                            </span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="text-center my-0 py-1">
                          <div v-if="task.status == 'busy' && !task.allDay">
                            <v-icon size="20" class="px-2">mdi-timer-sand</v-icon>
                            <v-list-item-action-text style="display: block; width: 100%">{{ $t('tasks.inProcess', $store.state.locale) }}</v-list-item-action-text>
                          </div>
                          <div v-if="task.status == 'overdue'">
                            <v-icon size="20" class="px-2" color="error">mdi-alert</v-icon>
                            <v-list-item-action-text style="display: block; width: 100%">{{ $t('tasks.expired', $store.state.locale) }}</v-list-item-action-text>
                          </div>
                          <div v-if="task.status == 'noDate'">
                            <v-icon size="20" class="px-2">mdi-clock-time-nine-outline</v-icon>
                            <v-list-item-action-text style="display: block; width: 100%">{{ $t('tasks.noDate', $store.state.locale) }}</v-list-item-action-text>
                          </div>
                        </v-list-item-action>
                        <v-list-item-action class="ma-0 pa-0 px-1"  v-if="$store.state.userRoleNumber < 4">
                          <v-btn icon @click="editTask(task)"><v-icon size="20">mdi-pencil</v-icon></v-btn>
                        </v-list-item-action>
                        <v-list-item-action class="ma-0 pa-0 px-1" v-if="$store.state.userRoleNumber < 4">
                          <v-btn icon @click="removeTaskDialog(task)"><v-icon size="20">mdi-delete</v-icon></v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </div>
                  </div>
                  <v-divider></v-divider>

                  <div class="text-center" v-if="filteredTasks.open.length > 9">
                    <v-btn v-if="!showAllTasks[tasksPeriod]" @click="showAllTasks[tasksPeriod] = true" class="ma-3 mb-1" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small>
                      {{ $t("tasks.seeAll", $store.state.locale) }}
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                    <v-btn v-if="showAllTasks[tasksPeriod]" @click="showAllTasks[tasksPeriod] = false" class="ma-3 mb-1" text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small>
                      {{ $t("tasks.seeLess", $store.state.locale) }}
                    </v-btn>
                  </div>
                </v-list>

                <div v-if="filteredTasks.open.length == 0 && tasksPeriod != 'completed'" class="pa-4 text-center">
                  <i>{{ $t('tasks.noTasks', $store.state.locale) }}</i>
                </div>
                <!-- \ OPEN TASKS / -->

                <!-- / COMPLETED TASKS \ -->
                <div v-if="filteredTasks.completed.length > 0 || tasksPeriod == 'completed'">
                  <div class="text-center" v-if="tasksPeriod != 'completed'">
                    <v-btn v-if="!showCompletedTasks[tasksPeriod]" @click="showCompletedTasks[tasksPeriod] = true" class="ma-3" text :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" x-small>
                      {{ $t("tasks.showCompleted", $store.state.locale) }}
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                    <v-btn v-if="showCompletedTasks[tasksPeriod]" @click="showCompletedTasks[tasksPeriod] = false" class="ma-3" text :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" x-small>
                      {{ $t("tasks.hideCompleted", $store.state.locale) }}
                    </v-btn>
                  </div>

                  <v-list dense class="pa-0 ma-0" v-if="showCompletedTasks[tasksPeriod] || tasksPeriod == 'completed' ">
                    <div v-for="(task, index) in filteredTasks.completed" :key="index + tasksCheck">
                      <div v-if="(!showAllTasks[tasksPeriod] && index < 10) || showAllTasks[tasksPeriod] || tasksPeriod != 'completed'">
                        <v-divider></v-divider>
                        <v-list-item dense :class="task.status" style="min-height: 50px">
                          <v-list-item-action v-if="completeTasks" class="my-0 py-0" @click="taksAction(task)" style="cursor: pointer">
                            <v-icon v-if="task.extra.completed" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-checkbox-marked</v-icon>
                            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                          </v-list-item-action>
                          <v-list-item-action class="my-0 py-0" v-else style="cursor: pointer">
                            <v-icon v-if="task.extra.completed" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-checkbox-marked</v-icon>
                            <v-icon v-else>mdi-checkbox-blank-outline</v-icon>
                          </v-list-item-action>

                          <v-list-item-content class="my-0 py-0">
                            <v-list-item-title><span :class="[{'strike-text': !task.justUncompleted},{'unstrike-task': task.justUncompleted}]">{{ task.description }}</span></v-list-item-title>
                            <v-list-item-subtitle>
                              <span :class="[{'strike-text': !task.justUncompleted},{'unstrike-task': task.justUncompleted}]">
                                {{ task.clientname }}
                                <span v-if="tasksPeriod == 'day' && task.dateBooking && task.start && !task.allDay" class="v-list-item__subtitle">({{ task.start | moment("H:mm") }}u)</span>
                                <span v-else-if="task.dateBooking" class="v-list-item__subtitle">({{ task.dateBooking | moment("dddd D MMMM") }})</span>
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action class="text-center my-0 py-1" v-if="task.extra.completedOn">
                            <div style="font-size: 12px" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text}"><strong>{{ $t("tasks.completedOn", $store.state.locale) }}</strong><br>
                            <i v-if="task.extra.completedOn.seconds">{{ task.extra.completedOn.seconds | moment("D-MM-YYYY H:mm") }}u</i>
                            <i v-else-if="task.extra.completedOn">{{ task.extra.completedOn | moment("D-MM-YYYY H:mm") }}u</i>
                          </div>
                          </v-list-item-action>
                        </v-list-item>
                      </div>
                    </div>

                    <div class="text-center" v-if="filteredTasks.completed.length > 10 && tasksPeriod == 'completed'">
                      <v-divider></v-divider>
                      <v-btn v-if="!showAllTasks[tasksPeriod]" @click="showAllTasks[tasksPeriod] = true" class="ma-3" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small>
                        {{ $t("tasks.seeAll", $store.state.locale) }}
                        <v-icon right>mdi-chevron-down</v-icon>
                      </v-btn>
                      <v-btn v-if="showAllTasks[tasksPeriod]" @click="showAllTasks[tasksPeriod] = false" class="ma-3" text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small>
                        {{ $t("tasks.seeLess", $store.state.locale) }}
                      </v-btn>
                    </div>
                  </v-list>
                </div>

                <div v-if="filteredTasks.completed.length == 0 && tasksPeriod == 'completed'" class="pa-4 text-center">
                  <i>{{ $t('tasks.noCompletedTasks', $store.state.locale) }}</i>
                </div>
                 <!-- \ COMPLETED TASKS / -->
              </div>
              <div v-else style="min-height: 130px; position: relative">
                <div class="thrive-loader"></div>
              </div>
            </v-card>

            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
              <div class="pa-3">
                <v-avatar
                  size="36"
                  light
                  style="float: left"
                  class="ma-0 mr-2 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account-cash</v-icon>
                </v-avatar>
                <h3 class="pt-1 pb-4">{{ $t('payment.deposits', $store.state.locale)}} </h3>
                
                <v-divider></v-divider>
                

                <div v-if="!downPaymentsLoading">
                  <div v-if="downPaymentsHighlighed && downPaymentsHighlighed.length > 0">
                    <v-data-table
                      :headers="downPaymentHeader"
                      :items-per-page="itemsPerPage"
                      :items="downPaymentsHighlighed"
                      :page.sync="downPaymentsSelectedPage"
                      hide-default-footer
                      dense
                      :key="downPaymentsHighlighed.length"
                      class="revenue-overview-table"
                      :no-data-text="$t('reports.noDataFound', $store.state.locale)"
                      >
                      <template v-slot:item="{item}">
                        <tr>
                          <td>{{ formatReportDate(item.timestamp.seconds, "X", "D MMM") }} - {{ formatReportDate(item.timestamp.seconds, "X", "H:mm") }}u</td>
                          <td>
                            {{ item.clientName }}
                            <div v-if="$store.state.activeUserRoleNumber == 0 && !$store.state.demoMode"> {{item.id}}</div>
                          </td>
                          <td>

                            <v-tooltip dark bottom  v-if="item.paymentStatus == 'paid' && item.status.type && item.status?.type == 'refund'" > 
                              <template v-slot:activator="{ on }">
                                <v-chip small v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].quaternary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].quaternary" light class="mx-1 my-1 custom-chip-style">{{ $t('booking.downPaymentStatusrefund' , $store.state.locale) }} <v-icon right x-small>mdi-help-circle</v-icon></v-chip> 
                              </template>
                              <span class="tooltip-text-white">{{ $t('booking.downPaymentStatusrefundHint' , $store.state.locale) }}</span>
                            </v-tooltip>

                            <v-chip v-else-if="item.paymentStatus == 'paid'" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].quaternary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].quaternary" light class="mx-1 my-1 custom-chip-style">{{ $t('booking.downPaymentStatus' + item.paymentStatus , $store.state.locale) }}</v-chip>
                            
                            <v-tooltip dark bottom  v-else-if="item.paymentStatus == 'failed'" > 
                              <template v-slot:activator="{ on }">
                                <v-chip small v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" light class="mx-1 my-1 custom-chip-style">{{ $t('booking.downPaymentStatus' + item.paymentStatus , $store.state.locale) }} <v-icon right x-small>mdi-help-circle</v-icon></v-chip> 
                              </template>
                              <span class="tooltip-text-white">{{ $t('booking.downPaymentStatus' + item.paymentStatus + 'Hint' , $store.state.locale) }}</span>
                            </v-tooltip>

                            <v-tooltip dark bottom  v-else-if="item.paymentStatus == 'expired' || item.paymentStatus == 'open'"> 
                              <template v-slot:activator="{ on }">
                                <v-chip small v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].text" light class="mx-1 my-1 custom-chip-style">{{ $t('booking.downPaymentStatusopen', $store.state.locale) }} <v-icon right x-small>mdi-help-circle</v-icon></v-chip> 
                              </template>
                              <span class="tooltip-text-white">{{ $t('booking.downPaymentStatusopenHint' , $store.state.locale) }}</span>
                            </v-tooltip>

                            <v-chip v-else small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].text" light class="mx-1 my-1 custom-chip-style">{{ item.paymentStatus }} </v-chip>

                            <div v-if="$store.state.activeUserRoleNumber == 0 && !$store.state.demoMode"> {{item.paymentStatus}}</div>
                          </td>
                          <td class="text-right">{{ formatReportNumber(item.amount.number) }}</td>
                          <td>
                            <v-chip v-if="item.conversionDocId "small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mx-1 my-1 custom-chip-style">{{ $t('branch.widgetSettings', $store.state.locale) }} </v-chip>
                            <v-chip v-if="!item.conversionDocId "small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mx-1 my-1 custom-chip-style">{{ $t('booking.downpaymentRequiredEmailExtraShort', $store.state.locale) }} </v-chip>
                          </td>
                               
                          <td>
                              <v-btn v-if="item.bookingSummaryId" x-small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" outlined @click="viewDownPaymentAppointmentInCalendar(item.bookingSummaryId)"> {{ $t('general.view' , $store.state.locale) }}</v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <v-divider v-if="downPaymentsHighlighed.length > itemsPerPage"></v-divider>

                    <div class="text-center pa-4" v-if="downPaymentsHighlighed.length > itemsPerPage">
                      <v-pagination
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        v-model="downPaymentsSelectedPage"
                        :length="downPaymentsSelectorPages"
                        :total-visible="7"
                      ></v-pagination>
                    </div>
                  </div>

                  <div v-else>
                      Geen aanbetalingen om weer te geven
                  </div>
                </div>
                <div v-else class="text-center" style="padding: 50px 10px 10px 10px">
                  <v-progress-circular :size="50" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" indeterminate></v-progress-circular>
                </div>

                
              


              </div>
            </v-card>
          </v-flex>


          <v-flex xs6>
            <v-row no-gutters>
              <v-col cols="8">
                <h3 class="news-title">Leads</h3>
              </v-col>
              <v-col cols="4">
                <div class="text-right" v-if="addTasks">
                  <v-btn fab dark small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="openNewLeadDialog()">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-expansion-panels class="mt-1 mb-4" accordion flat v-model="leadTypesPanel" multiple dense style="z-index: 0">
            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow my-1" v-for="(type, name) in leadTypes" :key="name" :class="$store.state.companyTheme" style="width: 100%">
              <v-expansion-panel dense>
                <v-expansion-panel-header class="pa-2" style="min-height: 44px; font-size: 16px">
                  <template v-slot:default>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <div class="lead-type-header">{{ type.name[$store.state.locale.toLowerCase()] }}</div>
                        <div class="lead-type-counter">{{ type.leads.length }}</div>
                      </v-col>
                    </v-row>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-for="(lead, index) in type.leads" :key="index">
                    <div v-if="(!type.showAll && index < 5) || type.showAll">
                      <v-divider></v-divider>
                      <v-list-item dense @click="openLead(lead)">
                        <v-list-item-avatar size="26">
                          <v-icon v-if="name == 'unassigned'" class="grey lighten-1" small dark>mdi-account-plus</v-icon>
                          <v-icon v-else class="grey lighten-1" small dark>{{ lead.actions[0].icon }}</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content class="my-0 py-0">
                          <v-list-item-title
                            ><strong>{{ lead.clientInfo.firstName }} {{ lead.clientInfo.lastName }}</strong></v-list-item-title
                          >
                          <v-list-item-subtitle>
                            <span v-if="name == 'unassigned'">{{ $t("leadCampaign.assignLead", $store.state.locale) }}</span>
                            <span v-else>
                              {{ lead.actions[0].nameResult[$store.state.locale.toLowerCase()] }}
                              <span v-if="name == 'unscheduled' && lead.assignedTo">{{ getAssignedEmployee(lead.assignedTo) }}</span>
                              <span v-if="name == 'scheduled'">{{ $t("leadCampaign.on", $store.state.locale) }} {{ lead.actions[0].date | moment("D MMMM H:mm") }}u</span>
                              <span v-if="name == 'scheduledToday'">{{ $t("leadCampaign.at", $store.state.locale) }} {{ lead.actions[0].date | moment("H:mm") }}u</span>
                            </span>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="text-center my-0 py-1" v-if="lead.overtime && name == 'unassigned'">
                          <v-icon class="px-2" color="warning"> mdi-alert </v-icon>
                          <v-list-item-action-text style="display: block; width: 100%">{{ $t("leadCampaign.leadActionToLate", $store.state.locale) }}</v-list-item-action-text>
                        </v-list-item-action>
                      </v-list-item>
                    </div>
                  </div>

                  <div class="text-center" v-if="type.leads.length > 5">
                    <v-divider></v-divider>
                    <v-btn v-if="!type.showAll" @click="type.showAll = true" class="ma-3" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small>
                      {{ $t("leadCampaign.seeAll", $store.state.locale) }}
                      <v-icon right>mdi-chevron-down</v-icon>
                    </v-btn>
                    <v-btn v-if="type.showAll" @click="type.showAll = false" class="ma-3" text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small>
                      {{ $t("leadCampaign.seeLess", $store.state.locale) }}
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-card>
            </v-expansion-panels>
          </v-flex>
        </v-layout>
      </v-container>
    </div>   

    <!-- / New Task Dialog \ -->
    <v-dialog v-model="newTask.dialog" persistent scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t('tasks.addTaskTitle', $store.state.locale) }}</h3>
          <v-spacer></v-spacer>
          <v-btn v-if="!newTask.overlap.checking" icon dark @click="closeNewTaskDialog()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert v-if="newTask.feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning" v-html="newTask.feedback"></v-alert>

        <v-divider></v-divider>

        <v-card-text>
          <v-container grid-list-md class="ma-0 pa-0">
            <v-layout row wrap class="pt-2">
              <v-flex xs12>
               
                <v-menu v-model="newTask.dateDialog" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      outlined
                      dense
                      class="mt-4"
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      v-model="taskDateReadable"
                      :label="$t('general.date', $store.state.locale) "
                      prepend-inner-icon="mdi-calendar-today"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" no-title scrollable :first-day-of-week="1" :min="min" v-model="newTask.date" @input="newTask.dateDialog = false"></v-date-picker>
                </v-menu>

                <v-switch
                  class="my-0 mt-4 pl-1"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  dense
                  inset
                  hide-details                  
                  v-model="newTask.allDay"
                  :label="$t('tasks.allDay', $store.state.locale) "
                ></v-switch>
              </v-flex>
              <v-flex xs6 v-if="!newTask.allDay">
                <v-text-field
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  prepend-inner-icon="mdi-clock-outline"
                  :label="$t('general.start', $store.state.locale)"
                  type="time"
                  :suffix="$t('general.hour', $store.state.locale)"
                  hide-details
                  class="mt-4"
                  v-model="newTask.start"
                ></v-text-field>
              </v-flex>
              <v-flex xs6 v-if="!newTask.allDay">
                <v-text-field
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  prepend-inner-icon="mdi-clock-outline"
                  :label="$t('general.end', $store.state.locale)"
                  type="time"
                  :suffix="$t('general.hour', $store.state.locale)"
                  class="mt-4"
                  hide-details
                  v-model="newTask.end"
                  >
                </v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-textarea outlined hide-details class="mt-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" background-color="background" name="input-5-1" :label="$t('general.description', $store.state.locale)"  rows="3" v-model="newTask.notes"></v-textarea>
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <v-select
                outlined
                class="mt-4"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                background-color="background"
                :items="employees"
                item-text="name"
                item-value="id"
                return-object
                dense
                v-model="newTask.employee"
                :menu-props="{ maxHeight: '300' }"
                :label="$t('general.employees', $store.state.locale)"
                hide-details
              ></v-select>
            </v-flex>
            <v-flex xs12 v-if="!newTask.allDay">
              <v-switch class="ma-0 mt-4 pa-0" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :label="$t('tasks.overwritable', $store.state.locale)" v-model="newTask.bookingOverride"> </v-switch>
            </v-flex>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left">
                <div class="text-left">
                  <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"  @click="closeNewTaskDialog()"> {{ $t('general.cancel', $store.state.locale) }} </v-btn>
                </div>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  dark
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :loading="newTask.overlap.checking"
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  @click="addNewTask()"
                  >
                  {{ $t('tasks.addTask', $store.state.locale) }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ New Task Dialog / -->

    <!-- / Delete Task Dialog \ -->
    <v-dialog v-model="removeTaskInfo.dialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :disabled="removeTaskInfo.loading"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
          </v-avatar>
          <h3 class="mb-4">{{ $t('tasks.removeTask', $store.state.locale) }}</h3> 
          <p class="ma-0">{{ $t('tasks.removeTaskInfo', $store.state.locale) }}</p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn outlined depressed :disabled="removeTaskInfo.loading" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="removeTaskInfo.dialog = false">
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :loading="removeTaskInfo.loading" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeTask()">
                   {{ $t('general.remove', $store.state.locale) }}
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete Task Dialog / -->

    <!-- / New Lead Dialog \ -->
    <v-dialog v-model="newLead.dialog" persistent scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h2>{{ $t('leadCampaign.createNewLead', $store.state.locale) }}</h2>
          <v-spacer></v-spacer>
          <v-btn v-if="!newTask.overlap.checking" icon dark @click="closeNewLeadDialog()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-alert v-if="newLead.feedback" class="ma-0 pa-2" style="border-radius: 0" type="warning" v-html="newLead.feedback"></v-alert>

        <v-divider></v-divider>

        <v-card-text>
          <v-container grid-list-md class="pa-0" fluid>
            <v-layout row wrap>
              <v-flex xs6>
                <v-text-field
                  class="ma-0 pa-0 mt-1"
                  :label="$t('general.firstName', $store.state.locale)"
                  v-model="newLead.clientInfo.firstName"
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  hide-details
                ></v-text-field>
              </v-flex>

              <v-flex xs6>
                <v-text-field
                  class="ma-0 pa-0 mt-1"
                  :label="$t('general.lastName', $store.state.locale)"
                  v-model="newLead.clientInfo.lastName"
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  hide-details
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-text-field
                  class="ma-0 pa-0 mt-1"
                  :label="$t('general.email', $store.state.locale)"
                  v-model="newLead.clientInfo.email"
                  autocomplete
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  required
                  dense
                  outlined
                  hide-details
                ></v-text-field>
              </v-flex>

              <v-flex xs12>
                <v-select
                  :label="$t('general.language', $store.state.locale)"
                  outlined
                  class="mt-1"
                  dense
                  hide-details
                  content-class="pa-0 ma-0 mr-4"
                  :items="availableLanguages"
                  v-model="newLead.language"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  item-text="id"
                  >
                  <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                    <country-flag :country="data.item.flagCode" size='small'/>
                    <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                  </template>
                  <template slot="item" slot-scope="data">
                    <country-flag :country="data.item.flagCode" size='small'/>
                    <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                  </template>
                </v-select>
              </v-flex>
            </v-layout>


            <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="pa-2 mt-2">
              <v-layout class="pa-0 ma-0">
                <v-flex xs12>
                  <p class="ma-0 pa-0" style="font-size: 13px">{{ $t('general.phone', $store.state.locale) }}</p>
                </v-flex>
              </v-layout>
              <v-layout class="pa-0 ma-0">

                <v-flex xs3>
                  <v-select
                    outlined
                    hide-details
                    class="pa-0 ma-0 mt-0 select-custom-items"
                    content-class="pa-0 ma-0 select-custom-items-content"
                    :items="$countries"
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-model="newLeadPhoneRegion"
                    item-text="item"
                    item-value="countryCode"
                    >
                    <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                      <country-flag :country="data.item.flagCode" size='small'/>
                      <span class="pl-1"> {{ data.item.phonePrefix }}</span>
                    </template>
                    <template v-slot:item="{ active, item, attrs, on }">
                      <v-list-item v-on="on" v-bind="attrs" #default="{ active }" class="my-0 py-0 pl-3">
                        <v-list-item-avatar size="20">
                          <country-flag :country="item.flagCode" size='small'/>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ item.phonePrefix }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-flex>

                <v-flex xs9>
                  <v-text-field
                    class="ma-0 pa-0 mt-0"
                    v-model="newLeadPhoneInput"
                    autocomplete
                    dense
                    counter
                    required
                    outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details
                    >
                    <template v-slot:append>        
                      <v-icon v-if="newLeadPhoneValid == true" color="green"> mdi-check-circle </v-icon> 
                      <v-icon v-if="newLeadPhoneValid == false" color="warning"> mdi-alert</v-icon> 
                    </template>
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-card>

            <v-layout row wrap>
              <v-flex xs12>
                <v-select
                  :items="$leadTags"
                  v-model="newLead.type"
                  label="Leadtype"
                  :item-text="(item) =>  $t(`leadCampaign.tags.${item.query}`, $store.state.locale)"
                  item-value="source"
                  required
                  clearable
                  dense
                  outlined
                  class="mt-4"
                  hide-details
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-if="campaigns && campaigns.length > 0"
                  :items="campaigns"
                  v-model="newLead.campaign"
                  :label="$t('leadCampaign.campaign', $store.state.locale)"
                  item-text="name"
                  item-value="id"
                  required
                  dense
                  return-object
                  outlined
                  class="mt-4"
                  hide-details
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-select
                  :items="customSources"
                  v-model="newLead.customSource"
                  :label="$t('leadCampaign.loadSource', $store.state.locale)"
                  :item-text="(item) => item.name[$store.state.locale.toLowerCase()]"
                  item-value="id"
                  required
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  class="mt-4"
                  hide-details
                ></v-select>
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="newLead.extra"
                  :label="$t('general.extra', $store.state.locale)"
                  outlined
                  dense
                  rows="3"
                  class="mt-4"
                  hide-details
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left">
                <div class="text-left">
                  <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"  @click="closeNewLeadDialog()"> {{ $t('general.cancel', $store.state.locale) }} </v-btn>
                </div>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :loading="newLead.loading" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" @click="addNewLead()">
                  {{ $t('leadCampaign.addLead', $store.state.locale) }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ New Lead Dialog / -->

    <!-- / Task Overlap Dialog \ -->
    <v-dialog v-model="newTask.overlap.dialog" persistent scrollable max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
        <v-card-text class="ma-0 pt-4">
          <div class="text-center">
            <div class="event-overlap"></div>
            <h2 class="pa-2" style="line-height: 1.3"><strong> {{ $t('tasks.taskOverlap', $store.state.locale) }}</strong></h2>
            <p class="ma-0 mb-1" v-html="$t('tasks.taskOverlap', $store.state.locale)"></p>
          </div>
          <div>
            <div v-if="newTask.overlap.overlaps && newTask.overlap.overlaps.length > 0">
              <div style="border-radius: 5px" :style="{ backgroundColor: booking.color }" class="ma-2 pa-1" flat v-for="(booking, index) in newTask.overlap.overlaps" :key="index">
                <div style="position: relative">
                  <div v-if="booking.type == 'booking'" class="booking-type" :style="{ backgroundColor: booking.color }" style="z-index: 1; font-weight: bold; line-height: 15px">
                    <span style="color: #fff; font-weight: bold; line-height: 15px" v-if="booking.source == 'widget'">O</span>
                    <span style="color: #fff; font-weight: bold; line-height: 15px" v-if="booking.source == 'app'">A</span>
                    <span style="color: #fff; font-weight: bold; line-height: 15px" v-if="booking.source == 'personal'">P</span>
                    <span style="color: #fff; font-weight: bold; line-height: 15px" v-if="booking.source == 'telephone'">T</span>
                  </div>
                  <div class="event-header">
                    <div class="eve-time" style="color: #fff">{{ booking.start | moment("H:mm") }}</div>
                    <div v-if="booking.type == 'booking'" class="calender-event-description eve-desc">{{ booking.subtitle }}</div>
                    <div v-else class="calender-event-description eve-desc">{{ booking.title }}</div>
                  </div>
                  <div v-if="booking.type == 'booking'" class="calender-event-title">{{ booking.title }}</div>
                  <div v-else-if="booking.type == 'task'" class="calender-event-title task">{{ booking.subtitle }}</div>
                  <div v-else class="calender-event-title">{{ booking.subtitle }}</div>

                  <div v-if="booking.type == 'task'" style="left: 0; top: 32px" class="task-type" :class="{ done: booking.extra.completed }"></div>
                  <div v-if="booking.type == 'booking'" style="color: #fff; font-size: 12px">{{ booking.client }}</div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left">
                <div class="text-left">
                  <v-btn outlined depressed color="tertiary" @click="closeNewTaskChecker()"> Annuleren </v-btn>
                </div>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark class="modal-button-next" color="secondary" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" @click="addNewTask()">
                  {{ $t('tasks.addTask', $store.state.locale) }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Task Overlap Dialog / -->

    <!-- / Edit Lead Dialog \ -->
    <edit-lead-form :customSources="customSources" :selectedBranch="$store.state.activeBranch" @leadEdited="updateLeadData($event)"></edit-lead-form>
    <!-- \ Edit Lead Dialog / -->

    <!-- / Edit Pause Dialog \ -->
    <edit-pause ref="editPause"></edit-pause>
    <!-- \ Edit Pause dialog / -->
  </div>
</template>

<script>
  import _ from "lodash";
  import db from "../firebase/init";
  import AddNewsForm from "../components/modals/AddNewsForm.vue";
  import EditNewsForm from "../components/modals/EditNewsForm.vue";
  import EditLeadForm from "../components/modals/EditLeadForm.vue";
  import editPause from "../components/modals/EditPause.vue";
  import companySelector from "@/components/elements/companySelector.vue";
  import moment from "moment";
  import axios from "axios";
  import { bus } from "../main";

  import algoliasearch from "algoliasearch/lite";
  const client = algoliasearch('B4XU2Z5BAW', 'a29d0e9160e36dc8ee83d5888c28cdcc');
  const clientIndex = client.initIndex('thrive_clients');


  import { parsePhoneNumber } from 'awesome-phonenumber'

  // import { NFC } from 'nfc-pcsc';

  export default {
    name: "Home",
    components: {
      AddNewsForm: AddNewsForm,
      EditNewsForm: EditNewsForm,
      EditLeadForm: EditLeadForm,
      "edit-pause": editPause,
      companySelector: companySelector
    },

    data: function () {
      return {
        min: moment().format("YYYY-MM-DD"),
        removeDialog: false,
        selectedBranchData: new Object(),
        removeNewsId: null,
        inputForm: false,
        changeTreatmentTimeModal: false,
        editNewsDialog: false,
        addNewsDialogChange: false,
        dialog: false,
        showCompletedTasks:{
          day: false,
          thisWeek: false,
          nextWeek: false,
          completed: false
        },
        showAllTasks:{
          day: false,
          thisWeek: false,
          nextWeek: false,
          completed: false
        },
        selectedTask: new Object(),
        selectedTaskOriginal: new Object(),
        newTask: {
          feedback: null,
          dialog: false,
          date: null,
          dateDialog: false,
          allDay: false,
          start: null,
          end: null,
          notes: "",
          resource: null,
          bookingOverride: false,
          overlap: {
            checking: false,
            dialog: false,
            overlaps: new Array(),
          },
        },
        newLead: {
          dialog: false,
          feedback: null,
          type: null,
          campaign: null,
          customSource: null,
          clientId: null,
          extra: null,
          clientInfo: {
            firstName: "",
            lastName: "",
            email: "",
            phone: new Object(),
          },
          type: null,
          language: "nl",
        },
        newLeadPhoneRegion:"",
        newLeadPhoneInput: "",
        newLeadPhoneValid: null,
        customSources: [
          {
            id: "advertisement",
            name: {
              en: "Advertisement",
              nl: "Advertentie",
              de: "Anzeige"
            },
          },
          {
            id: "bringFriend",
            name: {
              en: "Bring a friend",
              nl: "Bring a friend",
              de: "Bring a friend"
            },
          },
          {
            id: "email",
            name: {
              en: "E-mail",
              nl: "E-mail",
              de: "E-mail",
            },
          },
          {
            id: "website",
            name: {
              en: "Website",
              nl: "Website",
              de: "Webseite",
            },
          },
          {
            id: "flyer",
            name: {
              en: "Flyer",
              nl: "Flyer",
              de: "Pamphlet",
            },
          },
          {
            id: "wordOfMonth",
            name: {
              en: "Word of month",
              nl: "Mond tot mond",
              de: "Mund zu Mund"
            },
          },
          {
            id: "referral",
            name: {
              en: "Referral",
              nl: "Doorverwijzing",
              de: "Verweisung"
            },
          },
          {
            id: "social",
            name: {
              en: "Social Media",
              nl: "Social Media",
              de: "Sozialen Medien",
            },
          },
          {
            id: "formerClient",
            name: {
              en: "Former client",
              nl: "Voormalige klant",
              de: "Ehemaliger Kunde"
            },
          },
        ],
        newLeadTypes: [
          {
            id: "walkIn",
            name: {
              en: "Walk-in",
              nl: "Walk-in",
            },
          },
          {
            id: "callIn",
            name: {
              en: "Call-in",
              nl: "Call-in",
            },
          },
          {
            id: "mailIn",
            name: {
              en: "Mail-in",
              nl: "Mail-in",
            },
          },
        ],
        branches: new Array(),
        selectedLead: new Object(),
        selectedLeadOriginal: new Object(),
        leadTypesPanel: new Array(),
        leadTypes: {
          unassigned: {
            name: {
              nl: "Niet toegewezen",
              en: "Unasigned",
              de: "Nicht zugewiesen"
            },
            showAll: false,
            leads: new Array(),
          },
          unscheduled: {
            name: {
              nl: "Niet gepland",
              en: "Unscheduled",
              de: "Außerplanmäßig"
            },
            showAll: false,
            leads: new Array(),
          },
          overdue: {
            name: {
              nl: "Te laat",
              en: "Overdue",
              de: "Überfällig"
            },
            showAll: false,
            leads: new Array(),
          },
          scheduledToday: {
            name: {
              nl: "Gepland voor vandaag",
              en: "Scheduled today",
              de: "Heute angesetzt"
            },
            showAll: false,
            leads: new Array(),
          },
          scheduled: {
            name: {
              nl: "Gepland voor later",
              en: "Scheduled",
              de: "Planmäßig"
            },
            showAll: false,
            leads: new Array(),
          },

          noActions: {
            name: {
              nl: "Geen acties",
              en: "No actions",
              de: "Keine Handlungen"
            },
            showAll: false,
            leads: new Array(),
          },
        },
        removeTaskInfo: {
          dialog: false,
          loading: false,
          id: null,
          task: null
        },
        readMoreNewsDialog: {
          show: false,
          title: null,
          intro: null,
          description: null,
          extra: null,
          author: null,
          employee: null,
          dateAdded: null,
        },
        newsViewersDialog: {
          show: false,
          title: null,
          number: null,
          viewers: 0,
        },
        tasksCheck: 0,
        leads: new Array(),
        employees: new Array(),
        chooseType: [
          {
            name: "Nieuwsbericht",
            id: "news",
            selected: true,
            color: "#E79760",
            avatarColor: "#EEB690",
            number: 0,
          },
          {
            name: "Software update",
            id: "update",
            selected: true,
            color: "#72D9C4",
            avatarColor: "#9CE4D5",
            number: 0,
          },
          {
            name: "Tip",
            id: "tip",
            selected: true,
            color: "#83C0EC",
            avatarColor: "#A7D2F1",
            number: 0,
          },
          {
            name: "Actie",
            id: "action",
            selected: true,
            color: "#F8DD65",
            avatarColor: "#FAE792",
            number: 0,
          },
          {
            name: "Succesverhaal",
            id: "casestudie",
            selected: true,
            color: "#F39488",
            avatarColor: "#F6B3AB",
            number: 0,
          },
        ],
        snapshotListenerLeads: null,
        requiredRules: [(v) => !!v || "Dit veld is verplicht"],
        news: new Array(),
        selectedNewsItem: new Object(),
        selectedNewsItemOriginal: new Object(),
        versions: new Array(),
        viewsNumber: 0,
        views: new Array(),
        tasks: new Array(),
        searchCompanies: "",
        companies: new Array(),
        dataloaded: false,
        tasksPeriod: "day",
        selectedBranch: null,
        tasksLoaded: false,
        campaigns: null,
        genders: [
          {
            id: 1,
            name: "Man",
          },
          {
            id: 2,
            name: "Vrouw",
          },
        ],
        downPaymentsLoading: true,
        downPaymentsHighlighed: new Array(),
        downPaymentHeader: [
        {
          text: this.$t('general.date', this.$store.state.locale),
          align: "left",
          sortable: false,
          width: "120px"
        },
        {
          text: this.$t('general.client', this.$store.state.locale),
          align: "left",
          sortable: false,
          width: "150px"
        },
        {
          text: this.$t('general.status', this.$store.state.locale),
          align: "left",
          sortable: false,
          width: "80px"
        },

        {
          text: this.$t('general.amount', this.$store.state.locale),
          align: "right",
          sortable: false,
          width: "80px"
        },

        {
          text: this.$t('general.type', this.$store.state.locale),
          align: "left",
          sortable: false,
          width: "160px"
        },
        {
          text: "",
          align: "left",
          sortable: false,
        },
      ],
      itemsPerPage: 15,
      downPaymentsSelectedPage: 1,

      };
    },

    created() {
      this.loadNews();
      this.loadData();
      window.onscroll = function(ev) {
        console.log("scrolling")
      }

      window.addEventListener('scroll', this.handleScroll);
    },

    beforeDestroy() {
      this.snapshotListenerLeads = null;
    },

    watch: {
      newLeadPhoneInput: {
      handler() {
        if(this.newLeadPhoneInput && this.newLeadPhoneInput.length > 7){
          this.$countries.forEach(language => {
            if(this.newLeadPhoneInput.includes(language.phonePrefix)){
              this.newLeadPhoneRegion = language.countryCode;
            }
          });
          this.checkPhonenumber(this.newLeadPhoneInput, this.newLeadPhoneRegion)
        }
        else{
          this.newLeadPhoneValid = null;
        }
      },
      deep: true
    }
  },

    methods: {

      async loadData(){
        this.leads = new Array();
        this.loadResources();
        await Promise.all([this.loadTasks(this.tasksPeriod), this.loadLeads(), this.loadResources()])
        this.getDownPaymentInformation()
        this.dataloaded = true;
      },

      /**
       * Show importent downpayment information to highlight for employees
       * 
       * Example: Failed downpayments or open downpayments.
       */
      async getDownPaymentInformation(){
        this.downPaymentsLoading = true;
        this.downPaymentsHighlighed = new Array();
        this.downPaymentsSelectedPage = 1;

        // Look back on the last 7 days
        let start = moment().subtract(7,'days').format("YYYY-MM-DDTHH:mm");
        let end = moment().endOf('day').format("YYYY-MM-DDTHH:mm");

        // Check if downpayments are excepted from any branch
        let useOrganisationType = (this.$store.state.activeBranch.connections && this.$store.state.activeBranch.connections.mollie && this.$store.state.activeBranch.connections.mollie.useBranchConnection) ? true : false;;

        await db.collection("prepayments")
        .where('companyId', '==', this.$store.state.activeCompany.id)
        .where('timestamp', '>=', new Date(start))
        .where('timestamp', '<=', new Date(end))
        .get()
        .then(snap => {  
          snap.forEach(doc => {
            if((!useOrganisationType && !doc.data().branchId) || (useOrganisationType && doc.data().branchId == this.$store.state.activeBranch.id)){ // Check only for company level downpayments and downpayments specific for this branch
              let prepayment = doc.data();
              prepayment.id = doc.id;

              if(prepayment.status.type != 'settled'){ // Aanbetaling is nog niet verrekend
                this.downPaymentsHighlighed.push(prepayment);
              }
            }
          });
        })

        for (let i = 0; i < this.downPaymentsHighlighed.length; i++) { // Get client name
          this.downPaymentsHighlighed[i].clientName = await this.getClientData(this.downPaymentsHighlighed[i].clientId)
        }
        
        this.downPaymentsHighlighed.sort((a, b) => b.timestamp.seconds - a.timestamp.seconds); // Sort to show newest first

        this.downPaymentsLoading = false;
      },

      async viewDownPaymentAppointmentInCalendar(bookingSummaryId){
        let booking = null;
        await db.collection("bookingsummary").doc(bookingSummaryId)
        .get()
        .then(doc =>{
          if(doc.exists){
            booking = doc.data()
            booking.id =  bookingSummaryId;
          }
        })

        if(booking){
          this.$router.push({ name: `agenda`, params:{
            type: "gotobooking",
            data: booking,
            open: booking.deleted ? true : false
          }});
        }
      },

      async getClientData(clientId){
        let clientName = null;
        await clientIndex.search(clientId,{ // Get client Data from Algolia
          attributesToRetrieve: ['name', 'surname'], // Get only this fields
          restrictSearchableAttributes: ['objectID'] // Search only on this fields
        })
        .then(({ hits }) => {
          clientName = hits && hits.length > 0 ? `${hits[0].name} ${hits[0].surname}` : "-";
        });
        return clientName;
      },

      checkPhonenumber(input, region){
        const pn = parsePhoneNumber( input, region );
        if(pn.g && pn.g.valid){
          this.newLeadPhoneValid = true;
          this.newLead.clientInfo.phone.phone = pn.g.number;
          this.newLead.clientInfo.phone.type = pn.g.type;
        }
        else{
          this.newLeadPhoneValid = false;
        }
      },

      async loadResources() {
        this.employees = new Array();
        await db.collection("employees")
        .where("branches", "array-contains", this.$store.state.activeBranch.id)
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            let employee = doc.data();
            employee.id = doc.id;
            employee.docId = doc.id;
            this.employees.push(employee);
          });
        });
        return
      },

      openNewTaskDialog() {
        this.newTask.dialog = true;
        this.newTask.date = moment().format("YYYY-MM-DD");
      },

      openNewLeadDialog() {
        this.newLeadPhoneRegion = this.availableLanguages[0].languageCode;
        this.newLead.dialog = true;
        if (!this.campaigns) {
          this.getCampaigns();
        }
      },

      getAssignedEmployee(id) {
        let employee = id ? this.employees.find((element) => element.id == id) : null;
        return employee ? employee.name : null;
      },

      closeNewLeadDialog() {
        this.newLead = {
          loading: false,
          dialog: false,
          feedback: null,
          type: null,
          extra: null,
          campaign: null,
          clientId: null,
          customSource: null,
          clientInfo: {
            firstName: "",
            lastName: "",
            email: "",
            phone: new Object(),
          },
          language: "nl",
        };
        this.newLeadPhoneInput = "";
      },

      getAssignedEmployee(id){  
        let employee = id ? this.employees.find(element => element.id == id): null;
        return employee ? employee.name : null;
      },

      addNewLead(){
        this.newLead.feedback = false;
        this.newLead.loading = true;
        if(this.newLead.clientInfo.firstName && this.newLead.clientInfo.lastName && this.newLead.clientInfo.email && this.newLeadPhoneValid && this.newLead.clientInfo.phone){
          let newLead = new Object();
          this.createLeadClient().then((clientType)=>{
            newLead = {
              companyId: this.$store.state.activeCompany.id,
              branchId: this.$store.state.activeBranch.id,
              campaign: this.newLead.campaign ? this.newLead.campaign.id : null,
              clientId: this.newLead.clientId,
              questions: null,
              extra: this.newLead.extra,
              actions: new Array(),
              language: this.newLead.language,
              type: 'open',
              source: this.newLead.type,
              clientType: clientType,
              clientInfo: {
                email: this.newLead.clientInfo.email,
                firstName: this.newLead.clientInfo.firstName,
                lastName: this.newLead.clientInfo.lastName,
                phone: this.newLead.clientInfo.phone.phone.international
              },
              customSource: this.newLead.customSource,
              created: new Date(),
              updated: new Date()
            };

            db.collection("leads")
            .add(newLead)
            .then(() => {
              newLead.created = {
                seconds: moment().format("X")
              }
              // this.leads.push(newLead);
              this.closeNewLeadDialog()
              //this.sortLeads();
            })
            .catch(error => {
              this.newLead.loading = false;
              this.newLead.feedback = "Lead toevoegen niet gelukt. Probeer het opnieuw."
              console.error("Error adding Lead : ", error);
            });
          });
        } 
        else {
          this.newLead.loading = false;
          this.newLead.feedback = "Vul alle contactgegevens in om de lead toe te voegen.";
        }
      },

      async createLeadClient() {
        console.log("Client Check");
        const configAxiosPOST = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        let leadTag = {
          id: this.newLead.campaign ? this.newLead.campaign.id : null,
          name: this.newLead.campaign ? this.newLead.campaign.name : null,
          companyId: this.$store.state.activeCompany.id,
          type: "lead",
        };

        let jsonOBJ = {
          companyId: this.$store.state.activeCompany.id,
          clientEmail: this.newLead.clientInfo.email,
          clientFirstName: this.newLead.clientInfo.firstName,
          clientLastName: this.newLead.clientInfo.lastName,
          clientPhone: this.newLead.clientInfo.phone ? this.newLead.clientInfo.phone.phone.international: null,
        };

        let tags = new Array();
        let phones = new Array();
        let clientType = "newClient";

        await new Promise((resolve) => {
          axios
            .post(`${this.$store.state.restApi.url}/postClientExist`, jsonOBJ, configAxiosPOST)
            .then((response) => {
              console.log("resonspe lead", response)
              this.newLead.clientInfo.phone.primary = true;
              
              if (response.data.typeExist == 2) { // Client does not exist
                db.collection("clients") // Create Client
                  .add({
                    name: this.newLead.clientInfo.firstName,
                    surname: this.newLead.clientInfo.lastName,
                    emails: [
                      {
                        email: this.newLead.clientInfo.email,
                        primary: true
                      }
                    ],
                    companyId: this.$store.state.activeCompany.id,
                    gender: "",
                    emailSubscriptions: {
                      newsletter: false,
                    },
                    health: {
                      macronutrients: new Object(),
                    },
                    address: new Object(),
                    phones: this.newLead.clientInfo.phone ? [this.newLead.clientInfo.phone] : new Array(),
                    permissions: new Object(),
                    type: "person",
                    tags: [leadTag],
                    language: this.newLead.language,
                  })
                  .then((docRef) => {
                    this.newLead.clientId = docRef.id;
                    resolve();
                  });
              }
              else  { // Client bestaat al
                db.collection("clients")
                  .doc(response.data.clientId)
                  .get()
                  .then((doc) => {
                    tags = doc.data().tags ? doc.data().tags : new Array();
                    tags.push(leadTag);
                    tags = [...new Map(tags.map((item) => [item.id, item])).values()]; // Merge tags and save only uniq tags

                    phones = doc.data().phones ? doc.data().phones : new Array();
                    phones.push(this.newLead.clientInfo.phone);
                    phones = [...new Map(tags.map((item) => [item.phone.e164, item])).values()]; // Merge phones and save only uniq phonenumbers
                  })
                  .then(() => {
                    db.collection("clients").doc(response.data.clientId).set(
                      {
                        tags: tags,
                        phones: phones,
                      },
                      { merge: true }
                    );
                  });
                this.newLead.clientId = response.data.clientId;
                resolve();
                clientType = "existingClient";
              }
            })
            .catch((err) => {
              this.newLead.loading = false;
              this.newLead.feedback = "Lead toevoegen niet gelukt. Probeer het opnieuw.";
              console.error("Error axios postClientExist =>", err);
              resolve();
            });
        });
        return clientType;
      },

      getCampaigns() {
        this.campaigns = new Array();
        db.collection("leadCampaigns")
          .where("companyId", "==", this.$store.state.activeCompany.id)
          .where("deleted.deleted", "==", false)
          .get()
          .then((snap) => {
            snap.forEach((doc) => {
              let campaign = doc.data();
              campaign.id = doc.id;
              this.campaigns.push(campaign);
            });
          })
          .catch((error) => {
            console.error("Error bij laden campaigns: ", error);
          });
      },

      closeNewTaskChecker() {
        this.newTask.overlap.dialog = false;
      },

      addNewTask() {
        if(this.newTask.schedule){
          this.addNewTaskCheck();
        }
        else{
          if(this.newTask.notes){
            this.postNewTask()
          }
          else{
            this.newTask.feedback = "Vul <strong>tijden</strong>, <strong>medewerker</strong> en <strong>omschrijving</strong> in om op te slaan.";
          }
        }
      },

      formatReportNumber(number, digits){
        if(number){
          return new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR", minimumFractionDigits: digits == 0 ? 0 : 2, maximumFractionDigits: digits == 0 ? 0 : 2}).format(Number(number));
        }
        else{
          return "-"
        } 
      },

      formatReportDate(data, oldFormat, newFormat){
        let date = null;
        if(moment(data, oldFormat).isValid()){
          date = moment(data, oldFormat).format(newFormat);
        }
        return date;
      },

      addNewTaskCheck() {
        this.newTask.overlap.checking = true;
        this.newTask.overlap.overlaps = new Array();
        this.newTask.feedback = null;
        let bookings = new Array();

        if (this.newTask.employee && this.newTask.notes && this.newTask.start && this.newTask.end) {
          db.collection("bookingen")
            .where("resourceDocId", "==", this.newTask.employee.id)
            .where("dateBooking", "==", this.newTask.date)
            .where("branch", "==", this.$store.state.activeBranch.id)
            .where("bookingOverride", "==", false)
            .get()
            .then((snapshot) => {
              snapshot.forEach((doc) => {
                let booking = new Object();
                booking.start = doc.data().start;
                booking.end = doc.data().end;
                booking.type = doc.data().type;
                booking.title = doc.data().title;
                booking.subtitle = doc.data().description ? doc.data().description : null;
                booking.color = doc.data().eventColor;
                booking.client = doc.data().clientname;
                booking.source = doc.data().source;
                booking.extra = doc.data().extra ? doc.data().extra : new Object();
                booking.PreferredEmployeeByClient = doc.data().PreferredEmployeeByClient;
                bookings.push(booking);
              });
            })
            .then(() => {
              if (bookings.length > 0) {
                let overlaps = new Array();
                let start = this.newTask.date + "T" + this.newTask.start;
                let end = this.newTask.date + "T" + this.newTask.end;
                bookings.forEach((booking) => {
                  if (
                    booking.start == start ||
                    moment(booking.start).isBetween(moment(start), moment(end)) == true ||
                    moment(start).isBetween(moment(booking.start), moment(booking.end)) == true ||
                    moment(end).isBetween(moment(booking.start), moment(booking.end)) == true
                  ) {
                    overlaps.push(booking);
                  }
                });
                if (overlaps.length > 0) {
                  // console.log("Wel overlap: ", overlaps)
                  this.newTask.overlap.checking = false;
                  this.newTask.overlap.dialog = true;
                  this.newTask.overlap.overlaps = _.sortBy(overlaps, ["start"]);
                } else {
                  // console.log("Geen overlap")
                  this.addNewTask();
                }
              } else {
                // console.log("Geen andere activiteiten deze dag")
                this.addNewTask();
              }
            })
            .catch((error) => {
              this.newTask.feedback = "Er ging iets mis bij de controle voor gelijktijdig geplande onderdelen. Probeer opnieuw.";
              this.newTask.overlap.checking = false;
              console.error("Error bij binnenhalen bookingen voor check:", error);
            });
        }
        else {
          this.newTask.feedback = "Vul <strong>tijden</strong>, <strong>medewerker</strong> en <strong>omschrijving</strong> in om op te slaan.";
          this.newTask.overlap.checking = false;
        }
      },

      async postNewTask() {
        this.newTask.overlap.checking = false;
        let bookingcolor = this.newTask.bookingOverride ? "rgba(161, 161, 161, 0.50)" : "rgb(161, 161, 161)";

        let logData = {
          superAdminAction: true, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "taskAdded",
          actionItem: this.newTask.notes,
          extra:[
            {
              name: "description",
              value: this.newTask.notes
            },
            {
              name: "employee",
              value: this.newTask.employee
            },
            {
              name: "date",
              value: this.newTask.date
            },
          ]
        };

        try {
          await db.collection("bookingen")
          .add({
            resourceDocId: this.newTask.employee ? this.newTask.employee.id : null,
            start: (this.newTask.date && this.newTask.start) ? this.newTask.date + "T" + this.newTask.start : this.newTask.date + "T00:05",
            end: (this.newTask.date && this.newTask.end) ? this.newTask.date + "T" + this.newTask.end : this.newTask.date + "T23:55",
            dateBooking: this.newTask.date ? this.newTask.date : null,
            created: moment().format("YYYY-MM-DD" + "T" + "HH:mm"),
            companyId: this.$store.state.activeCompany.id,
            branchId: this.$store.state.activeBranch.id,
            title: "Taak",
            allDay: this.newTask.allDay ? this.newTask.allDay : false,
            clientname: this.newTask.employee ? this.newTask.employee.name : null,
            description: this.newTask.notes,
            type: "task",
            extra: {
              completed: false,
              completedBy: null,
              completedOn: null,
            },
            eventColor: bookingcolor,
            bookingOverride: this.newTask.bookingOverride, //Overheen boeken - False = Nee, True = Ja
          })
          await this.$createNewLog("info", logData);

          this.loadTasks(this.tasksPeriod);
          this.newTask = {
            feedback: null,
            dialog: false,
            date: moment().format("YYYY-MM-DD"),
            dateDialog: false,
            start: null,
            end: null,
            notes: "",
            overlap: {
              checking: false,
              dialog: false,
              overlaps: new Array(),
            },
            resource: null,
            bookingOverride: false,
          };
        }
        catch (error) {
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
          this.newTask.feedback = this.$t("general.addError", this.$store.state.locale);
        }
      },

      closeNewTaskDialog() {
        this.newTask.dialog = false;
        this.newTask.feedback = null;
      },

      editTask(task){
        bus.$emit("editPause", task.id, this.employees);
      },
      
      removeTaskDialog(task){
        this.removeTaskInfo.dialog = true;
        this.removeTaskInfo.id = task.id;
        this.removeTaskInfo.task = task;
      },

      async removeTask(){
        this.removeTaskInfo.loading = true;

        await db.collection("bookingen")
        .doc(this.removeTaskInfo.id)
        .set({
          dateBooking: "deleted",
          deleted: true,
          deletedInfo: {
            normalDatebooking: this.removeTaskInfo.task.dateBooking,
            normalStart: this.removeTaskInfo.task.start,
            normalEnd: this.removeTaskInfo.task.end,
            deletedby: this.userFirstName + " " + this.userLastName + " (" + this.userCompany + ")", //Dynamisch maken via Rollen systeem
            deletedTime: moment().format("YYYY-MM-DDTHH:mm"),
          }
        }, {merge: true})
        .catch((error) => { // The document probably doesn't exist.
          console.error("Error deleting task: ", error);
        });

        this.removeTaskInfo = {
          dialog: false,
          loading: true,
          id: null,
          task: null
        };
        this.loadTasks(this.tasksPeriod);
      },

      async loadLeads() {
        this.snapshotListenerLeads = db
        .collection("leads")
        .where("type", "!=", "completed")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("branchId", "==", this.$store.state.activeBranch.id)
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            let lead = null;
            lead = change.doc.data();
            lead.id = change.doc.id;
            if (change.type === "added") {
              this.leads.push(lead);
              this.sortLeads();
            }
            if (change.type === "modified") {
              this.updateLeadData(lead);
            }
            if (change.type === "removed") {
              console.log("item verwijderd");
              this.leads = this.leads.filter((item) => {
                return item.id != lead.id;
              });
              this.sortLeads();
            }
          });
        })
        return 
      },

      sortLeads() {
        this.leadTypes.unassigned.leads = new Array();
        this.leadTypes.unscheduled.leads = new Array();
        this.leadTypes.scheduled.leads = new Array();
        this.leadTypes.scheduledToday.leads = new Array();
        this.leadTypes.overdue.leads = new Array();
        this.leadTypes.noActions.leads = new Array();

        this.leads.forEach((lead) => {
          if(lead.deleted && lead.deleted){
            // Skip deleted Leads
          }
          else if (!lead.assignedTo) {
            let actionSpeed = lead.actionSpeed ? lead.actionSpeed : 12;
            if (moment(lead.created.seconds, "X").add(actionSpeed, "hour").format("YYYY-MM-DDTHH:mm") < moment().format("YYYY-MM-DDTHH:mm")) {
              lead.overtime = true;
            }
            this.leadTypes.unassigned.leads.push(lead);
          } else if (!lead.actions || lead.actions.length == 0) {
            this.leadTypes.noActions.leads.push(lead);
          } else {
            if (lead.actions[0].id == "endLeadTracking") {
              this.leadTypes.noActions.leads.push(lead);
            } else if (lead.actions[0].date) {
              if (moment(lead.actions[0].date).format("YYYY-MM-DD") < moment().format("YYYY-MM-DD")) {
                this.leadTypes.overdue.leads.push(lead);
              } else if (moment(lead.actions[0].date).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD")) {
                this.leadTypes.scheduledToday.leads.push(lead);
              } else {
                this.leadTypes.scheduled.leads.push(lead);
              }
            } else {
              this.leadTypes.unscheduled.leads.push(lead);
            }
          }
        });
        // this.leadTypes.unassigned.leads = _.sortBy(this.leadTypes.unassigned.leads, [(o)=> { return o.created.seconds; }]);
        this.leadTypes.unassigned.leads = _.sortBy(this.leadTypes.unassigned.leads, ["created.seconds"]);
        this.leadTypes.unscheduled.leads = _.sortBy(this.leadTypes.unscheduled.leads, ["created.seconds"]);
        this.leadTypes.scheduled.leads = _.sortBy(this.leadTypes.scheduled.leads, ["action[0].date"]);
        // this.leadTypes.scheduled.leads = new Array();
        // this.leadTypes.scheduledToday.leads = new Array();
        // this.leadTypes.overdue.leads = new Array();
        this.leadTypes.noActions.leads = _.sortBy(this.leadTypes.noActions.leads, ["created.seconds"]);
        this.leadTypesPanel = new Array();
        Object.entries(this.leadTypes).forEach(([key, value], index) => {
          if (value.leads.length > 0) {
            this.leadTypesPanel.push(index);
          }
        });
      },

      openLead(lead) {
        bus.$emit("editLead", "home", lead);
      },

      updateLeadData(edittedLead) {
        this.leads = this.leads.map((lead) => {
          return lead.id == edittedLead.id ? edittedLead : lead;
        });
        this.sortLeads();
      },

      async loadTasks(type) {
        this.tasksLoaded = false;
        this.tasksPeriod = type;
        this.tasks = new Array();
        if (type == "day") { // Load all tasks of today   
          await db
          .collection("bookingen")
          .where("type", "==", "task")
          .where("branchId", "==", this.$store.state.activeBranch.id)
          .where("dateBooking", "<=", moment().format("YYYY-MM-DD"))
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              let task = doc.data();
              task.id = doc.id;
              if (!task.extra.completed) {
                this.tasks.push(task);
              }
              else if (task.extra.completed && task.dateBooking == moment().format("YYYY-MM-DD")) {
                this.tasks.push(task);
              }
            });
          })
          .catch((error)=>{
            console.error("Error getting tasks data: ", error)
          })
        }
        else if (type == "thisWeek") { // Load all tasks of this week
          await db
          .collection("bookingen")
          .where("type", "==", "task")
          .where("branchId", "==", this.$store.state.activeBranch.id)
          // .where("dateBooking", ">=", moment().startOf('week').format("YYYY-MM-DD"))
          .where("dateBooking", "<=", moment().endOf("week").format("YYYY-MM-DD"))
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              let task = doc.data();
              task.id = doc.id;
              if (!task.extra.completed) {
                this.tasks.push(task);
              }
              else if (task.extra.completed && task.dateBooking >= moment().startOf("week").format("YYYY-MM-DD")) {
                this.tasks.push(task);
              }
            });
          })
          .catch((error)=>{
            console.error("Error getting bookings task data: ", error)
          })
        }
        else if (type == "nextWeek") { // Load all tasks of next week
          await db
          .collection("bookingen")
          .where("type", "==", "task")
          .where("branchId", "==", this.$store.state.activeBranc.idh)
          .where("dateBooking", ">=", moment().startOf("week").add(1, "week").format("YYYY-MM-DD"))
          .where("dateBooking", "<=", moment().endOf("week").add(1, "week").format("YYYY-MM-DD"))
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              let task = doc.data();
              task.id = doc.id;
              this.tasks.push(task);
            });
          })
          .catch((error)=>{
            console.error("Error getting bookings task  data: ", error)
          })
        }
        else if (type == "completed") { // Load all completed tasks
          await db
          .collection("bookingen")
          .where("type", "==", "task")
          .where("branchId", "==", this.$store.state.activeBranch.id)
          .where("extra.completed", "==", true)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              let task = doc.data();
              task.id = doc.id;
              this.tasks.push(task);
            });
          })
          .catch((error)=>{
            console.error("Error getting bookings task  data: ", error)
          })
        }
        this.tasks.forEach((task) => {
          if (!task.extra.completed) {
            if(!task.dateBooking){
              task.status = "noDate";
            }
            else{
              if (task.dateBooking < moment().format("YYYY-MM-DD")) {
                task.status = "overdue";
              }
              else if (task.dateBooking == moment().format("YYYY-MM-DD")) {
                if (task.start > moment().format("YYYY-MM-DDTHH:mm") && task.end > moment().format("YYYY-MM-DDTHH:mm")) {
                  task.status = "open";
                }
                else if (task.start <= moment().format("YYYY-MM-DDTHH:mm") && task.end >= moment().format("YYYY-MM-DDTHH:mm")) {
                  task.status = "busy";
                }
                else {
                  task.status = "overdue";
                }
              }
              else {
                task.status = "open";
              }
            }
          }
        });

        this.tasks = _.sortBy(this.tasks, [
          function (o) {
            return o.extra.completed;
          },
        ]);
        this.tasksLoaded = true;
      },

      taksAction(task) {   
        if (!task.extra.completed) {
          task.justCompleted = true
        }
        else{
          task.justUncompleted = true
        }
        this.tasksCheck++
        
        setTimeout(() => { // Wait for animation
          if (!task.extra.completed) {
            task.extra.completed = true;
            task.extra.completedBy = `${this.userFirstName} ${this.userLastName}`;
            task.extra.completedOn = new Date();
          }
          else {
            task.extra.completed = false;
            task.extra.completedBy = null;
            task.extra.completedOn = null;
          }
          db.collection("bookingen").doc(task.id).set({
            extra: task.extra,
          }, { merge: true });
          this.tasks = _.sortBy(this.tasks, [
            function (o) {
              return o.extra.completed;
            },
          ]);
        }, 1200);
        
      },

      showNewsViewers(index) {
        this.newsViewersDialog.title = this.newsFiltered[index].title;
        this.newsViewersDialog.viewers = this.newsFiltered[index].views;
        this.newsViewersDialog.number = this.newsFiltered[index].views.length;
        this.newsViewersDialog.show = true;
      },

      readMoreNewsitem(index) {
        this.readMoreNewsDialog.title = this.newsFiltered[index].title;
        this.readMoreNewsDialog.intro = this.newsFiltered[index].intro;
        this.readMoreNewsDialog.description = this.newsFiltered[index].description;
        this.readMoreNewsDialog.extra = this.newsFiltered[index].extra;
        this.readMoreNewsDialog.user = this.newsFiltered[index].user;
        this.readMoreNewsDialog.dateAdded = moment.unix(this.newsFiltered[index].dateAdded.seconds).format("D MMMM YYYY");

        let userName = this.userId;
        let currentUser = {
          user: this.userFirstName + " " + this.userLastName,
          company: this.userCompany,
          companyName: this.userCompany,
          userName: userName,
          dateViewed: new Date(),
        };

        let userIndex = _.findIndex(this.newsFiltered[index].views, function (o) {
          return o.userName == userName;
        });

        if (userIndex < 0) {
          this.newsFiltered[index].views.push(currentUser);
          let viewsArray = { views: this.newsFiltered[index].views };
          db.collection("news").doc(this.newsFiltered[index].id).update(viewsArray);
        }

        if (this.newsFiltered[index].chooseType == "update") {
          this.readMoreNewsDialog.author = this.newsFiltered[index].updateAuthor;
        } else {
          this.readMoreNewsDialog.author = null;
        }
        this.readMoreNewsDialog.show = true;
      },

      likeNewsItem(index) {
        let userIndex = _.indexOf(this.newsFiltered[index].likes, this.userId);
        console.log(userIndex);
        if (userIndex >= 0) { // Has been liked.
          this.newsFiltered[index].likes.splice(userIndex, 1);
          let likesArray = {
            likes: this.newsFiltered[index].likes,
          };
          db.collection("news").doc(this.newsFiltered[index].id).update(likesArray);
        }
        else { // Not yet liked.
          this.newsFiltered[index].likes.push(this.userId);
          let likesArray = {
            likes: this.newsFiltered[index].likes,
          };
          db.collection("news").doc(this.newsFiltered[index].id).update(likesArray);
        }
      },

      editNewsitem(index) { // Edit news item, passing the correct data to props.
        this.editNewsDialog = true;
        this.selectedNewsItem = _.cloneDeep(this.newsFiltered[index]);
        this.selectedNewsItemOriginal = _.cloneDeep(this.selectedNewsItem);
      },

      deleteNewsitem() {
        db.collection("news")
        .doc(this.removeNewsId)
        .delete()
        .then(() => {
          this.removeDialog = false;
          this.loadNews();
        });
      },

      updateNews() {
        // Updating data after adding or editing news.
        this.selectedNewsItem = new Object();
        this.selectedNewsItemOriginal = new Object();
        this.loadNews();
      },

      loadNews() {
        this.news = new Array();
        this.versions = new Array();
        let updateCounter = 0;
        db.collection("news")
          .orderBy("dateAdded", "desc")
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              let news = doc.data();
              news.id = doc.id;
              news.publishDate = moment.unix(doc.data().dateAdded.seconds).format("YYYY-MM-DD");
              this.news.push(news);
              if (doc.data().chooseType == "update") {
                // Loading software versions.
                updateCounter++;
                let softwareUpdate = new Object();
                softwareUpdate.version = doc.data().version;
                softwareUpdate.author = doc.data().updateAuthor;
                softwareUpdate.date = moment.unix(doc.data().dateAdded.seconds).format("D MMMM YYYY");
                if (updateCounter < 6) {
                  this.versions.push(softwareUpdate);
                }
              }
            });
          })
          .catch((error) => {
            console.error("Error bij laden nieuws: ", error);
          })
          .then(() => {
            this.chooseType.map((item) => {
              item.number = 0;
            });

            let chooseType = this.chooseType;

            this.news.map((newsItem) => {
              if (newsItem.chooseType == "news") {
                this.chooseType[_.findIndex(chooseType, { id: "news" })].number++;
              } else if (newsItem.chooseType == "tip") {
                this.chooseType[_.findIndex(chooseType, { id: "tip" })].number++;
              } else if (newsItem.chooseType == "casestudie") {
                this.chooseType[_.findIndex(chooseType, { id: "casestudie" })].number++;
              } else if (newsItem.chooseType == "action") {
                this.chooseType[_.findIndex(chooseType, { id: "action" })].number++;
              } else if (newsItem.chooseType == "update") {
                this.chooseType[_.findIndex(chooseType, { id: "update" })].number++;
              }
            });
          });
      },

      loadLastAddedNews() {
        let updateCounter = this.versions.length;
        db.collection("news")
          .orderBy("dateAdded", "desc")
          .limit(1)
          .get()
          .then((snapshot) => {
            snapshot.forEach((doc) => {
              let news = doc.data();
              news.id = doc.id;
              // version: doc.data().version;
              // title: doc.data().title;
              // description: doc.data().description;
              // intro: doc.data().intro;
              // dateAdded: doc.data().dateAdded;
              // chooseType: doc.data().chooseType;
              // extra: doc.data().extra;
              // updateAuthor: doc.data().updateAuthor;
              // published: doc.data().published;
              // user: doc.data().user;
              this.news.unshift(news);
              if (doc.data().chooseType == "update") {
                // Loading software versions.
                updateCounter++;
                let softwareUpdate = new Object();
                softwareUpdate.version = doc.data().version;
                softwareUpdate.author = doc.data().updateAuthor;
                softwareUpdate.date = moment(doc.data().dateAdded.seconds).format("MMMM Do YYYY, h:mm:ss a");
                // Only load in the last 5 software vesions.
                if (updateCounter < 6) {
                  this.versions.push(softwareUpdate);
                }
              }
              console.log("last news item succesfully loaded");
            });
          })
          .then(() => {
            this.chooseType.map((item) => {
              item.number = 0;
            });

            let chooseType = this.chooseType;

            this.news.map((newsItem) => {
              if (newsItem.chooseType == "news") {
                this.chooseType[_.findIndex(chooseType, { id: "news" })].number++;
              } else if (newsItem.chooseType == "tip") {
                this.chooseType[_.findIndex(chooseType, { id: "tip" })].number++;
              } else if (newsItem.chooseType == "casestudie") {
                this.chooseType[_.findIndex(chooseType, { id: "casestudie" })].number++;
              } else if (newsItem.chooseType == "action") {
                this.chooseType[_.findIndex(chooseType, { id: "action" })].number++;
              } else if (newsItem.chooseType == "update") {
                this.chooseType[_.findIndex(chooseType, { id: "update" })].number++;
              }
            });
          });
      },

      selectChip(chipId) {
        // Turning chips on or off.
        this.chooseType.map((item) => {
          if (chipId == item.id) {
            if (item.selected == true) {
              item.selected = false;
            } else if (item.selected == false) {
              item.selected = true;
            }
          }
        });
      },
    },

    computed: {

      downPaymentsSelectorPages(){
        return Math.ceil(this.downPaymentsHighlighed.length / this.itemsPerPage)
      },

      availableLanguages(){
      let availableLanguages = this.$countries.filter(language => { // Filter rich languages data from company languages
        if(this.$store.state.activeCompany.languages.includes(language.id)){ return language.id }
      });
      // Show languages in the same order as company languages
      return availableLanguages.sort((a, b) => this.$store.state.activeCompany.languages.indexOf(a.id) - this.$store.state.activeCompany.languages.indexOf(b.id)); 
    },

      taskDateReadable() {
        return this.newTask.date ? moment(this.newTask.date).format("D MMMM YYYY") : "";
      },

      filteredTasks(){
        this.tasksCheck++
        let tasks = {
          open: new Array(),
          completed: new Array(),
        };
        let connectedEmployee = this.$store.state.connectedEmployee

        this.tasks.forEach(task=>{
          if(this.$store.state.connectedEmployee){
            if(this.$store.state.connectedEmployee.userRoleNumber < 4 || task.resourceDocId == this.$store.state.connectedEmployee.id){
              if(task.extra.completed){
                tasks.completed.push(task)
              }
              else{
                tasks.open.push(task)
              }
            }
          }
          else{
            if(!this.$store.state.connectedEmployee || (this.$store.state.connectedEmployee.userRoleNumber < 5) || (this.$store.state.connectedEmployee.userRoleNumber > 4 && task.resourceDocId == this.$store.state.connectedEmployee.id)){
              if(task.extra.completed){
                tasks.completed.push(task)
              }
              else{
                tasks.open.push(task)
              }
            }
          }
        })
        tasks.completed = tasks.completed.sort((a,b) => b.extra.completedOn.seconds - a.extra.completedOn.seconds); 
        return tasks
      },


      addTasks() {
        if (this.$store.state.userRules) {
          if (this.$store.state.userRules.free && _.indexOf(this.$store.state.userRules.free, "addTasks") >= 0) {
            return true; // Rule found in free
          } else {
            // Check if some user is logged in
            if (this.$store.state.connectedEmployee) {
              // Employee is logged in
              if (this.$store.state.activeUserRoleNumber < 4) {
                return true; // userRole OVERRULES the rules
              }
              else {
                if (this.$store.state.userRules.restricted && _.indexOf(this.$store.state.userRules.free, "addTasks") >= 0) {
                  return true; // Rule found in restricted
                } else {
                  return false;
                } // Rule NOT found in restricted
              }
            } else {
              return false;
            } // Employee is NOT logged in
          }
        } else {
          return true;
        } // No branch is logged in
      },
      completeTasks() {
        if (this.$store.state.userRules) {
          if (this.$store.state.userRules.free && _.indexOf(this.$store.state.userRules.free, "completeTasks") >= 0) {
            return true; // Rule found in free
          } else {
            // Check if some user is logged in
            if (this.$store.state.connectedEmployee) {
              // Employee is logged in
              if (this.$store.state.activeUserRoleNumber < 4) {
                return true; // userRole OVERRULES the rules
              }
              else {
                if (this.$store.state.userRules.restricted && _.indexOf(this.$store.state.userRules.free, "completeTasks") >= 0) {
                  return true; // Rule found in restricted
                } else {
                  return false;
                } // Rule NOT found in restricted
              }
            } else {
              return false;
            } // Employee is NOT logged in
          }
        } else {
          return true;
        } // No branch is logged in
      },


      newsFiltered() {
        // Only show chips when they are turned on. (you can only see unpublished news with superadmin priviliges).
        let newsItems = new Array();
        this.news.map((newsItem) => {
          if (this.chooseType[_.findIndex(this.chooseType, { id: newsItem.chooseType })].selected) {
            newsItems.push(newsItem);
          }
        });
        return newsItems;
      },
      chips() {
        // Giving chips the right color when it is either turned on or off.
        let chipsItems = new Array();
        this.chooseType.forEach((item) => {
          if (item.number > 0) {
            if (item.selected == false) {
              item.newColor = "#e0e0e0";
              item.newAvatarColor = "#f1f1f1";
            } else {
              item.newColor = item.color;
              item.newAvatarColor = item.avatarColor;
            }
            chipsItems.push(item);
          }
        });
        return chipsItems;
      },
    },
  };
</script>

<style scoped>
  .container.grid-list-xl .layout .flex {
    padding: 12px;
  }

  .v-chip:focus:not(.v-chip--selected):after {
    display: none;
  }

  /* newsitem styling */
  .newsitem-edit {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 99;
    cursor: default;
  }

  .extra-comments {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 99;
    cursor: default;
  }

  .newsitem-read-more {
    position: absolute;
    left: 15px;
    bottom: 25px;
    font-family: "Sofia Pro", san-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.4px;
    cursor: pointer;
    text-decoration: underline;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .newsitem-read-more:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  .news-title {
    font-weight: 400;
    font-size: 28px;
    color: var(--v-text-base);
  }

  .newsitem-wrapper.unpublished {
    opacity: 0.3;
  }

  .newsitem-wrapper h3 {
    font-family: "Sofia Pro", san-serif;
    font-weight: 600;
    font-size: 18px;
    color: var(--v-text-base);
    line-height: 21px;
    margin: 0 0 20px 0;
  }

  .newsitem-content-wrapper {
    font-family: "Sofia Pro", san-serif;
    font-weight: 300;
    font-size: 15px;
    padding: 15px 15px 60px 15px;
    color: var(--v-text-base);
    line-height: 20px;
  }

  .newsitem-image-wrapper {
    position: relative;
    width: 100%;
    height: 200px;
    cursor: pointer;
    text-decoration: underline;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .newsitem-image-wrapper .version-number {
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    color: white;
    width: 100%;
    font-size: 68px;
    margin: 50px 0;
    text-align: center;
    display: inline-block;
    z-index: 99;
  }

  .newsitem-update-image-update {
    width: 100%;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .newsitem-update-image-update-overlay {
    width: 100%;
    height: 200px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.85;
    z-index: 9;
  }

  .newsitem-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px;
    left: 0;
  }

  .favorite-footer {
    position: absolute;
    right: 15px;
    top: 0;
  }
  /* newsitem styling */

  /* readmore styling */
  .readmore-intro-header {
    padding: 0 0 15px 0;
  }
  .readmore-intro-header-date,
  .readmore-intro-header-author {
    display: block;
    padding: 0 0 3px 0;
  }

  .readmore-intro {
    padding: 0 0 15px 0;
  }

  .readmore-content {
    margin: 0 0 15px 0;
  }

  .readmore-content-extra {
    padding: 8px;
    background-color: #eee;
    margin: 0 0 15px 0;
  }
  .v-card__text {
    margin-top: 10px;
    font-size: 1rem;
  }
  /* readmore styling */


  .lead-type-header {
    font-size: 14px;
    font-weight: bold;
    display: inline-block;
    float: left;
  }

  .lead-type-counter {
    display: inline-block;
    background-color: var(--primary-color);
    border-radius: 5px;
    color: #fff;
    margin: 0 0 0 5px;
    padding: 2px 4px;
    font-size: 11px;
    font-weight: bold;
    float: left;
  }
  @keyframes strike{
    0%   { width : 0; }
    100% { width: 100%; }
  }
  @keyframes unstrike{
    0%   { width : 100%; }
    100% { width: 0; }
  }
  .strike-text{
    text-decoration: line-through
  }
  .strike-task,
  .unstrike-task {
    position: relative;
    background-color: 'red'
  }
  .strike-task::after,
  .unstrike-task::after  {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: black;
    animation-name: strike;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    animation-fill-mode: forwards; 
  }
  .unstrike-task::after {
    animation-name: unstrike;
  }
</style>
