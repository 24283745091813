<script>
import db from "../../firebase/init";
import { bus } from "../../main";
import moment from "moment";
import { getCorrelatedModifiers } from "../../modules/correlatedModifiers";
import treatmentSelector from "@/components/elements/treatmentSelector.vue";

export default {
  name: "ModifierForm",
  props: {
    loadedModifiers: Array,
    filterItemActions: Array,
    filterProductenTypes: Array,
    filterTreatmentsTypes: Array,
    modifierTypes: Array,
    typeDiscountTypes: Array,
    numberLimitTypes: Array,
    discountActionTypes: Array,
    quantityDiscountTypes: Array,
    modifiers: Array,
  },

  components: {
    treatmentSelector: treatmentSelector,
  },

  data() {
    return {
      editMode: false,
      showDialog: false,
      modifierIdCopy: false,
      feedback: null,
      modifierDateLimitEndDialog: false,
      modifierDateLimitStartDialog: false,
      now: null,
      treatments: [],
      products: [],
      productCategories: [],
      productsSuppliers: [],
      propductDataLoaded: false,
      treatmentDataLoaded: false,

      searchTreatments: null,
      searchProducts: null,
      
      feedbackDiscounts: new Array(),
      typesModifier: [
        { id: "defaultModifier", name: "Algemene korting" },
        { id: "membershipModifier", name: "Abonnementskorting" },
        { id: "loyaltyModifier", name: "Spaarpunten" },
        // { id: "prePaymentModifier", name: "Voorafbetaling" },
      ],
      editModifier: {
        branches: new Array(),
        numberLimit: new Object(),
        useLimit: new Object(),
        barcode: new Object(),
        dateLimit: new Object(),
        typeDiscount: {
          quantityRules: new Array(),
        },
        filterProducts: {
          items: new Array(),
        },
        filterTreatments: {
          items: new Array(),
        },
      },
      editModifierOriginal: new Object()
    };
  },

  mounted() {
    this.now = moment().format("YYYY-MM-DD");
    bus.$on("addModifierModal", () => {
      this.resetModifierDialog();
      this.editMode = false;
      this.showDialog = true;
      this.getProductData();
      this.getTreatmentData();
    });

    bus.$on("editModifierModal", (item) => {
      this.resetModifierDialog();
      this.editMode = true;
      this.showDialog = true;
      this.editModifier = item;
      this.editModifierOriginal = _.cloneDeep(this.editModifier);
      this.getProductData();
      this.getTreatmentData();
    });
  },

  methods: {
    closeModifierDialog() {
      this.showDialog = false;
    },

    /**
     * Clear all data before starting with new or existing modifier
     */
    resetModifierDialog(){
      this.editModifier = {
        name: "",
        automatic: false,
        description: "",
        loyaltyPointsNeeded: 0,
        branches: [],
        active: true,
        modifierGroup: "defaultModifier",
        combinationLimit: false,

        numberLimit: {
          number: null,
          numberUsed: null,
          type: "max",
          active: false,
        },
        useLimit: {
          active: false,
          number: 0,
        },
        barcode: {
          type: 'undefined',
          code: 0,
        },
        dateLimit: {
          startDate: null,
          endDate: null,
          active: false,
        },
        type: "all",
        typeModifier: "default",
        typeDiscount: {
          type: "fixedAmount",
          uniqueItems: true,
          buyQuantity: null,
          getQuantity: null,
          discountType: "percentage",
          quantityRules: [],
          discountValue: null,
        },

        filterProducts: {
          type: "specific",
          itemsAction: "include",
          items: [],
          active: false,
        },
        filterTreatments: {
          type: "specific",
          itemsAction: "include",
          items: [],
          active: false,
        },
      };
    },

    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }

      navigator.clipboard.writeText(text).then(() => {
        this.modifierIdCopy = true
        setTimeout(() => {
          this.modifierIdCopy = false
        }, 1000);

      }, function (err) {
        console.error('Async: Could not copy text: ', err);
      });
    },


    checkTypeModifier() {
      //When its not a defaultDiscount but a "membershipDiscount instead or something else.."
      if (this.editModifier.modifierGroup !== "defaultModifier" || this.editModifier.modifierGroup !== "loyaltyModifier") {

        this.editModifier.automatic = false;

        this.editModifier.dateLimit = {
          active: false,
          endDate: null,
          startDate: null,
        }

        this.editModifier.numberLimit = {
          active: false,
          number: null,
          numberUsed: null,
          type: "max"
        }

        this.editModifier.barcode = {
          code: 0,
          type: "undefined"
        }

        return false;

      } else {
        return true;
      }

    },

    checkModifiers() {
      this.feedbackDiscounts = new Array();
      //Skip if its in time ranges     
      const correlatedModifiersData = getCorrelatedModifiers(this.products, this.treatments, this.modifiers, this.editModifier);

      if (correlatedModifiersData.correlatedProductModifiersId.length !== 0) {

        switch (correlatedModifiersData.productsFilterType) {
          case "specific":
            this.feedbackDiscounts.push({
              message: `${correlatedModifiersData.specificProductsItemsAction === "include" ? "Dezelfde" : "Andere"} Specifieke product(en) zijn al reeds toegevoegd bij de promotie: ${correlatedModifiersData.correlatedProductModifiersName.join(" en ")}`,
              icon: "mdi-comment-processing",
              color: "primary",
              type: "warning"
            })
            break;

          case "categories":
            this.feedbackDiscounts.push({
              message: `${correlatedModifiersData.specificProductsItemsAction === "include" ? "Dezelfde" : "Andere"} Categorie product(en) zijn al reeds toegevoegd bij de promotie: ${correlatedModifiersData.correlatedProductModifiersName.join(" en ")}`,
              icon: "mdi-comment-processing",
              color: "primary",
              type: "warning"
            })
            break;

          case "brands":
            this.feedbackDiscounts.push({
              message: `${correlatedModifiersData.specificProductsItemsAction === "include" ? "Dezelfde" : "Andere"} merken m.b.t. producten zijn al reeds toegevoegd bij de promotie: ${correlatedModifiersData.correlatedProductModifiersName.join(" en ")}`,
              icon: "mdi-comment-processing",
              color: "primary",
              type: "warning"
            })
            break;

          default:
            break;
        }

      }

      if (correlatedModifiersData.correlatedTreatmentModifiersId.length !== 0) {

        switch (correlatedModifiersData.treatmentsFilterType) {
          case "specific":
            this.feedbackDiscounts.push({
              message: `${correlatedModifiersData.specificTreatmentsItemsAction === "include" ? "Dezelfde" : "Andere"} specifieke diensten(en) zijn al reeds toegevoegd bij de promotie: ${correlatedModifiersData.correlatedTreatmentModifiersName.join("en ")}`,
              icon: "mdi-comment-processing",
              color: "primary",
              type: "warning"
            })
            break;

          case "categories":
            this.feedbackDiscounts.push({
              message: `${correlatedModifiersData.specificTreatmentsItemsAction === "include" ? "Dezelfde" : "Andere"} categorie diensten(en) zijn al reeds toegevoegd bij de promotie: ${correlatedModifiersData.correlatedTreatmentModifiersName.join("en ")}`,
              icon: "mdi-comment-processing",
              color: "primary",
              type: "warning"
            })
            break;


          default:
            break;
        }

      }
    },

    resetDateLimit() {
      if (!this.editModifier.dateLimit.active) {
        this.editModifier.dateLimit.endDate = null;
        this.editModifier.dateLimit.startDate = null;
      }
    },

    resetNumberLimit() {
      if (!this.editModifier.numberLimit.active) {
        this.editModifier.numberLimit.number = null;
        this.editModifier.numberLimit.type = "max";
      }
    },

    resetModifierType() {
      if (this.editModifier.type == "product") {
        if (this.products.length === 0) {
          this.getProductData();
        }
        this.editModifier.filterTreatments.active = false;
        this.editModifier.filterTreatments.type = "specific";
        this.editModifier.filterTreatments.items = [];
      } else if (this.editModifier.type == "treatment") {
        if (this.treatments.length === 0) {
          this.getTreatmentData();
        }
        this.editModifier.filterProducts.active = false;
        this.editModifier.filterProducts.type = "specific";
        this.editModifier.filterProducts.items = [];
      } else if (this.editModifier.type == "all") {
        if (this.products.length === 0) {
          this.getProductData();
        }
        if (this.treatments.length === 0) {
          this.getTreatmentData();
        }
        this.editModifier.filterTreatments.active = false;
        this.editModifier.filterTreatments.type = "specific";
        this.editModifier.filterTreatments.items = [];
      }
    },

    resetDiscountType() {
      this.editModifier.typeDiscount.buyQuantity = null;
      this.editModifier.typeDiscount.getQuantity = null;
      this.editModifier.typeDiscount.discountType = "free";
      this.editModifier.typeDiscount.quantityRules = [];
      this.editModifier.typeDiscount.uniqueItems = true;
      this.editModifier.typeDiscount.repetitive = false;

      if (this.editModifier.typeDiscount.type == "quantityDiscount") {
        this.editModifier.typeDiscount.discountType = "percentage";
        this.addNewQuantityDiscount();
        this.addNewQuantityDiscount();
      }
      this.editModifier.useLimit.active = false;
      this.resetUseNumberLimit();
    },

    resetUseNumberLimit() {
      this.editModifier.useLimit.number = null;
    },

    removeQuantityDiscount(index) {
      this.editModifier.typeDiscount.quantityRules.splice(index, 1);
    },

    addNewQuantityDiscount() {
      let newQuantityDiscount = {
        number: null,
        discount: null,
      };
      this.editModifier.typeDiscount.quantityRules.push(newQuantityDiscount);
    },

    resetProductDiscount() {
      if (this.editModifier.filterProducts.active) {
        this.editModifier.filterProducts.items = [];
        this.getProductData();
      } else {
        this.editModifier.filterProducts.items = [];
      }
    },

    resetTreatmentDiscount() {
      if (this.editModifier.filterTreatments.active) {
        this.editModifier.filterTreatments.items = [];
        this.getTreatmentData();
      } else {
        this.editModifier.filterTreatments.items = [];
      }
    },

    async getTreatmentData() {
      if (!this.treatmentDataLoaded) {
        Promise.all([this.getTreatments(), this.getTreatmentTypes()]).then(() => {
          this.treatmentDataLoaded = true;
        });
      }
      this.checkModifiers();
    },

    async getProductData() {
      if (!this.propductDataLoaded) {
        Promise.all([this.getProducts(), this.getProductSuppliers(), this.getProductCategories()]).then(() => {
          this.propductDataLoaded = true;
        });
      }
      this.checkModifiers();
    },

    async getTreatments() {
      await db
        .collection("treatments")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("treatmentActive", "==", true)
        .get()
        .then((snap) => {
          this.treatments = [];
          snap.forEach((doc) => {
            let treatment = doc.data();
            treatment.id = doc.id;
            this.treatments.push(treatment);
          });
        })
        .catch((err) => {
          console.error("Error getting Data", err);
        });
      return;
    },

    async getTreatmentTypes() {
      await db
        .collection("treatmentTypes")
        //.where("companyId", "==", this.$store.state.activeCompany.id)
        .get()
        .then((snap) => {
          this.treatmentTypes = [];
          snap.forEach((doc) => {
            let treatmentType = doc.data();
            treatmentType.id = doc.id;
            this.treatmentTypes.push(treatmentType);
          });
        })
        .catch((err) => {
          console.error("Error getting Data", err);
        });
      return;
    },

    async getProducts() {
      await db
        .collection("products")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("active", "==", true)
        .get()
        .then((snap) => {
          this.products = [];
          snap.forEach((doc) => {
            let product = doc.data();
            product.id = doc.id;
            this.products.push(product);
          });
        })
        .catch((err) => {
          console.error("Error getting Data", err);
        });
      return;
    },

    async getProductSuppliers() {
      await db
        .collection("productSuppliers")
        .where("companies", "array-contains", this.$store.state.activeCompany.id)
        .get()
        .then((snap) => {
          this.productsSuppliers = [];
          snap.forEach((doc) => {
            let productsSupplier = doc.data();
            productsSupplier.id = doc.id;
            this.productsSuppliers.push(productsSupplier);
          });
        })
        .catch((err) => {
          console.error("Error getting Data", err);
        });
      return;
    },

    async getProductCategories() {
      await db
        .collection("productCategories")
        // .where("companies", "contains", this.$store.state.activeCompany.id)
        .get()
        .then((snap) => {
          this.productCategories = [];
          snap.forEach((doc) => {
            let productCategory = doc.data();
            productCategory.id = doc.id;
            this.productCategories.push(productCategory);
          });
        })
        .catch((err) => {
          console.error("Error getting Data", err);
        });
      return;
    },

    async saveModifier() {
      this.feedback = null;
      let numberLimit = false;
      let dateLimit = false;
      let quantityRulesValid = true;
      let quantityRulesNumberValid = true;
      let buyXYValid = true;
      let discountNumberValid = true;

      if (this.editModifier.dateLimit.active) {
        if (this.modifierEnddate.formatted && this.modifierStartdate.formatted) {
          dateLimit = true;
        }
      } else {
        dateLimit = true;
      }

      if (this.editModifier.numberLimit.active) {
        if (this.editModifier.numberLimit.number) {
          numberLimit = true;
        }
      } else {
        numberLimit = true;
      }

      if (this.editModifier.typeDiscount.type == "quantityDiscount") {
        // Bundelkorting
        if (this.editModifier.typeDiscount.quantityRules.length > 0) {
          this.editModifier.typeDiscount.quantityRules.forEach((item) => {
            if (!item.number || !item.discount) {
              quantityRulesValid = false;
            }
          });
        }
      }

      if (this.editModifier.typeDiscount.type == "buyXX" || this.editModifier.typeDiscount.type == "buyXY") {
        // Koop X krijg X
        if (!this.editModifier.typeDiscount.buyQuantity || !this.editModifier.typeDiscount.getQuantity) {
          buyXYValid = false;
        }
        if (this.editModifier.typeDiscount.discountType != "free") {
          if (!this.editModifier.typeDiscount.discountValue) {
            buyXYValid = false;
          }
        }
      }

      if (this.editModifier.typeDiscount.type == "fixedAmount" || this.editModifier.typeDiscount.type == "percentage") {
        if (!this.editModifier.typeDiscount.discountValue) {
          discountNumberValid = false;
        }
      }

      if (this.editModifier.name && numberLimit && dateLimit && discountNumberValid && quantityRulesValid && buyXYValid) {
        let logData = {
          superAdminAction: false, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "modifierAdded",
          actionItem: this.editModifier.name,
          extra: [
            {
              name: "name",
              value: this.editModifier.name
            },
            {
              name: "description",
              value: this.editModifier.description
            },
            {
              name: "branches",
              value: this.editModifier.branches
            },
            {
              name: "type",
              value: this.editModifier.type
            },
            {
              name: "discountType",
              value: `${this.editModifier.typeDiscount.type} (${this.editModifier.typeDiscount.discountValue})`
            },
          ]
        };

        const modifierData = {
          name: this.editModifier.name,
          companyId: this.$store.state.activeCompany.id,
          description: this.editModifier.description,
          branches: this.editModifier.branches,
          active: this.editModifier.active,
          loyaltyPointsNeeded: this.editModifier.loyaltyPointsNeeded,
          modifierGroup: this.editModifier.modifierGroup,
          automatic: this.editModifier.automatic,
          combinationLimit: this.editModifier.combinationLimit,
          numberLimit: this.editModifier.numberLimit,
          useLimit: this.editModifier.useLimit,
          dateLimit: {
            startDate: this.modifierStartdate.formatted,
            endDate: this.modifierEnddate.formatted,
            active: this.editModifier.dateLimit.active,
          },
          barcode: this.editModifier.barcode,
          type: this.editModifier.type,
          typeDiscount: this.editModifier.typeDiscount,
          filterProducts: this.editModifier.filterProducts,
          filterTreatments: this.editModifier.filterTreatments,
          deleted: false,
        }

        try {
          if (this.editMode) {
            await db.collection("modifiers")
              .doc(this.editModifier.id)
              .update(modifierData)
          }
          else {
            await db.collection("modifiers")
              .add(modifierData)
          }



          await this.$createNewLog("info", logData);
          this.closeModifierDialog();
          this.$emit("updateData");
          console.log("Modifier saved");

          //sleep 500ms
          await new Promise(resolve => setTimeout(resolve, 300));
          this.editMode = false;
          this.editModifier = {
            name: "",
            description: "",
            branches: [],
            automatic: false,
            modifierGroup: "defaultModifier",
            loyaltyPointsNeeded: 0,
            useLimit: {
              active: false,
              number: 0,
            },
            barcode: {
              type: 'undefined',
              code: 0,
            },
            combinationLimit: false,
            active: true,
            numberLimit: {
              number: null,
              numberUsed: null,
              type: "max",
              active: false,
            },
            dateLimit: {
              startDate: null,
              endDate: null,
              active: false,
            },
            type: "all",
            typeDiscount: {
              type: "fixedAmount",
              discountValue: null,
            },
            filterProducts: {
              type: "specific",
              itemsAction: "include",
              itemsAction: [],
              items: [],
              active: false,
            },
            filterTreatments: {
              type: "specific",
              itemsAction: "include",
              items: [],
              active: false,
            },
          };
        }
        catch (error) {
          console.error("Error modifier document save: ", error);
          logData.extra.push({ name: "error", value: JSON.stringify(error) })
          await this.$createNewLog("error", logData);
        }
      }
      else {
        if (!quantityRulesValid) {
          this.feedback = "Niet alle kortingsregels van de Bundelkorting zijn ingevoerd.";
        } else if (!buyXYValid) {
          this.feedback = "Vul alle velden voor dit kortingstype in om de korting toe te voegen.";
        } else {
          this.feedback = "Vul naam en kortingtype in om de korting toe te voegen.";
        }
      }
    },
  },
  computed: {
    filteredProducts(){
      if (this.searchProducts && this.searchProducts.length > 0){
        return this.products.filter(product => {
          return product.name && product.name.toLowerCase().includes(this.searchProducts.toLowerCase());
        });
      }
      else {
        return this.products;
      }
    },

    filteredTreatments(){
      if (this.searchTreatments && this.searchTreatments.length > 0){
        return this.treatments.filter(treatment => {
          return treatment.treatmentTitle && (treatment.treatmentTitle.nl?.toLowerCase().includes(this.searchTreatments.toLowerCase()) || treatment.treatmentTitle.en?.toLowerCase().includes(this.searchTreatments.toLowerCase()));
        });
      }
      else {
        return this.treatments;
      }
    },

    changed(){
      return _.isEqual(this.editModifier, this.editModifierOriginal) == false ? true : false;
    },

    modifierStartdate() {
      let date = {
        readable: "",
        formatted: null,
      };

      if (this.editModifier.dateLimit.startDate) {
        date.readable = moment(this.editModifier.dateLimit.startDate).format("D MMMM YYYY");
        date.formatted = moment(this.editModifier.dateLimit.startDate).format("YYYY-MM-DDTHH:mm");
      }
      return date;
    },
    modifierEnddate() {
      let date = {
        readable: "",
        formatted: null,
      };

      if (this.editModifier.dateLimit.endDate) {
        date.readable = moment(this.editModifier.dateLimit.endDate).format("D MMMM YYYY");
        date.formatted = moment(this.editModifier.dateLimit.endDate).format("YYYY-MM-DDTHH:mm");
      }
      return date;
    },

    discountInfo() {
      if (this.editModifier.typeDiscount.type == "fixedAmount" && this.editModifier.typeDiscount.type == "percentage") {
        return {
          prefix: null,
          suffix: "%",
          label: "Kortingspercentage",
        };
      } else if (this.editModifier.typeDiscount.type == "fixedAmount" && this.editModifier.typeDiscount.discountType == "amount") {
        return {
          prefix: "€",
          suffix: null,
          label: "Kortingsbedrag",
        };
      } else if (this.editModifier.typeDiscount.type == "buyXX" || this.editModifier.typeDiscount.type == "buyXY") {
        if (this.editModifier.typeDiscount.discountType == "percentage") {
          return {
            prefix: null,
            suffix: "%",
            label: "Kortingspercentage",
          };
        } else if (this.editModifier.typeDiscount.discountType == "amount") {
          return {
            prefix: "€",
            suffix: null,
            label: "Kortingsbedrag",
          };
        }
      } else if (this.editModifier.typeDiscount.type == "quantityDiscount") {
        if (this.editModifier.typeDiscount.discountType == "percentage") {
          return {
            prefix: null,
            suffix: "%",
            label: "Kortingspercentage",
          };
        } else if (this.editModifier.typeDiscount.discountType == "amount") {
          return {
            prefix: "€",
            suffix: null,
            label: "Kortingsbedrag",
          };
        }
      } else {
        return {
          prefix: null,
          suffix: null,
          label: "-",
        };
      }
    },
  },
};
</script>
<style>
.discount-fields-wrapper {
  width: 100%;
}

.discount-fields-wrapper:after {
  display: block;
  clear: both;
  content: "";
}

.discount-fields-input {
  width: calc(100% - 40px);
  float: left;
}

.discount-fields-remove {
  width: 40px;
  float: left;
}
</style>

<template>
  <v-dialog v-model="showDialog" persistent scrollable max-width="600px"
    :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2"
        :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="editMode"> {{ $t('modifier.editModifier', $store.state.locale) }}</h3>
        <h3 v-else> {{ $t('modifier.newModifier', $store.state.locale) }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeModifierDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider v-if="feedback || (feedbackDiscounts && feedbackDiscounts.length > 0)"></v-divider>

      <v-alert 
        v-if="feedback" 
        class="ma-0 pa-2" 
        style="border-radius: 0" 
        icon="mdi-alert" 
        dark
        :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning"
        >
        {{ feedback }}
      </v-alert>
      
      <v-alert
       
        v-for="(feedbackDiscount, index) in feedbackDiscounts" 
        :key="feedback + index" 
        dense
        prominent
        text
        dark
        :icon="feedbackDiscount.icon" 
        :type="feedbackDiscount.type" 
        :color="$themes[$store.state.companyTheme][$store.state.themeModus][feedbackDiscount.color]" 
      
        elevation="0" 
        class="ma-2 mx-3 mb-2 pa-2">
        {{ feedbackDiscount.message }}
      </v-alert>

      <v-divider></v-divider>

      <v-card-text class="ma-0">

        <v-card class="mt-2" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].developer" v-if="editMode && $store.state.userRoleNumber == 0 && !$store.state.demoMode">
        <v-btn @click="copyTextToClipboard(editModifier.id)" class="ma-1 mb-2" small
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
          style="color: #ffffff; text-transform: none;"><strong>{{ editModifier.id }}</strong></v-btn>
        <div style="display: inline-block" v-if="modifierIdCopy == false" class="pa-2 copy-tag-url"
          @click="copyTextToClipboard(editModifier.id)"><v-icon small color="#333">mdi-content-copy</v-icon>Kopieër
          DocId</div>
        <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied">DocId gekopieërd</div>
      </v-card>

        <v-form ref="ModifierForm" lazy-validation>
          <v-text-field label="Naam" dense v-model="editModifier.name" required outlined class="mt-4"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"
            hide-details></v-text-field>

          <v-textarea label="Omschrijving" dense v-model="editModifier.description" required outlined rows="2"
            class="mt-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"
            hide-details></v-textarea>

          <v-select dense clearable multiple chips deletable-chips small-chips :items="$store.state.activeUserBranches"
            item-text="name" item-value="id" label="Vestigingen" v-model="editModifier.branches"
            v-on:change="checkModifiers()" outlined class="mt-4"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"
            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" hide-details></v-select>

          <v-select class="mt-4" :items="typesModifier" item-value="id" :label="$t(
            'modifier.typeModifier',
            $store.state.locale
          )" v-model="editModifier.modifierGroup" v-on:change="checkTypeModifier()" outlined dense
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" hide-details>
            <template slot="item" slot-scope="data">
              {{
                $t(
                  `modifier.${data.item.id}`,
                  $store.state.locale
                )
              }}
            </template>

            <template slot="selection" slot-scope="data">
              {{
                $t(
                  `modifier.${data.item.id}`,
                  $store.state.locale
                )
              }}
            </template>

          </v-select>

          <v-text-field v-show="editModifier.modifierGroup === 'loyaltyModifier'" prepend-inner-icon="mdi-heart-circle"
            label="Aantal benodigde punten" v-model.number="editModifier.loyaltyPointsNeeded" outlined class="mt-4"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" background-color="background"
            hide-details>
          </v-text-field>


          <v-text-field
            v-show="editModifier.modifierGroup === 'defaultModifier' || editModifier.modifierGroup === 'loyaltyModifier'"
            prepend-inner-icon="mdi-barcode" label="Barcode" v-model.number="editModifier.barcode.code" outlined
            class="mt-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            background-color="background" hide-details></v-text-field>


          <v-switch
            v-show="editModifier.modifierGroup === 'defaultModifier' || editModifier.modifierGroup === 'loyaltyModifier'"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 mt-4 py-2 ml-2"
            v-model="editModifier.automatic" hide-details>
            <template slot="label">
              <span style="margin-right: 5px">Korting automatisch toepassen</span>
              <v-tooltip class="ma-0 pa-0" right>
                <template v-slot:activator="{ on }">
                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small
                    v-on="on">mdi-help-circle</v-icon>
                </template>
                <span style="color: #fff">Korting wordt automatisch toegepast in de kassa als aan de voorwaarden wordt
                  voldaan.</span>
              </v-tooltip>
            </template>
          </v-switch>

          <v-card
            v-show="editModifier.modifierGroup === 'defaultModifier' || editModifier.modifierGroup === 'loyaltyModifier'"
            class="py-4 px-2 mt-4" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption">
            <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="ma-0 py-2"
              v-model="editModifier.numberLimit.active" hide-details v-on:change="resetNumberLimit()">
              <template slot="label">
                <span style="margin-right: 5px">Gebruik van korting bepreken</span>
                <v-tooltip class="ma-0 pa-0" right>
                  <template v-slot:activator="{ on }">
                    <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small
                      v-on="on">mdi-help-circle</v-icon>
                  </template>
                  <span style="color: #fff">Gebruiksaantallen van de korting beheren.</span>
                </v-tooltip>
              </template>
            </v-switch>

            <v-container v-if="editModifier.numberLimit.active" grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12>
                  <v-select :items="numberLimitTypes" item-text="name" item-value="id" dense label="Type beperking"
                    v-model="editModifier.numberLimit.type" outlined class="mt-4"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details></v-select>
                </v-flex>
                <v-flex xs6>
                  <v-text-field label="Aantal" dense v-model.number="editModifier.numberLimit.number" required outlined
                    class="mt-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    hide-details></v-text-field>
                </v-flex>
                <v-flex xs6 v-if="editModifier.numberLimit.type == 'max'">
                  <v-text-field label="Aantal gebruikt" v-model="editModifier.numberLimit.numberUsed" required outlined
                    dense readonly disabled class="mt-4"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    hide-details></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>

          <v-switch class="mt-4 ml-2" label="Combineren met andere kortingen niet toestaan"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            v-model="editModifier.combinationLimit" hide-details></v-switch>

          <v-card
            v-show="editModifier.modifierGroup === 'defaultModifier' || editModifier.modifierGroup === 'loyaltyModifier'"
            class="py-4 px-2 mt-4" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption">
            <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              class="ma-0 py-2 pb-4" v-model="editModifier.dateLimit.active" hide-details
              v-on:change="resetDateLimit()">
              <template slot="label">
                <span style="margin-right: 5px">Korting beperken tot periode</span>
                <v-tooltip class="ma-0 pa-0" right>
                  <template v-slot:activator="{ on }">
                    <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small
                      v-on="on">mdi-help-circle</v-icon>
                  </template>
                  <span style="color: #fff">Korting is alleen in deze periode actief</span>
                </v-tooltip>
              </template>
            </v-switch>

            <v-container v-if="editModifier.dateLimit.active" grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs6>
                  <v-menu :close-on-content-click="false" v-model="modifierDateLimitStartDialog" :nudge-right="40"
                    transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-on="on" v-model="modifierStartdate.readable" label="Startdatum"
                        prepend-inner-icon="mdi-calendar" readonly dense outlined required hide-details
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"></v-text-field>
                    </template>
                    <v-date-picker v-model="editModifier.dateLimit.startDate" no-title :min="now"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      @input="modifierDateLimitStartDialog = false"></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs6>
                  <v-menu :close-on-content-click="false" v-model="modifierDateLimitEndDialog" :nudge-right="40"
                    transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field v-on="on" v-model="modifierEnddate.readable" label="Einddatum"
                        prepend-inner-icon="mdi-calendar" readonly dense outlined required hide-details
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                        :disabled="!editModifier.dateLimit.startDate"></v-text-field>
                    </template>
                    <v-date-picker v-model="editModifier.dateLimit.endDate" no-title
                      :min="editModifier.dateLimit.startDate"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      @input="modifierDateLimitEndDialog = false"></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>





          <v-select :items="modifierTypes" item-text="name" item-value="id" label="Korting toepasbaar op" dense
            v-model="editModifier.type" outlined class="mt-4"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"
            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" hide-details
            v-on:input="resetModifierType()"></v-select>

          <v-card class="py-4 px-2 mt-4" flat
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption">
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12>
                  <v-select :items="typeDiscountTypes" item-text="name" dense item-value="id" label="Kortingtype"
                    v-model="editModifier.typeDiscount.type" v-on:input="resetDiscountType()" outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details></v-select>
                </v-flex>

                <v-switch v-if="editModifier.typeDiscount.type == 'fixedAmount'" label="Repetitief berekenen"
                  v-model="editModifier.typeDiscount.repetitive" hide-details
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">
                  <template slot="label">
                    <span style="margin-right: 5px">Per item korting volledig opnieuw berekenen</span>
                    <v-tooltip class="ma-0 pa-0" right>
                      <template v-slot:activator="{ on }">
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small
                          v-on="on">mdi-help-circle</v-icon>
                      </template>
                      <span style="color: #fff">Wanneer deze optie uit uitgevinkt, wordt het bedrag berekend over de
                        betreffende
                        items.</span>
                    </v-tooltip>
                  </template>
                </v-switch>

                <v-flex xs12
                  v-if="editModifier.typeDiscount.type == 'buyXX' || editModifier.typeDiscount.type == 'buyXY'">
                  <v-card class="py-4 px-2 mt-4" flat
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                    <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      class="ma-0 py-2" v-model="editModifier.useLimit.active" hide-details
                      v-on:change="resetUseNumberLimit()">
                      <template slot="label">
                        <span style="color: #333; margin-right: 5px">Maximaal aantal keer toepassen bij afrekenen</span>
                        <v-tooltip class="ma-0 pa-0" bottom>
                          <template v-slot:activator="{ on }">
                            <v-icon color="#687387" small v-on="on">mdi-help-circle</v-icon>
                          </template>
                          <span style="color: #fff">Bij het toevoegen van meer producten of behandeling wordt de korting
                            slechts het
                            ingegeven aantal keer toegepast.</span>
                        </v-tooltip>
                      </template>
                    </v-switch>

                    <v-text-field v-if="editModifier.useLimit.active"
                      label="Maximaal aantal keer toepassen bij afrekenen" v-model="editModifier.useLimit.number"
                      required outlined dense suffix="x" class="mt-4"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"
                      hide-details></v-text-field>
                  </v-card>
                </v-flex>
                <v-flex xs6>
                  <v-text-field
                    v-if="editModifier.typeDiscount.type == 'buyXX' || editModifier.typeDiscount.type == 'buyXY'"
                    class="mt-4" label="Aantal te kopen" v-model.number="editModifier.typeDiscount.buyQuantity"
                    hide-details type="number" outlined required dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"></v-text-field>
                </v-flex>

                <v-flex xs6>
                  <v-text-field
                    v-if="editModifier.typeDiscount.type == 'buyXX' || editModifier.typeDiscount.type == 'buyXY'"
                    class="mt-4" label="Aantal waar actie op geldt"
                    v-model.number="editModifier.typeDiscount.getQuantity" hide-details type="number" outlined dense
                    required :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"></v-text-field>
                </v-flex>

                <v-flex xs12>
                  <v-select class="mt-4" v-if="editModifier.typeDiscount.type == 'quantityDiscount'"
                    :items="quantityDiscountTypes" item-text="name" dense item-value="id" label="Type korting"
                    v-model="editModifier.typeDiscount.discountType" outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details></v-select>

                  <v-select class="mt-4"
                    v-if="editModifier.typeDiscount.type == 'buyXX' || editModifier.typeDiscount.type == 'buyXY' || editModifier.typeDiscount.type == 'fixedAmount'"
                    :items="discountActionTypes" item-text="name" item-value="id" label="Type actie"
                    v-model="editModifier.typeDiscount.discountType" outlined dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details></v-select>








                  <v-text-field v-if="
                    (editModifier.typeDiscount.discountType == 'amount' || editModifier.typeDiscount.discountType == 'percentage') && editModifier.typeDiscount.type !== 'quantityDiscount'
                  " class="mt-4" :label="discountInfo.label" v-model.number="editModifier.typeDiscount.discountValue"
                    :prefix="discountInfo.prefix" :suffix="discountInfo.suffix" hide-details dense type="number"
                    outlined required :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"></v-text-field>

                  <div v-if="editModifier.typeDiscount.type == 'quantityDiscount'" class="text-center">
                    <v-card flat class="mt-4 pa-2">
                      <div class="discount-fields-wrapper"
                        v-for="(quantityRule, index) in editModifier.typeDiscount.quantityRules" :key="index">
                        <div class="discount-fields-input">
                          <v-container grid-list-md fluid class="ma-0 pa-0">
                            <v-layout row wrap class="ma-0 mb-2 pa-0">
                              <v-flex xs6>
                                <v-text-field label="Aantal" v-model="quantityRule.number" type="number" dense
                                  hide-details outlined required
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                                  :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"></v-text-field>
                              </v-flex>
                              <v-flex xs6>
                                <v-text-field :label="discountInfo.label" v-model.number="quantityRule.discount"
                                  :prefix="discountInfo.prefix" :suffix="discountInfo.suffix" hide-details dense
                                  type="number" outlined required
                                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                                  :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"
                                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"></v-text-field>
                              </v-flex>
                            </v-layout>
                          </v-container>
                        </div>
                        <div class="discount-fields-remove">
                          <v-btn :disabled="editModifier.typeDiscount.quantityRules.length < 3" icon color="text"
                            class="mt-1" @click="removeQuantityDiscount(index)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </v-card>

                    <v-btn class="mt-4" fab dark small
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" elevation="0"
                      @click="addNewQuantityDiscount()">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>

                  <v-switch v-if="editModifier.typeDiscount.type == 'buyXY'" label="Items moeten uniek zijn"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-model="editModifier.typeDiscount.uniqueItems" hide-details></v-switch>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>

          <v-card class="py-4 px-2 mt-4" flat
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"
            v-if="editModifier.type == 'all' || editModifier.type == 'product'">
            <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              class="ma-0 py-2 pb-4" v-model="editModifier.filterProducts.active" hide-details
              v-on:change="resetProductDiscount()">
              <template slot="label">
                <span style="margin-right: 5px">Korting beperken tot specifieke producten</span>
                <v-tooltip class="ma-0 pa-0" right>
                  <template v-slot:activator="{ on }">
                    <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small
                      v-on="on">mdi-help-circle</v-icon>
                  </template>
                  <span style="color: #fff">Filter specifieke producten</span>
                </v-tooltip>
              </template>
            </v-switch>

            <v-container v-if="editModifier.filterProducts.active" grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12>
                  <v-select :items="filterProductenTypes" item-text="name" item-value="id" label="Filteren op"
                    v-model="editModifier.filterProducts.type" outlined dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" hide-details
                    v-on:input="resetProductDiscount()"></v-select>
                </v-flex>

                <v-flex xs12 v-if="editModifier.filterProducts.type != 'all'">
                  <v-select :items="filterItemActions" item-text="name" item-value="id"
                    v-model="editModifier.filterProducts.itemsAction" outlined dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details></v-select>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="ma-0 pa-0" v-if="propductDataLoaded">
                <v-flex xs12
                  v-if="editModifier.filterProducts.type != 'all' && editModifier.filterProducts.type == 'specific'">
                  <v-select 
                    clearable 
                    multiple 
                    chips 
                    deletable-chips 
                    small-chips 
                    :items="filteredProducts" 
                    item-text="name"
                    item-value="id" 
                    label="Producten" 
                    v-model="editModifier.filterProducts.items"
                    v-on:change="checkModifiers()" 
                    outlined 
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details>
                    <template v-slot:prepend-item>
                      <v-list-item style="margin-top: -8px"> 
                        <v-list-item-content  class="pt-0" >
                          <v-text-field
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :placeholder="$t('general.search', $store.state.locale)"
                            prepend-inner-icon="mdi-magnify"
                            clearable
                            v-model="searchProducts"
                            hide-details
                            dense
                          ></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <div v-if="searchProducts && searchProducts.length > 0" style="padding: 4px 17px 3px 17px; background-color:rgba(0,0,0, 0.02); color: rgba(0, 0, 0, 0.6); text-align: center; font-size: 12px">{{ filteredTreatments.length }} {{ $t("settings.of", $store.state.locale) }} {{ products.length }} {{ $t("general.products", $store.state.locale) }}</div>
                      <v-divider v-if="searchProducts && searchProducts.length > 0"></v-divider>
                    </template>
                  </v-select>
                </v-flex>
                <v-flex xs12
                  v-if="editModifier.filterProducts.type != 'all' && editModifier.filterProducts.type == 'brands'">
                  <v-select clearable multiple chips deletable-chips small-chips :items="productsSuppliers"
                    item-text="name" item-value="id" label="Merken" v-model="editModifier.filterProducts.items"
                    v-on:change="checkModifiers()" outlined dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details></v-select>
                </v-flex>
                <v-flex xs12
                  v-if="editModifier.filterProducts.type != 'all' && editModifier.filterProducts.type == 'categories'">
                  <v-select clearable multiple chips dense deletable-chips small-chips :items="productCategories"
                    :item-text="item => item.name[($store.state.locale).toLowerCase()]" item-value="id"
                    label="Categorieën" v-model="editModifier.filterProducts.items" v-on:change="checkModifiers()"
                    outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details></v-select>
                </v-flex>
              </v-layout>
              <v-layout class="ma-0 pa-0" v-else>
                <div class="text-center pt-5" style="width: 100%">
                  <v-progress-circular indeterminate
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"></v-progress-circular>
                </div>
              </v-layout>
            </v-container>
          </v-card>

          <v-card class="py-4 px-2 mt-4" flat
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption"
            v-if="editModifier.type == 'all' || editModifier.type == 'treatment'">
            <v-switch :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              class="ma-0 py-2 pb-4" v-model="editModifier.filterTreatments.active" hide-details
              v-on:change="resetTreatmentDiscount()">
              <template slot="label">
                <span style="margin-right: 5px">Korting beperken tot specifieke behandelingen</span>
                <v-tooltip class="ma-0 pa-0" right>
                  <template v-slot:activator="{ on }">
                    <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" small
                      v-on="on">mdi-help-circle</v-icon>
                  </template>
                  <span style="color: #fff">Filter specifieke behandelingen</span>
                </v-tooltip>
              </template>
            </v-switch>

            <v-container v-if="editModifier.filterTreatments.active" grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12>
                  <v-select :items="filterTreatmentsTypes" item-text="name" item-value="id"
                    label="Filter behandeling op" v-model="editModifier.filterTreatments.type" outlined dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" hide-details
                    v-on:input="resetTreatmentDiscount()"></v-select>
                </v-flex>
              </v-layout>

              <v-layout row wrap class="ma-0 pa-0" v-if="treatmentDataLoaded">
                <v-flex xs12 v-if="editModifier.filterTreatments.type != 'all'">
                  <v-select 
                    :items="filterItemActions" 
                    item-text="name" item-value="id"
                    v-model="editModifier.filterTreatments.itemsAction" 
                    outlined dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details>
                  </v-select>
                </v-flex>
                <v-flex xs12  v-if="editModifier.filterTreatments.type != 'all' && editModifier.filterTreatments.type == 'specific'">
                  <v-select 
                    clearable 
                    multiple 
                    chips 
                    dense 
                    deletable-chips 
                    small-chips 
                    :items="filteredTreatments"
                    :item-text="(item) => $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.treatmentTitle)"
                    item-value="id" label="Behandelingen" v-model="editModifier.filterTreatments.items"
                    v-on:change="checkModifiers()" outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details>
                    <template v-slot:prepend-item>
                      <v-list-item style="margin-top: -8px"> 
                        <v-list-item-content  class="pt-0" >
                          <v-text-field
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :placeholder="$t('general.search', $store.state.locale)"
                            prepend-inner-icon="mdi-magnify"
                            clearable
                            v-model="searchTreatments"
                            hide-details
                            dense
                          ></v-text-field>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider></v-divider>
                      <div v-if="searchTreatments && searchTreatments.length > 0" style="padding: 4px 17px 3px 17px; background-color:rgba(0,0,0, 0.02); color: rgba(0, 0, 0, 0.6); text-align: center; font-size: 12px">{{ filteredTreatments.length }} {{ $t("settings.of", $store.state.locale) }} {{ treatments.length }} {{ $t("settings.treatments", $store.state.locale) }}</div>
                      <v-divider v-if="searchTreatments && searchTreatments.length > 0"></v-divider>
                    </template>
                  </v-select>
<!-- 
                    <treatmentSelector :source="'modifierForm'"></treatmentSelector>   -->
                </v-flex>
                <v-flex xs12
                  v-if="editModifier.filterTreatments.type != 'all' && editModifier.filterTreatments.type == 'categories'">
                  <v-select 
                    clearable 
                    multiple 
                    chips 
                    deletable-chips 
                    small-chips 
                    :items="treatmentTypes"
                    :item-text="(item) => $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.name)"
                    item-value="id" 
                    label="Categorieën" 
                    v-model="editModifier.filterTreatments.items"
                    v-on:change="checkModifiers()" 
                    outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    hide-details></v-select>
                </v-flex>
              </v-layout>

              <v-layout class="ma-0 pa-0" v-else>
                <div class="text-center pt-5" style="width: 100%">
                  <v-progress-circular indeterminate
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"></v-progress-circular>
                </div>
              </v-layout>
            </v-container>
          </v-card>

          <v-switch label="Actief" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
            v-model="editModifier.active" hide-details></v-switch>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md4 class="text-left"
              v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                @click="closeModifierDialog()"> {{ $t("general.cancel", $store.state.locale) }} </v-btn>
            </v-flex>
            <v-flex xs12 md8 class="text-right">
              <v-btn 
                v-if="editMode"
                :disabled="!changed" 
                :depressed="!changed"  
                :dark=" changed"  
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="saveModifier()"
                >
                <div v-if="changed">{{ $t("general.saveChanged", $store.state.locale) }}<v-icon right>mdi-chevron-right</v-icon></div>
                <div v-else>{{ $t("general.saved", $store.state.locale) }} <v-icon right>mdi-check</v-icon></div>  
              </v-btn>
              <v-btn v-else dark :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="saveModifier()">
                {{ $t("general.add", $store.state.locale) }}
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
              
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
