
<template>
  <div id="client-modal-wrapper">
    <v-snackbar
      v-model="snackbar.active"
      :color="snackbar.color"
      top
      :timeout="snackbar.timeout"
      >
      <v-row>
        <v-icon color="white" class="mx-3">{{ snackbar.icon}}</v-icon>
        <span class="py-2 white--text">{{ snackbar.text}}</span>
      </v-row>
    </v-snackbar>

    <div v-if="!employeeDataLoading">

      <v-container v-if="source != 'calendar'" grid-list-lg class=" ma-0 pa-0 pb-2" fluid>
        <v-layout row wrap>
          <v-flex md6 xs12>
            <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" height="36" dark small  style="float: left; min-width: 40px" class="px-0" depressed @click="goToEmployeeOverview()"> 
              <v-icon class="ma-0 pa-0">mdi-chevron-left</v-icon>
            </v-btn>
            
            <div class="pa-3 pt-1" style=" float: left; display: inline-block; font-size: 20px;" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].text }">{{ selectedEmployeeInfo.name }} </div>
          </v-flex>
          <v-flex md6 xs12 class="text-right">
            <v-btn style=" min-width: 20px" v-if="selectedEmployeeInfo.deleted && !selectedEmployeeInfo.deleted.deleted" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" outlined @click="removeEmployeeDialog.show = true" class="mx-3 px-2">
              <v-icon>mdi-delete</v-icon>
            </v-btn>

            <v-btn style=" min-width: 20px" v-if="selectedEmployeeInfo.deleted && selectedEmployeeInfo.deleted.deleted" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" outlined @click="restoreEmployeeDialog.show = true" class="mx-3 px-2">
               <v-icon left>mdi-delete-off</v-icon>Medewerker activeren
            </v-btn>
            <v-btn
                :disabled="!changed" 
                depressed
                :loading="saveDisabled"
                :dark="changed"
                outlined
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="updateEmployee()"
              >
                <div v-if="changed">{{ $t('general.saveChanged', $store.state.locale) }}<v-icon right >mdi-chevron-right</v-icon></div>
                <div v-else> {{ $t('general.saved', $store.state.locale) }} <v-icon right >mdi-check</v-icon></div>
              </v-btn>
          </v-flex>
        </v-layout>
      </v-container>

      <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 mb-4" :class="$store.state.companyTheme">
        <v-tabs class="mb-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card" show-arrows v-model="tab" @change="tabChanged()" style="border-bottom: thin solid; border-bottom: none ">
          <v-tab href="#basic"><v-icon left>mdi-account</v-icon>{{ $t('treatments.basic', $store.state.locale)}}</v-tab>
          <v-tab href="#schedule"><v-icon left>mdi-calendar-month</v-icon>{{ $t('employees.schedule', $store.state.locale) }}</v-tab>
          <v-tab href="#exceptions"><v-icon left>mdi-clock</v-icon>{{ $t('employees.exceptions', $store.state.locale) }}</v-tab>
          <v-tab href="#leave"><v-icon left>mdi-calendar-star</v-icon>{{ $t('employees.leave', $store.state.locale) }}</v-tab>
        </v-tabs>
      </v-card>

      <v-tabs-items v-model="tab" class="transparant-tabs">
        <!-- / Employee Basic \ -->
        <v-tab-item value="basic" :transition="tabTransition" :reverse-transition="tabTransition">
          <v-container grid-list-lg class=" ma-0 pa-0" fluid >
            <v-layout row wrap>
              <!-- / Column Left \ -->
              <v-flex md6 sm12 xs12>
                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
                  <div class="pa-3">
                    <v-avatar
                      size="36"
                      light
                      style="float: left"
                      class="ma-0 mr-2 custom-avatar-style" 
                      rounded="lg"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                      >
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account</v-icon>
                    </v-avatar>
                    <h3 class="pt-1 pb-4">{{ $t('treatments.basic', $store.state.locale)}}</h3>
                    <v-divider></v-divider>
                  </div>

                  <v-card outlined class="ma-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" v-if="$store.state.userRoleNumber < 2 && !$store.state.demoMode">
                    <v-btn dark @click="copyTextToClipboard(selectedEmployeeInfo.id)" class="ma-1 my-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="text-transform: none">
                      <strong>{{ selectedEmployeeInfo.id }}</strong>
                    </v-btn>
                    <div style="display: inline-block" v-if="selectedEmployeeIdCopy == false" class="pa-2 copy-tag-url" @click="copyTextToClipboard(selectedEmployeeInfo.id)">
                      <v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon> Kopieër DocId
                    </div>
                    <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied">DocId gekopieërd</div>
                  </v-card>

                  <v-container grid-list-md class="ma-0 py-0">
                    <v-layout row wrap>
                      <v-flex xs12>
                        <div class="mt-2">
                          <h5 class="pb-1">{{ $t('employees.employeeImage', $store.state.locale) }}</h5>
                          <v-row align="center" justify="center" class="my-0 pa-0">
                            <v-col xs3 style="max-width: 80px!important; max-height: 80px!important;" :style="{ backgroundColor: selectedEmployeeInfo.employeePhoto ? $themes[$store.state.companyTheme][$store.state.themeModus].background : 'none' }" class=" pa-0 ma-0 ml-3 ">
                              <v-icon v-if="!selectedEmployeeInfo.employeePhoto" :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" size="86" class="pa-0 ma-0">mdi-image</v-icon>
                              <img v-else :src="selectedEmployeeInfo.employeePhoto" style="max-width: 100%; padding: 3px; margin: 0" />
                            </v-col>
                            <v-col xs9>
                              <v-btn v-if="!selectedEmployeeInfo.employeePhoto" @click="openMediaLibrary()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" small dark>{{ $t('library.selectFromLibrary', $store.state.locale) }}</v-btn>
                              <div v-else>
                                <a :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary}" @click="openMediaLibrary()">{{ $t('library.replace', $store.state.locale) }}</a>
                              </div>
                            </v-col>
                          </v-row>
                        </div>
                      </v-flex>
                      <v-flex xs6>
                        <v-text-field
                          :label="$t('general.firstName', $store.state.locale)"
                          v-model="selectedEmployeeInfo.firstName"
                          autocomplete
                          outlined
                          required
                          dense
                          class="mt-2"
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        ></v-text-field>
                        </v-flex>
                        <v-flex xs6>

                        <v-text-field
                          :label="$t('general.lastName', $store.state.locale)"
                          v-model="selectedEmployeeInfo.lastName"
                          autocomplete
                          outlined
                          required
                          dense
                          hide-details
                          class="mt-2"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        ></v-text-field>
                        </v-flex>


                        <v-flex xs12>
                          <v-text-field
                          :label="$t('employees.widgetName', $store.state.locale)"
                          v-model="selectedEmployeeInfo.publicName"
                          autocomplete
                          outlined
                          required
                          dense
                          hide-details
                          class="mt-2"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        ></v-text-field>
                       </v-flex> 

                        <v-flex xs6>
                        <v-select
                          class="mt-3"
                          :label="$t('general.gender', $store.state.locale)"
                          v-model="selectedEmployeeInfo.gender"
                          :items="genders"
                          item-text="name"
                          item-value="id"
                          required
                          outlined
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          hide-details 
                        ></v-select>
                        </v-flex>
                        <v-flex xs6>

                        <v-menu ref="menuBirthDate" v-model="menuBirthDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            class="mt-3"
                            v-on="on"
                            v-model="employeeBirthDate.readable"
                            :label="$t('general.birthday', $store.state.locale)"
                            readonly
                            prepend-inner-icon="mdi-calendar"
                            hide-details
                            dense
                            outlined
                            clearable
                            @click:clear="clearBirthDate()"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          no-title
                          active-picker="YEAR"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          v-model="selectedEmployeeInfo.birthday"
                          :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                          @input="menuBirthDate = false"
                        ></v-date-picker>
                      </v-menu>
                      </v-flex>

                      <v-flex xs12>
                        <v-select
                          class="mt-3"
                          :label="$t('general.type', $store.state.locale)"
                          v-model="selectedEmployeeInfo.contractType"
                          :items="employment"
                          item-text="name"
                          item-value="id"
                          required
                          outlined
                          dense
                          :disabled="selectedEmployeeInfo.deleted && selectedEmployeeInfo.deleted.deleted"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          hide-details 
                        ></v-select>
                        <v-select
                        v-if="selectedEmployeeInfo.contractType != 'owner' && selectedEmployeeInfo.contractType != 'seatTenant' && selectedEmployeeInfo.contractType != 'entrepreneur'"
                          class="mt-3"
                          :label="$t('employees.cao', $store.state.locale)"
                          v-model="selectedEmployeeInfo.cao"
                          :items="caoItems"
                          :item-text="item => item.name[($store.state.locale).toLowerCase()]"
                          item-value="id"
                          required
                          outlined
                          dense
                          :disabled="selectedEmployeeInfo.deleted && selectedEmployeeInfo.deleted.deleted"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          hide-details 
                        ></v-select>
                      </v-flex>
                      <!-- <v-flex xs6>
                        <v-switch class="pa-0 ma-0 mt-3" hide-details :label="$t('general.active', $store.state.locale)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="selectedEmployeeInfo.active"></v-switch>
                      </v-flex> -->
                      
                    </v-layout>
                    <v-divider class="my-4"></v-divider>

                    <h5 class="ma-0 my-2">{{ $t('general.address', $store.state.locale) }}</h5> 

                    <v-layout>
                      <v-flex xs12>
                        <v-select
                          :label="$t('general.country', $store.state.locale)"
                          v-model="selectedEmployeeInfo.address.country"
                          :items="$countries"
                          :item-text="item => item.countryName[($store.state.locale).toLowerCase()]"
                          item-value="country"
                          required
                          outlined
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          hide-details
                        ></v-select>
                      </v-flex> 
                    </v-layout>

                    <v-layout class="mt-3">
                      <v-flex xs12 md6>
                        <v-text-field
                          :label="$t('general.zipcode', $store.state.locale)"
                          v-model="selectedEmployeeInfo.address.postalCode"
                          outlined
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          suffix="1234AA"
                          hide-details
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs6 md3>
                        <v-text-field
                          :label="$t('general.number', $store.state.locale)"
                          number
                          v-model.number="selectedEmployeeInfo.address.number"
                          outlined
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          hide-details
                        ></v-text-field>
                      </v-flex> 
                      <v-flex xs6 md3>
                        <v-text-field
                          label="Toevoeging"
                          v-model="selectedEmployeeInfo.address.numberAddition"
                          outlined
                          dense
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          hide-details
                          ></v-text-field>
                      </v-flex>
                    </v-layout>
                      
                    <v-layout class="mt-3">
                      <v-flex xs6>
                        <v-text-field
                          :label="$t('general.street', $store.state.locale)"
                          v-model="selectedEmployeeInfo.address.street"
                          outlined
                          dense
                          :disabled="!addAddressManual"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          hide-details
                        ></v-text-field>
                      </v-flex> 
                      <v-flex xs6>
                        <v-text-field
                          :label="$t('general.city', $store.state.locale)"
                          v-model="selectedEmployeeInfo.address.city"
                          outlined
                          dense
                          :disabled="!addAddressManual"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          hide-details
                        ></v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout class="mt-1">
                      <v-flex xs12 class="text-center">
                        <v-alert v-if="!addAddressManual && addressNotFound && !addressChanged" outlined class="ma-0 pa-2" dense type="warning">{{ $t('clients.addressNotFound', $store.state.locale) }}</v-alert>
                        <p :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].primary }" v-if="!selectedEmployeeInfo.address.postalCode || !selectedEmployeeInfo.address.number"> <v-icon size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon> <i>{{ $t('clients.getAddressHint', $store.state.locale) }}</i></p>
                        <v-btn class="mt-2" v-if="!addAddressManual && ((!addressNotFound && addressChanged)|| (addressNotFound && addressChanged))" :disabled="!selectedEmployeeInfo.address.postalCode || !selectedEmployeeInfo.address.number" outlined block small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="checkAddress">{{ $t('clients.getAddress', $store.state.locale) }}</v-btn>
                        <v-btn class="mt-2" outlined small block v-if="!addAddressManual && (selectedEmployeeInfo.address.country != 'netherlands' || !addressChanged && addressNotFound)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="!addAddressManual ? addAddressManual = true : addAddressManual = false">{{ $t('clients.addAddressManual', $store.state.locale) }}</v-btn>
                        <p v-if="addAddressManual"><i>{{ $t('clients.addressFoundManual', $store.state.locale) }} </i> <v-btn small icon @click="addAddressManual = false;"><v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-undo-variant</v-icon></v-btn></p>
                        <p v-if="addressChecked && !addAddressManual && !addressNotFound"><v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-information</v-icon> <i>{{ $t('clients.addressFound', $store.state.locale) }}</i></p>
                      
                        <div v-if="selectedEmployeeInfo.address.coordinates && selectedEmployeeInfo.address.coordinates.length == 2">
                              <GmapMap
                                :center="{lat:selectedEmployeeInfo.address.coordinates[1], lng:selectedEmployeeInfo.address.coordinates[0]}"
                                :zoom="14"
                                :options="{
                                  zoomControl: true,
                                  mapTypeControl: false,
                                  scaleControl: false,
                                  streetViewControl: false,
                                  rotateControl: false,
                                  fullscreenControl: false,
                                  disableDefaultUi: false
                                }"
                                map-type-id="terrain"
                                style="width: 100%; height: 200px"
                                >
                                <gmap-custom-marker 
                                  :marker="{lat:selectedEmployeeInfo.address.coordinates[1], lng:selectedEmployeeInfo.address.coordinates[0]}"
                                  :clickable="false"
                                  :draggable="false"
                                  >
                                  <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-map-marker</v-icon>
                                </gmap-custom-marker>

                              </GmapMap>
                            </div>
                      </v-flex>
                    </v-layout>

                    <v-layout>
                      <v-flex xs12>
                        <!-- / EMPLOYEE EMAILADDRESSES \ -->
                        <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="mt-4" :class="$store.state.companyTheme" :key="changes">
                          <div class="pa-3 pb-0">
                            <v-row class="pa-0 ma-0">
                              <v-avatar
                                size="36"
                                light
                                style="float: left"
                                class="ma-0 mr-2 custom-avatar-style" 
                                rounded="lg"
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                >
                                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-email</v-icon>
                              </v-avatar>
                              <h3 class="pt-1 pb-4">{{ $t('general.emailaddress', $store.state.locale) }}</h3>
                              <v-spacer></v-spacer>
                              <v-btn fab x-small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="addEmailaddress()"><v-icon>mdi-plus</v-icon></v-btn>
                            </v-row>

                            <v-divider></v-divider>
                          </div>

                          <div v-if="!selectedEmployeeInfo.emails || selectedEmployeeInfo.emails.length == 0" class="text-center pa-1 pt-0">
                            <v-avatar 
                              size="46"
                              light
                              class="ma-4 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-email-multiple-outline</v-icon>
                            </v-avatar>
                            <p>{{ $t('clients.noEmailsAdded', $store.state.locale) }}</p>
                          </div>

                          <div v-if="selectedEmployeeInfo.emails && selectedEmployeeInfo.emails.length > 0">
                            <v-data-table
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                              :headers="emailHeaders"
                              :items-per-page="-1"
                              :items="selectedEmployeeInfo.emails"
                              hide-default-footer
                              style="background: none"
                              >
                              <template v-slot:item="{item}">
                                <tr>
                                  <td>{{ item.email }}</td>
                                  <td :style="'color:'+ emailScoreColor(item.score) + ';'"
                                  @click="emailScoreDialog(item.score, item.error)">{{ item.score }}</td>
                                  <td class="text-center">
                                    <v-icon v-if="item.primary" :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-star</v-icon>
                                    <v-btn @click="setEmailPrimary(item)" icon small v-else><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].textSmooth">mdi-star-outline</v-icon></v-btn>
                                  </td>
                                  <td>
                                    <v-btn icon small @click="removeEmail(item)"><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon></v-btn>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                       
                          </div>
                        </v-card>
                        <!-- \ EMPLOYEE EMAILADDRESSES / -->

                        <!-- / EMPLOYEE PHONENUMBER \ -->
                        <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="my-4">
                          <div class="pa-3 pb-0">
                            <v-row class="pa-0 ma-0">
                              <v-avatar
                                size="36"
                                light
                                style="float: left"
                                class="ma-0 mr-2 custom-avatar-style" 
                                rounded="lg"
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                >
                                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-phone</v-icon>
                              </v-avatar>
                              <h3 class="pt-1 pb-4">{{ $t('general.phones', $store.state.locale) }}</h3>
                              <v-spacer></v-spacer>
                              <v-btn fab x-small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="addPhonenumber()"><v-icon>mdi-plus</v-icon></v-btn>
                            </v-row>

                            <v-divider></v-divider>
                          </div>

                          <div v-if="!selectedEmployeeInfo.phones || selectedEmployeeInfo.phones.length == 0" class="text-center pa-1 pt-0">
                            <v-avatar 
                              size="46"
                              light
                              class="ma-4 custom-avatar-style" 
                              rounded="lg"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                              >
                              <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-phone-ring</v-icon>
                            </v-avatar>
                            <p>{{ $t('clients.noPhonenumbersAdded', $store.state.locale) }}</p>
                          </div>

                          <div v-if="selectedEmployeeInfo.phones && selectedEmployeeInfo.phones.length > 0">
                            <v-data-table
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                              :headers="phoneHeaders"
                              :items-per-page="-1"
                              :items="selectedEmployeeInfo.phones"
                              hide-default-footer
                              style="background: none"
                              >
                              <template v-slot:item="{item}">
                                <tr>
                                  <td class="text-center">
                                    <v-icon :size="18" v-if="item.type == 'mobile'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-cellphone</v-icon>
                                    <v-icon :size="18" v-else :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-deskphone</v-icon>
                                  </td>
                                  <td v-if="item.region == 'NL'">{{ item.phone.national }}</td>
                                  <td v-else>{{ item.phone.international }}</td>
                                  <td class="text-center">
                                    <v-icon v-if="item.primary" :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-star</v-icon>
                                    <v-btn @click="setPrimary(item)" icon small v-else><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].textSmooth">mdi-star-outline</v-icon></v-btn>
                                  </td>
                                  <td>
                                    <v-btn icon small @click="removePhone(item)"><v-icon :size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon></v-btn>
                                  </td>
                                </tr>
                              </template>
                            </v-data-table>
                          </div>
                        </v-card>
                        <!-- \ EMPLOYEE PHONENUMBER / -->
                      </v-flex>
                    </v-layout>

                  </v-container>
                </v-card>
              </v-flex>
              <!-- \ Column Left / -->

              <!-- / Column Right \ -->
              <v-flex md6 xs12>
                <v-card flat :disabled="selectedEmployeeInfo.deleted && selectedEmployeeInfo.deleted.deleted" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
                  <div class="pa-3">
                    <v-avatar
                      size="36"
                      light
                      style="float: left"
                      class="ma-0 mr-2 custom-avatar-style" 
                      rounded="lg"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                      >
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-note</v-icon>
                    </v-avatar>
                    <h3 class="pt-1 pb-4">{{ $t('employees.employment', $store.state.locale)}}</h3>
                    
                    <v-divider></v-divider>
                  </div>

                  <v-container grid-list-md class="ma-0 py-0">
                    <p class="ma-0 mt-2 mb-2 ml-1"><strong>{{ $t('general.branches', $store.state.locale) }}</strong></p>
                    <v-layout row wrap class="ma-0 pa-0">
                      <v-flex xs12>
                        <v-alert v-if="branchUpForRemoval" type="info" prominent text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mb-4 pa-2" style="font-size: 14px" v-html="$t('employees.branchRemoval', $store.state.locale)"></v-alert>

                        <v-select
                          :items="branches"
                          item-text="name"
                          item-value="id"
                          v-model="selectedEmployeeInfo.branches"
                          v-on:input="createEmployeeBasicSchedule()"
                          multiple
                          outlined
                          chips
                          small-chips
                          dense
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :label="$t('employees.selectBranches', $store.state.locale)"
                        >
                        </v-select>


                      </v-flex>
                    </v-layout>

                    <p class="ma-0 mt-3 mb-2 ml-1"><strong>{{ $t('employees.startEndEmployment', $store.state.locale) }}</strong></p>
                    <v-layout row wrap class="ma-0 pa-0">
                      <v-flex xs6>
                        <v-menu ref="menuStartDate" v-model="menuStartDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-on="on"
                              v-model="employeeContractStart.readable"
                              :label="$t('employees.startContract', $store.state.locale)"
                              readonly
                              prepend-inner-icon="mdi-calendar"
                              hide-details
                              dense
                              outlined
                              clearable
                              @click:clear="clearContractStartDate()"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            ref="picker"
                            no-title
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            v-model="selectedEmployeeInfo.employeeContractStart"
                            :max="employeeContractStartMax"
                            min="1970-01-01"
                            @input="menuStartDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>
                      <v-flex xs6>
                        <v-menu ref="menuEndDate" v-model="menuEndDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-on="on"
                              v-model="employeeContractEnd.readable"
                              :label="$t('employees.endContract', $store.state.locale)"
                              readonly
                              prepend-inner-icon="mdi-calendar"
                              outlined
                              dense
                              hide-details
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              @click:clear="clearContractEndDate()"
                              clearable
                              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            ref="picker"
                            no-title
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            v-model="selectedEmployeeInfo.employeeContractEnd"
                            :max="employeeContractEndMax"
                            min="1970-01-01"
                            @input="menuEndDate = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-flex>
                    </v-layout>

                    <div v-if="$store.state.userRoleNumber < 2 && !$store.state.demoMode">
                      <p class="ma-0 mt-3 mb-2 ml-1"><strong>{{ $t('employees.functionsAndDevices', $store.state.locale) }}</strong></p>
                      <v-layout row wrap class="ma-0 pa-0">
                        <v-flex xs6>
                          <v-select
                            chips
                            deletable-chips
                            small-chips
                            :items="filteredAbilities"
                            item-text="ability"
                            item-value="abilityId"
                            :label="$t('employees.functions', $store.state.locale)"
                            v-model="selectedEmployeeInfo.employeeAbilities"
                            multiple
                            dense
                            outlined
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            background-color="menu"
                            hide-details
                            :no-data-text="$t('employees.noFunctionsFound', $store.state.locale)"
                          >
                            <template v-slot:prepend-item>
                              <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                                <v-list-item-content class="pt-0">
                                  <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" placeholder="Zoeken" prepend-inner-icon="mdi-magnify" clearable v-model="searchAbilities" hide-details dense></v-text-field>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider></v-divider>
                              <div
                                v-if="searchAbilities && searchAbilities.length > 0"
                                style="text-transform: lowercase; padding: 4px 17px 3px 17px; background-color: rgba(0, 0, 0, 0.02); color: rgba(0, 0, 0, 0.6); text-align: center; font-size: 12px"
                              >
                                {{ filteredAbilities.length }} {{  $t('general.of', $store.state.locale) }} {{ abilities.length }} {{  $t('employees.functions', $store.state.locale) }}
                              </div>
                              <v-divider v-if="searchAbilities && searchAbilities.length > 0"></v-divider>
                            </template>
                          </v-select>
                        </v-flex>
                        <v-flex xs6>
                          <v-select
                            chips
                            deletable-chips
                            small-chips
                            :items="filteredDeviceTypes"
                            :item-text="item => item.name[($store.state.locale).toLowerCase()]"
                    
                            item-value="deviceId"
                            :label="$t('employees.deviceTypes', $store.state.locale)"
                            v-model="selectedEmployeeInfo.deviceTypes"
                            multiple
                            outlined
                            dense
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            background-color="menu"
                            hide-details
                          >
                            <template v-slot:prepend-item>
                              <v-list-item>
                                <v-list-item-content class="pt-0">
                                  <v-text-field :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :placeholder="$t('general.search', $store.state.locale)" prepend-inner-icon="mdi-magnify" clearable v-model="searchDeviceTypes" hide-details dense></v-text-field>
                                </v-list-item-content>
                              </v-list-item>
                              <v-divider></v-divider>
                              <div v-if="searchDeviceTypes && searchDeviceTypes.length > 0" style="text-transform: lowercase; padding: 4px 17px 3px 17px; background-color: rgba(0, 0, 0, 0.02); color: rgba(0, 0, 0, 0.6); text-align: center; font-size: 12px">
                                {{ filteredDeviceTypes.length }} {{  $t('general.of', $store.state.locale) }} {{ deviceTypes.length }} {{  $t('employees.deviceTypes', $store.state.locale) }}
                              </div>
                              <v-divider v-if="searchDeviceTypes && searchDeviceTypes.length > 0"></v-divider>
                            </template>
                          </v-select>
                        </v-flex>
                      </v-layout>
                    </div>
                    <v-layout row wrap class="ma-0 pa-0">
                      <v-flex xs6 class="my-0 py-0">
                        <v-switch :label="$t('employees.employeeIsEmployee', $store.state.locale)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" hide-details class="ma-0 my-4 pa-0" v-model="selectedEmployeeInfo.trainee"></v-switch>
                      </v-flex>
                    </v-layout>
                    <v-layout row wrap class="ma-0 pa-0">
                      <v-flex xs6 class="mt-0 pt-0">
                        <v-switch :label="$t('employees.showInWidget', $store.state.locale)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" hide-details class="ma-0 my-4 pa-0" v-model="selectedEmployeeInfo.useInWidget"></v-switch>
                      </v-flex>
                    </v-layout>


                  </v-container>
                </v-card>

                <v-card flat :disabled="selectedEmployeeInfo.deleted && selectedEmployeeInfo.deleted.deleted" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 mt-4" :class="$store.state.companyTheme">
                  <div class="pa-3">
                    <v-avatar
                      size="36"
                      light
                      style="float: left"
                      class="ma-0 mr-2 custom-avatar-style" 
                      rounded="lg"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                      >
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-dialpad</v-icon>
                    </v-avatar>
                    <h3 class="pt-1 pb-4">{{ $t('employees.tagAndPincode', $store.state.locale)}}</h3>
                    
                    <v-divider class="mb-3"></v-divider>
                    
                    <v-alert type="info" prominent text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mb-0 pa-2" style="font-size: 14px" v-html="$t('employees.tagPincodeInfo', $store.state.locale)"></v-alert>

                    <div class="tags-wrapper">
                      <div class="text-center">
                        <h3 class="mt-3">Tags</h3>
                      </div>

                      <v-card class="mt-3" flat outlined v-if="selectedEmployeeInfo.nfcTags && selectedEmployeeInfo.nfcTags.length > 0">
                        <div v-for="(tag, index) in selectedEmployeeInfo.nfcTags" :key="index">
                          <div class="tag-wrapper" v-for="(tag, index) in selectedEmployeeInfo.nfcTags" :key="index">
                            <div class="tag-id">
                              <v-icon small class="mr-2" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" >mdi-tag</v-icon>{{ tag }}
                              <v-chip :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" x-small v-if="nfcStatus.id == tag"><span class="tag-chip">{{ $t('employees.currentTag', $store.state.locale) }}</span></v-chip>
                            </div>
                            <div class="tag-action">
                              <v-btn icon size="18" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeNfcTag(index)">
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </div>
                          <v-divider v-if="index + 1 < selectedEmployeeInfo.nfcTags.length"></v-divider>
                        </div>
                      </v-card>

                      <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" v-else class="text-center mt-2 pa-3">
                        <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" size="40">mdi-nfc-variant</v-icon>
                        <p class="ma-0"><strong>{{ $t('employees.noTagsConnected', $store.state.locale) }}</strong></p>
                      </v-card>

                      <v-alert v-if="nfcStatus.status == 'used' && nfcStatus.userId != selectedEmployeeInfo[docName]" dense outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" type="info" border="left" class="mt-2 mb-4">
                        {{ $t('employees.tagsConnectedToOtherEmployee', $store.state.locale) }}
                      </v-alert>

                      <v-btn dark class="mt-2" v-if="nfcStatus.status == 'available'" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" block depressed :busy="addNFCbusy" @click="addNfcTag(nfcStatus.id)">
                        <v-icon left>mdi-nfc-variant</v-icon>
                        Tag <strong>({{ nfcStatus.id }})</strong> {{ $t('employees.assignToEmployee', $store.state.locale) }}
                      </v-btn>
                    </div>

                    <div class="pincode-wrapper">
                      <div class="text-center">
                        <h3 class="mt-4">{{ $t('employees.pincode', $store.state.locale) }}</h3>
                      </div>
                      <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="text-center mt-2 pa-3">
                        <div v-if="!pincodesLoading">
                          <div v-if="pincode" style="max-width: 300px; margin: 0 auto">
                            <v-container grid-list-md class="ma-0 py-0">
                              <v-layout row wrap>
                                <v-flex xs3>
                                  <div class="pincode-number">
                                    {{ pincode.substring(0, 1) }}
                                  </div>
                                </v-flex>
                                <v-flex xs3>
                                  <div class="pincode-number">
                                    {{ pincode.substring(1, 2) }}
                                  </div>
                                </v-flex>
                                <v-flex xs3>
                                  <div class="pincode-number">
                                    {{ pincode.substring(2, 3) }}
                                  </div>
                                </v-flex>
                                <v-flex xs3>
                                  <div class="pincode-number">
                                    {{ pincode.substring(3, 4) }}
                                  </div>
                                </v-flex>
                              </v-layout>
                            </v-container>
                            <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mt-4" outlined @click="generatePincode()">{{ $t('employees.generateNewPincode', $store.state.locale) }}</v-btn>
                          </div>
                          <div v-if="!pincode" class="text-center">
                            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" size="40">mdi-dialpad</v-icon>
                            <p class="mt-2"><strong>{{ $t('employees.noPincodeSet', $store.state.locale) }}</strong></p>
                            <v-btn :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" outlined @click="generatePincode()">{{ $t('employees.generatePincode', $store.state.locale) }}</v-btn>
                          </div>
                        </div>
                        <div v-else class="pa-4 ma-4" style="position: relative">
                          <div class="thrive-loader" :class="$store.state.companyTheme"></div>
                        </div>
                      </v-card>
                    </div>
                  </div>

                </v-card>

                <v-card flat :disabled="selectedEmployeeInfo.deleted && selectedEmployeeInfo.deleted.deleted" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 mt-4" :class="$store.state.companyTheme">
                  <div class="pa-3">
                    <v-avatar
                      size="36"
                      light
                      style="float: left"
                      class="ma-0 mr-2 custom-avatar-style" 
                      rounded="lg"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                      >
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-cellphone</v-icon>
                    </v-avatar>
                    <h3 class="pt-1 pb-4">{{ $t('employees.userAccountAndRegistration', $store.state.locale)}}</h3>
                    
                    <v-divider class="mb-3"></v-divider>

                    <v-alert type="info" prominent text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mb-0 pa-2" style="font-size: 14px"  v-html="$t('employees.userAccountAndRegistrationInfo', $store.state.locale)"></v-alert>

                    <div v-if="selectedEmployeeInfo.loginAccount && selectedEmployeeInfo.userId" class="pt-3 text-center">
                      <v-progress-circular class="ma-4" v-if="userAccountLoading" :size="35" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" indeterminate></v-progress-circular>
                      <div v-else>
                        <p class="ma-0">{{ $t('employees.employeeConnectToUseraccount', $store.state.locale) }}</p>
                        <div style="font-size: 16px" class="pa-4 pb-2 ma-0">
                          <div class="circle-wrapper pa-0 px-2" style="display: inline-block">
                            <div class="circle" style="position: relative" v-bind:class="{ active: selectedEmployeeInfo.loginAccount, disabled: !selectedEmployeeInfo.loginAccountActive }"></div>
                          </div>
                          {{ userAccount.email }}
                        </div>
                        <p v-if="selectedEmployeeInfo.loginAccountActive" class="pb-2 ma-0"><i>{{ $t('employees.useraccountActive', $store.state.locale) }}</i></p>
                        <p v-else class="pb-2 ma-0"><i>{{ $t('employees.useraccountNotActive', $store.state.locale) }}</i></p>

                        <v-btn dark v-if="selectedEmployeeInfo.loginAccountActive" class="mt-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" block depressed @click="deactivateUserAccount()">{{ $t('employees.deactivateUseraccount', $store.state.locale) }}</v-btn>
                        <v-btn dark v-else class="mt-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" block depressed @click="activateUserAccount()">{{ $t('employees.activateUseraccount', $store.state.locale) }}</v-btn>
                        <v-btn dark v-if="selectedEmployeeInfo.userId" class="mt-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" outlined block depressed @click="disconnectUserAccount()">{{ $t('employees.disconnectUseraccount', $store.state.locale) }}</v-btn>

                        <!-- / DEVICES \ -->
                        <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardHeader" class="mt-3">
                          <h3 class="py-2">{{ $t('employees.useOnPhonesAndTables', $store.state.locale) }}</h3>
                          <v-container class="ma-0 py-0 text-left" v-if="userAccount && userAccount.registeredDevices && userAccount.registeredDevices.length > 0">
                            <v-layout row wrap class="pb-2">
                              <v-flex xs1></v-flex>
                              <v-flex xs6><strong>{{ $t('general.name', $store.state.locale) }}</strong></v-flex>
                              <v-flex xs5><strong>{{ $t('employees.activeSince', $store.state.locale) }}</strong></v-flex>
                            </v-layout>
                            <v-layout row wrap v-for="(device, index) in userAccount.registeredDevices" :key="index" style="border-top: 1px solid #eee; padding: 4px 4px 2px 4px">
                              <v-flex xs1>
                                <v-tooltip dark right>
                                  <template v-slot:activator="{ on }">
                                    <v-btn v-on="on" icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeRegisteredDevice(index)">
                                      <v-icon small>mdi-delete</v-icon>
                                    </v-btn>
                                  </template>
                                  <span class="tooltip-text-white">{{ $t('employees.removeDeviceRegistrationInfo', $store.state.locale) }}</span>
                                </v-tooltip>
                              </v-flex>
                              <v-flex xs6><span style="line-height: 38px">{{ device.deviceName }}</span></v-flex>
                              <v-flex xs5><span style="font-size: 12px; line-height: 38px">{{ device.registrationTime.seconds | moment("D MMMM YYYY - H:mm") }}u</span></v-flex>
                            </v-layout>
                          </v-container>
                          <div v-else class="pa-4 text-center">
                            <v-icon size="35" class="pb-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-cellphone-erase</v-icon><br />
                            <p class="ma-0" v-html="$t('employees.noDeviceRegistration', $store.state.locale)"></p>
                          </div>
                        </v-card>
                        <!-- \ DEVICES / -->
                      </div>
                    </div>


                    <div v-else>
                      <div class="pt-4 text-center">
                        <v-btn dark class="mt-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" block depressed :outlined="!newUserAccount" @click="setUserAccountType('new')">{{ $t('employees.createNewUseraccount', $store.state.locale) }}</v-btn>
                        <v-btn dark class="mt-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" block depressed :outlined="!existingUserAccount" @click="setUserAccountType('existing')">{{ $t('employees.connectToExistingUser', $store.state.locale) }}</v-btn>
                      </div>
                      <div v-if="newUserAccount">
                        <v-select
                          :items="userRoles"
                          v-model="employeeUserRole"
                          :label="$t('general.role', $store.state.locale)"
                          item-text="name"
                          item-value="id"
                          prepend-inner-icon="mdi-shield-check"
                          required
                          outlined
                          dense
                          class="mt-4"
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          background-color="menu"
                        ></v-select>

                        <v-select
                          :label="$t('general.language', $store.state.locale)"
                          outlined
                          dense
                          :hint="$t('user.languageHint', $store.state.locale)"
                          persistent-hint
                          class="mt-4"
                          content-class="pa-0 ma-0 mr-4"
                          :items="availableLanguages"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          v-model="employeeUserLanguage"
                          background-color="menu"
                          item-text="languageCode"
                          >
                          <template slot="selection" slot-scope="data" class="ma-0 pa-0">
                            <country-flag :country="data.item.flagCode" size='small'/>
                            <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                          </template>
                          <template slot="item" slot-scope="data">
                            <country-flag :country="data.item.flagCode" size='small'/>
                            <span>{{ data.item.languageName[$store.state.locale.toLowerCase()] }}</span>
                          </template>
                        </v-select>
                        <v-text-field
                          :label="$t('general.email', $store.state.locale)"
                          v-model="employeeUserEmail"
                          :autocomplete="false"
                          required
                          :rules="[rules.required, rules.email]"
                          prepend-inner-icon="mdi-email"
                          outlined
                          dense
                          class="mt-4"
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          background-color="menu"
                        ></v-text-field>
                        <v-text-field
                          :label="$t('employees.password', $store.state.locale)"
                          v-model="employeeUserPassword"
                          prepend-inner-icon="mdi-lock"
                          :hint="$t('userRules.passwordHint', $store.state.locale)"
                          counter
                          :rules="[rules.required, rules.min]"
                          required
                          outlined
                          dense
                          class="mt-4"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          background-color="menu"
                        ></v-text-field>
                        <v-switch class="ma-0 mt-1 pa-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :label="$t('user.emailNewAccount', $store.state.locale)" v-model="employeeUserSendEmail" hide-details></v-switch>
                        
                        <v-alert v-if="userAccountFeedback" text :value="true" class="ma-0 mt-2 pa-2" style="border-radius: 0" type="warning">{{ userAccountFeedback }}</v-alert>

                        <v-btn dark class="mt-3" :loading="saveDisabled" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" block depressed :disabled="!checkNewUserAccountData" :dark="checkNewUserAccountData" @click="updateEmployee()">{{ $t('userRules.createUserAccountBranch', $store.state.locale) }}</v-btn>

                      </div>

                      <div v-if="existingUserAccount">
                        <v-text-field
                          :label="$t('general.email', $store.state.locale)"
                          v-model="searchEmail"
                          :autocomplete="false"
                          required
                          dense
                          prepend-inner-icon="mdi-email"
                          outlined
                          class="mt-4"
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          background-color="menu"
                        ></v-text-field>
                        <v-btn class="mt-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" block depressed :dark="!searchUserDisabled" :loading="findUserLoading" :disabled="searchUserDisabled" @click="findUsers()">{{ $t('general.search', $store.state.locale) }} </v-btn>

                        <div class="text-center mt-4" v-if="foundUsers && foundUsers.length > 0 && userSearched">
                          <h4>{{ $t('employees.availableUsers', $store.state.locale) }}</h4>
                        </div>
                        <v-card outlined flat class="mt-2" v-if="foundUsers && foundUsers.length > 0 && userSearched">
                          <v-list class="py-0" dense>
                            <v-list-item-group v-for="(user, i) in foundUsers" :key="i">
                              <v-divider v-if="i != 0"></v-divider>
                              <v-list-item :disabled="user.employeeId ? true : false" @click="selectUser(user)">
                                <v-list-item-content>
                                  <v-list-item-title>{{ user.email }}</v-list-item-title>
                                  <v-list-item-subtitle v-if="user.employeeId">{{ $t('employees.userConnectedToEmployees', $store.state.locale) }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon v-if="!user.employeeId">
                                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-chevron-right</v-icon>
                                </v-list-item-icon>
                                <v-list-item-icon v-if="user.employeeId">
                                  <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary">mdi-account-lock</v-icon>
                                </v-list-item-icon>
                              </v-list-item>
                              
                            </v-list-item-group>
                          </v-list>
                        </v-card>
                        <div class="mt-3 text-center" v-if="foundUsers.length == 0 && userSearched">
                          <p>{{ $t('employees.noUserFound', $store.state.locale) }}</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </v-card>


              </v-flex>
              <!-- \ Column Right / -->

            </v-layout>
          </v-container>
        </v-tab-item>
        <!-- \ Employee Basic / -->

        <!-- / Employee Schedule \ -->
        <v-tab-item value="schedule" :transition="tabTransition" :reverse-transition="tabTransition">
          <v-card flat>
            <v-card-text class="pa-2 ma-0" v-if="!employeeDataLoading">
              <v-container grid-list-md class="pa-0" fluid>
                <v-layout row wrap  :disabled="selectedEmployeeInfo.deleted && selectedEmployeeInfo.deleted.deleted">
                  <v-flex md4 xs12>
                    <v-select
                      :items="employeeBranches"
                      item-text="name"
                      item-value="id"
                      prepend-inner-icon="mdi-map-marker"
                      v-model="selectedBranch"
                      :label="$t('general.branch', $store.state.locale)"
                      outlined
                      dense
                      v-on:input="getEmployeeWeekScheduleData()"
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      background-color="background"
                    >
                    </v-select>
                  </v-flex>
                  <v-flex md8 xs12>
                    <div class="text-right">
                      <v-btn
                        v-if="_.indexOf(selectedEmployeeInfo.branches, selectedBranch) >= 0"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        :dark="basicScheduleChanged"
                        outlined
                        height="40"
                        :disabled="!basicScheduleChanged"
                        :depressed="!basicScheduleChanged"
                        @click="saveBasicScheduleDatesEmployee()"
                        >{{ $t('general.saveChanged', $store.state.locale) }}<v-icon right dark>mdi-chevron-right</v-icon></v-btn
                      >
                    </div>
                  </v-flex>
                </v-layout>

               

                <!-- <v-layout row wrap v-if="_.indexOf(selectedEmployeeInfo.branches, selectedBranch) >= 0">
                  <v-flex xs4>
                   
                  </v-flex>
                  <v-flex xs4>
                    
                  </v-flex>
                  <v-flex xs4>
                    
                  </v-flex>
                </v-layout> -->

                <v-layout row wrap>
                  <v-flex xs12 :key="employeeWeekSchedule.loading">
                    
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12" lg="4" class="py-0 pl-0">
                        <h4 class="mt-5">
                          {{ $t('employees.workingDates', $store.state.locale) }} 
                        </h4> 
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-menu
                          ref="scheduleStartDateDialog"
                          :close-on-content-click="false"
                          v-model="scheduleStartDateDialog"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                          >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              clearable
                              v-on="on"
                              :value="scheduleStartDate.readable"
                              :label="$t('general.startDate', $store.state.locale)"
                              :disabled="selectedEmployeeInfo.deleted && selectedEmployeeInfo.deleted.deleted" 
                              prepend-inner-icon="mdi-calendar-today"
                              readonly
                              dense
                              @click:clear="resetScheduleStartDate()"
                              outlined
                              :hint="`${selectedEmployeeInfo.firstName} ${selectedEmployeeInfo.lastName} ${$t('employees.startDateHint', $store.state.locale)}`"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              class="mt-0"
                              background-color="background"
                            ></v-text-field>
                          </template>
                          <v-date-picker ref="picker" v-model="scheduleStartDateRaw" no-title :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @input="scheduleStartDateDialog = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-menu
                          ref="scheduleEndDateDialog"
                          :close-on-content-click="false"
                          v-model="scheduleEndDateDialog"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="290px"
                          >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              clearable
                              v-on="on"
                              class="mt-0"
                              :value="scheduleEndDate.readable"
                              :label="$t('general.endDate', $store.state.locale)"
                              prepend-inner-icon="mdi-calendar-today"
                              @click:clear="resetScheduleEndDate()"
                              readonly
                              dense
                              outlined
                              :disabled="selectedEmployeeInfo.deleted && selectedEmployeeInfo.deleted.deleted" 
                              :hint="`${selectedEmployeeInfo.firstName} ${selectedEmployeeInfo.lastName} ${$t('employees.endDateHint', $store.state.locale)}`"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                              background-color="background"
                            ></v-text-field>
                          </template>
                          <v-date-picker ref="picker" v-model="scheduleEndDateRaw" no-title :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @input="scheduleEndDateDialog = false"></v-date-picker>
                        </v-menu>
                      </v-col> 
                    </v-row>
                   
                    <v-divider class="mt-n4"></v-divider>
                
                    <h3 class="my-2">{{ $t('employees.weekSchedule', $store.state.locale) }}</h3> 

                    <v-card flat class=" pa-1" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" v-if="!employeeWeekSchedule.loading">
                      <v-container grid-list-md class="pa-0" fluid>
                        <v-layout row wrap>
                          <v-flex md4>
                            <div class="text-left">
                              <v-btn class="mx-1 my-1" dark outlined small height="40" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="employeeWeekSchedule.loading" :dark="!employeeWeekSchedule.loading" @click="getEmployeeWeekScheduleData('previous')">
                                <v-icon>mdi-chevron-left</v-icon>
                              </v-btn>
                              <v-menu
                        
                                v-model="employeeWeekScheduleDateDialog"
                                :nudge-right="0"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                                >
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" class="mx-0 my-1" dark text small height="40" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="employeeWeekSchedule.loading" :dark="!employeeWeekSchedule.loading">
                                <v-icon>mdi-calendar</v-icon>
                              </v-btn> 
                                </template>
                                <v-date-picker ref="picker" v-model="employeeWeekScheduleDate" no-title locale-first-day-of-year="4" show-week :show-current="false" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @input="getEmployeeWeekScheduleData('custom')"></v-date-picker>
                              </v-menu>
                              
                              <v-btn class="mx-1 my-1" dark outlined small height="40" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="employeeWeekSchedule.loading" :dark="!employeeWeekSchedule.loading" @click="getEmployeeWeekScheduleData('next')">
                                <v-icon>mdi-chevron-right</v-icon>
                              </v-btn> 
                              
                              <v-tooltip dark top v-if="(($store.state.activeUserRoleNumber == 0 && !$store.state.demoMode) || ($store.state.activeUserRoleNumber < 4 && employeeWeekScheduleIsInFuture())) && employeeWeekSchedule.docId">
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" class="mx-1 my-1" icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="employeeWeekSchedule.loading" @click="copyScheduleFromDataBase(employeeWeekSchedule)">
                                    <v-icon size="20">mdi-content-copy</v-icon>
                                  </v-btn>
                                </template>
                                <span class="tooltip-text-white">{{ $t('employees.copyScheduleInfo', $store.state.locale) }} {{ employeeWeekSchedule.weekNumber }}</span>
                              </v-tooltip>

                              <v-tooltip dark top  v-if="(($store.state.activeUserRoleNumber == 0 && !$store.state.demoMode) || ($store.state.activeUserRoleNumber < 4 && employeeWeekScheduleIsInFuture()))">
                                <template v-slot:activator="{ on }">
                                  <v-btn 
                                    v-on="on"
                                    class="mx-1 my-1" 
                                    icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                    :disabled="employeeWeekSchedule.loading || (!$store.state.employeeDatabaseScheduleSaveData || $store.state.employeeDatabaseScheduleSaveData.employeeId != selectedEmployeeInfo.id || $store.state.employeeDatabaseScheduleSaveData.docId == employeeWeekSchedule.docId)" 
                                    @click="pastScheduleFromDataBase()">
                                    <v-icon size="20">mdi-content-paste</v-icon>
                                  </v-btn>
                                </template>
                                <span v-if="$store.state.employeeDatabaseScheduleSaveData" class="tooltip-text-white">{{ $t('employees.pastScheduleInfo', $store.state.locale) }} {{ $store.state.employeeDatabaseScheduleSaveData.weekNumber }}</span>
                              </v-tooltip>
                  

                              <v-tooltip dark top v-if="(($store.state.activeUserRoleNumber == 0 && !$store.state.demoMode) || ($store.state.activeUserRoleNumber < 4 && employeeWeekScheduleIsInFuture()))" >
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" class="mx-1 my-1" icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="employeeWeekSchedule.loading" @click="editScheduleFromDataBase(employeeWeekSchedule.docId)">
                                    <v-icon>mdi-pencil</v-icon>
                                  </v-btn>
                                </template>
                                <span v-if="(employeeWeekSchedule.docId && employeeWeekSchedule.type == 'basicSchedule') ||  !employeeWeekSchedule.type" class="tooltip-text-white">{{ $t('employees.customEditScheduleInfo', $store.state.locale) }} {{ employeeWeekSchedule.weekNumber }}</span>
                                <span v-else class="tooltip-text-white">{{ $t('employees.customEditCustomScheduleInfo', $store.state.locale) }} {{ employeeWeekSchedule.weekNumber }}</span>
                              </v-tooltip>

                              <v-tooltip dark top  v-if="(($store.state.activeUserRoleNumber == 0 && !$store.state.demoMode) || ($store.state.activeUserRoleNumber < 4 && employeeWeekScheduleIsInFuture())) && employeeWeekSchedule.docId">
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" class="mx-1 my-1" icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :disabled="employeeWeekSchedule.loading" @click="removeScheduleFromDataBase(employeeWeekSchedule.docId)">
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span class="tooltip-text-white">{{ $t('employees.deleteScheduleInfo', $store.state.locale) }} {{ employeeWeekSchedule.weekNumber }}</span>
                              </v-tooltip>

                            </div>
                          </v-flex>
                          <v-flex md4>
                            <div class="text-center">
                              <!-- <v-chip label dark v-if="$store.state.activeUserRoleNumber == 0 && !$store.state.demoMode" :color="employeeWeekSchedule.type == 'basicSchedule' ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : employeeWeekSchedule.type == 'scheduleCollectionDocument' ? $themes[$store.state.companyTheme][$store.state.themeModus].secondary : $themes[$store.state.companyTheme][$store.state.themeModus].tertiary" small><strong>{{ employeeWeekSchedule.type }}</strong>&nbsp;<span v-if="employeeWeekSchedule.collectionType">  ({{ employeeWeekSchedule.collectionType }})</span></v-chip> -->
                              <v-chip v-if="!employeeWeekSchedule.docId" label dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" x-small>{{ $t('employees.basicSchedule', $store.state.locale) }} <span v-if="$store.state.activeUserRoleNumber == 0 && !$store.state.demoMode"><strong>({{ employeeWeekSchedule.collectionType }})</strong></span></v-chip>
                              <v-chip v-if="employeeWeekSchedule.docId && employeeWeekSchedule.type == 'basicSchedule'" label dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" x-small>{{ $t('employees.'+ employeeWeekSchedule.type , $store.state.locale) }} <span v-if="$store.state.activeUserRoleNumber == 0 && !$store.state.demoMode"><strong>({{ employeeWeekSchedule.collectionType }})</strong></span></v-chip>
                              <v-chip v-if="employeeWeekSchedule.docId && employeeWeekSchedule.type == 'customChangedSchedule'" label dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" x-small>{{ $t('employees.'+ employeeWeekSchedule.type , $store.state.locale) }} <span v-if="$store.state.activeUserRoleNumber == 0 && !$store.state.demoMode"><strong>({{ employeeWeekSchedule.collectionType }})</strong></span></v-chip>

                              <h3 style="font-size: 14px">{{ $t('general.week', $store.state.locale) }}  {{ employeeWeekSchedule.weekNumber }}</h3>
                              <p class="ma-0" style="font-size: 12px">{{ employeeWeekSchedule.days[0].date | moment("DD-MM-YYYY") }} - {{ employeeWeekSchedule.days[6].date | moment("DD-MM-YYYY") }}</p>
                            </div>
                          </v-flex>
                          <v-flex md2>
                            <v-card flat class="pa-1 mr-1 text-center">
                              <h4 style="font-size: 13px" :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" >{{ $t('employees.scheduledWeekHours', $store.state.locale) }} </h4>
                              <div v-if="employeeWeekSchedule.combinedMinutes && employeeWeekSchedule.combinedMinutes > 0">
                                {{ minutesToHourFormat(employeeWeekSchedule.combinedMinutes, 5) }} {{ $t('general.hour', $store.state.locale) }}
                              </div>
                              <div v-else>
                                -
                              </div>
                            </v-card>
                            
                          </v-flex>

                          <v-flex md2>
                            <v-card flat class="pa-1 mr-1 text-center">                             
                              <h4 style="font-size: 14px" :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" >{{ $t('employees.basicSchedule', $store.state.locale) }} </h4>
                              <div v-if="employeeWeekSchedule.minutes > 0">
                                <div v-if="employeeWeekSchedule.minutesAverage == employeeWeekSchedule.minutes">
                                  {{ minutesToHourFormat(employeeWeekSchedule.minutes, 5) }} {{ $t('general.hour', $store.state.locale) }}
                                </div>
                                <div v-else>
                                  {{ minutesToHourFormat(employeeWeekSchedule.minutes, 5) }} {{ $t('general.hour', $store.state.locale) }}
                                  ({{ $t('employees.scheduleAverage', $store.state.locale) }} : {{  minutesToHourFormat(employeeWeekSchedule.minutesAverage,5) }} {{ $t('general.hour', $store.state.locale) }})
                                </div>
                              </div>
                              <div v-else>
                                -
                              </div>
                            </v-card>
                          </v-flex>

                        </v-layout>
                      </v-container>

                      <v-layout  class="grouplessons-schedule-wrapper" >
                        <div class="day-of-week text-center new-schedule" :class="{'alternately': employeeWeekSchedule.days[index].type == 'alternately'}" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card }" v-for="(day, index) in daysOfWeek" :key="index">
                        <div class="schedule-day-title">
                        
                          

                          <div>{{ $t('general.' + day.name, $store.state.locale) }}</div>
                          <div style="font-size: 16px; opacity: 0.3" >{{ employeeWeekSchedule.days[index].date | moment("D")}}</div>

                          <v-divider class="pt-2 pa-0"></v-divider>
                         
                          <div style="height: 30px; width: 100%">
                            <div v-if="employeeWeekSchedule.days[index].minutes > 0" style="font-size:12px">({{ minutesToHourFormat(employeeWeekSchedule.days[index].minutes, 5) }})</div>
                          </div>

                          <v-divider class="pa-2"></v-divider>

                        </div>
                          <div :style="{'height' : scheduleSpaces + 30 + 'px'}" style="position: relative;">
                            <div v-if="employeeWeekSchedule.schedule[day.id].length > 0 || employeeWeekScheduleOtherBranches.schedule[day.id].length > 0" :style="{'height' : scheduleSpaces + 'px'}" style="position: absolute; width: 100%;"> 
                              <!-- <div class="grouplesson" :class="{'grouplesson-border': time.type != 'pause'}" v-for="(time, index) in employeeWeekSchedule.schedule[day.id]" :key="index"  :style="[{'color': time.type != 'pause' ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].primary}, {'height' : calculateScheduleMinutes(time)  + 'px'}, {'marginTop' : calculateScheduleDistance('overview', day.id, index, null)  + 'px'}]"> -->
                              <div class="current-branch-schedule-wrapper" :class="{'shared': employeeWeekScheduleOtherBranches.days[index] && employeeWeekScheduleOtherBranches.days[index].exist }">
                                <div class="grouplesson" v-for="(time, index) in employeeWeekSchedule.schedule[day.id]" :key="index"  :class="[{'extra' : time.type == 'extraPresence'}]" :style="[{'backgroundColor': getTimeBlockColor(time.type)}, {'opacity' : time.type == 'pause' ? 0.8 : 1}, {'height' : calculateScheduleMinutes(time)  + 'px'}, {'marginTop' : calculateScheduleDistance('overview', day.id, index, null, 'employeeWeekSchedule')  + 'px'}]">
                                  <div class="grouplesson-time" :style="[{'color': time.type != 'pause' ? $themes[$store.state.companyTheme][$store.state.themeModus].card : $themes[$store.state.companyTheme][$store.state.themeModus].text}]">
                                    {{ time.start }}-{{ time.end }} 
                                    <span v-if="time.reason"><br> {{ $t('employees.'+ time.reason, $store.state.locale)}}</span>
                                    <span v-if="time.extra" style="font-weight: 300"><br> {{time.extra}}</span>
                                  </div>
                                  <div class="grouplesson-edit">
                                  </div>
                                </div>
                              </div>
                              <v-tooltip dark top>
                                <template v-slot:activator="{ on }">
                                  <div v-on="on" class="different-branch-schedule-wrapper" :class="[{'exist': employeeWeekScheduleOtherBranches.days[index] && employeeWeekScheduleOtherBranches.days[index].exist }, {'full': employeeWeekSchedule.schedule[day.id].length == 0 }]">    
                                    <div class="different-branch-schedule-wrapper-content">     
                                      <div class="grouplesson" v-for="(time, index) in employeeWeekScheduleOtherBranches.schedule[day.id]" :key="index"  :style="[{'backgroundColor': time.type != 'pause' ? $themes[$store.state.companyTheme][$store.state.themeModus].primary + '50' : $themes[$store.state.companyTheme][$store.state.themeModus].card}, {'height' : calculateScheduleMinutes(time)  + 'px'}, {'marginTop' : calculateScheduleDistance('overview', day.id, index, null, 'employeeWeekScheduleOtherBranches')  + 'px'}]"> </div>
                                    </div>
                                  </div>
                                </template>
                                <span class="tooltip-text-white">{{ $t('employees.employeeScheduledBranches', $store.state.locale) }} {{ employeeScheduledBranches(index) }}</span>
                                </v-tooltip> 
                            </div>
                            <div v-else class="schedule-day-empty">

                            </div>
                          </div>
                        </div>
                      </v-layout>
                    </v-card>

                    <div v-else>
                      <div style="position: relative; padding: 200px 0;">
                        <div class="thrive-loader"></div>
                      </div>
                    </div>

                    <v-card outlined class="pa-3 my-3" >
                      <h3 class="mb-2">
                        {{ $t('employees.basicSchedule', $store.state.locale) }} 
                      </h3> 

                      <v-row class="ma-0 pa-0" align="center">
                        <v-col cols="6" class="pa-0" style="max-width: 150px">
                          <span class="schedule-overview-day">{{ $t('employees.scheduleActiveFrom', $store.state.locale) }}: </span>
                        </v-col>
                        <v-col cols="6" class="pa-0"> 
                          <span class="schedule-overview-day" v-if="selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].basicScheduleLastChanged && selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].basicScheduleLastChanged.seconds">{{selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].basicScheduleLastChanged.seconds | moment("DD-MM-YYYY")}}</span>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 pa-0 mb-3" align="center">
                        <v-col cols="6" class="pa-0" style="max-width: 150px">
                          <span class="schedule-overview-day">{{ $t('employees.scheduleWeekContractHours', $store.state.locale) }}: </span>
                        </v-col>
                        <v-col cols="6" class="pa-0"> 
                          <span class="schedule-overview-day" v-if="selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].minutes">
                             <span v-if="selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].minutes.odd != selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].minutes.even">
                              {{ minutesToHourFormat(selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].minutes.odd, 5) }} / {{ minutesToHourFormat(selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].minutes.even, 5) }} {{  $t('general.hour', $store.state.locale) }} ({{ $t('employees.scheduleAverage', $store.state.locale) }} : {{  minutesToHourFormat(selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].minutes.total,5) }} {{  $t('general.hour', $store.state.locale) }})
                             </span>
                             <span v-else> {{ minutesToHourFormat(selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].minutes.total, 5) }} {{  $t('general.hour', $store.state.locale) }}</span>
                          </span>
                        </v-col>
                      </v-row>
                      <v-row class="ma-0 pa-0" align="center" v-if="selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Sun && selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Sun.length > 0">
                        <v-col cols="3" class="pa-0" style="max-width: 100px">
                          <span class="schedule-overview-day">{{ $t('general.sunday', $store.state.locale) }}:</span>
                        </v-col>
                        <v-col cols="9" class="pa-0"> 
                          <span v-for="(schedule, index) in selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Sun" :key="index" :class="'schedule-overview-item type-' +schedule.type">
                              {{ schedule.start}} - {{ schedule.end}}
                          </span>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row class="ma-0 pa-0" align="center" v-if="selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Mon && selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Mon.length > 0">
                        <v-col cols="3" class="pa-0" style="max-width: 100px">
                          <span class="schedule-overview-day">{{ $t('general.monday', $store.state.locale) }}:</span>
                        </v-col>
                        <v-col cols="9" class="pa-0">     
                          <span v-for="(schedule, index) in selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Mon" :key="index" :class="'schedule-overview-item type-' +schedule.type">
                            {{ schedule.start}} - {{ schedule.end}}
                          </span>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row class="ma-0 pa-0" align="center">
                        <v-col cols="3" class="pa-0" style="max-width: 100px">
                          <span class="schedule-overview-day">{{ $t('general.tuesday', $store.state.locale) }}:</span>
                        </v-col>
                        <v-col cols="9" class="pa-0">
                          <div v-if="selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Tue && selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Tue.length > 0">
                            <span v-for="(schedule, index) in selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Tue" :key="index" :class="'schedule-overview-item type-' + schedule.type">
                                {{ schedule.start}} - {{ schedule.end}}
                            </span>
                          </div>
                          <div v-else>-</div>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row class="ma-0 pa-0" align="center">
                        <v-col cols="3" class="pa-0" style="max-width: 100px">
                          <span class="schedule-overview-day">{{ $t('general.wednesday', $store.state.locale) }}:</span>
                        </v-col>
                        <v-col cols="9" class="pa-0">
                          <div v-if="selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Wed && selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Wed.length > 0">
                            <span v-for="(schedule, index) in selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Wed" :key="index" :class="'schedule-overview-item type-' +schedule.type">
                                {{ schedule.start}} - {{ schedule.end}}
                            </span>
                          </div>
                          <div v-else>-</div>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row class="ma-0 pa-0" align="center">
                        <v-col cols="3" class="pa-0" style="max-width: 100px">
                          <span class="schedule-overview-day">{{ $t('general.thursday', $store.state.locale) }}:</span>
                        </v-col>
                        <v-col cols="9" class="pa-0">
                          <div v-if="selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Thu && selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Thu.length > 0">
                            <span v-for="(schedule, index) in selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Thu" :key="index" :class="'schedule-overview-item type-' +schedule.type">
                                {{ schedule.start}} - {{ schedule.end}}
                            </span>
                          </div>
                          <div v-else>-</div>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row class="ma-0 pa-0" align="center">
                        <v-col cols="3" class="pa-0" style="max-width: 100px">
                          <span class="schedule-overview-day">{{ $t('general.friday', $store.state.locale) }}:</span>
                        </v-col>
                        <v-col cols="9" class="pa-0">
                          <div v-if="selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Fri && selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Fri.length > 0">
                            <div v-for="(schedule, index) in selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Fri" :key="index" :class="'schedule-overview-item type-' +schedule.type">
                                {{ schedule.start}} - {{ schedule.end}}
                            </div>
                          </div>
                          <div v-else>-</div>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-row class="ma-0 pa-0" align="center">
                        <v-col cols="3" class="pa-0" style="max-width: 100px">
                          <span class="schedule-overview-day">{{ $t('general.saturday', $store.state.locale) }}:</span>
                        </v-col>
                        <v-col cols="9" class="pa-0">
                          <div v-if="selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Sat && selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Sat.length > 0">
                            <span v-for="(schedule, index) in selectedEmployeeInfoOriginal.basicSchedule[selectedBranch].Sat" :key="index" :class="'schedule-overview-item type-' +schedule.type">
                                {{ schedule.start}} - {{ schedule.end}}
                            </span>
                          </div>
                          <div v-else>-</div>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-btn class="mt-3" :disabled="selectedEmployeeInfo.deleted && selectedEmployeeInfo.deleted.deleted" :dark="selectedEmployeeInfo.deleted && !selectedEmployeeInfo.deleted.deleted" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" block depressed @click="changeBasicSchedule()">
                        <v-icon left>mdi-pencil</v-icon> {{ $t('employees.basicSchedule', $store.state.locale) }} 
                      </v-btn>
                    </v-card>


                  </v-flex>
                </v-layout>
              </v-container>

              <div v-if="_.indexOf(selectedEmployeeInfo.branches, selectedBranch) >= 0 && exeptionsLoading">
                <div style="position: relative; padding: 75px 0">
                  <div class="thrive-loader"></div>
                </div>
              </div>

              <v-container class="ma-0 pa-0"  grid-list-md fluid>
                    <v-layout row wrap class=" pa-0" >
                      <v-flex xs6 class="ma-0" d-flex>
                        <v-card v-if="_.indexOf(selectedEmployeeInfo.branches, selectedBranch) >= 0 && !exeptionsLoading" outlined class="mt-2" :style="{ backgroundColor: $vuetify.theme.themes[theme].background }" style="width: 100%">
                          <v-card-title>
                            <h3>{{ $t('employees.absence', $store.state.locale) }}</h3>
                            <v-spacer></v-spacer>
                            <v-btn fab small icon @click.stop="addAbcenceException()">
                              <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-plus-circle</v-icon>
                            </v-btn>
                          </v-card-title>
                          <div class="px-4">
                            <v-container class="ma-0 pa-0">
                              <v-layout row wrap class="ma-0 pa-0">
                                <v-flex xs6 class="ma-0 pa-0">
                                  <v-btn
                                    class="ma-0 pa-0"
                                    style="border-radius: 0"
                                    block
                                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                    @click="(exeptionDisplay = 'past'), getEmployeeExeptions()"
                                    :outlined="exceptionFuture"
                                    depressed
                                    dark
                                    >{{ $t('employees.past', $store.state.locale) }} </v-btn
                                  >
                                </v-flex>
                                <v-flex xs6 class="ma-0 pa-0">
                                  <v-btn
                                    class="ma-0 pa-0"
                                    style="border-radius: 0"
                                    block
                                    dark
                                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                    @click="(exeptionDisplay = 'future'), getEmployeeExeptions()"
                                    :outlined="!exceptionFuture"
                                    depressed
                                    >{{ $t('employees.future', $store.state.locale) }} </v-btn
                                  >
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </div>

                          <v-data-table
                            :headers="headersExceptions"
                            :items="employeeExceptions"
                            :items-per-page="-1"
                            item-key="name"
                            :disabled="selectedEmployeeInfo.deleted && selectedEmployeeInfo.deleted.deleted"
                            hide-default-footer
                            :style="{ backgroundColor: $vuetify.theme.themes[theme].background }"
                            :no-data-text="$t('employees.noExceptions', $store.state.locale)"
                            >
                            <template v-slot:item="{ item, index }">
                              <tr>
                                <td>
                                  <span v-if="item.startdate == item.enddate"> {{ item.startdate | moment("D MMMM YYYY") }}</span>
                                  <span v-else>{{ item.startdate | moment("D MMMM YYYY") }} t/m {{ item.enddate | moment("D MMMM YYYY") }}</span>
                                </td>
                                <td>
                                  <span v-if="item.time.length > 0">
                                    <div style="font-size: 13px" v-for="(exceptionTime, index) in item.time" :key="index">{{ exceptionTime.start }}u - {{ exceptionTime.end }}u</div>
                                  </span>
                                  <span v-else style="font-size: 13px"> {{ $t('employees.entireDay', $store.state.locale) }}</span>
                                </td>
                                <td>
                                  <span v-for="(reason, index) in $leaveItems.absentItems" :key="index">
                                    <span v-if="item.reason == reason.id">{{ reason.name }}</span>
                                  </span>
                                  <div style="font-size: 12px; font-style: italic" v-if="item.extra">{{ item.extra }}</div>
                                </td>
                                <td>
                                  <v-row>
                                    <v-btn icon @click="editAbcenceException(item, index)">
                                      <v-icon size="20" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-pencil</v-icon>
                                    </v-btn>
                                    <v-btn icon @click="removeException(item, index)">
                                      <v-icon size="20" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon>
                                    </v-btn>
                                  </v-row>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-card>
                      </v-flex>
                      <v-flex xs6 class="ma-0" d-flex>
                        <v-card v-if="_.indexOf(selectedEmployeeInfo.branches, selectedBranch) >= 0 && !presenceLoading" outlined class="mt-2" :style="{ backgroundColor: $vuetify.theme.themes[theme].background }" style="width: 100%">
                          <v-card-title>
                            <h3>{{ $t('employees.presence', $store.state.locale) }}</h3>
                            <v-spacer></v-spacer>
                            <v-btn fab small icon @click.stop="addPresenceException()">
                              <v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >mdi-plus-circle</v-icon>
                            </v-btn>
                          </v-card-title>
                          <div class="px-4">
                            <v-container class="ma-0 pa-0">
                              <v-layout row wrap class="ma-0 pa-0">
                                <v-flex xs6 class="ma-0 pa-0">
                                  <v-btn
                                    class="ma-0 pa-0"
                                    style="border-radius: 0"
                                    block
                                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                    @click="(presenceDisplay = 'past'), getEmployeePresence()"
                                    :outlined="presenceFuture"
                                    depressed
                                    dark
                                    >{{ $t('employees.past', $store.state.locale) }} </v-btn
                                  >
                                </v-flex>
                                <v-flex xs6 class="ma-0 pa-0">
                                  <v-btn
                                    class="ma-0 pa-0"
                                    style="border-radius: 0"
                                    block
                                    dark
                                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                    @click="(presenceDisplay = 'future'), getEmployeePresence()"
                                    :outlined="!presenceFuture"
                                    depressed
                                    >{{ $t('employees.future', $store.state.locale) }} </v-btn
                                  >
                                </v-flex>
                              </v-layout>
                            </v-container>
                          </div>

                          <v-data-table
                            :headers="headersExceptions"
                            :items="employeePresence"
                            :items-per-page="-1"
                            item-key="name"
                            hide-default-footer
                            :style="{ backgroundColor: $vuetify.theme.themes[theme].background }"
                            :no-data-text="$t('employees.noPresence', $store.state.locale)"
                            >
                            <template v-slot:item="{ item, index }">
                              <tr>
                                <td>
                                  <span v-if="item.startdate == item.enddate"> {{ item.startdate | moment("D MMMM YYYY") }}</span>
                                  <span v-else>{{ item.startdate | moment("D MMMM YYYY") }} t/m {{ item.enddate | moment("D MMMM YYYY") }}</span>
                                </td>
                                <td>
                                  <span v-if="item.time.length > 0">
                                    <div style="font-size: 13px" v-for="(presenceTime, index) in item.time" :key="index">{{ presenceTime.start }}u - {{ presenceTime.end }}u</div>
                                  </span>
                                  <span v-else style="font-size: 13px"> {{ $t('employees.entireDay', $store.state.locale) }}</span>
                                </td>
                                <td>
                                  <span v-for="(reason, index) in $leaveItems.presenceItems" :key="index">
                                    <span v-if="item.reason == reason.id">{{ reason.name }}</span>
                                  </span>
                                  <div style="font-size: 12px; font-style: italic" v-if="item.extra">{{ item.extra }}</div>
                                </td>
                                <td>
                                  <v-row>
                                    <v-btn icon @click="editPresenceException(item, index)">
                                      <v-icon size="20" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-pencil</v-icon>
                                    </v-btn>

                                    <v-btn icon @click="removeException(item, index)">
                                      <v-icon size="20" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-delete</v-icon>
                                    </v-btn>
                                  </v-row>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-card>
                      </v-flex>
                    </v-layout>
                  </v-container>

              
            </v-card-text>
          </v-card>
        </v-tab-item>
        <!-- \ Employee Schedule / -->

        <!-- / Employee Exceptions \ -->
        <v-tab-item value="exceptions" :transition="tabTransition" :reverse-transition="tabTransition">
          <v-container grid-list-lg class=" ma-0 pa-0" fluid >
            <v-layout row wrap v-if="selectedEmployeeInfo.contractType">
              <v-flex md3 xs12>
                <v-select
                  :label="$t('general.year', $store.state.locale)"
                  v-model="selectedYear"
                  :items="years"
                  item-text="name"
                  item-value="id"
                  required
                  outlined
                  dense
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  hide-details 
                ></v-select>
              </v-flex>
            </v-layout>
            <v-layout row wrap v-if="selectedEmployeeInfo.contractType">
              <!-- <v-flex md6 xs12  v-if="selectedEmployeeInfo.contractType != 'owner' && selectedEmployeeInfo.contractType != 'seatTenant' && selectedEmployeeInfo.contractType != 'entrepreneur'"> -->
              <v-flex md6 xs12 >
                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
                  <div class="pa-3">
                    <v-avatar
                      size="36"
                      light
                      style="float: left"
                      class="ma-0 mr-2 custom-avatar-style" 
                      rounded="lg"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                      >
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-timer-minus-outline</v-icon>
                    </v-avatar>
                    <h3 class="pt-1 pb-4">{{ $t('employees.absent', $store.state.locale)}}</h3>
                    <v-divider></v-divider>
                  </div>

                  <div v-if="!employeeExceptionsLoading"> 
                    <v-data-table
                      v-if="employeeExceptionsLeave.length > 0"
                      :headers="headersExceptionsAbsent"
                      :items="employeeExceptionsLeave"
                      item-key="name"
                      dense
                      class="dense-table"
                      hide-default-footer
                      :style="{ backgroundColor: $vuetify.theme.themes[theme].background }"
                      :no-data-text="$t('employees.noExceptions', $store.state.locale)"
                      >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>{{ item.startdate | moment("DD-MM-YYYY") }} <span v-if="item.startdate != item.enddate">- {{ item.enddate | moment("DD-MM-YYYY") }}</span></td>
                          <td>
                            <span v-for="(reason, index) in $leaveItems.absentItems" :key="index">
                              <span v-if="item.reason == reason.id">{{ reason.name }}</span>
                            </span>
                            <span v-if="item.extra"> ({{ item.extra }})</span>
                          </td>
                          <td>
                            {{ calculateExceptionTime(item) }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <div v-else class="pa-4 text-center">
                      <i>{{ $t('employees.noAbsentExceptions', $store.state.locale)}}</i>
                    </div>
                  </div>
                  
                  <div v-else style="position: relative; padding: 50px 0">
                    <div class="thrive-loader"></div>
                  </div>
                  
                </v-card>
              </v-flex>
              <v-flex md6 xs12>
                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme" >
                  <div class="pa-3">
                    <v-avatar
                      size="36"
                      light
                      style="float: left"
                      class="ma-0 mr-2 custom-avatar-style" 
                      rounded="lg"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                      >
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-bed</v-icon>
                    </v-avatar>
                    <h3 class="pt-1 pb-4">{{ $t('employees.sick', $store.state.locale)}}</h3>
                    
                    <v-divider></v-divider>
                  </div>

                  <div v-if="!employeeExceptionsLoading"> 
                    <v-data-table
                      v-if="employeeExceptionsSick.length > 0"
                      :headers="headersExceptionsAbsent"
                      :items="employeeExceptionsSick"
                      item-key="name"
                      hide-default-footer
                      dense
                      class="dense-table"
                      :style="{ backgroundColor: $vuetify.theme.themes[theme].background }"
                      :no-data-text="$t('employees.noExceptions', $store.state.locale)"
                      >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>
                            {{ item.startdate | moment("DD-MM-YYYY") }} <span v-if="item.startdate != item.enddate">- {{ item.enddate | moment("DD-MM-YYYY") }}</span>
                          </td>
                          <td>
                            <span v-for="(reason, index) in $leaveItems.absentItems" :key="index">
                              <span v-if="item.reason == reason.id">{{ reason.name }}</span>
                            </span>
                            <span v-if="item.extra"> ({{ item.extra }})</span>
                          </td>
                          <td>
                            {{ calculateExceptionTime(item) }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <div v-else class="pa-4 text-center">
                      <i>{{ $t('employees.noAbsentExceptions', $store.state.locale)}}</i>
                    </div>
                  </div>
                  
                  <div v-else style="position: relative; padding: 50px 0">
                    <div class="thrive-loader"></div>
                  </div>
                </v-card>
                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0 mt-4" :class="$store.state.companyTheme">
                  <div class="pa-3">
                    <v-avatar
                      size="36"
                      light
                      style="float: left"
                      class="ma-0 mr-2 custom-avatar-style" 
                      rounded="lg"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                      >
                      <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-timer-plus-outline</v-icon>
                    </v-avatar>
                    <h3 class="pt-1 pb-4">{{ $t('employees.extra', $store.state.locale)}}</h3>
                    
                    <v-divider></v-divider>
                  </div>
                  <div v-if="!employeeExceptionsLoading"> 
                    <v-data-table
                      v-if="employeeExceptionsExtra.length > 0"
                      :headers="headersExceptionsExtra"
                      :items="employeeExceptionsExtra"
                      item-key="name"
                      dense
                      class="dense-table"
                      hide-default-footer
                      >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>
                            {{ item.startdate | moment("DD-MM-YYYY") }}
                          </td>
                          <td>
                            <span v-for="(reason, index) in $leaveItems.presenceItems" :key="index">
                              <span v-if="item.reason == reason.id">{{ reason.name }}</span>
                            </span>
                            <span v-if="item.extra"> ({{ item.extra }})</span>
                          </td>
                          <td>
                            {{ calculateExceptionTime(item) }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <div v-else class="pa-4 text-center">
                      <i>{{ $t('employees.noAbsentExceptions', $store.state.locale)}}</i>
                    </div>
                  </div>
                  
                  <div v-else style="position: relative; padding: 50px 0">
                    <div class="thrive-loader"></div>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout row wrap v-else>
              <v-flex xs12>
                <div class="text-center pa-4 ma-4"> 
                  <v-avatar
                    size="58"
                    light
                    class="ma-0 mr-2 mt-4 custom-avatar-style" 
                    rounded="lg"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                    >
                    <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-clock</v-icon>
                  </v-avatar>
                  <p class="pa-4"> Vul <i>Type medewerker</i> in om urenstatus te bekijken van {{ selectedEmployeeInfo.firstName }}.</p>
                </div>
                
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        <!-- \ Employee Exceptions / -->

        <!-- / Employee Leave \ -->
        <v-tab-item value="leave" :transition="tabTransition" :reverse-transition="tabTransition">
          <div v-if="!employeeLeaveLoading">
            <div v-if="selectedEmployeeInfo.contractType">
              <v-container grid-list-lg class=" ma-0 pa-0" fluid v-if="selectedEmployeeInfo.contractType != 'owner' && selectedEmployeeInfo.contractType != 'seatTenant' && selectedEmployeeInfo.contractType != 'entrepreneur'">
                <v-layout row wrap>
                  <v-flex md3 xs12>
                    <v-select
                      :label="$t('general.year', $store.state.locale)"
                      v-model="selectedYear"
                      :items="years"
                      item-text="name"
                      item-value="id"
                      required
                      outlined
                      dense
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      hide-details
                      v-on:input="calculateEmployeeLeave()" 
                    ></v-select>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex md4 xs12>
                    <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                      <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                        <v-list-item class="pa-0 pl-4 ma-0">
                          <v-list-item-content>
                            <v-list-item-title>
                              <h3>{{ $t('employees.leaveOpen', $store.state.locale) }}</h3>
                            </v-list-item-title>
                            <v-list-item-subtitle> {{  employeeLeave.total.hours }} {{ $t('general.hour', $store.state.locale) }} <span v-if="employeeLeave.total.minutes"> {{ $t('employees.leaveAnd', $store.state.locale) }} {{ employeeLeave.total.minutes }} {{ $t('general.minutes', $store.state.locale) }}</span></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-avatar
                            size="56"
                            light
                            class="ma-4 custom-avatar-style" 
                            rounded="lg"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                            >
                            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-sigma</v-icon>
                          </v-list-item-avatar>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-flex>
                  <v-flex md4 xs12>
                    <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                      <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                        <v-list-item class="pa-0 pl-4 ma-0">
                          <v-list-item-content>
                            <v-list-item-title>
                              <h3>{{ $t('employees.leaveUsed', $store.state.locale) }}</h3>
                            </v-list-item-title>
                            <v-list-item-subtitle> {{  employeeLeave.used.hours }} {{ $t('general.hour', $store.state.locale) }} <span v-if="employeeLeave.used.minutes"> {{ $t('employees.leaveAnd', $store.state.locale) }} {{ employeeLeave.used.minutes }} {{ $t('general.minutes', $store.state.locale) }}</span></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-avatar
                            size="56"
                            light
                            class="ma-4 custom-avatar-style" 
                            rounded="lg"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" 
                            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].tertiary}" 
                            >
                            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary">mdi-minus</v-icon>
                          </v-list-item-avatar>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-flex>
                  <v-flex md4 xs12>
                    <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                      <v-list class="ma-0 pa-0" disabled :color="$themes[$store.state.companyTheme][$store.state.themeModus].card">
                        <v-list-item class="pa-0 pl-4 ma-0">
                          <v-list-item-content>
                            <v-list-item-title>
                              <h3>{{ $t('employees.leaveCollected', $store.state.locale) }}</h3>
                            </v-list-item-title>
                            <v-list-item-subtitle> {{  employeeLeave.collected.hours }} {{ $t('general.hour', $store.state.locale) }} <span v-if="employeeLeave.collected.minutes"> {{ $t('employees.leaveAnd', $store.state.locale) }} {{ employeeLeave.collected.minutes }} {{ $t('general.minutes', $store.state.locale) }}</span></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-avatar
                            size="56"
                            light
                            class="ma-4 custom-avatar-style" 
                            rounded="lg"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].quaternary" 
                            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].quaternary}" 
                            >
                            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].quaternary">mdi-plus</v-icon>
                          </v-list-item-avatar>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12>
                    <div v-if="employeeLeave.details && employeeLeave.details.length > 0">  
                      <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                        <v-data-table
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :headers="leaveOverviewHeaders"
                          :items-per-page="-1"
                          :items="employeeLeave.overview"
                          hide-default-footer
                          style="background: none"
                          dense
                          class="dense-table"
                          >
                          <template v-slot:body>
                            <tbody>
                              <tr v-if="employeeLeave.lastYearPickedUp.time > 0">
                                <td class="py-1">{{ $t(`employees.transferFromLastYear`, $store.state.locale) }}</td>

                                <td class="text-right">
                                  <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                                    <template v-slot:activator="{ on }">
                                      <span v-on="on">{{ minutesToHourFormat(employeeLeave.lastYearPickedUp.time, 5) }} {{ $t(`general.hours`, $store.state.locale) }}</span> 
                                    </template>
                                    <span style="color: #fff; font-size: 11px">{{ (employeeLeave.lastYearPickedUp.time / 60).toFixed(2)}} {{ $t(`general.hours`, $store.state.locale) }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                              <tr>
                                <td class="py-1">{{ $t(`employees.accruedHours`, $store.state.locale) }}</td>
                                <td class="text-right">
                                  <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                                    <template v-slot:activator="{ on }">
                                      <span v-on="on">{{ minutesToHourFormat(employeeLeave.collected.time, 5) }} {{ $t(`general.hours`, $store.state.locale) }}</span> 
                                    </template>
                                    <span style="color: #fff; font-size: 11px">{{ (employeeLeave.collected.time / 60).toFixed(2)}} {{ $t(`general.hours`, $store.state.locale) }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                              <tr v-if="employeeLeave.extraLeaveAgeOrContract.time > 0">
                                <td class="py-1">{{ $t(`employees.extraLeaveHoursDueToAgeOrEmployment`, $store.state.locale) }}</td>
                                <td class="text-right">
                                  <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                                    <template v-slot:activator="{ on }">
                                      <span v-on="on">{{ minutesToHourFormat(employeeLeave.extraLeaveAgeOrContract.time, 5) }} {{ $t(`general.hours`, $store.state.locale) }}</span> 
                                    </template>
                                    <span style="color: #fff; font-size: 11px">{{ (employeeLeave.extraLeaveAgeOrContract.time / 60).toFixed(2)}} {{ $t(`general.hours`, $store.state.locale) }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                              <tr v-if="employeeLeave.extraLeaveNoSickness.time > 0">
                                <td class="py-1">{{ $t(`employees.extraHoursOfLeaveDueToNoIllness`, $store.state.locale) }}</td>
                                <td class="text-right">
                                  <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                                    <template v-slot:activator="{ on }">
                                      <span v-on="on">{{ minutesToHourFormat(employeeLeave.extraLeaveNoSickness.time, 5) }} {{ $t(`general.hours`, $store.state.locale) }}</span> 
                                    </template>
                                    <span style="color: #fff; font-size: 11px">{{ (employeeLeave.extraLeaveNoSickness.time / 60).toFixed(2)}} {{ $t(`general.hours`, $store.state.locale) }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>

                              <tr v-if="employeeLeave.correction.time != 0">
                                <td class="py-1">{{ $t(`employees.correction`, $store.state.locale) }}</td>
                                <td class="text-right">
                                  <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                                    <template v-slot:activator="{ on }">
                                      <span v-on="on">{{ minutesToHourFormat(employeeLeave.correction.time, 5) }} {{ $t(`general.hours`, $store.state.locale) }}</span> 
                                    </template>
                                    <span style="color: #fff; font-size: 11px">{{ (employeeLeave.correction.time / 60).toFixed(2)}} {{ $t(`general.hours`, $store.state.locale) }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>

                              
                              <tr v-if="employeeLeave.lessLeaveSickness.time > 0">
                                <td class="py-1">{{ $t(`employees.handingInHoursOfLeaveDueToIllness`, $store.state.locale) }}</td>
                                <td class="text-right">
                                  <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                                    <template v-slot:activator="{ on }">
                                      <span v-on="on">-{{ minutesToHourFormat(employeeLeave.lessLeaveSickness.time, 5) }} {{ $t(`general.hours`, $store.state.locale) }}</span> 
                                    </template>
                                    <span style="color: #fff; font-size: 11px">-{{ (employeeLeave.lessLeaveSickness.time / 60).toFixed(2)}} {{ $t(`general.hours`, $store.state.locale) }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                              <tr>
                                <td class="py-1">{{ $t(`employees.extraHoursWorked`, $store.state.locale) }}</td>
                                <td class="text-right">
                                  <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                                    <template v-slot:activator="{ on }">
                                      <span v-on="on">{{ minutesToHourFormat(employeeLeave.extraHours.time, 5) }} {{ $t(`general.hours`, $store.state.locale) }}</span> 
                                    </template>
                                    <span style="color: #fff; font-size: 11px">{{ (employeeLeave.extraHours.time / 60).toFixed(2)}} {{ $t(`general.hours`, $store.state.locale) }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                              <tr>
                                <td class="py-1">{{ $t(`employees.usedLeaveHours`, $store.state.locale) }} </td>
                                <td class="text-right">
                                  <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                                    <template v-slot:activator="{ on }">
                                      <span v-on="on">-{{ minutesToHourFormat(employeeLeave.used.time, 5) }} {{ $t(`general.hours`, $store.state.locale) }}</span> 
                                    </template>
                                    <span style="color: #fff; font-size: 11px">-{{ (employeeLeave.used.time / 60).toFixed(2)}} {{ $t(`general.hours`, $store.state.locale) }}</span>
                                  </v-tooltip>
                                </td>
                              </tr>
                            </tbody>
                          </template>
                          <template v-slot:foot>
                            <tr>
                              <td class="ma-0 pa-0"><v-divider class="ma-0 pa-0"></v-divider></td>
                              <td class="ma-0 pa-0"><v-divider class="ma-0 pa-0"></v-divider></td>
                            </tr>
                            <tr>
                              <td class="py-3"><b>{{ $t(`reports.clients.total`, $store.state.locale) }}</b></td>
                              <td class="text-right"> <b>{{ minutesToHourFormat(employeeLeave.total.time, 5) }}  {{ $t(`general.hours`, $store.state.locale) }}</b></td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>

                    </div>

                    <div v-else class="text-center pa-4 ma-4"> 
                      <v-avatar
                        size="58"
                        light
                        class="ma-0 mr-2 mt-4 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-star</v-icon>
                      </v-avatar>
                      <p class="pa-4">{{ selectedEmployeeInfo.firstName }} {{ $t(`employees.noLeaveDays`, $store.state.locale) }} {{ $t(`employees.${selectedEmployeeInfo.contractType}`, $store.state.locale) }}.</p>
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex xs12>
                    <div v-if="employeeLeave.details && employeeLeave.details.length > 0">  
                      <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme">
                        <v-data-table
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :headers="leaveDetailsHeaders"
                          :items-per-page="-1"
                          :items="employeeLeave.details"
                          hide-default-footer
                          style="background: none"
                          dense
                          class="dense-table"
                          >
                          <template v-slot:item="{item}">
                            <tr :class="[{'future': item.future },{'row-disabled' : (!item.docId || !item.docType)}]" @click="openLeaveDetails(item)">
                              <td>
                                <v-icon class="my-1" small :color="item.future ? $themes[$store.state.companyTheme][$store.state.themeModus].background : $themes[$store.state.companyTheme][$store.state.themeModus].quaternary ">mdi-circle</v-icon>
                              </td>
                              <td> {{ item.start | moment("DD-MM-YYYY") }} <span v-if="item.end && item.start != item.end"> - {{ item.end | moment("DD-MM-YYYY") }}</span></td>
                              <td >
                                <span v-if="item.overviewType =='leaveBuildUp'">
                                  {{ $t(`employees.leaveBuildUpMonth`, $store.state.locale) }} {{ item.start | moment("MMMM") }}
                                </span>
                                <span v-if="item.overviewType =='leaveDebit'">
                                  {{ $t(`employees.leaveDebit`, $store.state.locale) }} ({{ $t(`employees.${item.reason}` , $store.state.locale) }}) <span v-if="item.description">:<i>{{ item.description }}</i></span>
                                </span>
                                <span v-if="item.type =='correction'">
                                  {{ getCorrectionType(item.overviewType) }} <span v-if="item.description">: <i>{{ item.description }}</i></span>
                                </span>
                                <span v-if="item.type =='extraLeaveNoSickness'">
                                  {{ $t(`employees.extraLeaveNoSickness`, $store.state.locale) }} 
                                </span>
                                <span v-if="item.overviewType =='extraLeaveAge'">
                                  {{ $t(`employees.extraLeaveHoursDueToAgeOrEmployment`, $store.state.locale) }} 
                                </span>
                                <span v-if="item.type =='lessLeaveSickness'">
                                  {{ $t(`employees.handingInHoursOfLeaveDueToIllness`, $store.state.locale) }} 
                                </span>
                                <span v-if="item.type =='extraHours'">
                                  {{ $t(`employees.extraHours`, $store.state.locale) }} ({{ $t(`employees.${item.reason}` , $store.state.locale) }})<span v-if="item.description"> <i>{{ item.description }}</i></span>
                                </span>
                              </td>
                              <td class="text-right"><span v-if="item.overviewType =='leaveDebit' || item.overviewType == 'correctionSubtractHours' || item.overviewType == 'lessLeaveSicknessMonth'">-</span>
                                <v-tooltip class="ma-0 pa-0" left color="#050508" content-class="small-tooltip">
                                  <template v-slot:activator="{ on }">
                                    <span v-on="on">{{ minutesToHourFormat(item.time, 5) }} {{ $t(`general.hours`, $store.state.locale) }}</span> 
                                  </template>
                                  <span style="color: #fff; font-size: 11px">{{ (item.time / 60).toFixed(2)}} {{ $t(`general.hours`, $store.state.locale) }}</span>
                                </v-tooltip>
                              </td>
                            </tr>
                          </template>
                          <template v-slot:body.append>
                            <tr></tr>
                          </template>
                          <template v-slot:foot>
                            <tr >
                              <td></td>
                              <td class="py-3"><b>{{ $t(`reports.clients.total`, $store.state.locale) }}</b></td>
                              <td></td>
                              <td class="text-right"> <b>{{ minutesToHourFormat(employeeLeave.total.time, 5) }}  {{ $t(`general.hours`, $store.state.locale) }}</b></td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-card>
                      <v-btn
                        class="mt-4"
                        dark
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                        @click="addLeaveCorrectionDialog()"
                      >{{ $t(`employees.leaveCorrectionAdd`, $store.state.locale) }}</v-btn>
                    </div>

                    <div v-else class="text-center pa-4 ma-4"> 
                      <v-avatar
                        size="58"
                        light
                        class="ma-0 mr-2 mt-4 custom-avatar-style" 
                        rounded="lg"
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                        >
                        <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-star</v-icon>
                      </v-avatar>
                      <p class="pa-4">{{ selectedEmployeeInfo.firstName }} {{ $t(`employees.noLeaveDays`, $store.state.locale) }} {{ $t(`employees.${selectedEmployeeInfo.contractType}`, $store.state.locale) }}.</p>

                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
              <div v-else class="text-center pa-4 ma-4"> 
                <v-avatar
                  size="58"
                  light
                  class="ma-0 mr-2 mt-4 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-star</v-icon>
                </v-avatar>
                <p class="pa-4">{{ selectedEmployeeInfo.firstName }} {{ $t(`employees.noLeaveDays`, $store.state.locale) }} {{ $t(`employees.${selectedEmployeeInfo.contractType}`, $store.state.locale) }}.</p>
              </div>
            </div>
            <div v-else>
              <div class="text-center pa-4 ma-4"> 
                <v-avatar
                  size="58"
                  light
                  class="ma-0 mr-2 mt-4 custom-avatar-style" 
                  rounded="lg"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                  >
                  <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-star</v-icon>
                </v-avatar>
                <p class="pa-4"> Vul <i>Type medewerker</i> in om verlof te bekijken van {{ selectedEmployeeInfo.firstName }}.</p>
              </div>
            </div>
          </div>

          <div v-else style="position: relative; padding: 50px 0">
            <div class="thrive-loader"></div>
          </div>
          
        </v-tab-item>
        <!-- \ Employee Leave / -->
      </v-tabs-items>
    </div>

    <div v-else style="position: relative; padding: 150px;">
      <div class="thrive-loader" :class="$store.state.companyTheme"></div>
    </div>

    <!-- / Image Library \ -->
    <v-dialog
      v-model="libraryDialog"
      fullscreen
      hide-overlay
      scrollable
      transition="dialog-bottom-transition"
      content-class="custom-shape"
      >
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t('library.employee', $store.state.locale) }} </h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeLibraryDialog()">
            <v-icon dark :color=" $themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text class="pa-0 ma-0" style="position: relative">
          <media-library
          @mediaFileSelect="setChosenImage" 
          :assetGroup="'companyAsset'" 
          :assetType="'employee'" 
          :fileRules="{accept: ['image/jpg', 'image/jpeg', 'image/png'], fileSize: 2000000}" ></media-library>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- \ Image Library / -->

    <!-- / New / Edit Absence Dialog \ -->
    <v-dialog v-model="exceptionDialog.show" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <template>
        <v-card>
          <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
            <h3 v-if="exceptionDialog.type == 'new'">{{ $t('employees.addException', $store.state.locale) }}</h3>
            <h3 v-else >{{ $t('employees.editException', $store.state.locale) }}</h3>
            <v-spacer></v-spacer>
            <v-btn v-if="!exceptionDialog.overlap.checking" icon dark @click="resetExceptionDialog()">
              <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-alert v-if="feedbackExeption" :value="true" class="ma-0 pa-2" style="border-radius: 0; font-size:14px" type="warning" v-html="feedbackExeption"></v-alert>

          <v-divider></v-divider>

          <v-card class="mx-5 mt-4" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].developer" v-if="!$store.state.demoMode && $store.state.activeUserRoleNumber == 0 && exceptionDialog.type == 'edit'">
            <v-btn class="ma-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="copyTextToClipboard(exceptionDialog.id, 'absenceDoc')" style="color: #ffffff; text-transform: none;"><strong>{{exceptionDialog.id}}</strong></v-btn>
            <div style="display: inline-block" v-if="absenceDocCopy == false" class="pa-2 copy-tag-url" @click="copyTextToClipboard(exceptionDialog.id, 'absenceDoc')"><v-icon small>mdi-content-copy</v-icon> Kopieër DocId</div>
            <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied" >DocId gekopieërd</div>
          </v-card>

          <v-card-text class="pa-2 ma-0">
            <v-form ref="AddExceptionForm" lazy-validation>
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-select
                      :items="$leaveItems.absentItems"
                      v-model="exceptionDialog.reason"
                      item-text="name"
                      item-value="id"
                      :label="$t('employees.absenceReason', $store.state.locale)"
                      required
                      dense
                      outlined
                      class="mt-2"
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      background-color="background"
                    ></v-select>
                    <v-text-field dense v-model="exceptionDialog.extra" :label="$t('employees.notes', $store.state.locale)" outlined class="mt-4" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  background-color="background"></v-text-field>
                    <v-switch class="mt-4 pa-0" :label="$t('general.period', $store.state.locale)" v-model="exceptionDialog.period" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" ></v-switch>
                    <v-switch class="mt-4 pa-0" :label="$t('employees.fullDay', $store.state.locale)" v-model="exceptionDialog.day" hide-details @change="setExceptionTimes()" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" ></v-switch>
                  </v-flex>
                  <v-flex xs6>
                    <v-menu :close-on-content-click="false" v-model="exceptionStart" :nudge-right="40" transition="scale-transition" offset-y :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          v-model="exceptionStartDate.readable"
                          :label="exeptionStartDateLabel[$store.state.locale]"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          required
                          dense
                          outlined
                          class="mt-4"
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          background-color="background"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="exceptionDialog.startDate" no-title :min="exceptionMinDate" :max="exceptionEndMaxDate" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @input="exceptionStart = false, checkexceptionDialogEndDate()"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs6>
                    <v-menu
                      v-if="exceptionDialog.period"
                      :close-on-content-click="false"
                      v-model="exceptionEnd"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y 
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          v-model="exceptionEndDate.readable"
                          :label="$t('general.endDate', $store.state.locale)"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          dense
                          required
                          :disabled="!exceptionDialog.startDate"
                          outlined
                          class="mt-4"
                          @input="checkExceptionEndDate()"
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          background-color="background"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="exceptionDialog.endDate" :max="exceptionEndMaxDate" no-title :min="exceptionDialog.startDate":color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @input="exceptionEnd = false, checkExceptionEndDate()"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs12>
                    <div class="mt-4" style="padding-bottom: 50px; "v-if="exceptionDialog.time.length > 0">
                      <h4 class="pb-2"> {{ $t('employees.selectTimes', $store.state.locale) }} </h4>
                      <v-row v-for="(exceptionTime, index) in exceptionDialog.time" :key="index">
                        <v-col cols="5">
                          <label style="font-size:12px">{{$t('general.start', $store.state.locale)}}</label>
                          <vue-timepicker :key="exceptionDialog.id" :placeholder="$t('general.time', $store.state.locale)"  :hour-label="$t('general.hour', $store.state.locale)" :minute-label="$t('general.minutes', $store.state.locale)" v-model="exceptionDialog.time[index].start" :minute-interval="5" close-on-complete></vue-timepicker>
                          <!-- <v-text-field dense :label="$t('general.start', $store.state.locale)" v-model="exceptionDialog.time[index].start" type="time" step="300" outlined hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" background-color="background"></v-text-field> -->
                        </v-col>
                        <v-col cols="5">
                          <label style="font-size:12px">{{$t('general.end', $store.state.locale)}}</label>
                          <vue-timepicker :key="exceptionDialog.id" :placeholder="$t('general.time', $store.state.locale)" :hour-label="$t('general.hour', $store.state.locale)" :minute-label="$t('general.minutes', $store.state.locale)" v-model="exceptionDialog.time[index].end"  :minute-interval="5" close-on-complete></vue-timepicker>
                          <!-- <v-text-field  dense :label="$t('general.end', $store.state.locale)" v-model="exceptionDialog.time[index].end" type="time" outlined hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @input="roundValue('exceptionDialog','time', index,'end', 5)"  background-color="background"></v-text-field> -->
                        </v-col>
                        <v-col cols="1">
                          <v-btn class="my-0 mt-6" icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="exceptionDialog.time.splice(index, 1)">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="10">
                          <div class="text-center">
                            <v-btn class="mx-2" fab dark small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addExceptionTimes()">
                              <v-icon dark>mdi-plus</v-icon>
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-flex>
                  <v-flex xs12>
                    <v-alert v-if="exceptionDialog.period" type="info" prominent text :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" class="mb-0 pa-2" style="font-size: 14px" v-html="$t('employees.periodAbsenceInfo', $store.state.locale)"></v-alert>
                  </v-flex xs12>

                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="resetExceptionDialog()"> {{ $t('general.cancel', $store.state.locale) }} </v-btn>
                </v-flex>
                <v-flex xs12 md6 class="text-right">
                  <v-btn
                    v-if="exceptionDialog.type == 'new'"
                    dark
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                    :loading="exceptionDialog.overlap.checking"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="addExceptionCheck('check','new')"
                    >
                    {{ $t('employees.addException', $store.state.locale) }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    dark
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                    :loading="exceptionDialog.overlap.checking"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="addExceptionCheck('check', 'edit')"
                  >
                    {{ $t('general.save', $store.state.locale) }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <!-- \ New / Edit Absence Dialog / -->

    <!-- / New / Edit Presence Dialog \ -->
    <v-dialog v-model="presenceDialog.show" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <template>
        <v-card>
          <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
            <h3 v-if="presenceDialog.type == 'new'">{{  $t('employees.addPresence', $store.state.locale) }}</h3>
            <h3 v-else>{{  $t('employees.editPresence', $store.state.locale) }}</h3>
            <v-spacer></v-spacer>
            <v-btn v-if="!presenceDialog.overlap.checking" icon dark @click="resetPresenceDialog()">
              <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-alert v-if="presenceDialog.feedback" :value="true" class="ma-0 pa-2" style="border-radius: 0" type="warning" v-html="presenceDialog.feedback"></v-alert>

          <v-divider></v-divider>

          <v-card class="mx-5 mt-4" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].developer" v-if="!$store.state.demoMode && $store.state.activeUserRoleNumber == 0 && presenceDialog.type == 'edit'">
            <v-btn class="ma-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"@click="copyTextToClipboard(presenceDialog.id, 'presenceDoc')" style="color: #ffffff; text-transform: none;"><strong>{{presenceDialog.id}}</strong></v-btn>
            <div style="display: inline-block" v-if="presenceDocCopy == false" class="pa-2 copy-tag-url" @click="copyTextToClipboard(presenceDialog.id, 'presenceDoc')"><v-icon small>mdi-content-copy</v-icon> Kopieër DocId</div>
            <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied" >DocId gekopieërd</div>
          </v-card>

          <v-card-text class="pa-2 ma-0">
            <v-form ref="AddExceptionForm" lazy-validation>
              <v-container grid-list-md>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-select
                      :items="$leaveItems.presenceItems"
                      v-model="presenceDialog.reason"
                      item-text="name"
                      item-value="id"
                      :label="$t('employees.presentReason', $store.state.locale)"
                      required
                      dense
                      outlined
                      class="mt-2"
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      background-color="background"
                    ></v-select>
                    <v-text-field dense v-model="presenceDialog.extra" :label="$t('employees.notes', $store.state.locale)" outlined class="mt-4" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  background-color="background"></v-text-field>
                  </v-flex>
                  <v-flex xs6>
                    <v-menu :close-on-content-click="true" v-model="presenceDialog.start" :nudge-right="40" transition="scale-transition" offset-y :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  max-width="290px" min-width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          v-model="presenceStartDate.readable"
                          :label="$t('general.date', $store.state.locale)"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          required
                          dense
                          outlined
                          class="mt-4"
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          background-color="background"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="presenceDialog.startDate" no-title :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @input="presenceStart = false"></v-date-picker>
                    </v-menu>
                  </v-flex>
                  <v-flex xs6>
                  </v-flex>
                  <v-flex xs12 style="padding-bottom: 70px;" v-if="presenceDialog.show">
                    <h4 class="py-4"> {{ $t('employees.selectTimes', $store.state.locale) }}</h4>

                    <div class="clear pb-4" v-for="(exceptionTime, index) in presenceDialog.time" :key="`${presenceDialog.time.start}-${presenceDialog.time.end}-${index}-${presenceDialog.id}`">
                      <div style="width: calc(100% - 40px); float: left; padding: 0 10px 0 0">
                        <v-row>
                          <v-col cols="6">
                            <label style="font-size:12px">{{$t('general.start', $store.state.locale)}}</label>
                            <vue-timepicker :key="presenceDialog.time.length + presenceDialog.id" :placeholder="$t('general.time', $store.state.locale)"  :hour-label="$t('general.hour', $store.state.locale)" :minute-label="$t('general.minutes', $store.state.locale)" v-model="presenceDialog.time[index].start"  :minute-interval="5" close-on-complete></vue-timepicker>

                            <!-- <v-text-field  dense :label="$t('general.start', $store.state.locale)" v-model="presenceDialog.time[index].start" type="time" step=300 outlined hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @input="roundValue('presenceDialog','time', index,'start', 5)" background-color="background"></v-text-field> -->
                          </v-col>
                          <v-col cols="6">
                            <label style="font-size:12px">{{$t('general.end', $store.state.locale)}}</label>
                            <vue-timepicker :key="presenceDialog.time.length + presenceDialog.id" :placeholder="$t('general.time', $store.state.locale)" :hour-label="$t('general.hour', $store.state.locale)" :minute-label="$t('general.minutes', $store.state.locale)" v-model="presenceDialog.time[index].end"  :minute-interval="5" close-on-complete></vue-timepicker>

                            <!-- <v-text-field dense :label="$t('general.end', $store.state.locale)" v-model="presenceDialog.time[index].end" type="time" steps="300" outlined hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @input="roundValue('presenceDialog','time', index,'end', 5)"  background-color="background"></v-text-field> -->
                          </v-col>
                        </v-row>
                      </div>
                      <div style="width:40px; float: left">
                        <v-btn style="margin-top: 2px" icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="presenceDialog.time.splice(index, 1)">
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </div>
                    
                    <div class="text-center" style="width: calc(100% - 50px);">
                      <v-btn fab dark small depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addPresenceTimes()">
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="resetPresenceDialog()"> {{ $t('general.cancel', $store.state.locale) }} </v-btn>
                </v-flex>
                <v-flex xs12 md6 class="text-right">
                  <v-btn
                    v-if="presenceDialog.type == 'new'"
                    dark
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                    :loading="presenceDialog.overlap.checking"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="savePresenceCheck('new')"
                    >
                    {{ $t('employees.addPresenceButton', $store.state.locale) }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    dark
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                    :loading="presenceDialog.overlap.checking"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="savePresenceCheck('edit')"
                    >
                    {{ $t('general.save', $store.state.locale) }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <!-- \ New / Edit Presence Dialog / -->

    <!-- / Leave correction Dialog \ -->
    <v-dialog v-model="leaveCorrection.dialog" scrollable max-width="600px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <template>
        <v-card>
          <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
            <h3 v-if="leaveCorrection.dialogType == 'edit'">{{ $t('employees.leaveCorrectionEdit', $store.state.locale) }}</h3>
            <h3 v-else>{{ $t('employees.leaveCorrectionAdd', $store.state.locale) }}</h3>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="leaveCorrection.dialog = false">
              <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-alert v-if="leaveCorrection.feedback" :value="true" class="ma-0 pa-2" style="border-radius: 0" type="warning" v-html="leaveCorrection.feedback"></v-alert>

          <v-divider></v-divider>

          <v-card-text class="pa-2 ma-0">

            <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" v-if="!$store.state.demoMode && $store.state.activeUserRoleNumber < 2 && leaveCorrection.dialogType == 'edit'">
              <v-btn class="ma-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="color: #ffffff; text-transform: none;"><strong>{{leaveCorrection.docId}}</strong></v-btn>
              <div style="display: inline-block" v-if="selectedCorrectionIdCopy == false" class="pa-2 copy-tag-url" @click="copyTextToClipboard(leaveCorrection.docId)"><v-icon small>mdi-content-copy</v-icon> Kopieër DocId</div>
              <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied" >DocId gekopieërd</div>
            </v-card>
            <v-form ref="AddExceptionForm" lazy-validation>
              <v-container grid-list-md>
                <v-layout row wrap d-flex>
                  <v-flex xs6>
                    <v-menu
                      :close-on-content-click="false"
                      v-model="leaveCorrection.dateDialog"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          v-model="leaveCorrection.date"
                          :label="$t('general.date', $store.state.locale)"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          dense
                          required
                          outlined
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          background-color="background"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="leaveCorrection.date"  no-title :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @input="leaveCorrection.dateDialog = false"></v-date-picker>
                    </v-menu>
                  
                    <v-select
                      :items="leaveCorrectionTypes"
                      v-model="leaveCorrection.type"
                      item-text="name"
                      item-value="id"
                      :label="$t('general.type', $store.state.locale)"
                      required
                      dense
                      class="mt-2"
                      outlined
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"   
                      background-color="background"
                    ></v-select>
                    <v-layout row wrap>
                      <v-flex xs6>
                        <v-select
                          :items="leaveCorrectionHours"
                          v-model="leaveCorrection.hours"
                          :label="$t('general.hours', $store.state.locale)"
                          required
                          dense
                          outlined
                          class="mt-2"
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                          background-color="background"
                        ></v-select>
                      </v-flex>
                      <v-flex xs6>
                        <v-select
                          :items="leaveCorrectionMinutes"
                          v-model="leaveCorrection.minutes"
                          :label="$t('employees.leaveCorrectionMinutes', $store.state.locale)"
                          required
                          dense
                          outlined
                          class="mt-2"
                          hide-details
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  
                          background-color="background"
                        ></v-select>
                      </v-flex>
                    </v-layout>
                  </v-flex>

                  <v-flex xs6 d-flex>
                    <v-card v-if="leaveCorrection.dialogType == 'edit'" class="pa-2" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" style="width: 100%">
                      <div>Voor correctie</div>
                      <h3 v-if="(leaveCorrection.type =='correctionSubtractHours' || leaveCorrection.type =='payoutHours')">{{ minutesToHourFormat((employeeLeave.total.time + leaveCorrection.time), 5) }} {{ $t(`general.hours`, $store.state.locale) }}</h3> 
                      <h3 v-if="(leaveCorrection.type =='correctionAddHours' || leaveCorrection.type =='carriedOverLastYear')">{{ minutesToHourFormat((employeeLeave.total.time - leaveCorrection.time) , 5) }} {{ $t(`general.hours`, $store.state.locale) }}</h3> 

                      <div v-if="(leaveCorrection.minutes > 0 || leaveCorrection.hours > 0) && leaveCorrection.type">
                        <div>Na correctie</div>
                        <div v-if="(leaveCorrection.type =='correctionSubtractHours' || leaveCorrection.type =='payoutHours')">
                          <h3 v-if="leaveCorrection.type == 'carriedOverLastYear' || leaveCorrection.type == 'correctionAddHours'">{{ minutesToHourFormat((employeeLeave.total.time + leaveCorrection.time + (leaveCorrection.hours * 60) + leaveCorrection.minute), 5) }} {{ $t(`general.hours`, $store.state.locale) }}</h3> 
                          <h3 v-else>{{ minutesToHourFormat((employeeLeave.total.time + leaveCorrection.time - (leaveCorrection.hours * 60) - leaveCorrection.minutes), 5) }} {{ $t(`general.hours`, $store.state.locale) }}</h3> 
                        </div>

                        <div v-if="(leaveCorrection.type =='correctionAddHours' || leaveCorrection.type =='carriedOverLastYear')">
                          <h3 v-if="leaveCorrection.type == 'carriedOverLastYear' || leaveCorrection.type == 'correctionAddHours'">{{ minutesToHourFormat((employeeLeave.total.time - leaveCorrection.time + (leaveCorrection.hours * 60) + leaveCorrection.minute), 5) }} {{ $t(`general.hours`, $store.state.locale) }}</h3> 
                          <h3 v-else>{{ minutesToHourFormat((employeeLeave.total.time - leaveCorrection.time - (leaveCorrection.hours * 60) - leaveCorrection.minutes), 5) }} {{ $t(`general.hours`, $store.state.locale) }}</h3> 
                        </div>
                      </div>
                    </v-card>

                    <v-card v-else class="pa-2" flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].background" style="width: 100%">
                      <div>Voor correctie</div>
                      <h3>{{ minutesToHourFormat(employeeLeave.total.time, 5) }} {{ $t(`general.hours`, $store.state.locale) }}</h3> 

                      <div v-if="(leaveCorrection.minutes > 0 || leaveCorrection.hours > 0) && leaveCorrection.type">
                        <div>Na correctie</div>
                        <h3 v-if="leaveCorrection.type == 'carriedOverLastYear' || leaveCorrection.type == 'correctionAddHours'">{{ minutesToHourFormat(((employeeLeave.total.time + (leaveCorrection.hours * 60) + leaveCorrection.minutes)), 5) }} {{ $t(`general.hours`, $store.state.locale) }}</h3> 
                        <h3 v-else>{{ minutesToHourFormat((employeeLeave.total.time - (leaveCorrection.hours * 60) - leaveCorrection.minutes), 5) }} {{ $t(`general.hours`, $store.state.locale) }}</h3> 
                      </div>
                    </v-card>
                  </v-flex>

                </v-layout>
                
                <v-layout row wrap>

                  <v-flex xs12>
                    <v-textarea dense v-model="leaveCorrection.description" rows="3" :label="$t('employees.leaveCorrectionDescription', $store.state.locale)" outlined class="mt-4" hide-details :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  background-color="background"></v-textarea>
                  </v-flex>
                  
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn v-if="leaveCorrection.dialogType == 'edit'" class="ma-0 mr-2" icon depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="leaveCorrection.dialog = false"> 
                    <v-icon @click="removeLeaveCorrection()">mdi-delete</v-icon>
                  </v-btn>

                  <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="leaveCorrection.dialog = false"> {{ $t('general.cancel', $store.state.locale) }} </v-btn>
                </v-flex>
                <v-flex xs12 md6 class="text-right">
                  <v-btn
                    v-if="leaveCorrection.dialogType == 'edit'"
                    dark
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                    :loading="leaveCorrection.busy"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="saveLeaveCorrection()"
                    >
                    {{ $t('general.save', $store.state.locale) }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    dark
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                    :loading="leaveCorrection.busy"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="addLeaveCorrection()"
                    >

                    {{ $t('employees.addLeaveCorrection', $store.state.locale) }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <!-- \ Leave correction Dialog / --> 

    <!-- / Basic Schedule Dialog \ -->
     <v-dialog v-model="newBasicSchedule.dialog" scrollable max-width="1200px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <template>
        <v-card>
          <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].card }">
            <h3 v-if="newBasicSchedule.type == 'new'">{{ $t('employees.newBasicSchedule', $store.state.locale) }} {{ $t('admin.caoOn', $store.state.locale) }} {{ getBranchName() }}</h3> 
            <div v-else>
              <h3>{{ $t('employees.editWeekSchedule', $store.state.locale) }} {{ newBasicSchedule.weekNumber }} {{ $t('admin.caoOn', $store.state.locale) }} {{ getBranchName() }}</h3> 
            </div>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="resetNewBasicSchedule()">
              <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <v-alert v-if="newBasicSchedule.feedback" :value="true" class="ma-0 pa-2" style="border-radius: 0" type="warning" v-html="newBasicSchedule.feedback"></v-alert>

          <v-divider></v-divider>

          <v-card-text class="pa-0 ma-0">

            <v-card flat class="pa-1" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption">

              <v-layout row wrap class="ma-0 pa-0 my-4 px-1">
                <v-flex xs12 md4 v-if="newBasicSchedule.type != 'edit'">
                  <v-menu
                    :close-on-content-click="false"
                    v-model="newBasicScheduleStartDateDialog"
                    :nudge-right="0"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        clearable
                        v-on="on"
                        class="mt-1"
                        :value="newBasicScheduleStartDate.readable"
                        :label="$t('general.startDate', $store.state.locale) "
                        prepend-inner-icon="mdi-calendar-today"
                        readonly
                        dense
                        @click:clear="newBasicSchedule.startDate = ''"
                        outlined
                        :min="today" 
                        hide-details
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      ></v-text-field>
                    </template>
                    <v-date-picker ref="picker" v-model="newBasicSchedule.startDate" :min="nextWeek()" no-title locale-first-day-of-year="4" show-week :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @input="newBasicScheduleStartDateDialog = false"></v-date-picker>
                  </v-menu>
                </v-flex>
                
                <v-flex xs12 md4 class="px-4">
                  <h4>{{ $t('employees.time', $store.state.locale) }}</h4>
                  <div v-if="newBasicSchedule.minutes.type == 'alternately'">
                    {{ minutesToHourFormat(newBasicSchedule.minutes.total, 5) }} <i>({{ minutesToHourFormat(newBasicSchedule.minutes.even, 5) }} / {{ minutesToHourFormat(newBasicSchedule.minutes.odd, 5) }})</i>
                  </div>
                  <div v-else>
                    {{ minutesToHourFormat(newBasicSchedule.minutes.total, 5) }}
                  </div>    
                </v-flex>
                <v-flex xs12 md4></v-flex>
              </v-layout>
                
                <v-layout dflex class="grouplessons-schedule-wrapper" :key="newSchedulePeriodChanges" >
                  <div class="day-of-week text-center new-schedule" :class="{'alternately': newBasicSchedule.schedule.continiousScheduleType[day.id] == 'alternately'}" :style="{'background-color': $themes[$store.state.companyTheme][$store.state.themeModus].card }" v-for="(day, index) in daysOfWeek" :key="index + newSchedulePeriodChanges">
                    <div class="schedule-day-title">
                      {{ $t('general.' + day.name, $store.state.locale) }} 
                      <div style="font-size:12px">
                        <div v-if="calculateDayHours('modal', day.id).type == 'alternately'">
                          ({{ calculateDayHours('modal', day.id).hours.even }} / {{ calculateDayHours('modal', day.id).hours.odd }})
                        </div>
                        <div v-else>
                          ({{ calculateDayHours('modal', day.id).hours.total }})
                        </div>    
                      </div>

                      <v-select
                        :items="continiousScheduleTypes"
                        item-text="name"
                        item-value="id"
                        required
                        dense
                        outlined
                        :height="30"
                        v-model="newBasicSchedule.schedule.continiousScheduleType[day.id]"
                        class="ma-2 "
                        hide-details
                        @change="setContiniousSchedules(day.id)"
                        :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                        background-color="background"
                      ></v-select>

                      <v-divider class="pa-2 pb-0"></v-divider>
                      <div>
                        <div class="pb-1" v-for="(value, i) in continiousScheduleNumbers(newBasicSchedule.schedule.continiousScheduleType[day.id])" in :key="i" style="display: inline-block" :style="{'width': continiousScheduleNumbers(newBasicSchedule.schedule.continiousScheduleType[day.id]).length > 1 ? '50%' : '100%'}">
                          <p v-if="newBasicSchedule.schedule.continiousScheduleType[day.id] == 'alternately' && i == 0" class="ma-0" style="font-size: 12px" v-html="$t('employees.evenWeeksBreak', $store.state.locale)"></p>
                          <p v-else-if="newBasicSchedule.schedule.continiousScheduleType[day.id] == 'alternately' && i == 1" class="ma-0" style="font-size: 12px" v-html="$t('employees.oddWeeksBreak', $store.state.locale)"></p>
                          <p v-else class="ma-0" style="font-size: 12px" v-html="$t('employees.allWeeksBreak', $store.state.locale)"></p>
                        </div>
                      </div>
                      <v-divider class="pa-2"></v-divider>
                    </div>
             
                    <div v-for="(value,i) in continiousScheduleNumbers(newBasicSchedule.schedule.continiousScheduleType[day.id])" in :key="i" style="display: block; float: left;" :style="{'width': continiousScheduleNumbers(newBasicSchedule.schedule.continiousScheduleType[day.id]).length > 1 ? '50%' : '100%'}">
                      <div v-if="newBasicSchedule.schedule[day.id] && newBasicSchedule.schedule[day.id].length > 0" style="display: inline-block; width: 100%" :style="{'height': newScheduleSpaces + 50 + 'px'}"> 
                        <div class="grouplesson" v-for="(time, index) in newBasicSchedule.schedule[day.id]" :key="index" v-if="(continiousScheduleNumbers(newBasicSchedule.schedule.continiousScheduleType[day.id]).length > 1 && i == 1 && time.continiousType =='odd') || (i == 0 && time.continiousType == 'even') || continiousScheduleNumbers(newBasicSchedule.schedule.continiousScheduleType[day.id]).length == 1" :style="[{'backgroundColor': time.type != 'pause' ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : $themes[$store.state.companyTheme][$store.state.themeModus].background}, {'height' : calculateScheduleMinutes(time)  + 'px'}, {'marginTop' : calculateScheduleDistance('modal', day.id, index, time.continiousType)  + 'px'}]">
                          <div class="grouplesson-time" :style="[{'color': time.type != 'pause' ? $themes[$store.state.companyTheme][$store.state.themeModus].card : $themes[$store.state.companyTheme][$store.state.themeModus].text}]">{{ time.start }}-{{ time.end }}</div>
                          <div class="grouplesson-edit">
                            <v-btn class="my-1" style="z-index: 9"  fab x-small dark  :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" @click="removePeriod([day.id], index)"><v-icon small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon></v-btn>
                          </div>
                        </div>
                        <v-btn class="my-2" fab :height="30" :width="30" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addPeriod(day.id, i, continiousScheduleNumbers(newBasicSchedule.schedule.continiousScheduleType[day.id]).length)"><v-icon small>mdi-plus</v-icon></v-btn> 
                      </div>
                    
                      <div v-else class="schedule-day-empty">
                        <v-btn class="my-2" fab :height="30" :width="30" outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addPeriod(day.id, i, continiousScheduleNumbers(newBasicSchedule.schedule.continiousScheduleType[day.id]).length)"><v-icon small>mdi-plus</v-icon></v-btn> 
                      </div>
                    </div>
                  </div>
                </v-layout>
              </v-card>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-container grid-list-md fluid class="ma-0 pa-0">
              <v-layout row wrap class="ma-0 pa-0">
                <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                  <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="resetNewBasicSchedule()"> {{ $t('general.cancel', $store.state.locale) }} </v-btn>
                </v-flex>
                <v-flex xs12 md6 class="text-right">
                  <v-btn
                    v-if="newBasicSchedule.type == 'new'"
                    :dark="newBasicScheduleChanged"
                    :disabled="!newBasicScheduleChanged"
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                    :loading="leaveCorrection.busy"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="addNewBasicSchedule()"
                    >
                    {{ $t('employees.addNewBasicSchedule', $store.state.locale) }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-btn
                    v-else
                    :dark="newBasicScheduleChanged"
                    :disabled="!newBasicScheduleChanged"
                    :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                    :loading="leaveCorrection.busy"
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    @click="editBasicSchedule()"
                    >
                    {{ $t('employees.editBasicSchedule', $store.state.locale) }}
                    <v-icon right dark>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <!-- \ Basic Schedule Dialog / -->
    
    <!-- / Basic Schedule Time Element Dialog \ -->
    <v-dialog v-model="basicSchedulePeriod.dialog" persistent scrollable max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="ma-0 pt-4">
          <div class="text-center">
            <h3 class="pa-2" style="line-height: 1.3">{{ $t('employees.addSchedulePart', $store.state.locale) }}</h3>


            <p v-html="$t(`employees.${basicSchedulePeriod.continiousType}Weeks`, $store.state.locale)"></p>

            <v-alert v-if="basicSchedulePeriod.feedback" :value="true" class="ma-0 pa-2" style="border-radius: 0" type="warning" v-html="basicSchedulePeriod.feedback"></v-alert>
          </div>
          <div>
            <v-row>
              <v-col cols="12">
                <v-select
                  :items="scheduleTypes"
                  v-model="basicSchedulePeriod.type"
                  item-text="name"
                  item-value="id"
                  :label="$t('general.type', $store.state.locale)"
                  required
                  dense
                  outlined
                  class="mt-4"
                  hide-details
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  background-color="background"
                ></v-select>
              </v-col>
              <v-col cols="6">
                {{ $t('general.start', $store.state.locale) }}
                <v-row>
                  <v-col cols="6">
                    <v-select
                      :items="filteredStartHours"
                      v-model="basicSchedulePeriod.startHour"
                      item-text="name"
                      item-value="id"
                      :label="$t('general.hour', $store.state.locale)"
                      required
                      dense
                      outlined
                      class="mt-2"
                      hide-details
                      @input="setEndHourAndMinutes('hour')"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      background-color="background"
                    ></v-select>
                  </v-col>

                  <v-col cols="6">
                    <v-select
                      :items="filteredStartMinutes"
                      v-model="basicSchedulePeriod.startMinutes"
                      item-text="name"
                      item-value="id"
                      :label="$t('general.minutes', $store.state.locale)"
                      required
                      dense
                      outlined
                      class="mt-2"
                      hide-details
                      @input="setEndHourAndMinutes('minutes')"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      background-color="background"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="6">
                {{ $t('general.end', $store.state.locale) }}
                <v-row>
                  <v-col cols="6">
                    <v-select
                      :items="filteredEndHours"
                      v-model="basicSchedulePeriod.endHour"
                      item-text="name"
                      item-value="id"
                      :label="$t('general.hour', $store.state.locale)"
                      required
                      dense
                      outlined
                      class="mt-2"
                      hide-details
                      @input="setEndMinutes()"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      background-color="background"
                    ></v-select>
                  </v-col>

                  <v-col cols="6">
                    <v-select
                      :items="filteredEndMinutes"
                      v-model="basicSchedulePeriod.endMinutes"
                      item-text="name"
                      item-value="id"
                      :label="$t('general.minutes', $store.state.locale)"
                      required
                      dense
                      outlined
                      class="mt-2"
                      hide-details
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      background-color="background"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
   
            </v-row>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left">
                <div class="text-left">
                  <v-btn outlined depressed color="tertiary" @click="closeBasicSchedulePeriod()"> {{ $t('general.cancel', $store.state.locale) }}  </v-btn>
                </div>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" @click="addBasicSchedulePeriod()">
                  {{ $t('general.add', $store.state.locale) }} 
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \New Basic Schedule Time Element  Dialog / -->

    <!-- / Task Overlap Dialog \ -->
    <v-dialog v-model="exceptionDialog.overlap.dialog" persistent scrollable max-width="500px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-text class="ma-0 pt-4">
          <div class="text-center">
            <!-- <div class="event-overlap"></div> -->
            <h3 class="pa-2" style="line-height: 1.3">{{ $t('employees.calendarOverlap', $store.state.locale) }}</h3>
            <p class="ma-0 mb-1">{{ $t('employees.calendarOverlapHint', $store.state.locale) }} </p>
          </div>
          <div>
            <div v-if="exceptionDialog.overlap.overlaps && exceptionDialog.overlap.overlaps.length > 0">
              <div flat v-for="(booking, index) in exceptionDialog.overlap.overlaps" :key="index">
                <div style="border-radius: 5px; position: relative" :style="{ backgroundColor: booking.color ? booking.color : booking.preferredPractitioner ? $store.state.activeBranch.calendarSettings.colorWithPreference : $store.state.activeBranch.calendarSettings.colorNoPreference}" class="ma-2 pa-1" v-if="index < 6">
                  <div v-if="booking.type == 'booking'" class="booking-type" :style="{ backgroundColor: booking.color }" style="z-index: 1; font-weight: bold; line-height: 15px">
                    <span style="color: #fff; font-weight: bold; line-height: 15px" v-if="booking.source == 'widget'">O</span>
                    <span style="color: #fff; font-weight: bold; line-height: 15px" v-if="booking.source == 'app'">A</span>
                    <span style="color: #fff; font-weight: bold; line-height: 15px" v-if="booking.source == 'personal'">P</span>
                    <span style="color: #fff; font-weight: bold; line-height: 15px" v-if="booking.source == 'telephone'">T</span>
                  </div>
                  <div class="event-header">
                    <div class="eve-time" style="color: #fff">{{ booking.start | moment("H:mm") }}</div>
                    <div v-if="booking.type == 'booking'" class="calender-event-description eve-desc">{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, booking.subtitle) }}</div>
                    <div v-else class="calender-event-description eve-desc">{{$autoLanguageSelector($store.state.locale, $store.state.companyLanguages, booking.title)}}</div>
                  </div>
                  <div v-if="booking.type == 'booking'" class="calender-event-title">{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, booking.title)}}</div>
                  <div v-else-if="booking.type == 'task'" class="calender-event-title task">{{ booking.subtitle }} </div>
                  <div v-else class="calender-event-title">{{  booking.subtitle }} </div>

                  <div v-if="booking.type == 'task'" style="left: 0; top: 32px" class="task-type" :class="{ done: booking.extra.completed }"></div>
                  <div v-if="booking.type == 'booking'" style="color: #fff; font-size: 12px">{{ booking.client }}</div>
                </div>
              </div>
              <div v-if="exceptionDialog.overlap.overlaps.length > 6" class="text-center">
                <p class="ma-0 pa-2" style="font-size: 16px">
                  <strong>+{{ exceptionDialog.overlap.overlaps.length - 6 }}</strong> <span v-if="exceptionDialog.overlap.overlaps.length - 6 == 1">{{ $t('employees.part', $store.state.locale) }} </span> <span v-else>{{ $t('employees.parts', $store.state.locale) }} </span>
                </p>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left">
                <div class="text-left">
                  <v-btn outlined depressed color="tertiary" @click="closeExceptionCheck()"> {{ $t('general.cancel', $store.state.locale) }}  </v-btn>
                </div>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" @click="addExceptionCheck('force')">
                  {{ $t('employees.continue', $store.state.locale) }} 
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Task Overlap Dialog / -->

    <!-- / View User Logs \ -->
    <view-user-logs :selectedUserCompany="$store.state.activeCompany.id"></view-user-logs>
    <!-- \ View User Logs / -->

    <!-- / New Phonenumber Dialog \ -->
    <add-phonenumber-dialog :type="'editEmployeeInformation'"  @newPhoneNumber="addNewPhonenumber($event)"></add-phonenumber-dialog>
    <!-- \ New Phonenumber Dialog / --> 

    <!-- / New Email Dialog \ -->
    <add-emailaddress-dialog :type="'editEmployeeInformation'" @newEmailaddress="addNewEmailaddress($event)"></add-emailaddress-dialog>
    <!-- \ New Email Dialog / --> 

    <!-- / Remove Employee Dialog\ -->
    <v-dialog v-model="removeEmployeeDialog.show" persistent max-width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-divider></v-divider>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-delete</v-icon>
          </v-avatar>
          <h3 class="mb-3">{{ $t('employees.removeEmployee', $store.state.locale) }}</h3>
          
          <p v-if="selectedEmployeeInfo.employeeContractEnd == 'noEndDate' || selectedEmployeeInfo.employeeContractEnd == ''" v-html="$t('employees.removeEmployeeHintContractEnd', $store.state.locale)"></p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn outlined depressed color="tertiary" :disabled="removeEmployeeDialog.loading" @click="removeEmployeeDialog.show = false"> {{ $t('general.cancel', $store.state.locale) }} </v-btn>
          <v-spacer></v-spacer>
          <v-btn dark :loading="removeEmployeeDialog.loading" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="removeEmployee()"> {{ $t('general.remove', $store.state.locale) }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Remove Employee Dialog / -->

    <!-- / Restore Employee Dialog\ -->
    <v-dialog v-model="restoreEmployeeDialog.show" persistent max-width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-divider></v-divider>
        <v-card-text class="text-center ma-0 pt-4">
          <v-avatar 
            size="56"
            light
            class="ma-4 custom-avatar-style" 
            rounded="lg"
            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
            :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
            >
            <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-arrow-u-left-top</v-icon>
          </v-avatar>
          <h3 class="mb-3">{{ $t('employees.restoreEmployee', $store.state.locale) }}</h3>
          
          <p v-html="$t('employees.restoreEmployeeHint', $store.state.locale)"></p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn outlined depressed color="tertiary" :disabled="restoreEmployeeDialog.loading" @click="restoreEmployeeDialog.show = false"> {{ $t('general.cancel', $store.state.locale) }} </v-btn>
          <v-spacer></v-spacer>
          <v-btn dark :loading="restoreEmployeeDialog.loading" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="restoreEmployee()"> {{ $t('general.activate', $store.state.locale) }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Restore Employee Dialog / -->

</div>
</template>
    
<script>
  import '@firebase/firestore';
  import db from "../firebase/init";
  import _ from "lodash";
  import { bus } from "../main";
  import moment from "moment";
  import axios from "axios";
  import firebase from "firebase/app";
  import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
  import uniqid from "uniqid";

  import GmapCustomMarker from 'vue2-gmap-custom-marker';
  import AddPhonenumber from "../components/modals/AddPhoneNumber.vue";
  import AddEmailaddress from "../components/modals/AddEmailaddress.vue";
  import MediaLibrary from "../components/elements/MediaLibrary";
  import ViewUserLogs from "../components/modals/ViewUserLogs.vue";

  export default {
    props: {
      source: String,
      branches: Array,
    },
    components: {
      "vue-timepicker":VueTimepicker,
      'gmap-custom-marker': GmapCustomMarker,
      "add-phonenumber-dialog": AddPhonenumber,
      "media-library" : MediaLibrary,
      "view-user-logs": ViewUserLogs,
      "add-emailaddress-dialog": AddEmailaddress,
    },

    name: "employee-information",

  data () {
    return {
      // General
      changes: 0,
      updateData: false,
      tabTransition: false,
      tab: "basic",
      selectedBranch: "",
      today: moment().format("YYYY-MM-DD"),

      snackbar: {
        active: false,
        text: "",
        icon: "",
        timeout: -1,
        color: "",
      },

      userAccountFeedback: null,

      rules: {
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
        required: (value) => !!value || this.$t("general.required", this.$store.state.locale),
        min: (v) => v.length >= 6 || this.$t("user.sixCharactersPassword", this.$store.state.locale),
      },

      // 1. BASIC
      selectedEmployeeInfo: new Object(),
      selectedEmployeeInfoOriginal: new Object(),
      removeEmployeeDialog: {
        show: false,
        loading: false
      },

      restoreEmployeeDialog: {
        show: false,
        loading: false
      },

      userRoles: [
        {
          name: this.$t("general.roleAdmin", this.$store.state.locale),
          id: "admin",
        },
        {
          name: this.$t("general.roleManager", this.$store.state.locale),
          id: "manager",
        },
        {
          name: this.$t("general.roleEmployee", this.$store.state.locale),
          id: "employee",
        },
      ],

      emailHeaders: [
        {
          text: this.$t('general.email', this.$store.state.locale),
          align: "left",
          value: "email",
          sortable: false,
        },
      {
          text: "Score",
          align: "center",
          value: "primary",
          width: "40px",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          value: "primary",
          width: "40px",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          value: "primary",
          width: "40px",
          sortable: false,
        }  
      ],
      phoneHeaders: [
        {
          text: this.$t('general.type', this.$store.state.locale),
          align: "center",
          value: "type",
          width: "50px",
          sortable: false,
        },
        {
          text: this.$t('general.phone', this.$store.state.locale),
          align: "left",
          value: "phone",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          value: "primary",
          width: "50px",
          sortable: false,
        },
        {
          text: "",
          align: "center",
          value: "primary",
          width: "50px",
          sortable: false,
        }  
      ],
      addAddressManual: false,
      addressNotFound: false,
      clientAddressChecked: new Object(),
      addressChecked: false,
      selectedEmployeeIdCopy: false,
      selectedCorrectionIdCopy: false,
      presenceDocCopy: false,
      absenceDocCopy: false,
      menuBirthDate: false,
      genders: [
        {
          id: 1,
          name: this.$t('general.male', this.$store.state.locale)
        },
        {
          id: 2,
          name: this.$t('general.female', this.$store.state.locale) 
        },
        {
          id: 3,
          name: this.$t('general.other', this.$store.state.locale) 
        }
      ],
      employment: [
        {
          id: "employee",
          name: this.$t('general.employee', this.$store.state.locale) 
        },
        {
          id: "intern",
          name: this.$t('employees.intern', this.$store.state.locale) 
        },
        {
          id: "seatTenant",
          name: this.$t('employees.seatTenant', this.$store.state.locale) 
        },
        {
          id: "entrepreneur",
          name: this.$t('employees.entrepreneur', this.$store.state.locale) 
        },
        {
          id: "owner",
          name: this.$t('employees.owner', this.$store.state.locale) 
        }
      ],

      /* 1.1 Employment */
      libraryDialog: false,
      deviceTypes: "",
      abilities: "",
      searchAbilities: "",
      searchDeviceTypes: "",
      menuStartDate: false,
      menuEndDate: false,
      employeeContractStartMax: moment().add(1, "year").format("YYYY-MM-DD"),
      employeeContractEndMax: moment().add(5, "year").format("YYYY-MM-DD"),
      abilities: new Array(),
      deviceTypes: new Array(),

      /* 1.2 Nfc & Pincode */
      nfcStatus: {
        status: "empty",
        id: null,
      },
      pincodesLoading: true,
      pincode: null,
      companyAuthentication: new Array(),

      /* 1.2 Account */
      searchEmail: "",
      searchEmailOriginal: "",
      foundUsers: new Array(),
      findUserLoading: false,
      userSearched: false,
      selectedUserForAccount: false,
      selectedUser: null,
      userAccount: null,
      userAccountOriginal: null,
      userAccountLoading: true,
      existingUserAccount: false,
      newUserAccount: false,
      employeeUserRole: "employee",
      employeeUserEmail: "",
      employeeUserLanguage: "NL",
      employeeUserPassword: "",
      employeeUserSendEmail: false,
      employeeDataLoading: true,

      // 2. SCHEDULE
      daysOfWeek: [ // Used for displaying Schedule
        {
          id: "Mon",
          name: "monday"
        },
        {
          id: "Tue",
          name: "tuesday"
        },
        {
          id: "Wed",
          name: "wednesday"
        },
        {
          id: "Thu",
          name: "thursday"
        },
        {
          id: "Fri",
          name: "friday"
        },
        {
          id: "Sat",
          name: "saturday"
        },
        {
          id: "Sun",
          name: "sunday"
        }
      ],

      leaveDetailsHeaders: [
        {
          text: "",
          align: "left",
          value: "start",
          width: "40px",
        },
        {
          text: this.$t('general.start', this.$store.state.locale),
          align: "left",
          value: "end",
          width: "220px",
        },
        {
          text: this.$t('employees.timeDescription', this.$store.state.locale),
          align: "left",
          value: "extra",
          sortable: false,
        },
        {
          text: this.$t('employees.time', this.$store.state.locale),
          align: "right",
          value: "extra",
          width: "120px",
          sortable: false,
        },
      ],
      headersExceptions: [
        {
          text: this.$t('general.period', this.$store.state.locale),
          align: "left",
          value: "startdate",
          width: "150px",
        },
        {
          text: this.$t('employees.times', this.$store.state.locale),
          align: "left",
          value: "time",
          width: "150px",
        },
        {
          text: this.$t('employees.description', this.$store.state.locale),
          align: "left",
          value: "extra",
          sortable: false,
        },
        {
          text: "",
          align: "left",
          value: "name",
          sortable: false,
          width: "100px",
        },
      ],
      headersExceptionsAbsent: [
        {
          text: this.$t('general.date', this.$store.state.locale),
          align: "left",
          value: "startdate",
          width: "190px",
        },
        {
          text: this.$t('employees.description', this.$store.state.locale),
          align: "left",
          value: "extra",
          sortable: false,
        },
        {
          text: this.$t('general.hours', this.$store.state.locale),
          align: "left",
          value: "name",
          sortable: false,
          width: "80px",
        },
      ],
      headersExceptionsExtra: [
        {
          text: this.$t('general.date', this.$store.state.locale),
          align: "left",
          value: "startdate",
          width: "190px",
        },
        {
          text: this.$t('employees.description', this.$store.state.locale),
          align: "left",
          value: "extra",
          sortable: false,
        },
        {
          text: this.$t('general.hours', this.$store.state.locale),
          align: "left",
          value: "name",
          sortable: false,
          width: "80px",
        },
      ],
      employeeExceptionsLeave: new Array(),
      employeeExceptionsSick: new Array(),
      employeeExceptionsExtra: new Array(),
      employeeExceptionsDate: {
        year: moment().format("YYYY"),
        type: 'year',
        number: null
      },
      presenceDisplay: "future",
      exeptionDisplay: "future",
      scheduleStartDateRaw: null,
      scheduleStartDateDialog: false,
      scheduleEndDateRaw: null,
      scheduleEndDateDialog: false,
      exeptionsLoading: true,
      presenceLoading: true,
      employeeExceptionsLoading: true,
      employeeExceptions: new Array(),
      employeeExceptionsOtherBranches: new Array(),
      exceptionEnd: false,
      feedbackExeption: null,
      feedbackPresence: null,
      exceptionStart: false,
      exceptionAvailableDay: false,
      presenceDialogDialog: false,
      employeePresence: new Array(),
      employeePresenceOtherBranches: new Array(),
      newBasicScheduleOriginal: new Object(),
      newBasicSchedule: {
        schedule: {
          continiousScheduleType: new Object(),
          Sun: new Array(),
          Mon: new Array(),
          Tue: new Array(),
          Wed: new Array(),
          Thu: new Array(),
          Fri: new Array(),
          Sat: new Array()
        },
        minutes: new Object()
      },

      basicSchedulePeriod: {
        dialog: false,
        type: 'work',
        startHour: null,
        startMinutes: null,
        endHour: null,
        endMinutes: null,
        day: null,
        feedback: null,
        continiousType: 'all'
      },
      hours:['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23'],
      minutes: ['00','05','10','15','25','30','35','40','45','50','55'],
      basicSchedulePeriodChanges: 0,
      newBasicScheduleStartDateDialog: false,
      continiousScheduleTypes:[
        {
          id: "continious",
          name: this.$t('employees.continiousSchedule', this.$store.state.locale)
        },
        {
          id: "alternately",
          name: this.$t('employees.alternatelySchedule', this.$store.state.locale)
        }
      ],
      scheduleTypes:[
        {
          id: "work",
          name: this.$t('employees.scheduleTypeWork', this.$store.state.locale)
        },
        {
          id: "pause",
          name: this.$t('employees.scheduleTypePause', this.$store.state.locale)
        }
      ],
      employeeWeekScheduleDateDialog: false,
      employeeWeekScheduleDate: moment().format("YYYY-MM-DD"),
      employeeWeekSchedule: {
        loading: true,
        firstDay: null,
        lastDay: null,
        minutes: null,
        type: null,
        minutesAverage: null,
        weekNumber: null,
        days: new Array(),
        schedule: {
          Sun: new Array(),
          Mon: new Array(),
          Tue: new Array(),
          Wed: new Array(),
          Thu: new Array(),
          Fri: new Array(),
          Sat: new Array() 
        }
      },
      employeeWeekScheduleOtherBranches: {
        exist: false,
        schedules: new Array(),
        type: null,
        branches: new Array(),
        minutesAverage: null,
        weekNumber: null,
        days: new Array(),
        schedule: {
          Sun: new Array(),
          Mon: new Array(),
          Tue: new Array(),
          Wed: new Array(),
          Thu: new Array(),
          Fri: new Array(),
          Sat: new Array() 
        }
      },

      
      // 3. HOURS & LEAVE

      // 3.1 Cao
      yearWorkedHourSchedule: null,
      caoItems: new Array(),
      selectedYear: moment().year(),
      years: [2022,2023],

      leaveCorrectionTypes: [
        {
          id: "carriedOverLastYear",
          name: this.$t('employees.carriedOverLastYear', this.$store.state.locale) 
        },
        {
          id: "payoutHours",
          name: this.$t('employees.payoutHours', this.$store.state.locale) 
        },
        {
          id: "correctionAddHours",
          name: this.$t('employees.correctionAddHours', this.$store.state.locale) 
        },
        {
          id: "correctionSubtractHours",
          name: this.$t('employees.correctionSubtractHours', this.$store.state.locale) 
        }
      ],
      employeeLeaveLoading: true,
      leaveCorrectionHours: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70],
      leaveCorrectionMinutes: [0,5,10,15,20,25,30,35,40,45,50,55],

      leaveOverviewHeaders: [
        {
          text: this.$t('general.description', this.$store.state.locale) ,
          align: "left",
          value: "type",
          width: "50px",
          sortable: false,
        },
        {
          text:  this.$t('employees.time', this.$store.state.locale) ,
          align: "right",
          value: "primary",
          width: "50px",
          sortable: false,
        }
      ],

      saveDisabled: false,
      employeeLeave: {
        total: {
          hours: 0,
          minutes: 0,
          time: 0
        },
        credit: {
          hours: 0,
          minutes: 0,
          time: 0
        },
        used: {
          hours: 0,
          minutes: 0,
          time: 0
        },
        collected: {
          hours: 0,
          minutes: 0,
          time: 0
        },
        extraHours: {
          hours: 0,
          minutes: 0,
          time: 0
        },
        correction: {
          hours: 0,
          minutes: 0,
          time: 0
        },
        lastYearPickedUp: {
          hours: 0,
          minutes: 0,
          time: 0
        },
        extraLeaveNoSickness: {
          hours: 0,
          minutes: 0,
          time: 0,
          period: null,
          items: new Array()
        },
        lessLeaveSickness: {
          hours: 0,
          minutes: 0,
          time: 0,
          period: null,
          items: new Array()
        },
        extraLeaveAgeOrContract: {
          hours: 0,
          minutes: 0,
          time: 0,
          period: null,
          items: new Array()
        },
        details: new Array()
      },
      leaveCorrection:{
        date: null,
        dialogType: 'new',
        dateDialog: false,
        type: null,
        time: 0,
        hours: null,
        minutes: null,
        description: null,
        dialog: false,
        busy: false,
        feedback: null,
        docId: null
      },
      employeeLeaveCorrection: new Array(),

      scheduleSpaces: 0,
      newScheduleSpaces: 0,
      scheduleStart: "00:00",
      scheduleEnd: "23:59",
      scheduleScale: 0.6,
      newSchedulePeriodChanges: 0,

      presenceDialog: new Object(),
      exceptionDialog: new Object(),
    };
  },



  created() {
    bus.$on("getEmployeeInformation", (employeeId) => {
      this.employeeDataLoading = true;
      this.resetEmployeeWeekSchedule();
      this.tab = "basic";
      this.getEmployeeInformation(employeeId);
    })
    bus.$on("refreshNFC", () => {
      this.checkNFC();
    });
    this.resetNewBasicSchedule();
    this.resetPresenceDialog();
    this.resetExceptionDialog();
  },


  methods: {
    /**
     * Determin first possible day to start new schedule. If the basic schedule already exits, this is always first day of the next week
     */
    nextWeek(){
      if(this.selectedEmployeeInfo && this.selectedEmployeeInfo.basicSchedule && this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].startDate != null){ // basic schedule already exits. Pick the first day of the next week
        return moment().add(1, 'week').day(0).format("YYYY-MM-DD");
      }
      else{ // Return today
        return moment().format("YYYY-MM-DD");
      }
    },

    employeeWeekScheduleIsInFuture(){
      let year = moment().format("YYYY");
      let week = moment().isoWeek();
      if(this.employeeWeekSchedule.year >= year && this.employeeWeekSchedule.weekNumber >= week){
        return true;
      }
      else{
        return false;
      }
    },

    resetExceptionDialog(){
      this.exceptionDialog = {
        id: null,
        show: false,
        type: 'new',
        day: true,
        period: false,
        time: new Array(),
        extra: "",
        startDate: null,
        endDate: null,
        overlap: {
          checking: false,
          dialog: false,
          overlaps: new Array(),
        },
        feedback: null
      };
    },

    addAbcenceException(){
      this.exceptionDialog.show = true;
    },

    editAbcenceException(item){
      this.exceptionDialog = {
        id: item.id,
        type: 'edit',
        show: true,
        reason: item.reason,
        day: item.time && item.time.length > 0 ? false : true,
        period: item.startdate == item.enddate ? false : true,
        time: item.time,
        extra: item.extra,
        startDate: item.startdate,
        endDate: item.enddate,
        overlap: {
          checking: false,
          dialog: false,
          overlaps: new Array(),
        },
        feedback: null
      };
    },


    resetPresenceDialog(){
      console.log("resetPresenceDialog")
      this.presenceDialog = {
        id: null,
        day: false,
        period: false,
        show: false,
        type: 'new',
        reason: null,
        extra: null,
        startDate: null,
        time: new Array(),
        overlap: {
          checking: false,
          dialog: false,
          overlaps: new Array(),
        },
        feedback: null
      };
      this.presenceDialog.time.push({ start: "", end: "" })
    },

    addPresenceException(){
      this.presenceDialog.show = true;
    },

    editPresenceException(item){
      this.presenceDialog = {
        id: item.id,
        day: false,
        period: false,
        show: true,
        type: 'edit',
        reason: item.reason,
        extra: item.extra,
        startDate: item.startdate,
        time: item.time,
        overlap: {
          checking: false,
          dialog: false,
          overlaps: new Array(),
        },
        feedback: null
      };
    },

    openLeaveDetails(item){
      if(this.$store.state.userRoleNumber < 2){
        if(item.docId && item.docType){
          if(item.docType == "leaveCorrections"){
            this.leaveCorrection.docId = item.docId;
            this.leaveCorrection.description = item.description;
            this.leaveCorrection.type = item.overviewType;
            this.leaveCorrection.date = item.start;
            this.leaveCorrection.time = item.time;
            this.leaveCorrection.hours = Math.floor(item.time / 60);
            this.leaveCorrection.minutes = item.time - (this.leaveCorrection.hours * 60);
            this.leaveCorrection.dialogType = 'edit';
            this.leaveCorrection.feedback = null;
            this.leaveCorrection.dateDialog = false;
            this.leaveCorrection.dialog = true;
          }
          console.log("Open details data")
        }
        else{
          console.log("No data")
        }
      }
      
    },

    async getEmployeeInformation(employeeId){
      await this.loadEmployeeData(employeeId);
      this.employeeLeaveLoading = true;
      
      this.loadDeviceTypes();
      this.loadAbilities();
      this.editEmployeeDialog = true;
      this.existingUserAccount = false;
      this.newUserAccount = false;
      this.pincode = null;
      this.checkNFC();
      this.getCompanyPincodes();
      this.calculateEmployeeLeaveYears();
      this.loadCao();
      this.tabChanged();
    },

    async tabChanged() {
      this.scheduleStartDateRaw = null;
      this.scheduleEndDateRaw = null;

      if(this.selectedBranch){
        this.scheduleStartDateRaw = this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].startDate;
        this.scheduleEndDateRaw = this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].endDate;
      }
      

      if(this.tab == 'schedule'){
        this.getEmployeeExeptions();
        this.getEmployeePresence();
        await this.getEmployeeWeekScheduleData();
        this.calculateScheduleSpaces();
        
  
      }
      if(this.tab == 'exceptions'){
        this.getEmployeeExeptionsOverview();
      }
      if(this.tab == 'leave'){
        this.employeeLeaveLoading = true;
        this.getEmployeeExeptionsOverview();
        this.getEmployeeLeaveCorrections();
        this.getEmployeeTime()
        this.calculateEmployeeLeave();
      }
    },

    selectUser(user) {
      this.selectedUserForAccount = true;
      this.selectedEmployeeInfo.userId = user.id;
      this.selectedEmployeeInfo.uid = user.user_id;
      this.selectedEmployeeInfo.loginAccountActive = false;
      this.selectedEmployeeInfo.loginAccount = true;
      if (user.active) {
        this.selectedEmployeeInfo.loginAccountActive = true;
      }
      this.userAccount = _.cloneDeep(user);
      this.userAccountLoading = false;
      this.searchEmailOriginal = "";
      this.searchEmail = "";
      this.foundUsers = new Array();
      this.userSearched = false;
      this.updateEmployee('connectUser')
    },

    roundValue(object, key, index, type, rounding){
      if(this[object][key][index][type].length == 5){
        console.log("field, index, type, rounding", object, key, index, type, rounding)
        console.log("data: ", this[object][key][index][type])
        let time = this[object][key][index][type];
        let minutes = time.slice(-2);
        let newMinutes = Math.round(minutes/5)*5; // Round to nearest 5 
        this[object][key][index][type] = `${time.slice(0, 2)}:${moment(newMinutes, 'm').format("mm")}`
        console.log(`${time.slice(0, 2)}:${moment(newMinutes, 'm').format("mm")}`);
      }
      
    },


    calculateExceptionTime(item){ // Calculate absence of presence time based on year schedule

      /* EXPLAINATION   
      1. A day or period of is calulcated into hours based on the schedule of the employee
      2. Don't count overlaps on extrapresence
      */
    
      let days = [
        {format: 'Sun', number: 0},
        {format: 'Mon', number: 1},
        {format: 'Tue', number: 2},
        {format: 'Wed', number: 3},
        {format: 'Thu', number: 4},
        {format: 'Fri', number: 5},
        {format: 'Sat', number: 6}
      ];

      let minutes = 0;
      // Welke week of weken
      let period = item.startdate == item.enddate ? false : true; // check if exception is multiple days

      let startWeekNumber = moment(item.startdate).isoWeek();
      let endWeekNumber = moment(item.enddate).isoWeek();
      let weeks = Array((endWeekNumber - startWeekNumber) + 1).fill("");
      let counter = 0;

      for (let i = 0; i < weeks.length; i++) {
        weeks[i] = startWeekNumber + counter;
        counter++
      }
      
      weeks.forEach(weekNumber=>{ // Loop trough weeks
        let weekData = this.yearWorkedHourSchedule.find(week=> {return week.week == weekNumber})
        moment.locale('en')
        days.forEach(day=>{ // Loop trough days of the week
          let dayData = weekData.days[day.format] ? weekData.days[day.format] : null;
          let dayDate = moment().year(this.selectedYear).isoWeek(weekNumber).weekday(day.number).format("YYYY-MM-DD")

          if(dayData && dayData.schedule && dayDate >= item.startdate && dayDate <= item.enddate ){
            let extraPresentMinutes = 0
            console.lo
            if(item.time && item.time.length > 0){
              
              item.time.forEach(exceptionTime=>{ // Loop through times of the absence or presence

                if(dayData.schedule && dayData.schedule.length > 0){
                  if(item.type == 'absence'){ // We calculate the overlap time for absence
                    dayData.schedule.forEach(time=>{
                      let start = exceptionTime.start >= time.start && exceptionTime.start <= time.end ? time.end : exceptionTime.start;
                      let end = exceptionTime.end >= time.start && exceptionTime.end <= time.end ? time.end : exceptionTime.end;
                      minutes = minutes + Number(moment(end, "HH:mm").diff(moment(start, "HH:mm"), "minutes"));
                    })
                  }
                  else{ // We remove the overlap time for presence
                    extraPresentMinutes = extraPresentMinutes + Number(moment(exceptionTime.end, "HH:mm").diff(moment(exceptionTime.start, "HH:mm"), "minutes"));
                    let overlapMinutes = 0;
                    dayData.schedule.forEach(time=>{

                      if(exceptionTime.start > time.start && exceptionTime.start < time.end || exceptionTime.end > time.start && exceptionTime.end < time.end || exceptionTime.start < time.start && exceptionTime.end > time.end ){
                        let start = exceptionTime.start < time.start && exceptionTime.start <= time.end ? exceptionTime.start > time.start && exceptionTime.start <= time.end ? time.end : time.start : exceptionTime.start;
                        let end = exceptionTime.end >= time.start && exceptionTime.end <= time.end ? exceptionTime.end : exceptionTime.end >= time.start && exceptionTime.end >= time.end ? time.end : exceptionTime.start;
                        overlapMinutes = overlapMinutes + Number(moment(end, "HH:mm").diff(moment(start, "HH:mm"), "minutes"));
                      }
                    })

                    minutes = extraPresentMinutes - overlapMinutes; // Remove overlap minutes from extraPresentMinutes
                  }
                 
                }
                else{ // No times in employee schedule
                  minutes = minutes + Number(moment(exceptionTime.end, "HH:mm").diff(moment(exceptionTime.start, "HH:mm"), "minutes"));
                }
              })
            }
            else{// Whole day absence, just get the already calculated workedMinutes
              minutes = minutes + dayData.workedMinutes;
            }
          }
        })
      })
      
      return this.minutesToHourFormat(minutes, 5); // Return minutes formated as hour
    },

    async saveBasicScheduleDatesEmployee(){
      this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].startDate = this.scheduleStartDate.firebase
      this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].endDate = this.scheduleEndDate.firebase
      await db.collection("employees")
      .doc(this.selectedEmployeeInfo.id)
      .set({ basicSchedule: this.selectedEmployeeInfo.basicSchedule},{ merge: true })
      .catch((error) => {
        console.error("Error updating basic schedule employee: ", error);
      });
    },

    continiousScheduleNumbers(type){
      if(type && type == 'alternately'){
        return [1,2]
      }
      return [1]
    },

    resetScheduleStartDate(){
      this.scheduleStartDateRaw = '';
    },

    resetScheduleEndDate(){
      this.scheduleEndDateRaw = '';
    },

    async changeBasicSchedule(){
      this.newBasicSchedule.schedule = _.cloneDeep(this.selectedEmployeeInfo.basicSchedule[this.selectedBranch]);
      
      this.sortPeriod('Mon')
      await this.checkDeprecatedcheduleData();
      await this.calculateBasicScheduleHours();

      this.newBasicScheduleOriginal = _.cloneDeep(this.newBasicSchedule)
      this.newBasicSchedule.dialog = true;
    },
    
    async checkDeprecatedcheduleData(){
      if(!this.newBasicSchedule.schedule.continiousScheduleType){
        this.newBasicSchedule.schedule.continiousScheduleType = {
          Sun:"continious",
          Mon:"continious",
          Tue:"continious",
          Wed:"continious",
          Thu:"continious",
          Fri: "continious",
          Sat: "continious"
        }
      }
      let days = [
          {format: 'Sun', number: 0},
          {format: 'Mon', number: 1},
          {format: 'Tue', number: 2},
          {format: 'Wed', number: 3},
          {format: 'Thu', number: 4},
          {format: 'Fri', number: 5},
          {format: 'Sat', number: 6}
        ];

        days.forEach(day=>{ // Check all Days
          // console.log("checking day: ", day)
          if(this.newBasicSchedule.schedule[day.format] && this.newBasicSchedule.schedule[day.format].length > 0){
            this.newBasicSchedule.schedule[day.format].forEach(time=>{ // Calculate minutes per day
              if(!time.continiousType){
                time.continiousType = 'all'
              }
              if(!time.type){
                time.type = 'work'
              }
            })
          }
        })
        return
    },

    resetNewBasicSchedule(){
      this.newBasicSchedule = {
        dialog: false,
        startDate: null,
        feedback: null,
        schedule: {
          continiousScheduleType: {},
          Sun: new Array(),
          Mon: new Array(),
          Tue: new Array(),
          Wed: new Array(),
          Thu: new Array(),
          Fri: new Array(),
          Sat: new Array()
        },
        type: 'new',
        minutes: {
          type: "continious",
          total:0,
          even: 0,
          odd: 0
        }
      };
    },


    checkDayTypes(){ // Set the types per day of the schedule (continious or alternately). To show and select in the GUI
      this.newBasicSchedule.schedule.continiousScheduleType = {
        Mon: "continious",
        Tue: "continious",
        Wed: "continious",
        Thu: "continious",
        Fri: "continious",
        Sat: "continious",
        Sun: "continious"
      };

      let days = [
        {format: 'Sun', number: 0},
        {format: 'Mon', number: 1},
        {format: 'Tue', number: 2},
        {format: 'Wed', number: 3},
        {format: 'Thu', number: 4},
        {format: 'Fri', number: 5},
        {format: 'Sat', number: 6}
      ];

      days.forEach(day=>{ // Check all Days of the New Basic Schedule
        if(this.newBasicSchedule.schedule[day.format] && this.newBasicSchedule.schedule[day.format].length > 0){
          this.newBasicSchedule.schedule[day.format].forEach(time=>{ // Calculate minutes per day
            if(time.type != 'pause'){ // Only calculate work time
              if(time.continiousType != 'all'){
                this.newBasicSchedule.schedule.continiousScheduleType[day] = "alternately";
              }
            }
          })
        }
      })
    },

    calculateBasicScheduleHours(){
      let all = 0;
      let even = 0;
      let odd = 0;

      let days = [
        {format: 'Sun', number: 0},
        {format: 'Mon', number: 1},
        {format: 'Tue', number: 2},
        {format: 'Wed', number: 3},
        {format: 'Thu', number: 4},
        {format: 'Fri', number: 5},
        {format: 'Sat', number: 6}
      ];

      days.forEach(day=>{ // Check all Days of the New Basic Schedule
        if(this.newBasicSchedule.schedule[day.format] && this.newBasicSchedule.schedule[day.format].length > 0){
          this.newBasicSchedule.schedule[day.format].forEach(time=>{ // Calculate minutes per day
            if(time.type != 'pause'){ // Only calculate work time
              let timeInMinutes = Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes"));

              if(time.continiousType == 'even'){
                even = even + timeInMinutes;
              }
              else if(time.continiousType == 'odd'){
                odd = odd + timeInMinutes;
              }
              else{
                all = all + timeInMinutes;
              }
            }
          })
        }
      })
      
      even = all + even;
      odd = all + odd;
      let total = (even + odd) / 2;

      if(all == even && all == odd){
        this.newBasicSchedule.minutes.type = 'continious';
      }
      else{
        this.newBasicSchedule.minutes.type = 'alternately';
      }

      this.newBasicSchedule.minutes.total = total;
      this.newBasicSchedule.minutes.even = even;
      this.newBasicSchedule.minutes.odd = odd;

      return
    },

    getTimeBlockColor(type){
      if(type == 'pause'){
        return this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].background;
      }
      else{
        return this.$themes[this.$store.state.companyTheme][this.$store.state.themeModus].primary;
      }
    },

    calculateScheduleMinutes(time){
      return Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes")) * this.scheduleScale;
    },

    /** Calulcate the distance of the time items 
     * @param {String} type                 =  Where is the function used? Overview of Modal
     * @param {String} day                  =  Formatted name of the day (Fir, Mon Sat etc)
     * @param {String} index                =  index of the time item in the schedule array
     * @param {String} continiousType       =  all / even / odd
     * @param {String} employeescheduleType =  name data type to check for schedule / (employeeWeekSchedule or employeeWeekScheduleOtherBranches)
     * 
     */
    calculateScheduleDistance(type, day, index, continiousType, employeescheduleType){
      let distance = 0;
      let continiousScheduleType = this.newBasicSchedule.schedule[day].type
      
      if(type == 'modal'){
        // Find first starttime of this schedule
        let newScheduleStart = this.newBasicSchedule.schedule[day][index].start;
        let days = [
          {format: 'Sun', number: 0},
          {format: 'Mon', number: 1},
          {format: 'Tue', number: 2},
          {format: 'Wed', number: 3},
          {format: 'Thu', number: 4},
          {format: 'Fri', number: 5},
          {format: 'Sat', number: 6}
        ];

        let timesStart =  new Array();
        let timesEnd =  new Array();

        days.forEach(day=>{ // Check all Days
          if(this.newBasicSchedule.schedule[day.format]){
            this.newBasicSchedule.schedule[day.format].forEach(time=>{ // Calculate minutes per day
              timesStart.push(time.start);
              timesEnd.push(time.end);
            })
          }
        })

        let uniqueTimesStart = [...new Set(timesStart)];
        let uniqueTimesEnd = [...new Set(timesEnd)];

        uniqueTimesStart.sort();
        uniqueTimesEnd.sort();
        newScheduleStart = uniqueTimesStart[0];


        let continiousTypes = new Object()
        this.newBasicSchedule.schedule[day].forEach(time=>{
          if(!continiousTypes[time.continiousType]){
            continiousTypes[time.continiousType] = new Array()
          }
          continiousTypes[time.continiousType].push(time)
        })

        let continiousIndex = continiousTypes[this.newBasicSchedule.schedule[day][index].continiousType].findIndex(time => time.start === this.newBasicSchedule.schedule[day][index].start);

        if(continiousIndex == 0){
          distance =  Number(moment(continiousTypes[this.newBasicSchedule.schedule[day][index].continiousType][continiousIndex].start, "HH:mm").diff(moment(newScheduleStart, "HH:mm"), "minutes")) * this.scheduleScale;
        }
        else{
          distance =  Number(moment(continiousTypes[this.newBasicSchedule.schedule[day][index].continiousType][continiousIndex].start, "HH:mm").diff(moment(continiousTypes[this.newBasicSchedule.schedule[day][index].continiousType][continiousIndex-1].end, "HH:mm"), "minutes")) * this.scheduleScale;
        }
      }
      else{
        if(this.employeeWeekSchedule.schedule[day][index]){
          if(index == 0){
            distance =  Number(moment(this[employeescheduleType].schedule[day][index].start, "HH:mm").diff(moment(this.scheduleStart, "HH:mm"), "minutes")) * this.scheduleScale;
          }
          else{
            distance =  Number(moment(this[employeescheduleType].schedule[day][index].start, "HH:mm").diff(moment(this[employeescheduleType].schedule[day][index - 1].end, "HH:mm"), "minutes")) * this.scheduleScale;
          }
        }
        
      }
      
      return distance;
    },

    setContiniousSchedules(day){
      if(this.newBasicSchedule.schedule.continiousScheduleType[day] == 'continious'){
        this.newBasicSchedule.schedule[day] = this.newBasicSchedule.schedule[day].filter(block => {return block.continiousType != 'odd'})
        this.newBasicSchedule.schedule[day].forEach(block=>{
          block.continiousType = 'all'
        })
        this.newSchedulePeriodChanges++
      }
      else{
        let newSchedule = new Array();
        this.newBasicSchedule.schedule[day].forEach(block=>{
          let newBlock = _.cloneDeep(block);
          newBlock.continiousType = 'odd';
          block.continiousType = 'even';
          newSchedule.push(newBlock);
          newSchedule.push(block);
        })
        this.newBasicSchedule.schedule[day] = newSchedule;
        this.calculateBasicScheduleHours();
        this.newSchedulePeriodChanges++
      }
    },

    calculateScheduleSpaces(){
      this.scheduleSpaces = 0;
      let days = [
        {format: 'Sun', number: 0},
        {format: 'Mon', number: 1},
        {format: 'Tue', number: 2},
        {format: 'Wed', number: 3},
        {format: 'Thu', number: 4},
        {format: 'Fri', number: 5},
        {format: 'Sat', number: 6}
      ];

      let timesStart =  new Array();
      let timesEnd =  new Array();

      if(this.employeeWeekSchedule && this.employeeWeekSchedule.schedule){
        days.forEach(day=>{ // Check all Days
          if(this.employeeWeekSchedule.schedule[day.format]){
            this.employeeWeekSchedule.schedule[day.format].forEach(time=>{ // Calculate minutes per day
              timesStart.push(time.start);
              timesEnd.push(time.end);
            })
          }
        })
      }

      if(this.employeeWeekScheduleOtherBranches && this.employeeWeekScheduleOtherBranches.schedule){
        days.forEach(day=>{ // Check all Days
          if(this.employeeWeekScheduleOtherBranches.schedule[day.format]){
            this.employeeWeekScheduleOtherBranches.schedule[day.format].forEach(time=>{ // Calculate minutes per day
              timesStart.push(time.start);
              timesEnd.push(time.end);
            })
          }
        })
      }

      // if(this.selectedEmployeeInfo.basicSchedule && this.selectedEmployeeInfo.basicSchedule[this.selectedBranch]){
      //   days.forEach(day=>{ // Check all Days
      //     if(this.selectedEmployeeInfo.basicSchedule[this.selectedBranch][day.format]){
      //       this.selectedEmployeeInfo.basicSchedule[this.selectedBranch][day.format].forEach(time=>{ // Calculate minutes per day
      //         timesStart.push(time.start);
      //         timesEnd.push(time.end);
      //       })
      //     }
      //   })
      // }

      

      let uniqueTimesStart = [...new Set(timesStart)];
      let uniqueTimesEnd = [...new Set(timesEnd)];
      // console.log("timesStart: ", timesStart)
      // console.log("uniqueTimesStart: ", uniqueTimesStart)
      // console.log("uniqueTimesEnd: ", uniqueTimesEnd)
      uniqueTimesStart.sort();
      uniqueTimesEnd.sort();
      this.scheduleStart = uniqueTimesStart[0];
      this.scheduleEnd = uniqueTimesEnd[uniqueTimesEnd.length - 1];
      this.scheduleSpaces = Number(moment(uniqueTimesEnd[uniqueTimesEnd.length - 1], "HH:mm").diff(moment(uniqueTimesStart[0], "HH:mm"), "minutes")) * this.scheduleScale;

    },

    calculateDayHours(type, day){

      let all = 0;
      let even = 0;
      let odd = 0;


      if(this.newBasicSchedule.schedule[day] && this.newBasicSchedule.schedule[day].length > 0){ // Check for timeblocks
        this.newBasicSchedule.schedule[day].forEach(timeblock=>{
          if(timeblock.type != 'pause'){
            let timeInMinutes = Number(moment(timeblock.end, "HH:mm").diff(moment(timeblock.start, "HH:mm"), "minutes"));
            if(timeblock.continiousType == 'even'){
              even = even + timeInMinutes;
            }
            else if(timeblock.continiousType == 'odd'){
              odd = odd + timeInMinutes;
            }
            else{
              all = all + timeInMinutes;
            }
          }
        })


      even = all + even;
      odd = all + odd;
      let total = (even + odd) / 2;
      let type = (all == even && all == odd) ? 'continious' : 'alternately';

      let totalHours = Math.floor(total/60);
      let totalMinutes = (((total - (totalHours * 60)).toFixed(0))/5).toFixed(0)*5

      let evenHours = Math.floor(even/60);
      let evenMinutes = (((even - (evenHours * 60)).toFixed(0))/5).toFixed(0)*5

      let oddHours = Math.floor(odd/60);
      let oddMinutes = (((odd - (oddHours * 60)).toFixed(0))/5).toFixed(0)*5

      return {
          type: type,
          hours: {
            total: totalMinutes > 9 ? `${totalHours}:${totalMinutes}` : `${totalHours}:0${totalMinutes}`,
            even: evenMinutes > 9 ? `${evenHours}:${evenMinutes}` : `${evenHours}:0${evenMinutes}`,
            odd: oddMinutes > 9 ? `${oddHours}:${oddMinutes}` : `${oddHours}:0${oddMinutes}`
          }
        }
      }
      else{
        return {
          type: 'continious',
          hours: {
            total: 0,
            even: 0,
            odd: 0
          }
        }
      }
    },

    async addPeriod(day, continious, continiousLength){
      this.basicSchedulePeriod.type = "work";
      this.basicSchedulePeriod.startHour = null;
      this.basicSchedulePeriod.startMinutes = null;
      this.basicSchedulePeriod.endHour = null;
      this.basicSchedulePeriod.endMinutes = null;
      this.basicSchedulePeriod.feedback = null;

      this.basicSchedulePeriod.continiousType = 'all';
      if(continiousLength > 1){
        this.basicSchedulePeriod.continiousType = continious == 0 ? 'even' : 'odd' ;
      }

      this.basicSchedulePeriod.day = day;
  
      this.sortPeriod(day);

      await this.setStartHourAndMinutes(day);
      await this.setEndHourAndMinutes();

      this.basicSchedulePeriod.dialog = true;
    },

    removePeriod(day, index){
      this.newBasicSchedule.schedule[day].splice(index, 1);
      this.calculateBasicScheduleHours();
      this.sortPeriod(day);
    },

    sortPeriod(day){
      this.newBasicSchedule.schedule[day] = this.newBasicSchedule.schedule[day].sort((a, b) => a.start.localeCompare(b.start));    
      
      this.newScheduleSpaces = 0;
      
      let days = [
        {format: 'Sun', number: 0},
        {format: 'Mon', number: 1},
        {format: 'Tue', number: 2},
        {format: 'Wed', number: 3},
        {format: 'Thu', number: 4},
        {format: 'Fri', number: 5},
        {format: 'Sat', number: 6}
      ];

      let timesStart =  new Array();
      let timesEnd =  new Array();

      if(this.newBasicSchedule.schedule){
        days.forEach(day=>{ // Check all Days
          if(this.newBasicSchedule.schedule[day.format]){
            this.newBasicSchedule.schedule[day.format].forEach(time=>{ // Calculate minutes per day
              timesStart.push(time.start);
              timesEnd.push(time.end);
            })
          }
        })
      }

      let uniqueTimesStart = [...new Set(timesStart)];
      let uniqueTimesEnd = [...new Set(timesEnd)];
      uniqueTimesStart.sort();
      uniqueTimesEnd.sort();
      // console.log("this.timesStart: ", timesStart)
      this.newScheduleSpaces = Number(moment(uniqueTimesEnd[uniqueTimesEnd.length - 1], "HH:mm").diff(moment(uniqueTimesStart[0], "HH:mm"), "minutes")) * this.scheduleScale;
      this.newSchedulePeriodChanges++
    },

    async setStartHourAndMinutes(day){
      let dayTimeBlocks = _.cloneDeep(this.newBasicSchedule.schedule[day]);
      if(this.basicSchedulePeriod.continiousType != 'all'){
        dayTimeBlocks = _.cloneDeep(this.newBasicSchedule.schedule[day].filter(block => {return block.continiousType == this.basicSchedulePeriod.continiousType}))
      }

      if(dayTimeBlocks && dayTimeBlocks.length > 0){ // Check for previous timeblocks
        let latestHour = dayTimeBlocks[dayTimeBlocks.length - 1].end.substring(0, 2);
        let latestHourNumber = Number(dayTimeBlocks[dayTimeBlocks.length - 1].end.substring(0, 2));
        let latestMinutes = dayTimeBlocks[dayTimeBlocks.length - 1].end.substring(3, 5);

        if(Number(latestMinutes) <= 55 ){
          this.basicSchedulePeriod.startHour = latestHour;
          this.basicSchedulePeriod.startMinutes = latestMinutes;
        }
        else{
          if(latestHourNumber < 23 ){

            this.basicSchedulePeriod.startHour = latestHourNumber > 8 ? `0${ latestHourNumber++ }` : latestHourNumber++ ;
            this.basicSchedulePeriod.startMinutes = '00';
          }
          else{
            this.basicSchedulePeriod.startHour = null;
            this.basicSchedulePeriod.startMinutes = null;
          }
        }
      }
      else{
        this.basicSchedulePeriod.startHour = '09';
        this.basicSchedulePeriod.startMinutes = '00';
      }
      return
    },

    /**
    * Triggerd on starthour of startminutes changes
    @type {string} triggert field hour or minutes
    */
    async setEndHourAndMinutes(type){
      if(type && type == 'hour' && this.filteredStartMinutes && this.filteredStartMinutes.length > 0){ // Set new (available) start minutes value
        this.basicSchedulePeriod.startMinutes = this.filteredStartMinutes[0];
      }
      this.basicSchedulePeriod.endHour = this.filteredEndHours[0];
      await this.setEndMinutes();
      return
    },

    setEndMinutes(){ // Set endminute value based on available minutes
      this.basicSchedulePeriod.endMinutes = this.filteredEndMinutes[this.filteredEndMinutes.length - 1];
      return
    },

    closeBasicSchedulePeriod(){ // Close New Period for new Basic Schedule
      this.calculateBasicScheduleHours();
      this.basicSchedulePeriod.dialog = false;
    },
    
    addBasicSchedulePeriod(){ // Add New Period to new Basic Schedule
      if(`${this.basicSchedulePeriod.endHour}:${this.basicSchedulePeriod.endMinutes}` && `${this.basicSchedulePeriod.endHour}:${this.basicSchedulePeriod.endMinutes}` && (`${this.basicSchedulePeriod.startHour}:${this.basicSchedulePeriod.startMinutes}` < `${this.basicSchedulePeriod.endHour}:${this.basicSchedulePeriod.endMinutes}`)){
        this.newBasicSchedule.schedule[this.basicSchedulePeriod.day].push({
          type: this.basicSchedulePeriod.type,
          start: `${this.basicSchedulePeriod.startHour}:${this.basicSchedulePeriod.startMinutes}`,
          end: `${this.basicSchedulePeriod.endHour}:${this.basicSchedulePeriod.endMinutes}`,
          continiousType: this.basicSchedulePeriod.continiousType
        })
        this.sortPeriod(this.basicSchedulePeriod.day);
        this.closeBasicSchedulePeriod();
      }
      else{
        this.basicSchedulePeriod.feedback = "Vul alle velden in."
      }
    },

    async addNewBasicSchedule(){
      if(this.newBasicSchedule.startDate){
        let success = false;
        let schedule = { // Compose data for database
          endDate: null,
          minutes: this.newBasicSchedule.minutes,
          continiousScheduleType: this.newBasicSchedule.schedule.continiousScheduleType,
          basicScheduleLastChanged: new Date(this.newBasicScheduleStartDate.firebase),
          startDate: this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].startDate ? this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].startDate : this.newBasicScheduleStartDate.firebase,
          Sun: this.newBasicSchedule.schedule.Sun,
          Mon: this.newBasicSchedule.schedule.Mon,
          Tue: this.newBasicSchedule.schedule.Tue,
          Wed: this.newBasicSchedule.schedule.Wed,
          Thu: this.newBasicSchedule.schedule.Thu,
          Fri: this.newBasicSchedule.schedule.Fri,
          Sat: this.newBasicSchedule.schedule.Sat,
        };

        let basicSchedule = _.cloneDeep(this.selectedEmployeeInfo.basicSchedule);
        basicSchedule[this.selectedBranch] = schedule; // Set schedule for current branch

        // Save Basic Schedule to Employee
        await db.collection("employees")
        .doc(this.selectedEmployeeInfo.id)
        .set({ basicSchedule: basicSchedule},{ merge: true })
        .then(() => {
          success = true;
        })
        .catch((error) => {
          console.error("Error updating basic schedule employee: ", error);
        });


        if(success){ // On successful saving
          let previousDate = null;
          if(this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].basicScheduleLastChanged || this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].startDate){
            if(this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].basicScheduleLastChanged){
              previousDate = moment.unix(this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].basicScheduleLastChanged.seconds).format("YYYY/MM/DD")
            }
            else{
              previousDate = moment(this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].startDate, "YYYY-MM-DD").format("YYYY/MM/DD")
            }
          }

          console.log("previousDate: ", previousDate)

          if(previousDate) { // previousDate means the new Basic Schedule is replacing an old basic Schedule from a certain day.

            // Calculate weeks between last save and new schedule date
            let startDate = moment(this.newBasicScheduleStartDate.firebase);
            let weekNumberPreviousDate = moment(previousDate, "YYYY/MM/DD").week();
            let yearPreviousDate = moment(previousDate, "YYYY/MM/DD").year();

            let weeksBetween = startDate.diff(previousDate, "weeks"); // Count number of weeks between last start of basis schedule and start of new basic schedule
            let days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

            if (weeksBetween > 0) { // Weeks to save schedules to
              // Distinguish schedules between even and odd weeks
              let dataForEvenWeeks = {
                minutes: 0,
                schedule: {
                  Sun: new Array(),
                  Mon: new Array(),
                  Tue: new Array(),
                  Wed: new Array(),
                  Thu: new Array(),
                  Fri: new Array(),
                  Sat: new Array()
                }
              }

              let dataForOddWeeks = {
                minutes: 0,
                schedule: {
                  Sun: new Array(),
                  Mon: new Array(),
                  Tue: new Array(),
                  Wed: new Array(),
                  Thu: new Array(),
                  Fri: new Array(),
                  Sat: new Array()
                }
              }

              days.forEach((day)=>{
                if(this.selectedEmployeeInfo.basicSchedule[this.selectedBranch][day] && this.selectedEmployeeInfo.basicSchedule[this.selectedBranch][day].length > 0){
                  this.selectedEmployeeInfo.basicSchedule[this.selectedBranch][day].forEach((timeblock)=>{
                    if(!timeblock.type || timeblock.type != 'pause'){
                      let timeInMinutes = Number(moment(timeblock.end, "HH:mm").diff(moment(timeblock.start, "HH:mm"), "minutes"));
                      if(timeblock.continiousType && timeblock.continiousType == 'even'){
                        dataForEvenWeeks.minutes = dataForEvenWeeks.minutes + timeInMinutes;
                      }
                      else if(timeblock.continiousType && timeblock.continiousType == 'odd'){
                        dataForOddWeeks.minutes = dataForOddWeeks.minutes + timeInMinutes;
                      }
                      else{
                        dataForOddWeeks.minutes = dataForOddWeeks.minutes + timeInMinutes;
                        dataForEvenWeeks.minutes = dataForEvenWeeks.minutes + timeInMinutes;
                      }
                    }
                    if(timeblock.continiousType && timeblock.continiousType == 'even'){
                      dataForEvenWeeks.schedule[day].push(timeblock);  
                    }
                    else if(timeblock.continiousType && timeblock.continiousType == 'odd'){
                      dataForOddWeeks.schedule[day].push(timeblock);  
                    }
                    else{
                      dataForEvenWeeks.schedule[day].push(timeblock);  
                      dataForOddWeeks.schedule[day].push(timeblock); 
                    }
                  })
                }
              })

              let activeWeeks = Array.apply(null, Array(weeksBetween));
              let weekCounter = weekNumberPreviousDate;
              let yearCounter = yearPreviousDate;
              let counter = 0;

              await new Promise((resolve) => {
                activeWeeks.forEach((week, index) => {
                  if (weekCounter == 52) {
                    weekCounter = 0;
                    yearCounter++;
                  }
                  weekCounter++;
                  activeWeeks[index] = {
                    year: yearCounter,
                    week: weekCounter,
                  };
                  counter++;
                  if (counter == activeWeeks.length) {
                    resolve();
                  }
                });
              })
           
              let oldBasicSchedules = new Array(); // Array of the "unchanged" weeks of the old Basic Schedule period. 
              let oldCustomSchedules = new Array(); // Array of the "changed" weeks of the old Basic Schedule period. 
              let customChangedSchedules = new Array(); // Array of the "customg changed" weeks in the old Basic Schedule period. 
              let vm = this


              async function getActiveWeeks() {
                if (activeWeeks.length > 0) {
                  for (let i = 0; i < activeWeeks.length; i++) { 
                    await db.collection("schedules")
                    .where("companyId", "==", vm.$store.state.activeCompany.id)
                    .where("branchId", "==", vm.selectedBranch)
                    .where("year", "==", activeWeeks[i].year)
                    .where("week", "==", activeWeeks[i].week)
                    .where("employeeId", "==", vm.selectedEmployeeInfo.id)
                    .get()
                    .then((snap) => {
                      if (snap.size == 0) { // Schedule with this weeknumber not saved in database
                        oldBasicSchedules.push({
                          weekData: activeWeeks[i],
                        });
                      }
                      else{
                        snap.forEach((doc) => {
                          if(doc.data().type == "basicSchedule"){ // Schedule with this weeknumber saved in database
                            oldCustomSchedules.push({
                              weekData: activeWeeks[i],
                              docId: doc.id
                            });
                          }
                          else{
                            customChangedSchedules.push({
                              weekData: activeWeeks[i],
                              docId: doc.id,
                              type: doc.data().type
                            })
                          }
                        })
                      }
                    }); 
                  }
                }
              }

              await getActiveWeeks();
              await getOldCustomSchedules();
              await getOldBasicSchedules();

              async function getOldBasicSchedules() {
                if (oldBasicSchedules.length > 0) {
                  for (let i = 0; i < oldBasicSchedules.length; i++) { 
                    await db.collection("schedules").add({
                      companyId: vm.$store.state.activeCompany.id,
                      branchId: vm.selectedBranch,
                      employeeId: vm.selectedEmployeeInfo.id,
                      week: oldBasicSchedules[i].weekData.week,
                      year: oldBasicSchedules[i].weekData.year,
                      updated: new Date(),
                      schedule: oldBasicSchedules[i].weekData.week % 2 == 0 ? dataForEvenWeeks.schedule : dataForOddWeeks.schedule,
                      type: "basicSchedule",
                      minutes: oldBasicSchedules[i].weekData.week % 2 == 0 ? dataForEvenWeeks.minutes : dataForOddWeeks.minutes,
                      minutesAverage: (dataForOddWeeks.minutes + dataForEvenWeeks.minutes) / 2
                    })
                    .catch((error) => {
                      console.error("Error addin schedule: ", error);
                    });
                  }
                }          
              }

              async function getOldCustomSchedules() {
                if (oldCustomSchedules.length > 0) {
                  for (let i = 0; i < oldCustomSchedules.length; i++) { 
                    await db.collection("schedules").doc(oldCustomSchedules[i].docId).set({
                      companyId: vm.$store.state.activeCompany.id,
                      branchId: vm.selectedBranch,
                      employeeId: vm.selectedEmployeeInfo.id,
                      week: oldCustomSchedules[i].weekData.week,
                      year: oldCustomSchedules[i].weekData.year,
                      updated: new Date(),
                      schedule: oldCustomSchedules[i].weekData.week % 2 == 0 ? dataForEvenWeeks.schedule : dataForOddWeeks.schedule,
                      type: "basicSchedule",
                      minutes: oldCustomSchedules[i].weekData.week % 2 == 0 ? dataForEvenWeeks.minutes : dataForOddWeeks.minutes,
                      minutesAverage: (dataForOddWeeks.minutes + dataForEvenWeeks.minutes) / 2
                    }, { merge: true })
                    .catch((error) => {
                      console.error("Error updating schedule: ", error);
                    });
                  }
                }
              }

              // let docCounter = 0;
              // await new Promise((resolve) => {
              //   activeWeeks.forEach((week) => {
              //     db.collection("schedules")
              //     .where("companyId", "==", this.$store.state.activeCompany.id)
              //     .where("branchId", "==", this.selectedBranch)
              //     .where("year", "==", week.year)
              //     .where("week", "==", week.week)
              //     .where("employeeId", "==", this.selectedEmployeeInfo.id)
              //     .get()
              //     .then((snap) => {
              //       if (snap.size == 0) { // Schedule with this weeknumber not saved in database
              //         oldBasicSchedules.push(week);
              //       }
              //       else{
              //         if(doc.data().type == "basicSchedule"){ // Schedule with this weeknumber saved in database
              //           oldCustomSchedules.push({
              //             weekData: week,
              //             docId: doc.id
              //           });
              //         }
              //         else{
              //           customChangedSchedules.push({
              //             weekData: week,
              //             docId: doc.id,
              //             type: doc.data().type
              //           })
              //         }
              //       }
              //       docCounter++;
              //       if (docCounter == activeWeeks.length) {
              //         resolve();
              //       }
              //     });
              //   });
              // })

              // await new Promise((resolve) => {
              //   if (oldBasicSchedules.length > 0) {
              //     let savedScheduleCounter = 0;   
              //     oldBasicSchedules.forEach((week) => {
              //       db.collection("schedules").doc().set({
              //           companyId: this.$store.state.activeCompany.id,
              //           branchId: this.selectedBranch,
              //           employeeId: this.selectedEmployeeInfo.id,
              //           week: week.week,
              //           year: week.year,
              //           updated: new Date(),
              //           schedule: week.week % 2 == 0 ? dataForEvenWeeks.schedule : dataForOddWeeks.schedule,
              //           type: "basicSchedule",
              //           minutes: week.week % 2 == 0 ? dataForEvenWeeks.minutes : dataForOddWeeks.minutes,
              //           minutesAverage: (dataForOddWeeks.minutes + dataForEvenWeeks.minutes) / 2
              //         }, { merge: true }
              //       )
              //       .then(() => {
              //         //console.log("Nieuw database document aangemaakt")
              //         savedScheduleCounter++;
              //         if (savedScheduleCounter == oldBasicSchedules.length) {
              //           resolve();
              //         }
              //       })
              //       .catch((error) => {
              //         savedScheduleCounter++;
              //         if (savedScheduleCounter == oldBasicSchedules.length) {
              //           resolve();
              //         }
              //         console.error("Error updating schedules: ", error);
              //       });
              //     });
              //   }
              //   else{
              //     resolve()
              //   }
              // })

              // await new Promise((resolve) => {
              //   if (oldCustomSchedules.length > 0) {
              //     let savedOldScheduleCounter = 0;   
              //     oldCustomSchedules.forEach((week) => {
              //       db.collection("schedules").doc(week.docId).set({
              //           companyId: this.$store.state.activeCompany.id,
              //           branchId: this.selectedBranch,
              //           employeeId: this.selectedEmployeeInfo.id,
              //           week: week.weekData.week,
              //           year: week.weekData.year,
              //           updated: new Date(),
              //           schedule: week.weekData.week % 2 == 0 ? dataForEvenWeeks.schedule : dataForOddWeeks.schedule,
              //           type: "basicSchedule",
              //           minutes: week.weekData.week % 2 == 0 ? dataForEvenWeeks.minutes : dataForOddWeeks.minutes,
              //           minutesAverage: (dataForOddWeeks.minutes + dataForEvenWeeks.minutes) / 2
              //         }, { merge: true }
              //       )
              //       .then(() => {
              //         savedOldScheduleCounter++;
              //         if (savedOldScheduleCounter == oldCustomSchedules.length) {
              //           resolve();
              //         }
              //       })
              //       .catch((error) => {
              //         savedOldScheduleCounter++;
              //         if (savedOldScheduleCounter == oldCustomSchedules.length) {
              //           resolve();
              //         }
              //         console.error("Error updating schedules: ", error);
              //       });
              //     });
              //   }
              //   else{
              //     resolve()
              //   }
              // })

              schedule.basicScheduleLastChanged = {
                seconds: moment(this.newBasicScheduleStartDate.firebase).format('X')
              };
              this.selectedEmployeeInfo.basicSchedule[this.selectedBranch] = schedule;

              console.log("customChangedSchedules: ", customChangedSchedules)

              this.snackbar.active = true;
              this.snackbar.timeout = 3000;
              this.snackbar.color = "rgba(1, 230, 110, 0.7)";
              this.snackbar.icon = "mdi-thumb-up";
              this.snackbar.text = this.$t('employees.basicScheduleChanged', this.$store.state.locale);
            }
            else {
              console.log("Zelfde week nog gewijzigd");
              this.snackbar.active = true;
              this.snackbar.timeout = 3000;
              this.snackbar.color = "rgba(1, 230, 110, 0.7)";
              this.snackbar.icon = "mdi-thumb-up";
              this.snackbar.text = this.$t('employees.basicScheduleChanged', this.$store.state.locale);
            }
            this.getEmployeeWeekScheduleData('reload');
          }
          else{ // No schedule saves needed;
            this.snackbar.active = true;
            this.snackbar.timeout = 3000;
            this.snackbar.color = "rgba(1, 230, 110, 0.7)";
            this.snackbar.icon = "mdi-thumb-up";
            this.snackbar.text = this.$t('employees.basicScheduleSaved', this.$store.state.locale);
          }

          this.removeCalendarResourceCache(this.newBasicSchedule.startDate, moment(this.newBasicSchedule.startDate).add(6, "months").format("YYYY-MM-DD")) // Remove created cache in firebase

          this.selectedEmployeeInfo.basicSchedule[this.selectedBranch] = _.cloneDeep(schedule); // Update existing schedule in GUI
          // this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].basicScheduleChanged = { seconds: moment().format("X")}
          this.newBasicScheduleOriginal = new Object();
          this.newBasicSchedule.dialog = false;
        }
        else{
          this.newBasicSchedule.feedback = "Opslaan niet gelukt";
        }
      }
      else{
        this.newBasicSchedule.feedback = this.$t('employees.addNewBasicScheduleFeedback', this.$store.state.locale);
      }
    },

    async editBasicSchedule(){
      this.newBasicSchedule.loading = true;
      let scheduleData = {
        companyId: this.$store.state.activeCompany.id,
        branchId: this.selectedBranch,
        employeeId: this.selectedEmployeeInfo.id,
        week: this.newBasicSchedule.weekNumber,
        year: this.newBasicSchedule.year,
        updated: new Date(),
        schedule: this.newBasicSchedule.schedule,
        type: "customChangedSchedule",
        uniqId: uniqid(),
        minutes: this.newBasicSchedule.minutes.total,
        minutesAverage: this.newBasicSchedule.minutes.total
      }

      if(this.newBasicSchedule.docId){
        db.collection("schedules").doc(this.newBasicSchedule.docId).set(scheduleData, { merge: true })
      }
      else{
        db.collection("schedules").add(scheduleData)
      }
      this.newBasicSchedule.dialog = false;
      this.getEmployeeWeekScheduleData('reload') // Reload
      this.newBasicSchedule = {
        schedule: {
          continiousScheduleType: new Object(),
          Sun: new Array(),
          Mon: new Array(),
          Tue: new Array(),
          Wed: new Array(),
          Thu: new Array(),
          Fri: new Array(),
          Sat: new Array()
        },
        minutes: new Object()
      };
    },

    async getEmployeeTime(){
      const configAxiosPOST = {
        headers: {"Content-Type": "application/json"},
      };

      let productivityData = JSON.stringify({
        company: this.$store.state.activeCompany.id,
        branch: [this.selectedBranch],
        start: "2022-01-01",
        end: "2022-12-28",
        employee: this.selectedEmployeeInfo.id,
        timezone: "Europe/Amsterdam"
      })

      let employeeProductivityData = null;

      await axios.post(`https://backend.thrive365.nl/employeeTime`, productivityData, configAxiosPOST)
      .then(response=> {
        employeeProductivityData = response.data
      })
      .catch(error=> {
        console.error("Get EmployeeTime Error: ", error.response.data)
      })
      console.log("employeeProductivityData: ", employeeProductivityData)
    },

    addLeaveCorrectionDialog(){
      this.leaveCorrection.date = null;
      this.leaveCorrection.feedback = null;
      this.leaveCorrection.dateDialog = false;
      this.leaveCorrection.type = null;
      this.leaveCorrection.time = 0;
      this.leaveCorrection.docId = null;
      this.leaveCorrection.hours = 0;
      this.leaveCorrection.minutes = 0;
      this.leaveCorrection.description = null;
      this.leaveCorrection.dialogType = 'new';
      this.leaveCorrection.dialog = true;
    },

    async addLeaveCorrection(){
      this.leaveCorrection.feedback = null;
      let timeCheck = this.leaveCorrection.hours !== null && this.leaveCorrection.minutes !== null && (this.leaveCorrection.hours + this.leaveCorrection.minutes) > 0 ? true : false;
      if(this.leaveCorrection.date && this.leaveCorrection.type && timeCheck){
        this.leaveCorrection.busy = true;
        let exception = {
          date: new Date(this.leaveCorrection.date),
          type: this.leaveCorrection.type,
          year: Number(moment(this.leaveCorrection.date).format("YYYY")),
          time: (this.leaveCorrection.hours * 60) + this.leaveCorrection.minutes,
          description: this.leaveCorrection.description,
          companyId: this.$store.state.activeCompany.id,
          employeeId: this.selectedEmployeeInfo.id,
          userId: this.$store.state.activeUserId,
        }
        await db.collection("leaveCorrections").add(exception)
        .then(docRef=>{
          exception.date = {
            nanseconds: 0,
            seconds: Number(moment(this.leaveCorrection.date, "DD-MM-YYYY").format("X"))
          };
          exception.id = docRef.id
        })
        this.employeeLeaveCorrection.push(exception);
        this.leaveCorrection.busy = false;
        this.leaveCorrection.dialog = false;
        this.calculateEmployeeLeave()
      }
      else{
        this.leaveCorrection.feedback = this.$t('employees.addLeaveCorrectionFeedback', this.$store.state.locale) ;
      }
    },

    async saveLeaveCorrection(){
      let timeCheck = this.leaveCorrection.hours !== null && this.leaveCorrection.minutes !== null && (this.leaveCorrection.hours + this.leaveCorrection.minutes) > 0 ? true : false;
      if(this.leaveCorrection.date && this.leaveCorrection.type && timeCheck){

        this.leaveCorrection.busy = true;
        let exception = {
          date: new Date(this.leaveCorrection.date),
          type: this.leaveCorrection.type,
          time: (this.leaveCorrection.hours * 60) + this.leaveCorrection.minutes,
          description: this.leaveCorrection.description,
          userId: this.$store.state.activeUserId,
        }
        await db.collection("leaveCorrections")
        .doc(this.leaveCorrection.docId)
        .set(exception,{merge: true})

        this.employeeLeaveCorrection = this.employeeLeaveCorrection.map((item) => {
          let newItem = item
          if(item.id == this.leaveCorrection.docId){
            newItem.date = {
              nanseconds: 0,
              seconds: Number(moment(this.leaveCorrection.date, "DD-MM-YYYY").format("X"))
            };
            newItem.time = exception.time;
            newItem.description = exception.description;
            newItem.type = exception.type;
          }
          return newItem

        })
        this.leaveCorrection.busy = false;
        this.leaveCorrection.dialog = false;
        this.calculateEmployeeLeave()
      }
      else{
        this.leaveCorrection.feedback = this.$t('employees.addLeaveCorrectionFeedback', this.$store.state.locale) ;
      }
    },

    async removeLeaveCorrection(){
      this.leaveCorrection.busy = true;
      await db.collection("leaveCorrections").doc(this.leaveCorrection.docId).delete();
      this.employeeLeaveCorrection = this.employeeLeaveCorrection.filter((item) => {
        if(item.id != this.leaveCorrection.docId){
          return item
        }
      })
      this.leaveCorrection.busy = false;
      this.leaveCorrection.dialog = false;
      this.calculateEmployeeLeave()
    },


    /**
     * Get Employee Schedules and Calculate free days
     */
    async calculateEmployeeLeave(){
      this.employeeLeaveLoading = true;
      // 1. Get all worked hours of the employee
      // 2. Get CAO and calculate buildup leave
      // 3. Get leave and sickness

      let emptyTime = {
        hours: 0,
        minutes: 0,
        time: 0
      };

      this.employeeLeave.collected = _.cloneDeep(emptyTime);
      this.employeeLeave.correction = _.cloneDeep(emptyTime);
      this.employeeLeave.credit = _.cloneDeep(emptyTime);
      this.employeeLeave.extraHours = _.cloneDeep(emptyTime);
      this.employeeLeave.lastYearPickedUp = _.cloneDeep(emptyTime);
      this.employeeLeave.total = _.cloneDeep(emptyTime);
      this.employeeLeave.used = _.cloneDeep(emptyTime);
      this.employeeLeave.lessLeaveSickness = _.cloneDeep(emptyTime);
      this.employeeLeave.lessLeaveSickness.items = new Array();
      this.employeeLeave.extraLeaveNoSickness = _.cloneDeep(emptyTime);
      this.employeeLeave.extraLeaveNoSickness.items = new Array();


      let yearWorkedHourSchedule = new Array(); // Worked hours according to schedule
      let employeeLeave = new Array(); // Leave Items
      let employeeExtraHours = new Array(); // Leave Items
      let employeeSickness = new Array(); // Leave Items
      this.employeeLeave.details = new Array();

      let days = [
        {format: 'Sun', number: 0},
        {format: 'Mon', number: 1},
        {format: 'Tue', number: 2},
        {format: 'Wed', number: 3},
        {format: 'Thu', number: 4},
        {format: 'Fri', number: 5},
        {format: 'Sat', number: 6}
      ];

      let months = [
        {name: 'january', number: 1, start: null, end: null, leaveBuildUp: 0, worked: 0, cao:{}},
        {name: 'february', number: 2, start: null, end: null, leaveBuildUp: 0, worked: 0},
        {name: 'march', number: 3, start: null, end: null, leaveBuildUp: 0, worked: 0},
        {name: 'april', number: 4, start: null, end: null, leaveBuildUp: 0, worked: 0},
        {name: 'may', number: 5, start: null, end: null, leaveBuildUp: 0, worked: 0},
        {name: 'june', number: 6, start: null, end: null, leaveBuildUp: 0, worked: 0},
        {name: 'july', number: 7, start: null, end: null, leaveBuildUp: 0, worked: 0},
        {name: 'august', number: 8, start: null, end: null, leaveBuildUp: 0, worked: 0},
        {name: 'september', number: 9, start: null, end: null, leaveBuildUp: 0, worked: 0},
        {name: 'october', number: 10, start: null, end: null, leaveBuildUp: 0, worked: 0},
        {name: 'november', number: 11, start: null, end: null, leaveBuildUp: 0, worked: 0},
        {name: 'december', number: 12, start: null, end: null, leaveBuildUp: 0, worked: 0},
      ];

      months.forEach(month=>{ // Set start and enddates for months this year
        month.start = moment(month.number, "M").year(this.selectedYear).startOf('month').format("YYYY-MM-DD");
        month.end = moment(month.number, "M").year(this.selectedYear).endOf('month').format("YYYY-MM-DD");
      })

      let cao = null;

      // 1. Get all worked hours
      await new Promise((resolve)=>{
        let basisScheduleMinutes = {
          Sun: { all: 0, even: 0, odd: 0, schedule: new Array() },
          Mon: { all: 0, even: 0, odd: 0, schedule: new Array() },
          Tue: { all: 0, even: 0, odd: 0, schedule: new Array() },
          Wed: { all: 0, even: 0, odd: 0, schedule: new Array() },
          Thu: { all: 0, even: 0, odd: 0, schedule: new Array() },
          Fri: { all: 0, even: 0, odd: 0, schedule: new Array() },
          Sat: { all: 0, even: 0, odd: 0, schedule: new Array() }
        }; // Of all branches
        
        if(this.selectedEmployeeInfo.basicSchedule){
          Object.entries(this.selectedEmployeeInfo.basicSchedule).forEach(([key]) => { // Check in all Branches
            days.forEach(day=>{ // Check all Days
              if(this.selectedEmployeeInfo.basicSchedule[key][day.format]){
                
                this.selectedEmployeeInfo.basicSchedule[key][day.format].forEach(time=>{ // Calculate minutes per day
                  if(time.type == 'work'){
                    let continiousType = time.continiousType == "even" ? 'even' : time.continiousType == "odd" ? 'odd' : 'all'; 
                    let minutes = Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes"))
                    basisScheduleMinutes[day.format][continiousType] = basisScheduleMinutes[day.format][continiousType] + minutes;
                    basisScheduleMinutes[day.format].schedule.push(time);
                  }
                })
              }
            })
          })
        }

        

        // Huidige jaar bekijken
        let weekSchedules = new Object();

        // console.log("basisScheduleMinutes: ", this.$store.state.activeCompany.id, this.selectedYear, this.selectedEmployeeInfo.id)

        // Load custom schedules of all branches
        db.collection("schedules")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("year", "==", this.selectedYear)
        .where("employeeId", "==", this.selectedEmployeeInfo.id)
        .get()
        .then((snap) => {
          // console.log("snapsize: ", snap.size)
          snap.forEach((doc) => {
            let schedule = doc.data();
            schedule.id = doc.id
            if(!weekSchedules[schedule.week]){
              weekSchedules[schedule.week] = new Object();
            }
            weekSchedules[schedule.week][schedule.branchId] = schedule;
          })
        })
        .then(()=>{

          console.log("weekSchedules: ", weekSchedules)

          let counter = 0;
          for (let i = 0; i < 52; i++) { // Check all weeks of the year
            let startOfWeek = moment().year(this.selectedYear).isoWeek(i+1).startOf('week').format("YYYY-MM-DD");
            let endOfWeek = moment().year(this.selectedYear).isoWeek(i+1).endOf('week').format("YYYY-MM-DD");

            let weekNumber = moment(startOfWeek).isoWeek();
            let continiousType =  weekNumber % 2 == 0 ? 'even' : 'odd';  
            let data = {
              week: i + 1,
              type: 'basicSchedule',
              minutes: 0,
              days: new Object(),
              buildUpLeave: 0,
              usedLeave: 0
            };
            if(this.selectedEmployeeInfo.employeeContractStart){
              if((this.selectedEmployeeInfo.employeeContractStart <= startOfWeek || this.selectedEmployeeInfo.employeeContractStart <= endOfWeek) && (!this.selectedEmployeeInfo.employeeContractEnd || (this.selectedEmployeeInfo.employeeContractEnd > startOfWeek || this.selectedEmployeeInfo.employeeContractEnd > endOfWeek))){
                if(weekSchedules[i + 1]){
                  data.minutes = 0; 
                  Object.entries(weekSchedules[i + 1]).forEach(([key]) => { // Check in all Branches
                    days.forEach(day=>{ // Check all Days
                      if(weekSchedules[i + 1][key][day.format]){
                        data.days[day.format] = {
                          schedule: new Array(),
                          workedMinutes: 0,
                          buildUpLeave: 0,
                          usedLeave: 0,
                        };
                        weekSchedules[i + 1][key][day.format].forEach(time=>{ // Calculate minutes per day
                          if(time.type == 'work'){
                            data.days[day.format].workedMinutes.push(time)
                            let afterContractStart = this.selectedEmployeeInfo.employeeContractStart <= moment().year(this.selectedYear).isoWeek(i+1).isoWeekday(day.number).format("YYYY-MM-DD");
                            let beforeContractEnd = !this.selectedEmployeeInfo.employeeContractEnd || this.selectedEmployeeInfo.employeeContractEnd > moment().year(this.selectedYear).isoWeek(i+1).isoWeekday(day.number).format("YYYY-MM-DD") ? true : false;
                            let minutes = afterContractStart && beforeContractEnd ? Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes")) : 0;
                            data.minutes = data.minutes + minutes;
                            data.days[day.format].workedMinutes = minutes;
                          }
                        })
                      }
                    })
                  })
                }
                else{
                  days.forEach(day=>{ // Check all Days
                    data.days[day.format] = {
                      schedule: new Array(),
                      workedMinutes: 0,
                      buildUpLeave: 0,
                      usedLeave: 0,
                    };
                    let afterContractStart = this.selectedEmployeeInfo.employeeContractStart <= moment().year(this.selectedYear).isoWeek(i+1).isoWeekday(day.number).format("YYYY-MM-DD");
                    let beforeContractEnd = !this.selectedEmployeeInfo.employeeContractEnd || this.selectedEmployeeInfo.employeeContractEnd > moment().year(this.selectedYear).isoWeek(i+1).isoWeekday(day.number).format("YYYY-MM-DD") ? true : false;
                    if(basisScheduleMinutes[day.format] && afterContractStart && beforeContractEnd ){
                      data.minutes = data.minutes + basisScheduleMinutes[day.format].all + basisScheduleMinutes[day.format][continiousType];
                      data.days[day.format].workedMinutes = basisScheduleMinutes[day.format].all + basisScheduleMinutes[day.format][continiousType];
                      basisScheduleMinutes[day.format].schedule.forEach(time=>{
                        if(time.continiousType == 'all' ||time.continiousType == continiousType ){
                          data.days[day.format].schedule.push(time)
                        }
                      })
                    }
                  }) 
                }
              }
              else{
                data.type = "notEmployed"; 
              }
            }
            else{
              data.type = "notEmployed"; 
            }
            console.log("data: ", data)
            yearWorkedHourSchedule.push(data)
            console.log("data counter: ", counter)
            counter++
            console.log("data counter: ", counter)
            console.log("yearWorkedHourSchedule: ", yearWorkedHourSchedule)
            if(counter == 52){
              resolve()
            }
            
          }
        })
      })
     

      let activeAgreements = new Array();
      // 2. Get CAO and calculate free hours 
      if(this.selectedEmployeeInfo.cao){
        await db.collection("cao") // Get employee CAO
        .doc(this.selectedEmployeeInfo.cao)
        .get()
        .then(doc =>{
          if(doc.exists){
            cao = doc.data();
            cao.id = doc.id;
          }
        })
      
        await new Promise((resolve)=>{
          cao.agreements.forEach((agreement)=>{
            if(agreement.startDate < `${this.selectedYear}-12-31`){
              activeAgreements.push(agreement)
            }
          })

          activeAgreements = activeAgreements.sort(function(a,b){
            return b.startDate - a.startDate;
          });
          
          if(this.selectedEmployeeInfo.cao){
            let counter = 0;
            yearWorkedHourSchedule.forEach((week)=>{  // Check each week if CAO is changed
              days.forEach(day=>{ // Check all Days
                let activeAgreementsDay = new Array();
                // Get current Agreement
                
                activeAgreements.forEach((agreement)=>{
                  if(agreement.startDate < moment().year(this.selectedYear).isoWeek(week.week).isoWeekday(day.number).format("YYYY-MM-DD")){
                    activeAgreementsDay.push(agreement)
                  }
                })

                if(activeAgreementsDay.length > 0){
                  activeAgreementsDay = activeAgreementsDay.sort(function(a,b){
                    return b.startDate - a.startDate;
                  });
                  let freeMinutesPerMinute = (activeAgreementsDay[0].freeDays / 52) / activeAgreementsDay[0].fullTimeHours * (activeAgreementsDay[0].fullTimeHours / 5);
                  // console.log("freeMinutesPerMinute:",freeMinutesPerMinute)
                  if(week.days[day.format]){
                    week.buildUpLeave = week.buildUpLeave + (freeMinutesPerMinute * week.days[day.format].workedMinutes);
                    week.days[day.format].buildUpLeave = (freeMinutesPerMinute * week.days[day.format].workedMinutes);
                    months.forEach(month=>{ // Set start and enddates for months this year
                      if(moment().year(this.selectedYear).isoWeek(week.week).isoWeekday(day.number).format("YYYY-MM-DD") >= month.start &&  moment().year(this.selectedYear).isoWeek(week.week).isoWeekday(day.number).format("YYYY-MM-DD") <= month.end){
                        // console.log("Month vrije dagen:",month.name, day.format, (freeMinutesPerMinute * week.days[day.format].workedMinutes)/60, (freeMinutesPerMinute * week.days[day.format].workedMinutes))
                        month.leaveBuildUp = month.leaveBuildUp + (freeMinutesPerMinute * week.days[day.format].workedMinutes);
                        month.worked = month.worked + week.days[day.format].workedMinutes;
                      }
                    })
                  }
                }
              })
              counter++
              if(counter == yearWorkedHourSchedule.length){
                resolve()
              }
            })
          }
          else{ // Get basic labor rules for free days
            resolve()
          }
        })
      }

      // 3. Get leave and sickness and extra hours
      await db.collection("schedulesExceptions")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("year", "==", this.selectedYear)
      .where("resourceId", "==", this.selectedEmployeeInfo.id)
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let item = doc.data();
          item.id = doc.id;
          item.minutes = 0;
          if(item.reason == 'sickness'){
            employeeSickness.push(item);
          }
          else{
            if(item.reason == 'leave' || (item.reason == 'exchangeService' && item.type == 'absence')){
              employeeLeave.push(item);
            }
            if(item.reason == 'extraHours' || item.reason == 'overtime25' || item.reason == 'overtime100' || (item.reason == 'exchangeService' && item.type == 'present')){
              employeeExtraHours.push(item);
            }
            
          }
          
        })
      })
      .catch((error)=>{
        console.error("Error getting schedulesExceptions: ", error)
      })

      await new Promise((resolve)=>{
        let counter = 0
        if(employeeLeave.length > 0){
          yearWorkedHourSchedule.forEach((week)=>{  // Check each week if CAO is changed
            if(Object.keys(week.days).length > 0){
              days.forEach(day=>{ // Check all Days
                let weekNumber =  moment().year(this.selectedYear).isoWeek(week.week);
                let continiousType =  weekNumber % 2 == 0 ? 'even' : 'odd';  
                employeeLeave.forEach(leaveItem=>{ // Check all Days
                  if( // Check if leave affects this day
                    moment().year(this.selectedYear).isoWeek(week.week).isoWeekday(day.number).format("YYYY-MM-DD") == leaveItem.startdate ||
                    moment().year(this.selectedYear).isoWeek(week.week).isoWeekday(day.number).format("YYYY-MM-DD") == leaveItem.enddate ||
                    (moment().year(this.selectedYear).isoWeek(week.week).isoWeekday(day.number).format("YYYY-MM-DD") > leaveItem.startdate && moment().year(this.selectedYear).isoWeek(week.week).isoWeekday(day.number).format("YYYY-MM-DD") < leaveItem.enddate )
                  ){
            
                    if(leaveItem.time.length == 0){
                      if(week.days && week.days[day.format]){
                        week.days[day.format].usedLeave = week.days[day.format].workedMinutes;
                        week.usedLeave = week.usedLeave + week.days[day.format].workedMinutes;
                        leaveItem.minutesRounded = Number((((leaveItem.minutes + week.days[day.format].workedMinutes)/0.833333)*0.833333).toFixed(0));
                        leaveItem.minutes = ((leaveItem.minutes + week.days[day.format].workedMinutes)/0.833333)*0.833333;
                        leaveItem.worked =week.days[day.format].workedMinutes
                      }
                    }
                    else{
                      let freeDay = false
                      this.$officialFreeDays[this.employeeExceptionsDate.year].forEach(freeDay=>{
                        if(`${this.employeeExceptionsDate.year}-${freeDay.date}` == moment().year(this.selectedYear).isoWeek(week.week).isoWeekday(day.number).format("YYYY-MM-DD")){
                          freeDay = true
                        }
                      })
                      if(!freeDay){
                        console.log("leaveItem.time: ", leaveItem.time)
                        leaveItem.time.forEach(timeItem=>{
                          console.log("timeItem: ", timeItem)
                          console.log("week.days: ", week.days)
                          console.log("day.format: ", day.format)
                          console.log("week.usedLeave: ", week.usedLeave)
                          week.days[day.format].usedLeave = Number(moment(timeItem.end, "HH:mm").diff(moment(timeItem.start, "HH:mm"), "minutes"));
                          week.usedLeave = week.usedLeave + Number(moment(timeItem.end, "HH:mm").diff(moment(timeItem.start, "HH:mm"), "minutes"));
                          // leaveItem.minutes = leaveItem.minutes + Number(moment(timeItem.end, "HH:mm").diff(moment(timeItem.start, "HH:mm"), "minutes"));
                          leaveItem.minutes = Number((((leaveItem.minutes + Number(moment(timeItem.end, "HH:mm").diff(moment(timeItem.start, "HH:mm"), "minutes")))/0.833333)*0.833333).toFixed(0));
                        })
                      }
                    }
                  }
                })
              })
            }
            counter++
            if(counter == yearWorkedHourSchedule.length){
              resolve()
            }
          })
        }
        else{
          resolve()
        }
      })


      this.employeeLeave.extraHours.time = 0;
      await new Promise((resolve)=>{
        let counter = 0
        if(employeeExtraHours.length > 0){

          employeeExtraHours.forEach(extraItem=>{ // Check all Days
            let minutes = 0;
            extraItem.time.forEach(timeItem=>{
              minutes = (Math.ceil((minutes + Number(moment(timeItem.end, "HH:mm").diff(moment(timeItem.start, "HH:mm"), "minutes")))/5)*5); // Rounded up to 5 minutes
            })
            this.employeeLeave.extraHours.time = this.employeeLeave.extraHours.time + minutes;

            let data = {
              start: extraItem.startdate,
              end: extraItem.enddate,
              future: extraItem.enddate > moment().format("YYYY-MM-DD") ? true : false,
              type: 'extraHours',
              overviewType: '',
              reason: extraItem.reason,
              description: extraItem.extra,
              time: minutes,
              passedOldException: extraItem.enddate >= moment().format("YYYY-MM-DD") ? false : true,
              docId: extraItem.id,
              docType: "schedulesExceptions"
            }
            this.employeeLeave.details.push(data)

            counter++
            if(counter == employeeExtraHours.length){
              resolve()
            }
          })
        }
        else{
          resolve()
        }
      })
      this.employeeLeave.extraHours.time = this.employeeLeave.extraHours.time

      // 4. Calculate Extra Leave Sickness Relation
      if(activeAgreements && activeAgreements[0] && activeAgreements[0].sicknessLeaveRelation){
        this.employeeLeave.extraLeaveNoSickness.period = activeAgreements[0].sicknessLeaveBuildupPeriod;
        this.employeeLeave.lessLeaveSickness.period = activeAgreements[0].sicknessLeaveSurrenderPeriod;
        let yearData = new Array();
        let halfYearData = new Array();
        let quarterData = new Array();
        let monthData = new Array();

        let yearDataSurrender = new Array();
        let halfYearDataSurrender = new Array();
        let quarterDataSurrender = new Array();
        let monthDataSurrender = new Array();


        let year = 0;
        let halfYear = 0;
        let quarter = 0

        let workedYear = 0;
        let workedHalfYear = 0;
        let workedQuarter = 0

        let buildUpTimeInMinutes =  activeAgreements[0].sicknessLeaveBuildupDays * (activeAgreements[0].fullTimeHours / 5) * 60 // 456     
        let periodWeek = 52;
        if(this.employeeLeave.extraLeaveNoSickness.period == 'halfYear'){periodWeek = 26}
        else if(this.employeeLeave.extraLeaveNoSickness.period == 'quarter'){periodWeek = 13}
        else if(this.employeeLeave.extraLeaveNoSickness.period == 'month'){periodWeek = 4.3333}
        let buildUpTimePerMinute = (buildUpTimeInMinutes / periodWeek) / (activeAgreements[0].fullTimeHours * 60) // 0,00769


        let monthSicknessCount = 0;
        let quarterSicknessCount = 0;
        let halfYearSicknessCount = 0;
        let yearSicknessCount = 0;

        let sicknessLeaveSurrenderYear = false;

        let buildUpYear = true;
        let buildUpHalfYear = true;
        let buildUpQuarter = true;
        let buildUpMonth = true;

        let leaveSurrenderLimit = activeAgreements[0].sicknessLeaveSurrenderEvent == "secondSickReport" ? 2 : 1;

        // console.log("leaveSurrenderLimit: ", leaveSurrenderLimit)

        months.forEach(month=>{ // Calculate Extra Leave per Month
          employeeSickness.forEach(sickness=>{
            if(sickness.startdate >= moment().year(this.selectedYear).month(month.number - 1).startOf('month').format("YYYY-MM-DD") && sickness.startdate <= moment().year(this.selectedYear).month(month.number - 1).endOf('month').format("YYYY-MM-DD")){
              monthSicknessCount++
              quarterSicknessCount++
              halfYearSicknessCount++
              yearSicknessCount++
            }
          })
          workedYear = workedYear + month.worked
          workedHalfYear = workedHalfYear + month.worked 
          workedQuarter = workedQuarter + month.worked

          year = year + (month.worked * buildUpTimePerMinute);
          halfYear = halfYear + (month.worked * buildUpTimePerMinute);
          quarter = quarter + (month.worked * buildUpTimePerMinute);

          buildUpMonth= activeAgreements[0].sicknessLeaveBuildupPeriod == 'month' ? monthSicknessCount == 0 ? true: false : true;

          if(buildUpMonth){
            monthData.push({
              start: moment().year(this.selectedYear).month(month.number - 1).startOf('month').format("YYYY-MM-DD"),
              end: moment().year(this.selectedYear).month(month.number - 1).endOf('month').format("YYYY-MM-DD"),
              type: "month",
              time: month.worked * buildUpTimePerMinute
            })
          }
          monthSicknessCount = 0;

          if(month.number == 3){
            buildUpQuarter = activeAgreements[0].sicknessLeaveBuildupPeriod == 'quarter' ? quarterSicknessCount == 0 ? true: false : true;
            if(buildUpQuarter){
              quarterData.push({
                start: moment().year(this.selectedYear).month(0).startOf('month').format("YYYY-MM-DD"),
                end: moment().year(this.selectedYear).month(2).endOf('month').format("YYYY-MM-DD"),
                type: "quarter",
                time: quarter
              })
            }
            quarter = 0;
            quarterSicknessCount = 0;
          }
          if(month.number == 6){
            buildUpYear = activeAgreements[0].sicknessLeaveBuildupPeriod == 'year' ? yearSicknessCount == 0 ? true: false : true;
            buildUpQuarter = activeAgreements[0].sicknessLeaveBuildupPeriod == 'quarter' ? quarterSicknessCount == 0 ? true: false : true;

            if(buildUpHalfYear){
              halfYearData.push({
                start: moment().year(this.selectedYear).month(0).startOf('month').format("YYYY-MM-DD"),
                end: moment().year(this.selectedYear).month(5).endOf('month').format("YYYY-MM-DD"),
                type: "halfYear",
                time: halfYear
              })
            }
            halfYear = 0;
            halfYearSicknessCount = 0; 

            if(buildUpQuarter){
              quarterData.push({
                start: moment().year(this.selectedYear).month(3).startOf('month').format("YYYY-MM-DD"),
                end: moment().year(this.selectedYear).month(5).endOf('month').format("YYYY-MM-DD"),
                type: "quarter",
                time: quarter
              })
            }
            quarter = 0;
            quarterSicknessCount = 0;
          }
          if(month.number == 9){
            quarterData.push({
              start: moment().year(this.selectedYear).month(6).startOf('month').format("YYYY-MM-DD"),
              end: moment().year(this.selectedYear).month(8).endOf('month').format("YYYY-MM-DD"),
              type: "quarter",
              time: quarter
            })
            quarter = 0;
            quarterSicknessCount = 0;
          }

          if(month.number == 12){
            buildUpYear = activeAgreements[0].sicknessLeaveBuildupPeriod == 'year' ? yearSicknessCount == 0 ? true: false : true;
            buildUpHalfYear = activeAgreements[0].sicknessLeaveBuildupPeriod == 'halfYear' ? halfYearSicknessCount == 0 ? true: false : true;
            buildUpQuarter = activeAgreements[0].sicknessLeaveBuildupPeriod == 'quarter' ? quarterSicknessCount == 0 ? true: false : true;


            console.log("yearSicknessCount: ", yearSicknessCount)
            

            sicknessLeaveSurrenderYear = activeAgreements[0].sicknessLeaveSurrenderPeriod == 'year' ? yearSicknessCount >= leaveSurrenderLimit ? true : false : true;

            // console.log("sicknessLeaveSurrenderYear: ", sicknessLeaveSurrenderYear)

            if(sicknessLeaveSurrenderYear){
              yearDataSurrender.push({
                start: moment().year(this.selectedYear).month(0).startOf('month').format("YYYY-MM-DD"),
                end: moment().year(this.selectedYear).month(11).endOf('month').format("YYYY-MM-DD"),
                type: "year",
                time: activeAgreements[0].sicknessLeaveSurrenderDays * ((workedYear / 52) / 5)
              })
            }

            
            if(buildUpYear){
              yearData.push({
                start: moment().year(this.selectedYear).month(0).startOf('month').format("YYYY-MM-DD"),
                end: moment().year(this.selectedYear).month(11).endOf('month').format("YYYY-MM-DD"),
                type: "year",
                time: year
              })
            }
            year = 0
            yearSicknessCount = 0;

            if(buildUpHalfYear){
              halfYearData.push({
                start: moment().year(this.selectedYear).month(6).startOf('month').format("YYYY-MM-DD"),
                end: moment().year(this.selectedYear).month(11).endOf('month').format("YYYY-MM-DD"),
                type: "halfYear",
                time: halfYear
              })
            }
            halfYear = 0;
            halfYearSicknessCount = 0; 

            if(buildUpQuarter){
              quarterData.push({
                start: moment().year(this.selectedYear).month(9).startOf('month').format("YYYY-MM-DD"),
                end: moment().year(this.selectedYear).month(11).endOf('month').format("YYYY-MM-DD"),
                type: "quarter",
                time: quarter
              })
            }
            quarter = 0;
            quarterSicknessCount = 0;
          }
        })

        if(this.employeeLeave.extraLeaveNoSickness.period == 'year'){this.employeeLeave.extraLeaveNoSickness.items = yearData}
        else if(this.employeeLeave.extraLeaveNoSickness.period == 'halfYear'){this.employeeLeave.extraLeaveNoSickness.items = halfYearData}
        else if(this.employeeLeave.extraLeaveNoSickness.period == 'quarter'){this.employeeLeave.extraLeaveNoSickness.items = quarterData}
        else{
          this.employeeLeave.extraLeaveNoSickness.items = monthData
        }
        this.employeeLeave.extraLeaveNoSickness.time = 0;
        this.employeeLeave.extraLeaveNoSickness.items.forEach(item=>{
          let data = {
            start: item.start,
            end: item.end,
            future: item.end > moment().format("YYYY-MM-DD") ? true : false,
            type: 'extraLeaveNoSickness',
            overviewType: '',
            description: 'leaveBuildUpMonth',
            time: item.time // Rounded up to 5 minutes
          }
          this.employeeLeave.extraLeaveNoSickness.time = this.employeeLeave.extraLeaveNoSickness.time + (Math.ceil(item.time/5)*5)
          this.employeeLeave.details.push(data)
        })

        if(this.employeeLeave.lessLeaveSickness.period == 'year'){this.employeeLeave.lessLeaveSickness.items = yearDataSurrender}
        else if(this.employeeLeave.lessLeaveSickness.period == 'halfYear'){this.employeeLeave.lessLeaveSickness.items = halfYearDataSurrender}
        else if(this.employeeLeave.lessLeaveSickness.period == 'quarter'){this.employeeLeave.lessLeaveSickness.items = quarterDataSurrender}
        else{
          this.employeeLeave.lessLeaveSickness.items = monthDataSurrender
        }
        this.employeeLeave.lessLeaveSickness.time = 0;
        this.employeeLeave.lessLeaveSickness.items.forEach(item=>{

          let data = {
            start: item.start,
            end: item.end,
            future: item.end > moment().format("YYYY-MM-DD") ? true : false,
            type: 'lessLeaveSickness',
            overviewType: '',
            description: 'lessLeaveSicknessMonth',
            time: item.time // Rounded up to 5 minutes
          }
          this.employeeLeave.lessLeaveSickness.time = this.employeeLeave.lessLeaveSickness.time + item.time
          this.employeeLeave.details.push(data)
        })
      }



      // 4. Calculate Extra Leave Age Relation


      let freeMinutesForAge = 0;
      let ageEmployee = moment(`${this.selectedYear}-12-31`, "YYYY-MM-DD").diff(this.selectedEmployeeInfo.birthday, 'years');
      if(cao && cao.agreements){
        await new Promise((resolve)=>{
          cao.agreements.forEach((agreement)=>{
            if(agreement.startDate < `${this.selectedYear}-12-31`){
              activeAgreements.push(agreement)
            }
          })

          activeAgreements = activeAgreements.sort(function(a,b){
            return b.startDate - a.startDate;
          });
          
          if(this.selectedEmployeeInfo.cao && ageEmployee){
            let counter = 0;
            yearWorkedHourSchedule.forEach((week)=>{  // Check each week if CAO is changed
              days.forEach(day=>{ // Check all Days
                let activeAgreementsDay = new Array();
                // Get current Agreement
                
                activeAgreements.forEach((agreement)=>{
                  if(agreement.startDate < moment().year(this.selectedYear).isoWeek(week.week).isoWeekday(day.number).format("YYYY-MM-DD")){
                    activeAgreementsDay.push(agreement)
                  }
                })


                if(activeAgreementsDay.length > 0){
                  activeAgreementsDay = activeAgreementsDay.sort(function(a,b){
                    return b.startDate - a.startDate;
                  });

                  if(activeAgreementsDay[0] && activeAgreementsDay[0].ageRelation){

                    let fullTimeMinutesWork = activeAgreementsDay[0].fullTimeHours * 52 * 60;  // Fulltime working minutes per year
                    // console.log("fullTimeMinutesWork: ", fullTimeMinutesWork)
                    activeAgreements[0].extraFreeDaysAge.forEach(leave=>{
                      if(ageEmployee > leave.startAge && ageEmployee <= leave.endAge && week.days[day.format]){
                        // Calculate free minutes per minute work
                        let freeMinutesPerMinute = (((leave.days * (activeAgreements[0].fullTimeHours / 5))*60)) /  fullTimeMinutesWork
                        freeMinutesForAge = freeMinutesForAge + (freeMinutesPerMinute * week.days[day.format].workedMinutes)
                      }
                    })
                  }
                }
              })
              counter++
              if(counter == yearWorkedHourSchedule.length){
                resolve()
              }
            })
          }
          else{ // Get basic labor rules for free days
            resolve()
          }
        })
      }
      this.employeeLeave.extraLeaveAgeOrContract.time = freeMinutesForAge;
     
      let data = {
        start:`${this.selectedYear}-01-01`,
        end: `${this.selectedYear}-12-31`,
        future: false,
        type: 'year',
        overviewType: 'extraLeaveAge',
        description: '',
        // time: month.leaveBuildUp
        time: freeMinutesForAge
      }
      if(freeMinutesForAge && freeMinutesForAge > 4){ // Only push row if there is extra leave
        this.employeeLeave.details.push(data)
      }
      
      


      // 4. Calculate Extra Leave Employment Relation

      if(activeAgreements[0] && activeAgreements[0].employmentRelation){

      }


      // 5. Calculate Corrections

      let leaveCorrection = 0;
      
      await new Promise((resolve)=>{
        if(this.employeeLeaveCorrection && this.employeeLeaveCorrection.length > 0){
          let counter = 0;
          this.employeeLeaveCorrection.forEach(correction=>{
            console.log("correction: ", correction)
            let data = {
              start: moment(correction.date.seconds,'X').format("DD-MM-YYYY"),
              end: null,
              future: moment(correction.date.seconds,'X').format("DD-MM-YYYY") > moment().format("YYYY-MM-DD") ? true : false,
              type: 'correction',
              overviewType: correction.type,
              description: correction.description,
              time: correction.time,
              docId: correction.id,
              docType: "leaveCorrections"
            }
            if(correction.type == "carriedOverLastYear"){
              this.employeeLeave.lastYearPickedUp.time = this.employeeLeave.lastYearPickedUp.time + correction.time
            }
            else{
              if(correction.type == "payoutHours" || correction.type == "correctionSubtractHours"){
                leaveCorrection = leaveCorrection - correction.time
              }
              else{
                leaveCorrection = leaveCorrection + correction.time
              }
            }
            
            this.employeeLeave.details.push(data)
            counter++
            if(counter == this.employeeLeaveCorrection.length){
              resolve()
            }
          })
        }
        else{
          resolve()
        }
      })

      // console.log("leaveCorrection: ", leaveCorrection)

      let buildUpLeaveExtraEmployment = 0;
      let buildUpLeaveExtraAge = 0;
  
      let buildUpLeaveMinutes = 0;
      let usedLeaveMinutes = 0;
      let creditMinutes = 0;
      yearWorkedHourSchedule.forEach((week)=>{
        buildUpLeaveMinutes = buildUpLeaveMinutes + week.buildUpLeave;
        usedLeaveMinutes = usedLeaveMinutes + week.usedLeave;
      });
      
      // if(this.employeeLeave.extraLeaveNoSickness.items && this.employeeLeave.extraLeaveNoSickness.items.length > 0){
      //   this.employeeLeave.extraLeaveNoSickness.items.forEach((item)=>{
      //     buildUpLeaveMinutes = buildUpLeaveMinutes + item.time;
      //   });
      // }
      

      creditMinutes = buildUpLeaveMinutes - usedLeaveMinutes + leaveCorrection + this.employeeLeave.extraLeaveNoSickness.time;


      this.employeeLeave.correction.hours = Math.floor( leaveCorrection / 60)
      this.employeeLeave.correction.minutes = leaveCorrection - (this.employeeLeave.correction.hours * 60)
      this.employeeLeave.correction.time = leaveCorrection;

     // console.log("this.employeeLeave.correction.hours: ", this.employeeLeave.correction.hours)

      // Total collected time
      buildUpLeaveMinutes = buildUpLeaveMinutes
      this.employeeLeave.collected.hours = Math.floor( buildUpLeaveMinutes / 60)
      this.employeeLeave.collected.minutes = (((buildUpLeaveMinutes - (this.employeeLeave.collected.hours * 60)).toFixed(0))/5).toFixed(0) * 5;
      this.employeeLeave.collected.time = buildUpLeaveMinutes;

      //  Total used time
      usedLeaveMinutes = usedLeaveMinutes
      this.employeeLeave.used.hours = Math.floor(usedLeaveMinutes / 60);
      this.employeeLeave.used.minutes = (((usedLeaveMinutes - (this.employeeLeave.used.hours * 60)).toFixed(0))/5).toFixed(0) * 5;
      this.employeeLeave.used.time = usedLeaveMinutes;

      //Sum of left free time
      creditMinutes = creditMinutes;
      this.employeeLeave.credit.hours = Math.floor(creditMinutes / 60);
      this.employeeLeave.credit.minutes = (((creditMinutes - (this.employeeLeave.credit.hours * 60)).toFixed(0))/5).toFixed(0) * 5;
      this.employeeLeave.credit.time = creditMinutes;

      months.forEach(month=>{
        let data = {
          start: month.start,
          end: month.end,
          future: month.end > moment().format("YYYY-MM-DD") ? true : false,
          type: 'month',
          overviewType: 'leaveBuildUp',
          description: 'leaveBuildUpMonth',
          // time: month.leaveBuildUp
          time: month.leaveBuildUp
        }
        this.employeeLeave.details.push(data)
      });

      // console.log("months: ", months)

      employeeLeave.forEach(leave=>{
        let data = {
          start: leave.startdate,
          end: leave.enddate,
          future: leave.enddate > moment().format("YYYY-MM-DD") ? true : false,
          type: 'leave',
          overviewType: 'leaveDebit',
          reason: leave.reason,
          docId: leave.id,
          docType: "schedulesExceptions",
          description: leave.extra,
          // time: leave.minutes
          time: leave.minutes
        }
        this.employeeLeave.details.push(data)
      })
  

      this.employeeLeave.details = this.employeeLeave.details.sort(function(a,b){
        return b.start < a.start ? 1 : -1;
      });

  
      // Calculeren hoeveel er gewerkt is tot en met vandaag
      // Hoeveel basisrooster
      // Alle weken berekenen (hoeveel hebben er een aangepast rooster en hoeveel basis)

      // Uren verdelen in maanden


      // Opbouw uren berekenen
      // Opbouw extra vakantiedagen berekenen
      // Uren weergeven per maand
      // Aangeven per regel of het al is geweest 

      // Totaal opgebouwde uren
      // Totaal extra gewerkte uren
      // Opgenomen verlof uren

      let total = this.employeeLeave.extraLeaveNoSickness.time + this.employeeLeave.extraLeaveAgeOrContract.time + this.employeeLeave.collected.time + this.employeeLeave.lastYearPickedUp.time + this.employeeLeave.extraHours.time - this.employeeLeave.used.time - this.employeeLeave.lessLeaveSickness.time + this.employeeLeave.correction.time;

      this.employeeLeave.total.time = total;
      this.employeeLeave.total.hours = Math.floor(total / 60);
      this.employeeLeave.total.minutes = (((total - (this.employeeLeave.total.hours  * 60)).toFixed(0))/5).toFixed(0) * 5;

      this.yearWorkedHourSchedule = yearWorkedHourSchedule

      this.employeeLeaveLoading = false;
    },

    getCorrectionType(type){
      let correctionType = this.leaveCorrectionTypes.find(item => {return item.id == type})
      if(correctionType){
        return correctionType.name
      }
      else{
        return "correction"
      }
    },   
    
    resetEmployeeWeekSchedule(){
      this.employeeWeekSchedule = {
        loading: true,
        firstDay: null,
        lastDay: null,
        minutes: null,
        employeeId: null,
        minutesAverage: null,
        weekNumber: null,
        days: new Array(),
        schedule: {
          Sun: new Array(),
          Mon: new Array(),
          Tue: new Array(),
          Wed: new Array(),
          Thu: new Array(),
          Fri: new Array(),
          Sat: new Array() 
        }
      };
    },

    goToEmployeeOverview(){
      this.removeEmployeeDialog.show = false;
      bus.$emit("goToEmployeeOverview");
      this.employeeDataLoading = true;
      this.resetEmployeeWeekSchedule();
      if(this.updateData){
        console.log("Emit updateData")
        this.$emit("updateData");
      }
      this.updateData = false;
      this.tab = "basic";
    },

   

    
    
    async loadEmployeeData(employeeId){
      this.employeeDataLoading = true;
      await db.collection("employees")
      .doc(employeeId)
      .get()
      .then(doc =>{
        if(doc.exists){
          this.selectedEmployeeInfo = doc.data();
          this.selectedEmployeeInfo.id = employeeId;
          this.selectedEmployeeInfo.emails = this.selectedEmployeeInfo.emails ? this.selectedEmployeeInfo.emails : new Array();
          if (this.selectedEmployeeInfo.employeeContractEnd == "noEndDate") {
            this.selectedEmployeeInfo.employeeContractEnd = "";
          }
          this.selectedEmployeeInfoOriginal = _.cloneDeep(this.selectedEmployeeInfo);
          if(doc.data().branches && doc.data().branches.length > 0){
            this.selectedBranch = doc.data().branches[0]
          }
        }
      })
      .catch((error)=>{
        console.error("Error getting employee data: ", error)
      })
      this.checkAddress();
      this.checkUserAccount();
      if(this.selectedEmployeeInfo.cao){
        this.calculateEmployeeLeave();
      }
      
      this.employeeDataLoading = false;
    },

    minutesToHourFormat(minutes, rounding){
      let roundingNumber = rounding ? rounding : 1;
      let prefix = Math.sign(minutes) == -1 ? "-": "";
      let hours = Math.floor(Math.abs(minutes)/60);
      // let hours = Math.floor(minutes/60);
      let minutesLeft = (((Math.abs(minutes) - (hours * 60)).toFixed(0))/roundingNumber).toFixed(0)*roundingNumber
      return minutesLeft > 9 ? `${prefix}${hours}:${minutesLeft}` : `${prefix}${hours}:0${minutesLeft}`; // Add leading zero if  minutes is less than two characters
    },
    
    async removeEmployee(){
      this.removeEmployeeDialog.loading = true;
      let docName = "id";
      if (this.source == "calendar") {
        docName = "docId";
      };

      // 1. Set endDate of no endDate is specified
      if(this.selectedEmployeeInfo.employeeContractEnd == 'noEndDate' || this.selectedEmployeeInfo.employeeContractEnd == ''){
        this.selectedEmployeeInfo.employeeContractEnd = moment().format("YYYY-MM-DD");
      }

      // 2. Update database
      await db.collection("employees")
      .doc(this.selectedEmployeeInfo[docName])
      .set(
        {
          deleted: {
            deleted: true,
            timestamp: new Date()
          },
          employeeContractEnd: this.selectedEmployeeInfo.employeeContractEnd
        },
        { merge: true }
      )

      // 3. Disable User Account 

      if(this.selectedEmployeeInfo.uid){
        let idToken = await firebase.auth().currentUser.getIdToken(true);
        await axios.post(`${this.$store.state.restApi.url}/auth`, {
          token: idToken,
          type: "disableUser",
          uid: this.selectedEmployeeInfo.uid,
          employeeId: this.selectedEmployeeInfo[docName],
        })
        .catch((error)=>{
          console.error("Error disabling User Account", error);
        })
      }
      
      // 4. Load employeeData again and go to overview
      
      this.$emit("updateData");
      setTimeout(()=>{
        this.removeEmployeeDialog.show = false;
        this.removeEmployeeDialog.loading = false;
        this.goToEmployeeOverview();
      },1000)
    },

    async restoreEmployee(){
      this.restoreEmployeeDialog.loading = true;
      let docName = "id";
      if (this.source == "calendar") {
        docName = "docId";
      };

      // 1. Update database
      await db.collection("employees")
      .doc(this.selectedEmployeeInfo[docName])
      .set(
        {
          deleted: {
            deleted: false,
            timestamp: new Date()
          },
        },
        { merge: true }
      )

      // 2. Enable User Account 

      if(this.selectedEmployeeInfo.uid){
        let idToken = await firebase.auth().currentUser.getIdToken(true);
        await axios.post(`${this.$store.state.restApi.url}/auth`, {
          token: idToken,
          type: "enableUser",
          uid: this.selectedEmployeeInfo.uid,
          employeeId: this.selectedEmployeeInfo[docName],
        })
        .catch((error)=>{
          console.error("Error enabling User Account", error);
        })
      }
      
      // 3. Load employeeData again and go to overview
      
      this.$emit("updateData");

      setTimeout(()=>{
        this.restoreEmployeeDialog.show = false;
        this.restoreEmployeeDialog.loading = false;
        this.goToEmployeeOverview();
      },1000)

    },

    /**
    * Get nam of the selected branch
    */
    getBranchName() {
      let branchData = this.$store.state.companyBranches.find(storeBranch => {return storeBranch.id == this.selectedBranch})
      if(branchData != undefined){
        return branchData.name;
      }
      else{
        return "";
      }
    },

    /**
    * Get Employee schedule
    * @type {string} value 'next' of 'previous' 
    */
    async getEmployeeWeekScheduleData(type){
      this.employeeWeekSchedule.loading = true;
      this.employeeWeekSchedule.docId = null;
      this.employeeWeekSchedule.schedule = {Sun: new Array(),Mon: new Array(),Tue: new Array(),Wed: new Array(),Thu: new Array(),Fri: new Array(),Sat: new Array()}; // Reset schedule

      // Reset Schedule data for this week for other branches
      this.employeeWeekScheduleOtherBranches.days = new Array();
      this.employeeWeekScheduleOtherBranches.exist = false;
      this.employeeWeekScheduleOtherBranches.schedule = {Sun: new Array(),Mon: new Array(),Tue: new Array(),Wed: new Array(),Thu: new Array(),Fri: new Array(),Sat: new Array()}; // Reset schedule
      this.employeeWeekScheduleOtherBranches.schedules = new Array()

      if(type){ // Type is set if next of previous buttons are ured (no first loading)
        if(type == 'custom'){ 
          this.employeeWeekSchedule.weekNumber = moment(this.employeeWeekScheduleDate).isoWeek()
          this.employeeWeekSchedule.year = moment(this.employeeWeekScheduleDate).year();  
        }
        if(type == 'reload'){ 
          // Change noting to currcent schedule
        }
        if(type == 'next'){    
          if(this.employeeWeekSchedule.weekNumber <= moment(this.employeeWeekSchedule.year, "YYYY").weeksInYear()){
            this.employeeWeekSchedule.weekNumber++
          }else{
            this.employeeWeekSchedule.weekNumber = 1;
            this.employeeWeekSchedule.year++;
          }
        }
        if(type == 'previous'){    
          if(this.employeeWeekSchedule.weekNumber == 1){
            this.employeeWeekSchedule.year--;
            this.employeeWeekSchedule.weekNumber = moment(this.employeeWeekSchedule.year, "YYYY").weeksInYear();
          }
          else{
            this.employeeWeekSchedule.weekNumber--;
          }
        }
      }
      else{ // First load with current week
        this.getEmployeePresence();
        this.getEmployeeExeptions();
        this.employeeWeekSchedule.weekNumber = moment().isoWeek();
        this.employeeWeekSchedule.year = moment().year(); 

      }

      this.employeeWeekScheduleOtherBranches.weekNumber = this.employeeWeekSchedule.weekNumber;
      this.employeeWeekScheduleOtherBranches.year = this.employeeWeekSchedule.year;

      this.employeeWeekScheduleDate = moment().year(this.employeeWeekSchedule.year).isoWeek(this.employeeWeekSchedule.weekNumber).weekday(0).format("YYYY-MM-DD")
      this.employeeWeekScheduleDateDialog = false;

      let evenWeek = this.employeeWeekSchedule.weekNumber % 2 == 0 ? true : false;     

      let days = [
        {format:"Sun", index: 6},
        {format:"Mon", index: 0},
        {format:"Tue", index: 1},
        {format:"Wed", index: 2},
        {format:"Thu", index: 3},
        {format:"Fri", index: 4},
        {format:"Sat", index: 5}
      ];
      this.employeeWeekSchedule.days = new Array();
      days.forEach((day, index)=>{
        this.employeeWeekSchedule.days.push(
          {
            date: moment().year(this.employeeWeekSchedule.year).isoWeek(this.employeeWeekSchedule.weekNumber).day(index + 1).format('YYYY-MM-DD'),
            minutes: 0,
            type: 'continious'
          }
        )
        this.employeeWeekScheduleOtherBranches.days.push(
          {
            exist: false,
            type: 'continious',
            branches: new Array()
          }
        )
      })

      // Set Basic schedule Data based on current data

      let minutesOdd = 0;
      let minutesEven = 0;
      let minutesAll = 0;

      days.forEach(day=>{
        if(this.selectedEmployeeInfo.basicSchedule[this.selectedBranch] && this.selectedEmployeeInfo.basicSchedule[this.selectedBranch][day.format] && this.selectedEmployeeInfo.basicSchedule[this.selectedBranch][day.format].length > 0){
          this.selectedEmployeeInfo.basicSchedule[this.selectedBranch][day.format].forEach(time=>{

            if(time.type != 'pause'){
              if(time.continiousType == 'all'){
                minutesAll = minutesAll + Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes"));
                this.employeeWeekSchedule.days[day.index].minutes = this.employeeWeekSchedule.days[day.index].minutes + Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes"));
              }
              else if(time.continiousType == 'odd'){
                minutesOdd = minutesOdd + Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes"));
                this.employeeWeekSchedule.days[day.index].type = 'alternately';
                if(!evenWeek){
                  this.employeeWeekSchedule.days[day.index].minutes = this.employeeWeekSchedule.days[day.index].minutes + Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes"));
                }
              }
              else if(time.continiousType == 'even'){
                minutesEven = minutesEven + Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes"));
                this.employeeWeekSchedule.days[day.index].type = 'alternately';
                if(evenWeek){
                  this.employeeWeekSchedule.days[day.index].minutes = this.employeeWeekSchedule.days[day.index].minutes + Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes"));
                }
              }
            }
            if(time.continiousType == 'all'){
              this.employeeWeekSchedule.schedule[day.format].push(time);
            }
            else if(time.continiousType == 'odd' && !evenWeek){
              this.employeeWeekSchedule.schedule[day.format].push(time);
              this.employeeWeekSchedule.days[day.index].type = 'alternately';
            }
            else if(time.continiousType == 'even' && evenWeek){
              this.employeeWeekSchedule.schedule[day.format].push(time);
              this.employeeWeekSchedule.days[day.index].type = 'alternately';
            }
          })
        }
      })
      minutesOdd = minutesAll + minutesOdd;
      minutesEven = minutesAll + minutesEven;
      this.employeeWeekSchedule.minutes = evenWeek ? minutesEven : minutesOdd;
      this.employeeWeekSchedule.minutesAverage = (minutesEven + minutesOdd) / 2;
      this.employeeWeekSchedule.employeeId = this.selectedEmployeeInfo.id;
      this.employeeWeekSchedule.type = null;
      this.employeeWeekSchedule.docId = null;
      this.employeeWeekSchedule.collectionType = 'basicSchedule';


      // Check if there are alternate schedules in the database
      await new Promise((resolve)=>{
        db.collection("schedules")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("year", "==", this.employeeWeekSchedule.year)
        .where("week", "==", this.employeeWeekSchedule.weekNumber)
        .where("employeeId", "==", this.selectedEmployeeInfo.id)
        .get()
        .then((snap) => {
          if (snap.size == 0) {         
            resolve()
          }
          else{
            snap.forEach((doc) => {
              if(doc.data().branchId == this.selectedBranch){ // this database schedule is for the selected branch    
                this.employeeWeekSchedule.schedule = doc.data().schedule;
                this.employeeWeekSchedule.minutes  = doc.data().minutes;
                this.employeeWeekSchedule.minutesAverage = doc.data().minutesAverage;
                this.employeeWeekSchedule.type =  doc.data().type; 
                this.employeeWeekSchedule.docId = doc.id;
                this.employeeWeekSchedule.employeeId = doc.data().employeeId;
                this.employeeWeekSchedule.collectionType = 'scheduleCollectionDocument';

                days.forEach(day=>{
                  if(this.employeeWeekSchedule.schedule[day.format] && this.employeeWeekSchedule.schedule[day.format].length > 0){
                    this.employeeWeekSchedule.schedule[day.format].forEach(time=>{
                      if(time.type != 'pause'){
                        if(time.continiousType == 'all'){
                          this.employeeWeekSchedule.days[day.index].minutes = this.employeeWeekSchedule.days[day.index].minutes + Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes"));
                        }
                        else if(time.continiousType == 'odd'){
                          this.employeeWeekSchedule.days[day.index].type = 'alternately';
                          if(!evenWeek){
                            this.employeeWeekSchedule.days[day.index].minutes = this.employeeWeekSchedule.days[day.index].minutes + Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes"));
                          }
                        }
                        else if(time.continiousType == 'even'){
                          this.employeeWeekSchedule.days[day.index].type = 'alternately';
                          if(evenWeek){
                            this.employeeWeekSchedule.days[day.index].minutes = this.employeeWeekSchedule.days[day.index].minutes + Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes"));
                          }
                        }
                      }
                    })
                  }
                })
              }
              else{  // this database schedule is for the a different branch    
                this.employeeWeekScheduleOtherBranches.schedules.push({
                  branchId: doc.data().branchId,
                  docId: doc.id,
                  type: doc.data().type,
                  schedule:  doc.data().schedule,
                  week: this.employeeWeekSchedule.weekNumber,
                  year: this.employeeWeekSchedule.year
                })
              }
            
            })
            resolve()              
          }
        })
      })


      // Check if there are any basis schedules or database schedules for other branches
      let branchesSchedulesToCheck = new Array();
      let weekStartDay = moment().year(this.employeeWeekSchedule.year).isoWeek(this.employeeWeekSchedule.weekNumber).day(1).format('YYYY-MM-DD');
      let weekEndDay = moment().year(this.employeeWeekSchedule.year).isoWeek(this.employeeWeekSchedule.weekNumber).day(7).format('YYYY-MM-DD');

     
      if(Object.keys(this.selectedEmployeeInfo.basicSchedule).length > 0){
        for (const [key] of Object.entries(this.selectedEmployeeInfo.basicSchedule)) {
          if(key != this.selectedBranch){
            
            let basicScheduleStartDate = this.selectedEmployeeInfo.basicSchedule[key].basicScheduleLastChanged ? moment.unix(this.selectedEmployeeInfo.basicSchedule[key].basicScheduleLastChanged.seconds).format("YYYY-MM-DD") : this.selectedEmployeeInfo.basicSchedule[key].startDate;

            if(basicScheduleStartDate <= weekEndDay && (!this.selectedEmployeeInfo.basicSchedule[key].endDate || this.selectedEmployeeInfo.basicSchedule[key].endDate >= weekStartDay)){
              branchesSchedulesToCheck.push({
                branchId: key,
                docId: null,
                type: 'basicSchedule',
                schedule:  this.selectedEmployeeInfo.basicSchedule[key],
                week: this.employeeWeekSchedule.weekNumber,
                year: this.employeeWeekSchedule.year,
                basicScheduleStartDate: basicScheduleStartDate,
                basicScheduleEndDate: this.selectedEmployeeInfo.basicSchedule[key].endDate
              })
            }
          } 
        }
      }

      // Check for scheduleExceptions

      // Employee extra presents (start)
      if(this.employeePresence && this.employeePresence.length > 0){ // If employeePresence has items


        this.employeePresence.forEach(presence=>{ // Loop through employeePresence
         
          days.forEach(day =>{  // Check by day Days
            let dateOfDay =  moment().year(this.employeeWeekSchedule.year).isoWeek(this.employeeWeekSchedule.weekNumber).day(day.index + 1).format('YYYY-MM-DD');
            if(dateOfDay >= presence.startdate && dateOfDay <= presence.enddate){
              presence.time.forEach(time=>{
                this.employeeWeekSchedule.schedule[day.format].push({
                  start: time.start,
                  end:  time.end,
                  reason: presence.reason,
                  extra: presence.extra,
                  type: 'extraPresence',
                  continiousType: 'all'
                })
              })
            }
          })
        })

        // Check for overlapping times and shorten the extra times if nessesary
        days.forEach(day =>{
          if(this.employeeWeekSchedule.schedule[day.format] && this.employeeWeekSchedule.schedule[day.format].length > 0){
            let originalSchedule = JSON.parse(JSON.stringify(this.employeeWeekSchedule.schedule[day.format]));

            originalSchedule.forEach(time=>{
              originalSchedule.forEach(timeCompare=>{     

                if(time.continiousType == timeCompare.continiousType ){  
                  if(time.type == "extraPresence"){
                    if(timeCompare.start == time.start && timeCompare.end == time.end){ // Check if timeblock is not the same
                      // Timeblocks are the same
                    }
                    else{ // Timeblocks are not the same
                      if(time.start >= timeCompare.start && time.end <= timeCompare.end ){ // Timeblock is smaller than compared timeblock (set times to nulll)
                        time.start = null;
                        time.end = null;
                      }

                      else if(time.start <= timeCompare.start && time.end <= timeCompare.end && time.end > timeCompare.start){ // Timeblock has overlap on start with timeblock (change end time)
                        time.end = timeCompare.start
                      }

                      else if(time.start >= timeCompare.start && time.end >= timeCompare.end && time.start < timeCompare.end){ // Timeblock has overlap on end with timeblock (change start time)
                        time.start = timeCompare.end
                      }
                    }
                  }
                }
              })
            })
            this.employeeWeekSchedule.schedule[day.format] = originalSchedule.filter(item=> { return item.start != null && item.end != null }) // Use only he valid time items
          }
        })
      }
      // Employee extra presents (end)


      // Employee absence (start)
     
      if(this.employeeExceptions && this.employeeExceptions.length > 0){
        let absenceItems = {
          Sun: new Array(),
          Mon: new Array(),
          Tue: new Array(),
          Wed: new Array(),
          Thu: new Array(),
          Fri: new Array(),
          Sat: new Array()
        };

        this.employeeExceptions.forEach(absence=>{ // Loop through employeePresence
         
         days.forEach(day =>{  // Check by day Days
           let dateOfDay =  moment().year(this.employeeWeekSchedule.year).isoWeek(this.employeeWeekSchedule.weekNumber).day(day.index + 1).format('YYYY-MM-DD');
           if(dateOfDay >= absence.startdate && dateOfDay <= absence.enddate){
            
            if(absence.time && absence.time.length > 0){
              absence.time.forEach(time=>{
                absenceItems[day.format].push({
                  start: time.start,
                  end:  time.end,
                  allDay: false,
                  reason: absence.reason,
                  extra: absence.extra,
                  type: 'absence',
                })
              })
            }
            else{
              absenceItems[day.format].push({
                allDay: true,
                reason: absence.reason,
                extra: absence.extra,
                type: 'absence',
              })
            }
            
           }
         })
       })

       // Check for overlapping times and shorten the extra times if nessesary
       days.forEach(day =>{
          if(this.employeeWeekSchedule.schedule[day.format] && this.employeeWeekSchedule.schedule[day.format].length > 0){
            let originalSchedule = JSON.parse(JSON.stringify(this.employeeWeekSchedule.schedule[day.format]));
            let absenceSchedule = JSON.parse(JSON.stringify(absenceItems[day.format]));

            originalSchedule.forEach(time=>{
              absenceSchedule.forEach(absenceTime=>{     

                if(time.type != 'extraPresence'){
                  if(absenceTime.allDay){ // All Timeblocks are invalid
  
                    time.start = null;
                    time.end = null;
                  }
                  else{
                    if(absenceTime.start == time.start && absenceTime.end == time.end){ // Check if timeblock is not the same
                      // Timeblocks are the same
                    }
                    else{ // Timeblocks are not the same
                      if(time.start >= absenceTime.start && time.end <= absenceTime.end ){ // Timeblock is smaller than compared timeblock (set times to nulll)
                        time.start = null;
                        time.end = null;
                      }

                      else if(time.start <= absenceTime.start && time.end <= absenceTime.end && time.end > absenceTime.start){ // Timeblock has overlap on start with timeblock (change end time)
                        time.end = absenceTime.start
                      }

                      else if(time.start >= absenceTime.start && time.end >= absenceTime.end && time.start < absenceTime.end){ // Timeblock has overlap on end with timeblock (change start time)
                        time.start = absenceTime.end
                      }
                    }
                  }
                  
                }
              })
            })
            this.employeeWeekSchedule.schedule[day.format] = originalSchedule.filter(item=> { return item.start != null && item.end != null }) // Use only he valid time items
          }
        })
      }
      // Employee absence (end)


      // Format the other branches schedules data if available
      if(this.employeeWeekScheduleOtherBranches.schedules.length > 0 || branchesSchedulesToCheck.length > 0){
        // Combine Basicschedule and database schedules of other branches
        let branchIds = new Set(this.employeeWeekScheduleOtherBranches.schedules.map(d => d.branchId));
        let schedulesToCheck = [...this.employeeWeekScheduleOtherBranches.schedules, ...branchesSchedulesToCheck.filter(schedule => !branchIds.has(schedule.branchId))];


        schedulesToCheck.forEach((schedule)=>{
          let checkForStartAndEndDate = schedule.docId ? false : true; // If this is the basic schedule. Check if the basic schedule already had started or ended
          
          // Check Days
          days.forEach(day=>{
            if(schedule.schedule[day.format] && schedule.schedule[day.format].length > 0){ // Check if the schedule has values this day
              let valid = checkForStartAndEndDate ? false : true;

              if(checkForStartAndEndDate){ // Check if the basic schedule already had started or ended
                let date = moment().year(this.employeeWeekSchedule.year).isoWeek(this.employeeWeekSchedule.weekNumber).day(day.index).format('YYYY-MM-DD');
                if(date >= schedule.basicScheduleStartDate && (!schedule.basicScheduleEndDate || date <= schedule.basicScheduleEndDate)){
                  valid = true;
                }
              }

              if(valid){
                let branchDataPushed = false;
                this.employeeWeekScheduleOtherBranches.days[day.index].exist = true;
                schedule.schedule[day.format].forEach(time=>{ // Push times to schedule
                
                  if(time.continiousType == 'all'){
                    this.employeeWeekScheduleOtherBranches.schedule[day.format].push(time);
                    branchDataPushed = true;
                  }
                  else if(time.continiousType == 'odd'){
                    this.employeeWeekScheduleOtherBranches.days[day.index].type = 'alternately';
                    if(!evenWeek){
                      this.employeeWeekScheduleOtherBranches.schedule[day.format].push(time); 
                      branchDataPushed = true;
                    }
                  }
                  else if(time.continiousType == 'even'){
                    this.employeeWeekScheduleOtherBranches.days[day.index].type = 'alternately';
                    if(evenWeek && this.employeeWeekScheduleOtherBranches.schedule[day.index]){
                      this.employeeWeekScheduleOtherBranches.schedule[day.index].push(time); 
                      branchDataPushed = true;
                    }
                    
                  }

                  if(branchDataPushed){
                    this.employeeWeekScheduleOtherBranches.days[day.index].branches.push(schedule.branchId)
                  }
                })

              }
            }
            this.employeeWeekScheduleOtherBranches.days[day.index].branches =  [...new Set(this.employeeWeekScheduleOtherBranches.days[day.index].branches)];

          })

        })
        
        // Merge overlapping times from different branches
        days.forEach(day=>{  
          if(this.employeeWeekScheduleOtherBranches.schedule[day.format] && this.employeeWeekScheduleOtherBranches.schedule[day.format].length > 0){
            let originalSchedule = JSON.parse(JSON.stringify(this.employeeWeekScheduleOtherBranches.schedule[day.format]));

            originalSchedule.forEach(time=>{
              originalSchedule.forEach(timeCompare=>{     
                // console.log("combinedSchedule: ", timeCompare.start, time.start, timeCompare.end, time.end)
                if(time.continiousType == timeCompare.continiousType ){  
                  if(timeCompare.start == time.start && timeCompare.end == time.end){ // Check if timeblock is not the same
                    // Timeblocks are the same
                  }
                  else{ // Timeblocks are not the same
                    if(time.start >= timeCompare.start && time.end <= timeCompare.end ){ // Timeblock is smaller than compared timeblock (set times to nulll)
                      time.start = null;
                      time.end = null;
                    }

                    else if(time.start <= timeCompare.start && time.end <= timeCompare.end && time.end > timeCompare.start){ // Timeblock has overlap on start with timeblock (change end time)
                      time.end = timeCompare.start
                    }

                    else if(time.start >= timeCompare.start && time.end >= timeCompare.end && time.start < timeCompare.end){ // Timeblock has overlap on end with timeblock (change start time)
                      time.start = timeCompare.end
                    }
                  }
                }

              })
            })
            // Return the combined times to the employeeWeekScheduleOtherBranches
            this.employeeWeekScheduleOtherBranches.schedule[day.format] = originalSchedule.filter(item=> { return item.start != null && item.end != null }) // Remove all items were time = null
          }
        })
      }

      // Check for scheduleExceptions

      // Employee extra presents other branches (start)
      if(this.employeePresenceOtherBranches && this.employeePresenceOtherBranches.length > 0){ // If employeePresence has items


        this.employeePresenceOtherBranches.forEach(presence=>{ // Loop through employeePresence
          days.forEach(day =>{  // Check by day Days
            let dateOfDay =  moment().year(this.employeeWeekScheduleOtherBranches.year).isoWeek(this.employeeWeekScheduleOtherBranches.weekNumber).day(day.index + 1).format('YYYY-MM-DD');
            if(dateOfDay >= presence.startdate && dateOfDay <= presence.enddate){
              presence.time.forEach(time=>{
                this.employeeWeekScheduleOtherBranches.schedule[day.format].push({
                  start: time.start,
                  end:  time.end,
                  reason: presence.reason,
                  extra: presence.extra,
                  type: 'extraPresence',
                  continiousType: 'all'
                })
                this.employeeWeekScheduleOtherBranches.days[day.index].branches.push(presence.branchId);
              })
            }
            this.employeeWeekScheduleOtherBranches.days[day.index].branches =  [...new Set(this.employeeWeekScheduleOtherBranches.days[day.index].branches)];
          })
        })



        // Check for overlapping times and shorten the extra times if nessesary
        days.forEach(day =>{
          if(this.employeeWeekScheduleOtherBranches.schedule[day.format] && this.employeeWeekScheduleOtherBranches.schedule[day.format].length > 0){
            let originalSchedule = JSON.parse(JSON.stringify(this.employeeWeekScheduleOtherBranches.schedule[day.format]));

            originalSchedule.forEach(time=>{
              originalSchedule.forEach(timeCompare=>{     

                if(time.continiousType == timeCompare.continiousType ){  
                  if(time.type == "extraPresence"){
                    if(timeCompare.start == time.start && timeCompare.end == time.end){ // Check if timeblock is not the same
                      // Timeblocks are the same
                    }
                    else{ // Timeblocks are not the same
                      if(time.start >= timeCompare.start && time.end <= timeCompare.end ){ // Timeblock is smaller than compared timeblock (set times to nulll)
                        time.start = null;
                        time.end = null;
                      }

                      else if(time.start <= timeCompare.start && time.end <= timeCompare.end && time.end > timeCompare.start){ // Timeblock has overlap on start with timeblock (change end time)
                        time.end = timeCompare.start
                      }

                      else if(time.start >= timeCompare.start && time.end >= timeCompare.end && time.start < timeCompare.end){ // Timeblock has overlap on end with timeblock (change start time)
                        time.start = timeCompare.end
                      }
                    }
                  }
                }
              })
            })
            this.employeeWeekScheduleOtherBranches.schedule[day.format] = originalSchedule.filter(item=> { return item.start != null && item.end != null }) // Use only he valid time items
            if(this.employeeWeekScheduleOtherBranches.schedule[day.format].length > 0){
              this.employeeWeekScheduleOtherBranches.days[day.index].exist = true;
            }
            
          }
        })
      }
      // Employee extra presents other branches (end)


      // Employee absence other branches (start)
      if(this.employeeExceptionsOtherBranches && this.employeeExceptionsOtherBranches.length > 0){
        let absenceItems = {
          Sun: new Array(),
          Mon: new Array(),
          Tue: new Array(),
          Wed: new Array(),
          Thu: new Array(),
          Fri: new Array(),
          Sat: new Array()
        };

        this.employeeExceptionsOtherBranches.forEach(absence=>{ // Loop through employeePresence
        
          days.forEach(day =>{  // Check by day Days
            let dateOfDay =  moment().year(this.employeeWeekScheduleOtherBranches.year).isoWeek(this.employeeWeekScheduleOtherBranches.weekNumber).day(day.index + 1).format('YYYY-MM-DD');
            if(dateOfDay >= absence.startdate && dateOfDay <= absence.enddate){
              
              if(absence.time && absence.time.length > 0){
                absence.time.forEach(time=>{
                  absenceItems[day.format].push({
                    start: time.start,
                    end:  time.end,
                    allDay: false,
                    reason: absence.reason,
                    extra: absence.extra,
                    type: 'absence',
                  })
                })
              }
              else{
                absenceItems[day.format].push({
                  allDay: true,
                  reason: absence.reason,
                  extra: absence.extra,
                  type: 'absence',
                })
              }
              
            }
          })
        })

        // Check for overlapping times and shorten the extra times if nessesary
        days.forEach(day =>{
          if(this.employeeWeekScheduleOtherBranches.schedule[day.format] && this.employeeWeekScheduleOtherBranches.schedule[day.format].length > 0){
            let originalSchedule = JSON.parse(JSON.stringify(this.employeeWeekScheduleOtherBranches.schedule[day.format]));
            let absenceSchedule = JSON.parse(JSON.stringify(absenceItems[day.format]));

            originalSchedule.forEach(time=>{
              absenceSchedule.forEach(absenceTime=>{     

                if(time.type != 'extraPresence'){
                  if(absenceTime.allDay){ // All Timeblocks are invalid

                    time.start = null;
                    time.end = null;
                  }
                  else{
                    if(absenceTime.start == time.start && absenceTime.end == time.end){ // Check if timeblock is not the same
                      // Timeblocks are the same
                    }
                    else{ // Timeblocks are not the same
                      if(time.start >= absenceTime.start && time.end <= absenceTime.end ){ // Timeblock is smaller than compared timeblock (set times to nulll)
                        time.start = null;
                        time.end = null;
                      }

                      else if(time.start <= absenceTime.start && time.end <= absenceTime.end && time.end > absenceTime.start){ // Timeblock has overlap on start with timeblock (change end time)
                        time.end = absenceTime.start
                      }

                      else if(time.start >= absenceTime.start && time.end >= absenceTime.end && time.start < absenceTime.end){ // Timeblock has overlap on end with timeblock (change start time)
                        time.start = absenceTime.end
                      }
                    }
                  }
                  
                }
              })
            })
            this.employeeWeekScheduleOtherBranches.schedule[day.format] = originalSchedule.filter(item=> { return item.start != null && item.end != null }) // Use only he valid time items
            if(this.employeeWeekScheduleOtherBranches.schedule[day.format] == 0){
              this.employeeWeekScheduleOtherBranches.days[day.index].exist = false;
            }
          }
        })
      }
      // Employee absence other branches (end)

      // Calculate week combinedMinutes

      this.employeeWeekSchedule.combinedMinutes = 0;

      days.forEach(day =>{
        this.employeeWeekSchedule.days[day.index].minutes = 0;
        if(this.employeeWeekSchedule.schedule[day.format] && this.employeeWeekSchedule.schedule[day.format].length > 0){
          this.employeeWeekSchedule.schedule[day.format].forEach(time=>{
            if(time.type != 'pause'){
              if(time.continiousType == 'all' || (evenWeek && time.continiousType == 'even') || (!evenWeek && time.continiousType == 'odd')){
                let timeInMinutes = Number(moment(time.end, "HH:mm").diff(moment(time.start, "HH:mm"), "minutes"));
                this.employeeWeekSchedule.combinedMinutes =  this.employeeWeekSchedule.combinedMinutes + timeInMinutes;
                this.employeeWeekSchedule.days[day.index].minutes = this.employeeWeekSchedule.days[day.index].minutes+ timeInMinutes;
              }
            }
          })
        }
      });

      this.calculateScheduleSpaces();
      this.employeeWeekSchedule.loading = false;
    },

    /**
     * Used for the tooltip when schedule of a different branch is shown. 
     * This function finds and displays the name of the different branche(s)
     */
    employeeScheduledBranches(day){
      if(this.employeeWeekScheduleOtherBranches.days[day].branches && this.employeeWeekScheduleOtherBranches.days[day].branches.length > 0){
        let branches = new Array();

        this.employeeWeekScheduleOtherBranches.days[day].branches.forEach((branchId)=>{ // Find branchname from store
          let branchData = this.$store.state.companyBranches.find(storeBranch => {return storeBranch.id == branchId})
          if(branchData != undefined){
            branches.push(branchData.name)
          }

        })
        const formatter = new Intl.ListFormat(this.$store.state.nativeLanguage, { style: 'short', type: 'disjunction' });
        return formatter.format(branches)
      }
      else{
        return ""
      }
      
    },


    removeScheduleFromDataBase(id){
      db.collection("schedules").doc(id).delete();

      this.getEmployeeWeekScheduleData('reload')// Reload
    },

    copyScheduleFromDataBase(data){
      this.$store.state.employeeDatabaseScheduleSaveData = Object.assign({}, data);

      this.snackbar.active = true;
      this.snackbar.timeout = 3000;
      this.snackbar.color = "rgba(1, 230, 110, 0.7)";
      this.snackbar.icon = "mdi-content-copy";
      this.snackbar.text = this.$t('general.copied', this.$store.state.locale);
    },

    pastScheduleFromDataBase(){
      if(this.employeeWeekSchedule.type == "scheduleCollectionDocument"){
        db.collection("schedules").doc(this.employeeWeekSchedule.docId).delete()
      }
      
      db.collection("schedules").add({
        companyId: this.$store.state.activeCompany.id,
        branchId: this.selectedBranch,
        employeeId: this.$store.state.employeeDatabaseScheduleSaveData.employeeId,
        week: this.employeeWeekSchedule.weekNumber,
        year: this.employeeWeekSchedule.year,
        schedule: this.$store.state.employeeDatabaseScheduleSaveData.schedule,
        type: "customChangedSchedule",
        uniqId: uniqid(),
        minutes: this.$store.state.employeeDatabaseScheduleSaveData.minutes,
        minutesAverage: this.$store.state.employeeDatabaseScheduleSaveData.minutesAverage
      })

      this.getEmployeeWeekScheduleData('reload')// Reload

      this.snackbar.active = true;
      this.snackbar.timeout = 3000;
      this.snackbar.color = "rgba(1, 230, 110, 0.7)";
      this.snackbar.icon = "mdi-content-paste";
      this.snackbar.text = this.$t('general.pasted', this.$store.state.locale);
    },

    async editScheduleFromDataBase(){
      this.newBasicSchedule = Object.assign({}, this.employeeWeekSchedule);
      this.newBasicSchedule.loading = false;
      this.newBasicSchedule.type = 'edit';
      this.newBasicSchedule.feedback = null;
      this.newBasicSchedule.minutes = new Object();
      this.newBasicSchedule.schedule.continiousScheduleType = new Object();
      this.checkDayTypes();
      this.calculateBasicScheduleHours();
      this.newBasicSchedule.dialog = true;
    },

    checkNFC() {
      this.nfcStatus = {
        status: "empty",
        id: null,
      };

      if (window && window.process && window.process.type) {
        if (this.$store.state.nfcId) {
          db.collection("employees")
          .where("companyId", "==", this.$store.state.userCompany)
          .where("nfcTags", "array-contains", this.$store.state.nfcId)
          .get()
          .then((snap) => {
            if (snap.empty) {
              this.nfcStatus.status = "available";
              this.nfcStatus.id = this.$store.state.nfcId;
            } else {
              snap.forEach((doc) => {
                this.nfcStatus.status = "used";
                this.nfcStatus.id = this.$store.state.nfcId;
                this.nfcStatus.userId = doc.id;
              });
            }
          });
        }
      }
    },

    getCompanyPincodes() {
      this.pincodesLoading = true;
      db.collection("companies")
      .doc(this.$store.state.activeCompany.id)
      .collection("authentication")
      .doc("pincodes")
      .get()
      .then((doc) => {
        if (!doc.exists) {
          this.companyAuthentication = new Array();
          this.pincodesLoading = false;
        } else {
          this.companyAuthentication = doc.data().codes;
          let index = _.findIndex(doc.data().codes, ["employeeId", this.selectedEmployeeInfo.id]);
          if (index >= 0) {
            this.pincode = doc.data().codes[index].pin;
          } else {
            this.pincode = null;
          }
          this.pincodesLoading = false;
        }
      })
      .catch((error)=>{
        console.error("Error getting company pincodes: ", error)
      })
    },

    generatePincode() {
      let pincode = Math.floor(Math.random() * 9000) + 1000;
      this.checkPincode(pincode);
    },

    checkPincode(code) {
      // Check for duplications
      let digits = String(code).split("");
      let sequenceValid = false;
      let counterValid = true;

      for (var i = 0; i < digits.length - 1; i++) {
        if (Math.abs(parseInt(digits[i]) - parseInt(digits[i + 1])) > 1) {
          sequenceValid = true;
          break;
        }
      }

      for (var i = 0; i < digits.length; i++) {
        let index = i;
        for (var subIndex = 0; subIndex < digits.length; subIndex++) {
          let counter = 1;
          if (subIndex != index) {
            if (digits[index] == digits[subIndex]) {
              counter++;
              if (counter > 2) {
                counterValid = false;
                break;
              }
            }
          }
        }
      }

      if (!sequenceValid || !counterValid) {
        this.generatePincode();
      }

      if (this.companyAuthentication.length > 0) {
        let index = _.findIndex(this.companyAuthentication, ["pin", code]);
        if (index >= 0) {
          this.generatePincode();
        } else {
          this.setPincode(String(code));
        }
      } else {
        this.setPincode(String(code));
      }
    },

    setPincode(pincode) {
      let index = _.findIndex(this.companyAuthentication, ["employeeId", this.selectedEmployeeInfo.id]);
      if (index >= 0) {
        this.companyAuthentication.splice(index, 1);
      }
      let data = {
        employeeId: this.selectedEmployeeInfo.id,
        pin: pincode,
      };
      this.pincode = pincode;
      this.companyAuthentication.push(data);

      db.collection("companies").doc(this.$store.state.activeCompany.id).collection("authentication").doc("pincodes").set({
          codes: this.companyAuthentication,
      },{ merge: true })
      .catch((error)=>{
        console.log("authentication update error: ", error)
      })
    },

    addNfcTag(id) {
      this.addNFCbusy = true;
      if (this.selectedEmployeeInfo.nfcTags) {
        if (this.selectedEmployeeInfo.nfcTags.length == 0) {
          this.selectedEmployeeInfo.nfcTags = new Array();
        }
      } else {
        this.selectedEmployeeInfo.nfcTags = new Array();
      }
      this.selectedEmployeeInfo.nfcTags.push(id);

      db.collection("employees")
        .doc(this.selectedEmployeeInfo[this.docName])
        .set(
          {
            nfcTags: this.selectedEmployeeInfo.nfcTags,
          },
          { merge: true }
        )
        .then(() => {
          this.selectedEmployeeInfoOriginal = _.cloneDeep(this.selectedEmployeeInfo);
          this.checkNFC();
          this.addNFCbusy = false;
        });
    },

    removeNfcTag(index) {
      this.selectedEmployeeInfo.nfcTags.splice(index, 1);
      db.collection("employees")
        .doc(this.selectedEmployee[this.docName])
        .set(
          {
            nfcTags: this.selectedEmployeeInfo.nfcTags,
          },
          { merge: true }
        )
        .then(() => {
          this.selectedEmployeeInfoOriginal = _.cloneDeep(this.selectedEmployee);
          this.checkNFC();
        });
    },

    checkUserAccount() {
      this.userAccountLoading = true;
      if (this.selectedEmployeeInfo.loginAccount && this.selectedEmployeeInfo.userId) {
        db.collection("users")
          .doc(this.selectedEmployeeInfo.userId)
          .get()
          .then((doc) => {
            if (doc.exists) {
              this.userAccount = doc.data();
              this.userAccount.id = doc.id;
              this.userAccountOriginal = _.cloneDeep(this.userAccount);
            }
            else {
              this.selectedEmployeeInfo.loginAccount = false;
              this.selectedEmployeeInfoOriginalloginAccountActive = false;
              this.selectedEmployeeInfo.uid = null;
              this.selectedEmployeeInfo
              this.userAccount = null;
              this.userAccountOriginal = null;
            }
            // this.userAccount.uid = doc.data().user_id;
          })
          .then(() => {
            this.userAccountLoading = false;
          });
      }
      else {
        this.selectedEmployeeInfo.loginAccount = false;
        this.selectedEmployeeInfo.loginAccountActive = false;
        this.selectedEmployeeInfo.uid = null;
        this.userAccount = null;
        this.userAccountOriginal = null;
      }
    },


    loadCao(){
      this.caoItems = new Array();
      db.collection("cao")
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let cao = doc.data()
          cao.id = doc.id;
          this.caoItems.push(cao);
        })
      })
      .catch((error)=>{
        console.error("Error getting cao's: ", error)
      })              
    },  

    async getEmployeeLeaveCorrections() {
      this.employeeLeaveCorrection = new Array();

      await db.collection("leaveCorrections")
      .where("companyId", "==", this.selectedEmployeeInfo.companyId)
      .where("employeeId", "==", this.selectedEmployeeInfo.id)
      .where("year", "==", Number(this.employeeExceptionsDate.year))
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let exception = doc.data();
          exception.id = doc.id;
          this.employeeLeaveCorrection.push(exception);
        });
      })
      console.log("this.employeeLeaveCorrection: ", this.employeeLeaveCorrection)
    },  

    async getEmployeeExeptionsOverview() {
      this.employeeExceptionsLoading = true;
      this.employeeExceptionsLeave = new Array();
      this.employeeExceptionsSick = new Array();
      this.employeeExceptionsExtra = new Array();
      let startDate;
      if(this.employeeExceptionsDate.type == "year"){
        startDate = moment(this.employeeExceptionsDate.year, "YYYY").startOf('year').format("YYYY-MM-DD");
      }

      await db.collection("schedulesExceptions")
      .where("companyId", "==", this.selectedEmployeeInfo.companyId)
      .where("branchId", "==", this.selectedBranch)
      .where("resourceId", "==", this.selectedEmployeeInfo.id)
      .where("startdate", ">=", startDate)
      .where("year", "==", Number(this.employeeExceptionsDate.year))
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let exception = doc.data();
          exception.id = doc.id;
          if(doc.data().reason == "leave"){
            this.employeeExceptionsLeave.push(exception);
          }
          if(doc.data().reason == "exchangeService" && doc.data().type =='absence'){
            this.employeeExceptionsLeave.push(exception);
          }
          if(doc.data().reason == "sickness"){
            this.employeeExceptionsSick.push(exception);
          }
          if(doc.data().reason == "extraHours"){
            this.employeeExceptionsExtra.push(exception);
          }
          if(doc.data().reason == "exchangeService" && doc.data().type == 'present'){
            this.employeeExceptionsExtra.push(exception);
          }
        });
      })

      this.employeeExceptionsLoading = false;
    },

    getEmployeePresence(){
      let symbol = ">=";
      let today = String(moment().format("YYYY-MM-DD"));
      if (this.presenceDisplay == "past") {
        symbol = "<=";
      }
      this.presenceLoading = true;
      db.collection("schedulesExceptions")
        .where("companyId", "==", this.selectedEmployeeInfo.companyId)
        .where("resourceId", "==", this.selectedEmployeeInfo.id)
        .where("enddate", symbol, today)
        .where("type", "==", 'present')
        .get()
        .then((snap) => {
          this.employeePresence = new Array();
          this.employeePresenceOtherBranches = new Array();
          snap.forEach((doc) => {
            let exeption = doc.data();
            exeption.id = doc.id;
            if(doc.data().branchId == this.selectedBranch){
              this.employeePresence.push(exeption);
            }
            else{
              this.employeePresenceOtherBranches.push(exeption);
            }
          });
        })
        .then(() => {
          if(this.presenceDisplay == "past"){
            this.employeePresence = this.employeePresence.sort((b, a) => a.startdate.localeCompare(b.startdate));
          }
          else{
            this.employeePresence = this.employeePresence.sort((a, b) => a.startdate.localeCompare(b.startdate));
          }
          this.presenceLoading = false;
        })
        .catch((error)=>{
          console.error("Error getting schedulesExceptions: ", error)
        })
    },

    getEmployeeExeptions() {
      let symbol = ">=";
      let today = String(moment().format("YYYY-MM-DD"));
      if (this.exeptionDisplay == "past") {
        symbol = "<=";
      }
      this.exeptionsLoading = true;
      db.collection("schedulesExceptions")
        .where("companyId", "==", this.selectedEmployeeInfo.companyId)
        .where("resourceId", "==", this.selectedEmployeeInfo.id)
        .where("enddate", symbol, today)
        .where("type", "==", 'absence')
        .get()
        .then((snap) => {
          this.employeeExceptions = new Array();
          this.employeeExceptionsOtherBranches = new Array();
          snap.forEach((doc) => {
            let exeption = doc.data();
            exeption.id = doc.id;
            if(doc.data().branchId == this.selectedBranch){
              this.employeeExceptions.push(exeption);
            }
            else{
              this.employeeExceptionsOtherBranches.push(exeption);
            }
          });
        })
        .then(() => {
          if(this.exeptionDisplay == "past"){
            this.employeeExceptions = this.employeeExceptions.sort((b, a) => a.startdate.localeCompare(b.startdate));
          }
          else{
            this.employeeExceptions = this.employeeExceptions.sort((a, b) => a.startdate.localeCompare(b.startdate));
          }
          this.exeptionsLoading = false;
        })
        .catch((error)=>{
          console.error("Error getting schedulesExceptions: ", error)
        })
    },

    setExceptionTimes() {
      if (this.exceptionDialog.day) {
        this.exceptionDialog.time = new Array();
      } else {
        this.addExceptionTimes();
      }
    },

    addExceptionTimes() {
      this.exceptionDialog.time.push({ start: "", end: "" });
    },

    addPresenceTimes() {
      this.presenceDialog.time.push({ start: "", end: "" });
    },

    closeExceptionCheck() {
      this.exceptionDialog.overlap.dialog = false;
    },

    checkexceptionDialogEndDate(){
      if(this.exceptionDialog.startDate >= this.exceptionDialog.endDate){
        this.exceptionDialog.endDate = null;
      }
    },

    findUsers() {
      this.findUserLoading = true;
      this.searchEmailOriginal = _.cloneDeep(this.searchEmail);

      db.collection("users")
        .where("companyId", "==", this.$store.state.activeCompany.id)
        .where("email", "==", this.searchEmail)
        .get()
        .then((snap) => {
          this.foundUsers = new Array();
          snap.forEach((doc) => {
            let user = doc.data();
            user.id = doc.id;
            this.foundUsers.push(user);
          });
        })
        .then(() => {
          this.userSearched = true;
          this.findUserLoading = false;
        })
        .catch((error)=>{
          console.error("Error getting users: ", error)
        })
    },

    deactivateUserAccount() {
      this.selectedEmployeeInfo.loginAccountActive = false;
      this.updateEmployee('deactivateUserAccount')
    },

    removeRegisteredDevice(index) {
      this.userAccount.registeredDevices.splice(index, 1);
      this.updateEmployee('removeRegisteredDevice')
    },

    disconnectUserAccount() {
      this.selectedEmployeeInfo.loginAccount = false;
      this.selectedEmployeeInfo.loginAccountActive = false;
      this.selectedEmployeeInfo.userId = null;
      this.selectedEmployeeInfo.uid = null;
      this.searchEmailOriginal = "";
      this.searchEmail = "";
      this.foundUsers = new Array();
      this.userSearched = false;
      this.updateEmployee('disconnectUserAccount')
    },

    setUserAccountType(type) {
      this.userAccountFeedback = null;
      if (type == "existing") {
        this.employeeUserEmail = "";
        this.employeeUserLanguage = "NL";
        this.employeeUserPassword = "";
        (this.employeeUserSendEmail = false), (this.existingUserAccount = true);
        this.newUserAccount = false;
      }
      else {
        this.newUserAccount = true;
        this.existingUserAccount = false;
        this.employeeUserLanguage = this.availableLanguages[0].languageCode;
        this.searchEmailOriginal = "";
        this.searchEmail = "";
        this.foundUsers = new Array();
        this.userSearched = false;
        this.selectedEmployeeInfo.loginAccount = true;
        this.selectedEmployeeInfo.loginAccountActive = true;
      }
    },

    activateUserAccount() {
      this.selectedEmployeeInfo.loginAccountActive = true;
      this.updateEmployee('activateUserAccount')
    },

    async savePresenceCheck(type){
      this.presenceDialog.feedback = null;
      let timesCorrect = false;
      if(this.presenceDialog.reason && this.presenceDialog.startDate && this.presenceDialog.time.length > 0){
        // Check if times are correct
        await new Promise((resolve) => {
          let counter = 0;
          let trueCounter = 0;
    
          this.presenceDialog.time.forEach((presenceTime) => {
            if (presenceTime.start && presenceTime.start.length == 5 && presenceTime.end && presenceTime.end.length == 5) {
              if (presenceTime.end > presenceTime.start) {
                trueCounter++;
              }
            }
            counter++;
            if (counter == this.presenceDialog.time.length) {
              if (trueCounter == counter) {
                timesCorrect = true;
              }
              resolve();
            }
          });
        })

        if(timesCorrect){
          if(type == 'new'){
            this.addPresence();
          }
          else{
            this.editPresence();
          }
          
        }
        else{
          this.presenceDialog.feedback = this.$t('employees.feedbackTimesNotCorrect', this.$store.state.locale);
        }
      }
      else{
        this.presenceDialog.feedback = this.$t('employees.feedbackNoAllRequiredField', this.$store.state.locale);
      }
    },

    async editPresence() {
      this.feedbackExeption = null;

      await db.collection("schedulesExceptions").doc(this.presenceDialog.id).set({
        reason: this.presenceDialog.reason,
        extra: this.presenceDialog.extra,
        lastEditted: new Date(),
        startdate: this.presenceStartDate.firebase,
        enddate: this.presenceStartDate.firebase,
        passedOldException: this.presenceStartDate.firebase >= moment().format("YYYY-MM-DD") ? false : true,
        year: Number(moment(this.presenceStartDate.firebase).format("YYYY")),
        time: _.orderBy(this.presenceDialog.time, ["start"], ["asc"]),
      },{merge: true})

      this.removeCalendarResourceCache(this.presenceStartDate.firebase, this.presenceStartDate.firebase) // Remove created cache in firebase
      this.getEmployeePresence(this.selectedEmployeeInfo);
      this.checkForScheduleReload(this.presenceStartDate.firebase, this.presenceStartDate.firebase);
      this.resetPresenceDialog();
    },

    addPresence() {
      this.feedbackPresence = null;

      db.collection("schedulesExceptions")
        .add({
          companyId: this.$store.state.activeCompany.id,
          branchId: this.selectedBranch,
          resourceId: this.selectedEmployeeInfo.id,
          reason: this.presenceDialog.reason,
          extra: this.presenceDialog.extra,
          dateAdded: new Date(),
          startdate: this.presenceStartDate.firebase,
          enddate: this.presenceStartDate.firebase,
          passedOldException: this.presenceStartDate.firebase >= moment().format("YYYY-MM-DD") ? false : true,
          year: Number(moment(this.presenceStartDate.firebase).format("YYYY")),
          time: _.orderBy(this.presenceDialog.time, ["start"], ["asc"]),
          type: "present",
          userCreated: this.userFirstName + " " + this.userLastName,
        })
        .then(() => {
          this.removeCalendarResourceCache(this.presenceStartDate.firebase, this.presenceStartDate.firebase) // Remove created cache in firebase
          this.getEmployeePresence(this.selectedEmployeeInfo);
          this.checkForScheduleReload(this.presenceStartDate.firebase, this.presenceStartDate.firebase);
          this.resetPresenceDialog();
        })
        .catch((error) => {
          console.error("Fout bij toevoegen schedulesExceptions: ", error);
        });
    },

    async addExceptionCheck(checkType, type) {
      this.exceptionDialog.overlap.checking = true;
      this.exceptionDialog.overlap.overlaps = new Array();
      this.feedbackExeption = null;
      let bookings = new Array();

      if (this.exceptionDialog.reason && this.exceptionDialog.startDate) { // Check for basis information
        if (!this.exceptionDialog.period || (this.exceptionDialog.period && this.exceptionDialog.endDate)) { // Check for essential time information
          let timesCorrect = false;
          let times = new Array();
          await new Promise((resolve) => { // Check if times are correct entered in the modal (no endtime that is earlier than starttime)
            let counter = 0;
            let trueCounter = 0;
            if (!this.exceptionDialog.day) {
              this.exceptionDialog.time.forEach((exceptionTime) => {
                if (exceptionTime.start && exceptionTime.start.length == 5 && exceptionTime.end && exceptionTime.end.length == 5) {
                  if (exceptionTime.end > exceptionTime.start) {
                    trueCounter++;
                  }
                }
                counter++;
                if (counter == this.exceptionDialog.time.length) {
                  if (trueCounter == counter) {
                    timesCorrect = true;
                    times = this.exceptionDialog.time = _.orderBy(this.exceptionDialog.time, ["start"], ["asc"]);
                  }
                  resolve();
                }
              });
            }
            else {
              timesCorrect = true;
              resolve();
            }
          })
          
          if (timesCorrect) {
            let endDate = this.exceptionDialog.period ? this.exceptionEndDate.firebase : this.exceptionStartDate.firebase;
            console.log("Check: ", this.selectedEmployeeInfo.id, this.exceptionStartDate.firebase, endDate)
            await db.collection("bookingen")
            .where("resourceDocId", "==", this.selectedEmployeeInfo.id)
            .where("dateBooking", ">=", this.exceptionStartDate.firebase)
            .where("dateBooking", "<=", endDate)
            .where("bookingOverride", "==", false)
            .get()
            .then((snapshot) => {
              snapshot.forEach((doc) => {
                let booking = new Object();
                booking.id = doc.id;
                booking.start = doc.data().start;
                booking.end = doc.data().end;
                booking.type = doc.data().type;
                booking.title = doc.data().title;
                booking.subtitle = doc.data().description ? doc.data().description : null;
                booking.color = doc.data().eventColor;
                booking.client = doc.data().clientname;
                booking.source = doc.data().source;
                booking.extra = doc.data().extra ? doc.data().extra : new Object();
                booking.preferredPractitioner = doc.data().preferredPractitioner;
                bookings.push(booking);
              });
            })
            .catch((error) => {
              this.feedbackExeption = this.$t('employees.feedbackCheckError', this.$store.state.locale);
              this.exceptionDialog.overlap.checking = false;
              console.error("Error bij binnenhalen bookingen voor check:", error);
            });

            if (bookings.length > 0) { // Overlay possible because there are other bookings this period
              let overlaps = new Array();
              let start;
              let end;

              // Check Bookings for overlap
              if(!this.exceptionDialog.day && this.exceptionDialog.time.length > 0) { // Check for overlay on timeblocks
                this.exceptionDialog.time.forEach((awayTime) => {
                  start = this.exceptionStartDate.firebase + "T" + awayTime.start;
                  end = endDate + "T" + awayTime.end;
                  bookings.forEach((booking) => {
                    if (
                      booking.start == start ||
                      moment(booking.start).isBetween(moment(start), moment(end)) == true ||
                      moment(start).isBetween(moment(booking.start), moment(booking.end)) == true ||
                      moment(end).isBetween(moment(booking.start), moment(booking.end)) == true
                    ) {
                      overlaps.push(booking);
                    }
                  });
                });
              }
              else { // Check for any events in the full day
                start = this.exceptionStartDate.firebase + "T00:00";
                end = endDate + "T23:59";
                bookings.forEach((booking) => {
                  if (
                    booking.start == start ||
                    moment(booking.start).isBetween(moment(start), moment(end)) == true ||
                    moment(start).isBetween(moment(booking.start), moment(booking.end)) == true ||
                    moment(end).isBetween(moment(booking.start), moment(booking.end)) == true
                  ) {
                    overlaps.push(booking);
                  }
                });
              }

              if (overlaps.length > 0 && checkType != 'force') { // Overlap detected
                this.exceptionDialog.overlap.checking = false;
                this.exceptionDialog.overlap.dialog = true;
                this.exceptionDialog.overlap.overlaps = _.sortBy(overlaps, ["start"]);
              }
              else { // No Overlap detected or Forced Exception
                if(this.exceptionDialog.type == 'edit'){
                  this.editException();
                }
                else{
                  this.addException();
                }
              }
            }
            else { // Overlay NOT possible because there are NO other bookings this period
              if(this.exceptionDialog.type == 'edit'){
                this.editException();
              }
              else{
                this.addException();
              }
            }
          }
          else {
            this.feedbackExeption = this.$t('employees.feedbackNoTimes', this.$store.state.locale);
          }

        } else {
          this.feedbackExeption = this.$t('employees.feedbackNoEnddate', this.$store.state.locale);;
        }
      } 
      else {
        this.exceptionDialog.overlap.checking = false;
        this.feedbackExeption = this.$t('employees.feedbackNoStartdate', this.$store.state.locale);;
      }
    },

    async editException() {
      this.feedbackExeption = null;
      let endDate = this.exceptionDialog.period ? this.exceptionEndDate.firebase : this.exceptionStartDate.firebase;
      let times = !this.exceptionDialog.day ? _.orderBy(this.exceptionDialog.time, ["start"], ["asc"]) : new Array();

      await db.collection("schedulesExceptions").doc(this.exceptionDialog.id).set({
        reason: this.exceptionDialog.reason,
        extra: this.exceptionDialog.extra,
        lastEditted: new Date(),
        startdate: this.exceptionStartDate.firebase,
        enddate: endDate,
        passedOldException: endDate >= moment().format("YYYY-MM-DD") ? false : true,
        year: Number(moment(this.exceptionStartDate.firebase).format("YYYY")),
        time: times,
      },{merge: true})

      this.removeCalendarResourceCache(this.exceptionStartDate.firebase, this.exceptionStartDate.firebase) // Remove created cache in firebase
      this.resetExceptionDialog();
      this.getEmployeeExeptions(this.selectedEmployeeInfo);
      this.checkForScheduleReload(this.exceptionStartDate.firebase, endDate);
    },


    addException() {
      this.feedbackExeption = null;
      let endDate = this.exceptionDialog.period ? this.exceptionEndDate.firebase : this.exceptionStartDate.firebase;
      let times = !this.exceptionDialog.day ? _.orderBy(this.exceptionDialog.time, ["start"], ["asc"]) : new Array();

      db.collection("schedulesExceptions")
        .add({
          companyId: this.$store.state.activeCompany.id,
          branchId: this.selectedBranch,
          resourceId: this.selectedEmployeeInfo.id,
          reason: this.exceptionDialog.reason,
          extra: this.exceptionDialog.extra,
          dateAdded: new Date(),
          passedOldException: endDate >= moment().format("YYYY-MM-DD") ? false : true,
          startdate: this.exceptionStartDate.firebase,
          enddate: endDate,
          year: Number(moment(this.exceptionStartDate.firebase).format("YYYY")),
          time: times,
          type: "absence",
          userCreated: this.userFirstName + " " + this.userLastName,
        })
        .then(() => {
          this.removeCalendarResourceCache(this.exceptionStartDate.firebase, endDate) // Remove created cache in firebase

          this.resetExceptionDialog()
          this.getEmployeeExeptions(this.selectedEmployeeInfo);
          this.checkForScheduleReload(this.exceptionStartDate.firebase, endDate);
        })
        .catch((error) => {
          console.error("Fout bij toevoegen schedulesExceptions: ", error);
        });
    },

    /**
     * Remove Absence Exception from database
     * 
     * @param { Object } item Objectdata for removal
     * @param { number } index Index of the object in employeeExceptions array
     */
    async removeException(item, index) {
      let success = true;
      let logData = {
        superAdminAction: false, // Is action only accessible by superadmin?
        actionType: "userAction",
        action: "removeEmployeeSchedulesExceptionExtra",
        actionItem: `${this.selectedEmployeeInfo.name} ${this.selectedEmployeeInfo.lastName}`,
        extra:[
          {
            name: "name",
            value: `${this.selectedEmployeeInfo.name} ${this.selectedEmployeeInfo.lastName}`
          },
          {
            name: "id",
            value: this.selectedEmployeeInfo.id
          },
          {
            name: "item",
            value: JSON.stringify(item)
          },
          {
            name: "branchId",
            value: this.selectedBranch
          },
        ]
      };
      try {
        await db.collection("schedulesExceptions")
        .doc(item.id)
        .delete()
        .then(()=>{
          this.removeCalendarResourceCache(item.startdate, item.enddate) // Remove created cache in firebase
        })
      }
      catch (error) {
        success = false;
        console.error("Error removing Exception: ", error);
        logData.extra.push({ name: "error", value: JSON.stringify(error) })
        await this.$createNewLog("error", logData);
      };

      if(success){ 
        if(item.type == 'absence'){
          this.employeeExceptions.splice(index, 1);
        }
        else{
          this.employeePresence.splice(index, 1);
        }
        // Show succes Message
        this.snackbar.color = "rgba(1, 230, 110, 0.7)";
        this.snackbar.icon = "mdi-thumb-up";
        this.snackbar.text = "Uitzondering succesvol verwijderd.";

      
        this.checkForScheduleReload(item.startdate, item.enddate)
      }
      else{
        // Show Error Message
        this.snackbar.color = "rgba(213, 0, 0, 0.7)";
        this.snackbar.icon = "mdi-thumb-down";
        this.snackbar.text = "Uitzondering niet verwijderd.";
      }
      this.snackbar.active = true;
      this.snackbar.timeout = 3000;
    },

    /** Check if the current shown schedule needs to be reloaded
     * @param {start} // start date of the exception
     * @param {end} // end date of the exception
     */
    checkForScheduleReload(start, end){
      console.log("checkForScheduleReload: ", start, end)
      let weekStartDay = moment().year(this.employeeWeekSchedule.year).isoWeek(this.employeeWeekSchedule.weekNumber).day(1).format('YYYY-MM-DD');
      let weekEndDay = moment().year(this.employeeWeekSchedule.year).isoWeek(this.employeeWeekSchedule.weekNumber).day(7).format('YYYY-MM-DD');
      
      if(start >= weekStartDay && start <= weekEndDay || end >= weekStartDay && end <= weekEndDay){
        // Dates are in range: Reload Needed
        this.getEmployeeWeekScheduleData('reload')
      }
   
    },

    /**
     * Remove created cache in Firebase
     * Dispatch general function in the $store and logs response
     * 
     * @param { string } start startdate formatted as YYYY-MM-DD
     * @param { string } end enddate formatted as YYYY-MM-DD
     */
    removeCalendarResourceCache(start, end){ 
      this.$store.dispatch('removeCalendarResourceCache', {branchId: this.selectedBranch, start: start, end: end})
      .then((response)=>{
        console.log("removeCalendarResourceCache response: ", response)
      })
    },
    
    loadDeviceTypes() {
      this.deviceTypes = new Array();
      db.collection("deviceTypes")
      .get()
      .then((snap) => {
        this.deviceTypes = new Array();
        snap.forEach((doc) => {
          let device = doc.data();
          device.id = doc.id;
          this.deviceTypes.push(device);
        });
      });
    },

    loadAbilities() {
      this.abilities = new Array();
      db.collection("abilities")
        .get()
        .then((snap) => {
          this.abilities = new Array();
          snap.forEach((doc) => {
            let ability = doc.data();
            ability.id = doc.id;
            this.abilities.push(ability);
          });
        });
    },

    openMediaLibrary(){
      this.libraryDialog = true;
      bus.$emit("openMediaLibrary");
    },

    setChosenImage(data){
      this.selectedEmployeeInfo.employeePhoto = data.url;
      this.closeLibraryDialog();
    },

    closeLibraryDialog(){
      this.libraryDialog = false;
      bus.$emit("clearMediaLibrarySelection");
    },

    addEmailaddress(){
      let emails = this.selectedEmployeeInfo.emails ? this.selectedEmployeeInfo.emails : new Array();
      bus.$emit('addEmailDialog', emails, "editEmployeeInformation");
    },

    addNewEmailaddress(data){
      this.selectedEmployeeInfo.emails.push(data);
      this.changes++
      this.updateEmployee('email')
    },

    removeEmail(item){
      this.selectedEmployeeInfo.emails = this.selectedEmployeeInfo.emails.filter(email => {return email.email != item.email})
      this.checkForPrimaryEmail()
      this.updateEmployee('email')
    },

    async checkForPrimaryEmail(){
      let primary = this.selectedEmployeeInfo.emails.filter(email => {return email.primary})
      if(!primary || primary.length == 0){
        this.setEmailPrimary(this.selectedEmployeeInfo.emails[0])
      }
    },
    
    setEmailPrimary(item){
      this.selectedClient.emails.map(email => {
        email.email == item.email ? email.primary = true : email.primary = false })
    },

    emailScoreColor(score) {
			//Score from 10 to 8 "green"; 8 to 5 "yellow"; 5 to 0 "red"
			if (score >= 8) {
				return "green";
			} else if (score >= 5) {
				return "yellow";
			} else {
				return "red";
			}
		},
    
    emailScoreDialog(score, error) {
			this.dialogScore = !this.dialogScore;
			this.scoreInfo = {
				error: Object.keys(error).length > 0 ? error : null,
				score: score,
			}
		},

    addPhonenumber(){
      bus.$emit('addPhonenumberDialog', this.selectedEmployeeInfo.phones, 'editEmployeeInformation');
    },

    addNewPhonenumber(data){
      this.selectedEmployeeInfo.phones.push(data)
      this.updateEmployee('phone')
    },

    removePhone(item){
      this.selectedEmployeeInfo.phones = this.selectedEmployeeInfo.phones.filter(phone => {return phone.phone.e164 != item.phone.e164})
      this.checkForPrimaryNumber()
      this.updateEmployee('phone')
    },

    async checkForPrimaryNumber(){
      let primary = this.selectedEmployeeInfo.phones.filter(phone => {return phone.primary})
      if(!primary || primary.length == 0){
        let mobilePhones = this.selectedEmployeeInfo.phones.filter(phone => {return phone.type == 'mobile'})
        if(!mobilePhones || primary.mobilePhones == 0){
          this.setPrimary(this.selectedEmployeeInfo.phones[0])
        }
        else{
          this.setPrimary(mobilePhones[0])
        }
      }
    },
      
    setPrimary(item){
      this.selectedEmployeeInfo.phones.map(phone => {
        phone.phone.e164 == item.phone.e164 ? phone.primary = true : phone.primary = false })
    },

    copyTextToClipboard(text, type) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }

      navigator.clipboard.writeText(text).then(
        () => {
          this.presenceDocCopy = type == 'presenceDoc' ? true : false;
          this.absenceDocCopy = type == 'absenceDoc' ? true : false;

          this.selectedEmployeeIdCopy = true;
          this.selectedCorrectionIdCopy = true;
          setTimeout(() => {
            this.selectedEmployeeIdCopy = false;
            this.selectedCorrectionIdCopy = false;
            this.presenceDocCopy = false;
            this.absenceDocCopy = false;
          }, 1000);
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },

    createEmployeeBasicSchedule() {
      if(!this.selectedBranch || this.selectedBranch == ""){
        this.selectedBranch = this.selectedEmployeeInfo.branches.length > 0 ? this.selectedEmployeeInfo.branches[0] : "";
      }
      this.selectedEmployeeInfo.exception = new Object();
      this.selectedEmployeeInfo.exceptionAvailable = new Object();
      this.scheduleStartDateRaw = this.selectedEmployeeInfo.basicSchedule[this.selectedBranch] && this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].startDate ? this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].startDate : null;
      this.scheduleEndDateRaw = this.selectedEmployeeInfo.basicSchedule[this.selectedBranch] && this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].endDate ? this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].endDate : null;

      let employeeBranches = this.selectedEmployeeInfo.branches;
      employeeBranches.forEach((branch) => {
        if (!this.selectedEmployeeInfoOriginal.basicSchedule[branch]) {
          this.selectedEmployeeInfo.basicSchedule[branch] = new Object();

          this.selectedEmployeeInfo.basicSchedule[branch].Sun = new Array();
          this.selectedEmployeeInfo.basicSchedule[branch].Mon = new Array();
          this.selectedEmployeeInfo.basicSchedule[branch].Tue = new Array();
          this.selectedEmployeeInfo.basicSchedule[branch].Wed = new Array();
          this.selectedEmployeeInfo.basicSchedule[branch].Thu = new Array();
          this.selectedEmployeeInfo.basicSchedule[branch].Fri = new Array();
          this.selectedEmployeeInfo.basicSchedule[branch].Sat = new Array();

          this.selectedEmployeeInfo.basicSchedule[branch].continiousScheduleType = new Object();
          this.selectedEmployeeInfo.basicSchedule[branch].minutes = new Object();
          this.selectedEmployeeInfo.basicSchedule[branch].startDate = null;
          this.selectedEmployeeInfo.basicSchedule[branch].endDate = null;

          this.selectedEmployeeInfo.exception[branch] = new Array();
          this.selectedEmployeeInfo.exceptionAvailable[branch] = new Array();
        }
      });
    },

    checkExceptionEndDate(){ // Check days between start and end of period

      let days = moment(this.exceptionEndDate.firebase).diff(moment(this.exceptionStartDate.firebase), 'days')  
      if(days && days > 90){
        this.feedbackExeption = this.$t("employees.exceptionPeriodToLong", this.$store.state.locale);
        this.exceptionDialog.endDate = moment(this.exceptionStartDate.firebase).add(90,'days').format('YYYY-MM-DD');
      }

      console.log("Period days: ", days)

    },

    checkAddress(){
      if(this.selectedEmployeeInfo.address.country && this.selectedEmployeeInfo.address.postalCode && this.selectedEmployeeInfo.address.number){
        this.addressChecked = false;
        this.addressNotFound = false;
        this.clientAddressChecked = {
          postalCode: _.cloneDeep(this.selectedEmployeeInfo.address.postalCode),
          number: _.cloneDeep(this.selectedEmployeeInfo.address.number )
        };
        let url = `${this.$store.state.locationsApi.url}/getLocation?country=${this.selectedEmployeeInfo.address.country}&postalcode=${this.selectedEmployeeInfo.address.postalCode}&number=${this.selectedEmployeeInfo.address.number}`;
        axios
        .get(url)
        .then((response) => {
          if(response.status == 200 && response.data){
            this.selectedEmployeeInfo.address.city = response.data.city;
            this.selectedEmployeeInfo.address.street = response.data.street;
            this.selectedEmployeeInfo.address.coordinates = response.data.coordinates;
          }
          else{
            this.addressNotFound = true;
          }
        })
        .catch(error => {
          console.error("Postcode API error: ", error);
          this.addressNotFound = true;
        })
        this.addressChecked = true;
      }
    },

    updateUser(type) {
      console.log("updateUser. type, this.userAccount.id: ", type, this.userAccount.id)
      db.collection("users")
      .doc(this.userAccount.id)
      .set({
        registeredDevices: this.userAccount.registeredDevices ? this.userAccount.registeredDevices : new Array(),
      },{ merge: true })
      .then(() => {
        if (type == "updateAndClose") {
          this.editEmployeeDialog = false;
          this.saveDisabled = false;
        }
      })
      .catch((error) => {
        console.error("Error updating user: ", error);
      });
    },

    async updateEmployee(type) {
      // console.log("updateEmployee: ", type) // Check what function the updateEmployee is triggering (currently unused)
      
      this.saveDisabled = true;
      let employeeUnchanged = _.isEqual(this.selectedEmployeeInfo, this.selectedEmployeeInfoOriginal);
      let userUnchanged = _.isEqual(this.userAccount, this.userAccountOriginal);

      if (!userUnchanged) {
        if (!employeeUnchanged || this.imageUrl) { // Only update user
          this.updateUser("update");
        }
        else { // Update user and Close dialog
          this.updateUser("updateAndClose");
        }
      }

      if (!employeeUnchanged || this.newUserAccount) {
        if (this.selectedEmployeeInfo.firstName && this.selectedEmployeeInfo.lastName) {
          if (this.newUserAccount) { // Create New Account
            if (this.employeeUserEmail && this.employeeUserPassword) {
              let existingUsers = 0;
              await db.collection("users")
              .where("email", "==", (this.employeeUserEmail).toLowerCase())
              .get()
              .then((snap) => {
                existingUsers = snap.size
              })
              .catch((error)=>{
                console.error("Error getting users from firebase: ", error)
              })

              if(existingUsers == 0){ // No user found
                const idToken = await firebase.auth().currentUser.getIdToken(true); // Create token for backend verification
                try {
                  let response = await axios.post("https://europe-west1-thrive-365.cloudfunctions.net/auth", { // API Call to create user account
                    token: idToken,
                    type: "createUser",
                    email: this.employeeUserEmail,
                    password: this.employeeUserPassword,
                    sendEmail: this.employeeUserSendEmail,
                    company: this.$store.state.activeCompany.name,
                    language: this.employeeUserLanguage,
                    status: true,
                    name: `${this.selectedEmployeeInfo.firstName} ${this.selectedEmployeeInfo.lastName}`,
                  })
                  this.selectedEmployeeInfo.uid = response.data;
                }
                catch (err) {
                  console.error('Error creating user', err);
                }
                
                await db.collection("users").add({ // Create user document in firebase
                  firstName: this.selectedEmployeeInfo.firstName,
                  lastName: this.selectedEmployeeInfo.lastName,
                  userRole: this.employeeUserRole,
                  user_id: this.selectedEmployeeInfo.uid,
                  language: this.employeeUserLanguage,
                  employeeId: null,
                  accessCompanies: [this.$store.state.activeCompany.id],
                  companyId: this.$store.state.activeCompany.id,
                  email: this.employeeUserEmail,
                  active: true,
                })
                .then(docRef=>{
                  this.selectedEmployeeInfo.userId = docRef.id
                })
                this.newUserAccount = false;
                this.updateEmployeeFunc();
              }
              else{
                this.userAccountFeedback = this.$t("employees.userWithEmailAlreadyExists", this.$store.state.locale);
                this.saveDisabled = false;
              }
             
            }
            else {
              this.userAccountFeedback = this.$t("employees.fieldRequiredForUserAccount", this.$store.state.locale);
              this.saveDisabled = false;
            }
          }
          else {
            this.updateEmployeeFunc(null, null); // Update EmployeeData
          }
        }
        else {
          this.userAccountFeedback = this.$t("employees.fieldRequiredForSaving", this.$store.state.locale);
          this.saveDisabled = false;
        }
      }
    },

    calculateEmployeeLeaveYears(){ // Return selectable years based on starting date of contract
      let currentYear = Number(moment().year());
      if(this.selectedEmployeeInfo.employeeContractStart){
        let startYear = moment(this.selectedEmployeeInfo.employeeContractStart, "YYYY-MM-DD").format("YYYY");
        
        let numberOfYears = moment(currentYear,"YYYY").diff(moment(startYear,"YYYY"), 'years', false) + 1;
        let activeYears = Array(numberOfYears).fill("");

        for (let i = 0; i < activeYears.length; i++) {
          activeYears[i] = currentYear - i;
        }
        this.years = activeYears.reverse()
      }
      else{
        this.years = [currentYear];
      }
    },

    clearContractEndDate() {
      this.selectedEmployeeInfo.employeeContractEnd = null;
    },

    clearContractStartDate() {
      this.selectedEmployeeInfo.employeeContractStart = null;
    },

    clearBirthDate() {
      this.selectedEmployeeInfo.birthday = null;
    },

    async updateEmployeeFunc(UniqueFullpath, UniqueFullpathThumb) {
      let employeePhotoPath = null;
      let employeePhotoThumb = null;

      if (UniqueFullpath) {
        employeePhotoPath = UniqueFullpath;
      }
      if (UniqueFullpathThumb) {
        employeePhotoThumb = UniqueFullpathThumb;
      }

      let basicSchedule = new Object();
      if (this.selectedEmployeeInfo.basicSchedule) {
        basicSchedule = _.cloneDeep(this.selectedEmployeeInfo.basicSchedule);
      }
      if(this.selectedEmployeeInfo.branches && this.selectedEmployeeInfo.branches.length > 0){
        this.selectedEmployeeInfo.branches.forEach((branch) => {
          if (!basicSchedule[branch]) {
            basicSchedule[branch] = new Object();
            basicSchedule[branch].startDate = null;
            basicSchedule[branch].endDate = null;
            basicSchedule[branch].basicScheduleLastChanged = null;
            basicSchedule[branch].Sun = new Array();
            basicSchedule[branch].Mon = new Array();
            basicSchedule[branch].Tue = new Array();
            basicSchedule[branch].Wed = new Array();
            basicSchedule[branch].Thu = new Array();
            basicSchedule[branch].Fri = new Array();
            basicSchedule[branch].Sat = new Array();
          }
        });
      }
      
      
      let docName = "id";
      if (this.source && this.source == "calendar") {
        docName = "docId";
      }
      console.log("updateEmployeeFunc: ", basicSchedule)
      await db.collection("employees")
        .doc(this.selectedEmployeeInfo[docName])
        .set(
          {
            firstName: this.selectedEmployeeInfo.firstName,
            lastName: this.selectedEmployeeInfo.lastName,
            publicName: this.selectedEmployeeInfo.publicName,
            name: this.selectedEmployeeInfo.firstName + " " + this.selectedEmployeeInfo.lastName,
            active: this.selectedEmployeeInfo.active,
            gender: this.selectedEmployeeInfo.gender,
            contractType: this.selectedEmployeeInfo.contractType ? this.selectedEmployeeInfo.contractType: null,
            birthday: this.selectedEmployeeInfo.birthday,
            basicSchedule: basicSchedule,
            defaultHours: this.selectedEmployeeInfo.defaultHours,
            loginAccount: this.selectedEmployeeInfo.loginAccount,
            loginAccountActive: this.selectedEmployeeInfo.loginAccountActive,
            userId: this.selectedEmployeeInfo.userId,
            uid: this.selectedEmployeeInfo.uid,
            address: this.selectedEmployeeInfo.address,
            phones: this.selectedEmployeeInfo.phones,
            emails: this.selectedEmployeeInfo.emails,
            cao: this.selectedEmployeeInfo.cao ? this.selectedEmployeeInfo.cao : null,
            employeeAbilities: this.selectedEmployeeInfo.employeeAbilities,
            deviceTypes: this.selectedEmployeeInfo.deviceTypes,
            branches: this.selectedEmployeeInfo.branches,
            trainee: this.selectedEmployeeInfo.trainee,
            useInWidget: this.selectedEmployeeInfo.useInWidget ? this.selectedEmployeeInfo.useInWidget : false,
            exception: this.selectedEmployeeInfo.exception,
            exceptionAvailable: this.selectedEmployeeInfo.exceptionAvailable,
            employeeContractEnd: this.employeeContractEnd.formatted,
            employeeContractStart: this.employeeContractStart.formatted,
            employeePhoto: this.selectedEmployeeInfo.employeePhoto,
            employeePhotoThumb: employeePhotoThumb
          },
          { merge: true }
        ).then(() => {

          //Clear Cache 
          this.$store.dispatch("clearFetchedResourceCache")
        })
        .catch((error) => {
          console.error("Error updating employee: ", error);

          let logData = {
            superAdminAction: false, // Is action only accessible by superadmin?
            actionType: "userAction",
            action: "employeeUpdated",
            actionItem: `${this.selectedEmployeeInfoOriginal.firstName} ${this.selectedEmployeeInfoOriginal.lastName}`,
            extra:[
              {
                name: "error",
                value: JSON.stringify(error)
              }
            ]
          };
          this.$createNewLog("error", logData);
        })
        

        if (this.existingUserAccount && this.selectedEmployeeInfo.userId) { // Update User Doc with employeeId
          await db.collection("users").doc(this.selectedEmployeeInfo.userId).set({
            employeeId: this.selectedEmployeeInfo[docName],
          },{ merge: true });
        }

        if (!this.selectedEmployeeInfo.loginAccount && this.selectedEmployeeInfoOriginal.loginAccount) { // Update User Doc by removing employeeId              
          await db.collection("users").doc(this.selectedEmployeeInfoOriginal.userId).set({ 
            employeeId: null 
          },{ merge: true });
        }

        if ((this.selectedEmployeeInfo.userId == this.selectedEmployeeInfoOriginal.userId) && this.selectedEmployeeInfoOriginal.loginAccountActive != this.selectedEmployeeInfo.loginAccountActive) {
          await firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((idToken) => {
            if (!this.selectedEmployeeInfoOriginal.loginAccountActive && this.selectedEmployeeInfo.loginAccountActive) {
              axios.post("https://europe-west1-thrive-365.cloudfunctions.net/auth", { // API Call to enable user account
                token: idToken, 
                type: "enableUser",
                uid: this.selectedEmployeeInfo.uid,
                userId: this.selectedEmployeeInfo.userId,
              })
              .then((response) => {},
              (error) => {
                console.error("Error enabling User Account", error);
              });
            }
            else {
              axios.post("https://europe-west1-thrive-365.cloudfunctions.net/auth", { // API Call to disable user account
                token: idToken,
                type: "disableUser",
                uid: this.selectedEmployeeInfo.uid,
                userId: this.selectedEmployeeInfo.userId,
              })
              .then((response) => {},
              (error) => {
                console.error("Error disabling User Account", error);
              });
            }
          })
          .catch((error) => {
            console.error("Error getting ID Token", error);
          });
        }
  
        this.updateData = true;
        this.selectedEmployeeInfoOriginal = _.cloneDeep(this.selectedEmployeeInfo)
        this.saveDisabled = false;
        console.log("Employee succesfully updated");
        this.checkUserAccount();

        let logData = {
          superAdminAction: false, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "employeeUpdated",
          actionItem: `${this.selectedEmployeeInfoOriginal.firstName} ${this.selectedEmployeeInfoOriginal.lastName}`,
          extra:[
            {
              name: "originalData",
              value: ""
            },
            {
              name: "name",
              value: `${this.selectedEmployeeInfoOriginal.firstName} ${this.selectedEmployeeInfoOriginal.lastName}`
            },
            {
              name: "active",
              value: `${this.selectedEmployeeInfoOriginal.active}`
            },
            {
              name: "loginAccount",
              value: `${this.selectedEmployeeInfoOriginal.loginAccount}`
            },
            {
              name: "skills",
              value: JSON.stringify(this.selectedEmployeeInfoOriginal.employeeAbilities)
            },
            {
              name: "branches",
              value: this.selectedEmployeeInfoOriginal.branches
            },
            {
              name: "contractStart",
              value: this.selectedEmployeeInfoOriginal.employeeContractStart
            },
            {
              name: "contractEnd",
              value: this.selectedEmployeeInfoOriginal.employeeContractEnd
            },
            {
              name: "changedData",
              value: ""
            },
            {
              name: "name",
              value: `${this.selectedEmployeeInfo.firstName} ${this.selectedEmployeeInfo.lastName}`
            },
            {
              name: "active",
              value: `${this.selectedEmployeeInfo.active}`
            },
            {
              name: "loginAccount",
              value: `${this.selectedEmployeeInfo.loginAccount}`
            },
            {
              name: "skills",
              value: JSON.stringify(this.selectedEmployeeInfo.employeeAbilities)
            },
            {
              name: "branches",
              value: this.selectedEmployeeInfo.branches
            },
            {
              name: "contractStart",
              value: this.selectedEmployeeInfo.employeeContractStart
            },
            {
              name: "contractEnd",
              value: this.selectedEmployeeInfo.employeeContractEnd
            }
          ]
        };
        this.$createNewLog("info", logData);
        this.imageProgress = null;
    },
  },

  computed: {

    checkNewUserAccountData(){
      if(this.employeeUserPassword && this.employeeUserPassword.length > 5 && this.employeeUserEmail && this.rules.email(this.employeeUserEmail) == true){
        return true;
      }
      else{
        return false;
      }
    },
    // Filter the starthours of new period in new basis schedule
    // Show only available hours
    filteredStartHours(){  
      // Show only available hours
      if(this.newBasicSchedule.schedule[this.basicSchedulePeriod.day] && this.newBasicSchedule.schedule[this.basicSchedulePeriod.day].length > 0){ // Only check if this day had timeblocks
        let hours = new Array();
        this.hours.forEach(hour=>{
          let excluded = false; 
          this.newBasicSchedule.schedule[this.basicSchedulePeriod.day].forEach(block=>{
            if(block.continiousType == this.basicSchedulePeriod.continiousType){
              if(hour >= block.start.substring(0, 2) && hour <= block.end.substring(0, 2)){ // this hours is in existing timeblock

                if(hour >= block.start.substring(0, 2)){ // Hour is equal to start time of block
                  if(block.start.substring(3, 5) == '00' && hour != block.end.substring(0, 2)){ // No time left to select in this hour
                    excluded = true;
                  }
                  if(hour < block.end.substring(0, 2)){ // Exclude hours smaller than endhour of timeblock
                    excluded = true;
                  }
                }
                else{
                  excluded = true;
                }
              }
            }
          })
          if(!excluded){ // Don't push excluded hours as options
            hours.push(hour)
          }
        })
        return hours
      }
      else{
        return this.hours
      }
    },

    // Filter the startminutes of new period in new basis schedule
    // Show only available minutes
    filteredStartMinutes(){ // Show only available start minutes (Based on start hour)
      let minutes = new Array();
      if(this.newBasicSchedule.schedule[this.basicSchedulePeriod.day] && this.newBasicSchedule.schedule[this.basicSchedulePeriod.day].length > 0){ // Only check if this day has timeblocks
        this.minutes.forEach(minute=>{ // Loop trough minutes
          let excluded = false; 
          this.newBasicSchedule.schedule[this.basicSchedulePeriod.day].forEach(block=>{ // Loop through timeblocks
            if(block.continiousType == this.basicSchedulePeriod.continiousType){ // Check if timeblock has the same continiousType (even, odd or all)
              if(this.basicSchedulePeriod.startHour >= block.start.substring(0, 2) && this.basicSchedulePeriod.startHour <= block.end.substring(0, 2)){
                if(this.basicSchedulePeriod.startHour >= block.start.substring(0, 2)){ // Starthour is equal or bigger than starthour of timeblock
                  if(this.basicSchedulePeriod.startHour == block.end.substring(0, 2)){ // Starthour is equal to endhour of timeblock
                    if(block.end.substring(3, 5) > minute){ // Exlude the minutes below the endminutes
                      excluded = true;
                    }
                  }
                  else if(block.start.substring(3, 5) < minute){ // Exlude the minutes above the startminutes
                    excluded = true;
                  }
                }
                else{
                  let endCompare = block.end.substring(3, 5) == '00' ? minute <= '55' : minute < block.end.substring(3, 5); 
                  if(minute > block.start.substring(3, 5) && endCompare){ 
                    excluded = true;
                  }
                }
              }
            }
          })
          if(!excluded){ // Don't push excluded minutes as options
            minutes.push(minute);
          }
        })
        return minutes;
      }
      else{
        return this.minutes;
      }
    },

    // Filter the endhours of new period in new basis schedule
    // Show only available hours
    filteredEndHours(){
      //      Schedule example
      //
      // 09:00 ---------------   
      //      |               |
      // 09:30 --Start time---
      //      |               |
      // 10:00 ---------------  (posible endtime)
      //      |               |
      // 10:30 ---------------  (last posible endtime)
      //      |***************|
      // 11:00|***************|
      //      |**First Block**|
      // 11:30|***************|
      //      |***************|
      // 12:00 ---------------  
      //      |               |
      // 12:30 ---------------  (hours exluded from options)
      //      |***************|
      // 13:00|**Future Block*|  hours exluded from options)
      //      |***************|
      // 13:30 ---------------  (hours exluded from options)
      //      |               |
      // 14:00 ---------------  (hours exluded from options)

      if(this.basicSchedulePeriod.startHour && this.basicSchedulePeriod.startMinutes){ // Check if start time is entered
        let hours = new Array();
        let firstBlockStartTimeHour = null; // The hour of the first timeblock to encounter after starttime 
        let firstBlockStartTimeMinutes = null // The minutes of the first timeblock to encounter after starttime 
        this.hours.forEach(hour=>{ // Loop trough hours
          let excluded = false; 
          if(hour <= this.basicSchedulePeriod.startHour ){ // Check if hour is smaller than selected start hour
            if(hour == this.basicSchedulePeriod.startHour){ // Check if hour is the same as selected start hour
              if(this.basicSchedulePeriod.startMinutes == '55'){ // No time available in this hour (no option)
                excluded = true; 
              }
            }
            else{ // Hour is smaller than selected start hour (no option)
              excluded = true; 
            }
          }
          else{
            if(this.newBasicSchedule.schedule[this.basicSchedulePeriod.day] && this.newBasicSchedule.schedule[this.basicSchedulePeriod.day].length > 0){ // Only check if this day has timeblocks
              this.newBasicSchedule.schedule[this.basicSchedulePeriod.day].forEach(block=>{ // Loop through timeblocks
                if(block.continiousType == this.basicSchedulePeriod.continiousType){ // Check if timeblock has the same continiousType (even, odd or all)
                  let futureBlock = false; // This timeblock comes after the first timeblock to encounter after starttime 

                  if(firstBlockStartTimeHour){ // Check if first block and firstBlockStartTimeHour is found
                    if(hour >= firstBlockStartTimeHour){ // Check if hour is bigger than firstBlockStartTimeHour
                      if(hour > firstBlockStartTimeHour || (hour == firstBlockStartTimeHour && firstBlockStartTimeMinutes == '55')){ // Determine if block is a future block
                        futureBlock = true;
                        excluded = true;
                      }
                    }
                  }

                  if(!futureBlock){ // Nu future block
                    if(hour >= block.start.substring(0, 2) && hour <= block.end.substring(0, 2)){ // hour is in existing timeblock
                      if(!firstBlockStartTimeHour){ // firstBlockStartTimeHour if empty
                        firstBlockStartTimeHour = block.start.substring(0, 2)
                        firstBlockStartTimeMinutes = block.start.substring(3, 5)
                      }
                      if(hour > block.start.substring(0, 2)){
                        excluded = true;
                      }
                    }
                  }
                }
              })
            }
          }
          if(!excluded){ // Don't push excluded hour as options
            hours.push(hour)
          }
        })
        return hours
      }
      else{
        return this.hours;
      }
    },

    // Filter the endhours of new period in new basis schedule
    // Show only available minutes
    filteredEndMinutes(){
      let minutes = new Array();
      if(this.basicSchedulePeriod.startHour && this.basicSchedulePeriod.endHour){ // Check if start time is entered
        if(this.newBasicSchedule.schedule[this.basicSchedulePeriod.day] && this.newBasicSchedule.schedule[this.basicSchedulePeriod.day].length > 0){ // Only check if this day had timeblocks
          this.minutes.forEach(minute=>{ // Loop trough minutes
            let excluded = false; 
            this.newBasicSchedule.schedule[this.basicSchedulePeriod.day].forEach(block=>{ // Loop through timeblocks
              if(block.continiousType == this.basicSchedulePeriod.continiousType){ // Check if timeblock has the same continiousType (even, odd or all)
                if(this.basicSchedulePeriod.endHour >= block.start.substring(0, 2) && this.basicSchedulePeriod.endHour <= block.end.substring(0, 2)){ // Check if endhour is in timeblock

                  if(this.basicSchedulePeriod.endHour == block.start.substring(0, 2)){ // Endhour equals starthour of timeblock
                    if(this.basicSchedulePeriod.endHour != this.basicSchedulePeriod.startHour ){
                      if(block.start.substring(3, 5) < minute){ // Exlude the minutes above the startminutes
                        excluded = true;
                      }
                    } 
                  }
                  if(this.basicSchedulePeriod.endHour == this.basicSchedulePeriod.startHour && this.basicSchedulePeriod.startMinutes >= minute){ // Check if endhour and starthour are equal en exlude the minutes below the startminutes
                    excluded = true;
                  }
                }
              }
            })
            if(!excluded){ // Don't push excluded minutes as options
              minutes.push(minute);
            }
          })
          return minutes;
        }
        else{
          return this.minutes;
        }
      }
      else{
        return this.minutes;
      }
    },

    availableLanguages(){
      let availableLanguages = this.$countries.filter(language => { // Filter rich languages data from company languages
        if(this.$store.state.activeCompany.languages.includes(language.id)){ return language.id }
      });
      // Show languages in the same order as company languages
      return availableLanguages.sort((a, b) => this.$store.state.activeCompany.languages.indexOf(a.id) - this.$store.state.activeCompany.languages.indexOf(b.id)); 
    },

    searchUserDisabled() {
        if(this.searchEmail == this.searchEmailOriginal) {
          return true;
        }
        else {
          return false;
        }
      },
    
    exceptionEndMaxDate(){
        if(this.exceptionDialog.startDate){
          return moment(this.exceptionDialog.startDate, "YYYY-MM-DD").endOf("year").format("YYYY-MM-DD");
        }
        else{
          return null;
        }
      },

      exceptionMinDate(){
        if(this.$store.state.userRoleNumber < 2){
          return moment().subtract(1, 'year').format("YYYY-MM-DD");
        }
        else{
          return moment().format("YYYY-MM-DD");
        }
        
      },

      presenceStartDate() {
        let date = {
          readable: null,
          formatted: null,
          firebase: null,
        };
        if (this.presenceDialog.startDate != null) {
          date.readable = moment(this.presenceDialog.startDate).format("D-M-YYYY");
          date.formatted = moment(this.presenceDialog.startDate).format("YYYY-MM-DD");
          date.firebase = moment(this.presenceDialog.startDate).format("YYYY-MM-DD");
          return date;
        } else {
          return date;
        }
      },

      exceptionStartDate() {
        let date = {
          readable: null,
          formatted: null,
          firebase: null,
        };
        if (this.exceptionDialog.startDate != null) {
          date.readable = moment(this.exceptionDialog.startDate).format("D-M-YYYY");
          date.formatted = moment(this.exceptionDialog.startDate).format("YYYY-MM-DD");
          date.firebase = moment(this.exceptionDialog.startDate).format("YYYY-MM-DD");
          return date;
        } else {
          return date;
        }
      },

      exceptionEndDate() {
        let date = {
          readable: null,
          formatted: null,
          firebase: null,
        };

        if (this.exceptionDialog.endDate != null) {
          date.readable = moment(this.exceptionDialog.endDate).format("D-M-YYYY");
          date.formatted = moment(this.exceptionDialog.endDate).format("YYYY-MM-DD");
          date.firebase = moment(this.exceptionDialog.endDate).format("YYYY-MM-DD");
          return date;
        } else {
          return date;
        }
      },

      newBasicScheduleStartDate() {
        let date = {
          readable: null,
          formatted: null,
          firebase: null,
          changed: false,
        };
        if (this.newBasicSchedule.startDate != null && this.newBasicSchedule.startDate.length > 0) {
          date.readable = moment(this.newBasicSchedule.startDate).format("D MMMM YYYY");
          date.formatted = moment(this.newBasicSchedule.startDate).format("YYYY/MM/DD");
          date.firebase = moment(this.newBasicSchedule.startDate).format("YYYY-MM-DD");
          date.changed = true;
          return date;
        }
        else {
          return date;
        }
      },

      scheduleStartDate() {
        let date = {
          readable: null,
          formatted: null,
          firebase: null,
          changed: false,
        };
        if (this.scheduleStartDateRaw != null && this.scheduleStartDateRaw.length > 0) {
          date.readable = moment(this.scheduleStartDateRaw).format("D MMMM YYYY");
          date.formatted = moment(this.scheduleStartDateRaw).format("YYYY/MM/DD");
          date.firebase = moment(this.scheduleStartDateRaw).format("YYYY-MM-DD");
          date.changed = true;
          return date;
        }
        else if (this.selectedBranch && this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].startDate) {
          date.readable = moment(this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].startDate, "YYYY-MM-DD").format("D MMMM YYYY");
          date.formatted = moment(this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].startDate, "YYYY-MM-DD").format("YYYY/MM/DD");
          date.firebase = moment(this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].startDate, "YYYY-MM-DD").format("YYYY-MM-DD");
          date.changed = false;
          return date;
        }
        else {
          return date;
        }
      },

      scheduleEndDate() {
        let date = {
          readable: null,
          formatted: null,
          firebase: null,
          changed: false,
        };
        if (this.scheduleEndDateRaw != null && this.scheduleEndDateRaw.length > 0) {
          date.readable = moment(this.scheduleEndDateRaw).format("D MMMM YYYY");
          date.formatted = moment(this.scheduleEndDateRaw).format("YYYY/MM/DD");
          date.firebase = moment(this.scheduleEndDateRaw).format("YYYY-MM-DD");
          date.changed = true;
          return date;
        }
        else if (this.selectedBranch && this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].endDate) {
          date.readable = moment(this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].endDate, "YYYY-MM-DD").format("D MMMM YYYY");
          date.formatted = moment(this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].endDate, "YYYY-MM-DD").format("YYYY/MM/DD");
          date.firebase = moment(this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].endDate, "YYYY-MM-DD").format("YYYY-MM-DD");
          date.changed = false;
          return date;
        }
        else {
          return date;
        }
      },

      exceptionFuture() {
        if (this.exeptionDisplay == "future") {
          return true;
        } else {
          return false;
        }
      },

      presenceFuture() {
        if (this.presenceDisplay == "future") {
          return true;
        } else {
          return false;
        }
      },

      exeptionStartDateLabel() {
        if (this.exceptionDialog.period) {
          return{
            en: "Startdate",
            nl: "Startdatum"
          };
        }
        else {
          return{
            en: "Date",
            nl: "Datum"
          };
        }
      },

      employeeBranch() {
        return (id) => _.find(this.branches, ["id", id]);
      },



    version() {
      return this.$store.state.version;
    },  

    companyName(){
      return this.$store.state.companyName;
    },

    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    companySubscription() {
      return this.$store.state.companySubscription;
    },
    companyPackages() {
      return this.$store.state.companyPackages;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },

    companyTheme() {
      return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
    },

    birthdayDate(){
      let date = {
        readable: "",
        formatted: "",
        firebase: null
      }
      if(this.birthdayDateRaw){
        date.readable = moment(this.birthdayDateRaw).format("D MMMM YYYY");
        date.formatted = moment(this.birthdayDateRaw).format("YYYY/MM/DD");
        date.firebase = moment(this.birthdayDateRaw).format("DDMMYYYY");
        return date
      }
      else if(this.selectedEmployeeInfo.birthday){
        date.readable = moment(this.selectedEmployeeInfo.birthday, "DDMMYYYY").format("D MMMM YYYY");
        date.formatted = moment(this.selectedEmployeeInfo.birthday, "DDMMYYYY").format("YYYY/MM/DD");
        date.firebase = moment(this.selectedEmployeeInfo.birthday, "DDMMYYYY").format("DDMMYYYY");
        return date
      }  
      else {
        return date
      }
    },

    // Datum omvormen naar Europese leesbare datum
    addedDateFormatted() {
      return this.formatDate(this.date);
    },
    // Datum omvormen om te gebruiken in Firebase
    firebaseDateFormatted() {
      return new Date(this.date);
    },

    employeeBranches(){
      let activeBranches = this.branches.filter(branch => this.selectedEmployeeInfo.branches.includes(branch.id));
      return activeBranches
    },

    changed() {
      this.changes
      if (_.isEqual(this.selectedEmployeeInfo, this.selectedEmployeeInfoOriginal) == false) {
        return true;
      } else {
        return false;
      }
    },

    basicScheduleChanged(){
      let startDateChanged =  this.selectedEmployeeInfo.basicSchedule[this.selectedBranch] && this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].startDate == this.scheduleStartDateRaw  ? false : true;
      let endDateChanged =  this.selectedEmployeeInfo.basicSchedule[this.selectedBranch] && this.selectedEmployeeInfo.basicSchedule[this.selectedBranch].endDate == this.scheduleEndDateRaw  ? false : true;

      return (startDateChanged || endDateChanged) ? true : false;
    },


    newBasicScheduleChanged(){
      return _.isEqual(this.newBasicScheduleOriginal.schedule, this.newBasicSchedule.schedule) ? false : true;
    },
       
    addressChanged(){
      let clientAddress = { postalCode: this.selectedEmployeeInfo.address.postalCode, number: this.selectedEmployeeInfo.address.number}
      let clientAddressChecked = this.clientAddressChecked;
      return _.isEqual(clientAddress, clientAddressChecked) ? false : true;
    },

    employeeContractStart() {
      let date = {
        readable: null,
        formatted: "",
      };
      if (this.selectedEmployeeInfo.employeeContractStart) {
        date.readable = moment(this.selectedEmployeeInfo.employeeContractStart, "YYYY-MM-DD").format("D MMMM YYYY");
        date.formatted = moment(this.selectedEmployeeInfo.employeeContractStart, "YYYY-MM-DD").format("YYYY-MM-DD");
        return date;
      } else {
        return date;
      }
    },

    employeeContractEnd() {
      let date = {
        readable: null,
        formatted: "noEndDate",
      };
      if (this.selectedEmployeeInfo.employeeContractEnd && this.selectedEmployeeInfo.employeeContractEnd != "noEndDate") {
        date.readable = moment(this.selectedEmployeeInfo.employeeContractEnd, "YYYY-MM-DD").format("D MMMM YYYY");
        date.formatted = moment(this.selectedEmployeeInfo.employeeContractEnd, "YYYY-MM-DD").format("YYYY-MM-DD");
        return date;
      } else {
        return date;
      }
    },
    branchUpForRemoval(){
      if((_.isEqual(this.selectedEmployeeInfo.branches, this.selectedEmployeeInfoOriginal.branches) == false) && this.selectedEmployeeInfo.branches.length <= this.selectedEmployeeInfoOriginal.branches.length){
        return true;
      }
      else{
        return false;
      }
    },

      employeeBirthDate() {
        let date = {
          readable: null,
          formatted: "",
        };
        if (this.selectedEmployeeInfo.birthday) {
          date.readable = moment(this.selectedEmployeeInfo.birthday, "YYYY-MM-DD").format("D MMMM YYYY");
          date.formatted = moment(this.selectedEmployeeInfo.birthday, "YYYY-MM-DD").format("YYYY-MM-DD");
          return date;
        } else {
          return date;
        }
      },

      filteredAbilities() {
        if (this.searchAbilities && this.searchAbilities.length > 0) {
          return this.abilities.filter((ability) => {
            return ability.ability.toLowerCase().includes(this.searchAbilities.toLowerCase());
          });
        } else {
          return this.abilities;
        }
      },

      filteredDeviceTypes() {
        if (this.searchDeviceTypes && this.searchDeviceTypes.length > 0) {
          return this.deviceTypes.filter((device) => {
            return device.name[(this.$store.state.locale).toLowerCase()].toLowerCase().includes(this.searchDeviceTypes.toLowerCase());
          });
        } else {
          return this.deviceTypes;
        }
      },
  }
};
</script>

<style>
.schedule-overview-day{
  color: var(--text-color);;
  font-size: 12px;
}
.schedule-overview-item{
  display: inline-block;
  float: left;
  background-color: var(--primary-color);
  padding: 1px 4px;
  margin: 2px;
  border-radius: 3px;
  color: var(--card-color);;
  font-size: 11px;
}
.schedule-overview-item.type-pause{
  background-color: var(--separator-color);
  color: var(--text-color);
}

.day-of-week.new-schedule{
  border: 2px solid var(--card-color)
}

.day-of-week.new-schedule.alternately{
  border: none;
  padding: 2px;
  color: var(--primary-color);
  position: relative;
}

.day-of-week.new-schedule.alternately:before{
  opacity: 0.30;
  bottom: 0;
  content: "";
  left: 0;
  border: 2px solid var(--primary-color);
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
}


.row-disabled{
  pointer-events: none;
  /* background-color: red */
}
.vue__time-picker.time-picker{
  width: 100%;
}
.vue__time-picker.time-picker input{
  width: 100%;
  border-radius: 5px;  
  height: 40px;
  border-color: var(--separator-color);
}
.vue__time-picker.time-picker input:active,
.vue__time-picker.time-picker input:focus{
  border-width: 2px;
  border-color: var(--primary-color);
}

.vue__time-picker.time-picker ul.hours li.active,
.vue__time-picker.time-picker ul.hours li.active:hover,
.vue__time-picker.time-picker ul.minutes li.active,
.vue__time-picker.time-picker ul.minutes li.active:hover{
  background-color: var(--primary-color);
}

.vue__time-picker.time-picker .char{
  font-size: 20px!important;
}
.tag-wrapper {
  padding: 5px 5px 5px 10px;
}

.tag-wrapper:after {
  clear: both;
  content: "";
  display: block;
}
.tag-id {
  padding: 5px 0;
  width: calc(100% - 40px);
  float: left;
}
.tag-chip {
  color: #fff !important;
  font-weight: bold;
}

.tag-action {
  width: 40px;
  float: left;
}
.pincode-number {
  border: 2px solid var(--primary-color);
  background-color: var(--cardOption-color);
  color: var(--primary-color);
  font-size: 28px;
  padding: 20px 10px;
  border-radius: 10px;
}

.current-branch-schedule-wrapper{
  width: 100%
}

.current-branch-schedule-wrapper.shared{
  width: 75%;
  float: left;
  z-index: 9;
  position: relative;
}
.current-branch-schedule-wrapper.shared .grouplesson{
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.2)
}

.grouplesson.extra{
  background: repeating-linear-gradient(
    45deg,
    rgba(0,0,0,0),
    rgba(0,0,0,0) 8px,
    rgba(0,0,0,0.1) 8px,
    rgba(0,0,0,0.1) 16px
  );
}

.different-branch-schedule-wrapper{
  display: none;
  width: 25%;
  float: left;
  z-index: 5;
  position: relative;
}

.different-branch-schedule-wrapper.full{
  width: 100%;
}  

.different-branch-schedule-wrapper.exist{
  display: block;
}  

.different-branch-schedule-wrapper.full .different-branch-schedule-wrapper-content{
  margin: 0!important
}

.different-branch-schedule-wrapper-content{
  margin: 0 0 0 -25px
}

</style>
