<template>
   <v-dialog v-model="isOpen" width="500">
      <v-card> 
         <v-card-title> 
            Connect To Web USB
         </v-card-title>
         <v-card-text> 
            <v-list> 
               <v-list-item v-for="device in devices" :key="device.productId">
                  <v-list-item-title >
                     {{ device.productName }}
                  </v-list-item-title>
               </v-list-item>
            </v-list>
            <v-btn color="primary" @click="connectToNFCReader">
               Connect to NFC Reader
            </v-btn>
         </v-card-text>
      </v-card>
   </v-dialog>
</template>

<script>
export default {
   name: "connect-to-web-usb",
   props: {
      value: {
         type: Boolean,
         required: true,
      },
   },
   data() {
      return {
         devices: [], // Array to store connected devices
      };
   },
   computed: {
      /**
       * Computed property to sync the dialog's open state with the parent component.
       */
      isOpen: {
         get() {
            return this.value;
         },
         set(newValue) {
            this.$emit('input', newValue);
         },
      },
   },
   methods: {
      /**
       * Method to request access to the NFC reader via WebUSB.
       * This method is triggered when the user clicks the "Connect to NFC Reader" button.
       */
      async connectToNFCReader() {
         try {
            // Replace '0xXXXX' with the vendorId of your NFC reader device.
            const filters = [{ vendorId: 0x072f }];

            /**
             * Request the user to select a USB device that matches the specified filters.
             * This will prompt the browser's device picker.
             */
            const device = await navigator.usb.requestDevice({ filters });

            // Open a connection to the selected device.
            await device.open();

            // Select the device's configuration. Often 1 if there's only one configuration.
            await device.selectConfiguration(1);
            console.log('Configuratie geselecteerd:', device.configuration);

            console.log("Klaar met selectConfiguration");

            console.log("device Informatie", device);

            await this.claimInterface(device);

            // Claim the interface for communication. Replace '0' with the correct interface number.
            // await device.claimInterface(0);

            //          await device.claimInterface(0)
            //             .then(() => {
            //                console.log("Interface succesvol geclaimed");
            //             })
            //             .catch((error) => {
            //                console.error('Fout bij claimen van interface:', error);
            //             });

           
            //             console.log("device Informatie 2", device);

            // // Add the connected device to the list of devices.
            // this.devices.push(device);

            // console.log("Klaar met Claimen");

            // /**
            //  * Implement the logic to communicate with the NFC reader.
            //  * This may involve sending control transfers or bulk transfers to read data.
            //  */
            // await this.readFromNFCReader(device);
         } catch (error) {
            console.error('Error connecting to NFC reader:', error);
         }

         
      },

      async claimInterface(device) {
         console.log("Start claimInterface");
            try {
               for (let i = 0; i < device.configuration.interfaces.length; i++) {
                  console.log(`Proberen interface ${i} te claimen...`);
                  await device.claimInterface(i);
                  console.log(`Interface ${i} succesvol geclaimed`);
               }
            } catch (error) {
               console.error('Fout bij het claimen van een interface:', error);
            }
         },

      /**
       * Method to read data from the connected NFC reader.
       * @param {USBDevice} device - The connected USB device representing the NFC reader.
       */
      async readFromNFCReader(device) {
         try {
            console.log("Start Scanning ");

            // Example: Send a control transfer to the device.
            await device.controlTransferOut({
               requestType: 'vendor',
               recipient: 'device',
               request: 0x01, // Specific to the device's protocol.
               value: 0x0000,
               index: 0x0000,
            });

            // Example: Read data from the device.
            const result = await device.transferIn(1, 64); // Endpoint number and length.

            if (result.data && result.data.byteLength > 0) {
               // Process the received data.
               const decoder = new TextDecoder();
               const data = decoder.decode(result.data);
               console.log('Received data from NFC reader:', data);
            }
         } catch (error) {
            console.error('Error reading from NFC reader:', error);
         }
      },

      /**
       * Method to close the modal dialog.
       */
      closeModal() {
         this.$emit('input', false);
      },
   },
};
</script>
