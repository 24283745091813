<template>
  <div>
    <v-dialog v-model="showDialog" eager fullscreen hide-overlay scrollable transition="dialog-bottom-transition" content-class="custom-shape custom-dialog square">
			<v-card v-show="selectedMembership">
				<v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].card }">
					<h3>{{ selectedMembership.name[$store.state.nativeLanguage] }}</h3>
					<v-spacer></v-spacer>
					<v-btn icon dark @click="closeMembershipDialog()">
						<v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
					</v-btn>
				</v-card-title>

				<v-divider></v-divider>

				<v-card-text class="pa-4 ma-0" style="position: relative" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].background }">
					<membership-information :source="'addMembershipDialog'""></membership-information>
				</v-card-text>
			</v-card>
		</v-dialog>
  </div>
</template>

<script>
  import { bus } from "../main";
  import membershipInformation from "@/views/membershipInformation.vue";

  export default {
    name: "MembershipsInformationDialog",

    components:{
      "membership-information": membershipInformation,
    },

    data(){
      return{
        showDialog: false,
        selectedMembership: {
          name: new Object()
        }
      }
    },

    async created(){
      bus.$on("openMembershipInformationDialog", (selectedMembership) => { 
        this.selectedMembership = {...selectedMembership};
        bus.$emit("openMembershipInformation", selectedMembership);
        this.showDialog = true;
      })
    },

    methods:{
      closeMembershipDialog(){
        this.showDialog = false;
        this.selectedMembership = {
          name: new Object()
        };
        bus.$emit("closeMembershipInformation"); // Disable editing
      },
    }
  };
</script>