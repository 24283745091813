<template>
  <div>
    <div v-if="loading">
      <div style="position: relative; padding: 200px 0;">
        <div class="thrive-loader"></div>
      </div>
    </div>

    <v-container grid-list-md class="ma-0 pa-0" v-show="!loading">
      <v-layout row wrap v-if="treatments.length > 0">
        <v-flex xs3 style="max-width: 280px;">
          <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme" v-show="treatments.length > 0">
            <v-text-field
              v-model="search"
              outlined
              dense
              clearable
              hide-details
              background-color="background"  
              name="searchfield"
              :label=" $t('general.search', $store.state.locale)"
              prepend-inner-icon="mdi-magnify"
              single-line
              class="ma-2"
              @click="filterTreatmentView(null, 'search')"
              @click:clear="search = '', filterTreatmentView(null, 'search')"
              @input="filterTreatmentView(null,'search')"
            ></v-text-field>

            <v-divider></v-divider>

            <v-select
              :items="treatmentBranches"
              v-model="treatmentBranchFilter"
              :label="$t('general.branches', $store.state.locale)"
              item-text="name"
              item-value="id"
              required
              dense
              hide-details
              outlined
              class="ma-2"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              background-color="background"
              @change="filterTreatmentView(treatmentFilterId, treatmentFilterType)"
            ></v-select> 

            <!-- / Custom Treatment Categories \ -->
            <v-list class="pa-0 ma-0" dense>
              <v-divider></v-divider>
              <div class="clear pa-0" style="height: 40px">
                <v-subheader class="py-0" style="display: inline-block; height: 40px!important; line-height: 40px">{{ $t('treatments.customCategories', $store.state.locale) }}</v-subheader>
                <div style="float: right;">  
                  <v-icon class="pa-0 mt-2 ma-1" size="20" v-if="!editCustomCategories" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="setEditCustomCategories()">mdi-pencil</v-icon>      
                  <v-icon class="pa-0 mt-2 ma-1" size="20" v-if="editCustomCategories" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="setEditCustomCategories()">mdi-pencil-off</v-icon>
                  <v-icon class="pa-0 mt-2 ma-1" size="26" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addCustomTreatmentType()">mdi-plus-circle</v-icon>                
                </div>
              </div>
              
              <div v-if="sortedCustomTreatmentTypes && sortedCustomTreatmentTypes.length > 0">
                <v-divider></v-divider>
                <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('all')" @click="filterTreatmentView('all', 'custom')">
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('general.all', $store.state.locale) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <div v-for="(treatmentType, index) in sortedCustomTreatmentTypes" :key="index">
                  <v-divider></v-divider>
                  <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView(treatmentType.id)" class="pr-0">
                    <v-list-item-content  @click="filterTreatmentView(treatmentType.id, 'custom')">
                      <v-list-item-title>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, treatmentType.name) }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="pa-0 ma-0" v-if="editCustomCategories">
                      <div>
                        <v-btn icon>
                          <v-icon small @click="moveUp(index)" :disabled="index === 0">mdi-chevron-up</v-icon>
                        </v-btn>
                        <v-btn icon>
                          <v-icon small @click="moveDown(index)" :disabled="index === sortedCustomTreatmentTypes.length - 1">mdi-chevron-down</v-icon>
                        </v-btn>
                        <v-btn icon>
                          <v-icon small @click="editCustomTreatmentType(treatmentType)">mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn icon>
                          <v-icon small @click="showDeleteDialog(treatmentType.id, 'customCategory', 'deleteGroup')">mdi-delete</v-icon>
                        </v-btn>
                      </div>
                    </v-list-item-action>
                  </v-list-item>
                </div>
              </div>
            </v-list>

            <!-- \ Custom Treatment Categories / -->

            <!-- / General Treatment Categories \ -->
            <v-list class="pa-0 ma-0" dense>
              <v-divider></v-divider>
              <v-subheader>{{ $t('indicator.categories', $store.state.locale) }}</v-subheader>
              <v-divider></v-divider>
              <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView('all')" @click="filterTreatmentView('all', 'general')">
                <v-list-item-content>
                  <v-list-item-title>{{ $t('general.all', $store.state.locale) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div v-for="(treatmentType, index) in treatmentTypesFiltered" :key="index">
                <v-divider></v-divider>
                <v-list-item :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :input-value="selectedView(treatmentType.id)" @click="filterTreatmentView(treatmentType.id, 'general')">
                  <v-list-item-content>
                    <v-list-item-title>{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, treatmentType.name) }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </div>
              <v-divider></v-divider>
              <div class="pa-3">
                <v-btn dark height="40" style="text-overflow: ellipsis;" depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="addTreatment()" block class="ma-0">
                  <v-icon left>mdi-plus</v-icon> {{ $t('general.new', $store.state.locale) }} 
                </v-btn>
              </div>
            </v-list>
            <!-- \ General Treatment Categories / -->

          </v-card>
         </v-flex>

         <v-flex xs9 style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">
          <v-card flat v-if="treatmentsFiltered.length > 0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
            <v-container grid-list-md class=" ma-0 pa-0" fluid>
              <v-layout row wrap>
                <v-flex md3 xs12 class="text-right"></v-flex>
                <v-flex md6 xs12 class="text-right">
                 <p v-if="treatmentsFiltered && treatmentsFiltered.length > 1" class="text-center py-3 ma-0" style="text-transform: lowercase;">{{ treatmentsFiltered.length }} {{ $t('general.treatments', $store.state.locale) }}</p>
                 <p v-if="treatmentsFiltered && treatmentsFiltered.length == 1" class="text-center py-3 ma-0" style="text-transform: lowercase;">{{ treatmentsFiltered.length }} {{ $t('treatments.headers.treatment', $store.state.locale) }}</p>
                  <div v-if="pageCount > 1" class="text-center pb-2">
                    <v-pagination :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :total-visible="9" v-model="treatmentPage" :length="pageCount"  @input="selectTreatmentPage()"></v-pagination>
                  </div>
                </v-flex>
                <v-flex md3 xs12 class="text-right align-center">
                  <div class="d-flex justify-end align-center">
                    <v-btn
                    v-if="selected.length > 0"
                      class="ma-2" 
                      outlined
                      depressed
                      dark
                      height="30px"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" 
                      @click="showDeleteDialog(null, 'treatments','deleteTreatments')"
                      >
                      {{ $t('general.remove', $store.state.locale)}}
                    </v-btn>
                    <v-btn
                      v-if="selected.length == 1"
                      dark
                      fab
                      x-small
                      outlined
                      class="ma-2"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                      @click="duplicateTreatment()"
                      
                      >
                      <v-icon size="18" dark>mdi-content-copy</v-icon>
                    </v-btn>
                  </div>
                </v-flex>
              </v-layout>
            </v-container>

            <v-divider></v-divider>
            
            <v-data-table
              v-if="treatmentsFiltered.length > 0"
              :headers="headers"
              :items="treatmentsFiltered"
              v-model="selected"
              :page.sync="treatmentPage"
              :sort-by="['treatmentTitle']"
              hide-default-footer
              :itemsPerPage.sync="pages"
              :no-results-text="`${$t('treatments.noResultsFoundFor', $store.state.locale)} ${search}`"
              :style="{'background-color' : $themes[$store.state.companyTheme][$store.state.themeModus].card}"
              dense
              class="dense-table"
              >
              <template v-slot:item="{item, isSelected, select, index}">
                <tr>
                  <td>
                    <v-icon @click="select(true)" v-if="!isSelected">mdi-checkbox-blank-outline</v-icon>
                    <v-icon @click="select(false)" v-if="isSelected" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-checkbox-marked</v-icon>
                  </td>
                  <td>            
                    {{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, item.treatmentTitleIntern) }}
                    <div>
                      <v-chip v-if="!$store.state.demoMode && $store.state.userRoleNumber == 0" dark label small  light class="mr-2 mb-1 custom-chip-style" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="copyVariableToClipboard( item.id, index )">
                        <span v-if="!item.copy">{{ item.id }}</span><span v-else>{{ $t('emails.copied', $store.state.locale)}}</span><v-icon v-if="!item.copy" right size="12">mdi-content-copy</v-icon>
                      </v-chip>
                    </div>
                  </td>
                  <td>
                    <span v-for="treatmentType in treatmentTypes" :key="treatmentType.id">
                      <span v-if="item.treatmentType == treatmentType.id">{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, treatmentType.name )}}</span>
                    </span>
                    <v-chip small :color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].tertiary" light class="mx-1 my-1 custom-chip-style" v-if="item.eventBooking">{{ $t('treatments.subscription', $store.state.locale) }}</v-chip>
                  </td>
                  <td>{{ item.treatmentTime}}min</td>
                  <td class="text-right">{{ getTreatmentPrice(item.treatmentPrice, item.branchPriceAdjustments, item.vat) }} </td>
                  <td class="text-right">{{ getVatPrecentage(item.vat) }}</td>
                  <!-- <td>
                    <span v-if="item.devices && item.devices.length > 0">
                      <span v-for="(device, index) in item.devices" :key="index">
                        <span v-for="(deviceType, index) in deviceTypes" :key="index">
                          <v-chip small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mr-2 my-1 custom-chip-style" v-if="deviceType.deviceId == device">{{ deviceType.deviceName }}</v-chip>
                        </span>
                      </span>
                    </span>
                    <span v-else>-</span>
                  </td> -->
                  <td class="text-center">
                    <v-icon class="px-2" :color="activeColor(item.treatmentActive)" small>mdi-checkbox-blank-circle</v-icon>
                  </td>
                  <td class="text-center">
                    <v-icon class="px-2" :color="activeColor(item.treatmentOnline)" small>mdi-checkbox-blank-circle</v-icon>
                  </td>
                  <td>
                    <v-btn icon class="mx-0" @click="shortcutLink(item)">
                      <v-icon small  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-link</v-icon>
                    </v-btn>
                  </td>
                  <td>
                    <v-btn icon class="mx-0" @click="editTreatment(item)">
                      <v-icon small  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>

          <div class="no-data-message" v-if="treatmentsFiltered.length == 0 && (treatmentFilterActive == 'search' || treatmentFilterType == 'custom')">
            <v-avatar
              size="58"
              light
              class="ma-0 mr-2 custom-avatar-style" 
              rounded="lg"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
              :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
              >
              <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-clipboard-text-outline</v-icon>
            </v-avatar>
            <p v-if="treatmentFilterActive == 'search'"> {{ $t('treatments.noResultsFoundFor', $store.state.locale) }} <i>{{ search }}</i>.</p>
            <p v-if="treatmentFilterType == 'custom'"> {{ $t('booking.noTreatments', $store.state.locale) }}.</p>
            </div>
         </v-flex>
      </v-layout>

      <div class="no-data-message" v-else>
        <v-avatar
          size="58"
          light
          class="ma-0 mr-2 custom-avatar-style" 
          rounded="lg"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
          >
          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-clipboard-text-outline</v-icon>
        </v-avatar>
        <p>{{ $t('treatments.noTreatmentsFoundFor', $store.state.locale) }} {{ $store.state.activeCompany.name }}.</p>
        <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addTreatment()">
          <v-icon left>mdi-plus</v-icon> {{ $t('treatments.newTreatment', $store.state.locale) }} 
        </v-btn>
      </div>
    </v-container>

    <!-- / Edit Treatment Dialog \ -->
      <treatment-form
        :employees="employees"
        :vatItems="vatItems"
        :rooms="rooms"
        :customTreatmentTypes="customTreatmentTypesAll"
        :products="products"
        :productCategories="productCategories"
        :organizedTreatmentTypes="organizedTreatmentTypes"
        :subtreatmentTypes="subtreatmentTypes"
        :subtreatmentTypeCategories="subtreatmentTypeCategories"
        :deviceTypes="deviceTypes"
        :branches="branches"
        :abilities="abilities"
        :devices="devices"
        :occupationTypes="occupationTypes"
        :appointmentTimeIterations="appointmentTimeIterations"
        :bookingTimeLimitItems="bookingTimeLimitItems"
        :colors="colors"
        :discountTypes="discountTypes"
        :genders="genders"
        @editTreatmentDialogChange="editTreatmentDialog = $event"
        :treatmentTypes="treatmentTypes"
        @updateData="loadTreatments()"
        
      ></treatment-form>

    <!-- \ Edit Treatment Dialog / -->

    <!-- / Custom Treatment Type Dialog \ -->
    <custom-treatment-type-form @updateData="loadCustomTreatmentTypes()"></custom-treatment-type-form>
    <!-- \ Custom Treatment Type Dialog / -->

    <!-- / Campgain Link Dialog \ -->
    <v-dialog v-model="treatmentShortcut.dialog" persistent scrollable max-width="750px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" >
        <v-card-title class="pr-2" :style=" { backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t('treatments.shortcutUrlFor', $store.state.locale) }} {{  $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, treatmentShortcut.treatmentTitle ) }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="treatmentShortcut.dialog = false">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
        <v-divider></v-divider>

        <v-card-text class="pa-0 ma-0">
            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex md12>
                  <v-select
                    :items="treatmentShortcut.branches"
                    v-model="treatmentShortcut.branch"
                    :label="$t('conversion.selectBranch', $store.state.locale)" 
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    clearable
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    background-color="background"
                    dense
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    class="my-2"
                    dense
                    background-color="background"
                    :items="languages"
                    prepend-inner-icon="mdi-flag"
                    item-text="name"
                    item-value="code"
                    v-model="treatmentShortcut.lang"
                    :label="$t('conversion.selectLanguage', $store.state.locale)"  
                    hide-details
                    >
                  </v-select>
                </v-flex>
                <v-flex md12>
                  <v-select
                    :label="$t('conversion.selectSource', $store.state.locale)"  
                    outlined
                    dense
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                    class="my-2"
                    background-color="background"
                    :items="allConversionItems"
                    prepend-inner-icon="mdi-forward"
                    item-text="name"
                    item-value="query"
                    v-model="treatmentShortcut.source"
                    hide-details
                    clearable
                  >
                  </v-select>
                </v-flex>
                <v-flex md12>

                <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption" class="pa-2" style="font-style: italic;"> {{ websiteUrl.url}}</v-card>
              </v-flex>
              </v-layout>
            </v-container>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">

              <v-flex xs12 class="text-right">
                <v-btn v-if="!treatmentShortcut.copy" dark block :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  @click="copyTextToClipboard(websiteUrl.url, null)"><v-icon left small color="#fff">mdi-content-copy</v-icon>{{ $t('general.copyUrl', $store.state.locale) }}</v-btn>
                <div style="min-height: 36px" v-if="treatmentShortcut.copy" >
                  <div style="display: block" class="copy-tag-url copied ma-0 pt-2" ><v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-check-bold</v-icon> {{ $t('general.copied', $store.state.locale) }}</div>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- \ Campgain Link Dialog / -->

  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import db from "@/firebase/init";
import { bus } from "@/main";
import TreatmentForm from "@/components/modals/TreatmentForm.vue";
import CustomTreatmentTypeForm from "@/components/modals/CustomTreatmentTypeForm.vue";
import algoliasearch from "algoliasearch/lite";

export default {
  components: {
    "treatment-form": TreatmentForm,
    "custom-treatment-type-form": CustomTreatmentTypeForm
  },
  name: "Treatments",
   data(){
    return{
      algoSearch: algoliasearch("B4XU2Z5BAW", "a29d0e9160e36dc8ee83d5888c28cdcc"),
      newTreatmentDialog: false,
      editTreatmentDialog: false,
      branches: new Array(),
      devices: new Array(),
      pages: 40,
      companyUniqueDeviceTypes: new Array(),
      deviceTypes: new Array(),
      abilities: new Array(),
      employees: new Array(),
      treatments: new Array(),
      products: new Array(),
      productCategories: new Array(),
      vatItems: new Array(),
      treatmentTypes: new Array(),
      subtreatmentTypes: new Array(),
      subtreatmentTypeCategories: new Array(),
      organizedTreatmentTypes: new Array(),
      loading: true,
      rooms: new Array(),
      search: "",
      selected: new Array(),
      treatmentsFiltered: new Array(),
      treatmentFilterActive: 'all',
      treatmentFilterType: "none",
      treatmentFilterId: null,
      treatmentShortcut: {
        lang: 'nl',
        dialog: false,
        copy: false,
        branch: null,
        branches: new Array(),
        treatment: null,
        source: null,
      },
      editCustomCategories: false,
      treatmentPage: 1,
      customTreatmentTypes: new Array(),
      changedCustomTreatmentTypes: new Set(),
      customTreatmentTypesAll: new Array(),
      treatmentBranchFilter: 'all',
      generalTags: [
        {
          name: 'Adwords',
          query: 'adw',
          copy: false,
          color: '#1a73e8',
          textColor: '#fff'
        },
        {
          name: 'Mailchimp',
          query: 'mc',
          copy: false,
          color: '#ffe01b',
          textColor: '#333'
        }
      ],
      
      pagination: {
        sortBy: "treatmentTitle",
        rowsPerPage: -1,
        descending: false
      },
      genders: [
        {
          id: "female",
          name: this.$t('general.females', this.$store.state.locale) 
        },
        {
          id: "male",
          name: this.$t('general.males', this.$store.state.locale) 
        },
        {
          id: "unisex",
          name: this.$t('general.unisex', this.$store.state.locale) 
        }
      ],
      headers: [
      {
          text: "",
          align: "left",
          value: "edit",
          width: "60px",
          sortable: false,
        },
        {
          text: this.$t('treatments.headers.treatment', this.$store.state.locale),
          align: "left",
          sortable: true,
          value: `treatmentTitle[${this.$store.state.nativeLanguage}]`,
          sortable: false,
        },
        {
          text: this.$t('general.type', this.$store.state.locale),
          align: "left",
          value: "treatmentType"
        },
        {
          text: this.$t('general.time', this.$store.state.locale),
          align: "left",
          value: "treatmentTime",
          width: "80px",
          sortable: false,
        },
        {
          text: this.$t('general.price', this.$store.state.locale),
          align: "right",
          value: "treatmentPrice",
          width: "150px"
        },
        {
          text: this.$t('general.vat', this.$store.state.locale),
          align: "right",
          value: "treatmentPrice",
          width: "50px",
          sortable: false,
        },
        // {
        //   text: this.$t('treatments.headers.deviceTypes', this.$store.state.locale),
        //   align: "left",
        //   value: "devices",
        //   sortable: false
        // },
        {
          text: this.$t('general.active', this.$store.state.locale),
          align: "center",
          value: "active",
          width: "80px"
        },
        {
          text: this.$t('treatments.headers.online', this.$store.state.locale),
          align: "center",
          value: "online",
          width: "80px"
        },
        {
          text: "",
          align: "left",
          value: `treatmentTitleIntern[${this.$store.state.nativeLanguage}]`,
          sortable: false,
          width: "40px"
        },
        {
          text: "",
          align: "left",
          value: "edit",
          width: "60px",
          sortable: false,
        }
      ],
      discountTypes: [
        {
          label: this.$t('general.percentage', this.$store.state.locale),
          id: "percentage"
        },
        {
          label:  this.$t('general.amount', this.$store.state.locale),
          id: "amount"
        }
      ],
      colors: [
        "#72D9C4",
        "#ABDEE6",
        "#83C0EC",
        "#BC8DD0",
        "#F39488",
        "#F8DD65",
        "#E89861",
        "#61C1AD",
        "#D7E2EA",
        "#ECEAE4",  
      ],
      languages: [
        {
          name: "Nederlands",
          code: "nl"
        },
        {
          name: "Engels",
          code: "en"
        }
      ],
      bookingTimeLimitItems: [
        {
          name: this.$t('treatments.none', this.$store.state.locale),
          value: null
        },
        {
          name: '15 ' + this.$t('general.minutes', this.$store.state.locale),
          value: 15
        },
        {
          name: '30 ' + this.$t('general.minutes', this.$store.state.locale),
          value: 30
        },
        {
          name: '1' + this.$t('general.hour', this.$store.state.locale),
          value: 60
        },
        {
          name: '2 ' + this.$t('general.hours', this.$store.state.locale),
          value: 120
        },
        {
          name: '3 ' + this.$t('general.hours', this.$store.state.locale),
          value: 180
        },
        {
          name: '6 ' + this.$t('general.hours', this.$store.state.locale),
          value: 360
        },
        {
          name: '12 ' + this.$t('general.hours', this.$store.state.locale),
          value: 720
        },
        {
          name: '24 ' + this.$t('general.hours', this.$store.state.locale),
          value: 1440
        }
      ],

      appointmentTimeIterations: [
        {
          name: "15 " + this.$t('general.minutes', this.$store.state.locale),
          time: 15
        },
        {
          name: "30 " + this.$t('general.minutes', this.$store.state.locale),
          time: 30
        },
        {
          name: "45 " + this.$t('general.minutes', this.$store.state.locale),
          time: 45
        },
        {
          name: "60 " + this.$t('general.minutes', this.$store.state.locale),
          time: 60
        },
      ],

      occupationTypes: [
        {
          name:  this.$t('general.employee', this.$store.state.locale),
          id: 1
        },
        {
          name:  this.$t('general.device', this.$store.state.locale),
          id: 2
        },
        {
          name:  this.$t('treatments.employeeDevice', this.$store.state.locale),
          id: 3
        },
        {
          name:  this.$t('treatments.employeeOverlap', this.$store.state.locale),
          id: 4
        },
        {
          name:  this.$t('treatments.employeeRoom', this.$store.state.locale),
          id: 5
        },
        {
          name:  this.$t('general.room', this.$store.state.locale),
          id: 6
        },
      ],
    }
  },

  created() {
    bus.$on("companyUpdate", this.loadData);
    bus.$on("deleteGroup", (id) => {this.deleteGroup(id) });
    bus.$on("deleteTreatments", this.deleteTreatments);
    this.loadData();
  },

  methods: {
    loadData() {
      Promise.all([
        this.loadTreatments(), 
        this.loadBranches(), 
        this.loadAbilities(),
        this.loadTreatmentTypes(),
        this.loadSubtreatmentTypes(),
        this.loadSubtreatmentTypeCategories(),
        this.loadEmployees(),
        this.loadDevices(),
        this.loadVat(),
        this.loadCustomTreatmentTypes()
      ])
      .then(() => {
        this.organizeTreatmentTypes();
        this.loadProducts();
        this.loadProductCategories();
        this.loading = false;
      })
    },

    addTreatment(){ 
      bus.$emit("treatmentModal", null, 'new'); 
    },

    getTreatmentPrice(price, branchPriceAdjustments, vat){ // Show the Brutoprices of the treatment
      let vatData = this.vatItems.find(vatItem=> vatItem.id == vat);
      let allPrices = new Array();
      let prices = new Array();
      let orginalPrice = vatData ? new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR", minimumFractionDigits: 2}).format((price * ((vatData.percentage / 100)+1))) : ""; 
      
      allPrices.push(orginalPrice)

      if(branchPriceAdjustments && Object.keys(branchPriceAdjustments).length > 0){ // There are price adjustments for branches
        for (const [key, value] of Object.entries(branchPriceAdjustments)) {
          if(branchPriceAdjustments[key].adjustmentType != 'noChanges'){
            let adjustedPrice = vatData ? new Intl.NumberFormat("nl-NL", { style: "currency", currency: "EUR", minimumFractionDigits: 2}).format(((branchPriceAdjustments[key].totalAmountNetto) * ((vatData.percentage / 100)+1))) : ""; 
            allPrices.push(adjustedPrice)
          }

        }
      }

      allPrices.sort((a, b) => a.localeCompare(b)); // Sort prices from low to high

      if(allPrices.length > 0){ // Return only one or two prices
        if(allPrices.length > 1){
          prices.push(allPrices[0])
          prices.push(allPrices[allPrices.length - 1])
        }
        else{
          prices = [...allPrices]
        }
        return prices.join(' - ')
      }
      else{
        return "";
      }
    },

    getVatPrecentage(vat){
      let vatData = this.vatItems.find(vatItem=> vatItem.id == vat)
      return vatData ? `${vatData.percentage}%` : ""; 
    },

    setEditCustomCategories(){
      this.editCustomCategories = this.editCustomCategories ? false : true;
    },

    showDeleteDialog(id, type, deleteFunction){
      let deleteInformation = new Object()
      deleteInformation.dialog = true;
      deleteInformation.id = id;
      deleteInformation.deleting = false;
      deleteInformation.type = type;
      deleteInformation.emit = deleteFunction;

      if(type == 'customCategory'){
        deleteInformation.title = this.$t('treatments.removeCustomCategory', this.$store.state.locale);
        deleteInformation.info = this.$t('treatments.removeCustomCategoryInfo', this.$store.state.locale);
      }
      if(type == 'treatments'){
        if(this.selected.length > 1){
          deleteInformation.title = this.$t('treatments.removeTreatments', this.$store.state.locale);
          deleteInformation.info = this.$t('treatments.removeTreatmentsInfo', this.$store.state.locale);
        }
        else{
          deleteInformation.title = this.$t('treatments.removeTreatment', this.$store.state.locale);
          deleteInformation.info = this.$t('treatments.removeTreatmentInfo', this.$store.state.locale);
        }
      }

      bus.$emit('deletedModal', deleteInformation, 'open');
    },

    async deleteGroup(id){
      await db.collection("companies")
      .doc(this.$store.state.activeCompany.id)
      .collection("customTreatmentTypes")
      .doc(id)
      .set({
        deleted: {
          deleted: true,
          deletedOn: moment().format("YYYY-MM-DDTHH:mm"),
          deletedBy: this.$store.state.activeUserId
        }
      },{merge: true})

      this.loadCustomTreatmentTypes();
      bus.$emit('deletedModal', null, 'close');
    },

    async loadTreatments() {

      // this.clientPage = page ? page : 1;
      // let index =  this.algoSearch.initIndex("thrive_treatments");
      // var FacetFilterString = `companyId:${this.activeCompData.id}`;
      // await index.search("", {
      //   page: page ? page -1 : 0,
      //   facetFilters: [FacetFilterString],
      // },)
      // .then(({ hits, nbHits }) => {
      //   this.treatments = new Array();
      //   this.treatmentsCount = nbHits
      //   this.pageCount = Math.ceil(nbHits / 50)
      //   hits.forEach((treatment) => {
      //     this.treatments.push(treatment)
      //   });
      // });
      // this.treatmentsFiltered = _.cloneDeep(this.treatments)
      // return;

      
      await db.collection("treatments")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .orderBy("modified", "desc")
      .get()
      .then(snap => {
        this.treatments = new Array();
        snap.forEach(doc => {
          let treatment = doc.data();
          treatment.id = doc.id;
          treatment.copy = false;
          treatment.devices = new Array();
          if(treatment.subTreatments.length > 0){
            treatment.subTreatments.forEach(subtreament =>{
              if(subtreament.deviceId){
                treatment.devices.push(subtreament.deviceId);
              }
            })
          }
          if(!treatment.deleted || !treatment.deleted){
            this.treatments.push(treatment);
          }
        });
      })
      
      this.treatmentsFiltered = _.cloneDeep(this.treatments)
      this.treatmentsFiltered.sort((a, b) => this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, a.treatmentTitleIntern).localeCompare(this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, b.treatmentTitleIntern)));
      this.filterTreatmentView(this.treatmentFilterId,this.treatmentFilterType)
      return;
    },

    async loadBranches() {
      await db.collection("branches")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .get()
      .then(snap => {
        this.branches = new Array();
        snap.forEach(doc => {
          let branch = doc.data();
          branch.id = doc.id;
          this.branches.push(branch);
        });
      })
      await this.loadRooms();
      return;
    },

    activeColor(active){ // Set color green for active or red for inactive
      return active ? "#81C784" : "#EF5350"; 
    },

    loadProducts(){
      db.collection("products")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .get()
      .then(snap => {
        this.products = new Array();
        snap.forEach(doc => {
          let product = doc.data();
          product.id = doc.id;
          this.products.push(product);
        });
      })
    }, 

    loadProductCategories(){
      db.collection("productCategories")
      .where("companies", "array-contains", this.$store.state.activeCompany.id)
      .get()
      .then(snap => {
        this.productCategories = new Array();
        snap.forEach(doc => {
          let productCategory = doc.data();
          productCategory.id = doc.id;
          this.productCategories.push(productCategory);
        });
      })
    },

    async loadRooms() {
     await db.collection("rooms")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("resourceDeleted", "==", false)
      .get()
      .then(snap => {
        this.rooms = new Array();
        snap.forEach(doc => {
          let room = doc.data();
          room.id = doc.id;
          let branchName = null;
          let branchObject = _.find(this.branches, { 'id': doc.data().branches[0] });
          if(branchObject){
            branchName = " (" + branchObject.name + ")"
          }
          room.name = doc.data().name + branchName;
          room.data = {
            branch: doc.data().branches[0],
            id: doc.id
          };
          this.rooms.push(room);
        });
      })
      return      
    },
    
    async loadAbilities() {
      await db.collection("abilities")
      .get()
      .then(snap => {
        this.abilities = new Array();
        snap.forEach(doc => {
          let ability = doc.data();
          ability.id = doc.id;
          this.abilities.push(ability);
        });
      })
      return    
    },

    async loadCustomTreatmentTypes() {
      this.customTreatmentTypes = new Array();
      this.customTreatmentTypesAll = new Array();
      await db.collection("companies")
      .doc(this.$store.state.activeCompany.id)
      .collection("customTreatmentTypes")
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let group = doc.data();
          group.id = doc.id;
          group.order = doc.data().order ? doc.data().order : 0
          group.disabled = doc.data().deleted && doc.data().deleted.deleted ? true : false
          this.customTreatmentTypesAll.push(group);
          if(!group.disabled ){
            this.customTreatmentTypes.push(group);
          }
          
        });
      })
      this.initializeOrder();
      return  
    },

    selectTreatmentPage(){ // Old function to load through Algolia
      // this.loadTreatments(this.treatmentPage)
    },

    async filterTreatmentView(id, type){
      this.loading = true;
      this.treatmentPage = 1;
      this.treatmentFilterId = id;
      this.treatmentFilterType = type;
      
      if(id == "all"){
        this.search = "";
        this.treatmentFilterActive = 'all';
        let treatmentsBranchFiltered = this.treatmentBranchFilter == 'all' ? _.cloneDeep(this.treatments) :  _.cloneDeep(this.treatments.filter(item => { return item.branches.includes(this.treatmentBranchFilter)}));

        this.treatmentsFiltered = _.cloneDeep(treatmentsBranchFiltered);
      }
      else{
        if(type == 'search'){
          if(this.search && this.search.length > 0 || this.search && this.treatmentBranchFilter != 'all'){
            this.treatmentFilterActive = 'search';
            this.treatmentsFiltered = this.treatments.filter(treatment => {

              let treatmentTitle = false;
              let treatmentTitleIntern = false;
              let treatmentTitleReciept = false;
              let treatmentCategory = false;
              let treatmentCustomCategory = false;
              let treatmentMinutes = false;
              let treatmentPrice = false;
              let treatmentBranch = false;
              let treatmentBranchesFilter = false;

              if(treatment.treatmentTitle){
                for (const [key, value] of Object.entries(treatment.treatmentTitle)) {
                  if(value.toLowerCase().includes(this.search.toLowerCase())){
                    treatmentTitle = true;
                  }
                }
              }

              this.$store.state.companyBranches.forEach(branch => {
                if((branch.name.toLowerCase()).includes(this.search.toLowerCase()) && branch.active){
                  let activeBranches = treatment.branches.find((treatmentBranch) => { return branch.id == treatmentBranch})
                  if(activeBranches != undefined){
                    treatmentBranch = true
                  }
                }
              });
            

              // console.log("this.treatmentBranchFilter: ", this.treatmentBranchFilter)
              if(this.treatmentBranchFilter == 'all'){
                treatmentBranchesFilter = true;
              }
              else{
             
                  let activeBranches = treatment.branches.find((treatmentBranch) => { return treatmentBranch == this.treatmentBranchFilter})
                  console.log("activeBranches: ", activeBranches)
                  if(activeBranches != undefined){
                    treatmentBranchesFilter = true
                  }            
         
              }

              // console.log("this.treatmentBranchFilter: ", treatmentBranchesFilter)

              if(treatment.treatmentTime){
                if((String(treatment.treatmentTime)+'min').includes(this.search.toLowerCase())){
                  treatmentMinutes = true;
                }
              }

              if(treatment.treatmentTitleIntern){
                for (const [key, value] of Object.entries(treatment.treatmentTitleIntern)) {
                  if(value.toLowerCase().includes(this.search.toLowerCase())){
                    treatmentTitleIntern = true;
                  }
                }
              }
              
              if(treatment.treatmentTitleReciept){
                for (const [key, value] of Object.entries(treatment.treatmentTitleReciept)) {
                  if(value.toLowerCase().includes(this.search.toLowerCase())){
                    treatmentTitleReciept = true;
                  }
                }
              }

              this.treatmentTypes.forEach(type=>{
                if(treatment.treatmentType == type.id){
                  for (const [key, value] of Object.entries(type.name)) {
                    if(value.toLowerCase().includes(this.search.toLowerCase())){
                      treatmentCategory = true;
                    }
                  }
                }
              })

              this.customTreatmentTypes.forEach(type=>{
                if(treatment.customTreatmentType == type.id){
                  if(type.name.toLowerCase().includes(this.search.toLowerCase())){
                    treatmentCustomCategory = true
                  }
                }
              })

              if((treatmentTitle || treatmentTitleIntern ||  treatmentTitleReciept || treatmentCategory || treatmentCustomCategory || treatmentMinutes || treatmentPrice || treatmentBranch) && treatmentBranchesFilter){
                return treatment
              }
            });
            this.treatmentsFiltered.sort((a, b) => this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, a.treatmentTitleIntern).localeCompare(this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, b.treatmentTitleIntern)));

          }
          else{
            let treatmentsBranchFiltered = this.treatmentBranchFilter == 'all' ? _.cloneDeep(this.treatments) :  _.cloneDeep(this.treatments.filter(item => { return item.branches.includes(this.treatmentBranchFilter)}));
            this.treatmentsFiltered = treatmentsBranchFiltered.sort((a, b) => this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, a.treatmentTitleIntern).localeCompare(this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, b.treatmentTitleIntern)));
          }
        }
        if(type == 'general'){
          this.search = "";
 
          this.treatmentFilterActive = id;
          let treatmentsBranchFiltered = this.treatmentBranchFilter == 'all' ? _.cloneDeep(this.treatments) :  _.cloneDeep(this.treatments.filter(item => { return item.branches.includes(this.treatmentBranchFilter)}));

          this.treatmentsFiltered = await treatmentsBranchFiltered.filter(item => { return item.treatmentType == id});
          this.treatmentsFiltered.sort((a, b) => this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, a.treatmentTitleIntern).localeCompare(this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, b.treatmentTitleIntern)));

        }
        if(type == 'custom'){
 
          this.treatmentFilterActive = id;
          this.search = "";
          let treatmentsBranchFiltered = this.treatmentBranchFilter == 'all' ? _.cloneDeep(this.treatments) :  _.cloneDeep(this.treatments.filter(item => { return item.branches.includes(this.treatmentBranchFilter)}));
          this.treatmentsFiltered = await treatmentsBranchFiltered.filter(item => { return item.customTreatmentType == id});
          this.treatmentsFiltered.sort((a, b) => this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, a.treatmentTitleIntern).localeCompare(this.$autoLanguageSelector(this.$store.state.locale, this.$store.state.companyLanguages, b.treatmentTitleIntern)));

        }
      }
      this.loading = false;
    },

    async loadTreatmentTypes() {
      await db.collection("treatmentTypes")
      .get()
      .then(snap => {
        this.treatmentTypes = new Array();
        snap.forEach(doc => {
          let treatmentType = doc.data();
          treatmentType.id = doc.id;
          this.treatmentTypes.push(treatmentType);
        });
      })
      return
    },
    async loadSubtreatmentTypes() {
      await db.collection("subtreatmentTypes")
      .get()
      .then(snap => { 
        this.subtreatmentTypes = new Array();
        snap.forEach(doc => {
          let subtreatmentType = doc.data();
          subtreatmentType.id = doc.id;
          this.subtreatmentTypes.push(subtreatmentType);
        });
      })
      return
    },

    async loadSubtreatmentTypeCategories() {
      await db.collection("subtreatmentTypeCategories")
      .get()
      .then(snap => {
        this.subtreatmentTypeCategories = new Array();
        snap.forEach(doc => {
          let subtreatmentTypeCategory = doc.data();
          subtreatmentTypeCategory.id = doc.id;
          this.subtreatmentTypeCategories.push(subtreatmentTypeCategory);
        });
      })
      return;
    },

    async loadEmployees() {
      await db.collection("employees")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("deleted.deleted", "==", false)
      .get()
      .then(snap => {
        this.employees = new Array();
        snap.forEach(doc => {
          let employee = doc.data();
          employee.id = doc.id;
          this.employees.push(employee);
        });
      })
      return;
    },

    async loadDevices() {
      this.devices = new Array();
      await db.collection("devices")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let device = doc.data();
          device.id = doc.id;
          this.devices.push(device);
        });
      })

      await this.loadDeviceTypes();
      return;
    },

    async loadDeviceTypes() {
      this.deviceTypes = new Array();
      await db.collection("deviceTypes")
      .get()
      .then(snap => {
        snap.forEach(doc => {
          if(this.devices.find(device => device.deviceTypes.includes(doc.id))){
            let deviceType = doc.data();
            deviceType.id = doc.id;
            this.deviceTypes.push(deviceType)
          }
        });
      })
      return;  
    },

    getUsedDevice(allDeviceTypes) {
      this.deviceTypes = new Array();
      allDeviceTypes.forEach(device => {
        let included = _.includes(
          this.companyUniqueDeviceTypes,
          device.deviceId
        );
        if (included == true) {
          let deviceType = new Object();
          deviceType.deviceId = device.deviceId;
          deviceType.deviceName = device.deviceName;
          this.deviceTypes.push(deviceType);
        }
      });
    },

    async loadVat() {
      this.vatItems = new Array();
      await db.collection("vat")
      .orderBy('percentage')
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let vatItem = doc.data();
          vatItem.id = doc.id;
          vatItem.summary = doc.data().percentage + "%";
          this.vatItems.push(vatItem);
        });
      })
      return;
    },

    copyTextToClipboard(text, index) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }

      navigator.clipboard.writeText(text).then(()=> {
        this.treatmentShortcut.copy = true
        setTimeout(()=>{ 
          this.treatmentShortcut.copy = false
        }, 1000);

      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },

    shortcutLink(item){
      this.treatmentShortcut.treatmentTitle = item.treatmentTitle;
      this.treatmentShortcut.branches = this.branches.filter((branch)=> { return _.some(item.branches, (seletedItem)=> { return seletedItem == branch.id }) ? branch : null; });  
      this.treatmentShortcut.branch = item.branches.length > 0 ? item.branches[0] : null;
      this.treatmentShortcut.treatment = item.id;
      this.treatmentShortcut.dialog = true;
    },

    organizeTreatmentTypes(){
      let vm = this
      this.organizedTreatmentTypes = new Array();
      this.subtreatmentTypeCategories.forEach(category=>{
        let categoryItems = new Array();
        this.subtreatmentTypes.forEach(type=>{
          if(type.category == category.id){
            categoryItems.push(type);
          }
        })
        if(categoryItems.length > 0){
          categoryItems = _.sortBy(categoryItems, [function(o) { return o.name[vm.$store.state.locale]; }]);
          category.types = categoryItems;
          this.organizedTreatmentTypes.push(category)
        }
      })

      this.organizedTreatmentTypes = _.sortBy(this.organizedTreatmentTypes, [function(o) { return o.name[vm.$store.state.locale]; }]);
    },

    async editTreatment(item) {
      
      let treatment = {};
      treatment = _.cloneDeep(item);
      treatment.vatPercentage = 0;
      treatment.changed = false;
      
      if(treatment.vat){
        let counter = 0
        await new Promise((resolve)=>{
          this.vatItems.forEach(vatItem=>{
            if(vatItem.id == item.vat){
                treatment.vatPercentage = (vatItem.percentage / 100) + 1
            }
            counter++
            if(counter == this.vatItems.length){
              resolve()
            }
          })
        })
        .then(()=>{
          let counter = 0 
          new Promise(resolve=>{
            treatment.subTreatments.forEach((subTreatment, index)=>{
              treatment.subTreatments[index].priceBruto = Number((treatment.subTreatments[index].priceNetto * treatment.vatPercentage).toFixed(2))
              counter++
              if(counter == treatment.subTreatments.length){
                resolve()
              }
            })
          })
          .then(()=>{
            // Prijzen van subtreatments optellen met reduce()
            treatment.nettoPrice = Number(treatment.subTreatments.reduce((acc, subtreatment) =>  acc + subtreatment.priceNetto, 0).toFixed(2))
            treatment.brutoPrice = (treatment.nettoPrice  * treatment.vatPercentage).toFixed(2)
          })
        })
      } 
      else {
        treatment = _.cloneDeep(item);
      }
      bus.$emit("treatmentModal", treatment, 'edit');
    },

    addCustomTreatmentType(){
      bus.$emit("customTreatmentTypeModal", null, 'new');
    },

    editCustomTreatmentType(item){
      bus.$emit("customTreatmentTypeModal", item, 'edit');
    },

    initializeOrder() {
      // Check of er items zijn met order 999 en update de order waarden
      const maxOrder = Math.max(...this.customTreatmentTypes.map(item => item.order));
      let nextOrder = maxOrder === 999 ? this.customTreatmentTypes.length : maxOrder + 1;

      this.customTreatmentTypes.forEach(item => {
        if (item.order === 0 || item.order === 999) {
          this.$set(item, 'order', nextOrder++);
        }
      });
      this.saveOrder(this.customTreatmentTypes);
    },

    moveUp(index) {
      if (index > 0) {
        this.swapOrder(index, index - 1);
        this.saveOrder([this.customTreatmentTypes[index], this.customTreatmentTypes[index - 1]]);
      }
    },
    moveDown(index) {
      if (index < this.customTreatmentTypes.length - 1) {
        this.swapOrder(index, index + 1);
        this.saveOrder([this.customTreatmentTypes[index], this.customTreatmentTypes[index + 1]]);
      }
    },
    swapOrder(index1, index2) {
      const item1 = this.customTreatmentTypes[index1];
      const item2 = this.customTreatmentTypes[index2];

      this.$set(this.customTreatmentTypes, index1, {
        ...item1,
        order: item2.order,
      });
      this.$set(this.customTreatmentTypes, index2, {
        ...item2,
        order: item1.order,
      });

      // Voeg de gewijzigde items toe aan de Set
      this.changedCustomTreatmentTypes.add(item1.id);
      this.changedCustomTreatmentTypes.add(item2.id);
    },
    saveOrder(items) {

      if(items && items.length > 0){
        items.forEach((item)=>{
          db.collection("companies")
          .doc(this.$store.state.activeCompany.id)
          .collection("customTreatmentTypes")
          .doc(item.id)
          .set({
            order: item.order
          },{merge: true})
        })
      }
    },

    async duplicateTreatment() {
      // this.selected.forEach(async (element) => {
      //   try {
      //     //Duplicate treatment with firebase by making a clone
      //     let doc = await db.collection("treatments").doc(element.id).get();
      //     if (doc.exists) {
      //       let treatment = doc.data();
      //       //check language
      //       if (treatment.treatmentTitle[this.$store.state.locale.toLowerCase()]) {
      //         treatment.treatmentTitle[this.$store.state.locale.toLowerCase()] = treatment.treatmentTitle[this.$store.state.locale.toLowerCase()] + ' (copy)';
      //         treatment.treatmentTitleIntern[this.$store.state.locale.toLowerCase()] = treatment.treatmentTitleIntern[this.$store.state.locale.toLowerCase()] + ' (copy)';
      //       } else {
      //         treatment.treatmentTitle[this.$store.state.locale] = treatment.treatmentTitle["en"] + " (copy)";
      //         treatment.treatmentTitleIntern["en"] = treatment.treatmentTitleIntern["en"] + " (copy)";
      //       }
      //       treatment.modified = new Date();
                      
         
      //       await db.collection("treatments").add(treatment).then((docRef) => {
      //         console.log("Treatment duplicated", docRef.id);
      //       })
      //       this.loadTreatments();
      //     }
      //   } catch (error) {
      //     console.error("Error duplicating treatment: ", error);
      //   }
      // });
      bus.$emit("treatmentModal", this.selected[0], "clone");
      this.selected = new Array();
    },

   async deleteTreatments() {
    await new Promise((resolve)=>{
      let counter = 0;
      this.selected.forEach(element => {
        db.collection("treatments")
          .doc(element.id)
          .set({
            deleted: {
              deleted: true,
              deletedOn: moment().format("YYYY-MM-DDTHH:mm"),
              deletedBy: this.$store.state.activeUserId
            }
          },{merge: true})
          .then(()=>{
            counter++
            if(counter == this.selected.length){
              resolve()
            }
          })
        });
      })
      this.selected = new Array();
      this.loadTreatments();
      bus.$emit('deletedModal', null, 'close');
    },

    copyVariableToClipboard(text, index){
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(()=> {
        this.treatments.forEach(treatment =>{
          treatment.copy = false;
        })
        this.treatments[index].copy = true;
        setTimeout(()=>{ 
          this.treatments[index].copy = false;
        }, 1000);

      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    }
  },

  computed: {

    treatmentBranches(){
      let branches = [{
        id: 'all',
        name: this.$t('general.all', this.$store.state.locale)
      }]

      branches = branches.concat(this.$store.state.companyBranches);
      return branches
    },

    pageCount(){
      return Math.ceil(this.treatmentsFiltered.length / 40)
    },

    sortedCustomTreatmentTypes() {
      return this.customTreatmentTypes.sort((a, b) => a.order - b.order);
    },

    treatmentTypesFiltered(){
      let treatmentTypes = new Array();
      this.treatmentTypes.forEach(type=>{
        let treatmentsOfType = this.treatments.filter(item => {return item.treatmentType == type.id })
        if(treatmentsOfType && treatmentsOfType.length > 0){
          treatmentTypes.push(type)
        }
      })
      return treatmentTypes
    },  

    selectedView(){
      return id => {
        if(this.treatmentFilterActive == id){
          return true;
        }
        else {
          return false;
        }
      }
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    websiteUrl(){
      let url = {
        enable: false,
        url: 'leeg'
      }
      let lang = ""
      let branch = ""
      let src = ""

      let compUrl = this.$store.state.activeCompany.url + '#thriveWidget/?comp=' + this.$store.state.activeCompany.id

      if(this.treatmentShortcut.lang){
        lang = '&lang=' + this.treatmentShortcut.lang;
      }

      if(this.treatmentShortcut.source){
        src = '&src=' + this.treatmentShortcut.source;
      }

      if(this.treatmentShortcut.branch){
        branch = '&branch=' + this.treatmentShortcut.branch + "&type=dialog";
      }

      let campaign = '&act=shortcut&trm=' + this.treatmentShortcut.treatment;

      url.url = compUrl + branch + campaign + lang + src;

      return url
      // http://localhost:8081/?comp=hogansbeauty&branch=hogansbeautybreukelen&act=3&lid=m1SFIHNbGRFS9tn6h3xA&lang=nl
      // http://localhost:8081/?comp=hogansbeauty&branch=hogansbeautybreukelen&act=3&lid=m1SFIHNbGRFS9tn6h3xA
    },

    allConversionItems(){
      let items = _.cloneDeep(this.generalTags)
   
      if(this.$store.state.activeCompany.conversion && this.$store.state.activeCompany.conversion.sources.length > 0){
         this.$store.state.activeCompany.conversion.sources.forEach(source=>{
           items.push(source)
         })
      } 
      return items
    },
  }
};
</script>
