<template>
  <div>
    <v-dialog v-model="showDialog" scrollable max-width="600px" persistent height="300px">
			<v-card v-if="selectedMembershipInformationInformation">
				
				<v-card-text class="pa-2 ma-0 mb-2">
					<div class="text-center">
						<v-avatar 
							size="56"
							light
							class="ma-4 custom-avatar-style" 
							rounded="lg"
							:color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
							:style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
							>
							<v-icon large :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-check-bold</v-icon>
						</v-avatar> 
						<div class="text-center px-4">
							<h3 style="font-size: 20px;">{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, selectedMembershipInformationInformation.name) }}</h3>
							<p v-if="$autoLanguageSelector($store.state.locale, $store.state.companyLanguages, selectedMembershipInformationInformation.posDescription) != 'No name'">{{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, selectedMembershipInformationInformation.posDescription) }}</p>
						</div>
					</div>

					<!-- / General information \ -->
					<div class="pa-3 mb-4">
						<h4>{{ $t('leadCampaign.general', $store.state.locale) }}</h4>
						<v-divider class="ma-0 pa-0 pt-3 mt-3"></v-divider>

						<!-- / Branches \ -->
						<v-row align="center">
							<v-col cols="4" sd="8" class="py-1" style="font-size: 12px"><strong>{{ $t('general.branches', $store.state.locale) }}:</strong></v-col>
							<v-col cols="4" sd="8" class="py-1" style="font-size: 12px">
								<span v-if="selectedMembershipInformationInformation.branches.all">{{ $t('products.allBranches', $store.state.locale) }}</span>
								<span v-else>
								<v-chip v-for="(branch, index) in selectedMembershipInformationInformation.branches.branches" small block label :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"light class="mr-2 my-0 custom-chip-style" >{{ getBranchName(branch) }}</v-chip>
								</span>
							</v-col>
						</v-row>
						<!-- \ Branches / -->

						<!-- / End \ -->
						<!-- <v-row align="center">
							<v-col cols="6" sd="6" class="py-1"><strong>{{ $t('general.end', $store.state.locale) }}:</strong></v-col>
							<v-col cols="6" sd="6" class="py-1">
							<div>
								<div v-if="selectedMembershipInformationInformation.status.endDate">{{ membershipEndDate.readable }}</div>
								<div v-else>{{ $t('tasks.noDate', $store.state.locale) }} 
									<v-chip small block label color="#56ca00" text-color="#56ca00" light class="ml-1 ma-0 px-1 custom-chip-style" >{{ $t('memberships.autoRenewShort', $store.state.locale) }}</v-chip>
								</div>
							</div>
							</v-col>
						</v-row> -->
						<!-- \ End / -->

						<!-- / Duration \ -->
						<v-row align="center">
							<v-col cols="4" sd="8" class="py-1" style="font-size: 12px"><strong>{{ $t('payment.duration', $store.state.locale) }}:</strong></v-col>
							<v-col cols="8" sd="8" class="py-1" style="font-size: 12px">
								<div style="font-size: 12px">{{ selectedMembershipInformationInformation.duration }} {{  durationSuffix(selectedMembershipInformationInformation) }} <v-chip small block label color="#56ca00" text-color="#56ca00" light class="ml-1 ma-0 px-1 custom-chip-style" >{{ $t('memberships.autoExpireShort', $store.state.locale) }}</v-chip></div>
							</v-col>
						</v-row>
						<!-- \ Duration / -->

						<!-- / Periode \ -->
						<v-row align="center"  v-if="selectedMembershipInformationInformation.treatments.limitType != 'total'" >
							<v-col cols="4" sd="8" class="py-1" style="font-size: 12px"><strong>{{ $t('general.period', $store.state.locale) }}:</strong></v-col>
							<v-col cols="4" sd="8" class="py-1" style="font-size: 12px">
								<div style="font-size: 12px">{{  $t('memberships.' + selectedMembershipInformationInformation.paymentPeriod , $store.state.locale)}}</div>
							</v-col>
						</v-row>
						<!-- \ Periode / -->

						<!-- / Limit Number \ -->
						<v-row align="center">
							<v-col cols="4" sd="8" class="py-1" style="font-size: 12px"><strong>{{ $t('memberships.membershipUsage', $store.state.locale) }}:</strong></v-col>
							<v-col cols="8" sd="8" class="py-1" style="font-size: 12px">
							  <div v-if="selectedMembershipInformationInformation.treatments.limitType == 'period'" style="font-size: 12px">
								{{ selectedMembershipInformationInformation.treatments.limitNumber *  selectedMembershipInformationInformation.duration }}x 
								<i>({{ $t('memberships.limitNumber', $store.state.locale) }}  {{ selectedMembershipInformationInformation.treatments.limitNumber}}x {{ $t('admin.caoA', $store.state.locale) }} {{ limitSuffix(selectedMembershipInformationInformation).toLowerCase() }})</i>
							</div>

							<div v-if="selectedMembershipInformationInformation.treatments.limitType == 'total'" style="font-size: 12px">
							{{ selectedMembershipInformationInformation.treatments.limitNumber }}x {{ $t('memberships.perTotalMembershipLength', $store.state.locale).toLowerCase() }} 
							</div>
							
							</v-col>
						</v-row>
						<!-- \ Limit Number / -->

					</div>
					<!-- \General information / -->

					<!-- / Geldigheid \ -->
					<div>
						<h4 class="pt-0 pb-2 px-3">{{ $t("memberships.validation", $store.state.locale) }}</h4>
		
						<!-- / Monday \ -->
						<v-divider class="ma-0 pa-0"></v-divider>

						<v-row align="center" class="pa-3" >
							<v-col cols="4" sd="4" class="py-0" style="font-size: 12px"><strong>{{ $t('general.monday', $store.state.locale) }}</strong></v-col>
							<v-col cols="4" sd="4" class="py-0" >
								<v-chip small block label v-if="selectedMembershipInformationInformation.days.Mon.active && selectedMembershipInformationInformation.days.Mon.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.dayValid', $store.state.locale) }}</v-chip>
								<v-chip small block label v-if="selectedMembershipInformationInformation.days.Mon.active && !selectedMembershipInformationInformation.days.Mon.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" ></v-chip>
								<v-chip small block label v-if="!selectedMembershipInformationInformation.days.Mon.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.notValid', $store.state.locale) }}</v-chip>
							</v-col>
			
							<v-col cols="4" sd="4" class="py-0">
								<p style="font-size: 13px" v-if="selectedMembershipInformationInformation.days.Mon.active && !selectedMembershipInformationInformation.days.Mon.allDay" class="ma-0 pa-0 mt-2">{{ $t('memberships.validFrom', $store.state.locale) }} {{ selectedMembershipInformationInformation.days.Mon.start }}u {{ $t('general.till', $store.state.locale) }} {{ selectedMembershipInformationInformation.days.Mon.end }}u </p>
							</v-col>
				
						</v-row>
						<!-- \ Monday / -->

						<!-- / Tuesday \ -->
						<v-divider class="ma-0 pa-0"></v-divider>

						<v-row align="center" class="pa-3">
						<v-col cols="4" sd="4" class="py-0" style="font-size: 12px"><strong>{{ $t('general.tuesday', $store.state.locale) }}:</strong></v-col>
						<v-col cols="4" sd="4" class="py-0" >
							<v-chip small block label v-if="selectedMembershipInformationInformation.days.Tue.active && selectedMembershipInformationInformation.days.Tue.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.dayValid', $store.state.locale) }}</v-chip>
							<v-chip small block label v-if="selectedMembershipInformationInformation.days.Tue.active && !selectedMembershipInformationInformation.days.Tue.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" ></v-chip>
							<v-chip small block label v-if="!selectedMembershipInformationInformation.days.Tue.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.notValid', $store.state.locale) }}</v-chip>
						</v-col>
						<v-col cols="4" sd="4" class="py-0">
							<p style="font-size: 13px" v-if="selectedMembershipInformationInformation.days.Tue.active && !selectedMembershipInformationInformation.days.Tue.allDay" class="ma-0 pa-0 mt-2">{{ $t('memberships.validFrom', $store.state.locale) }} {{ selectedMembershipInformationInformation.days.Tue.start }}u {{ $t('general.till', $store.state.locale) }} {{ selectedMembershipInformationInformation.days.Tue.end }}u </p>
						</v-col>
						</v-row>
						<!-- \ Tuesday / -->

						<!-- / Wednesday \ -->
						<v-divider class="ma-0 pa-0"></v-divider>

						<v-row align="center" class="pa-3">
						<v-col cols="4" sd="4" class="py-0" style="font-size: 12px"><strong>{{ $t('general.wednesday', $store.state.locale) }}:</strong></v-col>
						<v-col cols="4" sd="4" class="py-0">
							<v-chip small block label v-if="selectedMembershipInformationInformation.days.Wed.active && selectedMembershipInformationInformation.days.Wed.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.dayValid', $store.state.locale) }}</v-chip>
							<v-chip small block label v-if="selectedMembershipInformationInformation.days.Wed.active && !selectedMembershipInformationInformation.days.Wed.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" ></v-chip>
							<v-chip small block label v-if="!selectedMembershipInformationInformation.days.Wed.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.notValid', $store.state.locale) }}</v-chip>
						</v-col>

						<v-col cols="4" sd="4" class="py-0">
							<p style="font-size: 13px" v-if="selectedMembershipInformationInformation.days.Wed.active && !selectedMembershipInformationInformation.days.Wed.allDay" class="ma-0 pa-0 mt-2">{{ $t('memberships.validFrom', $store.state.locale) }} {{ selectedMembershipInformationInformation.days.Wed.start }}u {{ $t('general.till', $store.state.locale) }} {{ selectedMembershipInformationInformation.days.Wed.end }}u </p>
						</v-col>

						</v-row>
						<!-- \ Wednesday / -->

						<!-- / Thursday \ -->
						<v-divider class="ma-0 pa-0"></v-divider>

						<v-row align="center" class="pa-3">
						<v-col cols="4" sd="4" class="py-0" style="font-size: 12px"><strong>{{ $t('general.wednesday', $store.state.locale) }}:</strong></v-col>
						<v-col cols="4" sd="4" class="py-0">
							<v-chip small block label v-if="selectedMembershipInformationInformation.days.Thu.active && selectedMembershipInformationInformation.days.Thu.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.dayValid', $store.state.locale) }}</v-chip>
							<v-chip small block label v-if="selectedMembershipInformationInformation.days.Thu.active && !selectedMembershipInformationInformation.days.Thu.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" ></v-chip>
							<v-chip small block label v-if="!selectedMembershipInformationInformation.days.Thu.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.notValid', $store.state.locale) }}</v-chip>
						</v-col>
						<v-col cols="4" sd="4" class="py-0">
							<p style="font-size: 13px" v-if="selectedMembershipInformationInformation.days.Thu.active && !selectedMembershipInformationInformation.days.Thu.allDay" class="ma-0 pa-0 mt-2">{{ $t('memberships.validFrom', $store.state.locale) }} {{ selectedMembershipInformationInformation.days.Thu.start }}u {{ $t('general.till', $store.state.locale) }} {{ selectedMembershipInformationInformation.days.Thu.end }}u </p>
						</v-col>
						</v-row>
						<!-- \ Thursday / -->

						<!-- / Friday \ -->
						<v-divider class="ma-0 pa-0"></v-divider>

						<v-row align="center" class="pa-3">
						<v-col cols="4" sd="4" class="py-0" style="font-size: 12px"><strong>{{ $t('general.friday', $store.state.locale) }}:</strong></v-col>
						<v-col cols="4" sd="4" class="py-0">
							<v-chip small block label v-if="selectedMembershipInformationInformation.days.Fri.active && selectedMembershipInformationInformation.days.Fri.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.dayValid', $store.state.locale) }}</v-chip>
							<v-chip small block label v-if="selectedMembershipInformationInformation.days.Fri.active && !selectedMembershipInformationInformation.days.Fri.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" ></v-chip>
							<v-chip small block label v-if="!selectedMembershipInformationInformation.days.Fri.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.notValid', $store.state.locale) }}</v-chip>
						</v-col>

						<v-col cols="4" sd="4" class="py-0">
							<p style="font-size: 13px" v-if="selectedMembershipInformationInformation.days.Fri.active && !selectedMembershipInformationInformation.days.Fri.allDay" class="ma-0 pa-0 mt-2">{{ $t('memberships.validFrom', $store.state.locale) }} {{ selectedMembershipInformationInformation.days.Fri.start }}u {{ $t('general.till', $store.state.locale) }} {{ selectedMembershipInformationInformation.days.Fri.end }}u </p>
						</v-col>

						</v-row>
						<!-- \ Friday / -->

						<!-- / Saturday \ -->
						<v-divider class="ma-0 pa-0"></v-divider>

						<v-row align="center" class="pa-3">
						<v-col cols="4" sd="4" class="py-0" style="font-size: 12px"><strong>{{ $t('general.saturday', $store.state.locale) }}:</strong></v-col>
						<v-col cols="4" sd="4" class="py-0">
							<v-chip small block label v-if="selectedMembershipInformationInformation.days.Sat.active && selectedMembershipInformationInformation.days.Sat.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.dayValid', $store.state.locale) }}</v-chip>
							<v-chip small block label v-if="selectedMembershipInformationInformation.days.Sat.active && !selectedMembershipInformationInformation.days.Sat.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" ></v-chip>
							<v-chip small block label v-if="!selectedMembershipInformationInformation.days.Sat.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.notValid', $store.state.locale) }}</v-chip>
						</v-col>
						<v-col cols="4" sd="4" class="py-0">
							<p style="font-size: 13px" v-if="selectedMembershipInformationInformation.days.Sat.active && !selectedMembershipInformationInformation.days.Sat.allDay" class="ma-0 pa-0 mt-2">{{ $t('memberships.validFrom', $store.state.locale) }} {{ selectedMembershipInformationInformation.days.Sat.start }}u {{ $t('general.till', $store.state.locale) }} {{ selectedMembershipInformationInformation.days.Sat.end }}u </p>
						</v-col>
						</v-row>
						<!-- \ Saturday / -->

						<!-- / Sunday \ -->
						<v-divider class="ma-0 pa-0"></v-divider>

						<v-row align="center" class="pa-3">
						<v-col cols="4" sd="4" class="py-0" style="font-size: 12px"><strong>{{ $t('general.sunday', $store.state.locale) }}:</strong></v-col>
						<v-col cols="4" sd="4" class="py-0">
							<v-chip small block label v-if="selectedMembershipInformationInformation.days.Sun.active && selectedMembershipInformationInformation.days.Sun.allDay" color="#56ca00" text-color="#56ca00" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.dayValid', $store.state.locale) }}</v-chip>
							<v-chip small block label v-if="selectedMembershipInformationInformation.days.Sun.active && !selectedMembershipInformationInformation.days.Sun.allDay" color="#ffb400" text-color="#ffb400" light class="mr-2 my-1 custom-chip-style" ></v-chip>
							<v-chip small block label v-if="!selectedMembershipInformationInformation.days.Sun.active" color="#ff4c51" text-color="#ff4c51" light class="mr-2 my-1 custom-chip-style" >{{ $t('memberships.notValid', $store.state.locale) }}</v-chip>
						</v-col>

						<v-col cols="4" sd="4" class="py-0">
							<p style="font-size: 13px" v-if="selectedMembershipInformationInformation.days.Sun.active && !selectedMembershipInformationInformation.days.Sun.allDay" class="ma-0 pa-0 mt-2">{{ $t('memberships.validFrom', $store.state.locale) }} {{ selectedMembershipInformationInformation.days.Sun.start }}u {{ $t('general.till', $store.state.locale) }} {{ selectedMembershipInformationInformation.days.Sun.end }}u </p>
						</v-col>
						</v-row>

						<v-divider class="ma-0 pa-0"></v-divider>
						<!-- \ Sunday / -->
					</div>
					<!-- \ Geldigheid / -->
					
				</v-card-text>
				
				<v-divider class="ma-0 pa-0"></v-divider>

				<v-card-actions>
					<v-container grid-list-md fluid class="ma-0 pa-0">
						<v-layout row wrap class="ma-0 pa-0">
							<v-flex xs12 class="text-center">
								<v-btn
									:block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
									outlined depressed color="tertiary" 
									@click="closeMembershipInformation()"
									>
									{{ $t('general.close', $store.state.locale) }}
								</v-btn>
							</v-flex>
						</v-layout>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
  </div>
</template>

<script>
  import { bus } from "../main";
  import membershipInformation from "@/views/membershipInformation.vue";

  export default {
    name: "MembershipsInformationModal",

    data(){
      return{
        showDialog: false,
        selectedMembershipInformationInformation: null,
        membershipTypes:[
          {
            name:  this.$t('memberships.paymentTypePeriodic', this.$store.state.locale),
            id: "continuous"
          },
          {
            name:  this.$t('memberships.paymentTypeSingle', this.$store.state.locale),
            id: "single"
          }
        ],
      }
    },

    async created(){
      bus.$on("showMembershipInformation", (membership) => { 
        this.selectedMembershipInformationInformation = {...membership};
        this.showDialog = true;
        this.check++
      })
    },

    methods:{
      getStatusType(type){
        let name = "";
        let data = this.membershipTypes.find((item)=>{ return item.id == type});
        if(data != undefined){
          name = data.name;
        }
        return name;
      },

      

      closeMembershipInformation(){
				this.showDialog = false;
				this.selectedMembershipInformationInformation = null;	
			},

      /**
       * Get the name of a company branch based on the ID
       * @param {String} id - ID of the Branch
       */
       getBranchName(id){
        let branchData = this.$store.state.companyBranches.find(branch => branch.id == id);
        if(branchData){
          return branchData.name
        }
        else{
          return null
        }
      },

	  limitSuffix(selectedMembershipInformation) {    
		let data = {
			daily: this.$t('general.day', this.$store.state.locale),
			everyWeek: this.$t('general.week', this.$store.state.locale),
			everyFourWeeks: this.$t('general.fourWeeks', this.$store.state.locale),
			everyMonth: this.$t('general.month', this.$store.state.locale),
			everyQuarter: this.$t('general.quarter', this.$store.state.locale),
			everyHalfYear: this.$t('general.day', this.$store.state.locale),
			everyYear: this.$t('general.year', this.$store.state.locale),
        }; 
		
		return selectedMembershipInformation && selectedMembershipInformation.paymentPeriod ? data[selectedMembershipInformation.paymentPeriod] : "";
		
	  },

      durationSuffix(selectedMembershipInformation) {       
        if(selectedMembershipInformation?.paymentPeriod){

          if(selectedMembershipInformation.paymentPeriod == 'daily'){
            if(selectedMembershipInformation.duration == 1){
              return "dag"
            }
            else{
              return "dagen"
            }
          }

          else if(selectedMembershipInformation.paymentPeriod == 'everyWeek'){
            if(selectedMembershipInformation.duration == 1){
              return "week"
            }
            else{
              return "weken"
            }
          }

          else if(selectedMembershipInformation.paymentPeriod == 'everyFourWeeks'){
            return "vierweken"
          }

          else if(selectedMembershipInformation.paymentPeriod == 'everyMonth'){
            if(selectedMembershipInformation.duration == 1){
              return "maand"
            }
            else{
              return "maanden"
            }
          }
          else if(selectedMembershipInformation.paymentPeriod == 'everyQuarter'){
            if(selectedMembershipInformation.duration == 1){
              return "kwartaal"
            }
            else{
              return "kwartalen"
            }
          }
          else if(selectedMembershipInformation.paymentPeriod == 'everyHalfYear'){
            if(selectedMembershipInformation.duration == 1){
              return "halfjaar"
            }
            else{
              return "halfjaren"
            }
          }
          else if(selectedMembershipInformation.paymentPeriod == 'everyYear'){
            if(selectedMembershipInformation.duration == 1){
              return "jaar"
            }
            else{
              return "jaren"
            }
          }
          else{
            return `${selectedMembershipInformation.paymentPeriod}`;
          }
        }
        else{
          return "";
        }
      },
    },
    computed: {
      
    }
  };
</script>