<template>
  <v-dialog v-model="showDialog" persistent scrollable max-width="700px" height="700px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
    <v-card>
      <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
        <h3 v-if="type == 'edit'">{{ $t("products.editProduct", $store.state.locale) }}</h3>
        <h3 v-if="type == 'new'">{{ $t("products.addNewProduct", $store.state.locale) }}</h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closeEditProductDialog()">
          <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-alert v-if="feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning" v-html="feedback"></v-alert>

      <v-tabs :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" show-arrows v-model="tab">
        <v-tab>{{ $t("general.basic", $store.state.locale) }}</v-tab>
        <v-tab @click="calculateTotalPrice()">{{ $t("general.price", $store.state.locale) }}</v-tab>
        <v-tab>{{ $t("general.branches", $store.state.locale) }}</v-tab>
        <v-tab>{{ $t("products.stockManagement", $store.state.locale) }}</v-tab>
        <v-tab>{{ $t("general.extra", $store.state.locale) }}</v-tab>
      </v-tabs>

      <v-divider></v-divider>

      <v-card-text class="ma-0 pa-0" style="height: 450px">
        <v-tabs-items v-model="tab" style="height: 450px; overflow-y: scroll">
          <v-tab-item :value="0" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">

              <v-card outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].developer" class="mb-4" v-if="type == 'edit' && $store.state.userRoleNumber < 2 && !$store.state.demoMode">
                <v-btn @click="copyTextToClipboard(product.id)" class="ma-1 mb-2" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="color: #ffffff; text-transform: none;"><strong>{{product.id}}</strong></v-btn>
                <div style="display: inline-block" v-if="productIdCopy == false" class="pa-2 copy-tag-url" @click="copyTextToClipboard(product.id)"><v-icon small color="#333">mdi-content-copy</v-icon>Kopieër DocId</div>
                <div v-else style="display: inline-block" class="pa-2 copy-tag-url copied" >DocId gekopieërd</div>
              </v-card>

              <v-container grid-list-md class="pa-0 ma-0">
                <v-text-field :label="$t('general.name', $store.state.locale)" dense v-model="product.name" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" required hide-details outlined background-color="background"></v-text-field>
                <v-layout row wrap>
                  <v-flex md6>
                    <v-select
                      class="mt-4"
                      :label="$t('products.categories', $store.state.locale)"
                      :items="productCategories"
                      :item-text="(item) => item.name ? item.name[$store.state.locale.toLowerCase()] : ''"
                      item-value="id"
                      v-model="product.category"
                      outlined
                      dense
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                      required
                      @change="setCategoryArray()"
                    ></v-select>
                  </v-flex>
                  <v-flex md6>

                  </v-flex>
                  <v-flex md6>
                    <v-select
                      class="mt-4"
                      dense
                      :label="$t('products.supplier', $store.state.locale)"
                      :items="productSuppliers"
                      item-text="name"
                      item-value="id"
                      return-object
                      v-model="product.supplier"
                      outlined
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                    ></v-select>
                  </v-flex>
                  <v-flex md6>
                    <v-select
                      :disabled="!product.supplier"
                      class="mt-4"
                      dense
                      :label="$t('products.supplierProductGroup', $store.state.locale)"
                      :items="product.supplier.groups"
                      item-text="name"
                      item-value="id"
                      v-model="product.supplierProductGroup"
                      outlined
                      hide-details
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      background-color="background"
                    ></v-select>
                  </v-flex>
                  <v-flex md6>
                    <v-text-field dense class="mt-4" :label="$t('products.sku', $store.state.locale)" v-model="product.sku" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" outlined hide-details background-color="background"></v-text-field>
                  </v-flex>
                  <v-flex md6>
                    <v-text-field
                      class="mt-4"
                      :label="$t('general.content', $store.state.locale)"
                      v-model="product.content"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      outlined
                      dense
                      hide-details
                      background-color="background"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md6>
                    <v-text-field
                      class="mt-4"
                      dense
                      :label="$t('products.barcode', $store.state.locale)"
                      prepend-inner-icon="mdi-barcode"
                      v-model.number="product.barcode.code"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      type="number"
                      hide-details
                      outlined
                      background-color="background"
                    ></v-text-field>
                  </v-flex>
                  <v-flex md6>
                    <barcode class="barcode-example" v-if="product.barcode.type != 'undefined'" :value="product.barcode.code" :format="product.barcode.type">
                      <span style="line-height: 56px">
                        <v-icon color="warning">mdi-alert</v-icon> <strong>{{ product.barcode.type }} {{ $t("products.barcodeCantBeGenerated", $store.state.locale) }}</strong>
                      </span>
                    </barcode>
                  </v-flex>
                  <v-flex md6>
                    <v-switch class="ma-0 mt-4 pa-0" :label="$t('products.retail', $store.state.locale)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="product.retail" @change="checkWebshopOption()"></v-switch>
                    <v-switch :disabled="!product.retail" class="ma-0 pa-0" :label="$t('products.webshop', $store.state.locale)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="product.webshop"></v-switch>
                  </v-flex>
                  <v-flex md6>
                    <v-switch class="ma-0 mt-4 pa-0" :label="$t('products.medicalProduct', $store.state.locale)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="product.medical"></v-switch>
                    <v-switch class="ma-0 pa-0" :label="$t('general.active', $store.state.locale)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="product.active" hide-details></v-switch>
                  </v-flex>
                </v-layout>
              </v-container>
            </div>
          </v-tab-item>
          <v-tab-item :value="1" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">
              <v-container grid-list-md class="pa-0 ma-0">
                <v-layout row wrap>
                  <v-flex md4>
                    <v-text-field
                      :label="$t('products.purchasePrice', $store.state.locale)"
                      dense
                      v-model.number="product.costPrice"
                      prefix="€"
                      type="number"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      outlined
                      background-color="background"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout row wrap>
                  <v-flex md4>
                    <v-text-field
                      :label="$t('products.salesPriceNet', $store.state.locale)"
                      v-model.number="product.priceNetto"
                      prefix="€"
                      outlined
                      dense
                      min="0"
                      type="number"
                      required
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      v-on:input="calculateBrutoPrice()"
                      hide-details
                    ></v-text-field>
                  </v-flex>
                  <v-flex md4>
                    <v-select
                      :label="$t('general.vat', $store.state.locale)"
                      dense
                      :items="vatItems"
                      item-text="summary"
                      item-value="id"
                      v-model="product.vat"
                      outlined
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      v-on:input="calculatePrice()"
                      hide-details
                    ></v-select>
                  </v-flex>
                  <v-flex md4>
                    <v-text-field
                      :label="$t('products.salesPriceGross', $store.state.locale)"
                      outlined
                      dense
                      min="0"
                      :loading="calculatingBruto"
                      type="number"
                      prefix="€"
                      :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      v-model.number="product.priceBruto"
                      v-on:input="calculateNettoPrice()"
                      required
                      hide-details
                    ></v-text-field>
                  </v-flex>
                </v-layout>

                <!-- <v-card flat class="mt-4" :color="$themes[$store.state.companyTheme][$store.state.themeModus].cardOption">
                  <v-container grid-list-md class="ma-0 pa-4 pb-2" >
                    <v-layout row wrap>
                      <v-flex md12>
                        <h3><strong>{{ $t('products.discount', $store.state.locale) }}</strong></h3>
                      </v-flex>
                      <v-flex md12>
                        <v-switch
                          hide-details
                          class="ma-0 mt-2 pa-0"
                          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                          v-model="product.discount.active"
                          @change="calculateTotalPrice()"
                        >
                          <template slot='label'>
                            <span style=" margin-right: 5px;">{{ $t('products.setDiscount', $store.state.locale) }}</span>                 
                            <v-tooltip class="ma-0 pa-0" right >
                              <template v-slot:activator="{on}">
                                <v-icon color="text" small v-on="on">mdi-help-circle</v-icon>
                              </template>
                              <span style="color: #fff;">{{ $t('products.setDiscountHint', $store.state.locale) }}</span>
                            </v-tooltip>
                          </template>
                        </v-switch>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  
                  <div v-if="product.discount.active">
                    <v-container grid-list-md class="ma-0 pa-4" >
                      <v-layout row wrap>
                        <v-flex md6>
                          <v-select
                            v-model="product.discount.type"
                            :label="$t('products.discountType', $store.state.locale)"
                            :items="discountTypes"
                            item-text="label"
                            item-value="id"
                            outlined
                            dense
                            hide-details
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            v-on:input="calculateTotalPrice()"
                          ></v-select>
                        </v-flex>
                        <v-flex md6>
                          <v-text-field
                            :label="discountInfo.label"
                            v-model.number="product.discount.number"
                            dont-fill-mask-blanks
                            :prefix="discountInfo.prefix"
                            :suffix="discountInfo.suffix"
                            :mask="discountInfo.mask"
                            outlined
                            dense
                            :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                            v-on:input="calculateTotalPrice()"
                            hide-details
                          ></v-text-field>
                        </v-flex>

                        <v-flex md12>
                          <v-switch
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            class="ma-0 my-2 pa-0"
                            v-model="product.discount.period"
                            hide-details
                          >
                            <template slot='label'>
                              <span style="margin-right: 5px;">{{ $t('products.setDiscountPeriod', $store.state.locale) }}</span>
                              <v-tooltip class="ma-0 pa-0" right >
                                <template v-slot:activator="{on}">
                                  <v-icon color="text" small v-on="on">mdi-help-circle</v-icon>
                                </template>
                                <span style="color: #fff;">{{ $t('products.setDiscountPeriodHint', $store.state.locale) }}</span>
                              </v-tooltip>
                            </template>
                          </v-switch>
                        </v-flex>

                        <v-flex xs6 v-if="product.discount.period">
                          <v-menu
                            :close-on-content-click="false"
                            v-model="discountStartDialog"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            >
                            <template v-slot:activator="{on}">
                              <v-text-field
                                v-on="on"
                                dense
                                v-model="productDiscountStartdate"
                                :label="$t('general.startDate', $store.state.locale)"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                hide-details
                                outlined
                                :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="product.discount.start"
                              no-title
                              :min="now"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                              @input="discountStartDialog = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-flex>
                        <v-flex xs6 v-if="product.discount.period">
                          <v-menu
                            :close-on-content-click="false"
                            v-model="discountEndDialog"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                            >
                            <template v-slot:activator="{on}">
                              <v-text-field
                                v-on="on"
                                v-model="productDiscountEnddate"
                                :label="$t('general.endDate', $store.state.locale)"
                                prepend-inner-icon="mdi-calendar"
                                readonly
                                dense
                                hide-details
                                outlined
                                :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                                :disabled="!product.discount.start"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="product.discount.end"
                              no-title
                              :min="product.discount.start"
                              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                              @input="discountEndDialog = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </div>
                </v-card> -->

                <div v-if="!calculating">
                  <v-layout style="margin: 15px 0 0 0">
                    <v-flex md4 class="ma-1"><p class="ma-0" style="font-size: 16px"></p></v-flex>
                    <v-flex md4 class="ma-1"><p class="ma-0" style="font-size: 12px">{{ $t("products.amountNet", $store.state.locale) }}</p></v-flex>
                    <v-flex md4 class="ma-1"><p class="ma-0" style="font-size: 12px">{{ $t("products.amountGross", $store.state.locale) }}</p></v-flex>
                  </v-layout>

                  <v-layout class="ma-0">
                    <v-flex class="ma-0" md4>
                      <p class="ma-0" style="font-size: 16px; line-height: 40px">{{ $t("general.total", $store.state.locale) }}</p>
                    </v-flex>
                    <v-flex class="ma-0" md4>
                      <v-text-field outlined dense readonly background-color="background" prefix="€" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :value="product.priceNetto" hide-details></v-text-field>
                    </v-flex>

                    <v-flex class="ma-0" md4>
                      <v-text-field outlined dense readonly background-color="background" prefix="€" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :value="product.priceBruto" hide-details></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout v-if="product.discount.active" class="ma-0">
                    <v-flex class="ma-0" md4>
                      <p class="ma-0" style="font-size: 16px; line-height: 40px">{{ $t("products.discount", $store.state.locale) }}</p>
                    </v-flex>
                    <v-flex class="ma-0" md4>
                      <v-text-field outlined dense readonly background-color="background" prefix="€" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :value="product.nettoDiscount" hide-details></v-text-field>
                    </v-flex>
                    <v-flex class="ma-0" md4>
                      <v-text-field outlined dense readonly background-color="background" prefix="€" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :value="product.brutoDiscount" hide-details></v-text-field>
                    </v-flex>
                  </v-layout>

                  <v-layout v-if="product.discount.active" style="margin: 0 0 5px 0">
                    <v-flex md4 class="ma-0">
                      <p class="ma-0" style="font-size: 16px; line-height: 40px">
                        <strong>{{ $t("products.amountTotalAmount", $store.state.locale) }}</strong>
                      </p>
                    </v-flex>
                    <v-flex class="ma-0" md4>
                      <v-text-field outlined dense readonly background-color="background" prefix="€" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :value="product.nettoTotalPrice" hide-details></v-text-field>
                    </v-flex>
                    <v-flex class="ma-0" md4>
                      <v-text-field outlined dense readonly background-color="background" prefix="€" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :value="product.brutoTotalPrice" hide-details></v-text-field>
                    </v-flex>
                  </v-layout>
                </div>
              </v-container>
            </div>
          </v-tab-item>

          <v-tab-item :value="2" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">
              <v-container grid-list-md class="pa-0 ma-0">
                <v-select
                  :label="$t('products.selectBranches', $store.state.locale)"
                  :items="branches"
                  item-text="name"
                  item-value="id"
                  multiple
                  outlined
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  attach
                  chips
                  hide-details=""
                  v-model="product.branches"
                ></v-select>
              </v-container>
            </div>
          </v-tab-item>

          <v-tab-item :value="3" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">
              <v-container grid-list-md>
                <v-switch :label="$t('products.useStockManagement', $store.state.locale)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-model="product.useStock" class="ma-0 pa-0" hide-details></v-switch>

                <div v-if="product.useStock">
                  <span v-if="Object.keys(product.branches).length > 0">
                    <div v-for="(branch, index) in product.branches" :key="index">
                      <span v-for="(branchSource, index) in branches" :key="index">
                        <p class="ma-0 mt-4 mb-2" style="font-weight: bold" v-if="branch == branchSource.id">{{ branchSource.name }}</p>
                      </span>
                      <v-layout row wrap>
                        <v-flex md6>
                          <v-text-field
                            :label="$t('products.stock', $store.state.locale)"
                            v-model.number="product.stock[branch].stockValue"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            type="number"
                            dense
                            hide-details
                            suffix="stuks"
                            outlined
                            background-color="background"
                          ></v-text-field>
                        </v-flex>
                        <v-flex md6>
                          <v-text-field
                            :label="$t('products.minStock', $store.state.locale)"
                            hide-details
                            v-model.number="product.stock[branch].minStockValue"
                            :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                            type="number"
                            dense
                            suffix="stuks"
                            outlined
                            background-color="background"
                          ></v-text-field>
                        </v-flex>
                      </v-layout>
                    </div>
                  </span>
                  <span v-else>{{ $t("products.stockHint", $store.state.locale) }}</span>
                </div>
              </v-container>
            </div>
          </v-tab-item>
          <v-tab-item :value="4" :transition="tabTransition" :reverse-transition="tabTransition">
            <div class="tab-component-content">
              <v-container grid-list-md>
                <v-flex md12 style="padding: 0 0 10px 0">
                  <div class="upload-news-image-wrapper clear">
                    <div class="upload-image">
                      <h3>{{ $t("products.uploadImage", $store.state.locale) }}</h3>
                      <div v-if="product.imageUrl" class="logo-upload-preview">
                        <v-img :src="product.imageUrl" aspect-ratio="1" class="grey lighten-2">
                          <template v-slot:placeholder>
                            <v-layout fill-height align-center justify-center ma-0> </v-layout>
                          </template>
                        </v-img>
                        <div v-if="uploading == true" class="upload-bar">
                          <p>{{ uploadProgress }} %</p>
                          <div class="upload-bar-inner" style="background-color: #00adef"></div>
                        </div>
                      </div>
                      <v-btn class="ma-0 mt-2 mb-4" raised :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" dark @click="onFileSelect">{{ $t("products.chooseImage", $store.state.locale) }}</v-btn>
                      <input type="file" style="display: none" ref="fileInput" accept="image/*" @change="onFilePicked" />
                    </div>
                  </div>
                </v-flex>
                <v-flex md12>
                  <v-text-field
                    :label="$t('products.productUseDescription', $store.state.locale)"
                    type="text"
                    outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-model="product.productDescription"
                  ></v-text-field>
                </v-flex>
                <v-flex md12>
                  <v-tooltip right>
                    <template v-slot:activator="{ on }">
                      <label class="custom-label" v-on="on"> {{ $t("products.productUseDescription", $store.state.locale) }} <v-icon small>mdi-help-circle</v-icon> </label>
                    </template>
                    <span style="color: #fff">{{ $t("products.productUseDescriptionHint", $store.state.locale) }} </span>
                  </v-tooltip>
                  <v-textarea
                    type="text"
                    outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    v-model="product.userManual"
                  ></v-textarea>
                </v-flex>
                <v-flex> </v-flex>
              </v-container>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-container grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
              <v-btn outlined depressed :color="$themes[$store.state.companyTheme][$store.state.themeModus].text" @click="closeEditProductDialog()">
                {{ $t("general.cancel", $store.state.locale) }}
              </v-btn>
            </v-flex>
            <v-flex xs12 md6 class="text-right">
              <v-btn
                v-if="type == 'edit'"
                :disabled="!changed"
                :depressed="!changed"
                :dark="changed"
                :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                @click="updateProduct()"
                >
                <div v-if="changed">{{ $t('general.saveChanged', userLanguage) }} <v-icon right >mdi-chevron-right</v-icon></div>
                <div v-else> {{ $t('general.saved', userLanguage) }} <v-icon right >mdi-check</v-icon></div>   
              </v-btn>
              <v-btn 
                v-if="type == 'new'"
                dark :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'" 
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @click="addProduct()">
                  {{ $t("products.addProduct", $store.state.locale) }}
                <v-icon right dark>mdi-chevron-right</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { bus } from "../../main";
  import db from "../../firebase/init";
  import "@firebase/firestore";
  import VueBarcode from "vue-barcode";
  import moment from "moment";
  import { cashConv } from "../../modules/quickWins";

  export default {
    name: "product-form",
    props: {
      loading: Boolean,
      branches: Array,
      vatItems: Array,
      productCategories: Array,
      productSuppliers: Array,
      supplierProductGroups: Array,
    },
    components: {
      barcode: VueBarcode,
    },
    data() {
      return {
        showDialog: false,
        tab: 0,
        tabTransition: false,
        calculatingBruto: false,
        calculatingNetto: false,
        product: {
          barcode: {},
          discount: {},
          supplier: {}
        },
        productOriginal: {
          barcode: {},
          discount: {}
        },
        discountTypes: [
          {
            label: "Percentage",
            id: "percentage",
          },
          {
            label: "Bedrag",
            id: "amount",
          },
        ],
        productIdCopy: false,
        discountStartDialog: false,
        discountEndDialog: false,
        now: null,
        calculating: false,
        feedback: null,
        uploading: false,
        uploadProgress: null,
        file: {},
        valid: true,
        filename: null,
        image: null,
        type: 'new'
      };
    },
    created() {
      bus.$on("ProductModal", (product, type) => {
        // this.branchSet()
        this.tab = 0;
        this.type = type
        if(type == "edit"){
          this.product = _.cloneDeep(product);
          this.product.categoryArray = this.product.category ? this.product.category : null;
          this.product.category = this.product.category ? this.product.category[0] : null;
          this.product.supplier = this.productSuppliers.find(supplier => {return supplier.id == this.product.supplier}) 
          this.productOriginal = _.cloneDeep(this.product);
          this.calculateTotalPrice();
        }
        else if(type == "clone"){
          this.type = 'new'
          this.product = _.cloneDeep(product);
          this.product.categoryArray = this.product.category ? this.product.category : null;
          this.product.category = this.product.category ? this.product.category[0] : null;
          this.product.supplier = this.productSuppliers.find(supplier => {return supplier.id == this.product.supplier}) 
          this.productOriginal = new Object()
          this.calculateTotalPrice();
        }
        else{
          this.type = 'new'
          this.resetProduct()
        }
        this.showDialog = true;
      });
      this.now = moment().format("YYYY-MM-DD");
    },

    methods: {
      closeEditProductDialog() {
        this.showDialog = false;
        this.$emit("editProductDialogChange", false);
        this.tab = 0;
      },

      setCategoryArray(){
        this.product.categoryArray = [this.product.category];
      },

      checkWebshopOption(){
        if(!this.product.retail){
          this.product.webshop = false;
        }
      },

      copyTextToClipboard(text) {
        if (!navigator.clipboard) {
          fallbackCopyTextToClipboard(text);
          return;
        }

        navigator.clipboard.writeText(text).then(()=> {
          this.productIdCopy = true
          setTimeout(()=>{ 
            this.productIdCopy = false
          }, 1000);

        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      },

      resetProduct() {
        this.tab = 0;
        this.product = {
          // Basis
          name: null,
          categoryArray: null,
          category: new Array(),
          supplier: new Object(),
          sku: null,
          supplierProductGroup: null,
          content: null,
          medical: false,
          retail: true,
          webshop: false,
          active: true,
          // Prijs
          discount: {
            active: false,
            start: null,
            end: null,
            type: "percentage",
            number: null,
          },
          costPrice: null,
          priceNetto: null,
          priceBruto: null,
          vat: null,
          // Barcode
          barcode: {
            code: null,
            type: "undefined",
          },
          // Voorraadbeheer
          branches: [],
          // Voorraadbeheer
          useStock: false,
          stock: {},
          // Extra
          productDescription: null,
          userManual: null,
          imageUrl: "",
        };
      },

      calculateBrutoPrice() {
        
        // Bereken Brutoprijs o.b.v. Nettoprijs en BTW
        if (this.product.vat) {
          this.calculatingBruto = true;
          let vat = null;
          let counter = 0;
          new Promise((resolve) => {
            this.vatItems.forEach((vatItem) => {
              if (vatItem.id == this.product.vat) {
                vat = vatItem.percentage / 100 + 1;
              }
              counter++;
              if (counter == this.vatItems.length) {
                resolve();
              }
            });
          })
          .then(() => {
            let priceBruto = (this.product.priceNetto * vat).toFixed(2);
            this.product.priceBruto = Number(priceBruto);
            this.calculateTotalPrice();
            this.calculatingBruto = false;
          });
        }
      },

      calculatePrice() {
        if (this.product.priceNetto) {
          this.calculateBrutoPrice();
        } else if (this.product.priceBruto) {
          this.calculateNettoPrice();
        }
      },

      calculateNettoPrice() {
        // Bereken Brutoprijs o.b.v. Nettoprijs en BTW
        if (this.product.vat) {
          this.calculatingNetto = true;
          let vat = null;
          let counter = 0;
          new Promise((resolve) => {
            this.vatItems.forEach((vatItem) => {
              if (vatItem.id == this.product.vat) {
                vat = vatItem.percentage / 100 + 1;
              }
              counter++;
              if (counter == this.vatItems.length) {
                resolve();
              }
            });
          })
          .then(() => {
            let nettoPrice = cashConv((this.product.priceBruto / vat), "EUR",4);
            console.log("nettoPrice: ", nettoPrice)
            // let nettoPrice = (this.product.priceBruto / vat).toFixed(2);
            this.product.priceNetto = nettoPrice;
            this.calculateTotalPrice()
     
          });
        }
      },

      calculateTotalPrice() {
        if (this.product.vat) {
          let vat = null;
          let counter = 0;
          new Promise((resolve, reject) => {
            this.vatItems.forEach((vatItem) => {
              if (vatItem.id == this.product.vat) {
                vat = vatItem.percentage / 100 + 1;
              }
              counter++;
              if (counter == this.vatItems.length) {
                resolve();
              }
            });
          })
          .then(() => {
            // Korting berekenen als deze actief is
            if (this.product.discount.active) {
              let discountNumber = 0;
              if (this.product.discount.number > 0) {
                discountNumber = this.product.discount.number;
              }

              if (this.product.discount.type == "percentage") {
                this.product.brutoDiscount = ((discountNumber / 100) * this.product.priceBruto).toFixed(2);
              }
              else {
                this.product.brutoDiscount = discountNumber.toFixed(2);
              }

              this.product.nettoDiscount = (this.product.brutoDiscount / vat).toFixed(2);

              this.product.nettoTotalPrice = (this.product.priceNetto - this.product.nettoDiscount).toFixed(2);
              this.product.brutoTotalPrice = (this.product.priceBruto - this.product.brutoDiscount).toFixed(2);
            }
          })
          .then(() => {
            this.calculating = true;
            this.$nextTick(() => {
              this.calculating = false;
            });
          });
        }
      },

      async updateProduct() {
        this.feedback = null;
        let priceData =  !this.product.vat ? !this.product.priceBruto ? true : false : true;

        let logData = {
          superAdminAction: true, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "productEditted",
          actionItem: this.productOriginal.name,
          extra:[
            {
              name: "originalData",
              value: ""
            },
            {
              name: "name",
              value: this.productOriginal.name
            },
            {
              name: "category",
              value: this.productOriginal.category
            },
            {
              name: "supplier",
              value: this.productOriginal.supplier
            },
            {
              name: "sku",
              value: this.productOriginal.sku
            },
            {
              name: "content",
              value: this.productOriginal.content
            },
            {
              name: "medical",
              value: this.productOriginal.medical
            },
            {
              name: "medical",
              value: this.productOriginal.medical
            },
            {
              name: "active",
              value: this.productOriginal.active
            },
            {
              name: "costPrice",
              value: Number(this.productOriginal.costPrice)
            },
            {
              name: "priceNetto",
              value: Number(this.productOriginal.priceNetto)
            },
            {
              name: "vat",
              value: this.productOriginal.vat
            },
            {
              name: "barcode",
              value: Number(this.productOriginal.barcode)
            },
            {
              name: "useStock",
              value: this.productOriginal.useStock
            },
            {
              name: "stock",
              value: this.productOriginal.stock
            },
            {
              name: "productDescription",
              value: this.productOriginal.productDescription
            },
            {
              name: "userManual",
              value: this.productOriginal.userManual
            },
            {
              name: "imageUrl",
              value: this.productOriginal.imageUrl
            },
            {
              name: "changedData",
              value: ""
            },
            {
              name: "name",
              value: this.product.name
            },
            {
              name: "category",
              value: this.product.category
            },
            {
              name: "supplier",
              value: this.product.supplier
            },
            {
              name: "sku",
              value: this.product.sku
            },
            {
              name: "content",
              value: this.product.content
            },
            {
              name: "medical",
              value: this.product.medical
            },
            {
              name: "medical",
              value: this.product.medical
            },
            {
              name: "active",
              value: this.product.active
            },
            {
              name: "costPrice",
              value: Number(this.product.costPrice)
            },
            {
              name: "priceNetto",
              value: Number(this.product.priceNetto)
            },
            {
              name: "vat",
              value: this.product.vat
            },
            {
              name: "barcode",
              value: Number(this.product.barcode)
            },
            {
              name: "useStock",
              value: this.product.useStock
            },
            {
              name: "stock",
              value: this.product.stock
            },
            {
              name: "productDescription",
              value: this.product.productDescription
            },
            {
              name: "userManual",
              value: this.product.userManual
            },
            {
              name: "imageUrl",
              value: this.product.imageUrl
            },
          ]
        }

        if (this.product.name && this.product.categoryArray.length > 0 && this.product.supplier && priceData) {
          try{
            db.collection("products")
            .doc(this.product.id)
            .update({
              companyId: this.$store.state.activeCompany.id,
              name: this.product.name,
              category: this.product.categoryArray,
              supplier: this.product.supplier.id,
              supplierProductGroup: this.product.supplierProductGroup ? this.product.supplierProductGroup : null,
              sku: this.product.sku,
              discount: this.product.discount,
              content: this.product.content,
              medical: this.product.medical,
              retail: this.product.retail,
              webshop: this.product.webshop ? this.product.webshop : false,
              active: this.product.active,
              costPrice: this.product.costPrice,
              priceNetto: this.product.priceNetto,
              vat: this.product.vat,
              barcode: this.product.barcode,
              branches: this.product.branches,
              useStock: this.product.useStock,
              stock: this.product.stock,
              productDescription: this.product.productDescription,
              userManual: this.product.userManual,
              imageUrl: this.product.imageUrl,
            })

            await this.$createNewLog("info", logData);
            this.$emit("updateData");
            this.closeEditProductDialog();
          }
          catch(error){
            console.error("Error adding Product: ", error);
            this.feedback = this.$t("general.addError", this.$store.state.locale) ;
            logData.extra.push({ name: "error", value: JSON.stringify(error) })
            await this.$createNewLog("error", logData);
          }
        }
        else {
          let missingFields = new Array()
          if (!this.product.name){
            missingFields.push(`<strong>${this.$t("products.name", this.$store.state.locale)}</strong>`)
          }
          if (!this.product.category || this.product.category.length == 0){
            missingFields.push(`<strong>${this.$t("products.categories", this.$store.state.locale)}</strong>`)
          }
          if (!this.product.supplier){
            missingFields.push(`<strong>${this.$t("products.supplier", this.$store.state.locale)}</strong>`)
          }
          if (!priceData){
            missingFields.push(`<strong>${this.$t("products.salesPriceGross", this.$store.state.locale)}</strong>`)
            missingFields.push(`<strong>${this.$t("products.vat", this.$store.state.locale)}</strong>`)
          }
 
          let missingFieldsString = missingFields.length > 1 ? missingFields.slice(0, -1).join(", ")+` ${this.$t("booking.and", this.$store.state.locale)} `+missingFields[missingFields.length-1] : missingFields.join("");

          this.feedback = `${this.$t("products.editProductIncompleteFeedback", this.$store.state.locale)}  ${missingFieldsString}`;
          console.log(this.product.name, this.product.priceBruto,this.product.category.length, this.product.supplier, this.product.branches.length, this.product.vat)
        }
      },

      async addProduct() {
        this.feedback = null;
        let priceData =  !this.product.vat ? !this.product.priceBruto ? true : false : true;

        let logData = {
          superAdminAction: true, // Is action only accessible by superadmin?
          actionType: "userAction",
          action: "productAdded",
          actionItem: this.product.name,
          extra:[
            {
              name: "name",
              value: this.product.name
            },
            {
              name: "category",
              value: this.product.category
            },
            {
              name: "supplier",
              value: this.product.supplier
            },
            {
              name: "sku",
              value: this.product.sku
            },
            {
              name: "content",
              value: this.product.content
            },
            {
              name: "medical",
              value: this.product.medical
            },
            {
              name: "sku",
              value: this.product.sku
            },
            {
              name: "medical",
              value: this.product.medical
            },
            {
              name: "active",
              value: this.product.active
            },
            {
              name: "costPrice",
              value: Number(this.product.costPrice)
            },
            {
              name: "priceNetto",
              value: Number(this.product.priceNetto)
            },
            {
              name: "vat",
              value: this.product.vat
            },
            {
              name: "barcode",
              value: Number(this.product.barcode)
            },
            {
              name: "useStock",
              value: this.product.useStock
            },
            {
              name: "stock",
              value: this.product.stock
            },
            {
              name: "productDescription",
              value: this.product.productDescription
            },
            {
              name: "userManual",
              value: this.product.userManual
            },
            {
              name: "imageUrl",
              value: this.product.imageUrl
            },
          ]
        }

        if (this.product.name && this.product.category.length > 0 && this.product.supplier && priceData) {
          try{
            let docRef = await db.collection("products")
            .add({
              companyId: this.$store.state.activeCompany.id,
              name: this.product.name,
              category: this.product.categoryArray,
              supplier: this.product.supplier.id,
              sku: this.product.sku,
              supplierProductGroup: this.product.supplierProductGroup,
              content: this.product.content,
              medical: this.product.medical,
              discount: this.product.discount,
              active: this.product.active,
              retail: this.product.retail,
              webshop: this.product.webshop,
              costPrice: this.product.costPrice,
              priceNetto: this.product.priceNetto,
              vat: this.product.vat,
              barcode: this.product.barcode,
              branches: this.product.branches,
              useStock: this.product.useStock,
              stock: this.product.stock,
              productDescription: this.product.productDescription,
              userManual: this.product.userManual,
              imageUrl: this.product.imageUrl,
            })

            if (this.filename) {
              await this.uploadProductImage(docRef.id);
            }

            await this.$createNewLog("info", logData);

            this.$emit("updateData");
            this.resetProduct()
            this.closeEditProductDialog();
          }
          catch(error) {
            console.error("Error adding Product: ", error);
            this.feedback = this.$t("general.addError", this.$store.state.locale) ;
            logData.extra.push({ name: "error", value: JSON.stringify(error) })
          }
        }
        else {
          let missingFields = new Array()
          if (!this.product.name){
            missingFields.push(`<strong>${this.$t("products.name", this.$store.state.locale)}</strong>`)
          }
          if (!this.product.category || this.product.category.length == 0){
            missingFields.push(`<strong>${this.$t("products.categories", this.$store.state.locale)}</strong>`)
          }
          if (!this.product.supplier){
            missingFields.push(`<strong>${this.$t("products.supplier", this.$store.state.locale)}</strong>`)
          }
          if (!priceData){
            missingFields.push(`<strong>${this.$t("products.salesPriceGross", this.$store.state.locale)}</strong>`)
            missingFields.push(`<strong>${this.$t("products.vat", this.$store.state.locale)}</strong>`)
          }
 
          let missingFieldsString = missingFields.length > 1 ? missingFields.slice(0, -1).join(", ")+` ${this.$t("booking.and", this.$store.state.locale)} `+missingFields[missingFields.length-1] : missingFields.join("");

          this.feedback = `${this.$t("products.addProductIncompleteFeedback", this.$store.state.locale)}  ${missingFieldsString}`;
        }
      },

      // Image methods
      onFileSelect() {
        // Opening selection folder in the browser.
        this.$refs.fileInput.click();
      },

      onFilePicked(event) {
        // Selecting picture.
        const files = event.target.files;
        this.file = files[0];
        let filename = files[0].name;
        this.filename = filename;
        // console.log("files: " + filename);
        if (filename.lastIndexOf(".") <= 0) {
          this.feedback = this.$t("products.addImageFeedback", this.$store.state.locale);
        }
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.product.imageUrl = fileReader.result;
        });
        fileReader.readAsDataURL(files[0]);
        this.image = files[0];
        this.feedback = null;
      },

      uploadProductImage(docId) {
        // Uploading Image.
        return new Promise((resolve, reject) => {
          // console.log("start storen");
          const file = this.file;
          const filename = this.filename;
          const ext = filename.slice(filename.lastIndexOf("."));
          const fileUrl = "productimages/" + docId + ext;
          this.uploading = true;
          let uploadTask = firebase.storage().ref(fileUrl).put(file);

          uploadTask.on(
            "state_changed",
            (taskSnapshot) => {
              var progress = Math.ceil((taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 100);
              this.uploadProgress = progress;
            },
            (error) => {
              console.error("Uploading file error: ", error);
              reject();
            },
            () => {
              // console.log("The upload is compleet");
              uploadTask.snapshot.ref
                .getDownloadURL()
                .then((url) => {
                  db.collection("products")
                    .doc(docId)
                    .update({
                      imageUrl: url,
                    })
                    .then(() => {
                      resolve();
                      this.uploading = false;
                      this.uploadProgress = null;
                    })
                    .catch((error) => {
                      reject();
                      this.uploading = false;
                      this.uploadProgress = null;
                    });
                })
                .catch((error) => {
                  reject();
                  this.uploading = false;
                  this.uploadProgress = null;
                });
            }
          );
        });
      },
    },

    watch: {
      product: {
        // Kijk of barcode de juiste lengte heeft voor EAN8 of EAN13
        handler: function () {
          if (this.product.barcode) {
            //console.log("Barcode aanwezig")
            if (String(this.product.barcode.code).length < 8) {
              //console.log("Barcode te kort")
              this.product.barcode.type = "undefined";
            } else if (String(this.product.barcode.code).length == 8) {
              //console.log("Barcode EAN8")
              this.product.barcode.type = "EAN8";
            } else if (String(this.product.barcode.code).length > 8 && String(this.product.barcode.code).length < 13) {
              let toGo = 13 - String(this.product.barcode.code).length;
              //console.log("Barcode EAN13 maar nog " + toGo + " nummer te gaan")
              this.product.barcode.type = "undefined";
            } else if (String(this.product.barcode.code).length == 13) {
              //console.log("Barcode EAN13")
              this.product.barcode.type = "EAN13";
            } else if (String(this.product.barcode.code).length > 13) {
              //console.log("Barcode te lang")
              this.product.barcode.type = "undefined";
            }
          } else {
            // _.get(this.product.barcode, this.product.barcode.code , [0])
          }
        },
        deep: true,
      },
    },

    computed: {
      discountInfo() {
        if (this.product.discount.type == "amount") {
          return {
            prefix: "€",
            suffix: null,
            label: "Kortingsbedrag",
            mask: "####",
          };
        } else {
          return {
            prefix: null,
            suffix: "%",
            label: "Kortingspercentage",
            mask: "##",
          };
        }
      },

      productDiscountStartdate() {
        if (this.product.discount.start) {
          return moment(this.product.discount.start).format("D MMM YYYY");
        } else {
          return "";
        }
      },

      productDiscountEnddate() {
        if (this.product.discount.end) {
          return moment(this.product.discount.end).format("D MMM YYYY");
        } else {
          return "";
        }
      },
      userLanguage() {
        return this.$store.state.locale ? this.$store.state.locale : "NL";
      },
      userCompanies() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
      },
      userCompany() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
      },
      userFirstName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
      },
      userLastName() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
      },
      userRole() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
      },
      userId() {
        return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
      },
      theme() {
        return this.$vuetify.theme.dark ? "dark" : "light";
      },
      companyTheme() {
        return this.$store.state.companyTheme ? this.$store.state.companyTheme : "thrive";
      },
      changed() {
        if (_.isEqual(this.product, this.productOriginal) == false) {
          return true;
        } else {
          return false;
        }
      },
    },
  };
</script>

<style>
  .barcode-example {
    height: 56px;
    margin: 16px 0 0 0;
    position: relative;
  }
  .barcode-example svg.vue-barcode-element {
    height: 100%;
    width: auto;
  }
</style>
