<template v-slot:activator="{ on }">
  <div>

    <div v-if="dataloaded && $store.state.activeUserCompanies.length > 1" class="pb-2">
      <v-container grid-list-md class="pa-0 py-2" fluid>
        <v-layout row wrap>
          <companySelector :showBranches="false" @companyChanged="loadData"></companySelector>
        </v-layout>
      </v-container>
    </div>

    <div v-if="!dataloaded" class="thrive-loader"></div>

    <div v-if="dataloaded">
      <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow" :class="$store.state.companyTheme" v-if="campaigns.length >0">
        <v-container grid-list-md class=" ma-0 pa-3 py-5" fluid>
            <v-layout row wrap>
              <v-flex md4 xs12>
                <v-text-field
                  style="max-width: 300px;"
                  v-model="search"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  placeholder="Zoeken"
                  outlined
                  dense
                  hide-details     
                  background-color="background"                                         
                  prepend-inner-icon="mdi-magnify"
                  autocomplete="disabled"
                  clearable>
                </v-text-field>
              </v-flex>
              <v-flex md8 xs12 class="text-right">
                <v-btn fab dark small :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" v-if="selected.length > 0" @click="deleteCampaigns()">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn class="mx-2" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addCampaign()">
                  <v-icon left>mdi-plus</v-icon> Nieuwe campagne
                </v-btn>
                
              </v-flex>
            </v-layout>
          </v-container>

          <v-divider> </v-divider>

        <v-data-table
          :headers="headers"
          :items="campaigns"
          :items-per-page="-1"
          show-select
          v-model="selected"
          :search="search"
          hide-default-footer
          item-key="name"
          no-data-text="Geen campaigns."
          >
          <template v-slot:item="{ item, isSelected, select}">
            <tr>
              <td>
                <v-icon @click="select(true)" v-if="!isSelected">mdi-checkbox-blank-outline</v-icon>
                <v-icon @click="select(false)" v-if="isSelected" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-checkbox-marked</v-icon>
              </td>
              <td>{{ item.name }}</td>
              <td>{{ getTreatmentType(item.type) }}</td>
              <td class="text-left">
                <v-btn icon @click="campaignLink(item)">
                  <v-icon small color="text">mdi-link</v-icon>
                </v-btn>
              </td>
              <td class="text-right">
                <v-btn icon @click="editCampaign(item)">
                  <v-icon small color="text">mdi-pencil</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>

      <div class="no-data-message" v-else>
        <v-avatar
          size="58"
          light
          class="ma-0 mr-2 custom-avatar-style" 
          rounded="lg"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
          >
          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-filter</v-icon>
        </v-avatar>
        <p>{{ $t('leadCampaign.noCampaigns', $store.state.locale) }}</p>
        <v-btn dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="addCampaign()">
          <v-icon left>mdi-plus</v-icon> {{ $t('leadCampaign.newLeadCampaign', $store.state.locale) }} 
        </v-btn>
      </div>
    </div>

    <!-- / New Lead Campaign Dialog \ -->
      <add-leadcampaign-form
        :campaignTypes="campaignTypes"
        :campaignSpeedItems="campaignSpeedItems"
        :treatments="treatments"
        @leadCampaignAdded="addNewCampaignData($event)"
      ></add-leadcampaign-form>
    <!-- \ New Lead Campaign Dialog / -->

    <!-- / New Lead Campaign Dialog \ -->
      <edit-leadcampaign-form
        :campaignTypes="campaignTypes"
        :campaignSpeedItems="campaignSpeedItems"
        :treatments="treatments"
        @leadCampaignEdited="updateCampaignData($event)"
      ></edit-leadcampaign-form>
    <!-- \ New Lead Campaign Dialog / -->

    <!-- / Campgain Link Dialog \ -->
    <v-dialog v-model="leadShortcut.dialog" persistent scrollable max-width="750px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="pr-2" :style=" { backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].tertiary }">
          <h2 class="white--text">Url om campagne te openen in de widget</h2>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="leadShortcut.dialog = false">
            <v-icon dark color="white">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
        <v-divider></v-divider>

        <v-card-text class="pa-0 ma-0">

            <v-container grid-list-md>
              <v-layout row wrap>
                <v-flex md12>
                  <v-select
                    :items="$store.state.activeUserBranches"
                    v-model="leadShortcut.branch"
                    label="Selecteer vestiging"
                    item-text="name"
                    item-value="id"
                    required
                    outlined
                    class="mt-4"
                    hide-details
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    clearable
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    background-color="background"
                    dense
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    class="my-2"
                    background-color="background"
                    :items="languages"
                    prepend-inner-icon="mdi-flag"
                    item-text="name"
                    item-value="code"
                    v-model="leadShortcut.lang"
                    :label="$t('conversion.selectLanguage', $store.state.locale)"  
                    hide-details
                    >
                  </v-select>
                </v-flex>
                <v-flex md12>
                  <v-select
                    :label="$t('conversion.selectSource', $store.state.locale)"  
                    outlined
                    :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                    class="my-2"
                    background-color="background"
                    :items="allConversionItems"
                    prepend-inner-icon="mdi-forward"
                    item-text="name"
                    item-value="query"
                    v-model="leadShortcut.source"
                    hide-details
                    clearable
                  >
                  </v-select>
                </v-flex>
                <v-flex md12>

                <div style="display: block; padding: 5px 5px 15px 5px;">
                  <div style="display: block; font-style: italic; padding: 5px 10px; color: #fff; background-color: #333;">{{ websiteUrl.url}}</div>
                </div>
              </v-flex>
              </v-layout>
            </v-container>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">

              <v-flex xs12 class="text-right">
                <v-btn dark  block :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" @click="copyTextToClipboard(websiteUrl.url, null)"><v-icon left small color="#fff">mdi-content-copy</v-icon>{{ $t('general.copyUrl', $store.state.locale) }}</v-btn>
                <div style="min-height: 28px">
                  <div style="display: block" v-if="leadShortcut.copy" class="copy-tag-url copied ma-0 pt-2" >{{ $t('general.copied', $store.state.locale) }}</div>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!-- \ Campgain Link Dialog / -->

  </div>
</template>

<script>
import _ from "lodash";
import db from "../firebase/init";
import AddLeadCampaignForm from "../components/modals/AddLeadCampaignForm.vue";
import EditLeadCampaignForm from "../components/modals/EditLeadCampaignForm.vue";
import moment from "moment";
import { bus } from "../main";
import TreatmentTypesVue from './TreatmentTypes.vue';
import companySelector from "@/components/elements/companySelector.vue";
 
export default {
  name: "LeadCampaigns",
  components: {
    "add-leadcampaign-form": AddLeadCampaignForm,
    "edit-leadcampaign-form": EditLeadCampaignForm,
    companySelector:companySelector
  },

  data: function() {
    return {
      leadShortcut: {
        lang: 'nl',
        dialog: false,
        copy: false,
        branch: null,
        campaign: null,
        source: null,
      },
      campaigns: [],
      selected: [],
      languages: [
        {
          name: "Nederlands",
          code: "nl"
        },
        {
          name: "Engels",
          code: "en"
        }
      ],
      generalTags: [
        {
          name: 'Adwords',
          query: 'adw',
          copy: false,
          color: '#1a73e8',
          textColor: '#fff'
        },
        {
          name: 'Mailchimp',
          query: 'mc',
          copy: false,
          color: '#ffe01b',
          textColor: '#333'
        }
      ],
      campaignSpeedItems: [
        {
          name: {
            en: "48 hours",
            nl: "48 uur"
          },
          id: 48
        },
        {
          name: {
            en: "24 hours",
            nl: "24 uur"
          },
          id: 24
        },
        {
          name: {
            en: "12 hours",
            nl: "12 uur"
          },
          id: 12
        },
      ],
      campaignTypes: [
        {
          name: {
            en: "Trial lesson",
            nl: "Proefles"
          },
          id: "trialLesson",
        },
        {
          name: {
            en: "Trial treatment",
            nl: "Proefbehandeling"
          },
          id: "trialTreatment"
        },
        {
          name: {
            en: "consultation",
            nl: "Consult"
          },
          id: "consultation"
        },
        {
          name: {
            en: "Guided tour",
            nl: "Rondleiding"
          },
          id: "guidedTour"
        }
      ],
      treatments: new Array(),
      search: "",
      dataloaded: false,
      headers: [
      {
        text: "Naam",
        align: "left",
        value: "name",
        width: "280px"
      },
      {
        text: "Type",
        align: "left",
        value: "type",
        width: "180px"
      },
      {
        text: "Inschrijf url",
        align: "left",
        value: "",
        width: "180px"
      },
      {
        text: "",
        align: "right",
        sortable: false,
        width: "40px"
      }
    ],
    };
  },

  created() {
    this.loadData();
  },

  methods: {
    async loadData(){
      await Promise.all([this.getCampaigns(), this.getTreatments()])
      this.dataloaded = true;
    },

    async getCampaigns(){
      this.campaigns = new Array();
      await db.collection("leadCampaigns")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .where("deleted.deleted", "==", false)
      .get()
      .then(snap => {
        snap.forEach(doc => {
          let campaign = doc.data();
          campaign.id = doc.id;
          this.campaigns.push(campaign);
        });
      })
      .then(()=>{
        this.dataloaded = true;
      })
      .catch(error=>{
        console.error("Error bij laden campaigns: ", error);
      })
      return
    },

    async getTreatments(){
      await db.collection("treatments")
      .where("companyId", "==", this.$store.state.activeCompany.id)
      .get()
      .then(snap => {
        this.treatments = new Array();
        snap.forEach(doc => {
          let treatment = doc.data();
          treatment.id = doc.id;
          this.treatments.push(treatment);
        });
      })
      .catch(error => {
        console.error("Error getting treatments for campaigns: ", error);
      });
      return
    },

    campaignLink(item){
      this.leadShortcut.branch = this.$store.state.activeUserBranches[0].id,
      this.leadShortcut.campaign = item.id
      this.leadShortcut.dialog = true;
    },
    
    getTreatmentType(id){
      let type = this.campaignTypes.find(element => element.id == id);
      return type.name[this.userLanguage.toLowerCase()];
    },

    addCampaign(){
      this.selected = [];
      bus.$emit("addNewLeadCampaign");
    },

    editCampaign(item){
      bus.$emit("editLeadCampaign", item);
    },

    addNewCampaignData(data){
      this.campaigns.push(data);
    },

    updateCampaignData(edittedCampaign){
      this.campaigns = this.campaigns.map(campaign => { return campaign.id == edittedCampaign.id ? edittedCampaign : campaign });
    },

    copyTextToClipboard(text, index) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }

      navigator.clipboard.writeText(text).then(()=> {
        this.leadShortcut.copy = true
        setTimeout(()=>{ 
          this.leadShortcut.copy = false
        }, 1000);

      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },

    deleteCampaigns(){
      this.selected.forEach(campaign => {
        db.collection("leadCampaigns")
          .doc(campaign.id)
          .set({
            deleted: {
              deleted: true,
              deletedTime: new Date(),
              deletedBy: this.userFirstName + ' ' + this.userLastName,
            },
          }, {merge: true})
          .then(() => {
            // Log Action
            let logData = {
              superAdminAction: false, // Is action only accessible by superadmin?
              actionType: "userAction",
              action: "leadCampaignRemoved",
              actionItem: campaign.name,
              extra:[
                {
                  name: "name",
                  value: campaign.name
                },
                {
                  name: "type",
                  value: campaign.type
                },
                {
                  name: "company",
                  value: campaign.companyId
                }
              ]
            };
            this.$createNewLog("info", logData);
          })
          .then(() => {
            this.campaigns = _.filter(this.campaigns, (item)=> { return _.some(this.selected, (seletedItem)=> { return seletedItem.id == item.id }) ? null : item; });  
            this.selected = new Array();
            console.log("User successfully deleted!");
          })
          .catch(error => {
            this.selected = new Array();
            console.error("Error removing Lead Campaign: ", error);
          });
      });
    },
  },

  computed: {
    websiteUrl(){
      let url = {
        enable: false,
        url: 'leeg'
      }
      let lang = ""
      let branch = ""
      let src = ""

      let compUrl = this.$store.state.activeCompany.url + '#thriveWidget/?comp=' + this.$store.state.activeCompany.id

      if(this.leadShortcut.lang){
        lang = '&lang=' + this.leadShortcut.lang;
      }

      if(this.leadShortcut.source){
        src = '&src=' + this.leadShortcut.source;
      }

      if(this.leadShortcut.branch){
        branch = '&branch=' + this.leadShortcut.branch + "&type=dialog";
      }

      let campaign = '&act=leadForm&lid=' + this.leadShortcut.campaign;

      url.url = compUrl + branch + campaign + lang + src;

      return url
      // http://localhost:8081/?comp=hogansbeauty&branch=hogansbeautybreukelen&act=3&lid=m1SFIHNbGRFS9tn6h3xA&lang=nl
      // http://localhost:8081/?comp=hogansbeauty&branch=hogansbeautybreukelen&act=3&lid=m1SFIHNbGRFS9tn6h3xA
    },

    allConversionItems(){
      let items = _.cloneDeep(this.generalTags)
   
      if(this.$store.state.activeCompany.conversion && this.$store.state.activeCompany.conversion.sources.length > 0){
         this.$store.state.activeCompany.conversion.sources.forEach(source=>{
           items.push(source)
         })
      } 
      return items
    },
  }
};
</script>

<style scoped>


</style>


