<template>
  <div >
    <div v-if="loading" style="position: relative; padding: 200px 0;">
      <div class="thrive-loader"></div>
    </div>

    <div v-else>
      
      <div v-for="(update,index) in conversieUpdatesFiltered" :key="index + updateCounter">
        <v-snackbar
          absolute
          centered
          :style="{top: index * 58 + 'px'}"
          v-model="update.show" 
          top
          :min-width="500"
          color="rgba(0,0,0, 0.55)"
          :timeout="0"
          class="update-snackbar"
          >
          <v-icon class="pr-2" color="#A5D6A7">mdi-emoticon-outline</v-icon>
          <span class="white--text">{{ $t('conversion.newConversionStarted', $store.state.locale) }} {{ updateCompany(update.company).name }}</span>
          <v-btn
            color="white"
            icon
            @click="update.show = false"
          >
            <v-icon color="white">close</v-icon>
          </v-btn>
        </v-snackbar>
      </div>
 
      <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
        <v-container v-if="!loading" grid-list-md fluid class="ma-0 pa-1">
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex md3 xs6>
              <v-btn :color="todaySelected.color" darken depressed :loading="todaySelected.loading" @click="filterConversionToday()" style="margin: 0; height: 40px;" block large><span :style="{color: todaySelected.textColor}">{{ $t('general.today', $store.state.locale) }}</span></v-btn>
            </v-flex>
            <v-flex md3 xs6>
              <v-btn :color="weekSelected.color" depressed :loading="weekSelected.loading" @click="filterConversionWeek()" style="margin: 0; height: 40px;" block large><span :style="{color: weekSelected.textColor}">{{ $t('conversion.thisWeek', $store.state.locale) }}</span></v-btn>
            </v-flex>
            <v-flex md3 xs6>
              <v-btn :color="monthSelected.color" depressed :loading="monthSelected.loading" @click="filterConversionMonth()" style="margin: 0; height: 40px;" block large><span :style="{color: monthSelected.textColor}">{{ $t('conversion.thisMonth', $store.state.locale) }}</span></v-btn>
            </v-flex>
            <v-flex md3 xs6>
              <v-btn :color="periodSelected.color" depressed :loading="periodSelected.loading" @click="filterConversionPeriod()" style="margin: 0; height: 40px;" block large><span :style="{color: periodSelected.textColor}">{{ $t('conversion.selectMonth', $store.state.locale) }}</span></v-btn>
            </v-flex>
          </v-layout>
          <v-layout v-if="filterPeriod" row wrap class="ma-0 pa-0">
            <v-flex md3 xs12>
              <v-select
                single-line
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :items="activeYears"
                dense
                :label="$t('conversion.selectYear', $store.state.locale)"
                hide-details
                outlined
                v-model="selectedPeriodYear"      
              ></v-select>
            </v-flex>
            <v-flex md6 xs12>
              <v-select
                single-line
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                :items="months"
                item-text="name"
                dense
                item-value="id"
                item-disabled="disabled"
                :label="$t('conversion.selectMonth', $store.state.locale)"
                hide-details
                v-model="selectedPeriodMonth"
                outlined 
              ></v-select>
            </v-flex>
            <v-flex md3 xs12>
              <v-btn @click="loadCustomPeriodFilter(selectedPeriodMonth, selectedPeriodYear, 'filter')" :disabled="filterButtonDisabled" :depressed="filterButtonDisabled" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" style="margin: 0; height: 40px;" large block><span style="color:#fff;">{{ $t('conversion.filter', $store.state.locale) }}</span></v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card>

      <div v-if="!loading && !dataLoading" >
        <v-container grid-list-md class="pa-0" fluid>
          <v-layout row wrap class="ma-0 pa-1">
            <v-flex xs12 md3>
              <v-select
              :items="conversionBranches"
              v-model="selectedBranche"
              :label="$t('general.branches', $store.state.locale)"
              item-text="name"
              item-value="id"
              required
              dense
              hide-details
              outlined
              class="ma-2"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              background-color="background"
            ></v-select> 

            </v-flex>
            <v-flex xs12 md6>
              <div class="conversion-selected-period">
                <div v-if="conversionPeriodStart && conversionPeriodEnd">
                  <p class="ma-0" v-if="conversionPeriodType == 'day'">{{ conversionPeriodStart }}</p>
                  <p class="ma-0" v-else>{{ conversionPeriodStart }} t/m {{ conversionPeriodEnd  | moment('subtract','1 days', "D MMMM YYYY") }}</p>
                </div>
              </div>
            </v-flex>
            <v-flex xs12 md3>
              <div class="text-center" v-if="todayIsInConversionPeriod()">
                <span class="text-center" style="min-width:200px; padding: 4px 10px; font-size: 18px; margin: 10px 0 0 0; display: inline-block; background-color: #E8F5E9;" v-if="liveUpdates"><strong>{{ $t('conversion.live', $store.state.locale) }}</strong> <v-icon color="#251433" size="18px" style="margin: -3px 0 0 10px" v-if="liveUpdatesTimer">mdi-timer-outline</v-icon> {{liveUpdatesTimer}} <v-btn icon color="#FF4500" ><v-icon @click="stopLiveUpdates()">mdi-stop</v-icon></v-btn></span>
                <v-tooltip bottom v-if="!liveUpdates">
                  <template v-slot:activator="{ on }">  
                    <v-btn v-on="on" block class="mb-1 mt-2" outlined :color="companySecondaryColor" @click="checkForNewConversions()">{{ $t('conversion.liveView', $store.state.locale) }}</v-btn>
                  </template>
                  <span class="white--text">{{ $t('conversion.liveViewExplain', $store.state.locale) }}</span>
                </v-tooltip>
              </div>
            </v-flex>
          </v-layout>
        </v-container>
      
        <v-container v-if="conversions && conversions.length > 0" grid-list-md fluid class="ma-0 pa-0">
          <v-layout row wrap class="ma-0 pa-1">
            <v-flex md9 xs12>
              <div>
                <v-select
                  class="pa-0 ma-0"
                  v-model="selectedSources"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  background-color="background"
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :items="sources"
                  item-text="name"
                  item-value="query"
                  :label="$t('conversion.selectSources', $store.state.locale)"
                  multiple
                  dense
                  single-line
                  hide-details
                  outlined
                  >
                  <template v-slot:selection="{ item, index }">
                    <v-chip small :color="item.bgColor.hex" v-if="index === 0">
                      <span :style="{ color: item.textColor.hex}">{{ item.name }}</span>
                    </v-chip>
                    <v-chip small :color="item.bgColor.hex" v-if="index === 1">
                      <span :style="{ color: item.textColor.hex}">{{ item.name }}</span>
                    </v-chip>
                    <v-chip small :color="item.bgColor.hex" v-if="index === 2">
                      <span :style="{ color: item.textColor.hex}">{{ item.name }}</span>
                    </v-chip>
                    <v-chip small :color="item.bgColor.hex" v-if="index === 3">
                      <span :style="{ color: item.textColor.hex}">{{ item.name }}</span>
                    </v-chip>
                    <v-chip small :color="item.bgColor.hex" v-if="index === 4">
                      <span :style="{ color: item.textColor.hex}">{{ item.name }}</span>
                    </v-chip>
                    <v-chip small :color="item.bgColor.hex" v-if="index === 5">
                      <span :style="{ color: item.textColor.hex}">{{ item.name }}</span>
                    </v-chip>
                    <span
                      v-if="index === 7"
                      class="grey--text caption"
                    >(+{{ selectedSources.length - 6 }} {{ $t('conversion.other', $store.state.locale) }})</span>
                  </template>
                  <template v-slot:prepend-item>
                    <v-list-item
                      ripple
                      @click="toggle"
                      >
                      <v-list-item-action>
                        <v-icon :color="selectedSources && selectedSources.length > 0 ? $themes[$store.state.companyTheme][$store.state.themeModus].primary : ''">{{ icon }}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('conversion.other', $store.state.locale) }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
        
              
                  </template>

                  <template v-slot:append-item>
                    <v-divider></v-divider>
                  </template>
          
                </v-select>
              </div>
            </v-flex>
            <v-flex md3 xs12>
              <v-btn :disabled="!sourcesFilterChanged" :depressed="!sourcesFilterChanged" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  :dark="sourcesFilterChanged"  @click="filterSources()" style="margin: 0; height: 40px;" block>{{ $t('conversion.filter', $store.state.locale) }}</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      
      <div class="text-center" style="padding: 100px" v-if="!conversionsFiltered || conversionsFiltered.length == 0">
        <v-avatar
          size="58"
          light
          class="ma-0 mr-2 custom-avatar-style" 
          rounded="lg"
          :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
          :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
          >
          <v-icon size="32" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar-alert</v-icon>
        </v-avatar>
        <p class="pa-4">{{ $t('conversion.noConversionsFound', $store.state.locale) }}</p>
      </div>

      <div v-else>
        <v-container class=" ma-0 pa-1" grid-list-md fluid>
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex md2 xs6 d-flex>
              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="usertype-total theme-card theme-shadow" :class="$store.state.companyTheme">
                <div class="usertype-total-number" :style="{color: $vuetify.theme.themes[theme].text}">{{ conversionsFiltered.length }}</div>
                <div class="usertype-total-desc">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span v-if="conversions == 1" :style="{color: $vuetify.theme.themes[theme].text}">{{ $t('conversion.intention', $store.state.locale) }} <v-icon small :color="$vuetify.theme.themes[theme].text" v-on="on">mdi-help-circle</v-icon></span>
                      <span v-else :style="{color: $vuetify.theme.themes[theme].text}">{{ $t('conversion.intentions', $store.state.locale) }} <v-icon small :color="$vuetify.theme.themes[theme].text" v-on="on">mdi-help-circle</v-icon></span>
                    </template>
                    <span class="white--text">{{ $t('conversion.intentionsHint', $store.state.locale) }}</span>
                  </v-tooltip>     
                </div>
              </v-card>
            </v-flex>
            <v-flex md2 xs6 d-flex>
              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="usertype-total theme-card theme-shadow" :class="$store.state.companyTheme">
                <div class="usertype-total-number" :style="{color: $vuetify.theme.themes[theme].text}">{{ newClients }}</div>
                <div class="usertype-total-desc">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span :style="{color: $vuetify.theme.themes[theme].text}">{{ $t('conversion.newClients', $store.state.locale) }} <v-icon small :color="$vuetify.theme.themes[theme].text" v-on="on">mdi-help-circle</v-icon></span>
                    </template>
                    <span class="white--text">{{ $t('conversion.newClientsHint', $store.state.locale) }}</span>
                  </v-tooltip>     
                </div>
              </v-card>
            </v-flex>
            <v-flex md2 xs6 d-flex>
              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="usertype-total theme-card theme-shadow" :class="$store.state.companyTheme">
                <div class="usertype-total-number" :style="{color: $vuetify.theme.themes[theme].text}">{{ conversionsSuccessRate }}%</div>
                <div class="usertype-total-desc">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span :style="{color: $vuetify.theme.themes[theme].text}">{{ $t('conversion.success', $store.state.locale) }} <v-icon small :color="$vuetify.theme.themes[theme].text" v-on="on">mdi-help-circle</v-icon></span>   
                    </template>
                    <span class="white--text">{{ $t('conversion.successHint', $store.state.locale) }}</span>
                  </v-tooltip>             
                </div>
              </v-card>
            </v-flex>
            <v-flex md2 xs6 d-flex>
              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="usertype-total theme-card theme-shadow" :class="$store.state.companyTheme">
                <div class="usertype-total-number" :style="{color: $vuetify.theme.themes[theme].text}">&euro; {{ conversionsValue }}</div>
                <div class="usertype-total-desc">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span :style="{color: $vuetify.theme.themes[theme].text}">{{ $t('conversion.revenue', $store.state.locale) }} <v-icon small :color="$vuetify.theme.themes[theme].text" v-on="on">mdi-help-circle</v-icon></span>   
                    </template>
                    <span class="white--text">{{ $t('conversion.revenueHint', $store.state.locale) }}</span>
                  </v-tooltip>       
                </div>
              </v-card>
            </v-flex>
            <v-flex md2 xs6 d-flex>
              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="usertype-total theme-card theme-shadow" :class="$store.state.companyTheme">
                <div class="usertype-total-number" :style="{color: $vuetify.theme.themes[theme].text}">&euro; {{ extraConversionPotential }}</div>
                <div class="usertype-total-desc">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span :style="{color: $vuetify.theme.themes[theme].text}">{{ $t('conversion.potential', $store.state.locale) }}  <v-icon small :color="$vuetify.theme.themes[theme].text" v-on="on">mdi-help-circle</v-icon></span>   
                    </template>
                    <span class="white--text">{{ $t('conversion.potentialHint', $store.state.locale) }} </span>
                  </v-tooltip>       
                </div>
              </v-card>
            </v-flex>
            <v-flex md2 xs6 d-flex>
              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="usertype-total theme-card theme-shadow" :class="$store.state.companyTheme">
                <div class="usertype-total-number" :style="{color: $vuetify.theme.themes[theme].text}">{{ manualConversions.success }}/{{ manualConversions.called }}</div>
                <div class="usertype-total-desc">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span :style="{color: $vuetify.theme.themes[theme].text}">{{ $t('conversion.successCall', $store.state.locale) }} <v-icon small :color="$vuetify.theme.themes[theme].text" v-on="on">mdi-help-circle</v-icon></span>   
                    </template>
                    <span class="white--text">{{ $t('conversion.successCallHint', $store.state.locale) }} </span>
                  </v-tooltip>       
                </div>
              </v-card>
            </v-flex>

            <v-flex xs12 v-if="messagesSend.number > 0 && ($store.state.activeUserRoleNumber < 2)">
              <v-alert
                color="blue-grey"
                icon="mdi-cellphone-message"
                class="pa-2  ma-0"
                dense
                outlined
                >
                {{ $t('conversion.sendMessages', $store.state.locale) }} <strong>{{ messagesSend.number }}</strong> <span v-if="messagesSend.number > 1"><strong> {{ $t('conversion.messages', $store.state.locale) }} </strong></span> <span v-else><strong> {{ $t('conversion.message', $store.state.locale) }} </strong></span> (&euro;{{ messagesSend.costs }}). <i> {{ $t('conversion.sendMessagesCost', $store.state.locale) }}  &euro; {{ pricePerMessageSend }}</i>.  
              </v-alert>
            </v-flex>

            <v-flex xs12 v-if="adWordsCosts && ($store.state.activeUserRoleNumber < 2 && !$store.state.demoMode)">
              <v-alert
                type="info"
                class="pa-2  ma-0"
                dense
                outlined
                >
                {{ $t('conversion.adwordsBudgetSet', $store.state.locale) }}<strong> &euro;{{ activeCompData.conversion.adwordsBudget }}</strong>. {{ $t('conversion.adwordsCostsEstimated', $store.state.locale) }}<strong>&euro; {{adWordsCosts}}</strong>.
              </v-alert>
            </v-flex>


          </v-layout>
        </v-container>

        <div v-if="selected && selected.length > 0 && !deleting" class="clear" style="padding: 0 10px;">
          <v-btn style="float: right;" fab dark small :color="$themes[$store.state.companyTheme][$store.state.themeModus].secondary" @click="removeConversionsDialog = true">
            <v-icon darken>mdi-delete</v-icon>
          </v-btn> 
        </div>

        <div v-if="deleting">
          <div class="loading-dots">
            <div class="loading-dots-loader"></div>
            <p class="loading-dots-text"> {{ deleteCounter }} {{ $t('conversion.of', $store.state.locale) }} {{ selected.length }} {{ $t('conversion.deleted', $store.state.locale) }}. </p>
          </div>
        </div>

        <v-container v-else  class=" ma-0 pa-1 pt-0" grid-list-md fluid>
          <v-layout row wrap class="ma-0 pa-0">
            <v-flex xs12>
              <v-card flat :color="$themes[$store.state.companyTheme][$store.state.themeModus].card" class="usertype-total theme-card theme-shadow pa-0" :class="$store.state.companyTheme">
                <v-data-table
                v-model="selected"
                :headers="headers"
                :items="conversionsFiltered"
                show-expand
                :sort-desc="true"
                :page.sync="conversionSelectedPage"
                :page-count="conversionSelectorPages"
                hide-default-footer
                :sort-by.sync="tableOptions.sortBy"
                :items-per-page="50"
                :show-select="$store.state.userRoleNumber < 2 && !$store.state.demoMode"
                :expanded.sync="expanded"
                single-expandd
                dense
                class="extra-dense-table"
                >
                <template v-slot:item="{ item, index, isSelected, select, isExpanded, expand  }" >
                  <tr :key="conversionKey + index">
                    <td v-if="$store.state.userRoleNumber < 2 && !$store.state.demoMode">
                      <v-icon @click="select(true)" v-if="!isSelected">mdi-checkbox-blank-outline</v-icon>
                      <v-icon @click="select(false)" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" v-if="isSelected">mdi-checkbox-marked</v-icon>
                    </td>
                    <td class="dense">
                      <div v-if="item.clientData.language == 'nl' || item.clientData.language == 'NL'" class="language-flag nl"> </div>
                      <div v-else-if="item.clientData.language == 'gb' || item.clientData.language == 'GB'" class="language-flag  uk"> </div>
                    </td>
                    <td>
                      <v-tooltip :disabled="(userRole != 'superadmin') && !$store.state.demoMode" right style="cursor: pointer;">
                        <template v-slot:activator="{ on }">                           
                          <v-avatar
                            v-on="on"
                            size="32"
                            v-if="item.clientData.device == 'desktop'"
                            :color="$vuetify.theme.themes[theme].background"
                          >
                            <v-icon small :color="$vuetify.theme.themes[theme].text">mdi-desktop-mac</v-icon>
                          </v-avatar>
                          <v-avatar
                            v-on="on"
                            size="32"
                            v-else-if="item.clientData.device == 'mobile'"
                            :color="$vuetify.theme.themes[theme].background"
                          >
                            <v-icon small :color="$vuetify.theme.themes[theme].text">mdi-cellphone-android</v-icon>
                          </v-avatar>
                          <v-avatar 
                            v-on="on"
                            size="32" 
                            v-else 
                            :color="$vuetify.theme.themes[theme].background"
                            >
                            <span small class="headline" :style="{color: $vuetify.theme.themes[theme].text}">?</span>
                          </v-avatar>
                        </template>
                        <span v-if="item.clientData.userAgent" style="color: #fff"><strong>Device info: </strong>{{ item.clientData.userAgent }} </span>
                        <span v-else style="color: #fff">{{ $t('conversion.noInformationSaved', $store.state.locale) }}</span>
                      </v-tooltip> 
                    </td>                     
                    <td> 
                      <div class="attempt-box"><span>{{ item.attempts }}</span>x</div>
                    </td>
                    <td>
                      <span v-if="(today - averageConversionTime) <  item.timestampUpdated.seconds">
                        <v-tooltip right :disabled="item.progress.completed">
                          <template v-slot:activator="{ on }">
                            <div style=" cursor: pointer; padding: 5px 0 0 0; font-style: italic; text-align: left;">
                              <span v-if="(today - item.timestamp.seconds) == 1">   
                                {{ (today - item.timestamp.seconds) }}  {{ $t('conversion.secondAgo', $store.state.locale) }}
                              </span>
                              <span v-if="(today - item.timestamp.seconds) > 1 && (today - item.timestamp.seconds) < 60">   
                                {{ (today - item.timestamp.seconds) }} {{ $t('conversion.secondsAgo', $store.state.locale) }}
                              </span>
                              <span v-if="(today - item.timestamp.seconds) > 59 && (today - item.timestamp.seconds) < 120">   
                                {{ Math.floor((today - item.timestamp.seconds) / 60) }} {{ $t('conversion.minuteAgo', $store.state.locale) }}
                              </span>
                              <span v-if="(today - item.timestamp.seconds) > 119" >   
                                {{ Math.floor((today - item.timestamp.seconds) / 60) }} {{ $t('conversion.minutesAgo', $store.state.locale) }}
                              </span>
                              <v-icon v-if="!item.progress.completed" small color="#333" v-on="on">mdi-help-circle</v-icon>
                            </div>   
                          </template>
                          <span style="color: #fff">{{ $t('conversion.clientMightBeBusy', $store.state.locale) }}</span>
                        </v-tooltip>
                      </span>

                      <span v-else>{{ item.timestamp.seconds | moment("D MMM YYYY - H:mm")}}u</span>
                    </td>
                    <td>
                      <v-chip v-if="item.clientData.existingClient" small :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" :text-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" light class="mx-1 my-1 custom-chip-style" ><v-icon small left :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account</v-icon>{{ item.clientData.name }}</v-chip>
                      <span v-else>{{ item.clientData.name }}</span>
                      <v-tooltip right v-if="item.widgetData.blacklisted">
                        <template v-slot:activator="{ on }">  
                          <v-icon small v-on="on" color="red">mdi-cancel</v-icon>                                            
                        </template>
                        <span style="color: #fff" v-html="$t('conversion.blacklisted', $store.state.locale)"></span>
                      </v-tooltip>
                      <v-tooltip right v-if="item.clientData.appointmentType && item.clientData.appointmentType == 'bookingAsGuardian'">
                        <template v-slot:activator="{ on }">  
                          <v-icon small v-on="on" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-human-female-boy</v-icon>                                            
                        </template>
                        <span style="color: #fff" v-html="$t('conversion.bookingAsGuardian', $store.state.locale)"></span>
                      </v-tooltip>
                    </td>
                    <td>
                      <span v-for="(type, index) in types" :key="index">
                        <span v-if="item.type == type.id">{{type.name}}</span>
                      </span>
                    </td>
                    <td>
                      <span v-if="item.widgetData && item.widgetData.branch && item.widgetData.branch.name">{{ item.widgetData.branch.name }}</span>
                    </td>
                    <td>
                      <span v-if="item.source">
                        <span v-for="(source, index) in sources" :key="index">
                          <v-chip small :color="source.bgColor.hex" v-if="item.source == source.query"><span :style="{color: source.textColor.hex}">{{source.name}}</span></v-chip>
                        </span> 
                        <v-tooltip right :disabled="!item.originalSource" style="cursor: pointer;">
                          <template v-slot:activator="{ on }">                                              
                            <v-icon small color="#333"  v-if="item.originalSource" v-on="on">mdi-help-circle</v-icon>
                          </template>
                          <span style="color: #fff"> {{ $t('conversion.usedSource', $store.state.locale) }} {{ item.originalSource }}</span>
                        </v-tooltip>
                      </span>

                    </td>              
                    <td>
                      <span v-if="item.value"> 
                        <span v-if="item.value.type == 'amount' && item.value.number > 0"> &euro; {{ formatCurrency(item.value.number) }} </span>
                        <span v-else-if="item.value.type != 'amount' && item.value.number > 0"> {{ item.value.number }} </span>
                        <span v-else>-</span>
                      </span>
                    </td>
                    <td class="text-left">
                      <span v-if="item.widgetData && item.widgetData.downPayment">
                        <v-tooltip left style="cursor: pointer;" v-if="item.widgetData.downPayment.status == 'paid'" >
                          <template v-slot:activator="{ on }">                                              
                            <v-icon color="#56ca00"  v-on="on">mdi-cash-check</v-icon>
                          </template>
                          <span style="color: #fff; font-weight: bold; font-size: 13px" v-html="$t('booking.downPaymentReceived', $store.state.locale)"></span><span style="color: #fff; font-size: 13px">. &euro;{{ item.widgetData.downPayment.amount.toFixed(2) }}</span>
                        </v-tooltip>

                        <v-tooltip left style="cursor: pointer;" v-else-if="item.widgetData.downPayment.status == 'open'" >
                          <template v-slot:activator="{ on }">                                              
                            <v-icon color="#ffb400"  v-on="on">mdi-cash-remove</v-icon>
                          </template>
                          <span style="color: #fff; font-weight: bold; font-size: 13px" v-html="$t('booking.downPaymentGreen', $store.state.locale)"></span><span style="color: #fff; font-size: 13px"> &euro;{{ item.widgetData.downPayment.amount.toFixed(2) }}</span>
                        </v-tooltip>

                        <v-tooltip left style="cursor: pointer;"  v-else-if="item.widgetData.downPayment.status == 'failed' || item.widgetData.downPayment.status == 'expired'">
                          <template v-slot:activator="{ on }">                                              
                            <v-icon color="#ff4c51"  v-on="on">mdi-cash-remove</v-icon>
                          </template>
                          <span style="color: #fff; font-weight: bold; font-size: 13px" v-html="$t('booking.downPaymentNotReceived', $store.state.locale)"></span><span style="color: #fff; font-size: 13px"> &euro;{{ item.widgetData.downPayment.amount.toFixed(2) }}</span>
                        </v-tooltip>
                      </span> 
                    </td>
                    <td>
                      <div v-if="!item.progress.availableForAction && item.progress.completed && item.progress.completedBy == 'client'" >
                        <span v-if="item.widgetData.downPayment && item.widgetData.downPayment.status == 'paid'" style="padding: 3px; display: block; width: 60px; text-align: center; background-color: #8bc34a;">{{ $t('general.yes', $store.state.locale) }} </span>
                        <span v-else-if="item.widgetData.downPayment && item.widgetData.downPayment.status == 'open'" style="padding: 3px; display: block; width: 60px; text-align: center; background-color: #FDD835;">{{ $t('general.no', $store.state.locale) }}</span>
                        <span v-else-if="item.widgetData.downPayment && item.widgetData.downPayment.id && item.widgetData.downPayment.status != 'open' && item.widgetData.downPayment.status != 'paid'" style="padding: 3px; display: block; width: 60px; text-align: center; background-color: #F4511E;">{{ $t('general.no', $store.state.locale) }}</span>
                        <span v-else-if="!item.widgetData.downPayment || !item.widgetData.downPayment.id" style="padding: 3px; display: block; width: 60px; text-align: center; background-color: #8bc34a;">{{ $t('general.yes', $store.state.locale) }}</span>
                      </div>
                      <!-- <span v-if="!item.progress.availableForAction && item.progress.completed && item.progress.completedBy == 'client'" style="padding: 3px; display: block; width: 60px; text-align: center; background-color: #8bc34a;">{{ $t('general.yes', $store.state.locale) }}2</span> -->
                      <span v-else-if="!item.progress.availableForAction && item.progress.completed && item.progress.completedBy != 'client'"> <span style="padding: 3px; margin: 0 3px 0 0; display: inline-block; width: 60px; text-align: center; background-color: #8bc34a;">>{{ $t('general.yes', $store.state.locale) }}</span><v-icon small color="text">mdi-phone-message</v-icon></span> 
                      <span v-else-if="item.progress.availableForAction && item.progress.onHold"> <span style="padding: 5px; margin: 0 3px 0 0; background-color: #64B5F6; display: inline-block; width: 60px; text-align: center;">{{ $t('general.busy', $store.state.locale) }}</span> <v-icon small color="text">mdi-phone-message</v-icon></span>
                      <span v-else-if="item.progress.availableForAction && !item.progress.completed"  style="padding: 5px; background-color: #fb8c00; display: block; width: 60px; text-align: center;">{{ $t('general.no', $store.state.locale) }}</span>
                      <span v-else-if="!item.progress.availableForAction && !item.progress.completed && item.progress.noSuccessReason == 'otherwiseSuccesfull'"> <span style="padding: 3px; margin: 0 3px 0 0; display: inline-block; width: 60px; text-align: center; background-color: #8bc34a;">{{ $t('general.yes', $store.state.locale) }}</span><v-icon small color="text">mdi-phone-message</v-icon></span> 
                      <span v-else-if="!item.progress.availableForAction && !item.progress.completed"> <span style="padding: 5px; margin: 0 3px 0 0; background-color: #ff5252; display: inline-block; width: 60px; text-align: center;">{{ $t('general.no', $store.state.locale) }}</span> <v-icon small color="text">mdi-phone-message</v-icon></span>
                    </td>
                    <td class="text-center" style="font-size: 12px;">
                      <div style="display: inline-block" v-if="$store.state.activeUserRoleNumber < 2 && item.widgetData && item.widgetData.error"> 
                        <v-tooltip left style="cursor: pointer;">
                          <template v-slot:activator="{ on }">                           
                            <v-icon color="error" v-on="on">mdi-flag</v-icon>
                          </template>
                          <span style="color: #fff"><strong>BookingCache DocId:</strong> {{ item.widgetData.error.cacheId }} <strong><br>Error:</strong> {{ item.widgetData.error.log}} </span>
                        </v-tooltip> 
                      </div>
                    
                      <v-tooltip left v-if="item.progress.callMe || item.clusterCallMe" style="cursor: pointer;">
                        <template v-slot:activator="{ on }">
                          <v-icon  v-on="on"  color="success">mdi-card-account-phone</v-icon>                                             
                        </template>
                        <span style="color: #fff">{{ $t('conversion.callClientforHelp', $store.state.locale) }}</span>
                      </v-tooltip>

                      <v-tooltip left v-if="item.smsSend || item.clusterSmsSend" style="cursor: pointer;">
                        <template v-slot:activator="{ on }">
                          <v-icon  v-on="on"  color="text" >mdi-cellphone-message</v-icon>                                             
                        </template>
                        <span style="color: #fff">{{ $t('conversion.smsSendToClient', $store.state.locale) }}</span>
                      </v-tooltip>

                      <v-tooltip left v-if="item.widgetData && item.widgetData.closeReason || item.clusterCloseReason" style="cursor: pointer;">
                        <template v-slot:activator="{ on }">
                          <v-icon  v-on="on"  color="text">mdi-message-text</v-icon>                                             
                        </template>
                        <span style="color: #fff">{{ $t('conversion.clientWidgetFeedback', $store.state.locale) }}</span>
                      </v-tooltip>


                      <v-tooltip left v-if="!item.progress.completed && (((today - item.timestampUnix)/60).toFixed(0) <= 20)" style="cursor: pointer;">
                        <template v-slot:activator="{ on }">
                          <v-icon  v-on="on"  color="red" >mdi-fire</v-icon>                                             
                        </template>
                        <span style="color: #fff">{{ $t('conversion.perfectTimeforHelp', $store.state.locale) }} </span>
                      </v-tooltip>
                        
                    </td>
                    <td>           
                      <v-btn icon @click="expand(true), checkBusyStatus(item.progress.onHold, item.progress.noSuccessReason)" v-if="!isExpanded"><v-icon dark>mdi-chevron-down</v-icon></v-btn>
                      <v-btn icon @click="expand(false)" v-if="isExpanded"><v-icon dark>mdi-chevron-up</v-icon></v-btn>
                    </td>
                  </tr>          
                </template>

                <template v-slot:expanded-item="{ headers, item, index }">
                  <td :colspan="headers.length" style="padding: 0!important;">
                  <v-card flat :style="{backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].menuhover, borderColor: $themes[$store.state.companyTheme][$store.state.themeModus].menuborder}" >
                    <v-card-text class="ma-0">
                      <v-container class="extra-conversion-info ma-0 pa-0" grid-list-md fluid>
                        <v-layout row wrap class="ma-0 pa-0">
                          <v-flex md4 xs6>
                            <div class="conversion-client-info clear">
                              <v-avatar
                                size="36"
                                light
                                style="float: left"
                                class="ma-0 mr-2 custom-avatar-style" 
                                rounded="lg"
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"    
                                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                >
                                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-account</v-icon>
                              </v-avatar>
                              <h3 class="pt-1 pb-4">{{ $t('conversion.contactInfo', $store.state.locale) }}</h3>
                              <v-divider class="mb-4 mr-4"></v-divider>
                              <div class="clear">
                                <div v-if="item.clientData.appointmentType == 'bookingAsGuardian'">
                                  <div class="clear" @click="copyTextToClipboard(item.clientData.name, 'minorName')"><span class="info-label" >{{ $t('general.name', $store.state.locale) }}</span> <span class="info-data" v-if="item.clientData.minorData && item.clientData.minorData.name"> {{ item.clientData.minorData.name }} <span v-if="copyType =='minorName'" style="font-style: italic; font-size: 10px!important">Gekopieërd</span><v-icon v-else size="12" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon></span><span class="info-data" v-else> - </span></div>
                                  <div class="clear" ><span class="info-label" >{{ $t('general.type', $store.state.locale) }}</span> <span class="info-data" v-if="item.clientData.minorData && item.clientData.minorData.minorType"> {{ $t('general.'+item.clientData.minorData.minorType, $store.state.locale) }}</span><span class="info-data" v-else> - </span></div>
                                  <div class="mt-3"><strong> {{ $t('clients.guardianAppointment', $store.state.locale) }}: </strong></div>
                                </div>
                                <div @click="copyTextToClipboard(item.clientData.name, 'name')"><span class="info-label" >{{ $t('general.name', $store.state.locale) }}</span> <span class="info-data" v-if="item.clientData.name"> {{ item.clientData.name }} <span v-if="copyType =='name'" style="font-style: italic; font-size: 10px!important">Gekopieërd</span><v-icon v-else size="12" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon></span><span class="info-data" v-else> - </span></div>
                                <div @click="copyTextToClipboard(item.clientData.phone, 'phone')"><span class="info-label" >{{ $t('general.phone', $store.state.locale) }}</span> <span class="info-data"  v-if="item.clientData.phone">{{ item.clientData.phone }} <span v-if="copyType =='phone'" style="font-style: italic; font-size: 10px!important"">Gekopieërd</span><v-icon v-else size="12" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon></span><span class="info-data"  v-else> - </span></div>
                                <div @click="copyTextToClipboard(item.clientData.email, 'email')"><span class="info-label" >{{ $t('general.email', $store.state.locale) }}</span> <span class="info-data"  v-if="item.clientData.email">{{ item.clientData.email }} <span v-if="copyType =='email'" style="font-style: italic; font-size: 10px!important">Gekopieërd</span><v-icon v-else size="12" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon></span><span class="info-data" v-else> - </span></div>
                                <div v-if="item.startUrl"><span class="info-label">{{ $t('conversion.startUrl', $store.state.locale) }}</span> <span class="info-data" >{{ item.startUrl }}</span></div>
                                <div v-if="($store.state.userRoleNumber < 2 && !$store.state.demoMode)"><span class="info-label">{{ $t('conversion.ipAddress', $store.state.locale) }} </span> <span class="info-data"  v-if="item.clientData.ip && item.clientData.ip.query">{{ item.clientData.ip.query }}</span><span class="info-data" v-else> - </span></div>
                                <div v-if="($store.state.userRoleNumber < 2 && !$store.state.demoMode)"><span class="info-label">Widget versie:</span> <span class="info-data"  v-if="item.widgetData.version">{{ item.widgetData.version }}</span><span class="info-data" v-else> - </span></div>
                                <div v-if="($store.state.userRoleNumber < 2 && !$store.state.demoMode)" @click="copyTextToClipboard(item.widgetData.error.cacheId, 'cacheId')" >
                                  <span class="info-label">Cache Doc:</span> <span class="info-data"  v-if="item.widgetData.error && item.widgetData.error.cacheId">{{ item.widgetData.error.cacheId }}  
                                  <span v-if="copyType =='cacheId'" style="font-style: italic; font-size: 10px!important">Gekopieërd</span><v-icon v-else size="12" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon>
                                </span><span class="info-data" v-else> - </span></div>
                                <div v-if="($store.state.userRoleNumber < 2 && !$store.state.demoMode)" @click="copyTextToClipboard(item.widgetData.downPayment.id , 'downPaymentId')" >
                                  <span class="info-label">prePayment Doc:</span> 
                                  <span class="info-data"  v-if="item.widgetData.downPayment && item.widgetData.downPayment">{{ item.widgetData.downPayment.id }}  
                                    <span v-if="copyType =='downPaymentId'" style="font-style: italic; font-size: 10px!important">Gekopieërd</span>
                                    <v-icon v-else size="12" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-content-copy</v-icon>
                                  </span>
                                  <span class="info-data" v-else> - </span>
                                </div>
                              </div>

                              <div class="clear py-2" v-if="item.widgetData.blacklisted" >
                                <v-alert outlined dense text type="error" class="pa-1" v-if="item.widgetData.blacklisted" >
                                 <div v-html="$t('conversion.blacklisted', $store.state.locale)"> </div> 
                                </v-alert>
                              </div>

                              <div class="clear py-2" v-if="!item.widgetData.blacklisted" >
                                <v-btn @click="setPersonToBlacklist(item)" small outlined :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">Persoon op de zwarte lijst zetten</v-btn>
                              </div>

                            </div>

                            <div>
                              <div v-if="item.progress.callMe" style=" margin: 5px 0; padding: 5px; border-radius: 3px; background-color: #4caf50; display: inline-block">
                                <v-icon  color="menu" >mdi-card-account-phone</v-icon>                                             
                                <span class="px-2" style="color: #fff">{{ $t('conversion.callClientforHelp', $store.state.locale) }}</span>
                              </div>
                            </div>

                            <div v-if="item.smsSend" style=" margin: 5px 0; padding: 5px; border-radius: 3px;">
                              <v-icon  color="text" >mdi-cellphone-message</v-icon>                                             
                              <span class="px-2">{{ $t('conversion.smsSendToClient', $store.state.locale) }}</span>
                            </div>

                            <div>
                              <div v-if="item.widgetData && item.widgetData.closeReason" style=" margin: 5px 0; padding: 5px; border-radius: 3px; display: inline-block" :style="{backgroundColor: $vuetify.theme.themes[theme].text}">
                                <v-icon  color="menu" >mdi-message-text</v-icon>
                                <span class="px-2" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].menu}">
                                  <strong>{{ $t('general.feedback', $store.state.locale) }}</strong>
                                    <span :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].menu}" v-if="item.widgetData.closeReason == 'noSpaceFound'">{{ $t('conversion.noTimeFound', $store.state.locale) }}</span>
                                    <span :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].menu}" v-else-if="item.widgetData.closeReason == 'restartingWidget'">{{ $t('conversion.restartWidget', $store.state.locale) }}</span>
                                    <span :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].menu}" v-else-if="item.widgetData.closeReason == 'widgetError'">{{ $t('conversion.widgetNotWorking', $store.state.locale) }}</span>
                                    <span :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].menu}" v-else>{{ item.widgetData.closeReason }}</span>
                                </span>                                             
                              </div>
                            </div>
                          </v-flex>
                          <!-- / PROGRESS COLUMN \ -->
                          <v-flex md4 xs6> 
                            <div v-if="item.type == 'bookingwidget'" class="conversion-client-info">
                              <v-avatar
                                size="36"
                                light
                                style="float: left"
                                class="ma-0 mr-2 custom-avatar-style" 
                                rounded="lg"
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                >
                                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-calendar</v-icon>
                              </v-avatar>
                              <h3 class="pt-1 pb-4">{{ $t('conversion.appointmentDetails', $store.state.locale) }}</h3>
                              <v-divider class="mb-4 mr-4"></v-divider>
                              
                              <div  style="padding: 0 15px 0 0;">
                                <div class="close-conversion-buttons" style="margin: 0 0 10px 0;" v-if="!item.progress.completed && item.progress.availableForAction">
                                  <div class="close-conversion-button left" :class="{'active': closeConversionSuccessfull == true}" @click="closeConversionSuccessfull = true, closeConversionReason = null">{{ $t('conversion.information', $store.state.locale) }}</div>
                                  <div class="close-conversion-button middle" :class="{'active': closeConversionSuccessfull == 'wait'}" @click="closeConversionSuccessfull = 'wait', checkBusyStatus(item.progress.onHold, item.progress.noSuccessReason)">{{ $t('conversion.busy', $store.state.locale) }}</div>
                                  <div class="close-conversion-button right" :class="{'active': closeConversionSuccessfull == false}" @click="closeConversionSuccessfull = false, closeConversionReason = null">{{ $t('conversion.close', $store.state.locale) }}</div>
                                </div>
                                <div v-if="closeConversionSuccessfull == true">
                                  <div class="info-wrapper">
                                    <div><span class="info-label">{{ $t('general.branch', $store.state.locale) }}:</span> <span class="info-data" v-if="item.widgetData && item.widgetData.branch && item.widgetData.branch.name">{{ item.widgetData.branch.name }}</span><span class="info-data"  v-else> - </span></div>
                                    <div><span class="info-label">{{ $t('general.date', $store.state.locale) }}:</span> <span class="info-data" v-if="item.widgetData && item.widgetData.date">{{ item.widgetData.date | moment("D MMMM YYYY") }}</span><span class="info-data"  v-else> - </span></div>
                                    <div><span class="info-label">{{ $t('general.time', $store.state.locale) }}:</span> <span class="info-data" v-if="item.widgetData && item.widgetData.time">{{ item.widgetData.time }}</span><span class="info-data"  v-else> - </span></div>
                                    <div><span class="info-label">{{ $t('conversion.treatments', $store.state.locale) }}:</span>
                                      <span class="info-data" v-if="item.widgetData && item.widgetData.treatments && item.widgetData.treatments">
                                        <div v-for="(treatment, index) in item.widgetData.treatments" :key="index">
                                          &#8226; {{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, treatment.treatmentTitle) }} 
                                        </div>
                                      </span>
                                      <span class="info-data"  v-else> - </span>
                                    </div>
                                    <div><span class="info-label">{{ $t('conversion.employee', $store.state.locale) }}:</span> <span class="info-data" v-if="item.widgetData && item.widgetData.employee && item.widgetData.employee.name">{{ item.widgetData.employee.name }}</span><span class="info-data"  v-else> - </span></div>
                                    <div><span class="info-label">{{ $t('conversion.processingTime', $store.state.locale) }}:</span> <span class="info-data" v-if="item.timestamp.seconds != item.timestampUpdated.seconds">{{ Math.ceil((item.timestampUpdated.seconds - item.timestamp.seconds) / 60) }} <span v-if="Math.ceil((item.timestampUpdated.seconds - item.timestamp.seconds) / 60) == 1">{{ $t('general.minute', $store.state.locale) }}</span><span v-else>{{ $t('general.minutes', $store.state.locale) }}</span></span><span class="info-data"  v-else> - </span></div>
                                  </div>

                                  <!-- <v-btn small v-if="!item.progress.completed && item.progress.availableForAction" block outlined @click="copyClientData(item.clientData)" class="ma-0 mt-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"  dark>{{ $t('conversion.copyClientData', $store.state.locale) }}</v-btn>  -->
                        
                                  <v-btn small v-if="!clientDataCopied" block outlined @click="copyClientData(item.clientData)" class="ma-0 mt-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"  dark>{{ $t('conversion.copyClientData', $store.state.locale) }}</v-btn> 
                                  <div v-else class="text-center mt-4" style="height: 24px;"><v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].quaternary">mdi-check-bold</v-icon> <i>{{ $t('general.copied', $store.state.locale) }}</i></div>
                                  <v-btn small v-if="item.widgetData.date" block outlined @click="goToCalendar(item)" class="ma-0 mt-3" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  dark>{{ $t('conversion.gotoDate', $store.state.locale) }} {{ item.widgetData.date | moment("D MMMM") }}</v-btn> 

                                </div>

                                <div v-else-if="closeConversionSuccessfull == 'wait'">
                                  <p style="padding: 5px 0; font-size: 16px; margin: 0;">{{ $t('conversion.waitForClosing', $store.state.locale) }}</p>

                                  <v-container  grid-list-md fluid class="ma-0 pa-0">
                                    <p><i>{{ $t('conversion.selectWaitReason', $store.state.locale) }}</i></p>
                                    <v-flex xs12>
                                      <v-radio-group v-model="closeConversionReason"  hide-details>
                                        <v-radio value="tryAgain" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                                          <template v-slot:label>
                                            <div class="custom-label">{{ $t('conversion.clientWillTryAgain', $store.state.locale) }}</div>
                                          </template>
                                        </v-radio>
                                        <v-radio value="notReachable" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                                          <template v-slot:label>
                                            <div class="custom-label">{{ $t('conversion.clientNotReachable', $store.state.locale) }}</div>
                                          </template>
                                        </v-radio>
                                        <v-radio value="otherReasonWait" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                                          <template v-slot:label>
                                            <div class="custom-label">{{ $t('conversion.other', $store.state.locale) }}</div>
                                          </template>
                                        </v-radio>
                                      </v-radio-group>

                                      <v-text-field
                                        v-if=" closeConversionReason == 'otherReasonWait'"
                                        :label="$t('conversion.reason', $store.state.locale)"
                                        style="background-color: #fff; margin: 15px 0 0 0;"
                                        hide-details
                                        v-model="closeConversionReasonOtherWait"
                                        outlined
                                        height="48px"
                                      ></v-text-field>

                                    </v-flex>
                                    <v-flex xs12>
                                        <div style="padding: 10px 0 0 0;"> 
                                          <v-btn block large @click="updateConversionWait(item, index)" class="ma-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  dark>{{ $t('conversion.waitingForClosing', $store.state.locale) }}</v-btn> 
                                          <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                              <div style=" cursor: pointer; padding: 5px 0 0 0; font-style: italic; text-align: center;">{{ $t('conversion.moreInformation', $store.state.locale) }} <v-icon small color="#ccc" v-on="on">mdi-help-circle</v-icon></div>   
                                            </template>
                                            <span style="color: #fff">{{ $t('conversion.convertLater', $store.state.locale) }} </span>
                                          </v-tooltip>
                                        </div>
                                      </v-flex>
                                  </v-container>
                                </div>
                                
                                <div v-else>
                                  <p style="padding: 5px 0; font-size: 16px; margin: 0;">{{ $t('conversion.closeConversion', $store.state.locale) }} </p>

                                  <v-container  grid-list-md fluid class="ma-0 pa-0">
                                    <p><i>{{ $t('conversion.convertLater', $store.state.locale) }}</i></p>
                                    <v-flex xs12>
                                      <v-radio-group v-model="closeConversionReason"  hide-details>
                                        <v-radio value="otherwiseSuccesfull" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                                          <template v-slot:label>
                                            <div class="custom-label">{{ $t('conversion.selectFailReason', $store.state.locale) }}  </div>
                                          </template>
                                        </v-radio>
                                        <v-radio value="noDeal" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                                          <template v-slot:label>
                                            <div class="custom-label">{{ $t('conversion.clientFoundThirdParty', $store.state.locale) }}  </div>
                                          </template>
                                        </v-radio>
                                        <v-radio value="otherReason" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" >
                                          <template v-slot:label>
                                            <div class="custom-label">{{ $t('conversion.other', $store.state.locale) }}</div>
                                          </template>
                                        </v-radio>
                                      </v-radio-group>

                                      <v-text-field
                                        v-if=" closeConversionReason== 'otherReason'"
                                        :label="$t('conversion.reason', $store.state.locale)"
                                        style="background-color: #fff; margin: 15px 0 0 0;"
                                        hide-details
                                        v-model="closeConversionReasonOther"
                                        outlined
                                        height="48px"
                                      ></v-text-field>

                                    
                                    </v-flex>
                                    <v-flex xs12>
                                        <div style="padding: 10px 0 0 0;">
                                          <v-btn block large @click="updateConversionNoSuccess(item, index)" class="ma-0" :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"  dark>{{ $t('conversion.closeWithoutSuccess', $store.state.locale) }}</v-btn> 
                                          <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                              <div style=" cursor: pointer; padding: 5px 0 0 0; font-style: italic; text-align: center;">{{ $t('conversion.moreInformation', $store.state.locale) }} <v-icon small color="#ccc" v-on="on">mdi-help-circle</v-icon></div>   
                                            </template>
                                            <span style="color: #fff">{{ $t('conversion.closeWithoutSuccessHint', $store.state.locale) }}</span>
                                          </v-tooltip>
                                        </div>
                                      </v-flex>
                                  </v-container>
                                </div>
                                
                              </div>

                            </div>

                            <div v-if="item.type == 'wordpressForm' && item.wordpressData" class="conversion-client-info">
                              <h3>{{ $t('conversion.formInfo', $store.state.locale) }}</h3>
                              <div class="info-wrapper">
                              <div><span class="info-label">{{ $t('conversion.form', $store.state.locale) }}:</span> <span class="info-data"  v-if="item.wordpressData.formName">{{ item.wordpressData.formName }}</span><span class="info-data" v-else> - </span></div>                      
                              <div><span class="info-label">{{ $t('conversion.formSubject', $store.state.locale) }}:</span> <span class="info-data" v-if="item.wordpressData.subject">{{ item.wordpressData.subject }}</span><span class="info-data"  v-else> - </span></div>
                              <div><span class="info-label">{{ $t('conversion.formMessage', $store.state.locale) }}:</span> <span class="info-data" v-if="item.wordpressData.message">{{ item.wordpressData.message }}</span><span class="info-data"  v-else> - </span></div>
                              </div>
                            </div>


                          <!-- \ APPOINTMENT DATA COLUMN / -->
                          </v-flex>
                          <!-- / PROGRESS COLUMN \ -->
                          <v-flex md4 xs6>
                            <div class="conversion-client-info">
                              <v-avatar
                                size="36"
                                light
                                style="float: left"
                                class="ma-0 mr-2 custom-avatar-style" 
                                rounded="lg"
                                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                                :style="{'color': $themes[$store.state.companyTheme][$store.state.themeModus].primary}" 
                                >
                                <v-icon :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary">mdi-progress-check</v-icon>
                              </v-avatar>
                              <h3 class="pt-1 pb-4">{{ $t('conversion.status', $store.state.locale) }}</h3>
                              <v-divider class="mb-4 mr-4"></v-divider>

                              <p style="margin: 0" v-if="item.progress.completed"><span class="info-label">{{ $t('conversion.conversionSuccess', $store.state.locale) }}: </span>{{ $t('general.yes', $store.state.locale) }}</p>
                              <p style="margin: 0" v-if="!item.progress.completed"><span class="info-label">{{ $t('conversion.conversionSuccess', $store.state.locale) }}:</span>{{ $t('general.no', $store.state.locale) }}</p>
                              <p style="margin: 0" ><span class="info-label">{{ $t('conversion.completedBy', $store.state.locale) }}:</span>
                                <span v-if="!item.progress.availableForAction && item.progress.completedBy == 'client'">Cliënt</span>
                                <span v-else-if="!item.progress.availableForAction && item.progress.completedBy != 'client'">{{ item.progress.completedBy }} </span>
                                <span v-else> - </span>
                              </p>
                              <p style="margin: 0" ><span class="info-label">{{ $t('conversion.completedOn', $store.state.locale) }}:</span> <span v-if="!item.progress.availableForAction && item.progress.completedOn">{{ item.progress.completedOn.seconds | moment("D MMMM YYYY - H:mm") }}u</span> <span v-else> - </span></p>
                              <p style="margin: 0" v-if="!item.progress.availableForAction && !item.progress.completed">
                                <span class="info-label">{{ $t('conversion.reasonNoSuccess', $store.state.locale) }}:</span> <span v-if="!item.progress.availableForAction && item.progress.noSuccessReason">
                                <span v-if="item.progress.noSuccessReason == 'notReachable'">{{ $t('clientNotReachable', $store.state.locale) }}</span>
                                <span v-else-if="item.progress.noSuccessReason == 'otherwiseSuccesfull'">{{ $t('conversion.apointmentMadeOtherwise', $store.state.locale) }}</span>
                                <span v-else-if="item.progress.noSuccessReason == 'noDeal'">{{ $t('conversion.clientFoundThirdParty', $store.state.locale) }}</span>
                                <span v-else>{{ item.progress.noSuccessReason}}</span>
                                </span> 
                                <span v-else> - </span>
                              </p>
                              
                            </div>
                          </v-flex>
                          <!-- \ PROGRESS COLUMN / -->
                        </v-layout>
                      </v-container>

                      <v-container class="extra-conversion-info ma-0 pa-0" grid-list-md fluid>
                        <v-layout row wrap class="ma-0 pa-0" v-if="item.attempts > 0 && item.attemptsCollection && item.type == 'bookingwidget'">
                          <h2 style="display: block; width: 100%;">{{ $t('conversion.seeAllIntentions', $store.state.locale) }} </h2>
                          <p style="width: 100%">{{ $t('conversion.multipleIntentions', $store.state.locale) }} </p>
                          <v-flex md4 xs6 v-for="(attempt, index) in item.attemptsCollection" :key="index" d-flex>
                            <v-card color="menu" class="pa-3 conversion-client-info" flat style ="width: 100%">
                                <div class="info-wrapper">
                                  <h3 style="font-weight: bold; display: inline-block">{{ attempt.timestamp.seconds | moment("D MMMM YYYY - H:mm")}}u</h3>

                                  <div style="display: inline-block" v-if="$store.state.activeUserRoleNumber < 2 && attempt.widgetData && attempt.widgetData.error"> 
                                    <v-tooltip left style="cursor: pointer;">
                                      <template v-slot:activator="{ on }">                           
                                        <v-icon color="error" v-on="on">mdi-flag</v-icon>
                                      </template>
                                      <span style="color: #fff"><strong>BookingCache DocId:</strong> {{ attempt.widgetData.error.cacheId }} <strong><br>Error:</strong> {{ attempt.widgetData.error.log}} </span>
                                    </v-tooltip> 
                                  </div>
                                
                                  <div><span class="info-label">{{ $t('general.name', $store.state.locale) }}:</span> <span class="info-data" v-if="attempt.clientData && attempt.clientData.name"> {{ attempt.clientData.name }}</span><span class="info-data" v-else> - </span></div>
                                  <div><span class="info-label">{{ $t('general.phone', $store.state.locale) }}:</span> <span class="info-data"  v-if="attempt.clientData && attempt.clientData.phone">{{ attempt.clientData.phone }}</span><span class="info-data"  v-else> - </span></div>
                                  <div><span class="info-label">{{ $t('general.email', $store.state.locale) }}:</span> <span class="info-data"  v-if="attempt.clientData && attempt.clientData.email">{{ attempt.clientData.email }}</span><span class="info-data" v-else> - </span></div>
                                  <div v-if="($store.state.activeUserRoleNumber < 2 && !$store.state.demoMode) && attempt.clientData && attempt.clientData.ip && attempt.clientData.ip.query"><span class="info-label">{{ $t('conversion.ipAddress', $store.state.locale) }}:</span> <span class="info-data"  v-if="attempt.clientData.ip && attempt.clientData.ip.query">{{ attempt.clientData.ip.query }}</span><span class="info-data" v-else> - </span></div>
                                  <div style="width: 100%; height: 15px; float: left;"></div>
                                  <div><span class="info-label">{{ $t('general.branch', $store.state.locale) }}:</span> <span class="info-data" v-if="attempt.widgetData && attempt.widgetData.branch && attempt.widgetData.branch.name">{{ attempt.widgetData.branch.name }}</span><span class="info-data"  v-else> - </span></div>
                                  <div><span class="info-label">{{ $t('general.date', $store.state.locale) }}:</span> <span class="info-data" v-if="attempt.widgetData && attempt.widgetData.date">{{ attempt.widgetData.date | moment("D MMMM YYYY") }}</span><span class="info-data"  v-else> - </span></div>
                                  <div><span class="info-label">{{ $t('general.time', $store.state.locale) }}:</span> <span class="info-data" v-if="attempt.widgetData && attempt.widgetData.time">{{ attempt.widgetData.time }}u</span><span class="info-data" v-else> - </span></div>
                                  <div><span class="info-label">{{ $t('conversion.treatments', $store.state.locale) }}:</span>
                                    <span class="info-data" v-if="attempt.widgetData && attempt.widgetData.treatments && attempt.widgetData.treatments">
                                      <div v-for="(treatment, index) in attempt.widgetData.treatments" :key="index">
                                        &#8226; {{ $autoLanguageSelector($store.state.locale, $store.state.companyLanguages, treatment.treatmentTitle) }} 
                                      </div>
                                    </span>
                                    <span class="info-data"  v-else> - </span>
                                  </div>
                                  <div><span class="info-label">{{ $t('conversion.employee', $store.state.locale) }}:</span> <span class="info-data" v-if="attempt.widgetData && attempt.widgetData.employee && attempt.widgetData.employee.name">{{ attempt.widgetData.employee.name }}</span><span class="info-data"  v-else> - </span></div>
                                  <div v-if="attempt.startUrl"><span class="info-label">{{ $t('conversion.startUrl', $store.state.locale) }}</span> <span class="info-data">{{ attempt.startUrl }}</span></div>
                                  <div>
                                    <div v-if="attempt.progress.callMe" style=" margin: 5px 0; padding: 5px; border-radius: 3px; background-color: #4caf50; display: inline-block">
                                      <v-icon  color="menu" >mdi-card-account-phone</v-icon>                                             
                                      <span class="px-2" style="color: #fff">{{ $t('conversion.callClientforHelp', $store.state.locale) }}</span>
                                    </div>
                                  </div>
                                  <div v-if="attempt.smsSend" style=" margin: 5px 0; padding: 5px; border-radius: 3px">
                                    <v-icon color="text">mdi-cellphone-message</v-icon>                                             
                                    <span class="px-2">{{ $t('conversion.smsSendToClient', $store.state.locale) }}</span>
                                  </div>
                                  <div>
                                    <div v-if="attempt.widgetData && attempt.widgetData.closeReason" style=" margin: 5px 0; padding: 5px; border-radius: 3px; display: inline-block" :style="{backgroundColor: $vuetify.theme.themes[theme].text}">
                                      <v-icon  color="menu" >mdi-message-text</v-icon>
                                      <span class="px-2" :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].menu}">
                                        <strong>{{ $t('general.feedback', $store.state.locale) }}</strong>                                                                     
                                        <span :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].menu}" v-if="attempt.widgetData.closeReason == 'noSpaceFound'">{{ $t('conversion.noTimeFound', $store.state.locale) }}</span>
                                        <span :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].menu}" v-else-if="attempt.widgetData.closeReason == 'restartingWidget'">{{ $t('conversion.restartWidget', $store.state.locale) }}</span>
                                        <span :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].menu}" v-else-if="attempt.widgetData.closeReason == 'widgetError'">{{ $t('conversion.widgetNotWorking', $store.state.locale) }}</span>
                                        <span :style="{color: $themes[$store.state.companyTheme][$store.state.themeModus].menu}" v-else>{{ attempt.widgetData.closeReason }}</span>
                                      </span> 
                                    </div>
                                  </div>

                                </div>
                            </v-card>
                          </v-flex>
                        </v-layout>
                      </v-container>

                    </v-card-text>
                  </v-card>
                  <v-divider></v-divider>
                </td>
                </template>

                </v-data-table>
                <div v-if="conversionsFiltered && conversionsFiltered.length > 50">
                  <v-divider></v-divider>
                  <div class="text-center pa-4" >
                    <v-pagination
                      :color=" $themes[$store.state.companyTheme][$store.state.themeModus].primary"
                      v-model="conversionSelectedPage"
                      :length="conversionSelectorPages"
                      :total-visible="9"
                    ></v-pagination>
                  </div>
                </div>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </div>

    <!-- / Delete source dialog \ -->
    <v-dialog v-model="removeConversionsDialog" width="400" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3 v-if="selected.length == 1" style="text-transform: capitalize">{{ $t('conversion.widgetConversion', $store.state.locale) }}</h3>
          <h3 v-else style="text-transform: capitalize">{{ $t('conversion.widgetConversions', $store.state.locale) }} {{ $t('conversion.delete', $store.state.locale) }}</h3>

          <v-spacer></v-spacer>
          <v-btn icon dark @click="removeConversionsDialog = false">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <span style=" display: block; text-align: center!important;">{{ $t('conversion.deleteSure', $store.state.locale) }}  
            <span v-if="selected.length == 1">{{ $t('conversion.widgetConversion', $store.state.locale) }}</span>
            <span v-else>{{ $t('conversion.widgetConversions', $store.state.locale) }}</span> 
            {{ $t('conversion.deleteForever', $store.state.locale) }}
          </span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left" v-if="$vuetify.breakpoint.name != 'sm' && $vuetify.breakpoint.name != 'xs'">
                <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                  @click="removeConversionsDialog = false"
                >
                  {{ $t('general.cancel', $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  dark
                  depressed
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  @click="removeConversions()"
                >
                  {{ $t('general.remove', $store.state.locale) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- \ Delete source dialog / -->

      <!-- / Customer info dialog \ -->
    <customer-information-dialog
      :customerDialog="clientDialog"
      :selectedClientOriginal="selectedClientOriginal"
      :selectedClient="selectedClient"
      :activeCompData="activeCompData"
      @customerDialogChange="clientDialog = $event;"
    ></customer-information-dialog>

  </div>
</div>
</template>



<script>

import { bus } from "../main";
import moment from "moment";
import db from "../firebase/init";
import platform from 'platform'; 
import lodash from 'lodash';
import { cashConv } from "../modules/quickWins";

import CustomerInformationDialog from "../components/modals/CustomerInformationDialog.vue";


export default {
  name: "WidgetStats",
  props: {
    urlStart: String,
    urlEnd: String,
    
  },
  components: {
    "customer-information-dialog": CustomerInformationDialog,
  },

  data: function () {
    return {
      expanded: [],
      selectedBranche: [],
      activeCompData: new Object(),
      activeCompDataOriginal: new Object(),
      loading: true,
      feedback: null,
      liveUpdates: false,
      liveUpdatesTimer: null,
      filterToday: true,
      filterWeek: false,
      filterMonth: false,
      filterPeriod: false,
      selectedPeriodDay: "01",
      selectedPeriodYear: moment().year(),
      selectedPeriodMonth: Number(moment().format("M")),
      conversions: [],
      deleteCounter: 0,
      deleting: false,
      averageConversionTime: 300,
      companies: [],
      branches: [],
      activeComp: "", 
      compStartYear: null,
      compStartMonth: null, 
      dataLoading: false,
      toggle_exclusive: 1,
      today: null,
      selected: [],
      removeConversionsDialog: false,
      conversionPeriodType: 'day',
      conversionPeriodStart: null,
      conversionPeriodEnd: null,
      currentMonth: Number(moment().format("M")),
      selectedSources: [],
      filter: {
        selectedSources: [],
        active: false
      },
      clientDialog: false,
      selectedClient: null,
      selectedClientOriginal: null,
      timer: null,
      checkForUpdates: null,
      conversionSelectedPage: 1,
      searchCompanies: "",
      closeConversionReason: null,
      closeConversionReasonOther: null,
      closeConversionReasonOtherWait: null,
      conversionTableLabels: [],
      appointmentDateDialog: false,
      conversionSalonhubfeedback: null,
      closeConversionSuccessfull: true,
      conversionSalonhubfeedback: null,
      conversionsClustered: [],
      conversionPages: 20,
      pricePerMessageSend: 0.1495,
      clientDataCopied: false,
      tableOptions: {
        sortBy: "timestampUnix",
        itemsPerPage: 20,
        sortDesc: false
      },
      headers:[
      // {
      //       text: "",
      //       align: "left",
      //       value: "",
      //       width: "40px",
      //       sortable: false
      //     },
          {
            text: "",
            align: "left",
            value: "clientData.language",
            width: "40px",
            sortable: false
          },
          {
            text: "",
            align: "left",
            value: "clientData",
            width: "40px",
            sortable: false
          },
          {
            text: "",
            align: "left",
            value: "clientData.attempts",
            width: "50px",
            sortable: false
          },
          {
            text: this.$t('general.date', this.$store.state.locale),
            align: "left",
            value: "timestampUnix",
            width: "180px"
          },
          {
            text: this.$t('general.name', this.$store.state.locale),
            align: "left",
            value: "clientData.name"
          },
          {
            text: this.$t('conversion.tableType', this.$store.state.locale),
            align: "left",
            value: "type",
            width: "100px"
          },
          {
            text: this.$t('general.branch', this.$store.state.locale),
            align: "left",
            value: "widgetData.branch.name"
          },
          {
            text: this.$t('conversion.tableSource', this.$store.state.locale),
            align: "left",
            value: "source"
          },
          {
            text: this.$t('conversion.tableRevenue', this.$store.state.locale),
            align: "left",
            value: "value.number",
            width: "110px"
          },
          {
            text: "",
            align: "left",
            value: "value.number",
            width: "60px"
          },
          {
            text: this.$t('conversion.tableSuccess', this.$store.state.locale),
            align: "left",
            value: "progress.completed",
            width: "120px"
          },
          {
            text: "",
            align: "left",
            width: "80px",
            sortable: false
          },
          {
            text: "",
            value: "data-table-expand",
            align: "right",
            width: "60px",
            sortable: false
          }
        ],
      clientType: [
        {
          id: false,
          name: 'Ja'
        },
        {
          id: true,
          name: 'Nee'
        },
      ],
      genders: [
        'Dames',
        'Heren'
      ],
      conversieUpdates: [],
      types: [
        {
          id: 'bookingwidget',
          name: 'Widget'
        },
        {
          id: 'wordpressForm',
          name: 'Wordpress Formulier'
        }
      ],
      copyType: null,
      updateCounter: 0,
      conversionKey: 0,
      sources: new Array(),
      sourcesOriginal: [
        {
          query: 'nosource',
          name: this.$t('conversion.sourceOrganic', this.$store.state.locale),
          bgColor: {
            hex: '#cecece'
          },
          textColor: {
            hex: '#333'
          }
        },
        {
          query: 'unknown',
          name: this.$t('conversion.sourceUnknown', this.$store.state.locale),
          bgColor: {
            hex: '#ccc'
          },
          textColor: {
            hex: '#333'
          }
        },
        {
          query: 'mc',
          name: 'Mailchimp',
          bgColor: {
            hex: '#ffe01b'
          },
          textColor: {
            hex: '#333'
          }
        },
        {

          query: 'adw',
          name: 'Adwords',
          bgColor: {
            hex: '#1a73e8'
          },
          textColor: {
            hex: '#fff'
          }
        }
      ],
      conversionTableValuesTest: [
          200,
          675,
          410,
          390,
          310,
          460,
          250,
          240
        ],
      conversionTableLabelsTest: [
          '12am',
          '3am',
          '6am',
          '9am',
          '12pm',
          '3pm',
          '6pm',
          '9pm'
        ],
      timeChecker: null,
      interval: null,
      updatesRunning: true,
      companiesLiveChecking: null
    }
  },

  created() {
    bus.$on("cconversionUpdate", this.loadData);
    bus.$on("companyUpdate", this.loadData);
    this.loadData();
    this.updateDate();
    this.now = moment().format("YYYY-MM-DD");
  },

  destroyed(){
    this.updatesRunning = false;
    this.checkForUpdates;
  },

  methods: {
    filterSources(){
      this.conversionSelectedPage = 1;
      this.filter.selectedSources = _.cloneDeep(this.selectedSources)
    },

    updateDate(){
      this.timeChecker= setInterval(countdown, 1);
      let vm = this;

      function countdown() {
        vm.today = moment().unix()
      }
    },

    loadData(){
      // if(this.activeCompData && this.activeCompData.id){
      //   this.destroyFirbaseListener()
      // }
      // this.checkNewConversions();
      this.activeCompData = _.cloneDeep(this.$store.state.activeCompany);
      this.activeCompDataOriginal =  _.cloneDeep(this.activeCompData);
      this.sources = _.cloneDeep(this.sourcesOriginal);
      this.selectedBranche = 'all';
      this.sources = this.sources.concat(this.$store.state.activeCompany.conversion.sources);
      this.selectAllSources()
      this.sources.forEach(source => {
        this.filter.selectedSources.push(source.query)
      })
      this.loading = false;
      this.dataLoading = true;

      if(this.urlStart && this.urlEnd){
        // Url params aanwezig
        let currentDay = moment().startOf('day').format("YYYY-MM-DD")
        let tomorrow = moment().add(1, 'day').startOf('day').format("YYYY-MM-DD")
        let startCurrentWeek = moment().weekday(0).format("YYYY-MM-DD")
        let endCurrentWeek = moment().weekday(7).add(1, 'day').format("YYYY-MM-DD")
        let startCurrentMonth = moment().startOf('month').format("YYYY-MM-DD")
        let endCurrentMonth = moment().endOf('month').add(1, 'day').format("YYYY-MM-DD")

        if(this.urlStart == currentDay && this.urlEnd == tomorrow){
          this.filterConversionToday()
        } else if(this.urlStart == startCurrentWeek && this.urlEnd == endCurrentWeek){
          this.filterConversionWeek()
        } else if(this.urlStart == startCurrentMonth && this.urlEnd == endCurrentMonth){
          this.filterConversionMonth()
        } else {
          this.filterToday = false;
          this.filterWeek = false;
          this.filterMonth = false;
          this.filterPeriod = false;
          this.loadCustomPeriodFilter(this.urlStart, this.urlEnd, 'url')
        }
      }
      else {
        this.filterConversionToday()
      }
    },

    formatCurrency(number){
      return cashConv(number, "EUR",2);
    },
    

    selectAllSources(){
      this.selectedSources = []
      this.sources.forEach(source => {
        this.selectedSources.push(source.query)
      })
    },

    copyClientData(clientData){ // Copy ClientData to store to use later in BookingWidgetCalendar
      let nameArray = clientData.minorData && clientData.minorData.name ? clientData.minorData.name.split(" ") : clientData.name.split(" ");
      let dataObject = {
        firstName: nameArray[0],
        lastName: nameArray.filter((x, y) => y != 0).join(' '),
        email: clientData.email,
        phone: clientData.phone,
        clientId : clientData.id ? clientData.id : null,
        minor: clientData.minorData && clientData.minorData.minorType ? true : false,
        minorName: clientData.minorData && clientData.minorData.name ? clientData.minorData.name : null,
        minorType: clientData.minorData && clientData.minorData.minorType ? clientData.minorData.minorType : null,
        minorId: clientData.minorData && clientData.minorData.id ? clientData.minorData.id : null
      }
      this.$store.state.clientSaveData = dataObject;
      this.clientDataCopied = true;

      setTimeout(() => {
        this.clientDataCopied = false;
      }, 1500);
    },

    goToCalendar(item){ // Go toe Agenda.vue and open this date
      let bookingData = {
        visit: item.widgetData.date
      }
      this.$router.push({ name: `agenda`, params:{
        type: "gotobooking",
        data: bookingData,
        open: false
      }});
    },

    async setPersonToBlacklist(item){
      await db.collection("companies")
      .doc(this.$store.state.activeCompany.id)
      .collection("blacklist")
      .add({
        value: item.clientData.email,
        created: moment().format("YYYY-MM-DDTHH:mm"),
        type: 'email',
        blockType: 'global',
        userAdded: `${this.$store.state.activeUserFirstName} ${this.$store.state.activeUserLastName}`,
        userTypeAdded: this.$store.state.activeUserRole,
        branchId: this.$store.state.activeBranch.id,    
      })
      .then((docRef)=>{
        item.widgetData.blacklisted = docRef.id
      })
      db.collection("conversion")
      .doc(item.id)
      .set({
        widgetData: item.widgetData
      }, { merge: true })

      if(item.clientData.phone){
        await db.collection("companies")
        .doc(this.$store.state.activeCompany.id)
        .collection("blacklist")
        .add({
          value: item.clientData.phone,
          created: moment().format("YYYY-MM-DDTHH:mm"),
          type: 'phone',
          blockType: 'global',
          userAdded: `${this.$store.state.activeUserFirstName} ${this.$store.state.activeUserLastName}`,
          userTypeAdded: this.$store.state.activeUserRole,
          branchId: this.$store.state.activeBranch.id,      
        })
      }
      if(item.clientData.ip.query){
        await db.collection("companies")
        .doc(this.$store.state.activeCompany.id)
        .collection("blacklist")
        .add({
          value: item.clientData.ip.query,
          created: moment().format("YYYY-MM-DDTHH:mm"),
          type: 'ip',
          blockType: 'global',
          userAdded: `${this.$store.state.activeUserFirstName} ${this.$store.state.activeUserLastName}`,
          userTypeAdded: this.$store.state.activeUserRole,
          branchId: this.$store.state.activeBranch.id,       
        })
      }
    },

    openClientData(id){
      if(id){
        db.collection("clients")
          .doc(id)
          .get()
          .then((client) => {
            client = client.data();
            this.selectedClient = client;
            this.selectedClientOriginal = _.cloneDeep(client);
            this.clientDialog = true;
            bus.$emit("customerCard");
          })
          .catch(() => {
            console.error("error while fechting client");
          });
      }
    },

    todayIsInConversionPeriod(){
      let first = moment(this.conversionPeriodStart, 'D MMM YYYY').format("YYYY-MM-DD");
      let last = moment(this.conversionPeriodEnd, 'D MMM YYYY').format("YYYY-MM-DD");
      let today = moment().format("YYYY-MM-DD");

      if(today >= first && today <= last){
        return true;
      }
      else{
        return false;
      }
    },

    resetSalonhubConversion(){
      this.closeConversionSuccessfull = true;
      this.conversionSalonhubfeedback = null;
      this.closeConversionReason = null;
      this.closeConversionReasonOther = null;
      this.closeConversionReasonOtherWait = null;
    },

    copyTextToClipboard(text, type) {
      if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
      }

      navigator.clipboard.writeText(text).then(
        () => {
          this.copyType = type;
          setTimeout(() => {
            this.copyType = null
          }, 1000);
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
    removeConversions() {
      this.removeConversionsDialog = false;
      this.deleting = true;
      new Promise((resolve) => {
        let clusterCounter = 0;
        this.selected.forEach(conversionCluster => {
          let conversionCounter = 0;
          new Promise((resolve, reject) => {
            if(conversionCluster.attemptsCollection && conversionCluster.attemptsCollection.length > 0){
              conversionCluster.attemptsCollection.forEach(conversion => {
                db.collection("conversion")
                  .doc(conversion.id)
                  .delete()
                  .then(() => {
                    conversionCounter++;
                    let conversieIndex = _.findIndex(this.conversions, { 'id': conversion.id});
                    this.conversions.splice(conversieIndex, 1);
                    if (conversionCounter == conversionCluster.attemptsCollection.length) {
                      resolve();
                    }
                  })
                  .catch(error => {
                    console.error("Error removing conversion: ", error);
                    conversionCounter++;
                    if (conversionCounter == conversionCluster.attemptsCollection.length) {
                      resolve();
                    }
                  });
              })
            }
            else{
              resolve();
            }
          })
          .then(()=>{
            db.collection("conversion")
              .doc(conversionCluster.id)
              .delete()
              .then(() => {
                clusterCounter++;
                this.deleteCounter++;
                let conversieIndex = _.findIndex(this.conversions, { 'id': conversionCluster.id});
                this.conversions.splice(conversieIndex, 1);

                let conversieClusteredIndex = _.findIndex(this.conversionsClustered, { 'id': conversionCluster.id});
                this.conversionsClustered.splice(conversieIndex, 1);

                if (clusterCounter == this.selected.length) {
                  resolve();
                }
              })
              .catch(error => {
                console.error("Error removing conversion: ", error);
                clusterCounter++;
                this.deleteCounter++;
                if (clusterCounter == this.selected.length) {
                  resolve();
                }
              });
          })
        });
      })
      .then(() => {
        this.conversionKey++
        this.selected = [];
        this.deleteCounter = 0;
        this.deleting = false;
      });
    },

    checkBusyStatus(onHold, reason, button){
      if(reason){
        if(onHold){   
          if(reason == 'tryAgain' || reason == 'notReachable'){
            this.closeConversionReason = reason;
          }
          else{
            this.closeConversionReason = 'otherReasonWait';
            this.closeConversionReasonOtherWait = reason;
          }
          this.closeConversionSuccessfull = 'wait';
        } else {
          this.closeConversionReason = null;
          this.closeConversionSuccessfull = true;
          this.closeConversionReasonOtherWait = null;
        }
      }  
    },

    updateConversionWait(item, index){
      this.conversionSalonhubfeedback = null
      if( (this.closeConversionReason == 'notReachable' || this.closeConversionReason == 'tryAgain') || (this.closeConversionReason == 'otherReasonWait' && this.closeConversionReasonOtherWait)){
    
        let noSuccessReason = this.closeConversionReason

        if(this.closeConversionReason == "otherReasonWait"){
          noSuccessReason = this.closeConversionReasonOtherWait
        }

        db.collection("conversion")
        .doc(item.id)
        .set({
          value: item.value,
          progress: {
            availableForAction: true,
            onHold: true,
            completed: false,
            completedBy: this.userFirstName + " " + this.userLastName,
            completedBycompanyId: this.userCompany,
            completedOn: new Date(),
            noSuccessReason: noSuccessReason
          } 
        }, { merge: true })
        .then(()=>{
          item.progress = {
            availableForAction: true,
            onHold: true,
            completed: false,
            completedBy: this.userFirstName + " " + this.userLastName + " (" + this.userCompany + ")",
            completedBycompanyId: this.userCompany,
            completedOn: {
              seconds: moment().unix()
            },
            noSuccessReason: noSuccessReason
          }
        }).then(()=>{
          let conversieIndex = _.findIndex(this.conversions, { 'id': item.id});
          this.conversions[conversieIndex] = item;
        })
        .catch(error=>{
          console.error("Updaten van conversie niet gelukt: ", error)
        })
      } else {
        this.conversionSalonhubfeedback = "Vul alle velden in."
      }
    },

    updateConversionNoSuccess(item, index){
      this.conversionSalonhubfeedback = null
      let callMeValue = false;
       

      if( (this.closeConversionReason == 'otherwiseSuccesfull' || this.closeConversionReason == 'noDeal') || (this.closeConversionReason == 'otherReason' && this.closeConversionReasonOther)){
    
        let noSuccessReason = this.closeConversionReason

        if(this.closeConversionReason == "otherReason"){
          noSuccessReason = this.closeConversionReasonOther
        }
        if(item.progress.callMe){
          callMeValue = item.progress.callMe
        }

        db.collection("conversion")
        .doc(item.id)
        .set({
          value: item.value,
          progress: {
            availableForAction: false,
            completed: false,
            onHold: false,
            callMe: callMeValue,
            completedBy: this.userFirstName + " " + this.userLastName,
            completedBycompanyId: this.userCompany,
            completedOn: new Date(),
            noSuccessReason: noSuccessReason
          } 
        }, { merge: true })
        .then(()=>{
          item.progress = {
            availableForAction: false,
            completed: false,
            onHold: false,
            callMe: callMeValue,
            completedBy: this.userFirstName + " " + this.userLastName + " (" + this.userCompany + ")",
            completedBycompanyId: this.userCompany,
            completedOn: {
              seconds: moment().unix()
            },
            noSuccessReason: noSuccessReason
          }
        }).then(()=>{
          let conversieIndex = _.findIndex(this.conversions, { 'id': item.id});
          this.conversions[conversieIndex] = item;
          this.closeConversionSuccessfull = true;
        })
        .catch(error=>{
          console.error("Updaten van conversie niet gelukt: ", error)
        })
      } else {
        this.conversionSalonhubfeedback = "Vul alle velden in."
      }
    },

    updateConversionSuccess(item, index){
      this.conversionSalonhubfeedback = null;
      let callMeValue = false;
      if(item.progress.callMe){
          callMeValue = item.progress.callMe
        }

      if(item.widgetData.branch && item.widgetData.steps.date && item.widgetData.steps.time && item.widgetData.steps.treatment.name && item.widgetData.steps.gender.name && item.widgetData.steps.employee.name && item.value.number){
        let changedSalonhubData = _.cloneDeep(item.widgetData)
        let chosenDate = _.cloneDeep(changedSalonhubData.steps.date)
        changedSalonhubData.steps.date = moment(chosenDate).format("DD-MM-YYYY")
        changedSalonhubData.steps.datestamp = moment(chosenDate).unix()

        db.collection("conversion")
        .doc(item.id)
        .set({
          widgetData: changedSalonhubData,
          value: item.value,
          progress: {
            availableForAction: false,
            completed: true,
            onHold: false,
            callMe: callMeValue,
            completedBy: this.userFirstName + " " + this.userLastName,
            completedBycompanyId: this.userCompany,
            completedOn: new Date()
          } 
        }, { merge: true })
        .then(()=>{
          item.widgetData.steps.date = moment(chosenDate).format("DD-MM-YYYY")
          item.widgetData.steps.datestamp = moment(chosenDate).unix()
          item.progress = {
            availableForAction: false,
            completed: true,
            onHold: false,
            callMe: callMeValue,
            completedBy: this.userFirstName + " " + this.userLastName,
            completedBycompanyId: this.userCompany,
            completedOn: {
              seconds: moment().unix()
            }
          }
        }).then(()=>{
          let conversieIndex = _.findIndex(this.conversions, { 'id': item.id});
          this.conversions[conversieIndex] = item
        })
        .catch(error=>{
          console.error("Updaten van conversie niet gelukt: ", error)
        })
      } else {
        this.conversionSalonhubfeedback = "Vul alle velden in."
      }
      
    },

    toggle () {
      this.$nextTick(() => {
        if(this.chooseAllSources) {
          this.selectedSources = []
        } else {
          this.selectAllSources()
        }
      })
    },

    filterConversionToday(){
      this.filterToday = true;
      this.filterWeek = false;
      this.filterMonth = false;
      this.filterPeriod = false;
      let firstDate = moment().startOf('day').format("YYYY-MM-DD")
      let lastDate = moment().endOf('day').add(1, 'day').format("YYYY-MM-DD")
  
      this.loadConversionDate(firstDate, lastDate, 'day')
    },

    filterConversionWeek(){
      this.filterToday = false;
      this.filterWeek = true;
      this.filterMonth = false;
      this.filterPeriod = false;
      let firstDate = moment().weekday(0).format("YYYY-MM-DD")
      let lastDate = moment().weekday(7).add(1, 'day').format("YYYY-MM-DD")
  
      this.loadConversionDate(firstDate, lastDate, 'week')
    },

    filterConversionMonth(){
      this.filterToday = false;
      this.filterWeek = false;
      this.filterMonth = true;
      this.filterPeriod = false;
      let firstDate = moment().startOf('month').format("YYYY-MM-DD");
      let lastDate = moment().endOf('month').add(1, 'days').format("YYYY-MM-DD");


      this.loadConversionDate(firstDate, lastDate, 'month')
    },

    filterConversionPeriod(){
      this.filterToday = false;
      this.filterWeek = false;
      this.filterMonth = false;
      this.filterPeriod = true;
    },

    loadCustomPeriodFilter(start, end, filter){
      if(filter == 'url'){
        this.loadConversionDate(start, end, 'month')
      }
      else {
        this.selectedPeriodDay = '01'
        let month = this.selectedPeriodMonth.length == 2 ? this.selectedPeriodMonth : `0${this.selectedPeriodMonth}`;
        let selectedDate = this.selectedPeriodYear+"-"+month+"-01"
        let firstDate = moment(selectedDate).startOf('month').format("YYYY-MM-DD")
        let lastDate = moment(selectedDate).endOf('month').add(1, 'days').format("YYYY-MM-DD")

        this.loadConversionDate(firstDate, lastDate, 'month')
      }
    },

    loadConversionDate(firstDate, lastDate, type){
      console.log("loadConversionDate", firstDate, lastDate)
      // this.destroyFirbaseListener()

      this.dataLoading = true;
      this.conversions = new Array();
      let start = new Date(firstDate)
      let end = new Date(lastDate)
      this.conversionSelectedPage = 1;

      this.conversionPeriodType =  type;

      this.conversionPeriodStart = moment(firstDate).format("D MMMM YYYY")
      this.conversionPeriodEnd = moment(lastDate).format("D MMMM YYYY")

      if (history.pushState) {
        var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?start=' + firstDate+ '&end=' + lastDate;
        window.history.pushState({path:newurl},'',newurl);
      }

      let numberOfDays = moment(lastDate).diff(firstDate, 'days', false) + 1;
      this.conversionTableLabels = Array.apply(null, Array(numberOfDays))
      let counter = 0

      this.conversionTableLabels.forEach((year, index)=>{
        this.conversionTableLabels[index] = moment(firstDate).add(counter, 'days').format("D MMM")
        counter++
      })
      console.log("start: ", start, "end: ", end, this.activeCompData.id)
      // this.$router.push({ path: "widget-statistieken", query: {start: firstDate, end: lastDate}, silent: true })  
      db.collection("conversion")
        .where("companyId", "==", this.activeCompData.id)
        .where("timestamp", ">=", start)
        .where("timestamp", "<=", end)
        .orderBy("timestamp")
        .get()
        .then(snap => {
          snap.forEach(doc => {
            let conversion = doc.data()
            conversion.id = doc.id
            let source = this.sources.find((s)=> { return s.query == conversion.source});

            conversion.clientData.email = String(doc.data().clientData.email).toLowerCase() 

            conversion.timestampConverted = moment.unix(doc.data().timestamp.seconds).format("YYYY-MM-DD")
            conversion.timestampUnix = doc.data().timestamp.seconds
            if(!doc.data().source || doc.data().source == 'null'){
              conversion.source = 'nosource'
            }
            else if(source != undefined){
              conversion.source = source.query
            }
            else if(source == undefined){
              conversion.source = 'unknown'
              conversion.originalSource = doc.data().source
            }
            else if(doc.data().source && doc.data().source != null && doc.data().source != "notInstalled" && !source){
              conversion.source = 'unknown'
              conversion.originalSource = doc.data().source
            }
            else {
              console.log(conversion)
              conversion.source = 'nosource'
            }

            if(!doc.data().timestampUpdated){
              conversion.timestampUpdated = doc.data().timestamp
            }
          
            if(doc.data().type == 'bookingwidget' && doc.data().progress.completed == false){
              if(!conversion.widgetData.steps){
                let chosenDate = null
                let chosenDateStamp = null
                let chosenTime = null
                let chosenEmployee = {
                    name: null,
                    id: null
                  } 
                let chosenGender = {
                    name: null,
                    id: null
                  }
                let chosenTreatment = {
                    name: null,
                    id: null
                  }
                if(doc.data().widgetData.steps && doc.data().widgetData.steps.date){
                  chosenDate.date = doc.data().widgetData.steps.date;
                  chosenDateStamp = doc.data().widgetData.steps.datestamp;
                }
                if(doc.data().widgetData.steps && doc.data().widgetData.steps.time){
                  chosenTime = doc.data().widgetData.steps.time
                }
                if(doc.data().widgetData.steps && doc.data().widgetData.steps.employee){
                  chosenEmployee = doc.data().widgetData.steps.employee
                }
                if(doc.data().widgetData.steps && doc.data().widgetData.steps.gender){
                  chosenGender = doc.data().widgetData.steps.gender
                }
                if(doc.data().widgetData.steps && doc.data().widgetData.steps.treatment){
                  chosenTreatment = doc.data().widgetData.steps.treatment
                }
                conversion.widgetData.steps = {
                  date: chosenDate,
                  datestamp: chosenDateStamp,
                  time: chosenTime,
                  employee: chosenEmployee,
                  gender: chosenGender,
                  treatment: chosenTreatment
                }
            } 
            else{
                if(doc.data().widgetData.steps && doc.data().widgetData.steps.date){
                  conversion.widgetData.steps.date = doc.data().widgetData.steps.date;
                  conversion.widgetData.steps.datestamp = doc.data().widgetData.steps.datestamp;
                }
                else{
                  conversion.widgetData.steps.date = null
                }
                if(!conversion.widgetData.steps.time){
                  conversion.widgetData.steps.time = null
                }
                if(!conversion.widgetData.steps.employee){
                  conversion.widgetData.steps.employee = {
                    name: null,
                    id: null
                  }
                }
                if(!conversion.widgetData.steps.gender){
                  conversion.widgetData.steps.gender = {
                    name: null,
                    id: null
                  }
                }
                if(!conversion.widgetData.steps.treatment){
                  conversion.widgetData.steps.treatment = {
                    name: null,
                    id: null
                  }
                }
              }
              if(!conversion.value){
                conversion.value = {
                  number: null,
                  type: 'amount'
                }
              }
            }
            this.conversions.push(conversion)
          })
        })
        
        .then(()=>{
          this.filterUniqueConversions()
          this.dataLoading = false;
          // this.checkForNewConversions()
        })
        .catch(error=>{
          this.dataLoading = false;
          console.error("Laden van conversies is niet gelukt: ", error)
        })
    },

    checkForNewConversions(){
      let start = new Date(moment(this.conversionPeriodStart, "D MMM YYYY").add(1, 'days').format("YYYY/MM/DD"))
      let end = new Date(moment(this.conversionPeriodEnd, "D MMM YYYY").add(1, 'days').format("YYYY/MM/DD"))

      this.checkForUpdates =  db.collection("conversion")
        .where("companyId", "==", this.activeCompData.id)
        .where("timestamp", ">=", start)
        .where("timestamp", "<=", end)
        .orderBy("timestamp")
        .onSnapshot(querySnapshot=> {
          this.conversions = []
          this.today = moment().unix()
          querySnapshot.forEach(doc=> {
            let conversion = doc.data()
            conversion.id = doc.id
            conversion.clientData.email = String(doc.data().clientData.email).toLowerCase() 
            conversion.timestampUnix = doc.data().timestamp.seconds
            conversion.timestampConverted = moment.unix(conversion.timestampUnix).format("YYYY-MM-DD")

            let source = this.sources.find((s)=> { return s.query == conversion.source});

            if(source){
              conversion.source = source.query
            }
            else if(source == undefined){
              conversion.source = 'unknown'
              conversion.originalSource = doc.data().source
            }
              
            else if(doc.data().source && doc.data().source != "notInstalled" && !source){
              conversion.source = 'unknown'
              conversion.originalSource = doc.data().source
            } 
            else {
              conversion.source = 'nosource'
            }

            if(!doc.data().timestampUpdated){
              conversion.timestampUpdated = doc.data().timestamp
            }

            if(doc.data().type == 'bookingwidget' && doc.data().progress.completed == false){
              if(doc.data().widgetData){

              }
            }

            if(!doc.data().value){
              conversion.value = {
                number: null,
                type: 'amount'
              }
            }

            // if(doc.data().type == 'salonhub' && doc.data().progress.completed == false){
            //   if(!conversion.widgetData.steps){
            //     let chosenDate = null
            //     let chosenDateStamp = null
            //     let chosenTime = null
            //     let chosenEmployee = {
            //         name: null,
            //         id: null
            //       } 
            //     let chosenGender = {
            //         name: null,
            //         id: null
            //       }
            //     let chosenTreatment = {
            //         name: null,
            //         id: null
            //       }
            //     if(doc.data().widgetData.steps && doc.data().widgetData.steps.date){
            //       chosenDate.date = doc.data().widgetData.steps.date
            //       chosenDateStamp = doc.data().widgetData.steps.datestamp
            //     }
            //     if(doc.data().widgetData.steps && doc.data().widgetData.steps.time){
            //       chosenTime = doc.data().widgetData.steps.time
            //     }
            //     if(doc.data().widgetData.steps && doc.data().widgetData.steps.employee){
            //       chosenEmployee = doc.data().widgetData.steps.employee
            //     }
            //     if(doc.data().widgetData.steps && doc.data().widgetData.steps.gender){
            //       chosenGender = doc.data().widgetData.steps.gender
            //     }
            //     if(doc.data().widgetData.steps && doc.data().widgetData.steps.treatment){
            //       chosenTreatment = doc.data().widgetData.steps.treatment
            //     }
            //     conversion.widgetData.steps = {
            //       date: chosenDate,
            //       datestamp: chosenDateStamp,
            //       time: chosenTime,
            //       employee: chosenEmployee,
            //       gender: chosenGender,
            //       treatment: chosenTreatment
            //     }
            //   } 
            //   else{

            //     if(doc.data().widgetData.steps && doc.data().widgetData.steps.date){
            //       conversion.widgetData.steps.date = doc.data().widgetData.steps.date
            //       conversion.widgetData.steps.datestamp = doc.data().widgetData.steps.datestamp
            //     }
            //     else{
            //       conversion.widgetData.steps.date = null
            //     }
            //     if(!conversion.widgetData.steps.time){
            //       conversion.widgetData.steps.time = null
            //     }
            //     if(!conversion.widgetData.steps.employee){
            //       conversion.widgetData.steps.employee = {
            //         name: null,
            //         id: null
            //       }
            //     }
            //     if(!conversion.widgetData.steps.gender){
            //       conversion.widgetData.steps.gender = {
            //         name: null,
            //         id: null
            //       }
            //     }
            //     if(!conversion.widgetData.steps.treatment){
            //       conversion.widgetData.steps.treatment = {
            //         name: null,
            //         id: null
            //       }
            //     }
            //   }
            //   if(!conversion.value){
            //     conversion.value = {
            //       number: null,
            //       type: 'amount'
            //     }
            //   }
            // }

            this.conversions.push(conversion)
            
          }, function(error) {
            console.error("Laden van conversies is niet gelukt: ", error)
            this.checkForUpdates

          });
          
          this.filterUniqueConversions()
        })

        this.liveUpdates = true;
        let seconds = 3600
        
        this.timer = setInterval(()=> {
          seconds = seconds - 1
          let minutes = Math.floor(seconds / 60)
          let remainingSeconds =  ("0" + (seconds - (minutes * 60))).slice(-2);
          this.liveUpdatesTimer =  minutes + ":" + remainingSeconds
        }, 1000)

        setTimeout(()=>{ 
          stopLiveUpdates();
        }, 3600000); 
    },

    stopLiveUpdates(){
      this.liveUpdates = false;
      clearInterval(this.timer)
      this.liveUpdatesTimer =  null;
      this.checkForUpdates
      console.log("Live checken voorbij")
    },

    async filterUniqueConversions(){
      // CLUSTERING MULTIPLE ATTEMPTS of 1 PERSON

      // Filter on
      // -EMAIL: Only conversion attempts with the same EMAIL are clustered
      // -IP: Only conversion attempts with the same IP are clustered
      // -DATE OF TODAY: Only conversion attempts on the same DAY are clustered
      // -COMPLETED: Only 1 completed can be in cluster

      // let ip = conversionItem.clientData.ip && conversionItem.clientData.ip.query ? conversionItem.clientData.ip.query : null;
      this.conversionsClustered = new Array();

      let uniqConversions = _.uniqBy(this.conversions, conversionItem => [conversionItem.clientData.email, conversionItem.clientData.ip.query, conversionItem.timestampConverted].join());
      

      // Get unique conversion items

      for (let index = 0; index < uniqConversions.length; index++) {
     
        let conversionItems = this.conversions.filter((conversionItem)=> {return conversionItem.clientData.email == uniqConversions[index].clientData.email && conversionItem.clientData.ip.query == uniqConversions[index].clientData.ip.query && conversionItem.timestampConverted == uniqConversions[index].timestampConverted})
        let callMe = false;
        let smsSend = false;
        let closeReason = false;

        // Only one conversion attempt
        if(conversionItems.length == 1){ 
          uniqConversions[index].attempts = 1
          this.conversionsClustered.push(uniqConversions[index])
        }
        // Multiple one conversion attempt 
        else {  

          // Check for multiple completed Items
          let completedConversionItems = conversionItems.filter((completedItem) => {return completedItem.progress.completed });

          if(completedConversionItems.length > 1){ // More than one completedItems
            
            let multipleConversionOrderedByDate = _.orderBy(conversionItems, ['timestampUnix'], ['desc']);
            let splitsedItemsCollection = new Array();
            let counter = 0;
            let completedItemCluster = null;
            
            await new Promise((resolve)=>{
              multipleConversionOrderedByDate.forEach(item =>{
                counter++
                if(item.progress.callMe){
                  callMe = true;
                } 
                if(item.smsSend){
                  smsSend = true;
                }
                if(item.widgetData && item.widgetData.closeReason){
                  closeReason = true;
                }  

                if(item.progress.completed){
                  splitsedItemsCollection.push(new Array())
                  if(completedItemCluster == null){
                    completedItemCluster = 0;
                  } else {
                    completedItemCluster++
                  }
                  splitsedItemsCollection[completedItemCluster].push(item)
                } 
                else {
                  if(counter == 1){
                    item.attempts = 1
                    this.conversionsClustered.push(item)
                  }
                  else {
                    splitsedItemsCollection[completedItemCluster].push(item)
                  }
                }
                
                if(counter == multipleConversionOrderedByDate.length){
                  resolve()
                }
              })
            })


            splitsedItemsCollection.forEach(splitsedItems => {

              // ONE or NO completedItem
              let primaryConversion = new Object();
              
              let conversionSources = new Array();
              // conversionSources = _.filter(splitsedItems, function(item){ return item.source != "nosource"}); // Get one of the used Sources if available

              conversionSources = splitsedItems.filter((item)=> { return item.source != "nosource"} );
              
              // Find completed Item 
              let completedItem = splitsedItems.find((item)=> { return item.progress.completed });

              if(completedItem){ // Set completed item as default to show.
                primaryConversion = completedItem
                primaryConversion.clusterSmsSend = smsSend;
                primaryConversion.clusterCallMe = callMe;
                primaryConversion.clusterCloseReason = closeReason;

                // Collect other attempts and set in item
                primaryConversion.attemptsCollection = new Array();
                splitsedItems.forEach(attemptItem=>{
                  if(attemptItem.id != primaryConversion.id){
                    primaryConversion.attemptsCollection.push(attemptItem)
                  }
                })

                primaryConversion.attempts = splitsedItems.length;
                if(conversionSources.length > 0){
                  primaryConversion.source = conversionSources[0].source;
                }

                this.conversionsClustered.push(primaryConversion);
              } 
              
              else{ // No completed item found (Set most recent item as default to show)
                console.log("No completed items")
                let conversionOrderedByDate = _.orderBy(conversionItems, ['timestampUnix'], ['desc']);

                primaryConversion = conversionOrderedByDate[0];
                primaryConversion.clusterSmsSend = smsSend;
                primaryConversion.clusterCallMe = callMe;
                primaryConversion.clusterCloseReason = closeReason;

                primaryConversion.attempts = conversionItems.length;
                if(conversionSources.length > 0){
                  primaryConversion.source = conversionSources[0].source;
                }
                this.conversionsClustered.push(primaryConversion)
              }
            })
          }
          else{
            // One or no completedItem
            
            let primaryConversion = new Object();
            // let completedItem = _.find(conversionItems, function(item) { return item.progress.completed });
            let completedItem = conversionItems.find((item)=> { return item.progress.completed });
            let conversionSources = new Array();
            // conversionSources = _.filter(conversionItems, function(item){ return item.source != "nosource"}); // Get one of the used Sources if available
            conversionSources = conversionItems.filter((item) => { return item.source != "nosource"}); // Get one of the used Sources if available
            
            if(completedItem){
              // Find completed Item and set as default shown.

              primaryConversion = completedItem

              // Collect other attempts and set in item
              primaryConversion.attemptsCollection = []
              conversionItems.forEach(attemptItem=>{
                if(attemptItem.progress.callMe){
                  callMe = true;
                } 
                if(attemptItem.smsSend){
                  smsSend = true;
                }
                if(attemptItem.widgetData && attemptItem.widgetData.closeReason){
                  closeReason = true;
                } 
                if(attemptItem.id != primaryConversion.id){
                  primaryConversion.attemptsCollection.push(attemptItem)
                }
              })

              primaryConversion.attempts = conversionItems.length
              primaryConversion.clusterSmsSend = smsSend;
              primaryConversion.clusterCallMe = callMe;
              primaryConversion.clusterCloseReason = closeReason;
              if(conversionSources && conversionSources.length > 0){
                primaryConversion.source = conversionSources[0].source
              }

              this.conversionsClustered.push(primaryConversion)
            } 
            
            else{
              // When no completed Item id found set most resent itme as default shown.
              let conversionOrderedByDate = _.orderBy(conversionItems, ['timestampUnix'], ['desc']);

              primaryConversion = conversionOrderedByDate[0]

              // Collect other attempts and set in item
              primaryConversion.attemptsCollection = []
              conversionOrderedByDate.forEach(attemptItem=>{
                if(attemptItem.progress.callMe){
                  callMe = true;
                } 
                if(attemptItem.smsSend){
                  smsSend = true;
                } 
                if(attemptItem.widgetData && attemptItem.widgetData.closeReason){
                  closeReason = true;
                } 

                if(attemptItem.id != primaryConversion.id){
                  primaryConversion.attemptsCollection.push(attemptItem)
                }
              })

              primaryConversion.attempts = conversionItems.length
              primaryConversion.clusterSmsSend = smsSend;
              primaryConversion.clusterCallMe = callMe;
              primaryConversion.clusterCloseReason = closeReason;
              if(conversionSources && conversionSources.length > 0){
                primaryConversion.source = conversionSources[0].source
              }
              this.conversionsClustered.push(primaryConversion)
            }
            
          }
        }
      }
    },
    
    counter(){
      if(this.updatesRunning) {
        this.updateCounter = parseInt(this.updateCounter) + 1;
      }
      else {
        clearInterval(this.interval);
        this.companiesLiveChecking = null;
      }
    },

    checkNewConversions(){
      let today = new Date()
      let start = today.setMinutes( today.getMinutes() - 2 );

      this.companiesLiveChecking;

      if(this.$store.state.activeUserRoleNumber < 2 || this.userOperator){

        this.interval = setInterval(this.counter, 1000);

        this.companiesLiveChecking = db.collection("conversion")
          .where("timestamp", ">=", today)
          .onSnapshot(querySnapshot=> {
            querySnapshot.docChanges().forEach(change=> {
              if (change.type === "added") {
                // Snackbar weergeven voor elke nieuwe conversie
                this.userCompanies.forEach(company=>{
                  if(company == change.doc.data().companyId){
                    console.log("CONVERSIE: ", company, change.doc.data())
                    let snackbarItem = {
                    unix: Number(moment().format('X')),
                    company: company,
                    show: true
                  }
                  this.conversieUpdates.push(snackbarItem)
                  }
                })
              }
            })
          })
      }
    }

  },

  computed: {

    conversionBranches(){
      let branches = [{
        id: 'all',
        name: this.$t('general.all', this.$store.state.locale)
      }]

      branches = branches.concat(this.$store.state.userBranches);
      return branches
    },

    updateCompany(){
      return id => _.find(this.companies, ['id', id]);
    },

    conversieUpdatesFiltered(){
      let index = this.updateCounter; 
      let unixNow = Number(moment().format('X'));
      let timeout = 25;
      let updates = new Array();
      this.conversieUpdates.forEach(update=>{
        if((update.unix > (unixNow - timeout)) && update.show == true){
          updates.push(update);
        }
      })
      return updates;
    },

    conversionSelectorPages(){
      return this.conversionsFiltered?.length ? Math.ceil(this.conversionsFiltered.length / 50) : 1;
    },

    liveUpdatesActive(){
      if(this.liveUpdates){
        return true
      }
      else {
        return false
      }
    },

    conversionTableValues(){
      let data = Array.apply(null, Array(this.conversionTableLabels.length))

      this.conversionTableLabels.forEach((day, index) =>{
        data[index] = 0
        let dayDate = moment(this.conversionPeriodStart, "D MMMM YYYY").add(index, 'days').format('YYYY-MM-DD')
          this.conversionsFiltered.forEach(conversion =>{
            if(moment.unix(conversion.timestamp.seconds).format('YYYY-MM-DD') == dayDate){
              data[index]++
            }
          })
      })
      return data
    },

    chooseAllSources () {
      return this.selectedSources.length === this.sources.length
    },

    selectSomeSources () {
      return this.selectedSources.length > 0 && !this.chooseAllSources
    },

    icon () {
      if(this.chooseAllSources) return 'mdi-checkbox-marked'
      if(this.selectSomeSources) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },

    conversionsFiltered(){
      let refresh = this.conversions.length;
      let filterdConversions = new Array();
      if(this.conversions.length > 0){
        this.conversionsClustered.forEach(conversion=>{  
          let source = _.indexOf(this.filter.selectedSources, conversion.source);
          if(source >= 0 && (this.selectedBranche == 'all' || this.selectedBranche == conversion.widgetData?.branch?.id)){
            filterdConversions.push(conversion);
          }
          // filterdConversions.push(conversion);
        })
        return filterdConversions;
      } 
      else {
        return filterdConversions;
      }
    },

    conversionsSuccessRate(){
      let successCounter = 0
      let succesRate = 0

      if(this.conversionsFiltered.length > 0){
        this.conversionsFiltered.forEach(conversion=>{  
          if(conversion.progress && conversion.progress.completed){
            successCounter++
          }
        })
      }

      succesRate = (successCounter / this.conversionsFiltered.length * 100).toFixed(0)
      return succesRate
    },

    conversionsValue(){
      let successValue = 0;
      let value = 0;

      if(this.conversionsFiltered.length > 0){
        this.conversionsFiltered.forEach(conversion=>{  
          if(conversion.progress && conversion.progress.completed && conversion.value && conversion.value.type == 'amount'){
            successValue = successValue + conversion.value.number
          }
        })
      }
      value = successValue.toFixed(0)
      return value
    },

    newClients(){
      let clients = 0;

      if(this.conversionsFiltered.length > 0){
        this.conversionsFiltered.forEach(conversion=>{  
          if(conversion.widgetData && !conversion.widgetData.existingClient){
            clients++
          }
        })
      }
      return clients
    },

    adWordsCosts(){
      if(this.activeCompData.conversion.adwordsBudget){
        let budget = this.activeCompData.conversion.adwordsBudget;
        if(this.conversionPeriodType == 'day'){
          return budget;
        }
        if(this.conversionPeriodType == 'week'){
          let dayOfWeek = Number(moment().day()) + 1;
          return budget * dayOfWeek;
        }
        if(this.conversionPeriodType == 'month'){
          if(moment() > moment(this.conversionPeriodEnd, "D MMMM YYYY")){
            return budget * 30;
          }
          else{
            let dayOfMonth = Number(moment().format('D'))
            return budget * dayOfMonth;
          }
          
        }
      }
      else {
        return null;
      }
    },

    extraConversionPotential(){
      let convertedClients = 0;
      let convertedRevenue = 0;

      let potentialClientsWithPrice = 0;
      let potentialClientsWithoutPrice = 0;
      let potentialRevenue = 0;
      let counter = 0;
  
      if(this.conversionsFiltered.length > 0){
        this.conversionsFiltered.forEach(conversion=>{  
          if(conversion.progress.completed){
            convertedClients++
            if(conversion.value.number && conversion.value.type == 'amount'){
              convertedRevenue = Number(convertedRevenue + conversion.value.number);
            }
          }
          else if(!conversion.progress.completed && conversion.value && conversion.value.type == 'amount' && conversion.value.number){
            potentialClientsWithPrice++
            potentialRevenue = Number(potentialRevenue + conversion.value.number);
          }
          else{
            potentialClientsWithoutPrice++
          }       
        })

        if(convertedRevenue && convertedClients && potentialClientsWithoutPrice){
          potentialRevenue = ((convertedRevenue / convertedClients) * potentialClientsWithoutPrice) + potentialRevenue;
        }

        return potentialRevenue.toFixed(0);
      }
      else{
        return potentialRevenue.toFixed(0);
      }      
    }, 

    manualConversions(){
      let called = 0;
      let success = 0;

      if(this.conversionsFiltered.length > 0){
        this.conversionsFiltered.forEach(conversion=>{  
          if(conversion.progress.completedBy != 'client'){
            called++
            if(conversion.progress.completed){
              success++
            }
          }
        })
        return {
          called: called,
          success: success
        };
      }
      else{
        return {
            called: called,
            success: success
          };
      }
    },

    messagesSend(){
      let number = 0;
      let costs = 0;

      if(this.conversionsFiltered.length > 0){
        this.conversionsFiltered.forEach(conversion=>{ 
          if(conversion.smsSend || conversion.clusterSmsSend){
            number++
            costs = (number * this.pricePerMessageSend).toFixed(2)
          }
        })
        return {
          number: number,
          costs: costs
        };
      }
      else{
        return {
            number: number,
            costs: costs
          };
      }

    },

    sourcesFilterChanged(){
      if (_.isEqual(this.filter.selectedSources, this.selectedSources) == false) {
        return true;
      } else {
        return false;
      }
    },

    activeYears(){
      let currentYear = Number(moment().year())
      let startDateCompany = moment().format("YYYY-MM-DD")

      if(this.activeCompData.conversion.start){
        startDateCompany = moment.unix(this.activeCompData.conversion.start.seconds).format("YYYY-MM-DD")
      }

      let numberOfYears = moment().diff(startDateCompany, 'years', false) + 1;
      // console.log("numberOfYears: ", numberOfYears)
      let activeYears = Array.apply(null, Array(numberOfYears))
      let counter = 0

      activeYears.forEach((year, index)=>{
        activeYears[index] = currentYear - counter
        counter++
      })
      return activeYears
    },

    months(){
      let months = [
        { id: 1, name: this.$t('general.january', this.$store.state.locale), disabled: false },
        { id: 2, name: this.$t('general.february', this.$store.state.locale), disabled: false },
        { id: 3, name: this.$t('general.march', this.$store.state.locale), disabled: false },
        { id: 4, name: this.$t('general.april', this.$store.state.locale), disabled: false },
        { id: 5, name: this.$t('general.may', this.$store.state.locale), disabled: false },
        { id: 6, name: this.$t('general.june', this.$store.state.locale), disabled: false },
        { id: 7, name: this.$t('general.july', this.$store.state.locale), disabled: false },
        { id: 8, name: this.$t('general.august', this.$store.state.locale), disabled: false },
        { id: 9, name: this.$t('general.september', this.$store.state.locale), disabled: false },
        { id: 10, name: this.$t('general.october', this.$store.state.locale), disabled: false },
        { id: 11, name: this.$t('general.november', this.$store.state.locale), disabled: false },
        { id: 12, name: this.$t('general.december', this.$store.state.locale), disabled: false },  
      ]

      if(this.selectedPeriodYear == moment().year()){
        months.forEach(month=>{
          if(month.id > Number(this.currentMonth)){
            month.disabled = true
          } 
          else if(this.activeCompData.conversion.start && (month.id < Number(moment.unix(this.activeCompData.conversion.start.seconds).format("M")))){
            month.disabled = true
          } 
        })
        return months
      }
      else if(this.selectedPeriodYear == this.compStartYear){
        months.forEach(month=>{
          if(month.id < this.compStartMonth){
            month.disabled = true
          } 
        })
        return months
      } else{
        return months
      }
    },

    filterButtonDisabled(){
      if((this.selectedPeriodYear == moment().year() && this.currentMonth < this.selectedPeriodMonth) || this.selectedPeriodYear == this.compStartYear && this.selectedPeriodMonth < this.compStartMonth){
        return true
      } else {
        return false
      }
    },

    todaySelected(){
      let data = {
        color: this.$themes[this.companyTheme][this.theme].cardOption,
        textColor: this.$themes[this.companyTheme][this.theme].text,
        loading: false
      }
      if(this.filterToday && this.dataLoading){
        data.color = this.$themes[this.companyTheme][this.theme].primary;
        data.textColor = "#fff";
        data.loading = true
      } else if(this.filterToday){
        data.color = this.$themes[this.companyTheme][this.theme].primary;
        data.textColor = "#fff";
      } 
      return data
    },

    weekSelected(){
      let data = {
        color: this.$themes[this.companyTheme][this.theme].cardOption,
        textColor: this.$themes[this.companyTheme][this.theme].text,
        loading: false
      }
      if(this.filterWeek && this.dataLoading){
        data.color = this.$themes[this.companyTheme][this.theme].primary;
        data.textColor = "#fff";
        data.loading = true
      } else if(this.filterWeek){
        data.color = this.$themes[this.companyTheme][this.theme].primary;
        data.textColor = "#fff";
      } 
      return data
    },

    monthSelected(){
      let data = {
        color: this.$themes[this.companyTheme][this.theme].cardOption,
        textColor: this.$themes[this.companyTheme][this.theme].text,
        loading: false
      }
      if(this.filterMonth && this.dataLoading){
        data.color = this.$themes[this.companyTheme][this.theme].primary;
        data.textColor = "#fff";
        data.loading = true
      } else if(this.filterMonth){
        data.color = this.$themes[this.companyTheme][this.theme].primary;
        data.textColor = "#fff";
      } 
      return data
    },
    
    periodSelected(){
      let data = {
        color: this.$themes[this.companyTheme][this.theme].cardOption,
        textColor: this.$themes[this.companyTheme][this.theme].text,
        loading: false
      }
      if(this.filterPeriod && this.dataLoading){
        data.color = this.$themes[this.companyTheme][this.theme].primary;
        data.textColor = "#fff";
        data.loading = true
      } else if(this.filterPeriod){
        data.color = this.$themes[this.companyTheme][this.theme].primary;
        data.textColor = "#fff";
      } 
      return data
    },

    randomHipster(){
      if(this.conversionsFiltered.length == 0){
        let randomNumber = Math.floor(Math.random() * 12) + 1;
        let hipsterClass = "hipster-" + randomNumber
         return hipsterClass
      } else {
        return null
      }    
    },

    userLanguage(){
      return this.$store.state.locale ? this.$store.state.locale : 'NL';
    },
    userCompanies() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.accessCompanies : this.$store.state.userCompanies;
    },
    userCompany() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.company : this.$store.state.userCompany;
    },
    userFirstName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.name : this.$store.state.userFirstName;
    },
    userLastName() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.surname : this.$store.state.userLastName;
    },
    userRole() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userRole : this.$store.state.userRole;
    },
    userId() {
      return this.$store.state.connectedEmployee ? this.$store.state.connectedEmployee.userId : this.$store.state.userId;
    },
    userOperator() {
      return this.$store.state.userOperator;
    },
    companyPrimaryColor() {
      return this.$store.state.companyPrimaryColor;
    },
    companySecondaryColor() {
      return this.$store.state.companySecondaryColor;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    companyTheme() {
      if(this.$store.state.companyTheme){
        return this.$store.state.companyTheme;
      }
      else{
        return "thrive"
      }
    },
    filteredCompanies(){
      if(this.searchCompanies && this.searchCompanies.length > 0){
        return this.companies.filter(company => {
          return company.name.toLowerCase().includes(this.searchCompanies.toLowerCase());
        });
      } else {
        return this.companies
      }
    },
    changed() {
      if (_.isEqual(this.activeCompData, this.activeCompDataOriginal) == false) {
        return true;
      } else {
        return false;
      }
    }
  }
}
</script>

<style>

.update-snackbar .v-snack__wrapper{
  min-width: 450px!important;
}

.attempt-box{
  display: block;
  width: 32px;
  height: 32px;
  background-color: var(--v-text-base);
  border-radius: 50px;
  font-size: 11px;
  text-align: center;
  line-height: 34px;
  color: var(--v-menu-base);
}

.attempt-box span{
  font-size: 15px;
  color: var(--v-menu-base);
  padding: 0 1px 0 0;
}

.extra-conversion-info h3{
  padding: 0 0 10px 0;
}

.conversion-client-info .info-wrapper:after{
  display: block;
  clear: both;
  content: "";
}

.conversion-client-info .info-label{
  font-weight: 600;
  width: 160px;
  float: left;
  display: block;
}

.conversion-client-info .info-data{
  width: calc(100% - 160px);
  display: block;
  float: left;
}

.no-conversions{
  width: 280px;
  margin: 0px auto;
  padding: 80px 0;
}

.hipster-image{
  width: 130px;
  height: 130px;
  margin: 0 auto;
}

.no-conversions p{
  font-weight: 600;
  text-align: center;
  font-size: 16px;
}

.conversion-selected-period p {
  text-align: center;
  padding: 15px 0 5px 0;
  text-transform: lowercase;
}

 .v-toolbar.custom-toolbar .v-toolbar__content, 
 .v-toolbar.custom-toolbar .v-toolbar__extension{
   padding: 0!important;
 }



.conversion-table table head td:not(:nth-child(1)), 
.conversion-table table tbody td:not(:nth-child(1)), 
.conversion-table table thead th:not(:nth-child(1)), 
.conversion-table table tbody th:not(:nth-child(1)), 
.conversion-table table thead td:first-child, 
.conversion-table table tbody td:first-child, 
.conversion-table table thead th:first-child, 
.conversion-table table tbody th:first-child {
    padding: 0 8px!important;
}

.close-conversion-buttons{
  border-radius: 5px;
  overflow: hidden;
}

.close-conversion-buttons:after{
  display: block;
  content: "";
  clear: both;
}

.close-conversion-button{
  width: 33.3%;
  border: 1px solid #251433;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #fff;
  color: #251433;
  float: left;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
}
.close-conversion-button.left{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.close-conversion-button.middle{
  border-right: none;
  border-left: none;
}

.close-conversion-button.right{
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.close-conversion-button.active{
  background-color: #251433;
  color: #fff;
}

tr.buzy{
  background-color: rgba(0,0,0,0.09);
}

.custom-label{
  color: var(--v-text-base);
}
</style>

<style scoped>
  .usertype-total{
    padding: 10px;
    width: 100%
  }

  .usertype-total-number{
    text-align: center;
    font-size: 38px;
  }
  .usertype-total-desc{
    text-align: center;
    font-size: 16px;
  }
</style>

