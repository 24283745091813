<template>
  <v-dialog v-model="dialog" scrollable max-width="700px" :content-class="`custom-dialog ${$store.state.companyTheme}`">
      <v-card>
        <v-card-title class="pr-2" :style="{ backgroundColor: $themes[$store.state.companyTheme][$store.state.themeModus].cardHeader }">
          <h3>{{ $t('memberships.addNewSubscription', $store.state.locale) }}</h3>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog()">
            <v-icon dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
        <v-alert v-if="subscription.feedback" class="ma-0 pa-2" style="border-radius: 0" icon="mdi-alert" dark :color="$themes[$store.state.companyTheme][$store.state.themeModus].warning">{{ subscription.feedback }}</v-alert>

        <v-divider></v-divider>

        <v-card-text class="pa-0 px-4 pb-4 ma-0">
          <v-container grid-list-md class="pa-0 ma-0">
            <v-select
              class="mt-4"
              :label="$t('memberships.selectMembership', $store.state.locale)"
              :items="membershipPackages"
              :item-text="item => item.name[($store.state.locale).toLowerCase()]"
              item-value="id"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              dense
              v-model="subscription.subscription"
              outlined
              hide-details 
              >
            </v-select>

            <v-select
              v-if="$store.state.activeUserRoleNumber == 0 && !$store.state.demoMode && (selectedSubscription && selectedSubscription.paymentTypes != 'single')"
              class="mt-4"
              :label="$t('memberships.paymentMethodes', $store.state.locale)"
              :items="subscriptionPaymentTypes"
              :item-text="item => item.name"
              item-value="id"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
              dense
              v-model="subscription.paymentType"
              outlined
              hide-details 
              >
            </v-select>

            <v-card 
               v-if="$store.state.activeUserRoleNumber == 0 && !$store.state.demoMode && selectedSubscription"
              flat 
              class="pa-2 mt-4"
              :color="$themes[$store.state.companyTheme][$store.state.themeModus].developer">
              <v-text-field
              
                :label="$t('memberships.minDuration', $store.state.locale)"
                type="number"
                item-text="name"
                item-value="id"
                dense
                :min="0"
                v-model.number="subscription.duration"
                outlined
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                :background-color="$themes[$store.state.companyTheme][$store.state.themeModus].card"
                :suffix="durationSuffix(subscription.duration)"
                
                hide-details
              ></v-text-field>
            </v-card>

            <v-menu ref="menuStartDate" v-model="menuStartDate" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-on="on"
                  v-model="membershipStartDate.readable"
                  :label="$t('general.start', $store.state.locale)"
                  readonly
                  prepend-inner-icon="mdi-calendar"
                  hide-details
                  outlined
                  clearable
                  dense
                  class="mt-4"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  :item-color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                  background-color="menu"
                ></v-text-field>
              </template>
              <v-date-picker
                ref="picker"
                no-title
                :min="membershipStartMinDate"
                v-model="subscription.startDate"
                :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary" 
                @input="menuStartDate = false"
              ></v-date-picker>
            </v-menu>

            <div class="client-selector">
              <clientSelector :source="'subscriptionModal'"></clientSelector>  
            </div>
            
          </v-container>
         </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-container grid-list-md fluid class="ma-0 pa-0">
            <v-layout row wrap class="ma-0 pa-0">
              <v-flex xs12 md6 class="text-left">
                <v-btn
                  outlined
                  depressed
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].text"
                  @click="closeDialog()"
                  >
                  {{ $t("general.cancel", $store.state.locale) }}
                </v-btn>
              </v-flex>
              <v-flex xs12 md6 class="text-right">
                <v-btn
                  :dark="membershipValid"
                  :disabled="!membershipValid"
                  :color="$themes[$store.state.companyTheme][$store.state.themeModus].primary"
                  :block="$vuetify.breakpoint.name == 'sm' || $vuetify.breakpoint.name == 'xs'"
                  @click="addNewMembership()"
                  >
                    {{ $t('memberships.subscribeClient', $store.state.locale) }}
                  <v-icon right dark>mdi-chevron-right</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>


<script>
  import '@firebase/firestore';
  import db from "@/firebase/init";
  import moment from "moment";
  import { bus } from "@/main";
  import clientSelector from "@/components/elements/clientSelector.vue";

  export default {
    name: "add-subscription-model",
    props:{
      membershipPackages: Array()
    },
    components: {
      clientSelector: clientSelector,
    },
    data() {
      return {
        dialog: false,

        menuStartDate: false,
        membershipStartMinDate: null,
        clientData: new Object(),
        subscription: {
          dialog: false,
          feedback: null,
          startDate: null,
          subscription: null,
          newclient: false,
          paymentType: null,
        },
        subscriptionPaymentTypes: [
        {
          id: "periodic",
          name:  this.$t('memberships.paymentTypePeriodic', this.$store.state.locale),
        },
        {
          id: "single",
          name:  this.$t('memberships.paymentTypeSingle', this.$store.state.locale),
        },
      ],
      };
    },

    created(){
      bus.$on('newSubscriptionModal', () =>{
        this.resetDialog();
        this.dialog = true;
      });
      bus.$on('subscriptionClientSelected', (clientData) =>{
        this.clientData = clientData;
      });
    },

    methods: {
      resetDialog(){
        bus.$emit("resetClientSelect");
        bus.$emit("resetClientSearch");
        this.subscription.feedback = null;
        this.subscription.subscription = null;
        this.subscription.startDate = _.cloneDeep(this.membershipStartMinDate);
        this.membershipStartMinDate = moment().format("YYYY-MM-DD");
        this.subscription.paymentType = null;
        this.subscription.duration = null;
        this.clientData = new Object();
      },

      async addNewMembership(){
        
        if(this.subscription.newclient){
          await this.createClient();
        }

        await db.collection("memberships").add({
          created: new Date(),
          companyId: this.$store.state.activeCompany.id,
          clientId: this.clientData.id,
          startDate: this.membershipStartDate.formatted,
          subscription: this.subscription.subscription,
          freePeriod: this.selectedSubscription.freePeriod,
          usageRules: this.selectedSubscription.treatments,
          name: this.selectedSubscription.name,
          paymentDay: this.selectedSubscription.paymentDay,
          duration: this.subscription.duration ? this.subscription.duration : this.selectedSubscription.duration,
          paymentPeriod: this.selectedSubscription.paymentPeriod,
          paymentReminder: this.selectedSubscription.paymentReminder,
          singlePaymentDiscount: this.selectedSubscription.singlePaymentDiscount,
          trialPeriod: this.selectedSubscription.trialPeriod,
          vat: this.selectedSubscription.vat,
          terms: this.selectedSubscription.terms,
          periodicPaymentDiscount: this.selectedSubscription.periodicPaymentDiscount,
          registrationDescription: this.selectedSubscription.registrationDescription,
          registrationUrl: this.selectedSubscription.registrationUrl,
          sendInvoice: this.selectedSubscription.sendInvoice,
          usage: new Array(),
          sepaAttempts: this.selectedSubscription.sepaAttempts,
          amount: this.selectedSubscription.amount,
          amountBruto: this.selectedSubscription.amountBruto,
          separatedDiscount: this.selectedSubscription.separatedDiscount,
          branches: this.selectedSubscription.branches,
          days: this.selectedSubscription.days,

          offPeakMessage: this.selectedSubscription.offPeakMessage,
          peakMessage: this.selectedSubscription.peakMessage,
          posDescription: this.selectedSubscription.posDescription,

          status: {
            endDate: this.selectedSubscription.paymentTypes == 'periodic' || this.subscription.paymentType == 'periodic' ? null : this.calculateEndDate(),
            status: "active",
            type: this.selectedSubscription.paymentTypes != 'singleAndPeriodic' ? this.selectedSubscription.paymentTypes : this.subscription.paymentType
          }
        })
        .then(()=>{
          this.$emit("subscriptionAdded");
          this.closeDialog();
        })
      },

      /**
       * Calculate Enddate on single membership
       */
      calculateEndDate(){
        let types = [
          {id: 'daily', type: 'days', number: 1},
          {id: 'everyWeek', type: 'weeks', number: 1},
          {id: 'everyFourWeeks', type: 'fourWeeks', number: 4},
          {id: 'everyMonth', type: 'months', number: 1},
          {id: 'everyQuarter', type: 'months', number: 3},
          {id: 'everyHalfYear', type: 'months', number: 6},
          {id: 'everyYear', type: 'year', number: 1}
        ]
        let number = this.subscription.duration ? this.subscription.duration: this.selectedSubscription.duration;
        let type = types.find((periodType)=> {return periodType.id == this.selectedSubscription.paymentPeriod })
        if(number > 0 && type != undefined){
          return moment(this.membershipStartDate.formatted).add((type.number * number), type.type).format("YYYY-MM-DD")
        }
        else{
          return null;
        }
      },


      durationSuffix(periodNumber) {
        if(this.selectedSubscription.paymentPeriod == 'daily'){
          if(periodNumber == 1){
            return this.$t('general.day', this.$store.state.locale).toLowerCase()
          }
          else{
            return this.$t('general.days', this.$store.state.locale).toLowerCase()
          }
        }

        else if(this.selectedSubscription.paymentPeriod == 'everyWeek'){
          if(periodNumber== 1){
            return this.$t('general.week', this.$store.state.locale).toLowerCase()
          }
          else{
            return this.$t('general.weeks', this.$store.state.locale).toLowerCase()
          }
        }

        else if(this.selectedSubscription.paymentPeriod == 'everyFourWeeks'){
          return this.$t('general.fourWeeks', this.$store.state.locale).toLowerCase()
        }

        else if(this.selectedSubscription.paymentPeriod == 'everyMonth'){
          if(periodNumber == 1){
            return this.$t('general.month', this.$store.state.locale).toLowerCase()
          }
          else{
            return this.$t('general.months', this.$store.state.locale).toLowerCase()
          }
        }
        else if(this.selectedSubscription.paymentPeriod == 'everyQuarter'){
          if(periodNumber == 1){
            return this.$t('general.quarter', this.$store.state.locale).toLowerCase()
          }
          else{
            return this.$t('general.quarters', this.$store.state.locale).toLowerCase()
          }
        }
        else if(this.selectedSubscription.paymentPeriod == 'everyHalfYear'){
          if(periodNumber== 1){
            return this.$t('general.halfYear', this.$store.state.locale).toLowerCase()
          }
          else{
            return this.$t('general.halfYears', this.$store.state.locale).toLowerCase()
          }
        }
        else if(this.selectedSubscription.paymentPeriod == 'everyYear'){
          if(periodNumber == 1){
            return this.$t('general.year', this.$store.state.locale).toLowerCase()
          }
          else{
            return this.$t('general.years', this.$store.state.locale).toLowerCase()
          }
        }
      },


      closeDialog(){
        this.dialog = false;
        this.resetDialog();
      },
    },
    computed: {
      selectedSubscription(){
        if(this.subscription.subscription){
          let data = this.membershipPackages.find((membership)=> { return membership.id == this.subscription.subscription});
          if(data != undefined){
            return data
          }
          else{
            return false
          }
        }
        else{
          return false
        }
      },

      membershipValid(){
        if((this.selectedSubscription.paymentTypes != 'singleAndPeriodic' || this.subscription.paymentType) && this.subscription.startDate && this.subscription.subscription  && ((!this.subscription.newclient && this.clientData.id) || (this.subscription.newclient && this.clientData.firstName && this.clientData.surname && this.clientData.email))){
          return true;
        }
        else{
          return false;
        }
      },

      membershipStartDate() {
        let date = {
          readable: null,
          formatted: "",
          firebase: null,
        };
        if (this.subscription.startDate) {
          date.readable = moment(this.subscription.startDate).format("D MMMM YYYY");
          date.formatted = moment(this.subscription.startDate).format("YYYY-MM-DD");
          return date;
        } else {
          return date;
        }
      },
    }

  };
</script>
